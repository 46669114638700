import React from 'react';
import style from './style.module.css';

interface DropdownListOption {
    label: string;
    action: string;
}

interface DropdownListProps {
    options: DropdownListOption[];
    goToStudentDetails: () => void;
    gotoFeeCollection: () => void;
    openRecordLeaveFormModal: () => void;
    handleViewDetailsClick: () => void;
    handleChangeClassFormOpen: () => void;
    openStudentDropoutConfirmation: () => void;
    handleArchieveFeeCollectionClick: () => void;
}

const dropdownlistfeePayment = ({
    options,
    goToStudentDetails,
    gotoFeeCollection,
    openRecordLeaveFormModal,
    handleViewDetailsClick,
    handleChangeClassFormOpen,
    openStudentDropoutConfirmation,
    handleArchieveFeeCollectionClick,
}: DropdownListProps) => {
    return (
        <div>
            <ul className={style.dropdownListContainer}>
                {options.map((option, index) => (
                    <li
                        key={index}
                        className={style.dropdownListItem}
                        onClick={() => {
                            switch (option.action) {
                                case 'GO_TO_DETAIL':
                                    goToStudentDetails();
                                    break;
                                case 'GO_TO_COLLECT_FEE':
                                    gotoFeeCollection();
                                    break;
                                case 'OPEN_RECORD_LEAVE_FORM':
                                    openRecordLeaveFormModal();
                                    break;
                                case 'VIEW_DETAILS':
                                    handleViewDetailsClick();
                                    break;
                                case 'OPEN_CHANGE_CLASS_FORM':
                                    handleChangeClassFormOpen();
                                    break;
                                case 'OPEN_STUDENT_DROPOUT_FORM':
                                    openStudentDropoutConfirmation();
                                    break;
                                case 'OPEN_ARCHIVE':
                                    handleArchieveFeeCollectionClick();
                                    break;

                                default:
                                    break;
                            }
                        }}
                    >
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default dropdownlistfeePayment;
