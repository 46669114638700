// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__xT-nO {
    margin: 0.25rem;
    width: 85vw;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
@media (min-width: 640px) {
    .style_container__xT-nO {
        width: 600px
    }
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/enrolment-form-box/style.module.css"],"names":[],"mappings":"AACI;IAAA,eAAyC;IAAzC,WAAyC;IAAzC,kBAAyC;IAAzC;AAAyC;AAAzC;IAAA;QAAA;IAAyC;AAAA","sourcesContent":[".container {\n    @apply bg-white m-1 w-[85vw] sm:w-[600px];\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__xT-nO`
};
export default ___CSS_LOADER_EXPORT___;
