import React, { useState } from 'react';
import arrowDown from '../../../../assets/icons/down-arrow.png';

interface AiAttendanceListItemProps {
    image: string;
    isImageUrl: boolean;
    name: string;
    handleCardExpandBtnClick: () => void;
    isExpanded: boolean;
    status: string;
    handleStudentEditBtnClick: () => void;
    handleAddStudentBtnClick?: () => void;
    isRepeated?: boolean;
    imageIndex?: number;
    handleImageIndexClick?: (index: number) => void;
    handleIndex?: (index: number) => string;
    handleMoveStudentFromAbsentToPresent?: () => void;
    handleMoveStudentFromPresentToAbsent?: () => void;
}

const AiAttendanceListItem = ({
    image,
    isImageUrl,
    name,
    handleCardExpandBtnClick,
    isExpanded,
    status,
    handleStudentEditBtnClick,
    handleAddStudentBtnClick,
    isRepeated,
    imageIndex,
    handleImageIndexClick,
    handleIndex,
    handleMoveStudentFromAbsentToPresent,
    handleMoveStudentFromPresentToAbsent,
}: AiAttendanceListItemProps) => {
    const [statusState, setStatusState] = useState(status);

    return (
        <div className="inline-flex p-2 gap-4 bg-slate-100 rounded w-full shadow-inner shadow-slate-300">
            <img
                src={isImageUrl ? image : `data:image/jpeg;base64,${image}`}
                className={`${isExpanded ? 'h-24 w-24' : 'h-12 w-12'} rounded transition-all shadow-lg`}
                alt="students-avatar"
            />
            <div className="inline-flex w-full justify-between items-center">
                <div className="flex flex-col gap-2 p-1 flex-grow">
                    <div
                        className={`inline-flex items-center gap-4 h-4 mb-2 w-full ${isRepeated ? 'text-red-500' : ''}`}
                    >
                        {name}
                        {imageIndex !== undefined && handleImageIndexClick && (
                            <div
                                className="cursor-pointer flex items-center justify-center text-xs border-2 rounded-full p-1 px-2 active:scale-90 transition-all truncate"
                                onClick={() => handleImageIndexClick(imageIndex)}
                            >
                                {handleIndex?.(imageIndex + 1)} photo
                            </div>
                        )}
                    </div>
                    {/* <div className='h-4 w-48 bg-slate-200 animate-pulse rounded-md'></div> */}
                    {isExpanded && (
                        <div>
                            {status === 'unidentified' ? (
                                <div className='flex justify-end gap-2'>
                                    <button
                                        className="px-2 py-1 rounded flex items-center justify-center border-[#f56d20] border-2 text-[#f56d20] text-sm"
                                        onClick={handleAddStudentBtnClick}
                                    >
                                        Add Student
                                    </button>
                                    <button
                                        className="py-1 px-2 text-sm font-semibold bg-[#f56d20] text-white rounded"
                                        onClick={handleStudentEditBtnClick}
                                    >
                                        Edit Info
                                    </button>
                                </div>
                            ) : status === 'presentByAi' ? (
                                <div className="flex justify-end gap-2">
                                    <button
                                        className="py-1 px-2 text-sm font-semibold bg-[#f56d20] text-white rounded"
                                        onClick={handleStudentEditBtnClick}
                                    >
                                        Edit Info
                                    </button>
                                </div>
                            ) : status === 'presentByUser' ? (
                                <div className="flex justify-end gap-2">
                                    <div 
                                        className='text-red-500 text-sm px-2 py-1 border-2 font-semibold border-red-500 rounded cursor-pointer'
                                        onClick={handleMoveStudentFromPresentToAbsent}
                                    >
                                        Mark Absent
                                    </div>
                                </div>
                            ) : (
                                <div className="flex justify-end">
                                    <div 
                                        className='text-green-500 text-xs px-2 py-1 border-2 font-semibold border-green-500 rounded cursor-pointer'
                                        onClick={handleMoveStudentFromAbsentToPresent}
                                    >
                                        Mark Present
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div>
                    {isExpanded ? (
                        <img
                            src={arrowDown}
                            alt="collapse-icon"
                            className="h-4 w-4 cursor-pointer rotate-180"
                            onClick={handleCardExpandBtnClick}
                        />
                    ) : (
                        <img
                            src={arrowDown}
                            alt="expand-icon"
                            className="h-4 w-4 cursor-pointer"
                            onClick={handleCardExpandBtnClick}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AiAttendanceListItem;
