export type LoginState = {
    email: string;
    password: string;
    isLoginCalling: boolean;
    showPassword: boolean;
    errorMsg: string;
};

export type LoginAction =
    | { type: 'setEmail'; payload: string }
    | { type: 'setPassword'; payload: string }
    | { type: 'setShowPassword' }
    | { type: 'setIsLoginCalling' }
    | { type: 'setErrorMsg'; payload: string };

export const loginReducer = (state: LoginState, action: LoginAction) => {
    switch (action.type) {
        case 'setEmail':
            state.email = action.payload;
            break;
        case 'setPassword':
            state.password = action.payload;
            break;
        case 'setErrorMsg':
            state.errorMsg = action.payload;
            break;
        case 'setIsLoginCalling':
            state.isLoginCalling = !state.isLoginCalling;
            break;
        case 'setShowPassword':
            state.showPassword = !state.showPassword;
            break;
    }
};
