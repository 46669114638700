import { EnrollmentDataListWrapper } from '../models/enrollment-data/enrollment-data-list-wrapper';
import { EnrolmentPostData } from '../models/enrollment-data/enrolment-post-data';
import { EnrolmentPostDataWrapper } from '../models/enrollment-data/enrolment-post-data-wrapper';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class StudentEnrollmentService {
    private static _instance: StudentEnrollmentService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new StudentEnrollmentService());
    }

    public verifyEnrollmentSlug = async (
        slug: string
    ): Promise<ApiResponse<EnrollmentDataListWrapper>> => {
        return invokeApi<null, EnrollmentDataListWrapper>({
            route: `students-enrolement-data?populate=*&filters[slug]=${slug}`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public postEnrolmentData = async (data: EnrolmentPostDataWrapper) => {
        return invokeApi<EnrolmentPostDataWrapper, EnrollmentDataListWrapper>({
            route: `students-enrolement-data`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };
    public putEnrolmentData = async (data: EnrolmentPostDataWrapper, id: number) => {
        return invokeApi<EnrolmentPostDataWrapper, EnrollmentDataListWrapper>({
            route: `students-enrolement-data/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };

    public getEnrolmentFormList = async (
        supplierId: number,
        searchTerm: string | number,
        currentPage: number,
        query: string
    ): Promise<ApiResponse<EnrollmentDataListWrapper>> => {
        return invokeApi<null, EnrollmentDataListWrapper>({
            route: `students-enrolement-data?populate=*&pagination[page]=1&pagination[pageSize]=1500&custom[pageSize]=10&custom[page]=${currentPage}&filters[users][id]=${supplierId}&filters[$or][0][email][$contains]=${searchTerm}&filters[$or][1][phoneNumber][$contains]=${searchTerm}&${query}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
