import { ParentsListWrapper } from '../models/parent/parent-list-wrapper';
import { SupplierDataWrapper } from '../models/supplier/supplier-data-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class ParentService {
    private static _instance: ParentService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new ParentService());
    }

    public getAllParentProfiles = async (): Promise<ApiResponse<ParentsListWrapper>> => {
        return invokeApi<null, ParentsListWrapper>({
            route: `parent-details?populate=userInfo`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
