import React, { useState } from 'react';
import { FeeAttribute } from '../../../../models/fee/fee-attribute';
import style from './style.module.css';
import PopupModal from '../../widgets/popup-modal';
import { DROPDOWN_LISTS_FeePayment_Name } from '../../../pages/fee-payment/data';
import DROPDOWN_LISTS_FeePayment from '../../widgets/dropdown-list-feePayment';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import { AttendanceAttributesWraper } from '../../../../models/attendance/attendance-attributes-wrapper';
import FeeDetailsForm from '../../widgets/fee-details-form';
import { EditingFeeDetailsFormDataType } from '../../../view-models/fee-payment-viewmodel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/redux/store';
import {
    differenceInDays,
    differenceInMonths,
    getCurrentDateFormatted,
    getCurrentDateString,
    getFormattedDayWithYear,
    getFormattedWeekDay,
} from '../../../../utils/helpers/helpers';
import { Roles } from '../../../../utils/auth/roles';

type Props = {
    feePaymentAttribute: FeeAttribute;
    days: string;
    handleActionsButton: () => void;
    isActionsOpen: boolean;
    onCollectFeeClicked: () => void;
    handleFeeListItemCheck: () => void;
    isAllChecked: boolean;
    isModalOpen: boolean;
    isFeeDue: boolean;
    handleViewDetailsClick: () => void;
    leaveRecordData?: AttendanceAttributesWraper[];
    dropdownRef: React.RefObject<HTMLDivElement>;
    handleFeeItemModalCloseClick: () => void;
    handleFeeDetailsEditBtnClick: () => void;
    isFeeDetailsModalEditing: boolean;
    editingFeeDetailsFormData: EditingFeeDetailsFormDataType;
    handleFeeDetailFormInputChange: (field: string, value: string | boolean) => void;
    handleFeeDetailsUpdate: () => void;
    classNotActive: boolean;
    handleArchieveFeeCollection: () => void;
    handleChangeClassFormOpen: () => void;
    openRecordLeaveFormModal: () => void;
    goToStudentDetails: (id: number) => void;
    openStudentDropoutConfirmation: () => void;
    isViewOnly: boolean;
};

const FeePaymentListItem = ({
    feePaymentAttribute,
    handleActionsButton,
    onCollectFeeClicked,
    handleFeeListItemCheck,
    isAllChecked,
    handleViewDetailsClick,
    isModalOpen,
    isFeeDue,
    leaveRecordData,
    isActionsOpen,
    handleFeeItemModalCloseClick,
    dropdownRef,
    handleFeeDetailsEditBtnClick,
    isFeeDetailsModalEditing,
    editingFeeDetailsFormData,
    handleFeeDetailFormInputChange,
    handleFeeDetailsUpdate,
    classNotActive,
    handleArchieveFeeCollection,
    handleChangeClassFormOpen,
    openRecordLeaveFormModal,
    goToStudentDetails,
    openStudentDropoutConfirmation,
    isViewOnly,
}: Props) => {
    const feeRemindersSentCount = feePaymentAttribute?.fee_dates[0].feeRemindersSent?.filter(
        item => item.isSuccess === true
    ).length;
    const userState = useSelector((state: RootState) => state.user);

    return (
        <div className={style.listItemContainer}>
            <input
                type="checkbox"
                onChange={handleFeeListItemCheck}
                checked={!classNotActive && isAllChecked ? true : undefined}
                disabled={classNotActive}
                className={style.checkBoxInput}
            />
            <img
                className={style.listItemImage}
                src={
                    feePaymentAttribute.student?.data?.attributes?.photo?.data?.attributes?.url ||
                    StudentProfileIcon
                }
                alt="list_image"
            />

            <div className={style.DeatilesContainer}>
                <div className={style.listItemNameContainer}>
                    <div className={style.listItemName}>
                        {feePaymentAttribute?.student?.data?.attributes?.firstName ?? ''}{' '}
                        {feePaymentAttribute?.student?.data?.attributes?.middleName ?? ''}{' '}
                        {feePaymentAttribute?.student?.data?.attributes?.lastName ?? ''}
                        {/* {classNotActive && <div className='ml-4 p-1 border border-red-300 text-red-300'>student doesn&apos;t belong to this class anymore</div>} */}
                    </div>
                    <div className={style.listItemParent}>
                        Parent name:{' '}
                        {feePaymentAttribute?.student?.data?.attributes?.studentRelation[0]?.name ??
                            'NA'}
                    </div>
                </div>
                {!isFeeDue && (
                    <div className={style.listItemContact}>
                        {userState?.user?.role?.type === Roles.AUTHENTICATED_USER && (
                            <div>
                                Amount received: ₹
                                {feePaymentAttribute?.fee_dates[0]?.amount ?? 'Nil'}
                            </div>
                        )}

                        <div className={style.listItemOverdueDetails}>
                            Due Date:{' '}
                            {getFormattedDayWithYear(feePaymentAttribute?.fee_dates[0]?.dueDate) ??
                                'Nil'}
                            <br />
                            {/* Remaining Days Section */}
                            {/* Remaining days:{' '}
                            {differenceInDays(
                                new Date(),
                                new Date(
                                    feePaymentAttribute?.fee_dates[0]?.dueDate,
                                ),
                            )} */}
                        </div>
                    </div>
                )}
                {!isFeeDue && (
                    <div className={style.listItemBalance}>
                        Balance Amount: ₹{feePaymentAttribute?.balance ?? 'Nil'}
                    </div>
                )}
                {isFeeDue && (
                    <div className={style.listItemContact}>
                        {userState?.user?.role?.type === Roles.AUTHENTICATED_USER && (
                            <div>
                                Amount Due: ₹
                                {feePaymentAttribute?.balance + feePaymentAttribute?.amount ??
                                    'Nil'}
                            </div>
                        )}
                        <div className={style.listItemOverdueDetails}>
                            Due Date:{' '}
                            {getFormattedDayWithYear(feePaymentAttribute?.fee_dates[0]?.dueDate) ??
                                'Nil'}
                            <br />
                            {/* OverDue Days Section */}
                            {/* Overdue days:{' '}
                            {differenceInDays(
                                new Date(),
                                new Date(
                                    feePaymentAttribute?.fee_dates[0]?.dueDate,
                                ),
                            )} */}
                        </div>
                    </div>
                )}
                {isFeeDue && (
                    <div className={style.sentRemindersCount}>
                        {feeRemindersSentCount > 0 &&
                            `${feeRemindersSentCount} reminders sent: Last on ${new Date(
                                feePaymentAttribute?.fee_dates[0].feeRemindersSent?.filter(
                                    item => item.isSuccess === true
                                )[0]?.sentOn
                            )
                                .toDateString()
                                .slice(4)}`}
                        {feeRemindersSentCount === 0 && `0 reminders sent`}
                    </div>
                )}
            </div>
            {!isViewOnly && (
                <>
                    {classNotActive ? (
                        <button
                            onClick={handleArchieveFeeCollection}
                            className={style.listItemSolidButton}
                        >
                            Archive
                        </button>
                    ) : (
                        <button
                            onClick={onCollectFeeClicked}
                            className={style.listItemSolidButton}
                        >
                            Collect Fee
                        </button>
                    )}
                </>
            )}
            <button
                onClick={handleActionsButton}
                className={style.listItemButton}
            >
                More Actions
            </button>
            {isActionsOpen && (
                <div
                    ref={dropdownRef}
                    className={style.studentsOptionsDropdownContainer}
                >
                    <DROPDOWN_LISTS_FeePayment
                        options={DROPDOWN_LISTS_FeePayment_Name.filter(item => {
                            if (classNotActive) {
                                return (
                                    item.action !== 'OPEN_CHANGE_CLASS_FORM' &&
                                    item.action !== 'GO_TO_COLLECT_FEE' &&
                                    item.action !== 'OPEN_RECORD_LEAVE_FORM'
                                );
                            } else {
                                return item.action !== 'OPEN_ARCHIVE';
                            }
                        }).filter(item => {
                            if (
                                userState?.userDetails?.attributes?.feePaymentAccess ===
                                'restrictedView'
                            ) {
                                return (
                                    item.action !== 'OPEN_CHANGE_CLASS_FORM' &&
                                    item.action !== 'GO_TO_COLLECT_FEE' &&
                                    item.action !== 'OPEN_RECORD_LEAVE_FORM' &&
                                    item.action !== 'OPEN_STUDENT_DROPOUT_PROMPT'
                                );
                            } else {
                                return true;
                            }
                        })}
                        goToStudentDetails={() => {
                            handleActionsButton();
                            goToStudentDetails(feePaymentAttribute?.student?.data?.id);
                        }}
                        gotoFeeCollection={onCollectFeeClicked}
                        openRecordLeaveFormModal={() => {
                            handleActionsButton();
                            openRecordLeaveFormModal();
                        }}
                        handleViewDetailsClick={() => {
                            handleActionsButton();
                            handleViewDetailsClick();
                        }}
                        handleChangeClassFormOpen={() => {
                            handleActionsButton();
                            handleChangeClassFormOpen();
                        }}
                        openStudentDropoutConfirmation={() => {
                            handleActionsButton();
                            openStudentDropoutConfirmation();
                        }}
                        handleArchieveFeeCollectionClick={() => {
                            handleArchieveFeeCollection();
                        }}
                    />
                </div>
            )}

            <button
                onClick={handleActionsButton}
                className={style.listItemButton1}
            >
                <div className={style.DotesIcon}>...</div>
            </button>

            <PopupModal
                isOpen={isModalOpen}
                handleModalClose={handleFeeItemModalCloseClick}
                title="Fee Details"
                background="white"
            >
                <FeeDetailsForm
                    feePaymentAttribute={feePaymentAttribute}
                    isFeeDue={isFeeDue}
                    leaveRecordData={leaveRecordData}
                    handleFeeDetailsEditBtnClick={handleFeeDetailsEditBtnClick}
                    isFeeDetailsModalEditing={isFeeDetailsModalEditing}
                    editingFeeDetailsFormData={editingFeeDetailsFormData}
                    handleFeeDetailsUpdate={handleFeeDetailsUpdate}
                    handleFeeDetailFormInputChange={handleFeeDetailFormInputChange}
                />
            </PopupModal>
        </div>
    );
};

export default FeePaymentListItem;
