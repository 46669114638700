import React, { ChangeEvent } from 'react';
import style from './style.module.css';
import redClose from '../../../../assets/icons/red-close.png';
import Loader from '../../bits/loader';

interface PhotoUploadInputProps {
    label: string;
    uploadedImageData?: string[];
    handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    handleDeleteImage?: (index: number) => void;
    isAcademyImagePosting?: boolean;
}

const PhotoUploadInput = ({
    label,
    uploadedImageData,
    handleFileChange,
    handleDeleteImage,
    isAcademyImagePosting,
}: PhotoUploadInputProps) => {
    return (
        <div className={style.photoUploadInputContainer}>
            <div className={style.photoUploadInputLabel}>
                {label} <span className={style.imageMaxSizePrompt}>(max 10MB)</span>
            </div>
            <div className={style.photoUploadInputInputContainer}>
                <div className={style.imageContainer}>
                    <div className={style.formImageInputBtnWrapper}>
                        <label
                            htmlFor="fileInput"
                            className={style.formImageInputBtnLabel}
                        >
                            <input
                                type="file"
                                id="fileInput"
                                className={style.formImageInput}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                            <div className={style.formImageInputLabel}>
                                <div>+</div>
                                <div>Upload</div>
                            </div>
                        </label>
                    </div>
                    {uploadedImageData?.map((item, index) => (
                        <div
                            key={index}
                            className={style.imageWrapper}
                        >
                            <img
                                className={style.photoUploadInputThumbnail}
                                src={item}
                                alt="thumbnail"
                            />
                            <button
                                className={style.deleteImageButton}
                                onClick={event => {
                                    event.preventDefault();
                                    handleDeleteImage && handleDeleteImage(index);
                                }}
                            >
                                <img
                                    src={redClose}
                                    alt="Delete"
                                />
                            </button>
                        </div>
                    ))}
                    {(!uploadedImageData || isAcademyImagePosting) && (
                        <div className="min-h-[105px] h-[105px] w-[105px] text-center min-w-[105px] border flex justify-center items-center text-gray-500">
                            {isAcademyImagePosting ? <Loader /> : 'No image uploaded'}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PhotoUploadInput;
