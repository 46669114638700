import { ChangeEvent, useEffect, useReducer, useRef, useState } from 'react';
import {
    ACADEMY_QUERY,
    CLASS_DETAILS_QUERY,
    DISCIPLINE_QUERY,
    ENQUIRY_QUERY,
    STUDENT_QUERY,
    USER_QUERY,
} from '../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AcademiesService } from '../../services/academies-service';
import ClassService from '../../services/class-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { EnquiryService } from '../../services/enquiry-services';
import { EnquiryPostDataWrapper } from '../../models/enquiry/enquiry-post-data-wrapper';
import { EnquiryData } from '../../models/enquiry/enquiry-data';
import ApiResponse from '../../utils/types/api-response';
import { EnquiryPostData } from '../../models/enquiry/enquiry-post-data';
import { StudentFormDataType, studentFormDataInitialState } from './students-view-model';
import { ClassData } from '../../models/class/class-data';
import { UploadService } from '../../services/upload-service';
import { StudentPostDataWrapper } from '../../models/student/student-post-data-wrapper';
import { StudentData } from '../../models/student/student-data';
import { StudentService } from '../../services/student-service';
import { constants } from 'fs/promises';
import { EnquiriesState, enquiriesReducer } from '../reducers/enquiry-reducer';
import EnquiryFollowupWhatsappMessageData from '../../utils/types/enquiry-followup-whatsapp-message-data';
import { EnquiryFollowupEmailAttributes } from '../../models/enquiry/enquiry-followup-email-data';
import { it } from 'node:test';
import { DriveStep, driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import {
    differenceInDays,
    formatDateToInputDate,
    isElementPresent,
} from '../../utils/helpers/helpers';
import { ENQUIRY_PAGE_WALKTHROUGH } from '../../utils/constants/walkthroughConstants';
import { UserPostData } from '../../models/user/user-post-data';
import { UserService } from '../../services/user-services';
import { User } from '../../models/user/user';
import { setWalkthroughFlags } from '../../utils/redux/user-slice';
import { DisciplinesService } from '../../services/disciplines-service';
import { StudentDetailsWrapper } from '../../models/student/student-details-wrapper';

export interface EnquiryFormDataType {
    firstName: string;
    middleName: string;
    lastName: string;
    contactNo: string;
    parentName: string;
    email: string;
    academies: number[];
    discipline: number[];
    classesInterested: number[];
    isDemoChecked: boolean;
    remarks: string;
    demoDate: string | null;
    demoTime: string | null;
}

const EnquiresViewModel = () => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();
    const [isfilterModalOpen, setIsfilterModalOpen] = useState<boolean>(false);
    const [isNewEnquiryModalOpen, setIsNewEnquiryModalOpen] = useState<boolean>(false);
    const [expandedEnquiryItem, setExpandedEnquiryItem] = useState<number>(0);
    const [enquiryAcademyListExpanded, setEnquiryAcademyListExpanded] = useState<number>(0);
    const [enquiryDisciplineListExpanded, setEnquiryDisciplineListExpanded] = useState<number>(0);
    const [enquiryClassListExpanded, setEnquiryClassListExpanded] = useState<number>(0);
    const [studentImageid, setStudentImageId] = useState<number>();
    const [profileImageInputKey, setProfileImageInputKey] = useState<number>(0);
    const [currentEnquiryData, setCurrentEnquiryData] = useState<EnquiryData | undefined>();
    const [remarksText, setRemarksText] = useState<string>('');
    const [isSortOptionsVisible, setIsSortOptionsVisible] = useState<boolean>(false);
    const [selectedSortOption, setSelectedSortOption] = useState<number>(1);
    const [visibleFilter, setVisibleFilter] = useState<number>(0);
    const [checkedEnquiryListItems, setCheckedEnquiryListItems] = useState<EnquiryData[]>([]);
    const [allListItemsSelected, setAllListItemsSelected] = useState<boolean>(false);
    const [isSendEmailMessageDisabled, setIsSendEmailMessageDisabled] = useState<boolean>(true);
    const usedispatch = useDispatch();
    const [selectedDemoDate, setSelectedDemoDate] = useState('');
    const [allDisciplinesSelected, setAllDisciplinesSelected] = useState<boolean>(false);

    const setUserTutorialFlag = async (data: UserPostData): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.updateUser(data, userState?.user?.id ?? 0);
        if (response.success) {
            return response;
        } else {
            // console.log(
            //     `On Update user user Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const { mutate: updateUserTutorialFlag } = useMutation(setUserTutorialFlag, {
        onSuccess: data => {
            queryClient.invalidateQueries(USER_QUERY);
        },
        onError: error => {
            // console.log(`On user update Error:`, error);
            // alert('Failed to update user! Please try again');
            // Error actions
        },
    });

    const filteredSteps: DriveStep[] | undefined = [];
    if (isElementPresent('sendEmailButton')) {
        filteredSteps.push({
            element: '#sendEmailButton',
            popover: {
                title: 'Send Email',
                description: 'Send follow up email manually',
                side: 'bottom',
                align: 'start',
            },
        });
        if (isElementPresent('sendWhatsappButton')) {
            filteredSteps.push({
                element: '#sendWhatsappButton',
                popover: {
                    title: 'WhatsApp',
                    description: 'Send follow up Whatsapp message manually',
                    side: 'bottom',
                    align: 'start',
                },
            });
        }
    }

    const driverObj = driver({
        popoverClass: 'driverjs-theme',
        doneBtnText: 'Finish Tutorial',
        showProgress: true,
        showButtons: ['next', 'previous'],
        prevBtnText: 'Skip Tour',
        steps: filteredSteps,
        onPrevClick: (element, step, opts) => {
            if (step?.popover?.nextBtnText == 'Finish Tutorial') {
                driverObj.destroy();
                const prevTutorialState = userState.walkthroughFlag
                    ? userState.walkthroughFlag.split(',')
                    : [];
                prevTutorialState?.push(ENQUIRY_PAGE_WALKTHROUGH);
                const newTutorialFlag = prevTutorialState?.join(',');
                usedispatch(setWalkthroughFlags(newTutorialFlag));
            }
            if (
                !driverObj.hasNextStep() ||
                confirm('Are you sure you want to exit the tutorial?')
            ) {
                driverObj.destroy();
            }
        },
        onDestroyStarted: (element, step, opts) => {
            if (step?.popover?.nextBtnText == 'Finish Tutorial') {
                driverObj.destroy();
                const prevTutorialState = userState.walkthroughFlag
                    ? userState.walkthroughFlag.split(',')
                    : [];
                prevTutorialState?.push(ENQUIRY_PAGE_WALKTHROUGH);
                const newTutorialFlag = prevTutorialState?.join(',');
                usedispatch(setWalkthroughFlags(newTutorialFlag));
            }
            if (
                !driverObj.hasNextStep() ||
                confirm('Are you sure you want to exit the tutorial?')
            ) {
                driverObj.destroy();
            }
        },
    });
    const numberOfDays = differenceInDays(new Date(userState.user?.createdAt ?? ''), new Date());
    if (
        numberOfDays < 30 &&
        !(userState.walkthroughFlag === 'FETCHING') &&
        !userState.walkthroughFlag.split(',').includes(ENQUIRY_PAGE_WALKTHROUGH)
    ) {
        setTimeout(() => {
            driverObj.drive();
        }, 2000);
    }

    const sortDropdownRef = useRef<HTMLDivElement>(null);
    const filterDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                sortDropdownRef.current &&
                !sortDropdownRef.current.contains(event.target as Node)
            ) {
                setIsSortOptionsVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sortDropdownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                filterDropdownRef.current &&
                !filterDropdownRef.current.contains(event.target as Node)
            ) {
                toggleVisibleFilter(visibleFilter);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterDropdownRef]);

    const toggleVisibleFilter = (filter: number) => {
        if (filter === visibleFilter) {
            setVisibleFilter(0);
        } else {
            setVisibleFilter(filter);
        }
    };

    const initialEnquiryFormData = {
        firstName: '',
        middleName: '',
        lastName: '',
        parentName: '',
        contactNo: '',
        email: '',
        academies: [],
        discipline: [],
        classesInterested: [],
        isDemoChecked: false,
        remarks: '',
        demoDate: null,
        demoTime: null,
    };

    const initialState: EnquiriesState = {
        currentPage: 1,
    };

    const [state, dispatch] = useReducer(enquiriesReducer, initialState);

    const itemsPerPage = 10;
    const indexOfLastItem = state.currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [enquiryFormData, setEnquiryFormData] =
        useState<EnquiryFormDataType>(initialEnquiryFormData);
    const { isSuccess: isEnquiriesFetchSuccess, data: enquiriesData } = useQuery({
        queryKey: [ENQUIRY_QUERY],
        queryFn: EnquiryService.instance.getEnquiriesList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isClassFetchSuccess, data: classesData } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isDisciplineFetchSuccess, data: disciplineData } = useQuery({
        queryKey: [DISCIPLINE_QUERY],
        queryFn: () => DisciplinesService.instance.getDisciplinesList(),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const handleFilterModalClose = () => {
        setIsfilterModalOpen(false);
    };

    const handleFilterModalOpen = () => {
        setIsfilterModalOpen(true);
    };

    const handleAddEnquiryBtnClick = () => {
        setIsNewEnquiryModalOpen(true);
    };

    useEffect(() => {
        setEnquiryFormData(prevFormData => {
            // Ensure prevFormData.discipline is an array before filtering
            const updatedDiscipline = Array.isArray(prevFormData?.discipline)
                ? prevFormData.discipline.filter(item => {
                      return academiesData?.data?.data
                          ?.filter(academy => prevFormData.academies.includes(academy?.id))
                          ?.some(academy =>
                              academy?.attributes?.selectedDisciplines?.data
                                  ?.map(item => item.id)
                                  ?.includes(item)
                          );
                  })
                : [];

            return {
                ...prevFormData,
                discipline: updatedDiscipline,
            };
        });
    }, [enquiryFormData.academies, academiesData?.data?.data]);

    useEffect(() => {
        setEnquiryFormData(prevFormData => {
            const remainingDisciplines = prevFormData.discipline;
            const remainingClasses = prevFormData.classesInterested.filter(classId => {
                const classDiscipline = classesData?.data?.data.find(item => item.id === classId)
                    ?.attributes?.class_discipline?.data?.id;
                return classDiscipline && remainingDisciplines.includes(classDiscipline);
            });
            return {
                ...prevFormData,
                classesInterested: remainingClasses,
            };
        });
    }, [enquiryFormData.discipline]);

    const mapAcademiesToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data?.map(academy => {
                const { id, attributes } = academy;
                return {
                    value: id,
                    label: attributes.name,
                };
            });
        }
        return [];
    };

    const mapDiciplinesOfSelectedAcademiesToOptions = ():
        | { value: number; label: string }[]
        | [] => {
        if (academiesData?.data?.data && enquiryFormData.academies) {
            return academiesData.data.data
                ?.filter(academy => enquiryFormData.academies.includes(academy?.id))
                ?.flatMap(academy => {
                    return academy?.attributes?.selectedDisciplines?.data?.map(item => ({
                        value: item.id,
                        label: item.attributes.name,
                    }));
                });
        }
        return [];
    };

    const mapClassesOfSelectedDisciplinesToOptions = ():
        | { value: number; label: string }[]
        | [] => {
        if (classesData?.data?.data && enquiryFormData.classesInterested) {
            return classesData?.data?.data
                .filter(item =>
                    enquiryFormData.discipline.includes(
                        item?.attributes?.class_discipline?.data?.id
                    )
                )
                .filter(item =>
                    enquiryFormData.academies.includes(item?.attributes?.academies?.data.id)
            )
                .filter(item => item.attributes.status !== 'INACTIVE')
                .map(item => {
                    const { id, attributes } = item;
                    return {
                        value: id,
                        label: attributes.class_name,
                    };
                });
        }
        return [];
    };

    const handleNewEnquiryModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsNewEnquiryModalOpen(false);
            setEnquiryFormData(initialEnquiryFormData);
        } else {
            return;
        }
    };

    const handleEditEnquiryModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsEditEnquiryModalOpen(false);
            setEnquiryFormData(initialEnquiryFormData);
        } else {
            return;
        }
    }

    const handleInputChange = (field: string, value: string | number[] | boolean | null) => {
        setEnquiryFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const postNewEnquiry = async (
        data: EnquiryPostDataWrapper
    ): Promise<ApiResponse<EnquiryData>> => {
        const response = await EnquiryService.instance.postNewEnquiry(data);
        if (response.success) {
            return response;
        } else {
            // console.log(
            //     `On Add enquiry Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewEnquiry,
        isLoading: isNewEnquiryAdding,
        isSuccess: isNewEnquiryAdded,
    } = useMutation(postNewEnquiry, {
        onSuccess: data => {
            alert('Added Enquiry Successfully!');
            setIsNewEnquiryModalOpen(false);
            setEnquiryFormData(initialEnquiryFormData);
            queryClient.invalidateQueries(ENQUIRY_QUERY);
        },
        onError: error => {
            // console.log(`On enquiry add Error:`, error);
            alert('Failed to add enquiry! Please try again');
            // Error actions
        },
    });

    const updateEnquiry = async (data: {
        data: EnquiryPostDataWrapper;
        id: number;
    }): Promise<ApiResponse<EnquiryData>> => {
        const response = await EnquiryService.instance.updateEnquiry(data.data, data.id);
        if (response.success) {
            return response;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editEnquiry,
        isLoading: isEnquiryEditing,
        isSuccess: isEnquiryEdited,
    } = useMutation(updateEnquiry, {
        onSuccess: data => {
            // alert('Updated Enquiry Successfully!');
            queryClient.invalidateQueries(ENQUIRY_QUERY);
        },
        onError: error => {
            // console.log(`On enquiry add Error:`, error);
            alert('Failed to Update enquiry! Please try again');
            // Error actions
        },
    });

    const handleAddNewEnquiry = async (e: React.FormEvent) => {
        e.preventDefault();

        const newEnquiryData: EnquiryPostDataWrapper = {
            data: {
                firstName: enquiryFormData.firstName,
                middleName: enquiryFormData.middleName,
                lastName: enquiryFormData.lastName,
                parentName: enquiryFormData.parentName,
                contactNo: parseInt(enquiryFormData.contactNo),
                email: enquiryFormData.email === '' ? null : enquiryFormData.email,
                discipline: disciplineData?.data?.data
                    ?.filter(item => enquiryFormData?.discipline?.includes(item.id))
                    ?.map(data => data?.attributes?.name)
                    .join(','),
                academies: enquiryFormData.academies,
                classesInterested: enquiryFormData.classesInterested,
                users: [userState?.user?.id ?? 0],
                addedBy: userState?.user?.id ?? 0,
                demoDateAndTime: enquiryFormData.isDemoChecked ? new Date(`${enquiryFormData.demoDate}T${enquiryFormData.demoTime}:00`).toISOString() : null,
                remarks: enquiryFormData.remarks.trim().length > 0 ? [
                    {
                        date: new Date(),
                        enquiredBy: userState?.user?.id ?? 0,
                        remarksInfo: enquiryFormData.remarks,
                        status: 'Enquiring',
                    }
                ] : [],
            },
        };

        addNewEnquiry(newEnquiryData);
    };

    const handleEnquiryItemExpandCollapse = (id: number) => {
        if (expandedEnquiryItem === id) {
            setExpandedEnquiryItem(0);
        } else {
            setExpandedEnquiryItem(id);
        }
        setRemarksText('');
    };

    const handleEnquiryInfoListExpandClick = (type: string, id: number) => {
        switch (type) {
            case 'academy':
                if (enquiryAcademyListExpanded === id) {
                    setEnquiryAcademyListExpanded(0);
                } else {
                    setEnquiryAcademyListExpanded(id);
                }
                break;
            case 'discipline':
                if (enquiryDisciplineListExpanded === id) {
                    setEnquiryDisciplineListExpanded(0);
                } else {
                    setEnquiryDisciplineListExpanded(id);
                }
                break;
            case 'class':
                if (enquiryClassListExpanded === id) {
                    setEnquiryClassListExpanded(0);
                } else {
                    setEnquiryClassListExpanded(id);
                }
                break;
            default:
                break;
        }
    };

    const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);
    const [studentFormData, setStudentFormData] = useState<StudentFormDataType>(
        studentFormDataInitialState
    );
    const [activeTab, setActiveTab] = useState(1);

    const handleNewStudentModalClose = () => {
        setIsNewStudentModalOpen(false);
    };

    const switchTab = (tabNumber: number, isGoingBack: boolean) => {
        if (isGoingBack) {
            setActiveTab(tabNumber);
        } else {
            if (validateCurrentTab()) {
                setActiveTab(tabNumber);
            }
        }
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateCurrentTab = () => {
        switch (activeTab) {
            case 1:
                if (
                    !studentFormData.firstName ||
                    !studentFormData.gender ||
                    !studentFormData.DateOfBirth
                ) {
                    alert('Please fill in all required fields.');
                    return false;
                } else if (studentFormData.academies.length <= 0) {
                    alert('Please Choose an academy.');
                    return false;
                } else if (studentFormData.class.length <= 0) {
                    alert('Please Choose a class.');
                    return false;
                }
                break;
            case 2:
                if (
                    !studentFormData.parentNumber ||
                    !studentFormData.parentEmail ||
                    !studentFormData.currentAddress
                ) {
                    alert('Please fill in all required fields.');
                    return false;
                } else if (!isValidEmail(studentFormData.parentEmail)) {
                    alert('Invalid email format. Please enter a valid parent email address.');
                    return false;
                }
                break;
            case 3:
                break;
            default:
                break;
        }
        return true;
    };

    const handleStudentInputChange = (field: string, value: string | number[]) => {
        setStudentFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    useEffect(() => {
        const academyIds = new Set(studentFormData.academies);
        const filteredClassIds = studentFormData.class
            .map(classId => classesData?.data?.data?.find(classItem => classItem?.id === classId))
            .filter(classItem => {
                const academyId = classItem?.attributes?.academies?.data?.id;
                return academyId !== undefined && academyIds.has(academyId);
            })
            .map(classItem => classItem?.id)
            .filter((id): id is number => id !== undefined);

        setStudentFormData(prevData => ({
            ...prevData,
            class: filteredClassIds,
        }));
    }, [studentFormData.academies, classesData?.data?.data]);

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data && studentFormData.academies) {
            return classesData?.data?.data
                .filter((item: ClassData) =>
                    studentFormData?.academies.includes(item?.attributes?.academies?.data.id)
                )
                .map((item: ClassData) => ({
                    value: item.id,
                    label: item?.attributes?.class_name,
                }));
        }
        return [];
    };

    const PostStudentImage = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setStudentFormData(prevFormData => ({
                                ...prevFormData,
                                photo: item.url,
                            }));
                        }
                        if (item.id) {
                            setStudentImageId(item.id);
                        }
                    });
            } else {
                // console.log(
                //     `On Post postStudentProfileImageUpload Error ${JSON.stringify(
                //         response.error,
                //     )}`,
                // );
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postStudentProfileImageUpload: ${error}`);
            throw error;
        }
    };

    const {
        mutate: UploadStudentImage,
        isLoading: isProfileImagePosting,
        error: isAcademyImagePostingError,
    } = useMutation(PostStudentImage, {
        onSuccess: data => {
            console.log('image upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const handleProfileImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 10240) {
                    alert('Image size exceeds 10MB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadStudentImage(data);
            setProfileImageInputKey(prev => prev + 1);
        }
    };

    const handleAddNewStudentBtnClick = (enquiry: EnquiryData) => {
        setCurrentEnquiryData(enquiry);
        setStudentFormData(prev => ({
            ...prev,
            firstName: enquiry.attributes.firstName,
            middleName: enquiry.attributes.middleName,
            lastName: enquiry.attributes.lastName,
            parentEmail: enquiry.attributes.email,
            parentNumber: enquiry.attributes.contactNo,
            academies: enquiry.attributes.academies.data.map(item => item.id),
            class: enquiry.attributes.classesInterested.data.map(item => item.id),
        }));

        const data = enquiry.attributes.academies.data
            .map(academy => academy.id)
            .map(id => {
                const reqClasses =
                    classesData?.data?.data
                        ?.filter(
                            classData =>
                                classData?.attributes?.academies?.data?.id === id &&
                                enquiry.attributes?.classesInterested?.data
                                    .map(data => data.id)
                                    .includes(classData?.id)
                        )
                        .map(data => data?.id) ?? [];
                return {
                    academyId: id,
                    classId: reqClasses,
                    enrolmentDate: formatDateToInputDate(new Date()),
                };
            });
        setIsNewStudentModalOpen(true);
    };

    const postNewStudent = async (
        data: StudentPostDataWrapper
    ): Promise<StudentDetailsWrapper | undefined> => {
        try {
            const response = await StudentService.instance.postNewStudent(data);
            console.log(response);

            if (response.success) {
                if (currentEnquiryData) {
                    const remarksInfo = userState?.user?.username
                        ? 'Student added by ' + userState.user.username
                        : 'Student added';

                    const remarksData = {
                        data: {
                            remarks: [
                                ...(currentEnquiryData?.attributes?.remarks?.map(item => ({
                                    date: item.date,
                                    enquiredBy: item.enquiredBy?.data?.id,
                                    remarksInfo: item.remarksInfo,
                                    status: item.status,
                                })) ?? []),
                                {
                                    date: new Date(),
                                    enquiredBy: userState?.user?.id ?? 0,
                                    remarksInfo,
                                    status: 'Added Student',
                                },
                            ],
                        },
                    };

                    await editEnquiry({ data: remarksData, id: currentEnquiryData.id });
                }
                return response.data;
            }
        } catch (error) {
            console.error('Error posting new student:', error);
            throw new Error('An error occurred while posting the new student.');
        }
    };

    const {
        mutate: addNewStudent,
        isLoading: isNewStudentAdding,
        isSuccess: isNewStudentAdded,
    } = useMutation(postNewStudent, {
        onSuccess: data => {
            console.log(data);
            alert('Added a student Successfullydwfdw!');
            setIsNewStudentModalOpen(false);
            setActiveTab(1);
            setStudentFormData(studentFormDataInitialState);
            queryClient.invalidateQueries(STUDENT_QUERY);
        },
        onError: error => {
            alert('Failed to add student! Please try again');
        },
    });

    const handleAddNewStudent = (e: React.FormEvent) => {
        e.preventDefault();

        const newStudentData: StudentPostDataWrapper = {
            data: {
                firstName: studentFormData.firstName,
                lastName: studentFormData.lastName || null,
                middleName: studentFormData.middleName || null,
                gender: studentFormData.gender,
                dob: studentFormData.DateOfBirth,
                handicapped: studentFormData.isPhysicallyHandicapped === 'true' ? true : false,
                bloodGroup: studentFormData.BloodGroup || null,
                parentContactNo: studentFormData.parentNumber,
                parentEmailId: studentFormData.parentEmail,
                city: studentFormData.city || null,
                state: studentFormData.state || null,
                currentAddress: studentFormData.currentAddress || null,
                apartmentName: studentFormData.apartmentName || null,
                photo: studentImageid || null,
                academies: studentFormData.academies,
                classes: studentFormData.class,
                studentAcademyHistory: studentFormData.academies.map(academyId => {
                    return {
                        supplier: userState.user?.id,
                        academy: academyId,
                        status: 'Active',
                        statusDate: new Date(),
                    };
                }),
                studentRelation:
                    [
                        {
                            relation: studentFormData.relationOneRelationship || null,
                            name: studentFormData.relationOneFullName || null,
                            contactNo: studentFormData.relationOneContact || null,
                            emailId: studentFormData.relationOneEmail || null,
                            gender: studentFormData.relationOneGender || null,
                            profession: studentFormData.relationOneProfession || null,
                        },
                        {
                            relation: studentFormData.relationTwoRelationship || null,
                            name: studentFormData.relationTwoFullName || null,
                            contactNo: studentFormData.relationTwoContact || null,
                            emailId: studentFormData.relationTwoEmail || null,
                            gender: studentFormData.relationTwoGender || null,
                            profession: studentFormData.relationTwoProfession || null,
                        },
                    ] || null,
                users: [userState?.user?.id || 0],
            },
        };

        addNewStudent(newStudentData);
    };

    const handleNotInterestedClick = (enquiry: EnquiryData) => {
        const result = window.confirm(`Are you sure that you want to mark student not interested?`);
        if (result) {
            const remarksInfo =
                userState && userState.user && userState.user.username
                    ? 'Marked as Not Interested by ' + userState.user.username
                    : 'Marked as Not Interested';

            const data = {
                data: {
                    remarks: [
                        ...(enquiry && enquiry.attributes && enquiry.attributes?.remarks
                            ? enquiry.attributes.remarks.map(item => ({
                                  date: item && item.date,
                                  enquiredBy:
                                      item &&
                                      item.enquiredBy &&
                                      item.enquiredBy.data &&
                                      item.enquiredBy.data.id,
                                  remarksInfo: item && item?.remarksInfo,
                                  status: item && item.status,
                              }))
                            : []),
                        {
                            date: new Date(),
                            enquiredBy:
                                userState && userState.user && userState.user.id
                                    ? userState.user.id
                                    : 0,
                            remarksInfo,
                            status: 'Not Interested',
                        },
                    ],
                },
            };

            editEnquiry({ data, id: enquiry.id });
        } else {
            return;
        }
    };
    const handleRetRemarksTextChange = (text: string) => {
        setRemarksText(text);
    };

    const handleAddRemarks = (enquiry: EnquiryData) => {
        if (remarksText) {
            const data = {
                data: {
                    remarks: [
                        ...(enquiry && enquiry.attributes && enquiry.attributes.remarks
                            ? enquiry.attributes.remarks.map(item => ({
                                  date: item && item.date,
                                  enquiredBy:
                                      item &&
                                      item.enquiredBy &&
                                      item.enquiredBy.data &&
                                      item.enquiredBy.data.id,
                                  remarksInfo: item && item.remarksInfo,
                                  status: item && item.status,
                              }))
                            : []),
                        {
                            date: new Date(),
                            enquiredBy:
                                userState && userState.user && userState.user.id
                                    ? userState.user.id
                                    : 0,
                            remarksInfo: remarksText,
                            status: 'Enquiring',
                        },
                    ],
                },
            };
            editEnquiry({ data, id: enquiry.id });
            setRemarksText('');
        } else {
            alert('Please add remarks info!');
        }
    };

    const handleSortDropBtnClick = () => {
        setIsSortOptionsVisible(prev => !prev);
    };

    const handleSortOptionChange = (option: number) => {
        setSelectedSortOption(option);
    };
    const [selectedStatusForFilter, setSelectedStatusForFilter] = useState<string[]>(['Enquiring']);
    const [selectedDisciplineForFilter, setSelectedDisciplineForFilter] = useState<string[]>([]);

    useEffect(() => {
        if (allListItemsSelected === false) {
            setCheckedEnquiryListItems([]);
        }
    }, [allListItemsSelected]);

//     useEffect(() => {
//     console.log('Selected disciplines updated:', selectedDisciplineForFilter);
// }, [selectedDisciplineForFilter]);

    const onStatusFilterSelect = (status: string) => {
        setAllListItemsSelected(false);
        setSelectedStatusForFilter(prev => {
            const index = prev.indexOf(status);
            if (index !== -1) {
                // If the status is already present, remove it
                return prev.filter(item => item !== status);
            } else {
                // If the status is not present, add it
                return [...prev, status];
            }
        });
    };

    const onDisciplineFilterSelect = (discipline: string) => {
        setAllDisciplinesSelected(false);
        setSelectedDisciplineForFilter(prev => {
            const index = prev.indexOf(discipline);
            if (index !== -1) {
                // If the status is already present, remove it
                return prev.filter(item => item !== discipline);
            } else {
                // If the status is not present, add it
                return [...prev, discipline];
            }
        });
    };

    const sortedEnquiriesData = enquiriesData?.data?.data.sort((a, b) => {
        const dateA = new Date(a.attributes.createdAt).getTime();
        const dateB = new Date(b.attributes.createdAt).getTime();
        if (selectedSortOption === 1) {
            return dateB - dateA; // Sort from newest to oldest
        } else if (selectedSortOption === 2) {
            return dateA - dateB; // Sort from oldest to newest
        }
        return 0;
    });

    const filteredEnquiries = sortedEnquiriesData?.filter(item => {
        if (selectedStatusForFilter.length > 0) {
            if (
                selectedStatusForFilter.includes('Enquiring') &&
                item?.attributes?.remarks?.length === 0
            ) {
                return true;
            }
            const status =
                item?.attributes?.remarks[item?.attributes?.remarks?.length - 1]?.status || '';
            return selectedStatusForFilter.includes(status);
        } else {
            return true;
        }
    });

    const filteredDisciplines = filteredEnquiries?.filter(item => {
        if (selectedDisciplineForFilter.length > 0) {
            const discipline =
                item?.attributes?.discipline;
            return selectedDisciplineForFilter.includes(discipline);
        } else {
            return true;
        }
    });

    const onFilterDateSelect = (date: string) => {
        setSelectedDemoDate(date);
    }

    const filteredDateEnquiries = filteredDisciplines?.filter(enquiry => {
        if (!selectedDemoDate) {
            return filteredDisciplines;
        } else {
            const demoDateString = enquiry?.attributes?.demoDateAndTime;
            if (!demoDateString) return false; // Skip if demoDateAndTime is missing

            const demoDate = new Date(demoDateString);
            const formattedDemoDate = demoDate.toISOString().split('T')[0]; // Extract the YYYY-MM-DD part

            return formattedDemoDate === selectedDemoDate;
        } // Compare with the selected date
    });

    const handleEnquiryListItemCheck = (value: EnquiryData) => {
        setCheckedEnquiryListItems(prevItems => {
            const isAlreadyChecked = prevItems.some(item => item.id === value.id);

            if (isAlreadyChecked) {
                return prevItems.filter(item => item.id !== value.id);
            } else {
                return [...prevItems, value];
            }
        });
    };

    const handleAllListSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setAllListItemsSelected(e.target.checked);

        if (e.target.checked) {
            setCheckedEnquiryListItems(filteredEnquiries || []);
        }
    };

    const postFollowupMessage = async (data: {
        data: EnquiryFollowupWhatsappMessageData;
        remarksData: EnquiryPostDataWrapper;
        id: number;
    }): Promise<EnquiryFollowupWhatsappMessageData | string> => {
        const response = await EnquiryService.instance.postEnquiryFollowupWhatsappMessage(
            data?.data
        );
        if (response.success && response.data) {
            // setSuccessMessagesDelivered(prev => [...prev, response.data ?? '']);
            editEnquiry({ data: data?.remarksData, id: data?.id });
            return response.data;
        } else {
            // setFailedMessagesDelivered(prev => [...prev,data.studentName])
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: sendWhatsappFollowupMessage,
        isLoading: isSendingWhatsappMessage,
        error: isWhatsappMessageError,
    } = useMutation(postFollowupMessage, {
        onSuccess: data => {
            // alert(data);
        },
        onError: error => {
            // alert('Failed to send whatsapp message. please try again!');
            console.error(`On whatsapp reminder Error:`, error);
            throw error;
        },
    });

    const sendWhatsappMessage = () => {
        if (checkedEnquiryListItems.length === 0) {
            alert('Please select an enquiry to send message!');
        } else {
            enquiriesData?.data?.data
                ?.filter(item =>
                    checkedEnquiryListItems.map(checkedItem => checkedItem?.id).includes(item?.id)
                )
                .map(async item => {
                    const remarksInfo =
                        userState && userState.user && userState.user.username
                            ? 'Whatsapp message sent by ' + userState.user.username
                            : 'Whatsapp message sent';

                    const remarks = item?.attributes?.remarks || []; // Ensure remarks array is defined

                    const remarksData = {
                        data: {
                            remarks: [
                                ...remarks.map(remark => ({
                                    date: remark.date,
                                    enquiredBy:
                                        remark.enquiredBy &&
                                        remark.enquiredBy.data &&
                                        remark.enquiredBy.data.id,
                                    remarksInfo: remark.remarksInfo,
                                    status: remark.status,
                                })),
                                {
                                    date: new Date(),
                                    enquiredBy:
                                        userState && userState.user && userState.user.id
                                            ? userState.user.id
                                            : 0,
                                    remarksInfo,
                                    status: 'Enquiring',
                                },
                            ],
                        },
                    };
                    
                    const data = {
                        ownerName: userState?.user?.username || '',
                        academyName:
                            item?.attributes?.academies?.data
                                ?.map(a => a?.attributes?.name)
                                .join(', ') || '',
                        parentName: item?.attributes?.parentName || 'Sir/Madam',
                        contactNumber: item?.attributes?.contactNo,
                        academyEmailId: item?.attributes?.academies?.data[0]?.attributes?.email || '',
                        academyContactNumber: item?.attributes?.academies?.data[0]?.attributes?.contactNo || '',
                        enquiryId: item?.id
                    };
                    await sendWhatsappFollowupMessage({ data, remarksData, id: item?.id });
                });
        }
    };

    const postEnquiryFollowupEmail = async (data: {
        data: EnquiryFollowupEmailAttributes;
        remarksData: EnquiryPostDataWrapper;
        id: number;
    }): Promise<EnquiryFollowupEmailAttributes | string> => {
        const response = await EnquiryService.instance.postEnquiryFollowupEmail(data?.data);
        if (response.success && response.data) {
            // setSuccessEmailsDelivered(prev => [...prev, response.data ?? '']);
            editEnquiry({ data: data?.remarksData, id: data?.id });
            return response.data;
        } else {
            // setFailedEmailsDelivered(prev => [...prev,data.studentName])
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: sendEnquiryFollowupEmail,
        isLoading: isSendingEnquiryFollowupEmail,
        error: isEnquiryFollowupEmailError,
    } = useMutation(postEnquiryFollowupEmail, {
        onSuccess: data => {
            // alert(data);
        },
        onError: error => {
            // alert('Failed to send Email. please try again!');
            console.error(`On Email Reminder Error:`, error);
            throw error;
        },
    });

    const sendEmail = () => {
        if (checkedEnquiryListItems.length === 0) {
            alert('Please select an enquiry to send email!');
        } else {
            enquiriesData?.data?.data
                ?.filter(item =>
                    checkedEnquiryListItems.map(checkedItem => checkedItem?.id).includes(item?.id)
                )
                .map(async item => {
                    const remarksInfo =
                        userState && userState.user && userState.user.username
                            ? 'Email sent by ' + userState.user.username
                            : 'Email sent';

                    const remarks = item?.attributes?.remarks || []; // Ensure remarks array is defined

                    const remarksData = {
                        data: {
                            remarks: [
                                ...remarks.map(remark => ({
                                    date: remark.date,
                                    enquiredBy:
                                        remark.enquiredBy &&
                                        remark.enquiredBy.data &&
                                        remark.enquiredBy.data.id,
                                    remarksInfo: remark.remarksInfo,
                                    status: remark.status,
                                })),
                                {
                                    date: new Date(),
                                    enquiredBy:
                                        userState && userState.user && userState.user.id
                                            ? userState.user.id
                                            : 0,
                                    remarksInfo,
                                    status: 'Enquiring',
                                },
                            ],
                        },
                    };

                    const data = {
                        ownerName: userState?.user?.username || '',
                        academyName:
                            item?.attributes?.academies?.data
                                ?.map(a => a?.attributes?.name)
                                .join(', ') || '',
                        parentName: item?.attributes?.parentName || 'Sir/Madam',
                        ownerContactNo: userState?.user?.email || '',
                        ownerEmail: userState?.user?.email || '',
                        to: item?.attributes?.email || '',
                        disciplines: item?.attributes?.discipline,
                        academyPhoneNo:
                            item?.attributes?.academies?.data
                                ?.map(a => a?.attributes?.contactNo)
                                .join(', ') || '',
                    };
                    await sendEnquiryFollowupEmail({ data, remarksData, id: item?.id });
                });
        }
    };

    useEffect(() => {
        const hasAddedOrNotInterested = checkedEnquiryListItems.some(item => {
            return item?.attributes?.remarks?.some(
                remark => remark.status === 'Added Student' || remark.status === 'Not Interested'
            );
        });

        setIsSendEmailMessageDisabled(hasAddedOrNotInterested);
    }, [checkedEnquiryListItems]);

    const [editingEnquiryData,setEditingEnquiryData] = useState({} as EnquiryData);
    const [isEditEnquiryModalOpen,setIsEditEnquiryModalOpen] = useState(false);

    const handleEditBtnClick = (data: EnquiryData) => {
        setIsEditEnquiryModalOpen(true)
        setEditingEnquiryData(data);
        setEnquiryFormData({
            firstName: data?.attributes?.firstName || '',
            middleName: data?.attributes?.middleName || '',
            lastName: data?.attributes?.lastName || '',
            parentName: data?.attributes?.parentName || '',
            contactNo: data?.attributes?.contactNo || '',
            email: data?.attributes?.email || '',
            academies: data?.attributes?.academies?.data?.map(a => a?.id) || [],
            discipline: disciplineData?.data?.data?.filter(d => data?.attributes?.discipline.toLocaleLowerCase().split(',')?.includes(d?.attributes?.name.toLocaleLowerCase())).map(d => d?.id) || [],
            classesInterested: data?.attributes?.classesInterested?.data?.map(c => c?.id) || [],
            isDemoChecked: data?.attributes?.demoDateAndTime ? true : false,
            remarks: data?.attributes?.remarks.find(r => r.status === 'Enquiring')?.remarksInfo || '',
            demoDate: data?.attributes?.demoDateAndTime ? new Date(data?.attributes?.demoDateAndTime).toISOString()?.split('T')[0] : null,
            demoTime: data?.attributes?.demoDateAndTime ? new Date(data?.attributes?.demoDateAndTime).toLocaleTimeString() : null,
        })
    }

    const putEnquiry = async (
        data: EnquiryPostDataWrapper
    ): Promise<ApiResponse<EnquiryData>> => {
        const response = await EnquiryService.instance.putEnquiry(data, editingEnquiryData?.id);
        if (response.success) {
            return response;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateExistingEnquiry,
        isLoading: isEnquiryUpdating,
        isSuccess: isEnquiryUpdated,
    } = useMutation(putEnquiry, {
        onSuccess: data => {
            alert('Updated Enquiry Successfully!');
            setIsEditEnquiryModalOpen(false);
            setEditingEnquiryData({} as EnquiryData);
            setEnquiryFormData(initialEnquiryFormData);
            queryClient.invalidateQueries(ENQUIRY_QUERY);
        },
        onError: error => {
            alert('Failed to add enquiry! Please try again');
        },
    });

    const handleEditEnquiry = async (e: React.FormEvent) => {
        e.preventDefault();

        const body: EnquiryPostDataWrapper = {
            data: {
                firstName: enquiryFormData.firstName,
                middleName: enquiryFormData.middleName,
                lastName: enquiryFormData.lastName,
                parentName: enquiryFormData.parentName,
                contactNo: parseInt(enquiryFormData.contactNo),
                email: enquiryFormData.email === '' ? null : enquiryFormData.email,
                discipline: disciplineData?.data?.data
                    ?.filter(item => enquiryFormData?.discipline?.includes(item.id))
                    ?.map(data => data?.attributes?.name)
                    .join(','),
                academies: enquiryFormData.academies,
                classesInterested: enquiryFormData.classesInterested,
                demoDateAndTime: enquiryFormData.isDemoChecked ? new Date(`${enquiryFormData.demoDate}T${enquiryFormData.demoTime}:00`).toISOString() : null,
            },
        };

        updateExistingEnquiry(body);
    };

    return {
        isfilterModalOpen,
        handleFilterModalClose,
        isNewEnquiryModalOpen,
        handleNewEnquiryModalClose,
        handleAddEnquiryBtnClick,
        mapAcademiesToOptions,
        mapDiciplinesOfSelectedAcademiesToOptions,
        mapClassesOfSelectedDisciplinesToOptions,
        handleInputChange,
        enquiryFormData,
        handleAddNewEnquiry,
        enquiriesData: filteredDateEnquiries,
        handleNotInterestedClick,
        expandedEnquiryItem,
        handleEnquiryItemExpandCollapse,
        enquiryAcademyListExpanded,
        enquiryDisciplineListExpanded,
        enquiryClassListExpanded,
        handleEnquiryInfoListExpandClick,
        handleNewStudentModalClose,
        isNewStudentModalOpen,
        switchTab,
        handleStudentInputChange,
        studentFormData,
        activeTab,
        mapClassesToOptions,
        handleProfileImgChange,
        isProfileImagePosting,
        profileImageInputKey,
        handleAddNewStudent,
        handleAddNewStudentBtnClick,
        handleAddRemarks,
        remarksText,
        handleEditEnquiry,
        handleEditBtnClick,
        handleRetRemarksTextChange,
        state,
        dispatch,
        handleEditEnquiryModalClose,
        isEnquiriesFetchSuccess,
        itemsPerPage,
        indexOfLastItem,
        indexOfFirstItem,
        currentPage: state.currentPage,
        total: filteredDisciplines?.length ?? 0,
        isSortOptionsVisible,
        handleSortDropBtnClick,
        selectedSortOption,
        handleSortOptionChange,
        visibleFilter,
        toggleVisibleFilter,
        selectedStatusForFilter,
        onStatusFilterSelect,
        handleEnquiryListItemCheck,
        handleAllListSelect,
        allListItemsSelected,
        sendWhatsappMessage,
        isSendEmailMessageDisabled,
        sendEmail,
        isNewEnquiryAdding,
        isEnquiryEditing,
        isNewStudentAdding,
        userState,
        sortDropdownRef,
        filterDropdownRef,
        currentEnquiryData,
        isEditEnquiryModalOpen,
        isEnquiryUpdating,
        handleFilterModalOpen,
        selectedDemoDate,
        onFilterDateSelect,
        disciplineData: disciplineData?.data?.data,
        selectedDisciplineForFilter,
        onDisciplineFilterSelect
    };
};

export default EnquiresViewModel;
