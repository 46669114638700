export type AnnouncementState = {
    openTab: number;
};

export type AnnouncementAction =
    | { type: 'setOpenTab'; payload: number }
    | { type: 'setCurrentPage'; payload: number };

export const announcementReducer = (state: AnnouncementState, action: AnnouncementAction) => {
    switch (action.type) {
        case 'setOpenTab':
            state.openTab = action.payload;
            break;
        case 'setCurrentPage':
            return { ...state, currentPage: action.payload };
        default:
            return state;
    }
};
