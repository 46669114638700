export const CLASS_DAYS_LIST: {
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    value: number;
    label: string;
}[] = [
    {
        value: 1,
        day: 'monday',
        label: 'Monday',
    },
    {
        value: 2,
        day: 'tuesday',
        label: 'Tuesday',
    },
    {
        value: 3,
        day: 'wednesday',
        label: 'Wednesday',
    },
    {
        value: 4,
        day: 'thursday',
        label: 'Thursday',
    },
    {
        value: 5,
        day: 'friday',
        label: 'Friday',
    },
    {
        value: 6,
        day: 'saturday',
        label: 'Saturday',
    },
    {
        value: 7,
        day: 'sunday',
        label: 'Sunday',
    },
];
