import React from 'react'
import style from './style.module.css';
import { FitnessTestConductedData } from '../../../../models/progress-tracker/fitness-test-conducted-data';
import { PARAMETER_OPTIONS } from '../add-fitness-test-form/data';
import { StudentData } from '../../../../models/student/student-data';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import ArrowRight from '../../../../assets/icons/chevron-right.png';
import EditIcon from '../../../../assets/icons/edit.png';
import { calculateAge, calculateAgeInNumber, getFormattedDate } from '../../../../utils/helpers/helpers';
import { FieldsFormDataType, GridColumnType, GridRowType } from '../../../view-models/actions-view-models/record-fitness-test-view-model';
import { StudentFitnessRecordData } from '../../../../models/progress-tracker/student-fitness-record-data';
import GridIcon from '../../../../assets/icons/grid.png';
import DefaultUiIcon from '../../../../assets/icons/fitness-test-ui.png';
import 'react-data-grid/lib/styles.css';
import DataGrid  from 'react-data-grid';
import TimeInput from '../../bits/time-input';

type RecordFitnessTestFormProps = {
  handleCloseModal: () => void
  conductingTestData: FitnessTestConductedData;
  participatingStudents: StudentData[];
  testRecordsPendingStudents: number[];
  testRecordsSubmittedStudents: number[];
  activeStudentRecording: number;
  handleSetPendingRecordToActive: (studentId: number) => void
  progress: number;
  handleRecordSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  carousalPreviousIndex: number;
  carousalNextIndex: number;
  handleCarousalNextClick: () => void;
  handleCarousalPreviousClick: () => void;
  handleFieldsInputChange: (field: string, value: number) => void;
  fieldsFormData: FieldsFormDataType;
  submittedRecords: StudentFitnessRecordData[];
  handleEditSubmittedRecord: (studentId: number) => void;
  formView: 'DEFAULT' | 'GRID';
  handleViewSwitch: () => void;
  gridColumns: GridColumnType;
  gridRows: GridRowType[];
  isPublished: boolean;
  handlePublishFitnessTestConducted: () => void;
  isActiveStudentSkipChecked: boolean;
  handleSkipCheckboxChange: () => void;
  getMinMaxAgeOfAllTests: () => { name: string, maxAge: number, minAge: number }[]
}

const RecordFitnessTestForm = ({
  handleCloseModal,
  conductingTestData,
  participatingStudents,
  testRecordsPendingStudents,
  testRecordsSubmittedStudents,
  activeStudentRecording,
  handleSetPendingRecordToActive,
  progress,
  handleRecordSubmit,
  carousalPreviousIndex,
  carousalNextIndex,
  handleCarousalNextClick,
  handleCarousalPreviousClick,
  handleFieldsInputChange,
  fieldsFormData,
  handleEditSubmittedRecord,
  submittedRecords,
  formView,
  handleViewSwitch,
  gridColumns,
  isPublished,
  gridRows,
  handlePublishFitnessTestConducted,
  isActiveStudentSkipChecked,
  handleSkipCheckboxChange,
  getMinMaxAgeOfAllTests
}: RecordFitnessTestFormProps) => {
  
  return (
    <div className='h-screen max-h-screen overflow-hidden w-screen p-2 flex flex-col gap-2'>
      <div className='grid grid-cols-6 rounded bg-slate-50 px-4 shadow'>
        <div className='flex items-center justify-start'>
          {!isPublished &&
            <div 
              className='px-2 flex items-center gap-2 py-1 shadow-sm border rounded border-gray-300 cursor-pointer active:bg-slate-100 active:scale-95 transition-all' 
              onClick={handleViewSwitch}
            >
              <img 
                src={formView === 'DEFAULT' ? GridIcon : DefaultUiIcon}
                className='w-8 h-8'
              />
              <div className='font-semibold text-gray-600'>{formView === 'DEFAULT' ? 'Grid View' : 'UI View'}</div>
            </div>
          }
        </div>
        <div className='col-span-4 text-2xl font-semibold text-slate-500 text-center py-2'>
          Recording results for <span className='text-blue-500 drop-shadow-lg'>{conductingTestData?.attributes?.template?.data?.attributes?.name}</span> Fitness test
        </div>
        <div className='flex items-center justify-end text-slate-600 font-semibold'>{getFormattedDate(new Date())}</div>
      </div>

      {(formView === 'DEFAULT' && !isPublished) ? (
        <>
          <div className="relative h-2 my-4 mx-8 flex-shrink-0">
            <div className="bg-slate-300 rounded h-2 w-full absolute"></div>
            <div
              className={`bg-green-500 rounded h-2 absolute z-10 transition-all animate-pulseGlow`}
              style={{
                width: `${progress}%`
              }}
            ></div>
            <div 
              className="bg-green-500 rounded-full h-[36px] w-[36px] absolute z-10 text-[10px] text-white flex items-center justify-center transition-all"
              style={{ left: `calc(${progress}% - 18px)`, top: '-175%' }}
            >
              {progress.toFixed(0)}%
            </div>
          </div>

          <div className='flex-grow flex gap-4 overflow-hidden relative'>
            <div className='absolute top-0 left-0 transition-all -translate-x-full sm:translate-x-0 sm:static w-full sm:w-[25%] h-full rounded border-2 border-gray-400 flex flex-col gap-1 p-2'>
              <div className='text-lg font-semibold text-gray-700 py-2 text-center border-2 border-gray-400 bg-slate-200 rounded mb-2'>
                Submission Pending: {participatingStudents.filter(student => testRecordsPendingStudents.includes(student?.id))?.length}
              </div>
              <div className='flex flex-col gap-1 overflow-y-auto thin-scrollbar'>
                {participatingStudents?.filter(student => testRecordsPendingStudents.includes(student?.id))?.map((student, index) => (
                  <div 
                    key={student?.id} 
                    onClick={() => handleSetPendingRecordToActive(student?.id)}
                    className='bg-slate-200 rounded p-1 h-12 flex items-center gap-2 w-full] cursor-pointer px-2'
                  >
                    <div className='h-10 w-10 bg-gray-600 rounded-full overflow-hidden'>
                        <img 
                            src={student?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                            className='h-full w-full object-contain'
                        />
                    </div>
                    <div className='flex-grow'>
                        <div className='text-sm font-semibold text-slate-800 truncate'>{`${student?.attributes?.firstName} ${student?.attributes?.middleName ?? ''} ${student?.attributes?.lastName ?? ''}`}</div>
                        <div className='text-xs text-slate-700'>Age: {calculateAge(new Date(student?.attributes?.dob))}</div>
                    </div>
                    <div className=''>
                      {activeStudentRecording === student?.id ? (
                        <div className='h-4 w-4 rounded-full bg-green-300 mr-2'></div>
                      ) : (
                          <img
                              src={ArrowRight}
                              className='h-8  transition-all active:scale-95'
                          />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='w-full sm:w-[50%] h-full rounded border-2 border-gray-400 sm:p-4 p-2 flex gap-6 flex-col items-center justify-center overflow-hidden'>
              {(testRecordsPendingStudents.length > 0 || activeStudentRecording) ? (
                <>
                  <div className='text-lg font-semibold text-gray-700'>Total Participants: {participatingStudents?.length}</div>
                  <div className='flex items-center justify-center'>
                    {testRecordsPendingStudents.length > 1 && (
                      <div className='w-[240px] h-[45px] sm:w-[360px] sm:h-[70px] rounded bg-slate-100 border-2 border-slate-300 scale-75 opacity-25 flex gap-2 items-center translate-x-1/2'>
                        <div className='h-8 w-8 sm:h-10 sm:w-10 bg-gray-600 rounded-full overflow-hidden'>
                            <img 
                                src={participatingStudents[carousalPreviousIndex]?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                                className='h-full w-full object-contain'
                            />
                        </div>
                        <div className='flex-grow'>
                            <div className='text-sm font-semibold text-slate-800 truncate'>
                              {`${participatingStudents[carousalPreviousIndex]?.attributes?.firstName} ${participatingStudents[carousalPreviousIndex]?.attributes?.middleName ?? ''} ${participatingStudents[carousalPreviousIndex]?.attributes?.lastName ?? ''}`}
                            </div>
                            <div className='text-xs text-slate-700'>
                              Age: {calculateAge(new Date(participatingStudents[carousalPreviousIndex]?.attributes?.dob ?? ''))}
                            </div>
                        </div>
                      </div>
                    )}
                    <div className='flex gap-2 items-center z-[10]'>
                      {testRecordsPendingStudents.length > 1 && (
                        <img 
                          src={ArrowRight} 
                          className='h-8 rotate-180 transition-all active:scale-95 cursor-pointer'
                          onClick={handleCarousalPreviousClick}
                        />
                      )}
                      <div className='w-[240px] h-[45px] sm:w-[360px] sm:h-[70px] rounded bg-slate-100 border-2 border-slate-300 flex items-center gap-4 px-4'>
                          <div className='h-8 w-8 sm:h-10 sm:w-10 bg-gray-600 rounded-full overflow-hidden'>
                              <img 
                                  src={participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                                  className='h-full w-full object-contain'
                              />
                          </div>
                          <div className='flex-grow'>
                              <div className='text-sm font-semibold text-slate-800 truncate'>
                                {`${participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.firstName} ${participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.middleName ?? ''} ${participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.lastName ?? ''}`}
                              </div>
                              <div className='text-xs text-slate-700'>
                                Age: {calculateAge(new Date(participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.dob ?? ''))}
                              </div>
                          </div>
                          {submittedRecords?.some(record => record?.attributes?.student?.data?.id === activeStudentRecording) && (
                            <div className='text-xs px-2 py-1 rounded-lg bg-orange-300'>Editing</div>
                          )}
                      </div>
                      {testRecordsPendingStudents.length > 1 && (
                        <img 
                          src={ArrowRight} 
                          className='h-8  transition-all active:scale-95 cursor-pointer'
                          onClick={handleCarousalNextClick}
                        />
                      )}
                    </div>
                    {testRecordsPendingStudents.length > 1 && (
                      <div className='w-[240px] h-[45px] sm:w-[360px] sm:h-[70px] rounded bg-slate-100 border-2 border-slate-300 scale-75 opacity-25 flex gap-2 items-center -translate-x-1/2'>
                        <div className='h-8 w-8 sm:h-10 sm:w-10 bg-gray-600 rounded-full overflow-hidden'>
                            <img 
                                src={participatingStudents[carousalNextIndex]?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                                className='h-full w-full object-contain'
                            />
                        </div>
                        <div className='flex-grow'>
                            <div className='text-sm font-semibold text-slate-800 truncate'>
                              {`${participatingStudents[carousalNextIndex]?.attributes?.firstName} ${participatingStudents[carousalNextIndex]?.attributes?.middleName ?? ''} ${participatingStudents[carousalNextIndex]?.attributes?.lastName ?? ''}`}
                            </div>
                            <div className='text-xs text-slate-700'>
                              Age: {calculateAge(new Date(participatingStudents[carousalNextIndex]?.attributes?.dob ?? ''))}
                            </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <form className='w-full flex flex-col gap-2' onSubmit={handleRecordSubmit}>
                    {!isActiveStudentSkipChecked && (
                      conductingTestData?.attributes?.template?.data?.attributes?.fieldsRequired?.map((field, index) => (
                        <div key={index} className='bg-slate-200 rounded p-1 px-4 border border-slate-300 flex items-center gap-6'>
                          <div className='text-slate-500 font-semibold w-[60%] flex items-center gap-1'>
                            <div 
                                className='w-10 h-10 flex justify-center items-center rounded'
                            >
                                {PARAMETER_OPTIONS.find(option => option.value === field.parameter)?.icon}
                            </div>
                            {field.label}
                          </div>
                          {(field?.label === 'Height' || field?.label === 'Weight' || 
                            (calculateAgeInNumber(new Date(participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.dob ?? '')) <= 
                            (getMinMaxAgeOfAllTests()?.find(item => item.name === field?.label)?.maxAge ?? Infinity) &&
                            calculateAgeInNumber(new Date(participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.dob ?? '')) >= 
                            (getMinMaxAgeOfAllTests()?.find(item => item.name === field?.label)?.minAge ?? -Infinity))) ? (
                            <div className='grid grid-cols-2 items-center w-[40%] rounded bg-slate-100 px-2 relative'>
                              {field?.isMandatory &&
                                <div 
                                  className={`${(fieldsFormData[field?.label] === 0 || fieldsFormData[field?.label] === null || !Object.prototype.hasOwnProperty.call(fieldsFormData, field?.label)) ? 'bg-red-200' : 'bg-green-200'} rounded-full h-3 w-3 absolute -top-1 -left-1`}
                                ></div>
                              }
                              {field?.unit === 'MINUTE' || field?.unit === 'SECOND' ? (
                                <>
                                  <div className='flex justify-end px-2'>
                                    <TimeInput 
                                      id={`FitnessTestRecordInput${field?.label}`}
                                      value={fieldsFormData[field?.label] ?? 0}
                                      onChange={(value) => handleFieldsInputChange(field?.label, Number(value))}
                                      timeFormat='MINUTE'
                                    />
                                  </div>
                                  <label htmlFor={`FitnessTestRecordInput${field?.label}`} className='cursor-pointer m-0 text-[16px] font-normal text-slate-600'>{field?.unit}S</label>
                                </>
                              ) : (
                                <>
                                  <input
                                    className='rounded w-full bg-slate-100 border-0 focus:ring-0 text-right' 
                                    type='number' 
                                    id={`FitnessTestRecordInput${field?.label}`}
                                    required={field?.isMandatory ?? false}
                                    value={fieldsFormData[field?.label] === 0 ? '' : fieldsFormData[field?.label] ?? ''}
                                    onChange={(e) => handleFieldsInputChange(field?.label, Number(e.target.value))}
                                  />
                                  <label htmlFor={`FitnessTestRecordInput${field?.label}`} className='cursor-pointer m-0 text-[16px] font-normal text-slate-600'>{field?.unit}S</label>
                                </>
                              )}
                            </div>
                          ) : (
                            <div className='rounded w-[40%] py-2 text-center font-semibold bg-red-200 text-gray-600'>Age restricted</div>
                          )}
                        </div>
                      ))
                    )}

                    <div className='flex gap-2 justify-center items-center p-2 rounded border border-gray-200 shadow-md my-2'>
                      <input
                        type='checkbox'
                        className='w-5 h-5 rounded text-gray-500 focus:ring-0'
                        checked={isActiveStudentSkipChecked}
                        onChange={handleSkipCheckboxChange}
                      />
                      <div className='text-slate-600 font-semibold'>
                        Unable to take test for {`${participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.firstName} ${participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.middleName ?? ''} ${participatingStudents?.find(student => student?.id === activeStudentRecording)?.attributes?.lastName ?? ''}`}?
                      </div>
                    </div>
                    
                    <button 
                      type='submit'
                      className='w-full py-2 bg-slate-400 rounded text-white text-center font-semibold transition-all active:scale-95 cursor-pointer'
                    >
                      {isActiveStudentSkipChecked ? 'Skip' : submittedRecords?.some(record => record?.attributes?.student?.data?.id === activeStudentRecording) ? 'Update Record' : 'Submit Record'}
                    </button>
                  </form>
                  

                  {testRecordsPendingStudents.length === 0 && (
                    <div className='flex justify-end'>
                      <div 
                        className='flex items-center justify-end py-2 px-4 text-slate-500 border rounded border-gray-500 gap-2 cursor-pointer active:scale-95 transition-all'
                        onClick={() => handleEditSubmittedRecord(0)}
                      >
                        Continue to Publish
                        <img
                          src={ArrowRight}
                          className='h-6 w-6'
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className='flex flex-col items-center'>
                  <div className='text-xl text-slate-500 font-semibold text-center'>Records for all students have been submitted!</div>
                  <hr className='mt-2 mb-4 border-2 border-gray-300 w-[30%] shadow-md' />
                  <div className=' text-slate-400 font-semibold text-center'>Records can be edited until 10:00 PM today. After this time, they will be automatically published</div>
                  <div className='group mt-4'>
                    <div 
                      className='font-semibold rounded bg-gray-400 px-6 py-2 text-center text-white cursor-pointer active:scale-95 transition-all'
                      onClick={handlePublishFitnessTestConducted}
                    >
                      Publish
                    </div>
                    <div className='text-xs text-slate-500 text-center group-hover:scale-125 group-hover:translate-y-1 transition-all'>Note: Once the record is published, it cannot be edited!</div>
                  </div>
                </div>
              )}
            </div>

            <div className='absolute top-0 left-0 transition-all translate-x-full sm:translate-x-0 sm:static w-full sm:w-[25%] h-full rounded border-2 border-gray-400 flex-col gap-1 p-2'>
              <div className='text-lg font-semibold text-gray-700 py-2 text-center border-2 border-gray-400 bg-slate-200 rounded mb-2'>
                Records Submitted: {participatingStudents?.filter(student => testRecordsSubmittedStudents.includes(student?.id))?.length}
              </div>
              <div className='flex flex-col gap-1 overflow-y-auto thin-scrollbar'>
                {participatingStudents?.filter(student => testRecordsSubmittedStudents.includes(student?.id))?.map((student, index) => (
                  <div 
                    key={student?.id} 
                    className='bg-slate-200 rounded p-1 h-12 flex items-center gap-2 w-full] cursor-pointer px-2' 
                    onClick={() => handleEditSubmittedRecord(student?.id)}
                  >
                    <div className='h-10 w-10 bg-gray-600 rounded-full overflow-hidden'>
                        <img 
                            src={student?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                            className='h-full w-full object-contain'
                        />
                    </div>
                    <div className='flex-grow'>
                        <div className='text-sm font-semibold text-slate-800 truncate flex gap-1 items-center'>
                          {`${student?.attributes?.firstName} ${student?.attributes?.middleName ?? ''} ${student?.attributes?.lastName ?? ''}`}
                          {submittedRecords
                            ?.find(rec => rec?.attributes?.student?.data?.id === student?.id)?.attributes?.testResults
                            ?.every(result => !('value' in result) || result.value === 0) && (
                              <div className='text-[10px] text-slate-800 bg-red-300 rounded-lg px-2 pb-[1px]'>skipped</div>
                          )}
                        </div>
                        <div className='text-xs text-slate-700'>Age: {calculateAge(new Date(student?.attributes?.dob))}</div>
                    </div>
                    <div className=''>
                      {activeStudentRecording === student?.id ? (
                        <div className='h-4 w-4 rounded-full bg-green-300 mr-2'></div>
                      ) : (
                          <img
                              src={EditIcon}
                              className='h-5 transition-all active:scale-95'
                          />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='flex-grow'>
          <DataGrid 
            columns={gridColumns} 
            rows={gridRows} 
            
            className={`rdg-light rounded border border-gray-400 h-full`} 
          />
        </div>
      )}

      <div 
        className='px-4 py-2 bg-slate-200 rounded cursor-pointer text-center font-semibold hover:bg-slate-300'
        onClick={handleCloseModal}
      >
        Close
      </div>
    </div>
  )
}

export default RecordFitnessTestForm