import { AcademyData } from '../../models/academy/academy-data';
import { FeePaymentData } from '../../models/fee/fee-payment-data';

// academies-reducer.ts
export type FeePaymentState = {
    openTab: number;
    academies: AcademyData[];
    disciplines: number[];
    pendingFee: FeePaymentData[];
    receivedFee: FeePaymentData[];
    queryString: string;
    currentPage: number;
};

export type FeePaymentAction =
    | { type: 'setOpenTab'; payload: number }
    | { type: 'setAcademies'; payload: AcademyData[] }
    // | {type: 'setDisciplines'; payload: number[]}
    | { type: 'setPendingFee'; payload: FeePaymentData[] }
    | { type: 'setReceivedFee'; payload: FeePaymentData[] }
    | { type: 'setQueryString'; payload: string }
    | { type: 'setCurrentPage'; payload: number };

export const feePaymentReducer = (state: FeePaymentState, action: FeePaymentAction) => {
    switch (action.type) {
        case 'setOpenTab':
            state.openTab = action.payload;
            break;
        case 'setAcademies':
            state.academies = action.payload;
            break;
        // case 'setDisciplines':
        //     state.disciplines = action.payload;
        //     break;
        case 'setPendingFee':
            state.pendingFee = action.payload;
            break;
        case 'setReceivedFee':
            state.receivedFee = action.payload;
            break;
        case 'setQueryString':
            state.queryString = action.payload;
            break;
        case 'setCurrentPage':
            return { ...state, currentPage: action.payload };
        default:
            return state;
    }
};
