// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_spinnerContainer__-Ww3u {

    position: absolute;

    left: 50%;

    top: 50%;

    --tw-translate-x: -50%;

    --tw-translate-y: -50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.style_overlay__yNkMg {

    position: fixed;

    top: 0px;

    left: 0px;

    bottom: 0px;

    right: 0px;

    z-index: 100001;

    background-color: #000000b8
}
`, "",{"version":3,"sources":["webpack://./src/view/components/bits/fullscreen-loader/style.module.css"],"names":[],"mappings":"AACI;;IAAA,kBAA4E;;IAA5E,SAA4E;;IAA5E,QAA4E;;IAA5E,sBAA4E;;IAA5E,sBAA4E;;IAA5E;AAA4E;;AAI5E;;IAAA,eAAqE;;IAArE,QAAqE;;IAArE,SAAqE;;IAArE,WAAqE;;IAArE,UAAqE;;IAArE,eAAqE;;IAArE;AAAqE","sourcesContent":[".spinnerContainer {\n    @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;\n}\n\n.overlay {\n    @apply fixed top-0 left-0 bottom-0 right-0  bg-[#000000b8] z-[100001];\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `style_spinnerContainer__-Ww3u`,
	"overlay": `style_overlay__yNkMg`
};
export default ___CSS_LOADER_EXPORT___;
