import { invokeApi } from '../utils/helpers/invoke-api';
import { AnnouncementDataListWrapper } from '../models/announcement/announcement-data-list-wrapper';
import ApiResponse from '../utils/types/api-response';
import { CertificationListWrapper } from '../models/certifications/certification-list-wrapper';
import { CertificationPostDataWrapper } from '../models/certifications/certification-post-data-wrapper';
import { CertificationPostData } from '../models/certifications/certification-post-data';
import { CertificationDataWrapper } from '../models/certifications/certification-data-wrapper';
import { StudentCertificateRecordListWrapper } from '../models/certifications/student-certificate-record-list-wrapper';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { StudentCertificateRecordPostDataWrapper } from '../models/certifications/student-certificate-record-post-data-wrapper';
import { StudentCertificateRecordPostData } from '../models/certifications/student-certificate-record-post-data';
import { StudentCertificateRecordDataWrapper } from '../models/certifications/student-certificate-record-data-wrapper';
import { CertificationEventListWrapper } from '../models/certifications/certification-event-list-wrapper';
import { CertificationEventPostData } from '../models/certifications/certification-event-post-data';
import { CertificationEventDataWrapper } from '../models/certifications/certification-event-data-wrapper';

export class CertificationService {
    private static _instance: CertificationService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new CertificationService());
    }

    public getCertificationsOfAcademyAndDiscipline = async (academy: number,discipline: number): Promise<ApiResponse<CertificationListWrapper>> => {
        return invokeApi<null, CertificationListWrapper>({
            route: `certifications?populate=*&filters[academy][id]=${academy}&filters[discipline][id]=${discipline}&sort[0]=level`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getCertificationEventsOfAcademyAndDiscipline = async (academy: number,discipline: number): Promise<ApiResponse<CertificationEventListWrapper>> => {
        return invokeApi<null, CertificationEventListWrapper>({
            route: `certification-events?populate=*&filters[academy][id]=${academy}&filters[discipline][id]=${discipline}&sort[0]=date:desc`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public addCertification = async (data: CertificationPostData) => {
        return invokeApi<CertificationPostDataWrapper, CertificationDataWrapper>({
            route: `certifications`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public addCertificationEvent = async (data: CertificationEventPostData) => {
        return invokeApi<{data: CertificationEventPostData}, CertificationEventDataWrapper>({
            route: `certification-events`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public putCertificationEvent = async (data: CertificationEventPostData,id: number) => {
        return invokeApi<{data: CertificationEventPostData}, CertificationEventDataWrapper>({
            route: `certification-events/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public updateCertification = async (data: CertificationPostData, id: number) => {
        return invokeApi<CertificationPostDataWrapper, CertificationDataWrapper>({
            route: `certifications/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public getAllStudentsOfCertification = async (id: number): Promise<ApiResponse<StudentCertificateRecordListWrapper>> => {
        return invokeApi<null, StudentCertificateRecordListWrapper>({
            route: `student-certificate-records?populate=*&filters[certificationInfo][id]=${id}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAllStudentCertificationRecordsOfDiscipline = async (academy: number, discipline: number): Promise<ApiResponse<StudentCertificateRecordListWrapper>> => {
        return invokeApi<null, StudentCertificateRecordListWrapper>({
            route: `student-certificate-records?populate=*&filters[certificationInfo][academy][id]=${academy}&filters[certificationInfo][discipline][id]=${discipline}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAllStudentsOfSelectedAcademyAndDiscipline = async (academy: number,discipline: number): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=photo,classes&filters[academies][id]=${academy}&filters[classes][class_discipline][id]=${discipline}&sort[0]=firstName&pagination[pageSize]=1500`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewStudentCertificationRecord = async (data: StudentCertificateRecordPostData) => {
        return invokeApi<StudentCertificateRecordPostDataWrapper, StudentCertificateRecordDataWrapper>({
            route: 'student-certificate-records',
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };
}
