import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { announcementReducer, AnnouncementState } from '../reducers/announcement-reducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { AnnouncementPostDataWrapper } from '../../models/announcement/announcement-post-data-wrapper';
import { AnnouncementDataListWrapper } from '../../models/announcement/announcement-data-list-wrapper';
import Announcement from '../pages/announcement';
import { AnnouncementService } from '../../services/announcement-service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UploadService } from '../../services/upload-service';
import { ClassData } from '../../models/class/class-data';
import ClassService from '../../services/class-service';
import { HeaderMenuService } from '../../services/header-menu-service';
import { StudentData } from '../../models/student/student-data';
import { StudentService } from '../../services/student-service';
import { log } from 'console';
import {
    ANNOUNCEMENT_LIST_QUERY,
    CLASS_DETAILS_QUERY,
    STUDENT_DATA_QUERY,
} from '../../utils/constants/constants';

export interface formData {
    type: string;
    description: string;
    title: string;
    users: number[];
    file?: number;
    forClasses?: number[];
    forStudents?: number[];
    forAll?: number;
    selectedInput?: string;
}

const AnnouncementViewModel = () => {
    const [fileUploaded, setFileUploaded] = useState<number>(0);
    const queryClient = useQueryClient();
    const initialFormData: formData = {
        type: 'announcement',
        title: '',
        description: '',
        users: [],
        file: 0,
        forClasses: [],
        forStudents: [],
        forAll: 0,
        selectedInput: '',
    };

    const [formData, setFormData] = useState<formData>(initialFormData);
    const initialState: AnnouncementState = {
        openTab: 1,
    };

    const {
        isSuccess: isStudentListFetchSuccess,
        isLoading: isStudentListLoading,
        data: studentData,
    } = useQuery({
        queryKey: [STUDENT_DATA_QUERY],
        queryFn: async () => {
            try {
                const response = await AnnouncementService.instance.getStudentList(
                    userState.user?.id ?? 0
                );
                return response;
            } catch (error) {
                console.error('Error fetching student suggestion data: ', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const [state, dispatch] = useImmerReducer(announcementReducer, initialState);

    const userState = useSelector((state: RootState) => state.user);

    const setOpenTab = useCallback(
        (openTab: number) => {
            dispatch({
                type: 'setOpenTab',
                payload: openTab,
            });
        },
        [state.openTab]
    );

    const postAnnouncementData = async (
        data: AnnouncementPostDataWrapper
    ): Promise<AnnouncementDataListWrapper | undefined> => {
        const response = await AnnouncementService.instance.postNewAnnouncement(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewAnnouncement,
        isLoading: isNewAnnouncementLoading,
        isSuccess: isNewAnnouncementSuccess,
    } = useMutation(postAnnouncementData, {
        onSuccess: data => {
            queryClient.invalidateQueries(ANNOUNCEMENT_LIST_QUERY);
            alert('Announcement sent succesfully');
        },
        onError: error => {
            alert('Failed to add details. Please try again.');
        },
    });

    const {
        isSuccess: isAnnouncementListFetchSuccess,
        isLoading: isAnnouncementLoading,
        data: announcementData,
    } = useQuery({
        queryKey: [ANNOUNCEMENT_LIST_QUERY],
        queryFn: async () => {
            try {
                const response = await AnnouncementService.instance.getAnnouncementList(userState.user?.id ?? 0);
                return response;
            } catch (error) {
                console.error('Error fetching student suggestion data: ', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
    });

    const handleFormInputChange = (
        field: string,
        value: string | number | number[] | undefined
    ) => {
        setFormData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const postAnnoucementFile = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.forEach(item => {
                        if (item.id) {
                            setFileUploaded(item.id);
                        }
                    });
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postAddAcademyImages: ${error}`);
            throw error;
        }
    };

    const {
        mutate: uploadAnnouncementFile,
        isLoading: isAcademyImagePosting,
        error: isAcademyImagePostingError,
    } = useMutation(postAnnoucementFile, {
        onSuccess: data => {
            queryClient.invalidateQueries();
            console.log('image Upload Successful');
        },
        onError: error => {
            // console.log(`On upload images Error`);
            alert('Failed to Upload');
            // Error actions
        },
    });

    const { data: classesData } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data) {
            return classesData?.data?.data?.filter(item=>item.attributes.status !== "INACTIVE")
            .map((item: ClassData) => ({
                value: item.id,
                label: item?.attributes?.class_name,
            }));
        }
        return [];
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const fileSizeInBytes = selectedFiles[0].size;
            const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to kilobytes
            if (fileSizeInKB > 10240) {
                alert('Image size exceeds 10MB. Please choose a smaller file.');
                return;
            }
            const formData = new FormData();
            formData.append(`files`, selectedFiles[0]);
            const data = formData;

            await uploadAnnouncementFile(data);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (formData.selectedInput === 'all') {
            await addNewAnnouncement({
                data: {
                    type: 'Announcement',
                    title: formData.title,
                    description: formData.description,
                    users: userState.user?.id !== undefined ? [userState.user.id] : [],
                    file: fileUploaded === 0 ? null : fileUploaded,
                    forAll: userState.user?.id,
                },
            });
        } else if (formData.selectedInput === 'class') {
            await addNewAnnouncement({
                data: {
                    type: 'Announcement',
                    title: formData.title,
                    description: formData.description,
                    users: userState.user?.id !== undefined ? [userState.user.id] : [],
                    file: fileUploaded === 0 ? null : fileUploaded,
                    forClasses: formData.forClasses,
                },
            });
        } else {
            await addNewAnnouncement({
                data: {
                    type: 'Announcement',
                    title: formData.title,
                    description: formData.description,
                    users: userState.user?.id !== undefined ? [userState.user.id] : [],
                    file: fileUploaded === 0 ? null : fileUploaded,
                    forStudents: formData.forStudents,
                },
            });
        }
        setFormData(initialFormData);
        setFileUploaded(0);
    };
    const [isExpanded, setIsExpanded] = useState<number>(-1);
    const handleViewClick = (id: number) => {
        if (isExpanded === id) {
            setIsExpanded(-1);
        } else {
            setIsExpanded(id);
        }
    };

    const mapStudentsToOptions = (): { value: number; label: string }[] | [] => {
        if (studentData?.data?.data) {
            return studentData?.data?.data?.map((item: StudentData) => ({
                value: item.id,
                label:
                    item?.attributes?.firstName +
                    ' ' +
                    (item?.attributes?.middleName || '') +
                    ' ' +
                    (item?.attributes?.lastName || ''),
            }));
        }
        return [];
    };

    const [isMinimizeBarHovered, setIsMinimizeBarHovered] = useState(false);
    const [isRecentAnnoncementsListVisible, setIsRecentAnnoncementsListVisible] = useState(true);

    const handleOpenCloseRecentAnnouncementSideBar = () => {
        setIsRecentAnnoncementsListVisible(prev => !prev);
    }

    return {
        state,
        setOpenTab,
        userState,
        dispatch,
        handleFileChange,
        fileUploaded,
        handleFormInputChange,
        handleSubmit,
        formData,
        isAcademyImagePosting,
        mapClassesToOptions,
        isExpanded,
        handleViewClick,
        mapStudentsToOptions,
        announcementData,
        isStudentListFetchSuccess,
        isMinimizeBarHovered,
        setIsMinimizeBarHovered,
        isRecentAnnoncementsListVisible,
        handleOpenCloseRecentAnnouncementSideBar,
        isNewAnnouncementLoading
    };
};

export default AnnouncementViewModel;
