import React from 'react';
import style from './style.module.css';
import { FeeAttribute } from '../../../../models/fee/fee-attribute';
import { AttendanceAttributesWraper } from '../../../../models/attendance/attendance-attributes-wrapper';
import { EditingFeeDetailsFormDataType } from '../../../view-models/fee-payment-viewmodel';
import { formatDateToInputDate } from '../../../../utils/helpers/helpers';
import { AcademyData } from '../../../../models/academy/academy-data';

type Props = {
    feePaymentAttribute: FeeAttribute;
    isFeeDue: boolean;
    leaveRecordData?: AttendanceAttributesWraper[];
    isFeeDetailsModalEditing: boolean;
    handleFeeDetailsEditBtnClick: () => void;
    editingFeeDetailsFormData: EditingFeeDetailsFormDataType;
    handleFeeDetailFormInputChange: (field: string, value: string | boolean) => void;
    handleFeeDetailsUpdate: () => void;
};

function FeeDetailsForm({
    feePaymentAttribute,
    isFeeDue,
    leaveRecordData,
    isFeeDetailsModalEditing,
    handleFeeDetailsEditBtnClick,
    editingFeeDetailsFormData,
    handleFeeDetailFormInputChange,
    handleFeeDetailsUpdate,
}: Props) {
    const feeRemindersSentCount = feePaymentAttribute?.fee_dates[0].feeRemindersSent.filter(
        item => item.isSuccess === true
    ).length;

    return (
        <div className={style.container}>
            {isFeeDetailsModalEditing ? (
                <div className={style.FeeInfoContainer}>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}></h1>
                        <h1 className={style.rightInfoBold}>
                            {feeRemindersSentCount > 0 &&
                                `${feeRemindersSentCount} reminders sent: Last on ${new Date(
                                    feePaymentAttribute?.fee_dates[0].feeRemindersSent.filter(
                                        item => item.isSuccess === true
                                    )[0]?.sentOn
                                )
                                    .toDateString()
                                    .slice(4)}`}
                            {feeRemindersSentCount === 0 && `0 reminders sent`}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Student Name</h1>
                        <h1 className={style.rightInfo}>
                            {feePaymentAttribute?.student?.data?.attributes?.firstName ?? ''}{' '}
                            {feePaymentAttribute?.student?.data?.attributes?.middleName ?? ''}{' '}
                            {feePaymentAttribute?.student?.data?.attributes?.lastName ?? ''}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Current Class Information</h1>
                        <h1 className={style.rightInfo}>
                            {feePaymentAttribute?.class?.data?.attributes?.class_name}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Last Received Amount in Rs</h1>
                        <div className={style.rightInfo}>
                            <input
                                type="number"
                                value={editingFeeDetailsFormData.received}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('received', e.target.value)
                                }
                                className={style.formInput}
                            />
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>
                            Balance Amount from previous payment in Rs
                        </h1>
                        <div className={style.rightInfo}>
                            <input
                                type="number"
                                value={editingFeeDetailsFormData.balance}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('balance', e.target.value)
                                }
                                className={style.formInput}
                            />
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Last Fees Payment Schedule</h1>
                        <div className={style.rightInfo}>
                            <select
                                value={editingFeeDetailsFormData.previousSchedule}
                                onChange={e =>
                                    handleFeeDetailFormInputChange(
                                        'previousSchedule',
                                        e.target.value
                                    )
                                }
                                className={style.formInput}
                            >
                                <option value="Monthly">Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Semi-Annually">Semi-Annually</option>
                                <option value="Annually">Annually</option>
                            </select>
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Number of Sessions in a Week</h1>
                        <div className={style.rightInfo}>
                            <select
                                value={editingFeeDetailsFormData.sessions}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('sessions', e.target.value)
                                }
                                className={style.formInput}
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </select>
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Fees start date</h1>
                        <div className={style.rightInfo}>
                            <input
                                type="date"
                                value={formatDateToInputDate(
                                    new Date(editingFeeDetailsFormData.startDate)
                                )}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('startDate', e.target.value)
                                }
                                className={style.formInput}
                            />
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Fees end date</h1>
                        <div className={style.rightInfo}>
                            <input
                                type="date"
                                value={formatDateToInputDate(
                                    new Date(editingFeeDetailsFormData.endDate)
                                )}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('endDate', e.target.value)
                                }
                                className={style.formInput}
                            />
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Fees payment date</h1>
                        <div className={style.rightInfo}>
                            <input
                                type="date"
                                value={formatDateToInputDate(
                                    new Date(editingFeeDetailsFormData.paymentDate)
                                )}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('paymentDate', e.target.value)
                                }
                                className={style.formInput}
                            />
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Mode of payment</h1>
                        <div className={style.rightInfo}>
                            <select
                                value={editingFeeDetailsFormData.modeOfPayment}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('modeOfPayment', e.target.value)
                                }
                                className={style.formInput}
                            >
                                <option value="cash">Cash</option>
                                <option value="upi">UPI</option>
                                <option value="internetBanking">Internet Banking</option>
                                <option value="others">Others</option>
                            </select>
                        </div>
                    </div>
                    {feePaymentAttribute?.academy?.data?.attributes?.bankAccounts &&
                        editingFeeDetailsFormData.modeOfPayment !== 'cash' && (
                            <div className={style.infoItem}>
                                <h1 className={style.leftInfo}>Received in Account</h1>
                                <div className={style.rightInfo}>
                                    <select
                                        value={editingFeeDetailsFormData.accReceivedIn}
                                        onChange={e =>
                                            handleFeeDetailFormInputChange(
                                                'accReceivedIn',
                                                e.target.value
                                            )
                                        }
                                        className={style.formInput}
                                    >
                                        <option value="">None</option>
                                        {feePaymentAttribute?.academy?.data?.attributes?.bankAccounts
                                            ?.split(',')
                                            .map((acc, index) => (
                                                <option
                                                    key={index}
                                                    value={acc}
                                                >
                                                    {acc}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        )}
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Message</h1>
                        <div className={style.rightInfo}>
                            <textarea
                                className={style.textareaInput}
                                value={editingFeeDetailsFormData.message}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('message', e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>
                            Fees to be paid for upcoming sessions in Rs
                        </h1>
                        <h1 className={style.rightInfo}>{feePaymentAttribute?.amount}</h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Notify parent about this transaction?</h1>
                        <div className={style.rightInfo}>
                            <input
                                type="checkbox"
                                checked={editingFeeDetailsFormData.sendEmail}
                                onChange={e =>
                                    handleFeeDetailFormInputChange('sendEmail', e.target.checked)
                                }
                                className={style.formInputCheckBox}
                            />
                        </div>
                    </div>
                    {isFeeDue &&
                        (leaveRecordData && leaveRecordData.length > 0 ? (
                            <div className="p-2 border border-gray-50">
                                <div>Leave records: {leaveRecordData.length}</div>
                                <div className="flex gap-1 flex-wrap">
                                    {[...leaveRecordData]
                                        .sort((a, b) => {
                                            const dateA = new Date(
                                                a.attributes.attendance_date
                                            ).getTime();
                                            const dateB = new Date(
                                                b.attributes.attendance_date
                                            ).getTime();
                                            return dateA - dateB;
                                        })
                                        .map(data => (
                                            <div
                                                key={data?.id}
                                                className="p-1 bg-slate-100 rounded"
                                            >
                                                {new Date(data?.attributes?.attendance_date)
                                                    .toDateString()
                                                    .slice(4)}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ) : (
                            <div className="p-2">No Leave records</div>
                        ))}

                    <button
                        type="submit"
                        className={style.feeUpdateButton}
                        onClick={handleFeeDetailsUpdate}
                    >
                        Update Fees Details
                    </button>
                </div>
            ) : (
                <div className={style.FeeInfoContainer}>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}></h1>
                        <h1 className={style.rightInfoBold}>
                            {feeRemindersSentCount > 0 &&
                                `${feeRemindersSentCount} reminders sent: Last on ${new Date(
                                    feePaymentAttribute?.fee_dates[0].feeRemindersSent.filter(
                                        item => item.isSuccess === true
                                    )[0]?.sentOn
                                )
                                    .toDateString()
                                    .slice(4)}`}
                            {feeRemindersSentCount === 0 && `0 reminders sent`}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Student Name</h1>
                        <h1 className={style.rightInfo}>
                            {feePaymentAttribute?.student?.data?.attributes?.firstName ?? ''}{' '}
                            {feePaymentAttribute?.student?.data?.attributes?.middleName ?? ''}{' '}
                            {feePaymentAttribute?.student?.data?.attributes?.lastName ?? ''}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Current Class Information</h1>
                        <h1 className={style.rightInfo}>
                            {feePaymentAttribute?.class?.data?.attributes?.class_name}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Last Received Amount in Rs</h1>
                        <h1 className={style.rightInfo}>
                            ₹{feePaymentAttribute?.fee_dates[0]?.amount}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>
                            Balance Amount from previous payment in Rs
                        </h1>
                        <h1 className={style.rightInfo}>{feePaymentAttribute?.balance ?? 0}</h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Last Fees Payment Schedule</h1>
                        <h1 className={style.rightInfo}>{feePaymentAttribute?.payment_schedule}</h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Number of Sessions in a Week</h1>
                        <h1 className={style.rightInfo}>{feePaymentAttribute?.sessions}</h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Fees start date</h1>
                        <h1 className={style.rightInfo}>
                            {new Date(feePaymentAttribute?.fee_dates[0]?.startDate)
                                .toDateString()
                                .slice(4)}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Fees end date</h1>
                        <h1 className={style.rightInfo}>
                            {new Date(feePaymentAttribute?.fee_dates[0]?.dueDate)
                                .toDateString()
                                .slice(4)}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Fees payment date</h1>
                        <h1 className={style.rightInfo}>
                            {new Date(feePaymentAttribute?.fee_dates[0]?.fee_date)
                                .toDateString()
                                .slice(4)}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Mode of payment</h1>
                        <h1 className={style.rightInfo}>
                            {feePaymentAttribute?.fee_dates[0].payment_mode}
                        </h1>
                    </div>
                    {feePaymentAttribute?.fee_dates[0].accReceivedIn && (
                        <div className={style.infoItem}>
                            <h1 className={style.leftInfo}>Received in Account</h1>
                            <h1 className={style.rightInfo}>
                                {feePaymentAttribute?.fee_dates[0].accReceivedIn}
                            </h1>
                        </div>
                    )}
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>Message</h1>
                        <h1 className={style.rightInfo}>
                            {feePaymentAttribute?.fee_dates[0].message}
                        </h1>
                    </div>
                    <div className={style.infoItem}>
                        <h1 className={style.leftInfo}>
                            Fees to be paid for upcoming sessions in Rs
                        </h1>
                        <h1 className={style.rightInfo}>{feePaymentAttribute?.amount}</h1>
                    </div>
                    {isFeeDue &&
                        (leaveRecordData && leaveRecordData.length > 0 ? (
                            <div className="p-4 border border-gray-50">
                                <div>Leave records: {leaveRecordData.length}</div>
                                <div className="flex gap-1 flex-wrap">
                                    {[...leaveRecordData]
                                        .sort((a, b) => {
                                            const dateA = new Date(
                                                a.attributes.attendance_date
                                            ).getTime();
                                            const dateB = new Date(
                                                b.attributes.attendance_date
                                            ).getTime();
                                            return dateA - dateB;
                                        })
                                        .map(data => (
                                            <div
                                                key={data?.id}
                                                className="p-1 bg-slate-100 rounded"
                                            >
                                                {new Date(data?.attributes?.attendance_date)
                                                    .toDateString()
                                                    .slice(4)}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ) : (
                            <div className="p-2">No Leave records</div>
                        ))}
                    <button
                        type="submit"
                        className={style.feeUpdateButton}
                        onClick={handleFeeDetailsEditBtnClick}
                    >
                        Update Fees Details
                    </button>
                </div>
            )}
        </div>
    );
}

export default FeeDetailsForm;
