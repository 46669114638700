type Option = {
    value: number;
    label: string;
};

const TechniqueJointOptions = [
    { value: 0, label: 'nose' },
    { value: 1, label: 'left eye (inner)' },
    { value: 2, label: 'left eye' },
    { value: 3, label: 'left eye (outer)' },
    { value: 4, label: 'right eye (inner)' },
    { value: 5, label: 'right eye' },
    { value: 6, label: 'right eye (outer)' },
    { value: 7, label: 'left ear' },
    { value: 8, label: 'right ear' },
    { value: 9, label: 'mouth (left)' },
    { value: 10, label: 'mouth (right)' },
    { value: 11, label: 'left shoulder' },
    { value: 12, label: 'right shoulder' },
    { value: 13, label: 'left elbow' },
    { value: 14, label: 'right elbow' },
    { value: 15, label: 'left wrist' },
    { value: 16, label: 'right wrist' },
    { value: 17, label: 'left pinky' },
    { value: 18, label: 'right pinky' },
    { value: 19, label: 'left index' },
    { value: 20, label: 'right index' },
    { value: 21, label: 'left thumb' },
    { value: 22, label: 'right thumb' },
    { value: 23, label: 'left hip' },
    { value: 24, label: 'right hip' },
    { value: 25, label: 'left knee' },
    { value: 26, label: 'right knee' },
    { value: 27, label: 'left ankle' },
    { value: 28, label: 'right ankle' },
    { value: 29, label: 'left heel' },
    { value: 30, label: 'right heel' },
    { value: 31, label: 'left foot index' },
    { value: 32, label: 'right foot index' },

    // Left hand landmarks
    { value: 33, label: 'left wrist' },
    { value: 34, label: 'left thumb (CMC)' },
    { value: 35, label: 'left thumb (MCP)' },
    { value: 36, label: 'left thumb (IP)' },
    { value: 37, label: 'left thumb (tip)' },
    { value: 38, label: 'left index finger (MCP)' },
    { value: 39, label: 'left index finger (PIP)' },
    { value: 40, label: 'left index finger (DIP)' },
    { value: 41, label: 'left index finger (tip)' },
    { value: 42, label: 'left middle finger (MCP)' },
    { value: 43, label: 'left middle finger (PIP)' },
    { value: 44, label: 'left middle finger (DIP)' },
    { value: 45, label: 'left middle finger (tip)' },
    { value: 46, label: 'left ring finger (MCP)' },
    { value: 47, label: 'left ring finger (PIP)' },
    { value: 48, label: 'left ring finger (DIP)' },
    { value: 49, label: 'left ring finger (tip)' },
    { value: 50, label: 'left pinky (MCP)' },
    { value: 51, label: 'left pinky (PIP)' },
    { value: 52, label: 'left pinky (DIP)' },
    { value: 53, label: 'left pinky (tip)' },

    // Right hand landmarks
    { value: 54, label: 'right wrist' },
    { value: 55, label: 'right thumb (CMC)' },
    { value: 56, label: 'right thumb (MCP)' },
    { value: 57, label: 'right thumb (IP)' },
    { value: 58, label: 'right thumb (tip)' },
    { value: 59, label: 'right index finger (MCP)' },
    { value: 60, label: 'right index finger (PIP)' },
    { value: 61, label: 'right index finger (DIP)' },
    { value: 62, label: 'right index finger (tip)' },
    { value: 63, label: 'right middle finger (MCP)' },
    { value: 64, label: 'right middle finger (PIP)' },
    { value: 65, label: 'right middle finger (DIP)' },
    { value: 66, label: 'right middle finger (tip)' },
    { value: 67, label: 'right ring finger (MCP)' },
    { value: 68, label: 'right ring finger (PIP)' },
    { value: 69, label: 'right ring finger (DIP)' },
    { value: 70, label: 'right ring finger (tip)' },
    { value: 71, label: 'right pinky (MCP)' },
    { value: 72, label: 'right pinky (PIP)' },
    { value: 73, label: 'right pinky (DIP)' },
    { value: 74, label: 'right pinky (tip)' },
];

export default TechniqueJointOptions;

export const AssigmentSubmissionFormatTypes = [
    { value: 'TEXT', label: 'Text' },
    { value: 'IMAGE', label: 'Image' },
    { value: 'VIDEO', label: 'Video' },
    { value: 'AUDIO', label: 'Audio' },
    { value: 'DOCUMENT', label: 'Document' },
]