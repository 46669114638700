import React from 'react';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import { StudentData } from '../../../../models/student/student-data';

interface GuestStudentAttendanceMarkFormProps {
    searchTerm: string;
    setIsSearchInputOnFocus: (value: boolean) => void;
    handlesearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isSearchInputOnFocus: boolean;
    isStudentDataFetchLoading: boolean;
    studentSuggestionListData?: StudentData[];
    onStudentSearchListItemClick: (student: StudentData) => void;
    selectedStudentsForAttendance: StudentData[];
    handleRemoveSelectedStduentForAttendance: (id: number) => void;
    handleMarkAttendance: () => void;
}

const GuestStudentAttendanceMarkForm = ({
    searchTerm,
    setIsSearchInputOnFocus,
    handlesearchInputChange,
    isSearchInputOnFocus,
    isStudentDataFetchLoading,
    studentSuggestionListData,
    onStudentSearchListItemClick,
    selectedStudentsForAttendance,
    handleRemoveSelectedStduentForAttendance,
    handleMarkAttendance,
}: GuestStudentAttendanceMarkFormProps) => {
    return (
        <div className="w-[85vw] sm:w-[600px] h-[80vh] sm:h-[400px] p-2 sm:p-4 flex flex-col">
            <div className="w-full relative">
                <input
                    placeholder="Student Name"
                    className="w-full rounded border-2 border-gray-500"
                    value={searchTerm}
                    onFocus={() => setIsSearchInputOnFocus(true)}
                    onBlur={() => setTimeout(() => setIsSearchInputOnFocus(false), 200)}
                    onChange={handlesearchInputChange}
                />
                {isSearchInputOnFocus && (
                    <div
                        className={`absolute w-full max-h-48 overflow-y-auto bg-white z-10 rounded-[4px] mt-1 border-2`}
                    >
                        <ul className="px-2 text-xs">
                            {isStudentDataFetchLoading ? (
                                <li className="inline-flex p-1 items-center gap-2">
                                    <div className="w-6 h-6 border-t-2 border-blue-500 border-solid border-opacity-50 rounded-full animate-spin items-center"></div>
                                    Loading...
                                </li>
                            ) : studentSuggestionListData?.length === 0 ? (
                                <li className="p-1 border-b-2 cursor-pointer min-h-[34px]">
                                    <div className="p-2">No student found</div>
                                </li>
                            ) : (
                                (studentSuggestionListData ?? []).map((item, index) => (
                                    <li
                                        className="p-1 border-b-2 cursor-pointer min-h-[34px]"
                                        onClick={() => {
                                            onStudentSearchListItemClick(item);
                                            setIsSearchInputOnFocus(false);
                                        }}
                                        key={index}
                                    >
                                        {item && item?.attributes && (
                                            <>
                                                <span>
                                                    <img
                                                        className="rounded-full h-8 w-8 inline mr-1 overflow-hidden"
                                                        src={
                                                            item?.attributes?.photo?.data
                                                                ?.attributes?.url ??
                                                            StudentProfileIcon
                                                        }
                                                        alt={`Profile image`}
                                                    />
                                                </span>
                                                <span>
                                                    {item?.attributes?.firstName +
                                                        ' ' +
                                                        (item?.attributes?.lastName
                                                            ? item?.attributes?.lastName
                                                            : '')}
                                                </span>
                                            </>
                                        )}
                                    </li>
                                ))
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <div className="w-full flex-grow rounded bg-slate-100 h-auto sm:h-[260px] flex flex-col gap-1 mt-4 p-2 thin-scrollbar overflow-y-auto">
                {selectedStudentsForAttendance.length === 0 && (
                    <div className="text-center text-gray-500 font-semibold text-xl h-full flex items-center justify-center">
                        No students selected
                    </div>
                )}
                {selectedStudentsForAttendance.map((item, index) => (
                    <div
                        key={item?.id}
                        className="w-full p-2 bg-slate-200 rounded flex items-center gap-4"
                    >
                        <img
                            src={
                                item?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon
                            }
                            className="h-12 w-12 rounded-full"
                        />
                        <div>{`${item.attributes.firstName} ${item.attributes.middleName ?? ''} ${item.attributes.lastName ?? ''}`}</div>
                        <div className="flex-grow flex justify-end">
                            <button
                                className="px-4 py-2 bg-red-200 rounded font-semibold text-gray-700"
                                onClick={() => handleRemoveSelectedStduentForAttendance(item?.id)}
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex w-full justify-center sm:justify-end mt-6">
                <button
                    className="px-4 py-2 bg-primary rounded font-semibold text-white"
                    onClick={handleMarkAttendance}
                >
                    Mark present for selected students
                </button>
            </div>
        </div>
    );
};

export default GuestStudentAttendanceMarkForm;
