import React from 'react';

const Services = () => {
    return (
        <div className="h-full">
            <div
                className="bg-[#F5F8FF] sm:items-center sm:flex sm:pb-20"
                id="Advantage"
            >
                <div className="container sm:w-[80%] mx-auto text-black z-1000 flex flex-col items-center">
                    <div className="text-3xl text-center font-semibold ">
                        We help your business grow faster.
                    </div>
                    <div className="text-xl text-center font-extralight mt-5">
                        Why juggle with multiple registers, apps or excel sheets?
                    </div>
                    <div className="text-xl text-center font-extralight">
                        We have your back while you spend more time training students.
                    </div>

                    <div className="mt-10 flex justify-around flex-col md:flex-row gap-2">
                        <div className="flex flex-col bg-white md:w-[30%] p-6 rounded gap-y-8">
                            <img
                                src="/graphics/services/Frame.png"
                                className="w-[3em]"
                                alt=""
                            />
                            <h1 className="text-xl font-bold">Secure Student Database</h1>
                            <p className="text-base font-extralight">
                                Search, filter and sort your students’ list. All data is secured by
                                Amazon Web Services.
                            </p>
                        </div>
                        <div className="flex flex-col bg-white md:w-[30%] p-4 rounded gap-y-8">
                            <img
                                src="/graphics/services/Frame2.png"
                                className="w-[3em]"
                                alt=""
                            />
                            <h1 className="text-xl font-bold">Revenue Management</h1>
                            <p className="text-base font-extralight">
                                Track your monthly revenues. Collect fees and send fee reminders
                                digitally.
                            </p>
                        </div>
                        <div className="flex flex-col bg-white md:w-[30%] p-4 rounded gap-y-8">
                            <img
                                src="/graphics/services/Frame3.png"
                                className="w-[3em]"
                                alt=""
                            />
                            <h1 className="text-xl font-bold">Attendance Management</h1>
                            <p className="text-base font-extralight">
                                Mark attendance of your students. Get insights on who has a higher
                                probability of dropping out.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-3xl p-3 text-center text-white text-2xl  bg-[#F6B40A] m-6 hover:bg-yellow-400 ">
                        <a href="#demo">Book Demo</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
