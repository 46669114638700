import React from 'react'
import BlogsListViewModel from '../../view-models/blog-list-viewmodel';
import { Routes } from '../../navigation/routes';
import Navbar from '../../components/landing-page-items/Navbar';
import Footer from '../../components/landing-page-items/Footer';
import { Helmet } from 'react-helmet-async';

const BlogsListPage = () => {
    const {
        isLoading,
        blogs,
        handleOpenBlogPost,
        user,
        navigate,
    } = BlogsListViewModel();

    return (
        <div className='h-screen flex flex-col'>
            <Helmet>
                <title>SpArts Blogs</title>
            </Helmet>
            <Navbar />
            {/* <div className='flex justify-between items-center p-6 md:px-16 fixed w-full backdrop-blur-md'>
                <div className='flex items-center gap-[70px]'>
                    <img 
                        src='/assets/images/sparts-logo.png'
                        className='h-10'
                    />
                    <div className='items-center gap-[42px] hidden md:flex'>
                        <div onClick={() => navigate(Routes.LandingPage)} className='text-xl font-semibold text-gray-600 cursor-pointer hover:text-gray-400 transition-all'>Home</div>
                        <div onClick={() => navigate(Routes.BlogsList)} className='text-xl font-semibold text-gray-600 cursor-pointer hover:text-gray-400 transition-all'>Blogs</div>
                        <div className='text-xl font-semibold text-gray-600 cursor-pointer hover:text-gray-400 transition-all'>About</div>
                        <div className='text-xl font-semibold text-gray-600 cursor-pointer hover:text-gray-400 transition-all'>Contact</div>
                    </div>
                </div>
                <div className='flex items-center'>
                    {user?.user?.username ? (
                        <div className='font-semibold px-3 py-1 rounded-lg text-slate-500 border-slate-500 border flex items-center gap-2'>
                            <img 
                                src='/assets/images/user.png'
                                className='invert'
                            />
                            {user?.user?.username}
                        </div>
                    ) : (
                        <button 
                            className='font-semibold text-[#3AB7FC] hover:text-white transition-all hover:bg-[#3AB7FC] rounded-lg px-4 py-2'
                            onClick={() => navigate(Routes.Login)}
                        >
                            Login
                        </button>
                    )}
                </div>
            </div> */}
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-6 md:px-16 mt-[88px]'>
                {blogs.map((blog) => (
                    <div 
                        key={blog?.id}
                        className='p-4 cursor-pointer rounded-md bg-slate-50 shadow-md shadow-slate-200'
                        onClick={() => handleOpenBlogPost(blog?.attributes?.url)}
                    >
                        <img 
                            src={blog?.attributes?.previewImage?.data?.attributes?.url}
                            className='w-full h-64 object-cover rounded-lg cursor-pointer'
                        />
                        <div className=' rounded-lg p-4 cursor-pointer'>
                            <div className='flex flex-col'>
                                <div className='text-xl font-semibold'>{blog?.attributes?.title}</div>
                                <div className='text-gray-500 font-semibold text-sm line-clamp-3'>{blog?.attributes?.description}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    )
}

export default BlogsListPage