export enum Routes {
    LandingPage = '/',
    Login = '/login',
    ResetPassword = '/reset-password',
    Dashboard = '/dashboard',
    Academies = '/academies',
    Enquires = '/enquires',
    Students = '/students',
    Settings = '/settings',
    UserManagement = '/user-management',
    FeePayment = '/fee-payment',
    ScheduleDetails = '/schedule-details',
    // ScheduleDetailsCal = '/schedules-details-cal',
    Schedule = '/schedule',
    NotFound = '*',
    // ScheduleDate = '/schedule/:date_here',
    ProgressTracker = '/progress-tracker',
    EnrollStudentFrom = '/form/enroll-student',
    Announcement = '/announcement',
    BlogsList='/blogs',
    Blog='/blog',
    LiveAttendance='/live-attendance',
    VirtualClass='/virtual-class',
}
