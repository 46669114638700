import React from 'react';
import style from './style.module.css';

const AccessDeniedPage = () => {
    return (
        <div className="h-full w-full flex items-center justify-center text-6xl text-red-300 font-bold">
            ACCESS DENIED!
        </div>
    );
};

export default AccessDeniedPage;
