import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { FitnessTestDetailsData } from '../../../models/progress-tracker/fitness-test-details-data';
import { StudentData } from '../../../models/student/student-data';
import { FitnessTestConductedData } from '../../../models/progress-tracker/fitness-test-conducted-data';
import { FITNESS_TEST_CONDUCTED_LIST_QUERY, FITNESS_TEST_CONDUCTING_DATA, FITNESS_TEST_CONDUCTING_STUDENT_RECORDS, FITNESS_TEST_VALUES_LIST } from '../../../utils/constants/constants';
import { ProgressTrackerService } from '../../../services/progress-tracker-service';
import { FitnessTestTemplateFields } from '../../../models/progress-tracker/fitness-test-details-attributes';
import { StudentFitnessRecordPostData } from '../../../models/progress-tracker/student-fitness-record-post-data';
import { StudentFitnessRecordDataWrapper } from '../../../models/progress-tracker/student-fitness-record-data-wrapper';
import { FitnessTestConductedPostData } from '../../../models/progress-tracker/fitness-test-conducted-postdata';
import { FitnessTestConductedDataWrapper } from '../../../models/progress-tracker/fitness-test-conducted-data-wrapper';


export type FieldsFormDataType = {
    [K in FitnessTestTemplateFields["label"]]: Extract<FitnessTestTemplateFields, { label: K }>["isMandatory"] extends true ? number : number | null;
};

export type GridColumnType = Array<{
    key: FitnessTestTemplateFields["label"];
    name: string;
    width?: number;
}>;

export type GridRowType = {
    [K in FitnessTestTemplateFields["label"]]: Extract<FitnessTestTemplateFields, { label: K }>["isMandatory"] extends true ? number | string : number | string | null;
};

const RecordFitnessTestViewModel = ({
    handleClose,
    selectedConductableTestId,
}: {
    handleClose: () => void;
    selectedConductableTestId: number;
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();
    const [formView, setFormView] = useState<'DEFAULT' | 'GRID'>('DEFAULT');
    const [preSelectedStudent, setPreSelectedStudent] = useState<number>();

    const handleViewSwitch = () => {
        setFormView((prev) => (prev === 'DEFAULT' ? 'GRID' : 'DEFAULT'));
    }
    
    const { isFetching: isConductingTestDataFetching, data: conductingTestData } = useQuery({
        queryKey: [FITNESS_TEST_CONDUCTING_DATA, selectedConductableTestId],
        queryFn: () => ProgressTrackerService.instance.getFitnessTestConductingData(selectedConductableTestId),
        refetchOnWindowFocus: false,
        enabled: selectedConductableTestId > 0,
    });

    const { isFetching: isConductingTestStudentRecordsFetching, data: conductingTestStudentRecords } = useQuery({
        queryKey: [FITNESS_TEST_CONDUCTING_STUDENT_RECORDS, selectedConductableTestId],
        queryFn: () => ProgressTrackerService.instance.getStudentFitnessRecordsOfTest(selectedConductableTestId),
        refetchOnWindowFocus: false,
        enabled: selectedConductableTestId > 0,
    });

    const { isFetching: isFitnessTestValuesListFetching, data: fitnessTestValuesList } = useQuery({
        queryKey: [FITNESS_TEST_VALUES_LIST],
        queryFn: () => ProgressTrackerService.instance.getFitnessTestValuesList(),
        refetchOnWindowFocus: false,
        enabled: true,
    });
    
    const fieldsArray = conductingTestData?.data?.data?.attributes?.template?.data?.attributes?.fieldsRequired;
    const initialFieldFormData: FieldsFormDataType = {
        ...fieldsArray?.reduce((acc, item) => ({ ...acc, [item.label]: item.isMandatory ? 0 : null }), {} as FieldsFormDataType),
    };
    const [fieldsFormData, setFieldsFormData] = useState<FieldsFormDataType>(initialFieldFormData);

    const handleFieldsInputChange = (field: string, value: number) => {
        setFieldsFormData({ ...fieldsFormData, [field]: value });
    };

    const [participatingStudents, setParticipatingStudents] = useState<StudentData[]>([]);
    const [testRecordsPendingStudents, setTestRecordsPendingStudents] = useState<number[]>([]);
    const [testRecordsSubmittedStudents, setTestRecordsSubmittedStudents] = useState<number[]>([]);
    const [activeStudentRecording, setActiveStudentRecording] = useState<number>(0);
    const [carousalPreviousIndex, setCarousalPreviousIndex] = useState<number>(0);
    const [carousalNextIndex, setCarousalNextIndex] = useState<number>(0);
    const [isActiveStudentSkipChecked, setIsActiveStudentSkipChecked] = useState(false);

    useEffect(() => {
        setIsActiveStudentSkipChecked(false)
    },[activeStudentRecording])

    const [gridColumns, setGridColumns] = useState<GridColumnType>([]);
    const [gridRows, setGridRows] = useState<GridRowType[]>([]);

    useEffect(() => {
        if(conductingTestData?.data?.data?.attributes?.participants && conductingTestData?.data?.data?.attributes?.participants?.data?.length){
            setParticipatingStudents(conductingTestData?.data?.data?.attributes?.participants?.data || [])
        }
        if(conductingTestData?.data?.data) {
            setGridColumns(
                [
                    {
                        key: 'serialNo',
                        name: 'S.No.',
                        width: 60,
                    },
                    {
                        key: 'studentName',
                        name: 'Student Name'
                    },
                    ...conductingTestData?.data?.data?.attributes?.template?.data?.attributes?.fieldsRequired?.map(field => ({
                        key: field.label,
                        name: `${field.label} (${field.unit.toLocaleLowerCase()})`
                    })) || []
                ]
            )
        }
    },[conductingTestData?.data?.data])

    useEffect(() => {
        if(participatingStudents?.length){
            setTestRecordsPendingStudents(conductingTestData?.data?.data?.attributes?.participants?.data?.map(student => student.id)?.filter(student => !testRecordsSubmittedStudents.includes(student)) || [])
            setActiveStudentRecording(conductingTestData?.data?.data?.attributes?.participants?.data?.filter(student => !testRecordsSubmittedStudents.includes(student?.id))[0]?.id || 0);

            setGridRows(
                participatingStudents.map((student,index) => ({
                    id: student?.id,
                    serialNo: index + 1,
                    studentName: `${student?.attributes?.firstName} ${student?.attributes?.middleName ?? ''} ${student?.attributes?.lastName ?? ''}`,
                }))
            )
        }
    },[participatingStudents])

    useEffect(() => {
        if(conductingTestStudentRecords?.data?.data?.length){
            setTestRecordsSubmittedStudents(conductingTestStudentRecords?.data?.data?.map(record => record?.attributes?.student?.data?.id) || [])

            setGridRows(prev => prev.map(student => {
                // Check if student.id is valid and exists in conductingTestStudentRecords
                if (typeof student.id === 'number' && conductingTestStudentRecords?.data?.data
                  ?.some(r => r?.attributes?.student?.data?.id === student.id)) {
                  
                  // Find the relevant record and transform the test results
                  const testResults = conductingTestStudentRecords?.data?.data
                    ?.find(r => r?.attributes?.student?.data?.id === student.id)?.attributes?.testResults;
              
                  // Ensure testResults is properly mapped to the GridRowType format
                  const updatedStudent = testResults?.reduce((acc, data) => {
                    acc[data.label] = data.value;
                    return acc;
                  }, { ...student }); // Start with existing student properties
              
                  return updatedStudent as GridRowType; // Ensure the result matches GridRowType
                }
              
                // Return the student unchanged if conditions are not met
                return student;
              }));
        }
    },[conductingTestStudentRecords?.data?.data])

    useEffect(() => {
        const currentIndex = participatingStudents.filter(s => testRecordsPendingStudents.includes(s?.id)).findIndex(student => student.id === activeStudentRecording);
        setCarousalPreviousIndex(currentIndex - 1 < 0 ? participatingStudents.filter(s => testRecordsPendingStudents.includes(s?.id)).length - 1 : currentIndex - 1);
        setCarousalNextIndex((currentIndex + 1) % participatingStudents.filter(s => testRecordsPendingStudents.includes(s?.id)).length);
    },[activeStudentRecording,testRecordsPendingStudents,participatingStudents])

    useEffect(() => {
        setActiveStudentRecording(participatingStudents.filter(s => testRecordsPendingStudents.includes(s?.id))[0]?.id);
    },[testRecordsPendingStudents])

    const handleCarousalNextClick = () => {
        setPreSelectedStudent(undefined);
        setActiveStudentRecording(participatingStudents.filter(s => testRecordsPendingStudents.includes(s?.id))[carousalNextIndex].id);
    }

    const handleCarousalPreviousClick = () => {
        setPreSelectedStudent(undefined);
        setActiveStudentRecording(participatingStudents.filter(s => testRecordsPendingStudents.includes(s?.id))[carousalPreviousIndex].id);
    }

    useEffect(() => {
        if(testRecordsSubmittedStudents.length > 0){
            setTestRecordsPendingStudents(
                conductingTestData?.data?.data?.attributes?.participants?.data?.map(student => student.id)?.filter(student => !testRecordsSubmittedStudents.includes(student)) || []
            );
        }
    },[testRecordsSubmittedStudents])

    const handleSetPendingRecordToActive = (studentId: number) => {
        setActiveStudentRecording(studentId);
        setPreSelectedStudent(undefined);
    }

    const handleCloseModal = () => {
        setFieldsFormData({});
        setFormView('DEFAULT');
        setParticipatingStudents([])
        setTestRecordsPendingStudents([])
        setTestRecordsSubmittedStudents([])
        handleClose();
    }

    const postNewStudentFitnessRecord = async (
        data: StudentFitnessRecordPostData
    ): Promise<StudentFitnessRecordDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.postNewStudentFitnessRecord(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };
    
    const {
        mutate: addStudentFitnessRecord,
        isLoading: isStudentFitnessRecordAdding,
        isSuccess: isStudentFitnessRecordAdded,
    } = useMutation(postNewStudentFitnessRecord, {
        onSuccess: data => {
            if(data?.data) {
                queryClient.invalidateQueries(FITNESS_TEST_CONDUCTING_STUDENT_RECORDS);
            }
        },
        onError: error => {
            alert('Failed to add record! Please try again');
        },
    });

    const putStudentFitnessRecord = async (
        data: StudentFitnessRecordPostData
    ): Promise<StudentFitnessRecordDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.updateStudentFitnessRecord(
            data,
            conductingTestStudentRecords?.data?.data?.find(record => record?.attributes?.student?.data?.id === activeStudentRecording)?.id ?? 0
        );
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };
    
    const {
        mutate: editStudentFitnessRecord,
        isLoading: isStudentFitnessRecordEditing,
        isSuccess: isStudentFitnessRecordEdited,
    } = useMutation(putStudentFitnessRecord, {
        onSuccess: data => {
            if(data?.data) {
                queryClient.invalidateQueries(FITNESS_TEST_CONDUCTING_STUDENT_RECORDS);
            }
        },
        onError: error => {
            alert('Failed to edit record! Please try again');
        },
    });

    const handleRecordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(conductingTestStudentRecords?.data?.data?.some(record => record?.attributes?.student?.data?.id === activeStudentRecording)){
            const body: StudentFitnessRecordPostData = {
                testResults: fieldsArray?.map(item => ({ label: item.label, value: fieldsFormData[item.label], unit: item.unit, parameter: item.parameter })) ?? [],
            }
            editStudentFitnessRecord(body)
        }else{
            if(isActiveStudentSkipChecked){
                const body: StudentFitnessRecordPostData = {
                    student: activeStudentRecording,
                    testInfo: selectedConductableTestId,
                    testResults: fieldsArray?.map(item => ({ label: item.label, value: 0, unit: item.unit, parameter: item.parameter })) ?? [],
                    users: [userState?.user?.id ?? 0],
                }
                addStudentFitnessRecord(body)
            }else{
                const body: StudentFitnessRecordPostData = {
                    student: activeStudentRecording,
                    testInfo: selectedConductableTestId,
                    testResults: fieldsArray?.map(item => ({ label: item.label, value: fieldsFormData[item.label], unit: item.unit, parameter: item.parameter })) ?? [],
                    users: [userState?.user?.id ?? 0],
                }
                addStudentFitnessRecord(body)
            }
        }
        setFieldsFormData(initialFieldFormData);
    }

    const handleEditSubmittedRecord = (id: number) => {
        setPreSelectedStudent(undefined);
        setActiveStudentRecording(id);
        const testResults = conductingTestStudentRecords?.data?.data
            ?.find(item => item?.attributes?.student?.data?.id === id)?.attributes?.testResults;

        const fieldsFormData: FieldsFormDataType = testResults?.reduce((acc, item) => {
            acc[item.label as keyof FieldsFormDataType] = item.value;
            return acc;
        }, {} as FieldsFormDataType) ?? {} as FieldsFormDataType;

        setFieldsFormData(fieldsFormData);
    }

    const putFitnessTestConductable = async (
        data: FitnessTestConductedPostData
    ): Promise<FitnessTestConductedDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.updateFitnessTestConducted(data, conductingTestData?.data?.data?.id ?? 0);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };
    
    const {
        mutate: updateFitnessTestConductable,
        isLoading: isFitnessTestConductableUpdating,
        isSuccess: isFitnessTestConductableUpdated,
    } = useMutation(putFitnessTestConductable, {
        onSuccess: data => {
            if(data?.data) {
                alert('Fitness test published sucessfully!');
                handleCloseModal();
                queryClient.invalidateQueries(FITNESS_TEST_CONDUCTED_LIST_QUERY);
                queryClient.invalidateQueries(FITNESS_TEST_CONDUCTING_DATA);
            }
        },
        onError: error => {
            alert('Failed to publish fitness test! Please try again');
        },
    });

    const handlePublishFitnessTestConducted = async () => {
        updateFitnessTestConductable({ isPublished: true });
    }

    const handleSkipCheckboxChange = () => {
        setIsActiveStudentSkipChecked(prev => !prev);
    }

    const getMinMaxAgeOfAllTests = (): { name: string, maxAge: number, minAge: number }[] => {
        return (fitnessTestValuesList?.data?.data || []).map(test => {
            const testName = test?.attributes?.testName || 'Unknown Test';
            const values = test?.attributes?.values || [];
            
            const ages = values.map(value => value?.age).filter(age => age !== undefined);
    
            return {
                name: testName,
                maxAge: ages.length > 0 ? Math.max(...ages) : -Infinity,
                minAge: ages.length > 0 ? Math.min(...ages) : Infinity,
            };
        });
    };

    return {
        isLoading: isConductingTestDataFetching  || isStudentFitnessRecordAdding || isConductingTestStudentRecordsFetching || isStudentFitnessRecordEditing || isFitnessTestConductableUpdating || isFitnessTestValuesListFetching,
        handleCloseModal,
        conductingTestData: conductingTestData?.data?.data || {} as FitnessTestConductedData,
        participatingStudents,
        testRecordsPendingStudents,
        testRecordsSubmittedStudents,
        activeStudentRecording,
        handleSetPendingRecordToActive,
        handleRecordSubmit,
        carousalPreviousIndex,
        carousalNextIndex,
        handleCarousalNextClick,
        handleCarousalPreviousClick,
        fieldsFormData,
        handleFieldsInputChange,
        submittedRecords: conductingTestStudentRecords?.data?.data || [],
        handleEditSubmittedRecord,
        formView,
        handleViewSwitch,
        gridColumns,
        gridRows,
        handlePublishFitnessTestConducted,
        isActiveStudentSkipChecked,
        handleSkipCheckboxChange,
        getMinMaxAgeOfAllTests,
        preSelectedStudent,
        setPreSelectedStudent,
    };
};

export default RecordFitnessTestViewModel;
