import { AcademyDetailsWrapper } from '../models/academy/academy-details-wrapper';
import { AcademyListWrapper } from '../models/academy/academy-list-wrapper';
import { AcademyAttributes } from '../models/academy/acedemy-attributes';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import AcademyPostData from '../utils/types/academy-post-data';
import AcademyRequest from '../utils/types/academy-post-data';
import AcademyPostDataWrapper from '../utils/types/academypost-data-wrapper';
import ApiResponse from '../utils/types/api-response';

export class AcademiesService {
    private static _instance: AcademiesService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new AcademiesService());
    }

    public getAllAcademyList = async (
    ): Promise<ApiResponse<AcademyListWrapper>> => {
        return invokeApi<null, AcademyListWrapper>({
            route: `academies?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAcademyList = async (
        supplierId: number
    ): Promise<ApiResponse<AcademyListWrapper>> => {
        return invokeApi<null, AcademyListWrapper>({
            route: `academies?populate=*&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getTechniquesList = async (
        supplierId: number
    ): Promise<ApiResponse<AcademyListWrapper>> => {
        return invokeApi<null, AcademyListWrapper>({
            route: `academies?populate=*&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postAcademy = async (data: AcademyPostData) => {
        return invokeApi<AcademyPostDataWrapper, null>({
            route: `academies`,
            method: 'POST',
            privateRoute: true,
            data: { data },
        });
    };
    public getStudentActivitiesList = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate[studentAcademyHistory][populate][0]=academy&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public updateAcademy = async (data: AcademyPostData, id: number) => {
        return invokeApi<AcademyPostDataWrapper, AcademyDetailsWrapper>({
            route: `academies/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };
    public getDisciplines = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate[studentAcademyHistory][populate][0]=academy&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
