import React, { ChangeEvent, useState } from 'react';
import style from './style.module.css';
import Select from 'react-select';
import Loader from '../../bits/loader';
import AddAcademyCard from '../add-academy-card';
import { AcademyCard } from '../../../view-models/dashboard-view-model';
import { ClassData } from '../../../../models/class/class-data';
import { BOARD_OPTIONS, GRADE_OPTIONS } from './data';
import { StudentFormDataType } from '../../../view-models/actions-view-models/add-student-view-model';

interface NewStudentFormProps {
    switchTab: (tabNumber: number, isGoingBack: boolean) => void;
    handleInputChange: (property: string, value: string | number[]) => void;
    handleAddNewStudent: (e: React.FormEvent) => void;
    formData: StudentFormDataType;
    activeTab: number;
    mapAcademiesToOptions: () => { value: number; label: string }[];
    mapClassesToOptions: () => { value: number; label: string }[];
    isEditMode: boolean;
    handleStudentDropout?: () => void;
    handleProfileImgChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isProfileImagePosting: boolean;
    profileImageInputKey: number;
    isExpandedForAddAcademy: number;
    handleExpandClickForAddAcademy: (index: number) => void;
    handleDeleteClickForAcademy: (index: number) => void;
    // handleAddAcademyCardClick: () => void;
    academyCards: AcademyCard[];
    handleAddButtonClick: () => void;
    mapRemainingAcademiesToOptions: () => { value: number; label: string }[];
    handleAddAcademyInputChange: (
        property: string,
        value: string | number[] | number | undefined,
        index: number
    ) => void;
    mapRemainingClassesToOption: (index: number) => { value: number; label: string }[];
    handleClassAdding: (id: number, academyCardIndex: number) => void;
    handleClassDeleting: (classId: number, academyCardIndex: number) => void;
    allClasses: ClassData[];
    handleCheckboxChange: (
        key: string,
        timing: { day: string; from: string; to: string },
        academyCardIndex: number,
        classId: number
    ) => void;
    handleCheckboxChangeForDay: (day: string, academyCardIndex: number, classId: number) => void;
}

const NewStudentForm: React.FC<NewStudentFormProps> = ({
    switchTab,
    handleInputChange,
    handleAddNewStudent,
    formData,
    activeTab,
    mapAcademiesToOptions,
    mapClassesToOptions,
    isEditMode,
    handleStudentDropout,
    handleProfileImgChange,
    isProfileImagePosting,
    profileImageInputKey,
    isExpandedForAddAcademy,
    handleExpandClickForAddAcademy,
    academyCards,
    handleAddButtonClick,
    handleDeleteClickForAcademy,
    // handleAddAcademyCardClick,
    mapRemainingAcademiesToOptions,
    handleAddAcademyInputChange,
    mapRemainingClassesToOption,
    handleClassAdding,
    handleClassDeleting,
    allClasses,
    handleCheckboxChange,
    handleCheckboxChangeForDay,
}) => {
    return (
        <div className={style.formWrapper}>
            <div className="flex items-center justify-around text-sm font-semibold sm:text-lg py-2">
                {['Primary Details', 'Contact Details', 'Emergency Contacts'].map((text, index) => {
                    const tabIndex = index + 1;
                    const isActive = activeTab === tabIndex;
                    return (
                        <div
                            key={text}
                            className={`relative text-center ${isActive ? 'opacity-100' : 'opacity-40'} transition-all`}
                        >
                            {text}
                            {isActive && (
                                <div
                                    className="absolute bottom-[-5px] left-1/2 transform -translate-x-1/2 w-1/2 border-b-2 border-gray-500"
                                    style={{ width: '50%' }}
                                ></div>
                            )}
                        </div>
                    );
                })}
            </div>
            {/* {isEditMode && 
                <button 
                    className={style.studentDropBtn} 
                    onClick={handleStudentDropout}
                >
                    Dropout student
                </button>
            } */}
            <form
                onSubmit={handleAddNewStudent}
                className={style.formTabContainer}
            >
                <div className={`${style.tabOne} ${activeTab === 1 ? style.activeTab : ''}`}>
                    <div className={style.nextBtnContainer}>
                        <button
                            className={style.formSubmitBtn}
                            onClick={e => {
                                e.preventDefault();
                                switchTab(2, false);
                            }}
                        >
                            Next
                        </button>
                    </div>
                    <div className={style.inputFieldsContainer}>
                        <div className={style.nameGenderWrapper}>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    First Name:
                                </div>
                                <input
                                    required
                                    // disabled={isEditMode}
                                    className={style.formInput}
                                    placeholder="Enter student's first name"
                                    value={formData.firstName}
                                    onChange={e => handleInputChange('firstName', e.target.value)}
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Last Name:</div>
                                <input
                                    // disabled={isEditMode}
                                    className={style.formInput}
                                    placeholder="Enter student's last name"
                                    value={formData.lastName}
                                    onChange={e => handleInputChange('lastName', e.target.value)}
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Middle Name:</div>
                                <input
                                    className={style.formInput}
                                    // disabled={isEditMode}
                                    placeholder="Enter student's middle name"
                                    value={formData.middleName}
                                    onChange={e => handleInputChange('middleName', e.target.value)}
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    Gender:
                                </div>
                                <select
                                    required
                                    // disabled={isEditMode}
                                    className={style.formInput}
                                    value={formData.gender}
                                    onChange={e => handleInputChange('gender', e.target.value)}
                                >
                                    <option value="">Select student&apos;s gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                        </div>
                        <div className={style.photoDobBloodGroupWrapper}>
                            <div>
                                <div className={style.formInputLabel}>
                                    Profile photo:
                                    <span className={style.imageMaxSizePrompt}>(max 10MB)</span>
                                </div>
                                <div className="flex gap-4 ">
                                    <div className={style.formImageInputBtnWrapper}>
                                        <label
                                            htmlFor="fileInput"
                                            className={style.formImageInputBtnLabel}
                                        >
                                            <input
                                                key={profileImageInputKey}
                                                type="file"
                                                id="fileInput"
                                                className={style.formImageInput}
                                                onChange={handleProfileImgChange}
                                                accept="image/*"
                                                size={30720}
                                            />
                                            <div className={style.formImageInputLabel}>
                                                <div>+</div>
                                                <div>Upload</div>
                                            </div>
                                        </label>
                                    </div>
                                    {formData.photo && !isProfileImagePosting ? (
                                        <div className="h-[105px] w-[105px] min-h-[105px] min-w-[105px] border">
                                            <img
                                                src={formData.photo}
                                                alt="profile image"
                                                className="h-full w-full object-contain"
                                            />
                                        </div>
                                    ) : (
                                        <div className="min-h-[105px] h-[105px] w-[105px] text-center min-w-[105px] border flex justify-center items-center text-gray-500">
                                            {!isProfileImagePosting ? (
                                                'No image uploaded'
                                            ) : (
                                                <Loader />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className={style.formInputLabel}>
                                        <span className={style.requiredFieldLabelPrompt}>*</span>
                                        Date of Birth:
                                    </div>
                                    <input
                                        type="date"
                                        // disabled={isEditMode}
                                        className={style.formInput}
                                        value={formData.DateOfBirth}
                                        onChange={e =>
                                            handleInputChange('DateOfBirth', e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <div className={style.formInputLabel}>Blood Group:</div>
                                    <select
                                        className={style.formInput}
                                        value={formData.BloodGroup}
                                        onChange={e =>
                                            handleInputChange('BloodGroup', e.target.value)
                                        }
                                    >
                                        <option value="">Select student&apos;s blood group</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={style.formInputLabel}>Differently Abled:</div>
                            <select
                                className={style.formInput}
                                value={
                                    formData.isPhysicallyHandicapped
                                        ? String(formData.isPhysicallyHandicapped)
                                        : ''
                                }
                                onChange={e =>
                                    handleInputChange('isPhysicallyHandicapped', e.target.value)
                                }
                            >
                                <option value="">Differently abled (Yes/No)</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div className="w-full border-2 p-2 mt-2 ">
                            <div className="flex justify-between items-center">
                                <h1 className="font-semibold">Add Academies</h1>
                                <div
                                    className="bg-slate-500 rounded-full w-[20px] h-[20px] flex justify-center items-center p-2 cursor-pointer"
                                    onClick={handleAddButtonClick}
                                >
                                    <i className="ri-add-line text-white font-bold"></i>
                                </div>
                            </div>
                            <div className="max-h-[850px] overflow-y-auto thin-scrollbar">
                                {academyCards.map((academy, index) => (
                                    <AddAcademyCard
                                        key={index}
                                        index={index}
                                        isExpanded={isExpandedForAddAcademy}
                                        handleExpandClick={() =>
                                            handleExpandClickForAddAcademy(index)
                                        }
                                        handleDeleteClick={() => handleDeleteClickForAcademy(index)}
                                        listOfAvailableAcademies={mapRemainingAcademiesToOptions()}
                                        handleInputChange={handleAddAcademyInputChange}
                                        academyCardDetails={academy}
                                        listOfAllAcademies={mapAcademiesToOptions}
                                        listOfAvailableClasses={mapRemainingClassesToOption(index)}
                                        listOfAllClasses={mapClassesToOptions}
                                        handleClassAdding={handleClassAdding}
                                        handleClassDeleting={handleClassDeleting}
                                        allClasses={allClasses}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleCheckboxChangeForDay={handleCheckboxChangeForDay}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${style.tabTwo} ${activeTab === 2 ? style.activeTab : ''}`}>
                    <div className={style.nextBackBtnContainer}>
                        <button
                            className={style.formSubmitBtn}
                            onClick={e => {
                                e.preventDefault();
                                switchTab(1, true);
                            }}
                        >
                            Back
                        </button>
                        <button
                            className={style.formSubmitBtn}
                            onClick={e => {
                                e.preventDefault();
                                switchTab(3, false);
                            }}
                        >
                            Next
                        </button>
                    </div>
                    <div className={style.inputFieldsContainer}>
                        <div className={style.contactEmailWrapper}>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    Parent&apos;s contact number:
                                </div>
                                <input
                                    required
                                    type="number"
                                    className={style.formInput}
                                    placeholder="Enter parent's contact number"
                                    value={formData.parentNumber}
                                    onChange={e =>
                                        handleInputChange('parentNumber', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    Parent&apos;s email id:
                                </div>
                                <input
                                    required
                                    className={style.formInput}
                                    placeholder="Enter parent's email id"
                                    value={formData.parentEmail}
                                    onChange={e => handleInputChange('parentEmail', e.target.value)}
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>City:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter city"
                                    value={formData.city}
                                    onChange={e => handleInputChange('city', e.target.value)}
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>State:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter state"
                                    value={formData.state}
                                    onChange={e => handleInputChange('state', e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={style.formInputLabel}>
                                <span className={style.requiredFieldLabelPrompt}>*</span>
                                Current Address:
                            </div>
                            <input
                                required
                                className={style.formInput}
                                placeholder="Enter the address"
                                value={formData.currentAddress}
                                onChange={e => handleInputChange('currentAddress', e.target.value)}
                            />
                        </div>
                        <div>
                            <div className={style.formInputLabel}>Apartment name:</div>
                            <input
                                className={style.formInput}
                                placeholder="Enter apartment name"
                                value={formData.apartmentName}
                                onChange={e => handleInputChange('apartmentName', e.target.value)}
                            />
                        </div>
                        <div>
                            <div className={style.formInputLabel}>School name:</div>
                            <input
                                type="text"
                                placeholder="School Name"
                                className={style.formInput}
                                onChange={e => handleInputChange('school', e.target.value)}
                                value={formData.school}
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                            <div>
                                <div className={style.formInputLabel}>Grade:</div>
                                <select
                                    className={style.formInput}
                                    onChange={e => handleInputChange('grade', e.target.value)}
                                    value={formData.grade}
                                >
                                    <option
                                        value={''}
                                        className="bg-slate-300"
                                    >
                                        Select the Grade
                                    </option>
                                    {GRADE_OPTIONS.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Board:</div>
                                <select
                                    className={style.formInput}
                                    onChange={e => handleInputChange('board', e.target.value)}
                                    value={formData.board}
                                >
                                    <option
                                        className="bg-slate-300"
                                        value={''}
                                    >
                                        Select the Board
                                    </option>
                                    {BOARD_OPTIONS.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${style.tabThree} ${activeTab === 3 ? style.activeTab : ''}`}>
                    <div className={style.nextBackBtnContainer}>
                        <button
                            className={style.formSubmitBtn}
                            onClick={e => {
                                e.preventDefault();
                                switchTab(2, true);
                            }}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className={style.formSubmitBtn}
                        >
                            {isEditMode ? 'Update Student' : 'Add Student'}
                        </button>
                    </div>
                    <div className={style.inputFieldsContainer}>
                        <div className={style.relationInputContainer}>
                            <div>
                                <div className={style.formInputLabel}>Relation 1:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Select the relationship"
                                    value={formData.relationOneRelationship}
                                    onChange={e =>
                                        handleInputChange('relationOneRelationship', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 1 full name:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter full name"
                                    value={formData.relationOneFullName}
                                    onChange={e =>
                                        handleInputChange('relationOneFullName', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>
                                    Relation 1 contact number:
                                </div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter contact number"
                                    value={formData.relationOneContact}
                                    onChange={e =>
                                        handleInputChange('relationOneContact', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 1 email id:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter email id"
                                    value={formData.relationOneEmail}
                                    onChange={e =>
                                        handleInputChange('relationOneEmail', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 1 Gender:</div>
                                <select
                                    className={style.formInput}
                                    value={formData.relationOneGender}
                                    onChange={e =>
                                        handleInputChange('relationOneGender', e.target.value)
                                    }
                                >
                                    <option value="">Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 1 Profession:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter profession"
                                    value={formData.relationOneProfession}
                                    onChange={e =>
                                        handleInputChange('relationOneProfession', e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <hr className={style.relationDivider} />
                        <div className={style.relationInputContainer}>
                            <div>
                                <div className={style.formInputLabel}>Relation 2:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Select the relationship"
                                    value={formData.relationTwoRelationship}
                                    onChange={e =>
                                        handleInputChange('relationTwoRelationship', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 2 full name:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter full name"
                                    value={formData.relationTwoFullName}
                                    onChange={e =>
                                        handleInputChange('relationTwoFullName', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>
                                    Relation 2 contact number:
                                </div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter contact number"
                                    value={formData.relationTwoContact}
                                    onChange={e =>
                                        handleInputChange('relationTwoContact', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 2 email id:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter email id"
                                    value={formData.relationTwoEmail}
                                    onChange={e =>
                                        handleInputChange('relationTwoEmail', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 2 Gender:</div>
                                <select
                                    className={style.formInput}
                                    value={formData.relationTwoGender}
                                    onChange={e =>
                                        handleInputChange('relationTwoGender', e.target.value)
                                    }
                                >
                                    <option value="">Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Relation 2 Profession:</div>
                                <input
                                    className={style.formInput}
                                    placeholder="Enter profession"
                                    value={formData.relationTwoProfession}
                                    onChange={e =>
                                        handleInputChange('relationTwoProfession', e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewStudentForm;
