import Select from 'react-select';
import style from './style.module.css';

import React, { useState } from 'react';
import { EnrolmentCard } from '../../../view-models/students-view-model';
import EditIcon from '../../../../assets/icons/edit.png';
import DeleteIcon from '../../../../assets/icons/delete-bin.png';
import { AcademyData } from '../../../../models/academy/academy-data';

type Props = {
    handleNextButtonClick: (e: React.FormEvent) => void;
    listOfAllAcademies: () => { value: number; label: string }[];
    listOfAllClasses: () => { value: number; label: string }[];
    handleInputChange: (
        property: string,
        value: string | number[] | number | boolean | undefined
    ) => void;
    enrolmentCardDetails: EnrolmentCard[];
    handleAddClick: (e: React.FormEvent) => void;
    currentEnrolmentData: EnrolmentCard;
    allClasses: () => { value: number; label: string }[];
    onEditClick: (index: number) => void;
    onDeleteClick: (index: number) => void;
    isEmail: boolean;
    handleEmailSwitch: () => void;
    academiesWithoutContactInfo: AcademyData[];
};
function NewEnrolmentFormDetails({
    handleNextButtonClick,
    listOfAllAcademies,
    listOfAllClasses,
    handleInputChange,
    enrolmentCardDetails,
    handleAddClick,
    currentEnrolmentData,
    allClasses,
    onDeleteClick,
    onEditClick,
    isEmail,
    handleEmailSwitch,
    academiesWithoutContactInfo,
}: Props) {
    
    return (
        <div className="p-2 sm:px-8 sm:w-[88vw]">
            <div className="flex justify-end mb-4 gap-3">
                <div className="text-lg font-medium ">
                    <i className="ri-download-2-line"></i>
                </div>

                <div className="border-slate-500 border rounded w-[280px] text-center cursor-pointer">
                    <button className="p-1">Bulk Upload Enrolment Details</button>
                </div>
            </div>
            <form
                onSubmit={handleAddClick}
                className="flex flex-col sm:flex-row items-center rounded-md border border-gray-400 p-2 mt-1"
            >
                <div className="flex flex-grow flex-col-reverse ">
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 sm:gap-4 my-1">
                        <div className="flex items-center border rounded-md border-gray-300">
                            <div className="flex gap-2 rounded-md bg-slate-200 p-1 px-2">
                                <i
                                    className={`ri-mail-add-line cursor-pointer p-1 px-2 rounded-md ${isEmail ? 'bg-slate-600 text-white' : ''} transition-all`}
                                    onClick={handleEmailSwitch}
                                ></i>
                                <i
                                    className={`ri-phone-line cursor-pointer p-1 px-2 rounded-md ${isEmail ? '' : 'bg-slate-600 text-white'} transition-all`}
                                    onClick={handleEmailSwitch}
                                ></i>
                            </div>
                            <input
                                className="!border-0 focus:ring-0 rounded-md w-full"
                                type={isEmail ? 'email' : 'tel'}
                                pattern={isEmail ? undefined : '\\d{10}'}
                                placeholder={isEmail ? 'Email' : 'Phone Number'}
                                required
                                value={
                                    isEmail
                                        ? currentEnrolmentData.email
                                        : currentEnrolmentData.phoneNumber
                                }
                                onChange={e =>
                                    isEmail
                                        ? handleInputChange('email', e.target.value)
                                        : handleInputChange('phoneNumber', e.target.value)
                                }
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6 items-center">
                            <div className="flex gap-4 items-center justify-center h-full border rounded-md border-gray-300 py-2">
                                <div>Existing Student?</div>
                                <input
                                    type="checkbox"
                                    className="rounded-full"
                                    onChange={e =>
                                        handleInputChange('isExisting', e.target.checked)
                                    }
                                    checked={currentEnrolmentData.isExisting}
                                />
                            </div>
                            <div className="border rounded-md border-gray-300 flex items-center">
                                <div className="p-2 px-4 font-semibold bg-gray-300">₹</div>
                                <input
                                    type="number"
                                    placeholder="Amount to collect"
                                    value={currentEnrolmentData.amount}
                                    className="border-0 sm:flex-grow"
                                    onChange={e => handleInputChange('amount', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 my-1">
                        <div className='flex items-center'>
                            <Select
                                isMulti
                                required
                                options={listOfAllAcademies()}
                                className={`${style.formInput} text-start`}
                                classNamePrefix="Select Academy"
                                placeholder="Select Academy"
                                onChange={item =>
                                    handleInputChange(
                                        'academyId',
                                        item.map(({ value }) => value)
                                    )
                                }
                                value={listOfAllAcademies().filter(option =>
                                    currentEnrolmentData.academyId?.includes(option.value)
                                )}
                            />
                            {academiesWithoutContactInfo.length > 0 && (
                                <div className="relative group ml-2">
                                    <div className="flex items-center justify-center h-6 w-6 text-[14px] italic rounded-xl bg-gray-300 cursor-pointer ">
                                        i
                                    </div>
                                    <div className="hidden w-[70vw] sm:w-[30vw] group-hover:block absolute top-7 right-1 bg-white border border-gray-300 p-2 rounded shadow-xl z-[1000]">
                                        <div>Following academies do not have contact info: <span className='font-semibold text-gray-700'>{academiesWithoutContactInfo.map(a => a.attributes.name).join(', ')}</span>.</div>
                                        <div>Update these academies contact info to select them here!</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Select
                            isMulti
                            required
                            options={listOfAllClasses()}
                            className={`${style.formInput} text-start`}
                            classNamePrefix="Select Class"
                            placeholder="Select Class"
                            onChange={item =>
                                handleInputChange(
                                    'classId',
                                    item.map(({ value }) => value)
                                )
                            }
                            value={listOfAllClasses().filter(option =>
                                currentEnrolmentData.classId?.includes(option.value)
                            )}
                        />
                    </div>
                </div>
                <div className="m-4 w-full sm:w-auto">
                    <button
                        className="h-8 sm:h-12 w-full sm:w-12 flex items-center justify-center bg-slate-700 text-white rounded text-2xl sm:text-3xl active:scale-95 transition-all"
                        type="submit"
                    >
                        +
                    </button>
                </div>
            </form>
            <div className="overflow-auto w-full sm:h-[50vh] h-[40vh] border rounded thin-scrollbar mt-2">
                <div className="flex sticky top-0 bg-white">
                    <div className="text-center bg-slate-200 border border-gray-700 px-1 min-w-[160px]">
                        Email/Number
                    </div>
                    <div className="text-center bg-slate-200 border border-gray-700 px-1 min-w-[340px]">
                        Academy
                    </div>
                    <div className="text-center bg-slate-200 border border-gray-700 px-1 min-w-[340px]">
                        Class
                    </div>
                    <div className="text-center bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Existing Student
                    </div>
                    <div className="text-center bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Enrolment Fees
                    </div>
                    <div className="text-center bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Actions
                    </div>
                </div>
                {enrolmentCardDetails.map((item, index) => {
                        return (
                            <div
                                className={`flex`}
                                key={index}
                            >
                                <div className="border border-gray-300 text-center px-1 min-w-[160px]">
                                    {item.email ? item.email : item.phoneNumber}
                                </div>
                                <div className="border border-gray-300 text-center px-1 min-w-[340px] max-w-[340px]">
                                    {listOfAllAcademies()
                                        .filter(academy => item.academyId.includes(academy.value))
                                        .map(academy => academy.label)
                                        .join(', ')}
                                </div>
                                <div className="border border-gray-300 text-center px-1 min-w-[340px] max-w-[340px]">
                                    {allClasses()
                                        .filter(option => item.classId.includes(option.value))
                                        .map(option => option.label)
                                        .join(', ')}
                                </div>
                                <div className="border border-gray-300 text-center px-1 min-w-[140px]">
                                    {item.isExisting ? 'Yes' : 'No'}
                                </div>
                                <div className="border border-gray-300 text-center px-1 min-w-[140px]">
                                    {item?.amount}
                                </div>
                                <div className="flex justify-around items-center border border-gray-300 text-center px-1 min-w-[140px]">
                                    <div
                                        className="h-[25px] w-[25px]  p-1 cursor-pointer hover:scale-105 transition-all"
                                        onClick={() => onEditClick(index)}
                                    >
                                        <img
                                            src={EditIcon}
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="h-[25px] w-[25px] p-[1px] cursor-pointer hover:scale-105 transition-all"
                                        onClick={() => onDeleteClick(index)}
                                    >
                                        <img
                                            src={DeleteIcon}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="flex justify-end mt-8 mr-4">
                <button
                    className="p-2 px-6 bg-slate-700 text-white rounded font-semibold disabled:opacity-30 disabled:cursor-not-allowed"
                    type="button"
                    onClick={handleNextButtonClick}
                    disabled={enrolmentCardDetails.length === 0}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default NewEnrolmentFormDetails;
