import { FeeStructureDataWrapper } from '../models/fee-structure/fee-structure-data-wrapper';
import { FeeStructureListWrapper } from '../models/fee-structure/fee-structure-list-wrapper';
import { FeeStructurePostData } from '../models/fee-structure/fee-structure-post-data';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class FeeStructureService {
    private static _instance: FeeStructureService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new FeeStructureService());
    }

    public postFeeStructure = async (
        data: FeeStructurePostData
    ): Promise<ApiResponse<FeeStructureDataWrapper>> => {
        return invokeApi<{ data: FeeStructurePostData }, FeeStructureDataWrapper>({
            route: `fee-structures`,
            method: 'POST',
            privateRoute: true,
            data: { data },
        });
    };

    public getFeeStructureList = async (
        userId: number
    ): Promise<ApiResponse<FeeStructureListWrapper>> => {
        return invokeApi<null, FeeStructureListWrapper>({
            route: `fee-structures?populate=*&filters[users][id][$eq]=${userId}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
