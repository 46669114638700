export const DROPDOWN_LISTS_FeePayment_Name = [
    { label: 'Collect Fees', action: 'GO_TO_COLLECT_FEE' },
    { label: 'View Profile', action: 'GO_TO_DETAIL' },
    // { label: "Attendance History", action: () => console.log("Viewing attendance history") },
    { label: 'Drop out', action: 'OPEN_STUDENT_DROPOUT_FORM' },
    // { label: "Assignments", action: () => console.log("Viewing assignments") },
    { label: 'Record Leave', action: 'OPEN_RECORD_LEAVE_FORM' },
    { label: 'View Details', action: 'VIEW_DETAILS' },
    { label: 'Change Class', action: 'OPEN_CHANGE_CLASS_FORM' },
    // { label: "Drop out", action: () => console.log("Processing drop out") },
    { label: 'Archive', action: 'OPEN_ARCHIVE' },
];
