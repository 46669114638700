import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/user/user';
import { loadUserState, saveUserState } from '../helpers/helpers';
import { UserDetailsData } from '../../models/user-details/user-details-data';
import { SupplierData } from '../../models/supplier/supplier-data';

const userState = {
    user: loadUserState(),
    userDetails: {} as UserDetailsData,
    supplierDetails: {} as SupplierData,
    walkthroughFlag: 'FETCHING' as string,
};

const userSlice = createSlice({
    name: 'user',
    initialState: userState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
            saveUserState(action.payload);
        },
        setUserDetails: (state, action: PayloadAction<UserDetailsData>) => {
            state.userDetails = action.payload;
        },
        setSupplierDetails: (state, action: PayloadAction<SupplierData>) => {
            state.supplierDetails = action.payload;
        },
        setWalkthroughFlags: (state, action: PayloadAction<string>) => {
            state.walkthroughFlag = action.payload;
        },
    },
});

export const { setUser, setUserDetails, setSupplierDetails, setWalkthroughFlags } = userSlice.actions;
export default userSlice.reducer;
