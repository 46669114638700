// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_studentRelationsContainerWrapper__f7WUF {

    display: grid;

    grid-template-columns: repeat(1, minmax(0, 1fr));

    border-radius: 0.25rem;

    padding: 0.25rem
}

@media (min-width: 640px) {

    .style_studentRelationsContainerWrapper__f7WUF {

        grid-template-columns: repeat(2, minmax(0, 1fr));

        padding: 0.5rem
    }
}

.style_studentRelationContainer__OBQLH {

    flex: 1 1
}

.style_studentRelationContainer__OBQLH > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.style_studentProfileAcademicDetailsLabel__mlTPS {

    font-size: 10px;

    font-weight: 400;

    line-height: 1rem;

    letter-spacing: 0.5px;

    --tw-text-opacity: 1;

    color: rgb(119 119 119 / var(--tw-text-opacity))
}

.style_studentProfileAcademicDetailsContent__XUuDb {

    font-size: 13px;

    font-weight: 400;

    line-height: 1.25rem;

    letter-spacing: 0px;

    --tw-text-opacity: 1;

    color: rgb(34 34 34 / var(--tw-text-opacity))
}

.style_studentRelationContainerMain__a2vX\\+ {

    display: flex
}
`, "",{"version":3,"sources":["webpack://./src/view/components/pieces/student-relation-item/style.module.css"],"names":[],"mappings":"AACI;;IAAA,aAAyD;;IAAzD,gDAAyD;;IAAzD,sBAAyD;;IAAzD;AAAyD;;AAAzD;;IAAA;;QAAA,gDAAyD;;QAAzD;IAAyD;AAAA;;AAIzD;;IAAA;AAAuB;;AAAvB;;IAAA,uBAAuB;;IAAvB,4DAAuB;;IAAvB;AAAuB;;AAIvB;;IAAA,eAAwE;;IAAxE,gBAAwE;;IAAxE,iBAAwE;;IAAxE,qBAAwE;;IAAxE,oBAAwE;;IAAxE;AAAwE;;AAIxE;;IAAA,eAAsE;;IAAtE,gBAAsE;;IAAtE,oBAAsE;;IAAtE,mBAAsE;;IAAtE,oBAAsE;;IAAtE;AAAsE;;AAItE;;IAAA;AAAW","sourcesContent":[".studentRelationsContainerWrapper {\n    @apply grid grid-cols-1 sm:grid-cols-2 rounded sm:p-2 p-1;\n}\n\n.studentRelationContainer {\n    @apply flex-1 space-y-4;\n}\n\n.studentProfileAcademicDetailsLabel {\n    @apply text-[10px] font-normal leading-4 tracking-[0.5px] text-[#777777];\n}\n\n.studentProfileAcademicDetailsContent {\n    @apply text-[13px] font-normal leading-5 tracking-[0px] text-[#222222];\n}\n\n.studentRelationContainerMain {\n    @apply flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"studentRelationsContainerWrapper": `style_studentRelationsContainerWrapper__f7WUF`,
	"studentRelationContainer": `style_studentRelationContainer__OBQLH`,
	"studentProfileAcademicDetailsLabel": `style_studentProfileAcademicDetailsLabel__mlTPS`,
	"studentProfileAcademicDetailsContent": `style_studentProfileAcademicDetailsContent__XUuDb`,
	"studentRelationContainerMain": `style_studentRelationContainerMain__a2vX+`
};
export default ___CSS_LOADER_EXPORT___;
