import { ClassDetailsWrapper } from '../models/class/class-details-wrapper';
import { ClassListWrapper } from '../models/class/class-list-wrapper';
import { ClassPostData } from '../models/class/class-post-data';
import { ClassPostDataWrapper } from '../models/class/class-post-data-wrapper';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';

export class ClassService {
    private static _instance: ClassService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new ClassService());
    }

    public getClasses = async (ownerId: number) => {
        return invokeApi<null, ClassListWrapper>({
            route: `classes?filters[users][id][$eq]=${ownerId}&populate[0]=class_timings&populate[1]=academies&populate[2]=class_discipline&populate[3]=users&populate[4]=students.sessions.sessionTimings&&populate[5]=students.sessions.class`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getClassDetails = async (classId: number) => {
        return invokeApi<null, ClassDetailsWrapper>({
            route: `classes/${classId}?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewClass = async (data: ClassPostData) => {
        return invokeApi<ClassPostDataWrapper, ClassDetailsWrapper>({
            route: `classes`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public updateClass = async (data: ClassPostData, id: number) => {
        return invokeApi<ClassPostDataWrapper, ClassDetailsWrapper>({
            route: `classes/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public getClassStudentsDetails = async (classId: number, searchTerm: string) => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate[0]=photo&populate[1]=classes&populate[2]=academies&populate[3]=sessions.class&populate[4]=sessions.sessionTimings&populate[5]=studentAcademyHistory&populate[6]=users&pagination[page]=1&pagination[pageSize]=1000&filters[classes][id]=${classId}&filters[$or][0][firstName][$contains]=${searchTerm}&filters[$or][1][lastName][$contains]=${searchTerm}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentsImpactedAfterTimingsChange = async (classId: number) => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate[0]=sessions.sessionTimings&populate[1]=sessions.class&fields[0]=firstName&filters[sessions][class]=${classId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

}

export default ClassService;
