import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { FormEvent, useState } from 'react';
import { CertificationService } from '../../../services/certification-service';
import { StudentCertificateRecordPostData } from '../../../models/certifications/student-certificate-record-post-data';
import { StudentCertificateRecordDataWrapper } from '../../../models/certifications/student-certificate-record-data-wrapper';
import { useMutation, useQueryClient } from 'react-query';
import { CERTIFICATION_STUDENT_RECORDS_LIST, STUDENT_CERTIFICATION_RECORDS_FOR_DISCIPLINE_LIST } from '../../../utils/constants/constants';
import { CertificationData } from '../../../models/certifications/certification-data';

const CertifyNewStudentViewModel = ({
    handleClose,
    selectedCertificationData,
}: {
    handleClose: () => void;
    selectedCertificationData: CertificationData;
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const [serchTerm, setSearchTerm] = useState('');
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);

    const handleAddStudent = (id: number) => {
        setSelectedStudents([...selectedStudents, id]);
    }

    const handleRemoveStudent = (id: number) => {
        setSelectedStudents(selectedStudents.filter(student => student !== id));
    }
    
    const handleModalClose = () => {
        setSelectedStudents([]);
        setSearchTerm('');
        handleClose();
    }

    const postStudentCertificateRecord = async (
        data: StudentCertificateRecordPostData
    ): Promise<StudentCertificateRecordDataWrapper | undefined> => {
        const response = await CertificationService.instance.postNewStudentCertificationRecord(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addStudentCertificateRecord,
        isLoading: isStudentCertificateRecordAdding,
    } = useMutation(postStudentCertificateRecord, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(CERTIFICATION_STUDENT_RECORDS_LIST);
            queryClient.invalidateQueries(STUDENT_CERTIFICATION_RECORDS_FOR_DISCIPLINE_LIST);
        },
        onError: (error) => {
            queryClient.invalidateQueries(CERTIFICATION_STUDENT_RECORDS_LIST);
            queryClient.invalidateQueries(STUDENT_CERTIFICATION_RECORDS_FOR_DISCIPLINE_LIST);
        },
    });

    const handleCertifyNewStudentsFormSubmit = async () => {
        const addRecordPromises = selectedStudents.map(async (student) => {
            return addStudentCertificateRecord({
                student,
                certificationInfo: selectedCertificationData?.id,
            });
        });
        await Promise.all(addRecordPromises);
        handleModalClose();
    }

    return {
        isLoading: isStudentCertificateRecordAdding,
        handleModalClose,
        selectedStudents,
        serchTerm,
        setSearchTerm,
        handleAddStudent,
        handleRemoveStudent,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        handleCertifyNewStudentsFormSubmit,
    };
};

export default CertifyNewStudentViewModel;
