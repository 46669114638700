import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import ApiRequest from '../types/api-request';
import ApiResponse from '../types/api-response';
import { ErrorResponse } from '../types/error-reponse';
import { getJWTToken } from './helpers';

export const invokeApi = async <T, R>(apiRequest: ApiRequest<T>): Promise<ApiResponse<R>> => {
    const config: AxiosRequestConfig<T> = {
        baseURL: process.env.REACT_APP_BASE_URL + '/api',
        method: apiRequest.method,
        timeout: 90000,
        url: apiRequest.route,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    if (apiRequest.data) {
        config.data = apiRequest.data;
    }
    if (apiRequest.privateRoute && getJWTToken()) {
        config.headers = {
            Authorization: `Bearer ${getJWTToken()}`,
        };
    }
    try {
        const response: AxiosResponse<R> = await axios.request(config);
        return {
            isLoading: false,
            success: true,
            data: response.data,
        };
    } catch (error) {
        const axiosError = error as AxiosError;
        console.error('Error Data : ', axiosError.response?.data);
        // console.warn(`Error Response ${JSON.stringify(error)}`);
        const errorResponse: ErrorResponse = {
            status: 404,
            message: 'Something went wrong',
        };
        if (axiosError.response) {
            errorResponse.status = axiosError.response.status ?? 404;
            errorResponse.message = axiosError.response.statusText;
        } else if (axiosError.request) {
            errorResponse.message = axiosError.request;
        } else {
            errorResponse.message = axiosError.message;
        }
        return {
            isLoading: false,
            success: false,
            error: errorResponse,
        };
    }
};
