import React from 'react';
import PopupModal from '../../components/widgets/popup-modal';
import { ClassData } from '../../../models/class/class-data';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import AddFitnessTestViewModel from '../../view-models/actions-view-models/add-fitness-test-view-model';
import AddFitnessTestForm from '../../components/widgets/add-fitness-test-form';

interface AddFitnessTestProps {
    isOpen: boolean;
    handleClose: () => void;
}

const AddFitnessTest = ({
    isOpen,
    handleClose,
}: AddFitnessTestProps) => {
    const {
        isLoading,
        fitnessTestDetailsFormData,
        handleInputChange,
        handleAddNewField,
        handleActiveFieldChange,
        activeFieldData,
        markUnmarkFieldMandatory,
        handleRemoveField,
        formSubmissionError,
        handleCreateNewFitnessTest,
        isParamenterOptionListOpen,
        handleToggleParameterOptionList,
        paramenterOptionListRef,
        handleCloseModal,
        fitnessTestValuesList,
        isFitnessTestAdding
    } = AddFitnessTestViewModel({ handleClose });

    return (
        <PopupModal
            title="Add Fitness Test"
            isOpen={isOpen}
            background='white'
            handleModalClose={() => {
                handleCloseModal();
            }}
        >
            {(isLoading || isFitnessTestAdding) && <FullScreenLoader />}
            <AddFitnessTestForm
                fitnessTestDetailsFormData={fitnessTestDetailsFormData}
                handleInputChange={handleInputChange}
                handleAddNewField={handleAddNewField}
                handleActiveFieldChange={handleActiveFieldChange}
                activeFieldData={activeFieldData}
                markUnmarkFieldMandatory={markUnmarkFieldMandatory}
                handleRemoveField={handleRemoveField}
                handleCreateNewFitnessTest={handleCreateNewFitnessTest}
                formSubmissionError={formSubmissionError}
                isParamenterOptionListOpen={isParamenterOptionListOpen}
                handleToggleParameterOptionList={handleToggleParameterOptionList}
                paramenterOptionListRef={paramenterOptionListRef}
                fitnessTestValuesList={fitnessTestValuesList}
            />
        </PopupModal>
    );
};

export default AddFitnessTest;
