import React, { useEffect } from 'react';
import PopupModal from '../../components/widgets/popup-modal';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import RecordFitnessTestForm from '../../components/widgets/record-fitness-test-form';
import RecordFitnessTestViewModel from '../../view-models/actions-view-models/record-fitness-test-view-model';
import { FitnessTestConductedData } from '../../../models/progress-tracker/fitness-test-conducted-data';

interface TakeFitnessTestProps {
    isOpen: boolean;
    handleClose: () => void;
    selectedConductableTestId: number;
    student?: number
}

const RecordFitnessTest = ({
    isOpen,
    handleClose,
    selectedConductableTestId,
    student,
}: TakeFitnessTestProps) => {
    const {
        isLoading,
        handleCloseModal,
        participatingStudents,
        conductingTestData,
        testRecordsPendingStudents,
        testRecordsSubmittedStudents,
        activeStudentRecording,
        handleSetPendingRecordToActive,
        handleRecordSubmit,
        carousalPreviousIndex,
        carousalNextIndex,
        handleCarousalNextClick,
        handleCarousalPreviousClick,
        fieldsFormData,
        handleFieldsInputChange,
        submittedRecords,
        handleEditSubmittedRecord,
        formView,
        handleViewSwitch,
        gridColumns,
        gridRows,
        handlePublishFitnessTestConducted,
        isActiveStudentSkipChecked,
        handleSkipCheckboxChange,
        getMinMaxAgeOfAllTests,
        preSelectedStudent,
        setPreSelectedStudent,
    } = RecordFitnessTestViewModel({ handleClose, selectedConductableTestId });

    useEffect(() => {
        if (student) {
            setPreSelectedStudent(student);
        }
    }, [student])
    
    return (
        <PopupModal
            isOpen={isOpen}
            background='#F6F7F9'
            hideTopBar={true}
            isFullScreen={true}
            handleModalClose={handleCloseModal}
        >
            {isLoading && <FullScreenLoader />}
            <RecordFitnessTestForm 
                handleCloseModal={handleCloseModal}
                conductingTestData={conductingTestData}
                participatingStudents={participatingStudents}
                testRecordsPendingStudents={testRecordsPendingStudents}
                testRecordsSubmittedStudents={testRecordsSubmittedStudents}
                activeStudentRecording={preSelectedStudent ? preSelectedStudent : activeStudentRecording}
                handleSetPendingRecordToActive={handleSetPendingRecordToActive}
                progress={testRecordsSubmittedStudents.length > 0 ? (testRecordsSubmittedStudents.length / participatingStudents.length) * 100 : 0}
                handleRecordSubmit={handleRecordSubmit}
                carousalPreviousIndex={carousalPreviousIndex}
                carousalNextIndex={carousalNextIndex}
                handleCarousalNextClick={handleCarousalNextClick}
                handleCarousalPreviousClick={handleCarousalPreviousClick}
                fieldsFormData={fieldsFormData}
                handleFieldsInputChange={handleFieldsInputChange}
                submittedRecords={submittedRecords}
                handleEditSubmittedRecord={handleEditSubmittedRecord}
                formView={formView}
                handleViewSwitch={handleViewSwitch}
                gridColumns={gridColumns}
                gridRows={gridRows}
                handlePublishFitnessTestConducted={handlePublishFitnessTestConducted}
                isPublished={conductingTestData?.attributes?.isPublished}
                isActiveStudentSkipChecked={isActiveStudentSkipChecked}
                handleSkipCheckboxChange={handleSkipCheckboxChange}
                getMinMaxAgeOfAllTests={getMinMaxAgeOfAllTests}
            />
        </PopupModal>
    );
};

export default RecordFitnessTest;
