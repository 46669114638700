import React from 'react'
import PopupModal from '../../components/widgets/popup-modal'
import CertificationEventStudentInfoForm from '../../components/widgets/certification-event-student-info-form'
import CertificationEventStudentInfoViewModel from '../../view-models/actions-view-models/certification-event-student-info-viewmodel';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { StudentCertificateRecordData } from '../../../models/certifications/student-certificate-record-data';
import { CertificationData } from '../../../models/certifications/certification-data';
import { CertificationEventData } from '../../../models/certifications/certification-event-data';
import { StudentData } from '../../../models/student/student-data';

interface CertificationEventStudentInfoProps {
  isOpen: boolean;
  handleClose: () => void;
  studentsPresentCertification: StudentCertificateRecordData | undefined;
  certificationsList: CertificationData[];
  selectedEventData: CertificationEventData | undefined;
  studentData: StudentData | undefined;
}

const CertificationEventStudentInfo = ({
  isOpen,
  handleClose,
  studentsPresentCertification,
  certificationsList,
  selectedEventData,
  studentData,
}: CertificationEventStudentInfoProps) => {

  const {
    isLoading,
    eventStudentRemarkProvider,
    setEventStudentRemarkProvider,
    eventStudentRemarks,
    setEventStudentRemarks,
    liveCertificationEventStudentRemarks,
    handleAddLiveCertificationEventStudentRemarks,
    handleModalClose,
    handleApproveStudent,
    handleSkipStudent,
  } = CertificationEventStudentInfoViewModel({
    isOpen,
    handleClose,
    selectedEventData,
    studentData,
  });

  return (
    <PopupModal
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      background='white'
      title={`${studentData?.attributes?.firstName} ${studentData?.attributes?.lastName ?? ''}`}
    >
      {isLoading && <FullScreenLoader />}
      <CertificationEventStudentInfoForm 
        studentData={studentData}
        studentsPresentCertification={studentsPresentCertification}
        certificationsList={certificationsList}
        selectedEventData={selectedEventData}
        eventStudentRemarkProvider={eventStudentRemarkProvider}
        setEventStudentRemarkProvider={setEventStudentRemarkProvider}
        eventStudentRemarks={eventStudentRemarks}
        setEventStudentRemarks={setEventStudentRemarks}
        liveCertificationEventStudentRemarks={liveCertificationEventStudentRemarks}
        handleAddLiveCertificationEventStudentRemarks={handleAddLiveCertificationEventStudentRemarks}
        handleApproveStudent={handleApproveStudent}
        handleSkipStudent={handleSkipStudent}
      />
    </PopupModal>
  )
}

export default CertificationEventStudentInfo