import React from 'react';
import style from './style.module.css';
import ArrowRight from '../../../../assets/icons/arrow-right.png';
import { AcademyData } from '../../../../models/academy/academy-data';
import { newClassFormDataType } from '../../../view-models/academies-view-model';
import plusIcon from '../../../../assets/icons/plus.png';
import minusIcon from '../../../../assets/icons/minus.png';
import { CLASS_DAYS_LIST } from './data';
import Loader from '../../bits/loader';

interface NewStudentFormProps {
    classDaysSelected: number[];
    handleDaySelect: (day: number) => void;
    handleInputChange: (field: string, value: string | number[] | number) => void;
    academies: AcademyData[] | undefined;
    newClassFormData: newClassFormDataType;
    handleAddNewClass: (e: React.FormEvent) => void;
    disciplines: { value: number; label: string }[];
    isEditMode: boolean;
    handleTimeAddForDay: (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >
    ) => void;
    handleTimingsInputChange: (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >,
        index: number,
        field: 'from' | 'to',
        value: string
    ) => void;
    handleTimeDeleteForDay: (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >,
        index: number
    ) => void;
    handleChangeClassActiveStatus: () => void;
}

interface DayTimingsElementProps {
    isSelected: boolean;
    handleDaySelect: (day: number) => void;
    newClassFormData: newClassFormDataType;
    handleTimingsInputChange: (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >,
        index: number,
        field: 'from' | 'to',
        value: string
    ) => void;
    handleTimeAddForDay: (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >
    ) => void;
    handleTimeDeleteForDay: (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >,
        index: number
    ) => void;
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    label: string;
    dayValue: number;
}

const DayTimingsElement = ({
    isSelected,
    handleDaySelect,
    newClassFormData,
    handleTimeAddForDay,
    handleTimeDeleteForDay,
    handleTimingsInputChange,
    label,
    day,
    dayValue,
}: DayTimingsElementProps) => {
    return (
        <div className={style.dayCheckBoxInputContainer}>
            <div className={style.dayCheckBoxLabelContainer}>
                <input
                    className={style.dayCheckBoxInput}
                    type="checkbox"
                    checked={isSelected}
                    value={dayValue}
                    onChange={e => handleDaySelect(parseInt(e.target.value))}
                />
                <div>{label}</div>
            </div>
            <div className="flex flex-col gap-[2px] items-center">
                {newClassFormData[day].map((time, index) => (
                    <div
                        key={index}
                        className="flex gap-2"
                    >
                        <div
                            className={`${style.timePickerWrapper} ${!isSelected ? 'bg-gray-100' : ''}`}
                        >
                            <input
                                required={isSelected}
                                disabled={!isSelected}
                                className={style.formTimePicker}
                                type="time"
                                value={time.from}
                                onChange={e =>
                                    handleTimingsInputChange(day, index, 'from', e.target.value)
                                }
                            />
                            <img
                                className={style.timePickerMiddleArrowIcon}
                                src={ArrowRight}
                                alt="right arrow icon"
                            />
                            <input
                                required={isSelected}
                                disabled={!isSelected}
                                className={style.formTimePicker}
                                type="time"
                                value={time.to}
                                onChange={e =>
                                    handleTimingsInputChange(day, index, 'to', e.target.value)
                                }
                            />
                        </div>
                        {index === 0 ? (
                            <button
                                type="button"
                                className="h-6 w-6 flex items-center justify-center bg-slate-500 rounded-md active:scale-95 transition-all disabled:opacity-40"
                                disabled={
                                    !isSelected ||
                                    newClassFormData[day][newClassFormData[day].length - 1].from ===
                                        '' ||
                                    newClassFormData[day][newClassFormData[day].length - 1].to ===
                                        ''
                                }
                                onClick={() => handleTimeAddForDay(day)}
                            >
                                <img
                                    src={plusIcon}
                                    className="p-[6px]"
                                />
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="h-6 w-6 flex items-center justify-center bg-red-300 rounded-md active:scale-95 transition-all disabled:opacity-40"
                                disabled={!isSelected}
                                onClick={() => handleTimeDeleteForDay(day, index)}
                            >
                                <img
                                    src={minusIcon}
                                    className="p-[6px]"
                                />
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const NewClassForm: React.FC<NewStudentFormProps> = ({
    classDaysSelected,
    handleDaySelect,
    handleInputChange,
    academies,
    newClassFormData,
    handleAddNewClass,
    disciplines,
    isEditMode,
    handleTimeAddForDay,
    handleTimingsInputChange,
    handleTimeDeleteForDay,
    handleChangeClassActiveStatus
}) => {

    return (
        <div className={style.newClassFormContainer}>
            <form onSubmit={handleAddNewClass}>
                <div>
                    <div className={style.formInputLabel}>
                        {/* <span className={style.requiredFieldLabelPrompt}>*</span> */}
                        Academy:
                    </div>
                    <select
                        required
                        className={style.formInput}
                        value={newClassFormData.academy}
                        onChange={e => handleInputChange('academy', parseInt(e.target.value))}
                    >
                        <option value="">Select an academy</option>
                        {academies?.map(item => (
                            <option
                                key={item.id}
                                value={item.id}
                            >
                                {item?.attributes?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <div className={style.formInputLabel}>Disciplines:</div>
                    <select
                        required
                        className={style.formInput}
                        value={newClassFormData.discipline}
                        disabled={newClassFormData.academy === 0}
                        onChange={e => handleInputChange('discipline', parseInt(e.target.value))}
                    >
                        <option value="">Select a discipline</option>
                        {disciplines.map((discipline, index) => (
                            <option
                                key={index}
                                value={discipline.value}
                            >
                                {discipline.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <div className={style.formInputLabel}>Class type:</div>
                    <select
                        required
                        className={style.formInput}
                        value={newClassFormData.classType}
                        onChange={e => handleInputChange('classType', e.target.value)}
                    >
                        <option value="">Select class type</option>
                        <option value="Individual">Individual</option>
                        <option value="Group">Group</option>
                    </select>
                </div>
                <div>
                    <div className={style.formInputLabel}>Class name:</div>
                    <input
                        required
                        value={newClassFormData.className}
                        className={style.formInput}
                        placeholder="Type a batch name"
                        onChange={e => handleInputChange('className', e.target.value)}
                    />
                </div>
                <div>
                    <div className={style.formInputLabel}>Skill level:</div>
                    <select
                        required
                        className={style.formInput}
                        value={newClassFormData.skillLevel}
                        onChange={e => handleInputChange('skillLevel', e.target.value)}
                    >
                        <option value="">Select skill level</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Expert">Expert</option>
                    </select>
                </div>
                {/* Age group */}
                <div>
                    <div className={style.formInputLabel}>Age group:</div>
                    <select
                        required
                        className={style.formInput}
                        value={newClassFormData.ageGroup}
                        onChange={e => handleInputChange('ageGroup', e.target.value)}
                    >
                        <option value="">Select age group</option>
                        <option value="Kids">Kids</option>
                        <option value="Adults">Adults</option>
                        <option value="Kids and Adults">Kids and Adults</option>
                    </select>
                </div>
                <div className={style.daySelectionSectionContainer}>
                    <div className={style.formInputLabel}>Select days:</div>
                    <div className="flex flex-col gap-2 bg-slate-100 p-1 px-2 rounded">
                        {CLASS_DAYS_LIST.map(obj => (
                            <DayTimingsElement
                                key={obj.value}
                                isSelected={classDaysSelected.includes(obj.value)}
                                handleDaySelect={handleDaySelect}
                                newClassFormData={newClassFormData}
                                handleTimeAddForDay={handleTimeAddForDay}
                                handleTimeDeleteForDay={handleTimeDeleteForDay}
                                handleTimingsInputChange={handleTimingsInputChange}
                                day={obj.day}
                                dayValue={obj.value}
                                label={obj.label}
                            />
                        ))}
                    </div>
                </div>
                <div className={isEditMode ? style.saveClassBtnContainer : `flex justify-end mt-8 `}>
                    {isEditMode && <button type='button' className={style.deleteClassBtn} onClick={handleChangeClassActiveStatus}>Delete class</button>}
                    <button
                        type="submit"
                        className={style.saveClassBtn}
                    >
                        {isEditMode ? 'Update class' : 'Save class'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default NewClassForm;
