import React from 'react'
import { AddFitnessTestDetailsType, FieldInfoType } from '../../../view-models/actions-view-models/add-fitness-test-view-model'
import { PARAMETER_OPTIONS } from './data';
import { FitnessTestValueData } from '../../../../models/progress-tracker/fitness-test-value-data';

type AddFitnessTestFormProps = {
    fitnessTestDetailsFormData: AddFitnessTestDetailsType;
    handleInputChange: (field: string, value: string) => void;
    handleAddNewField: (e: React.FormEvent) => void;
    handleActiveFieldChange: (field: string, value: string | boolean | number) => void;
    activeFieldData: FieldInfoType;
    markUnmarkFieldMandatory: (index: number) => void;
    handleRemoveField: (index: number) => void;
    handleCreateNewFitnessTest: () => void;
    formSubmissionError: string | null;
    isParamenterOptionListOpen: boolean;
    handleToggleParameterOptionList: () => void;
    paramenterOptionListRef: React.RefObject<HTMLDivElement>;
    fitnessTestValuesList: FitnessTestValueData[];
}

const AddFitnessTestForm = ({
    fitnessTestDetailsFormData,
    handleInputChange,
    handleAddNewField,
    handleActiveFieldChange,
    activeFieldData,
    markUnmarkFieldMandatory,
    handleRemoveField,
    handleCreateNewFitnessTest,
    formSubmissionError,
    isParamenterOptionListOpen,
    handleToggleParameterOptionList,
    paramenterOptionListRef,
    fitnessTestValuesList,
}: AddFitnessTestFormProps) => {

  return (
    <div className='w-[90vw] sm:w-[700px] p-2'>
        <div>
            <div className='text-sm mt-3 mb-1'>Test Name: {formSubmissionError === 'Test name is required' ? <span className='text-red-500 text-xs'>{formSubmissionError}</span> : null}</div>
            <input
                className={`text-sm text-gray-500 resize-none w-full ${formSubmissionError === 'Test name is required' ? 'border-red-500' : 'border-gray-500'} border-opacity-30 rounded`}
                placeholder="Enter an identifier for the test"
                value={fitnessTestDetailsFormData.name}
                onChange={e => handleInputChange('name', e.target.value)}
            />
        </div>
        <div className='mt-4 text-sm mb-1'>Fields: {formSubmissionError === 'At least one field is required' ? <span className='text-red-500 text-xs'>{formSubmissionError}</span> : null}</div>
        <div className={`rounded border-2 ${formSubmissionError === 'At least one field is required' ? 'border-red-500' : 'border-gray-300'} p-2`}>
            <form 
                className='rounded bg-gray-300 p-2 flex gap-1'
                onSubmit={handleAddNewField}
            >
                <div className='w-10 relative'>
                    <div 
                        className='w-10 h-10 flex justify-center items-center bg-slate-50 rounded cursor-pointer'
                        // onClick={handleToggleParameterOptionList}
                    >
                        {PARAMETER_OPTIONS.find(option => option.value === activeFieldData.parameter)?.icon}
                    </div>
                    {isParamenterOptionListOpen && (
                        <div 
                            className='absolute top-10 mt-1 left-0 w-auto bg-slate-50 rounded shadow-md z-10'
                            ref={paramenterOptionListRef} 
                        >
                            {PARAMETER_OPTIONS.map(option => (
                                <div 
                                    key={option.value} 
                                    onClick={() => handleActiveFieldChange('parameter', option.value)}
                                    className={`flex items-center gap-1 p-1 cursor-pointer hover:bg-slate-200 ${option.value === activeFieldData.parameter ? 'bg-slate-200' : ''}`}
                                >
                                    <span>{option.icon}</span>
                                    <span>{option.label}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <select
                    required
                    className='text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded'
                    value={activeFieldData.label}
                    onChange={e => {
                        handleActiveFieldChange('parameter', fitnessTestValuesList.find(data => data?.attributes?.testName === e.target.value)?.attributes?.parameter ?? '');
                        handleActiveFieldChange('label', e.target.value);
                        handleActiveFieldChange('unit', fitnessTestValuesList.find(data => data?.attributes?.testName === e.target.value)?.attributes?.unit ?? '');
                        handleActiveFieldChange('test', Number(fitnessTestValuesList.find(data => data?.attributes?.testName === e.target.value)?.id ?? ''));
                    }}
                >
                    <option value={''}>Select Test</option>
                    {fitnessTestValuesList.filter(data => data?.attributes?.testName !== 'BMI')
                        .filter(data => !fitnessTestDetailsFormData.fields.map(field => field.test).includes(data?.id))?.map(data => (
                        <option key={data?.id} value={data?.attributes?.testName}>
                            {PARAMETER_OPTIONS.find(option => option.value === data?.attributes?.parameter)?.label} - {data?.attributes?.testName} (for {' '}
                                {data?.attributes?.values && data.attributes.values.length > 0 ? 
                                    `${Math.min(...data.attributes.values.map(value => value?.age || Infinity))} to ${Math.max(...data.attributes.values.map(value => value?.age || -Infinity))}`
                                    : 'N/A'}
                            {' '}years old)
                        </option>
                    ))}
                </select>
                <select
                    required
                    className='text-sm text-gray-500 resize-none w-[150px] border-gray-500 border-opacity-30 rounded'
                    disabled
                    value={activeFieldData.unit}
                    onChange={e => handleActiveFieldChange('unit', e.target.value)}
                >
                    <option className='bg-slate-300' value=''>Select Unit</option>
                    <option value='METER'>Meter</option>
                    <option value='CENTIMETER'>Centimeter</option>
                    <option value='SECOND'>Second</option>
                    <option value='MINUTE'>Minute</option>
                    <option value='REPETITION'>Repetition</option>
                    <option value='KILOGRAM'>Kilogram</option>
                    <option value='GRAM'>Gram</option>
                </select>
                <button 
                    type='submit' 
                    disabled={activeFieldData.label === ''}
                    className='flex items-center justify-center px-3 bg-primary rounded text-white font-semibold cursor-pointer disabled:opacity-20 active:scale-95 transition-all'
                >
                    Add
                </button>
            </form>
            <div className='py-2 flex flex-col gap-1 h-[220px] overflow-y-auto thin-scrollbar'>
                {fitnessTestDetailsFormData.fields.map((field, index) => (
                    <div key={index} className='flex rounded px-2 gap-2 py-1 border border-slate-400 items-center'>
                        <div 
                            className='w-10 h-10 flex justify-center items-center rounded '
                        >
                            {PARAMETER_OPTIONS.find(option => option.value === field.parameter)?.icon}
                        </div>
                        <div className='flex-grow grid grid-cols-3'>
                            <div className='col-span-2 flex-grow flex gap-2 items-center'>
                                <div className='text-lg font-semibold text-gray-600'>{field.label}</div>
                                <div className='text-xs bg-slate-100 px-2'>{field.unit.charAt(0) + field.unit.slice(1).toLowerCase()}</div>
                            </div>
                            <div 
                                className='flex text-sm items-center justify-center gap-2 bottom-2 border rounded px-2 py-1 cursor-pointer' 
                                onClick={() => markUnmarkFieldMandatory(index)}
                            >
                                <div>Mandatory?</div>
                                <input 
                                    type='checkbox' 
                                    className='w-4 h-4 rounded-full focus:ring-0 text-slate-500 cursor-pointer'
                                    checked={field.isMandatory}
                                    // onChange={() => markUnmarkFieldMandatory(index)}
                                />
                            </div>
                        </div>
                        <div 
                            className='px-2 py-1 rounded bg-red-300 cursor-pointer active:bg-red-500 transition-all'
                            onClick={() => handleRemoveField(index)}
                        >
                            Remove
                        </div>
                    </div>
                ))}
                {fitnessTestDetailsFormData.fields.length === 0 && <div className='h-full w-full flex items-center justify-center text-lg font-semibold text-gray-500'>No fields added</div>}
            </div>
        </div>
        <div>
            <div className='flex mt-8 justify-end'>
                <div 
                    className='px-8 py-2 bg-primary rounded text-lg text-white font-semibold cursor-pointer active:scale-95 transition-all'
                    onClick={handleCreateNewFitnessTest}
                >
                    Create
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddFitnessTestForm