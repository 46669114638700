import { useState } from 'react';
import { BookDemoFormAttributes } from '../../utils/types/book-demo-form-attribute';
import { useMutation } from 'react-query';
import { LandingPageService } from '../../services/landing-page-services';

const landingPageViewModel = () => {
    const initialFormData = {
        fullName: '',
        email: '',
        location: '',
        contactNo: '',
        academyName: '',
    };
    const [bookDemoFormData, setBookDemoFormData] =
        useState<BookDemoFormAttributes>(initialFormData);
    const [isDemoBooked, setIsDemoBooked] = useState<boolean>(false);

    const handleInputChange = (field: string, value: string) => {
        setBookDemoFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const postNewDemo = async (
        data: BookDemoFormAttributes
    ): Promise<BookDemoFormAttributes | undefined> => {
        const response = await LandingPageService.instance.postBookDemo(data);
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On Add Demo Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewDemo,
        isLoading: isNewDemoAdding,
        isSuccess: isNewDemoAdded,
    } = useMutation(postNewDemo, {
        onSuccess: data => {
            setIsDemoBooked(true);
            alert('Demo has been booked!');
        },
        onError: error => {
            // console.log(`On student add Error:`, error);
            alert('Failed to book demo! Please try again');
            // Error actions
        },
    });

    const handleBookDemoFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        addNewDemo(bookDemoFormData);
    };

    const handleClick = () => {
        throw new Error('This is a custom error thrown on button click');
    };

    return {
        bookDemoFormData,
        handleInputChange,
        handleBookDemoFormSubmit,
        isDemoBooked,
        handleClick,
    };
};

export default landingPageViewModel;
