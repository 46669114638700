import React from 'react';
import style from './style.module.css';
import { StudentAttributes } from '../../../../models/student/student-attributes';
// import {BiSolidLike, BiSolidDislike} from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import Loader from '../../bits/loader';
import horizontalEllipsis from '../../../../assets/icons/horizontal-ellipsis.png';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import SystemIcon from '../../../../assets/icons/system.png';

interface StudentsListItemProps {
    studentAttributes: StudentAttributes;
    present: boolean;
    absent: boolean;
    showLoader: boolean;
    addAttendance: (present: boolean, onLeave: boolean, note: string) => void;
    updateAttendance: (present: boolean, onLeave: boolean, note: string) => void;
    isSystemMarked: boolean;
    isLatest: boolean;
}

const AttendanceListItem = ({
    studentAttributes,
    present = false,
    absent = false,
    showLoader = false,
    addAttendance,
    updateAttendance,
    isSystemMarked,
    isLatest,
}: StudentsListItemProps) => {
    return (
        <div className={style.studentListItemWrapper}>
            <div className={style.studentIdentityWrapper}>
                <div className={style.studentPhoto}>
                    <img
                        className={style.listItemImage}
                        src={studentAttributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? studentAttributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                        alt="list_image"
                    />
                    {isSystemMarked && (
                        <div className={style.studentPhotoTag}>
                            <img
                                className={style.listItemImageTagIcon}
                                src={SystemIcon}
                                alt="list_image_tag"
                            />
                        </div>
                    )}
                </div>
                <div className={style.studentName}>
                    {studentAttributes?.firstName ?? ''} {studentAttributes?.middleName ?? ''}{' '}
                    {studentAttributes?.lastName ?? ''}
                </div>
            </div>

            <div className={style.studentPresentStatsWrap}>
                {!isLatest ? (
                    <div className={`${style.studentNotMarked} opacity-30 animate-pulse`}>
                        <div className={style.attendanceStatusText}>Loading...</div>
                    </div>
                ) : showLoader ? (
                    <Loader />
                ) : present ? (
                    <div className={style.studentPresent}>
                        <FaCheck className={style.presentMarkerIcon} />
                        <div className={style.attendanceStatusText}>Present</div>
                        <IoClose
                            className={style.onleaveMarkerIconDisabled}
                            onClick={updateAttendance.bind(this, false, false, '')}
                        />
                    </div>
                ) : absent ? (
                    <div className={style.studentAbsent}>
                        <FaCheck
                            className={style.presentMarkerIconDisabled}
                            onClick={updateAttendance.bind(this, true, false, '')}
                        />
                        <div className={style.attendanceStatusText}>Absent</div>
                        <IoClose className={style.onleaveMarkerIcon} />
                    </div>
                ) : (
                    <div className={style.onLeaveMarkerWrap}>
                        <div className={style.studentNotMarked}>
                            <FaCheck
                                className={style.presentMarkerIcon}
                                onClick={addAttendance.bind(this, true, false, '')}
                            />
                            <div className={style.attendanceStatusText}>Not Marked</div>
                            <IoClose
                                className={style.onleaveMarkerIcon}
                                onClick={addAttendance.bind(this, false, false, '')}
                            />
                        </div>
                        {/* <span className={style.onLeaveMarkerText}>
                            {' '}
                            On Leave{' '}
                        </span>{' '} */}
                    </div>
                )}
            </div>
            <div className={style.moreOptionsIconContainer}>
                <img src={horizontalEllipsis} />
            </div>

            {/* Temporary Commented */}
            {/* <div className={style.studentActionButtonWrapper}>
                <button className={style.timerActionButton}>
                    {' '}
                    <FaRegClock className={style.ActionIconButtonBlack} />{' '}
                </button>
                <button className={style.likeActionButton}>
                    {' '}
                    <BiSolidLike className={style.ActionIconButton} />{' '}
                </button>
                <button className={style.dislikeActionButton}>
                    {' '}
                    <BiSolidDislike className={style.ActionIconButton} />{' '}
                </button>

                <button className={style.actionButton}> Add Notes </button>
                <button className={style.actionButtonBorderd}>
                    {' '}
                    Attendence History{' '}
                </button>
            </div> */}
        </div>
    );
};

export default AttendanceListItem;
