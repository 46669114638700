// academies-reducer.ts
export type AcademiesState = {
    openTab: number;
    isModalVisible: boolean;
    currentPage: number;
    selectedAcademy: number;
    selectedClass: number;
};

export type AcademiesAction =
    | { type: 'setOpenTab'; payload: number }
    | { type: 'showModal' }
    | { type: 'hideModal' }
    | { type: 'setSelectedAcademy'; payload: number }
    | { type: 'setSelectedClass'; payload: number }
    | { type: 'deselectAcademy' }
    | { type: 'setCurrentPage'; payload: number };

export const academiesReducer = (
    state: AcademiesState,
    action: AcademiesAction
): AcademiesState => {
    switch (action.type) {
        case 'setOpenTab':
            return { ...state, openTab: action.payload };
        case 'showModal':
            return { ...state, isModalVisible: true };
        case 'hideModal':
            return { ...state, isModalVisible: false };
        case 'setSelectedAcademy':
            return { ...state, selectedAcademy: action.payload };
        case 'setSelectedClass':
            return { ...state, selectedClass: action.payload };
        default:
            return state;
    }
};
