import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getVirtualMeetingSlice, saveVirtualMeetingSlice } from "../helpers/helpers";
import { VirtualMeetingDataWrapper } from "../../models/virtual-meeting/virtual-meeting-data-wrapper";

const virtualMeetingState: {
    activeMeetingData: VirtualMeetingDataWrapper | null,
    isMeetOpen: boolean
} = getVirtualMeetingSlice()

const virtualMeetingSlice = createSlice({
    name: 'virtualMeeting',
    initialState: virtualMeetingState,
    reducers: {
        setActiveMeetingData: (state, action: PayloadAction<VirtualMeetingDataWrapper | null>) => {
            state.activeMeetingData = action.payload;
            saveVirtualMeetingSlice(state);
        },
        setMeetingView: (state, action: PayloadAction<boolean>) => {
            state.isMeetOpen = action.payload;
            saveVirtualMeetingSlice(state);
        },
    },
});

export const { setActiveMeetingData, setMeetingView } = virtualMeetingSlice.actions;
export default virtualMeetingSlice.reducer;