import React from 'react';
import { FeeStructureData } from '../../../../models/fee-structure/fee-structure-data';

interface FeeStructureListItemProps {
    data: FeeStructureData;
    isExpaned: boolean;
    expandFeeStructureCard: () => void;
}

const FeeStructureListItem = ({
    data,
    isExpaned,
    expandFeeStructureCard,
}: FeeStructureListItemProps) => {
    return (
        <div className="w-full bg-white p-2 border ">
            <div className="flex justify-between items-center w-full">
                <div className="flex flex-col gap-2 flex-grow">
                    <div className="font-semibold text-lg">{data?.attributes?.type}</div>
                    <div className="grid grid-cols-2 w-full text-md">
                        <div>Academy: {data?.attributes?.academy?.data?.attributes?.name}</div>
                        {data?.attributes?.classes?.data?.length > 0 && (
                            <div>
                                Classes:{' '}
                                {data?.attributes?.classes?.data?.length === 1
                                    ? data?.attributes?.classes?.data[0]?.attributes?.class_name
                                    : `${data?.attributes?.classes?.data[0]?.attributes?.class_name} and ${data?.attributes?.classes?.data.length - 1} more`}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="text-blue-500 cursor-pointer p-2"
                    onClick={expandFeeStructureCard}
                >
                    View Details
                </div>
            </div>
            {isExpaned && (
                <div>
                    <hr className=" m-2" />
                    {data?.attributes?.type === 'Training' && (
                        <div className="p-2 border rounded-lg mt-2 bg-slate-100 flex flex-col">
                            <div className="flex w-full items-center justify-center">
                                <div className="flex-grow grid grid-cols-9 text-lg font-semibold text-gray-600">
                                    <div className="text-center col-span-3  p-1">Schedule</div>
                                    <div className="text-center col-span-3  p-1">Sessions/week</div>
                                    <div className="text-center col-span-3  p-1">Amount</div>
                                </div>
                            </div>
                            <hr className="border-[1px] border-gray-300 m-1" />
                            <div className="w-full items-center justify-center overflow-y-auto thin-scrollbar flex-grow">
                                {data?.attributes?.trainingFees.map((item, index: number) => (
                                    <div
                                        className="text-center grid grid-cols-9 w-full my-1"
                                        key={index}
                                    >
                                        <div className="col-span-3 p-1">{item.frequency}</div>
                                        <div className="col-span-3 p-1">{item.sessionsPerWeek}</div>
                                        <div className="col-span-3 p-1">&#x20b9;{item.amount}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {data?.attributes?.type === 'Event/Competition' && (
                        <div className="p-2 border rounded-lg mt-2 bg-slate-100 flex flex-col">
                            <div className="flex w-full items-center justify-center">
                                <div className="flex-grow grid grid-cols-9 text-lg font-semibold text-gray-600">
                                    <div className="text-center col-span-3  p-1">Type</div>
                                    <div className="text-center col-span-3  p-1">Description</div>
                                    <div className="text-center col-span-3  p-1">Amount</div>
                                </div>
                            </div>
                            <hr className="border-[1px] border-gray-300 m-1" />
                            <div className="w-full items-center justify-center overflow-y-auto thin-scrollbar flex-grow">
                                {data?.attributes?.qualificationFees.map((item, index: number) => (
                                    <div
                                        className="text-center grid grid-cols-9 w-full my-1"
                                        key={index}
                                    >
                                        <div className="col-span-3 p-1">{item.type}</div>
                                        <div className="col-span-3 p-1">{item.info}</div>
                                        <div className="col-span-3 p-1">&#x20b9;{item.amount}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {data?.attributes?.type === 'Registration' && (
                        <div className="p-2 border rounded-lg mt-2 bg-slate-100 flex flex-col">
                            Registration Fee: &#x20b9;{data?.attributes?.registrationFees?.amount}
                        </div>
                    )}
                    {data?.attributes?.type === 'Uniform/Gear' && (
                        <div className="p-2 border rounded-lg mt-2 bg-slate-100 flex flex-col">
                            <div className="flex w-full items-center justify-center">
                                <div className="flex-grow grid grid-cols-9 text-lg font-semibold text-gray-600">
                                    <div className="text-center col-span-3  p-1">Type</div>
                                    <div className="text-center col-span-3  p-1">Description</div>
                                    <div className="text-center col-span-3  p-1">Amount</div>
                                </div>
                            </div>
                            <hr className="border-[1px] border-gray-300 m-1" />
                            <div className="w-full items-center justify-center overflow-y-auto thin-scrollbar flex-grow">
                                {data?.attributes?.apparelFees.map((item, index: number) => (
                                    <div
                                        className="text-center grid grid-cols-9 w-full my-1"
                                        key={index}
                                    >
                                        <div className="col-span-3 p-1">
                                            {item?.apparelType}{' '}
                                            {item?.apparelType === 'Gear' ? `(${item?.name})` : ''}
                                        </div>
                                        <div className="col-span-3 p-1">
                                            {item?.sizeOrDescription}
                                        </div>
                                        <div className="col-span-3 p-1">&#x20b9;{item?.amount}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FeeStructureListItem;
