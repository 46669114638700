// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__Jj6xU {

    min-width: 85vw;

    padding: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

.style_formUploadInputBtnWrapper__juniu {

    position: relative;

    display: inline-block;

    height: 32px;

    min-height: 32px;

    width: 140px;

    min-width: 140px;

    overflow: hidden;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(0 0 0 / var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.style_formUploadInputBtnLabel__fpOjE {

    cursor: pointer
}

.style_formUploadInput__f4S6R {

    position: absolute;

    inset: 0px;

    height: 100%;

    width: 100%;

    opacity: 0
}
.style_formUploadInputLabel__0cdaX {

    position: absolute;

    inset: 0px;

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/bulk-student-upload-form/style.module.css"],"names":[],"mappings":"AACI;;IAAA,eAA+B;;IAA/B,eAA+B;;IAA/B,mBAA+B;;IAA/B;AAA+B;;AAI/B;;IAAA,kBAA0H;;IAA1H,qBAA0H;;IAA1H,YAA0H;;IAA1H,gBAA0H;;IAA1H,YAA0H;;IAA1H,gBAA0H;;IAA1H,gBAA0H;;IAA1H,iBAA0H;;IAA1H,sBAA0H;;IAA1H,mDAA0H;;IAA1H,kBAA0H;;IAA1H;AAA0H;;AAI1H;;IAAA;AAAqB;;AAIrB;;IAAA,kBAA+C;;IAA/C,UAA+C;;IAA/C,YAA+C;;IAA/C,WAA+C;;IAA/C;AAA+C;AAG/C;;IAAA,kBAAiE;;IAAjE,UAAiE;;IAAjE,aAAiE;;IAAjE,sBAAiE;;IAAjE,mBAAiE;;IAAjE;AAAiE","sourcesContent":[".container {\n    @apply min-w-[85vw] p-2 text-sm;\n}\n\n.formUploadInputBtnWrapper {\n    @apply relative inline-block overflow-hidden h-[32px] w-[140px] min-h-[32px] min-w-[140px] bg-gray-200 border border-black;\n}\n\n.formUploadInputBtnLabel {\n    @apply cursor-pointer;\n}\n\n.formUploadInput {\n    @apply absolute inset-0 opacity-0 w-full h-full;\n}\n.formUploadInputLabel {\n    @apply absolute inset-0 flex flex-col items-center justify-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__Jj6xU`,
	"formUploadInputBtnWrapper": `style_formUploadInputBtnWrapper__juniu`,
	"formUploadInputBtnLabel": `style_formUploadInputBtnLabel__fpOjE`,
	"formUploadInput": `style_formUploadInput__f4S6R`,
	"formUploadInputLabel": `style_formUploadInputLabel__0cdaX`
};
export default ___CSS_LOADER_EXPORT___;
