import React, { useState } from 'react';
import CalendarViewModel from '../../view-models/calendar-view-model';
import DashboardViewModel from '../../view-models/dashboard-view-model';
import {
    Calendar as BigCalendar,
    momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment'
import '../../../index.css';
const localizer = momentLocalizer(moment);

const AcademyScheduler = () => {
    const { schedulerData } = DashboardViewModel();
    const { enquiriesData, handleEventClick, showPopup, selectedEvent, closePopup } = CalendarViewModel();

    return (
            <div className='h-[95vh]'>
                <BigCalendar
                    views={['week', 'day']}
                    events={[...enquiriesData, ...schedulerData]}
                    localizer={localizer}
                    defaultView='week'
                    onSelectEvent={handleEventClick}
                />
            
            {showPopup && (
                <div className='fixed grid gap-4 top-[50%] sm:left-[50%] left-[17%] bg-white p-5 shadow-md z-10'
                >
                    <h3 className='font-bold text-2xl'>{selectedEvent?.title}</h3>
                    <p>
                        <strong>Start:</strong> {selectedEvent?.start?.toLocaleString()}
                    </p>
                    <p>
                        <strong>End:</strong> {selectedEvent?.end?.toLocaleString()}
                    </p>
                    {selectedEvent?.title === 'ENQUIRY/DEMO' ? 
                            <>
                                <p><strong>Name:</strong> {selectedEvent?.name}</p>
                                <p><strong>Contact:</strong> {selectedEvent?.contact}</p>
                                <p><strong>Discipline:</strong> {selectedEvent?.discipline}</p> 
                            </> :
                            <>
                                <p><strong>No. of students:</strong> {selectedEvent?.noOfStudents}</p>
                            </>
                    }
                </div>
            )}

            {showPopup && (
                <div className='fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] z-[1]'
                    onClick={closePopup}
                />
            )}
            </div>
    );
};

export default AcademyScheduler;
