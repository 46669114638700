import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { useMutation, useQueryClient } from 'react-query';
import { FormEvent, useEffect, useState } from 'react';
import { CertificationEventPostData } from '../../../models/certifications/certification-event-post-data';
import { CertificationEventDataWrapper } from '../../../models/certifications/certification-event-data-wrapper';
import { CertificationService } from '../../../services/certification-service';
import { CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE } from '../../../utils/constants/constants';
import { StudentCertificateRecordData } from '../../../models/certifications/student-certificate-record-data';
import { CertificationEventData } from '../../../models/certifications/certification-event-data';
import { CertificationData } from '../../../models/certifications/certification-data';

export interface ParticipantsList {
    studentId: number;
    promotionLevel: number;
    status: "SKIPPED" | "APPROVED" | "DEFAULT" | "PROMOTED";
    remarks: {
        remark: string;
        givenBy: string;
    }[];
}

export interface CertificationEventFormDataType {
    eventName: string;
    date: string;
    venue: string;
    eventFees: number;
    instructorsInputFieldValue: string;
    instructors: string[];
    chiefExaminersInputFieldValue: string;
    chiefExaminers: string[];
    externalExaminersInputFieldValue: string;
    externalExaminers: string[];
    students: ParticipantsList[];
}

const AddCertificationEventViewModel = ({
    handleClose,
    activeAcademySelected,
    activeDisciplineSelected,
    allStudentCertificationRecordsOfDiscipline,
    isEditing,
    editingEventData,
    isOpen,
    certificationsList,
}: {
    handleClose: () => void;
    activeAcademySelected: number;
    activeDisciplineSelected: number;
    allStudentCertificationRecordsOfDiscipline: StudentCertificateRecordData[];
    isEditing: boolean;
    editingEventData: CertificationEventData | null;
    isOpen: boolean;
    certificationsList: CertificationData[];
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();
    const [expandedSection,setExpandedSection] = useState<'EVENT-DETAILS' | 'INSTRUCTORS' | 'EXAMINERS' | 'STUDENTS'>('EVENT-DETAILS');

    const initialCertificationEventFormData = {
        eventName: '',
        date: new Date().toISOString().split('T')[0],
        eventFees: 0,
        venue: '',
        instructorsInputFieldValue: '',
        instructors: [],
        chiefExaminersInputFieldValue: '',
        chiefExaminers: [],
        externalExaminersInputFieldValue: '',
        externalExaminers: [],
        students: [],
    }
    const [certificationEventFormData, setCertificationEventFormData] = useState<CertificationEventFormDataType>(initialCertificationEventFormData);

    useEffect(() => {
        if(isEditing && editingEventData) {
            setCertificationEventFormData({
                eventName: editingEventData?.attributes?.eventName,
                date: new Date( editingEventData?.attributes?.date).toISOString().split('T')[0],
                eventFees:  editingEventData?.attributes?.eventFees ?? 0,
                venue:  editingEventData?.attributes?.venue ?? '',
                instructorsInputFieldValue: '',
                instructors: editingEventData?.attributes?.instructors === '' ? [] : editingEventData?.attributes?.instructors.split(','),
                chiefExaminersInputFieldValue: '',
                chiefExaminers: editingEventData?.attributes?.chiefExaminers === '' ? [] : editingEventData?.attributes?.chiefExaminers.split(','),
                externalExaminersInputFieldValue: '',
                externalExaminers: editingEventData?.attributes?.externalExaminers === '' ? [] : editingEventData?.attributes?.externalExaminers.split(','),
                students: editingEventData?.attributes?.students.map(item => {
                    return {
                        ...item,
                        studentId: item?.studentId,
                        promotionLevel: certificationsList?.find(certification => certification?.id === item?.certificationId)?.attributes?.level ?? 0
                    }
                }),
            })
        }
    },[isOpen,isEditing,editingEventData])

    const handleInputChange = (value: string | number, field: string) => {
        setCertificationEventFormData({
            ...certificationEventFormData,
            [field]: value
        });
    }

    const handleAddStudent = (id: number) => {
        setCertificationEventFormData(prev => {
            return {
                ...prev,
                students: [
                    ...prev.students, 
                    {
                        status: 'DEFAULT',
                        remarks: [],
                        studentId: id, 
                        promotionLevel: allStudentCertificationRecordsOfDiscipline.some(record => record.attributes?.student?.data?.id === id) ? 
                            (allStudentCertificationRecordsOfDiscipline.find(record => record.attributes?.student?.data?.id === id)?.attributes?.certificationInfo?.data?.attributes?.level ?? 0) + 1 :
                            0
                    }
                ]
            }
        })
    }

    const handleStudentPromotionLevelChange = (studentId: number, promotionLevel: number) => {
        setCertificationEventFormData(prev => {
            return {
                ...prev,
                students: prev.students.map(student => {
                    if (student.studentId === studentId) {
                        return {
                            ...student,
                            promotionLevel
                        }
                    } else {
                        return student;
                    }
                })
            }
        })
    }

    const handleRemoveStudent = (id: number) => {
        setCertificationEventFormData(prev => {
            return {
                ...prev,
                students: prev.students.filter(student => student.studentId !== id)
            }
        })
    }

    const [expandedStudentCard, setExpandedStudentCard] = useState<number | null>(null);

    const handleExpandStudentCard = (id: number) => {
        if (expandedStudentCard === id) {
            setExpandedStudentCard(null);
        } else {
            setExpandedStudentCard(id);
        }
    }

    const handleAddInstructor = () => {
        if (!certificationEventFormData.instructorsInputFieldValue.trim()) return;
        setCertificationEventFormData(prev => {
            return {
                ...prev,
                instructorsInputFieldValue: '',
                instructors: [...prev.instructors, prev.instructorsInputFieldValue],
            }
        })
    }

    const handleRemoveInstructor = (index: number) => {
        setCertificationEventFormData(prev => {
            const newData = [...prev.instructors];
            newData.splice(index, 1);
            return {
                ...prev,
                instructors: newData
            }
        })
    }

    const handleAddChiefExaminer = () => {
        if (!certificationEventFormData.chiefExaminersInputFieldValue.trim()) return;
        setCertificationEventFormData(prev => {
            return {
                ...prev,
                chiefExaminersInputFieldValue: '',
                chiefExaminers: [...prev.chiefExaminers, prev.chiefExaminersInputFieldValue],
            }
        })
    }

    const handleRemoveChiefExaminer = (index: number) => {
        setCertificationEventFormData(prev => {
            const newData = [...prev.chiefExaminers];
            newData.splice(index, 1);
            return {
                ...prev,
                chiefExaminers: newData
            }
        })
    }

    const handleAddExternalExaminer = () => {
        if (!certificationEventFormData.externalExaminersInputFieldValue.trim()) return;
        setCertificationEventFormData(prev => {
            return {
                ...prev,
                externalExaminersInputFieldValue: '',
                externalExaminers: [...prev.externalExaminers, prev.externalExaminersInputFieldValue],
            }
        })
    }

    const handleRemoveExternalExaminer = (index: number) => {
        setCertificationEventFormData(prev => {
            const newData = [...prev.externalExaminers];
            newData.splice(index, 1);
            return {
                ...prev,
                externalExaminers: newData
            }
        })
    }
    
    const handleModalClose = () => {
        setCertificationEventFormData(initialCertificationEventFormData);
        setExpandedStudentCard(null)
        setExpandedSection('EVENT-DETAILS');
        handleClose();
    }

    const postCertificateEvent = async (
        data: CertificationEventPostData
    ): Promise<CertificationEventDataWrapper | undefined> => {
        const response = await CertificationService.instance.addCertificationEvent(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewCertificationEvent,
        isLoading: isNewCertificationEventAdding,
    } = useMutation(postCertificateEvent, {
        onSuccess: (data) => {
            handleModalClose();
            queryClient.invalidateQueries(CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE);
        },
        onError: (error) => {
            alert('Failed to add certification Event! Please try again');
        },
    });

    const putCertificateEvent = async (
        data: CertificationEventPostData
    ): Promise<CertificationEventDataWrapper | undefined> => {
        const response = await CertificationService.instance.putCertificationEvent(data,editingEventData?.id ?? 0);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateCertificationEvent,
        isLoading: isNewCertificationEventUpdating,
    } = useMutation(putCertificateEvent, {
        onSuccess: (data) => {
            handleModalClose();
            queryClient.invalidateQueries(CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE);
        },
        onError: (error) => {
            alert('Failed to update certification Event! Please try again');
        },
    });

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(isEditing) {
            updateCertificationEvent({
                eventName: certificationEventFormData?.eventName,
                date: new Date(certificationEventFormData?.date),
                venue: certificationEventFormData?.venue,
                eventFees: certificationEventFormData?.eventFees,
                chiefExaminers: certificationEventFormData?.chiefExaminers.join(','),
                externalExaminers: certificationEventFormData?.externalExaminers.join(','),
                instructors: certificationEventFormData?.instructors.join(','),
                students: certificationEventFormData?.students.map(student => {
                    return {
                        studentId: student.studentId,
                        certificationId: certificationsList?.find(cert => cert?.attributes?.level === student.promotionLevel)?.id ?? 0
                    }
                })
            })
        }else {
            addNewCertificationEvent({
                eventName: certificationEventFormData?.eventName,
                date: new Date(certificationEventFormData?.date),
                venue: certificationEventFormData?.venue,
                eventFees: certificationEventFormData?.eventFees,
                chiefExaminers: certificationEventFormData?.chiefExaminers.join(','),
                externalExaminers: certificationEventFormData?.externalExaminers.join(','),
                instructors: certificationEventFormData?.instructors.join(','),
                users: [userState?.user?.id ?? 0],
                academy: activeAcademySelected,
                discipline: activeDisciplineSelected,
                students: certificationEventFormData?.students.map(student => {
                    return {
                        studentId: student.studentId,
                        certificationId: certificationsList?.find(cert => cert?.attributes?.level === student.promotionLevel)?.id ?? 0
                    }
                })
            })
        }
    }

    const [serchTerm, setSearchTerm] = useState('');
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);

    return {
        isLoading: false,
        handleModalClose,
        handleInputChange,
        certificationEventFormData,
        handleAddInstructor,
        handleAddChiefExaminer,
        handleAddExternalExaminer,
        handleRemoveInstructor,
        handleRemoveChiefExaminer,
        handleRemoveExternalExaminer,
        handleFormSubmit,
        expandedSection,
        setExpandedSection,
        serchTerm,
        setSearchTerm,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        handleAddStudent,
        handleRemoveStudent,
        expandedStudentCard,
        handleExpandStudentCard,
        handleStudentPromotionLevelChange,
    };
};

export default AddCertificationEventViewModel;
