import { SettingTab } from '../../view-models/settings-view-model';

export const Notificationoptions = [
    { value: 'newStudent', label: 'New Student' },
    { value: 'dropStudent', label: 'Student Dropout' },
];

export const Studentoptions = [{ value: 'Attendance', label: 'Attendance Marked' }];

export const Emailoptions = [
    { value: 'Email', label: 'Email' },
    { value: 'Whatsapp', label: 'Whatsapp' },
    { value: 'Both', label: 'Both' },
];

export const Feepaymentoptions = [
    { value: 'pendingFees', label: 'Pending Fees' },
    { value: 'dueFees', label: 'Reminder before due date' },
];

export const enquireoptions = [{ value: 'followup', label: 'Follow up message' }];

export const followupoptions = [
    { value: '3', label: '3 Days' },
    { value: '5', label: '5 Days' },
    { value: '7', label: '7 Days' },
    { value: '10', label: '10 Days' },
    { value: '15', label: '15 Days' },
];

export const pendingFeesReminderOptions = [
    { value: '0', label: 'Same Day' },
    { value: '1', label: '1 Day' },
    { value: '7', label: '7 Days' },
    { value: '10', label: '10 Days' },
];

// Define options for reminder before due date dropdown
export const reminderBeforeDueDateOptions = [
    { value: '1', label: '1 Day' },
    { value: '3', label: '3 Days' },
    { value: '7', label: '7 Days' },
    { value: '10', label: '10 Days' },
    { value: '15', label: '15 Days' },
];

export const reportOptions = [
    { value: 'montlyFeeReports', label: 'Monthly Fees Received' },
    // { value: 'reminder', label: 'Reminder before due date' },
];

export const frequencyfeereports = [
    { value: '1', label: 'First Day of month(previous month)' },
    { value: '15', label: '15th Day of month(current month)' },
    { value: '31', label: 'last Day of month(current month)' },
];

export const attendanceOptions = [
    { value: 'attendanceData', label: 'Date Wise Attendance Reports' },
    // { value: 'reminder', label: 'Reminder before due date' },
];

export const frequencyattendancereports = [
    { value: '1', label: 'First Day of month(previous month)' },
    { value: '15', label: '15th Day of month(current month)' },
    { value: '31', label: 'last Day of month(current month)' },
];

export const tabTitles: SettingTab[] = [
    'My Details',
    'Password',
    'Team',
    'Billing',
    'Student Notification',
    'Report',
    'Payments',
];
