// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formInput__USh-R {
    width: 100%;
    resize: none;
    border-radius: 0.25rem;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
    --tw-border-opacity: 0.3;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/add-class-card/style.module.css"],"names":[],"mappings":"AACI;IAAA,WAAyF;IAAzF,YAAyF;IAAzF,sBAAyF;IAAzF,yDAAyF;IAAzF,wBAAyF;IAAzF,mBAAyF;IAAzF,oBAAyF;IAAzF,oBAAyF;IAAzF;AAAyF","sourcesContent":[".formInput {\n    @apply text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInput": `style_formInput__USh-R`
};
export default ___CSS_LOADER_EXPORT___;
