import React from 'react';

import arrowLeft from '../../../../assets/icons/chevron-left.png';
import arrowRight from '../../../../assets/icons/chevron-right.png';

interface CustomCarouselProps {
    imageArray: string[] | undefined;
    currentImageIndex: number;
    handlePrev: (length: number) => void;
    handleNext: (length: number) => void;
    handleDotClick: (index: number) => void;
    isApiUpload: boolean;
    topCount: boolean;
}

const CustomCarousel = ({
    handlePrev,
    imageArray,
    currentImageIndex,
    handleNext,
    handleDotClick,
    isApiUpload,
    topCount,
}: CustomCarouselProps) => {
    return (
        <div className="flex flex-col items-center w-full h-full">
            {topCount && (
                <div className="w-full flex justify-end px-4">
                    {currentImageIndex + 1} of {imageArray?.length}
                </div>
            )}
            <div className="relative h-full w-full bg-slate-100 flex items-center justify-center">
                <div
                    className="absolute left-0 h-full flex items-center cursor-pointer w-12"
                    onClick={() => handlePrev(imageArray?.length ?? 0)}
                >
                    <img
                        src={arrowLeft}
                        className="h-12 w-12"
                        alt="Previous"
                    />
                </div>

                <img
                    src={
                        !isApiUpload
                            ? `data:image/jpeg;base64,${imageArray?.[currentImageIndex]}`
                            : `${imageArray?.[currentImageIndex]}`
                    }
                    // src={`data:image/jpeg;base64,${imageArray?.result[currentImageIndex]}`}
                    className="h-[240px] sm:h-[32vw] w-full object-contain"
                    alt="processed-attendance"
                />
                <div
                    className="absolute right-0 h-full flex items-center cursor-pointer w-12"
                    onClick={() => handleNext(imageArray?.length ?? 0)}
                >
                    <img
                        src={arrowRight}
                        className="h-12 w-12"
                        alt="Next"
                    />
                </div>
                <div className="absolute bottom-0 mb-4 flex space-x-2">
                    {imageArray?.map((_, index) => (
                        <div
                            key={index}
                            className={`w-3 h-3 rounded-full cursor-pointer ${
                                currentImageIndex === index ? 'bg-blue-500' : 'bg-gray-300'
                            }`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomCarousel;
