import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { ACADEMY_QUERY, CLASS_DETAILS_QUERY, DISCIPLINE_QUERY, FITNESS_TEST_CONDUCTED_LIST_QUERY } from '../../../utils/constants/constants';
import { AcademyListWrapper } from '../../../models/academy/academy-list-wrapper';
import ApiResponse from '../../../utils/types/api-response';
import { ClassListWrapper } from '../../../models/class/class-list-wrapper';
import { ClassData } from '../../../models/class/class-data';
import { AcademyData } from '../../../models/academy/academy-data';
import { FitnessTestDetailsData } from '../../../models/progress-tracker/fitness-test-details-data';
import { AcademiesService } from '../../../services/academies-service';
import ClassService from '../../../services/class-service';
import { StudentData } from '../../../models/student/student-data';
import { ProgressTrackerService } from '../../../services/progress-tracker-service';
import { FitnessTestConductedPostData } from '../../../models/progress-tracker/fitness-test-conducted-postdata';
import { FitnessTestConductedDataWrapper } from '../../../models/progress-tracker/fitness-test-conducted-data-wrapper';
import { FitnessTestConductedData } from '../../../models/progress-tracker/fitness-test-conducted-data';


export interface TakeFitnessTestFormType {
    template: number;
    academy: number | null;
    discipline: number | null;
    classes: number[] | null;
    studentsData: StudentData[];
    remarks: string;
}

const TakeFitnessTestViewModel = ({
    handleClose,
    selectedFitnessTest,
    handleRecordFitnessTestModalOpen,
}: {
    handleClose: () => void;
    selectedFitnessTest: FitnessTestDetailsData;
    handleRecordFitnessTestModalOpen: (data: FitnessTestConductedData) => void;
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const { isFetching: isAcademiesFetching, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isFetching: isClassFetching, data: classesData } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const initialTakeFitnessTestFormData: TakeFitnessTestFormType = {
        template: selectedFitnessTest?.id,
        academy: null,
        discipline: null,
        classes: null,
        studentsData: [],
        remarks: '',
    }

    const [takeFitnessTestFormData, setTakeFitnessTestFormData] = useState<TakeFitnessTestFormType>(initialTakeFitnessTestFormData);
    const [isStudentFilterParametersSubmitted, setIsStudentFilterParametersSubmitted] = useState<boolean>(false);

    const handleStudentFilterParametersSubmit = () => {
        const allStudentsFilteredOnSelectedParameters: StudentData[] = Array.from(
            new Map(
              classesData?.data?.data
                ?.filter(c => c?.attributes?.academies?.data?.id === takeFitnessTestFormData?.academy)
                ?.filter(c => c?.attributes?.class_discipline?.data?.id === takeFitnessTestFormData?.discipline)
                ?.filter(c => {
                  return !takeFitnessTestFormData?.classes?.length || takeFitnessTestFormData?.classes?.includes(c?.id);
                })
                ?.flatMap(c => c?.attributes?.students?.data)
                ?.filter(s => !takeFitnessTestFormData?.studentsData?.some(student => student.id === s?.id))
                ?.map(s => [s?.id, s])
            ).values()
        );
        
        if(takeFitnessTestFormData.classes === null || takeFitnessTestFormData.classes?.length === 0) {
            setTakeFitnessTestFormData(prev => {
                return {
                    ...prev,
                    classes: mapClassesToOptions().map(item => item.value),
                    studentsData: [...prev.studentsData, ...allStudentsFilteredOnSelectedParameters],
                }
            })
        }else{
            setTakeFitnessTestFormData(prev => {
                return {
                    ...prev,
                    studentsData: [...prev.studentsData, ...allStudentsFilteredOnSelectedParameters],
                }
            })
        }
        setIsStudentFilterParametersSubmitted(true);
    }


    const handleStudentFilterParametersReset = () => {
        setTakeFitnessTestFormData(prev => {
            return {
                ...prev,
                academy: null,
                discipline: null,
                classes: null,
            };
        });
        setIsStudentFilterParametersSubmitted(false);
    }

    const handleFormInputChange = (field: string, value: string | number | number[] | undefined) => {
        setTakeFitnessTestFormData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    }

    const mapAcademyToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data?.map((item: AcademyData) => ({
                value: item.id,
                label: item?.attributes?.name,
            }));
        }
        return [];
    };

    /**
     * Maps the disciplines data to an array of options object.
     *
     * @return {Array<{ value: number; label: string }> | []} The mapped array of options.
     */
    const mapDisciplinesToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data && takeFitnessTestFormData?.academy) {
            return academiesData?.data?.data?.find(a => a?.id === takeFitnessTestFormData?.academy)?.attributes?.selectedDisciplines?.data.map((d) => ({
                value: d.id,
                label: d?.attributes?.name,
            })) ?? [];
        }
        return [];
    };

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data) {
            return classesData?.data?.data
                ?.filter(c => c?.attributes?.academies?.data?.id === takeFitnessTestFormData?.academy)
                ?.filter(c => takeFitnessTestFormData?.discipline !== null && c?.attributes?.class_discipline?.data?.id === takeFitnessTestFormData?.discipline)
                ?.map((item: ClassData) => ({
                    value: item.id,
                    label: item?.attributes?.class_name,
                }));
        }
        return [];
    };

    const postNewFitnessTestConductable = async (
        data: FitnessTestConductedPostData
    ): Promise<FitnessTestConductedDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.postNewFitnessTestConducted(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };
    
    const {
        mutate: addFitnessTestConductable,
        isLoading: isFitnessTestConductableAdding,
        isSuccess: isFitnessTestConductableAdded,
    } = useMutation(postNewFitnessTestConductable, {
        onSuccess: data => {
            if(data?.data) {
                alert('Fitness test Successfully scheduled to be conducted today!');
                handleCloseModal();
                handleRecordFitnessTestModalOpen(data?.data);
                queryClient.invalidateQueries(FITNESS_TEST_CONDUCTED_LIST_QUERY);
            }
        },
        onError: error => {
            alert('Failed to schedule fitness test! Please try again');
        },
    });

    const handleCunductableFitnessTestSubmit = () => {
        const body: FitnessTestConductedPostData = {
            template: selectedFitnessTest?.id,
            academy: takeFitnessTestFormData?.academy,
            classes: takeFitnessTestFormData?.classes,
            discipline: takeFitnessTestFormData?.discipline,
            participants: takeFitnessTestFormData?.studentsData.map(item => item.id),
            users: [userState?.user?.id ?? 0],
            remarks: takeFitnessTestFormData?.remarks ?? '',
        }
        addFitnessTestConductable(body);
    }

    const handleCloseModal = () => {
        handleClose();
        setTakeFitnessTestFormData(initialTakeFitnessTestFormData);
        setIsStudentFilterParametersSubmitted(false);
    }

    const [studentSearchTerm, setStudentSearchTerm] = useState('');
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
    const inputRef = useRef<null | HTMLInputElement>(null);
    const suggestionBoxRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
          const target = event.target as Node;
      
          if (
            inputRef.current &&
            !inputRef.current.contains(target) &&
            suggestionBoxRef.current &&
            !suggestionBoxRef.current.contains(target)
          ) {
            setIsSearchInputOnFocus(false);
          }
        }
      
        document.addEventListener('click', handleClickOutside);
      
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    const handleStudentSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStudentSearchTerm(event.target.value);
    }

    const { isLoading: isStudentDataFetchLoading, data: studentSuggestionListData} = useQuery({
        queryKey: [studentSearchTerm],
        queryFn: () => ProgressTrackerService.instance.getStudentSugessionList(userState?.user?.id ?? 0, studentSearchTerm),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const handleSugestionListItemClick = (data: StudentData) => {
        setTakeFitnessTestFormData(prev => {
            return {
                ...prev,
                studentsData: [...prev.studentsData, data],
            };
        });
    }

    const handleRemoveStudent = (id: number) => {
        setTakeFitnessTestFormData(prev => {
            return {
                ...prev,
                studentsData: prev.studentsData.filter(student => student.id !== id),
            };
        });
    }

    const handleClearAllStudents = () => {
        setTakeFitnessTestFormData(prev => {
            return {
                ...prev,
                studentsData: [],
            };
        });
    }

    return {
        isLoading: isAcademiesFetching || isClassFetching || isFitnessTestConductableAdding,
        handleCloseModal,
        mapAcademyToOptions,
        takeFitnessTestFormData,
        handleFormInputChange,
        mapDisciplinesToOptions,
        mapClassesToOptions,
        studentSearchTerm,
        isSearchInputOnFocus,
        handleStudentSearchTermChange,
        setIsSearchInputOnFocus,
        studentSuggestionListData: studentSuggestionListData?.data?.data ?? [],
        isStudentDataFetchLoading,
        handleSugestionListItemClick,
        inputRef,
        suggestionBoxRef,
        handleRemoveStudent,
        isStudentFilterParametersSubmitted,
        handleStudentFilterParametersSubmit,
        handleStudentFilterParametersReset,
        handleClearAllStudents,
        handleCunductableFitnessTestSubmit,
    };
};

export default TakeFitnessTestViewModel;
