import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { ProgressTrackerService } from '../../../services/progress-tracker-service';
import { FitnessTestDetailsPostData } from '../../../models/progress-tracker/fitness-test-details-postdata';
import { FITNESS_TEST_DETAILS_LIST_QUERY, FITNESS_TEST_VALUES_LIST } from '../../../utils/constants/constants';
import { FitnessTestDetailsDataWrapper } from '../../../models/progress-tracker/fitness-test-details-data-wrapper';

export interface FieldInfoType {
    label: string;
    unit: 'METER' | 'CENTIMETER' | 'SECOND' | 'MINUTE' | 'REPETITION' | 'KILOGRAM' | 'GRAM' | '';
    isMandatory: boolean;
    parameter: 'DEFAULT' | 'SPEED' | 'STAMINA' | 'STRENGTH' | 'POWER' | 'FLEXIBILITY' | 'AGILITY' | 'COORDINATION' | 'BALANCE' | '';
    test: number | null;
}

export interface AddFitnessTestDetailsType {
    name: string;
    fields: FieldInfoType[];
}

const AddFitnessTestViewModel = ({
    handleClose,
}: {
    handleClose?: () => void;
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const { isFetching: isFitnessTestValuesListFetching, data: fitnessTestValuesList } = useQuery({
        queryKey: [FITNESS_TEST_VALUES_LIST],
        queryFn: () => ProgressTrackerService.instance.getFitnessTestValuesList(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const initialFitnessTestDetailsFormData: AddFitnessTestDetailsType = {
        name: '',
        fields: [
            {
                label: 'Height',
                unit: 'METER',
                isMandatory: true,
                parameter: 'DEFAULT',
                test: null,
            },
            {
                label: 'Weight',
                unit: 'KILOGRAM',
                isMandatory: true,
                parameter: 'DEFAULT',
                test: null,
            }
        ],
    };
    const initialActiveFieldData: FieldInfoType = {
        label: '',
        unit: '',
        isMandatory: true,
        parameter: '',
        test: null,
    }
    const [fitnessTestDetailsFormData, setFitnessTestDetailsFormData] = useState<AddFitnessTestDetailsType>(initialFitnessTestDetailsFormData);
    const [activeFieldData, setActiveFieldData] = useState<FieldInfoType>(initialActiveFieldData);

    const handleInputChange = (field: string, value: string) => {
        setFormSubmissionError(null);
        setFitnessTestDetailsFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleActiveFieldChange = (field: string, value: string | boolean | number) => {
        // if (field === 'label' && typeof value === 'string' && value.length > 0) {
        //     value = value[0].toUpperCase() + value.slice(1);
        // }
        setActiveFieldData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    }

    const markUnmarkFieldMandatory = (index: number) => {
        setFitnessTestDetailsFormData(prevFormData => {
            const updatedFields = prevFormData.fields.map((field, i) =>
                i === index ? { ...field, isMandatory: !field.isMandatory } : field
            );
            return {
                ...prevFormData,
                fields: updatedFields,
            };
        });
    };

    const handleAddNewField = (e: React.FormEvent) => {
        e.preventDefault();
        setFormSubmissionError(null);
        setFitnessTestDetailsFormData(prevFormData => ({
            ...prevFormData,
            fields: [
                ...prevFormData.fields,
                {
                    label: activeFieldData.label,
                    unit: activeFieldData.unit,
                    isMandatory: activeFieldData.isMandatory,
                    parameter: activeFieldData.parameter,
                    test: activeFieldData.test,
                },
            ],
        }));
        setActiveFieldData(initialActiveFieldData);
    }

    const handleRemoveField = (index: number) => {
        setFitnessTestDetailsFormData(prevFormData => {
            const updatedFields = [...prevFormData.fields];
            updatedFields.splice(index, 1);
            return {
                ...prevFormData,
                fields: updatedFields,
            };
        });
    }

    const postNewFitnessTest = async (
        data: FitnessTestDetailsPostData
    ): Promise<FitnessTestDetailsDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.postNewFitnessTestDetails(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const handleCloseModal = () => {
        setFitnessTestDetailsFormData(initialFitnessTestDetailsFormData);
        setActiveFieldData(initialActiveFieldData);
        if (handleClose) {
            handleClose();
        }
        }

    const {
        mutate: addFitnessTest,
        isLoading: isFitnessTestAdding,
        isSuccess: isFitnessTestAdded,
    } = useMutation(postNewFitnessTest, {
        onSuccess: data => {
            alert('Added a fitness test Successfully!');
            handleCloseModal();
            queryClient.invalidateQueries(FITNESS_TEST_DETAILS_LIST_QUERY);
        },
        onError: error => {
            alert('Failed to add fitness test! Please try again');
        },
    });

    const [formSubmissionError, setFormSubmissionError] = useState<string | null>(null);
    const handleCreateNewFitnessTest = () => {
        if(fitnessTestDetailsFormData.name.trim() === '') {
            setFormSubmissionError('Test name is required');
            return;
        }else if(fitnessTestDetailsFormData.fields.length === 0) {
            setFormSubmissionError('At least one field is required');
            return;
        }else{
            addFitnessTest({
                ...fitnessTestDetailsFormData, 
                fieldsRequired: fitnessTestDetailsFormData.fields,
                users: userState?.user?.id ?? 0
            });
        }
    }

    const [isParamenterOptionListOpen, setIsParamenterOptionListOpen] = useState(false);

    const handleToggleParameterOptionList = () => {
        setIsParamenterOptionListOpen(prevState => !prevState);
    };

    const paramenterOptionListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsParamenterOptionListOpen(false);
    },[activeFieldData.parameter])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (paramenterOptionListRef.current && !paramenterOptionListRef.current.contains(event.target as Node)) {
                setIsParamenterOptionListOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return {
        isLoading: isFitnessTestValuesListFetching,
        fitnessTestDetailsFormData,
        handleInputChange,
        handleAddNewField,
        activeFieldData,
        markUnmarkFieldMandatory,
        handleActiveFieldChange,
        handleRemoveField,
        handleCreateNewFitnessTest,
        formSubmissionError,
        isParamenterOptionListOpen,
        handleToggleParameterOptionList,
        paramenterOptionListRef,
        handleCloseModal,
        fitnessTestValuesList: fitnessTestValuesList?.data?.data || [],
        isFitnessTestAdding
    };
};

export default AddFitnessTestViewModel;
