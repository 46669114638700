import { DocumentData } from '../models/document/document-data';
import { invokeApi } from '../utils/helpers/invoke-api';
import { DeleteImageResponse } from '../utils/types/delete-image-response';
import { uploadImageresponsedata } from '../utils/types/upload-Image-response-data';
import { uploadImageresponsedataWrapper } from '../utils/types/upload-Image-response-data-Wrapper';
import { uploadimagespostdata } from '../utils/types/upload-images-post-data';

export class UploadService {
    private static _instance: UploadService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new UploadService());
    }

    public uploadImage = async (data: FormData) => {
        return invokeApi<FormData, uploadImageresponsedata[]>({
            route: 'upload/',
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public deleteImage = async (imageId: number) => {
        return invokeApi<unknown, DeleteImageResponse>({
            route: `upload/files/${imageId}`,
            method: 'DELETE',
            privateRoute: true,
        });
    };

    public uploadDocument = async (data: FormData) => {
        return invokeApi<FormData, uploadImageresponsedata[]>({
            route: 'upload/',
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };
}
