import React, { ChangeEvent } from 'react'
import { MdClear } from 'react-icons/md';
import { GLOBAL_SEARCH_OPTIONS_DATA } from './data';
import { useNavigate } from 'react-router-dom';
import { StudentData } from '../../../../models/student/student-data';
import defaultAvatar from '../../../../assets/icons/user.png';
import { calculateAge, getFormattedDate } from '../../../../utils/helpers/helpers';
import { Routes } from '../../../navigation/routes';
import { EnquiryData } from '../../../../models/enquiry/enquiry-data';


type Props = {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    isStudentDataFetchLoading: boolean;
    studentSuggestionListData: StudentData[];
    isEnquiriesSearchResultFetching: boolean;
    enquiriesSearchResultList: EnquiryData[];
}

const GlobalSearchScreen = ({
    searchTerm,
    setSearchTerm,
    isStudentDataFetchLoading,
    studentSuggestionListData,
    isEnquiriesSearchResultFetching,
    enquiriesSearchResultList,
}: Props) => {

    if(searchTerm.length === 0) return null;

    const navigate = useNavigate();

    return (
        <div className='z-[9999] backdrop-blur-md w-screen h-screen absolute flex flex-col items-center bg-black bg-opacity-60 justify-center'>
            <div className='sm:w-3/4 w-[90%] flex items-center justify-center p-4 gap-4'>
                <input 
                    type="text" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className='w-full rounded-md shadow-lg border-0 focus:ring-0 text-lg px-4 py-2'
                />
                <MdClear onClick={() => setSearchTerm('')} size={44} className='text-slate-600 cursor-pointer bg-slate-200 rounded-md shadow-lg' />
            </div>
            <div className='bg-slate-200 sm:w-3/4 w-[95%] h-[90%] sm:h-3/4 rounded-md shadow-lg p-4 overflow-y-auto'>
                <div className='flex gap-4 flex-wrap'>
                    {GLOBAL_SEARCH_OPTIONS_DATA.filter(item => item.value.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
                        <div 
                            key={index} 
                            className='p-4 py-2 bg-slate-300 font-semibold rounded-lg cursor-pointer' 
                            onClick={() => {
                                setSearchTerm('');
                                navigate(item.link)
                            }}
                        >
                            {item.icon} {item.label}
                        </div>
                    ))}
                </div>
                <hr className='my-4 sm:my-8 mx-[30%] border-2 border-slate-400'/>
                <div className='grid grid-cols-2 gap-2'>
                    <div className='col-span-2 sm:col-span-1'>
                        <div className='font-semibold text-xl uppercase p-1'>Students</div>
                        <div className='bg-slate-50 max-h-[250px] sm:max-h-[400px] rounded overflow-y-auto flex flex-col gap-1 p-1'>
                            {isStudentDataFetchLoading ? (
                                [1,2,3,4,5,6,7,8,9,10].map(i => (
                                    <div key={i} className='p-2 flex gap-2 rounded bg-slate-300 animate-pulse w-full'>
                                        <div className='h-14 w-14 rounded-full bg-slate-400 animate-pulse flex-shrink-0'></div>
                                        <div className='flex flex-col gap-1 animate-pulse w-full'>
                                            <div className='w-1/2 bg-slate-200 animate-pulse h-6 rounded'></div>
                                            <div className='w-1/4 bg-slate-100 animate-pulse h-5 rounded'></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                studentSuggestionListData.length === 0 ? (
                                    <div className='w-full h-full flex flex-col gap-2 items-center justify-center py-10'>
                                        <div className='text-slate-600 font-semibold text-2xl drop-shadow-lg'>No Students Found!</div>
                                    </div>
                                ) : (
                                    <div className='w-full flex-grow rounded overflow-y-auto flex flex-col gap-1'>
                                        {studentSuggestionListData.map((student, index) => (
                                            <div key={index} className='p-2 flex flex-col gap-2 rounded hover:bg-slate-300 cursor-pointer w-full shadow-sm shadow-gray-400' onClick={() => {
                                                setSearchTerm('');
                                                navigate(`${Routes.Students}/${student?.id}`);
                                            }}>
                                                <div className='flex items-center gap-2'>
                                                    <img className='h-14 w-14 rounded-full object-cover' src={student?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultAvatar} alt='' />
                                                    <div className=''>
                                                        <div>{`${student?.attributes?.firstName} ${student?.attributes?.middleName ?? ''} ${student?.attributes?.lastName ?? ''}`}</div>
                                                        <div className='text-xs text-slate-600'>{calculateAge(new Date(student?.attributes?.dob))}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <div className='font-semibold text-xl uppercase p-1 sm:text-right'>Enquiries</div>
                        <div className='bg-slate-50 max-h-[250px] sm:max-h-[400px] rounded overflow-y-auto flex flex-col gap-1 p-1'>
                            {isEnquiriesSearchResultFetching ? (
                                [1,2,3,4,5,6,7,8,9,10].map(i => (
                                    <div key={i} className='p-2 flex gap-2 rounded bg-slate-300 animate-pulse w-full'>
                                        <div className='flex flex-col gap-1 animate-pulse w-full'>
                                            <div className='w-1/2 bg-slate-200 animate-pulse h-6 rounded'></div>
                                            <div className='w-1/4 bg-slate-100 animate-pulse h-5 rounded'></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                enquiriesSearchResultList.length === 0 ? (
                                    <div className='w-full h-full flex flex-col gap-2 items-center justify-center py-10'>
                                        <div className='text-slate-600 font-semibold text-2xl drop-shadow-lg'>No Enquiries Found!</div>
                                    </div>
                                ) : (
                                    <div className='w-full flex-grow rounded overflow-y-auto flex flex-col gap-1'>
                                        {enquiriesSearchResultList.map((enq, index) => (
                                            <div key={index} className='p-2 flex flex-col gap-2 rounded hover:bg-slate-300 cursor-pointer w-full shadow-sm shadow-gray-400' onClick={() => {
                                                setSearchTerm('');
                                                navigate(`${Routes.Enquires}`)
                                            }}>
                                                <div className='flex flex-col gap-1'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>{`${enq?.attributes?.firstName ?? 'Student name not added'} ${enq?.attributes?.middleName ?? ''} ${enq?.attributes?.lastName ?? ''}`}</div>
                                                        <div className='text-xs'>created on: {getFormattedDate(enq?.attributes?.createdAt)}</div>
                                                    </div>
                                                    <div className='flex items-center justify-between'>
                                                        <div className=''>Parent name: {enq?.attributes?.parentName}</div>
                                                        <div className='text-sm text-slate-600'>Contact: {enq?.attributes?.contactNo}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalSearchScreen