import React from 'react';
import style from './style.module.css';
import { StudentRelation } from '../../../../models/student/student-relation';

type Props = {
    relation: StudentRelation;
    index: number;
};

const StudentRelationItem = ({ relation, index }: Props) => {
    return (
        <>
            <hr />
            <div className={style.studentRelationsContainerWrapper}>
                <div className={style.studentRelationContainerMain}>
                    <div className={style.studentRelationContainer}>
                        <div className={style.studentProfileAcademicDetailsLabel}>
                            Relation {index + 1}
                            <div className={style.studentProfileAcademicDetailsContent}>
                                {relation.relation}
                            </div>
                        </div>
                        <div className={style.studentProfileAcademicDetailsLabel}>
                            Relation {index + 1} - Gender
                            <div className={style.studentProfileAcademicDetailsContent}>
                                {relation.gender}
                            </div>
                        </div>
                    </div>
                    <div className={style.studentRelationContainer}>
                        <div className={style.studentProfileAcademicDetailsLabel}>
                            Relation {index + 1} - FULL NAME
                            <div className={style.studentProfileAcademicDetailsContent}>
                                {relation.name}
                            </div>
                        </div>
                        <div className={style.studentProfileAcademicDetailsLabel}>
                            Relation {index + 1} - PROFESSION
                            <div className={style.studentProfileAcademicDetailsContent}>
                                {relation.profession}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.studentRelationContainerMain}>
                    <div className={style.studentRelationContainer}>
                        <div className={style.studentProfileAcademicDetailsLabel}>
                            Relation {index + 1} - PHONE NUMBER
                            <div className={style.studentProfileAcademicDetailsContent}>
                                {relation.contactNo}
                            </div>
                        </div>
                    </div>
                    <div className={style.studentRelationContainer}>
                        <div className={style.studentProfileAcademicDetailsLabel}>
                            Relation 2 - EMAIL ID
                            <div className={style.studentProfileAcademicDetailsContent}>
                                {relation.emailId}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentRelationItem;
