import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import SettingsViewModel from '../../view-models/settings-view-model';
import settingsPhoto from '../../../assets/icons/user.png';
import studenticon from '../../../assets/icons/studentsiconblack.svg';
import feepaymenticon from '../../../assets/icons/Fee payment.svg';
import enquiriesicon from '../../../assets/icons/enquiriesblack.png';
import scheduleicon from '../../../assets/icons/booking-confirmed-icon 1.png';
import Select from 'react-select';
import CoachListItem from '../../components/pieces/coach-list-item';
import UserProfileIcon from '../../../../assets/icons/user-profile.png';
import { DetailsFormDataType } from '../../view-models/settings-view-model';
import {
    Notificationoptions,
    Studentoptions,
    Emailoptions,
    Feepaymentoptions,
    enquireoptions,
    followupoptions,
    pendingFeesReminderOptions,
    reminderBeforeDueDateOptions,
    reportOptions,
    frequencyfeereports,
    attendanceOptions,
    frequencyattendancereports,
    tabTitles,
} from './data';
import PhotoUploadInput from '../../components/widgets/photo-upload-input';
import HeaderMenuViewModel from '../../view-models/header-menu-view-model';
import PhotoUploadForm from '../../components/widgets/photo-upload-form';
import Loader from '../../components/bits/loader';
import { Routes } from '../../navigation/routes';
import CustomSelectModal from '../../components/widgets/custom-select-modal';

const Settings = () => {
    const {
        activeTab,
        changeTab,
        usersData,
        userState,
        reportFormData,
        detailFormData,
        disabled,
        handleReportsInputChange,
        handleDetailsInputChange,
        handleSubmitReportSettings,
        handleSubmitNotificationSettings,
        handleSubmitDetailSettings,
        handleNotificationsInputChange,
        handleFormReset,
        // handleOnChangeImage,
        // detailImageData,
        isProfileImagePosting,
        handleSupplierProfileImgChange,
        notificationsFormData,
        resetPasswordFields,
        handlePasswordInputChange,
        passwordFormData,
        handlePasswordChangeSubmit,
        navigate,
        isValidVPA,
        emailSubject,
        emailBody,
        supplierPaymentDetails,
    } = SettingsViewModel();

    const renderMyDetailsContent = () => (
        <div className={styles.settingsTabContent}>
            <form onSubmit={event => handleSubmitDetailSettings(event)}>
                <div className={styles.photoContainer}>
                    <div className={styles.formInputLabel}>
                        Profile photo:
                        <span className={styles.imageMaxSizePrompt}>(max 200KB)</span>
                    </div>
                    <div className="flex flex-col-reverse">
                        <div className={styles.formImageInputBtnWrapper}>
                            <label
                                htmlFor="fileInput"
                                className={styles.formImageInputBtnLabel}
                            >
                                <input
                                    type="file"
                                    id="fileInput"
                                    className={styles.formImageInput}
                                    // onChange={(e) => {handleOnChangeImage(e.target.files[0])}}
                                    onChange={handleSupplierProfileImgChange}
                                    accept="image/*"
                                />
                                <div className={styles.formImageInputLabel}>
                                    <div>Change</div>
                                </div>
                            </label>
                        </div>
                        {detailFormData.photo && !isProfileImagePosting ? (
                            <div className="h-[160px] w-[160px] min-h-[160px] min-w-[160px] border mb-1">
                                <img
                                    src={detailFormData.photo}
                                    alt="profile image"
                                    className="h-full w-full object-contain"
                                />
                            </div>
                        ) : (
                            <div className="min-h-[160px] h-[160px] w-[160px] text-center min-w-[160px] border mb-1 flex justify-center items-center text-gray-500 ">
                                {!isProfileImagePosting ? 'No image uploaded' : <Loader />}
                            </div>
                        )}
                    </div>
                </div>

                <div className="sm:flex">
                    <div className={styles.firstformGroup}>
                        <label htmlFor="firstName">First Name:</label>
                        <input
                            type="text"
                            id="firstName"
                            className="sm:w-3/4 w-full px-3 py-2 border border-solid border-gray-300 rounded"
                            name="firstName"
                            value={detailFormData.firstName}
                            onChange={e => handleDetailsInputChange('firstName', e.target.value)}
                        />
                    </div>

                    <div className={styles.firstformGroup}>
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            type="text"
                            id="lastName"
                            className="sm:w-3/4 w-full px-3 py-2 border border-solid border-gray-300 rounded"
                            name="lastName"
                            value={detailFormData.lastName}
                            onChange={e => handleDetailsInputChange('lastName', e.target.value)}
                        />
                    </div>
                </div>

                <div className="sm:flex">
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="sm:w-3/4 w-full px-3 py-2 border border-solid border-gray-300 rounded"
                            placeholder="cj@example.com"
                            value={detailFormData.email}
                            onChange={e => handleDetailsInputChange('email', e.target.value)}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label
                            className="flex justify-between sm:w-3/4 w-full"
                            htmlFor="vpa"
                        >
                            VPA:{' '}
                            {!isValidVPA && (
                                <p className="text-xs ml-2 mt-1 text-red-500">Invalid VPA format</p>
                            )}
                        </label>
                        <input
                            type="text"
                            id="vpa"
                            name="vpa"
                            className={`sm:w-3/4 w-full px-3 py-2 border border-solid border-gray-300 rounded ${isValidVPA ? '' : ' ring-2 ring-red-400 focus:ring-2 focus:ring-red-400 border-none focus:border-none'}`}
                            placeholder="username@bank"
                            value={detailFormData.VPA}
                            onChange={e => handleDetailsInputChange('VPA', e.target.value)}
                        />
                    </div>
                </div>

                <div className={styles.buttons}>
                    <button
                        type="button"
                        className={styles.saveButton}
                        onClick={handleFormReset}
                        disabled={
                            // userState?.supplierDetails?.attributes
                            //     ?.firstName === detailFormData.firstName &&
                            // userState?.supplierDetails?.attributes?.lastName ===
                            //     detailFormData.lastName &&
                            // userState?.supplierDetails?.attributes?.userInfo
                            //     ?.data?.attributes?.email ===
                            //     detailFormData.email
                            disabled
                        }
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={styles.saveButton}
                        disabled={
                            // userState?.supplierDetails?.attributes
                            //     ?.firstName === detailFormData.firstName &&
                            // userState?.supplierDetails?.attributes?.lastName ===
                            //     detailFormData.lastName &&
                            // userState?.supplierDetails?.attributes?.userInfo
                            //     ?.data?.attributes?.email ===
                            //     detailFormData.email
                            disabled
                        }
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );

    const renderPasswordContent = () => {
        return (
            <div className="h-auto bg-white">
                <div className={styles.settingsTabContent}>
                    <form onSubmit={e => handlePasswordChangeSubmit(e)}>
                        <div className={styles.passwordForm}>
                            <div className={styles.formGroup}>
                                <label htmlFor="currentPassword">Current Password:</label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    required
                                    value={passwordFormData.currentPassword}
                                    onChange={e =>
                                        handlePasswordInputChange('currentPassword', e.target.value)
                                    }
                                    className={styles.passwordInput}
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="newPassword">New Password:</label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    required
                                    value={passwordFormData.newPassword}
                                    onChange={e =>
                                        handlePasswordInputChange('newPassword', e.target.value)
                                    }
                                    className={styles.passwordInput}
                                />
                            </div>
                        </div>

                        <div className={styles.buttons}>
                            <button
                                type="button"
                                className={styles.cancelButton}
                                onClick={resetPasswordFields}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={styles.saveButton}
                                disabled={
                                    passwordFormData.currentPassword === '' ||
                                    passwordFormData.newPassword.length < 6
                                }
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    const renderTeamContent = () => {
        return (
            <div className="bg-white p-2">
                {usersData?.map((item, index) => (
                    <div
                        // className={style.studentsListItemContainer}
                        key={index}
                    >
                        <CoachListItem
                            isExpandable={false}
                            coachData={item}
                        />
                    </div>
                ))}
                <div className="flex justify-end w-full my-4">
                    <button
                        className="bg-primary p-2 px-4 text-white rounded"
                        onClick={() => navigate(Routes.UserManagement, { replace: false })}
                    >
                        User Management
                    </button>
                </div>
            </div>
        );
    };

    const renderBillingContent = () => {
        return (
            <div className={styles.settingsTabContent}>
                <div className="w-full text-center">
                    <div className="text-orange-500 text-2xl font-semibold my-[200px]">
                        Coming Soon...
                    </div>
                </div>
                {/* <div className="mt-16 h-auto">
                  <div className="flex justify-end">
                      <button type="submit" className={styles.saveButton}>
                          Save
                      </button>
                  </div>
              </div> */}
            </div>
        );
    };

    const renderNotificationContent = () => {
        return (
            <div>
                <div className="bg-white p-4 flex flex-col">
                    <div className="inline-flex w-full items-center justify-center gap-2 mb-5">
                        <div className="text-[14px] rounded-full italic border-2 w-6 h-6 flex items-center justify-center font-serif text-gray-500">
                            i
                        </div>
                        <div className="text-gray-500 text-md">
                            Changes to these fields are for automating the notifications!
                        </div>
                    </div>
                    <div className="flex">
                        <h1 className="w-1/5"></h1>
                        <h1 className="sm:block hidden w-2/5 text-center">Notifications</h1>
                        <h1 className="sm:block hidden w-2/5 text-center">Email/Whatsapp</h1>
                    </div>

                    {/* student  */}
                    <div className="sm:flex gap-36 sm:p-6 mt-2 sm:mr-12 sm:ml-3 border rounded p-4">
                        <div className="w-1/5 flex gap-2 mb-2">
                            <img
                                src={studenticon}
                                alt="student"
                                className="w-6 h-6 text-black"
                            ></img>
                            <h3 className="font-semibold">Students</h3>
                        </div>
                        <h1 className="sm:hidden visible sm:w-0 w-full mb-1 text-center">
                            Notifications
                        </h1>
                        <Select
                            isMulti
                            options={Notificationoptions}
                            className={styles.formInput}
                            classNamePrefix="Select option"
                            onChange={item =>
                                handleNotificationsInputChange(
                                    'studentNotifications',
                                    item.map(({ value }) => value).join(',')
                                )
                            }
                            value={Notificationoptions.filter(option =>
                                notificationsFormData.studentNotifications
                                    ?.split(',')
                                    .includes(option.value)
                            )}
                        />
                        {/* <div className='hidden sm:block'>
                        </div>
                        <div className='block sm:hidden'>
                            <CustomSelectModal 
                                options={Notificationoptions}
                                placeholder='Select option'
                                onChange={item =>
                                    handleNotificationsInputChange(
                                        'studentNotifications',
                                        item.map(({value}) => value).join(','),
                                    )
                                }
                                value={Notificationoptions.filter(option =>
                                    notificationsFormData.studentNotifications
                                        ?.split(',')
                                        .includes(option.value),
                                )}
                            />
                        </div> */}
                        {/* isDisabled=
                      {
                          !notificationsFormData.feeNotifications
                              .split(',')
                              .includes('pendingFees')
                      } */}
                        <h1
                            className={`${
                                notificationsFormData.studentNotifications ? 'block' : 'hidden'
                            } sm:hidden visible sm:w-0 w-full mb-1 text-center mt-4`}
                        >
                            Email/Whatsapp
                        </h1>

                        <Select
                            isMulti
                            options={Emailoptions}
                            className={`lg:block
                              ${
                                  notificationsFormData.studentNotifications ? 'block' : 'hidden'
                              } ${styles.formInput}`}
                            classNamePrefix="Select option"
                            onChange={item =>
                                handleNotificationsInputChange(
                                    'studentNotificationMedium',
                                    item.map(({ value }) => value).join(',')
                                )
                            }
                            value={Emailoptions.filter(option =>
                                notificationsFormData.studentNotificationMedium
                                    ?.split(',')
                                    .includes(option.value)
                            )}
                        />
                    </div>

                    {/* Fee payment  */}
                    <div className="sm:flex gap-36 sm:p-6 sm:mt-2 mt-6 sm:mr-12 sm:ml-3 border rounded p-4">
                        <div className="sm:w-1/5 mb-2 flex gap-2">
                            <img
                                src={feepaymenticon}
                                alt="feepayment"
                                className="w-6 h-6 text-black"
                            ></img>
                            <h3 className="font-semibold">Fee Payment</h3>
                        </div>
                        <h1 className="sm:hidden visible sm:w-0 w-full mb-1 text-center">
                            Notifications
                        </h1>
                        <div className="sm:w-2/5 w-full">
                            <Select
                                isMulti
                                options={Feepaymentoptions}
                                className={styles.feeform}
                                classNamePrefix="Select option"
                                onChange={item =>
                                    handleNotificationsInputChange(
                                        'feeNotifications',
                                        item.map(({ value }) => value).join(',')
                                    )
                                }
                                value={Feepaymentoptions.filter(option =>
                                    notificationsFormData.feeNotifications
                                        .split(',')
                                        .includes(option.value)
                                )}
                            />
                            {/* <div className="flex mt-2 gap-2">
                              <input
                                  type="checkbox"
                                  id="autoSendNotifications"
                                  name="autoSendNotifications"
                                  onChange={handleFeeNotificationAutoSendChecked}
                                  checked={isFeeNotificationAutoSendChecked}
                              />
                              <div className="text-sm font-semibold">
                                  Auto Send Notifications
                              </div>
                          </div> */}
                            {/* Pending Fees Reminder */}
                            <div
                                className={`lg:flex ${
                                    notificationsFormData.feeNotifications
                                        .split(',')
                                        .includes('pendingFees')
                                        ? 'block'
                                        : 'hidden'
                                } flex gap-2 mt-3`}
                            >
                                <div className="text-sm mt-2 ml-1 font-semibold w-1/2">
                                    Pending Fees Reminder
                                </div>
                                <Select
                                    isMulti
                                    className="w-1/2"
                                    isDisabled={
                                        !notificationsFormData.feeNotifications
                                            .split(',')
                                            .includes('pendingFees')
                                    }
                                    options={pendingFeesReminderOptions}
                                    classNamePrefix="Select option"
                                    onChange={item =>
                                        handleNotificationsInputChange(
                                            'pendingFeeNotificationFrequency',
                                            item.map(({ value }) => value).join(',')
                                        )
                                    }
                                    value={pendingFeesReminderOptions.filter(option =>
                                        notificationsFormData.pendingFeeNotificationFrequency
                                            .split(',')
                                            .includes(option.value)
                                    )}
                                />
                            </div>
                            {/* Reminder before due date */}
                            <div
                                className={`lg:flex ${
                                    notificationsFormData.feeNotifications
                                        .split(',')
                                        .includes('dueFees')
                                            ? 'block'
                                            : 'hidden'
                                } flex gap-2 mt-1 w-full `}
                            >
                                <div className="text-sm mt-1 ml-1 font-semibold w-1/2">
                                    Reminder before due date
                                </div>
                                <Select
                                    isMulti
                                    className="w-1/2"
                                    isDisabled={
                                        !notificationsFormData.feeNotifications
                                            .split(',')
                                            .includes('dueFees')
                                    }
                                    options={reminderBeforeDueDateOptions}
                                    classNamePrefix="Select option"
                                    onChange={item =>
                                        handleNotificationsInputChange(
                                            'dueFeeNotificationFrequency',
                                            item.map(({ value }) => value).join(',')
                                        )
                                    }
                                    value={reminderBeforeDueDateOptions.filter(option =>
                                        notificationsFormData.dueFeeNotificationFrequency
                                            .split(',')
                                            .includes(option.value)
                                    )}
                                />
                            </div>
                        </div>
                        <h1
                            className={`${
                                notificationsFormData.feeNotifications ? 'block' : 'hidden'
                            } sm:hidden visible sm:w-0 w-full mb-1 text-center mt-4`}
                        >
                            Email/Whatsapp
                        </h1>
                        <div className="sm:w-2/5 w-full">
                            <Select
                                isMulti
                                options={Emailoptions}
                                className={`lg:block 
                              ${
                                  notificationsFormData.feeNotifications ? 'block' : 'hidden'
                              } ${styles.feeform}`}
                                classNamePrefix="Select option"
                                onChange={item =>
                                    handleNotificationsInputChange(
                                        'feeNotificationMedium',
                                        item.map(({ value }) => value).join(',')
                                    )
                                }
                                value={Emailoptions.filter(option =>
                                    notificationsFormData.feeNotificationMedium
                                        ?.split(',')
                                        .includes(option.value)
                                )}
                            />
                        </div>
                    </div>

                    {/* enquiries  */}
                    <div className="sm:flex gap-36 sm:p-6 sm:mt-2 mt-6 sm:mr-12 sm:ml-3 border rounded p-4">
                        <div className="w-1/5 flex gap-2 mb-2">
                            <img
                                src={enquiriesicon}
                                alt="feepayment"
                                className="w-6 h-6 text-black"
                            ></img>
                            <h3 className="font-semibold">Enquiries</h3>
                        </div>
                        <h1 className="sm:hidden visible sm:w-0 w-full mb-1 text-center">
                            Notifications
                        </h1>
                        <div className="sm:w-2/5 w-full">
                            <Select
                                isMulti
                                options={enquireoptions}
                                className={styles.feeform}
                                classNamePrefix="Select option"
                                onChange={item =>
                                    handleNotificationsInputChange(
                                        'enquiryNotifications',
                                        item.map(({ value }) => value).join(',')
                                    )
                                }
                                value={enquireoptions.filter(option =>
                                    notificationsFormData.enquiryNotifications
                                        .split(',')
                                        .includes(option.value)
                                )}
                            />
                            {/* <div className="flex mt-2 mb-1 gap-2">
                              <input
                                  type="checkbox"
                                  checked={isEnquiryNotificationAutoSendChecked}
                                  onChange={handleEnquiryNotificationAutoSendChecked}
                              />
                              <div className="text-sm  font-semibold">
                                  Auto Send Notifications
                              </div>
                          </div> */}
                            {/* Follow up message */}
                            <div
                                className={`lg:flex ${
                                    notificationsFormData.enquiryNotifications ? 'block' : 'hidden'
                                }
                                flex gap-8 mt-3 w-full`}
                            >
                                <div className="text-sm mt-2 ml-1 font-semibold w-1/2">
                                    Follow up message
                                </div>
                                <Select
                                    isMulti
                                    className="w-1/2 text-sm"
                                    options={followupoptions}
                                    isDisabled={!notificationsFormData.enquiryNotifications}
                                    classNamePrefix="Select option"
                                    onChange={item =>
                                        handleNotificationsInputChange(
                                            'enquiryFollowUpNotificationFrequency',
                                            item.map(({ value }) => value).join(',')
                                        )
                                    }
                                    value={followupoptions.filter(option =>
                                        notificationsFormData.enquiryFollowUpNotificationFrequency
                                            .split(',')
                                            .includes(option.value)
                                    )}
                                />
                            </div>
                        </div>
                        <h1
                            className={`${
                                notificationsFormData.enquiryNotifications ? 'block' : 'hidden'
                            } sm:hidden visible sm:w-0 w-full mb-1 text-center mt-4`}
                        >
                            Email/Whatsapp
                        </h1>
                        <div className="sm:w-2/5 w-full">
                            <Select
                                isMulti
                                options={Emailoptions}
                                className={`lg:block
                              ${
                                  notificationsFormData.enquiryNotifications ? 'block' : 'hidden'
                              } text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded disabled:bg-slate-100`}
                                classNamePrefix="Select option"
                                onChange={item =>
                                    handleNotificationsInputChange(
                                        'enquiryNotificationMedium',
                                        item.map(({ value }) => value).join(',')
                                    )
                                }
                                value={Emailoptions.filter(option =>
                                    notificationsFormData.enquiryNotificationMedium
                                        ?.split(',')
                                        .includes(option.value)
                                )}
                            />
                        </div>
                    </div>

                    {/* schedule */}
                    <div className="sm:flex gap-36 sm:ml-3 sm:p-6 sm:mt-2 mt-8 sm:mr-12 border rounded p-4">
                        <div className="w-1/5 flex gap-2 mb-2">
                            <img
                                src={scheduleicon}
                                alt="student"
                                className="w-6 h-6 text-black"
                            ></img>
                            <h3 className="font-semibold">Schedule</h3>
                        </div>
                        <h1 className="sm:hidden visible sm:w-0 w-full mb-1 text-center">
                            Notifications
                        </h1>
                        <Select
                            isMulti
                            options={Studentoptions}
                            className={styles.formInput}
                            classNamePrefix="Select option"
                            onChange={item =>
                                handleNotificationsInputChange(
                                    'scheduleNotifications',
                                    item.map(({ value }) => value).join(',')
                                )
                            }
                            value={Studentoptions.filter(option =>
                                notificationsFormData.scheduleNotifications
                                    .split(',')
                                    .includes(option.value)
                            )}
                        />
                        <h1
                            className={`${
                                notificationsFormData.scheduleNotifications ? 'block' : 'hidden'
                            } sm:hidden visible sm:w-0 w-full mb-1 text-center mt-4`}
                        >
                            Email/Whatsapp
                        </h1>
                        <Select
                            isMulti
                            options={Emailoptions}
                            className={`lg:block
                              ${
                                  notificationsFormData.scheduleNotifications ? 'block' : 'hidden'
                              } ${styles.formInput}`}
                            classNamePrefix="Select option"
                            onChange={item =>
                                handleNotificationsInputChange(
                                    'scheduleNotificationMedium',
                                    item.map(({ value }) => value).join(',')
                                )
                            }
                            value={Emailoptions.filter(option =>
                                notificationsFormData.scheduleNotificationMedium
                                    ?.split(',')
                                    .includes(option.value)
                            )}
                        />
                    </div>

                    {/* Save and Cancel buttons */}
                    <div className="flex sm:gap-4 sm:justify-end justify-between sm:mr-14 mt-4">
                        <button
                            type="submit"
                            className={styles.cancelButton}
                        >
                            Cancel
                        </button>

                        <button
                            type="submit"
                            className={styles.saveButton}
                            onClick={handleSubmitNotificationSettings}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const renderMyReportContent = () => {
        return (
            <div className="bg-white p-4 h-auto">
                <div className="bg-white p-2 flex flex-col">
                    <div className="flex justify-between">
                        <h1 className="w-1/5"></h1>
                        <h1 className="sm:block hidden text-center">Reports</h1>
                        <h1 className="sm:block hidden w-2/5 text-center">Frequency</h1>
                    </div>
                </div>
                <div className="sm:flex gap-28 p-2 mt-2 sm:mr-12 sm:ml-3 border rounded sm:p-4 mb-2">
                    <div className="sm:w-1/5 w-full flex gap-2 mb-2">
                        <img
                            src={feepaymenticon}
                            alt="feepayment"
                            className="w-6 h-6 text-black"
                        ></img>
                        <h3 className="font-semibold">Fee Payment</h3>
                    </div>
                    <h1 className="sm:hidden visible sm:w-0 w-full mb-1 text-center">Reports</h1>
                    <Select
                        isMulti
                        options={reportOptions}
                        className={styles.formInput}
                        classNamePrefix="Select option"
                        onChange={item =>
                            handleReportsInputChange(
                                'feeReports',
                                item.map(({ value }) => value).join(',')
                            )
                        }
                        value={reportOptions.filter(option =>
                            reportFormData.feeReports?.split(',').includes(option.value)
                        )}
                    />
                    <h1
                        className={`${
                            reportFormData.feeReports ? 'block' : 'hidden'
                        } sm:hidden visible sm:w-0 w-full mb-1 mt-4 text-center`}
                    >
                        Frequency
                    </h1>
                    <Select
                        isMulti
                        options={frequencyfeereports}
                        className={`lg:block
                              ${
                                  reportFormData.feeReports ? 'block' : 'hidden'
                              } ${styles.formInput}`}
                        classNamePrefix="Select option"
                        onChange={item =>
                            handleReportsInputChange(
                                'feeReportFrequency',
                                item.map(({ value }) => value).join(',')
                            )
                        }
                        value={frequencyfeereports.filter(option =>
                            reportFormData.feeReportFrequency?.split(',').includes(option.value)
                        )}
                    />
                </div>
                <div className="sm:flex gap-28 p-2 mt-4 sm:mr-12 sm:ml-3 border rounded sm:p-4 mb-2">
                    <div className="sm:w-1/5 w-full flex gap-2 mb-2">
                        <img
                            src={scheduleicon}
                            alt="scheduleicon"
                            className="w-6 h-6 text-black"
                        ></img>
                        <h3 className="font-semibold">Schedule</h3>
                    </div>
                    <h1 className="sm:hidden visible sm:w-0 w-full mb-1 text-center">Reports</h1>
                    <Select
                        isMulti
                        options={attendanceOptions}
                        className={styles.formInput}
                        classNamePrefix="Select option"
                        onChange={item =>
                            handleReportsInputChange(
                                'scheduleReports',
                                item.map(({ value }) => value).join(',')
                            )
                        }
                        value={attendanceOptions.filter(option =>
                            reportFormData.scheduleReports?.split(',').includes(option.value)
                        )}
                    />
                    <h1
                        className={`${
                            reportFormData.scheduleReports ? 'block' : 'hidden'
                        } sm:hidden visible sm:w-0 w-full mb-1 mt-4 text-center`}
                    >
                        Frequency
                    </h1>
                    <Select
                        isMulti
                        options={frequencyattendancereports}
                        className={`lg:block
                              ${
                                  reportFormData.scheduleReports ? 'block' : 'hidden'
                              } ${styles.formInput}`}
                        classNamePrefix="Select option"
                        onChange={item =>
                            handleReportsInputChange(
                                'scheduleReportFrequency',
                                item.map(({ value }) => value).join(',')
                            )
                        }
                        value={frequencyattendancereports.filter(option =>
                            reportFormData.scheduleReportFrequency
                                ?.split(',')
                                .includes(option.value)
                        )}
                    />
                </div>
                <div className={styles.buttons}>
                    <button
                        type="submit"
                        className={styles.cancelButton}
                    >
                        Cancel
                    </button>

                    <button
                        type="submit"
                        className={styles.saveButton}
                        onClick={handleSubmitReportSettings}
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    };

    const renderPaymentContent = () => {
        return (
            <div className="w-full bg-white p-2">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <div className="p-2 rounded border">
                            <div className="w-full text-center text-2xl font-semibold text-gray-600">
                                Account Details
                            </div>
                            <hr className="border-2 my-2" />
                            {/* <div className='flex items-center justify-between text-xl px-16'>
                                <div>Merchant ID:</div>
                                <div className='text-slate-500'>ajhhhahsb34abhba2bsa</div>
                            </div> */}
                            <div className="flex items-center justify-between text-xl px-16">
                                <div>Business Name:</div>
                                <div className="text-slate-500">
                                    {supplierPaymentDetails?.merchantName}
                                </div>
                            </div>
                            <hr className="my-3 opacity-30 mx-[15%]" />
                            <div className="flex items-center justify-between text-xl px-16">
                                <div>Email:</div>
                                <div className="text-slate-500">
                                    {supplierPaymentDetails?.email}
                                </div>
                            </div>
                            <hr className="my-3 opacity-30 mx-[15%]" />
                            <div className="flex items-center justify-between text-xl px-16">
                                <div>Phone Number:</div>
                                <div className="text-slate-500">
                                    {supplierPaymentDetails?.phone}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end w-full gap-4 mt-3">
                            <a
                                className="px-4 py-1 border-4 border-slate-400 rounded font-semibold text-slate-400"
                                href={`mailto:contact@sparts.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Request Edit
                            </a>
                            <button className="px-4 py-1 bg-slate-400 rounded font-semibold text-white">
                                Approve
                            </button>
                        </div>
                    </div>
                    <div className="p-2 rounded border">Consent</div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="flex">
                <div className="w-full">
                    <div className={styles.settingsTitle}> Settings </div>
                    <div className={styles.settingtabs}>
                        {tabTitles.map(title => (
                            <button
                                key={title}
                                onClick={() => changeTab(title)}
                                className={`${styles.settingtab} ${
                                    activeTab === title ? styles.active : ''
                                }`}
                            >
                                {title}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            {(() => {
                switch (activeTab) {
                    case 'My Details':
                        return renderMyDetailsContent();
                    case 'Password':
                        return renderPasswordContent();
                    case 'Team':
                        return renderTeamContent();
                    case 'Billing':
                        return renderBillingContent();
                    case 'Student Notification':
                        return renderNotificationContent();
                    case 'Report':
                        return renderMyReportContent();
                    case 'Payments':
                        return renderPaymentContent();
                    default:
                        return renderMyDetailsContent();
                }
            })()}
        </>
    );
};

export default Settings;
