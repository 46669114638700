import React, { ReactNode, useEffect } from 'react';
import AddStudentViewModel from '../../view-models/actions-view-models/add-student-view-model';
import { AiOutlinePlus } from 'react-icons/ai';
import PopupModal from '../../components/widgets/popup-modal';
import NewStudentForm from '../../components/widgets/new-student-form';
import { StudentData } from '../../../models/student/student-data';
import { EnquiryData } from '../../../models/enquiry/enquiry-data';
import FullScreenLoader from '../../components/bits/fullscreen-loader';

interface AddStudentProps {
    isOpen: boolean;
    handleClose: () => void;
    isEdit: boolean;
    studentData?: StudentData | undefined;
    enquiryData?: EnquiryData | undefined;
}

const AddStudent = ({ isEdit, studentData, isOpen, handleClose, enquiryData }: AddStudentProps) => {
    const {
        handleAddNewStudentBtnClick,
        handleStudentFormModalClose,
        isNewStudentModalOpen,
        switchTab,
        handleInputChange,
        handleAddNewStudent,
        formData,
        activeTab,
        mapAcademiesToOptions,
        mapClassesToOptions,
        handleProfileImgChange,
        isProfileImagePosting,
        profileImageInputKey,
        isExpandedForAddAcademy,
        handleExpandClickForAddAcademy,
        academyCards,
        handleAddNewAcademyCardButtonClick,
        handleDeleteClickForAcademy,
        mapRemainingAcademiesToOption,
        handleNewAcademyCardInputChange,
        mapRemainingClassesToOption,
        handleClassAdding,
        handleClassDeleting,
        handleCheckboxChange,
        allClasses,
        handleCheckboxChangeForDay,
        setStudentEditData,
        isEditStudentModalOpen,
        presetEnquiryDataInForm,
        isLoading,
        setActiveEnquiryData,
    } = AddStudentViewModel({ handleClose, isEdit });

    useEffect(() => {
        if (isEdit) {
            setStudentEditData(studentData);
        }
    }, [isEdit, studentData, isOpen]);

    useEffect(() => {
        presetEnquiryDataInForm(enquiryData);
        setActiveEnquiryData(enquiryData);
    }, [enquiryData, isOpen]);

    return (
        <PopupModal
            handleModalClose={handleStudentFormModalClose}
            isOpen={isOpen}
            background="white"
            title={isEdit ? 'Edit Student' : 'Add Student'}
        >
            {isLoading && <FullScreenLoader />}
            <NewStudentForm
                switchTab={switchTab}
                handleInputChange={handleInputChange}
                handleAddNewStudent={handleAddNewStudent}
                formData={formData}
                activeTab={activeTab}
                mapAcademiesToOptions={mapAcademiesToOptions}
                mapClassesToOptions={mapClassesToOptions}
                isEditMode={isEdit}
                handleProfileImgChange={handleProfileImgChange}
                isProfileImagePosting={isProfileImagePosting}
                profileImageInputKey={profileImageInputKey}
                isExpandedForAddAcademy={isExpandedForAddAcademy}
                handleExpandClickForAddAcademy={handleExpandClickForAddAcademy}
                academyCards={academyCards}
                handleAddButtonClick={handleAddNewAcademyCardButtonClick}
                handleDeleteClickForAcademy={handleDeleteClickForAcademy}
                mapRemainingAcademiesToOptions={mapRemainingAcademiesToOption}
                handleAddAcademyInputChange={handleNewAcademyCardInputChange}
                mapRemainingClassesToOption={mapRemainingClassesToOption}
                handleClassAdding={handleClassAdding}
                handleClassDeleting={handleClassDeleting}
                allClasses={allClasses ?? []}
                handleCheckboxChange={handleCheckboxChange}
                handleCheckboxChangeForDay={handleCheckboxChangeForDay}
            />
        </PopupModal>
    );
};

export default AddStudent;
