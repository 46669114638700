import { useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";


export const GLOBAL_SEARCH_OPTIONS_DATA = [
    {
        label: 'Academies',
        value: 'academies',
        link: Routes.Academies,
        icon: '🏫'
    },
    {
        label: 'Students',
        value: 'students',
        link: Routes.Students,
        icon: '👩‍🎓'
    },
    {
        label: 'Enquires',
        value: 'enquires',
        link: Routes.Enquires,
        icon: '📨'
    },
    {
        label: 'Fees',
        value: 'fees',
        link: Routes.FeePayment,
        icon: '💸'
    },
    {
        label: 'Schedule',
        value: 'schedule',
        link: Routes.Schedule,
        icon: '📅'
    }
]