import React from 'react'
import style from './style.module.css';
import { FitnessTestDetailsData } from '../../../../models/progress-tracker/fitness-test-details-data';
import Select from 'react-select';
import { TakeFitnessTestFormType } from '../../../view-models/actions-view-models/take-fitness-test-view-model';
import { StudentData } from '../../../../models/student/student-data';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import SquareCrossIcon from '../../../../assets/icons/square-cross.png';
import { calculateAge } from '../../../../utils/helpers/helpers';

type TakeFitnessTestFormProps = {
    selectedFitnessTest: FitnessTestDetailsData;
    mapAcademyToOptions: () => { value: number; label: string }[] | [];
    takeFitnessTestFormData: TakeFitnessTestFormType;
    handleFormInputChange: (field: string, value: string | number | number[] | undefined) => void
    mapDisciplinesToOptions: () => { value: number; label: string }[] | [];
    mapClassesToOptions: () => { value: number; label: string }[] | [];
    studentSearchTerm: string;
    handleStudentSearchTermChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isSearchInputOnFocus: boolean;
    setIsSearchInputOnFocus: (e: boolean) => void;
    studentSuggestionListData: StudentData[];   
    isStudentDataFetchLoading: boolean;
    handleSugestionListItemClick: (data: StudentData) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    suggestionBoxRef: React.RefObject<HTMLDivElement>;
    handleRemoveStudent: (id: number) => void;
    isStudentFilterParametersSubmitted: boolean;
    handleStudentFilterParametersSubmit: () => void;
    handleStudentFilterParametersReset: () => void;
    handleClearAllStudents: () => void;
    handleCunductableFitnessTestSubmit: () => void;
}

const TakeFitnessTestForm = ({
    selectedFitnessTest,
    mapAcademyToOptions,
    takeFitnessTestFormData,
    handleFormInputChange,
    mapDisciplinesToOptions,
    mapClassesToOptions,
    studentSearchTerm,
    handleStudentSearchTermChange,
    isSearchInputOnFocus,
    setIsSearchInputOnFocus,
    studentSuggestionListData,
    isStudentDataFetchLoading,
    handleSugestionListItemClick,
    inputRef,
    suggestionBoxRef,
    handleRemoveStudent,
    isStudentFilterParametersSubmitted,
    handleStudentFilterParametersSubmit,
    handleStudentFilterParametersReset,
    handleClearAllStudents,
    handleCunductableFitnessTestSubmit,
}: TakeFitnessTestFormProps) => {
  return (
    <div className='w-[90vw] sm:w-[700px] p-2'>
        <div>
            <div className='text-sm mt-3 mb-1'>Test Name:</div>
            <input
                className={`text-sm text-gray-700 resize-none w-full border-opacity-30 rounded disabled:bg-gray-300`}
                disabled
                value={selectedFitnessTest?.attributes?.name}
            />
        </div>
        <div className='p-2 rounded border border-gray-200 mt-3 overflow-hidden'>
            <div>
                <div className='text-sm mb-1'>Academy:</div>
                <Select
                    options={mapAcademyToOptions()}
                    className={`text-sm text-gray-500 resize-none w-full border-opacity-30 rounded`}
                    placeholder="Select Academy"
                    isDisabled={isStudentFilterParametersSubmitted}
                    onChange={item =>
                        handleFormInputChange('academy', item?.value)
                    }
                    value={mapAcademyToOptions().filter(option =>
                        takeFitnessTestFormData.academy === option.value
                    )}
                />
            </div>
            <div>
                <div className='text-sm mt-3 mb-1'>Discipline:</div>
                <Select
                    options={mapDisciplinesToOptions()}
                    className={`text-sm text-gray-500 resize-none w-full border-opacity-30 rounded`}
                    placeholder="Select Discipline"
                    isDisabled={isStudentFilterParametersSubmitted}
                    onChange={item =>
                        handleFormInputChange('discipline', item?.value)
                    }
                    value={mapDisciplinesToOptions().filter(option =>
                        takeFitnessTestFormData.discipline === option.value
                    )}
                />
            </div>
            <div>
                <div className='text-sm mt-3 mb-1'>Classes:</div>
                <Select
                    isMulti
                    options={mapClassesToOptions()}
                    isDisabled={isStudentFilterParametersSubmitted}
                    className={`text-sm text-gray-500 resize-none w-full border-opacity-30 rounded`}
                    placeholder="Select Classes"
                    onChange={item =>
                        handleFormInputChange('classes', item?.map(item => item?.value))
                    }
                    value={mapClassesToOptions().filter(option =>
                        takeFitnessTestFormData?.classes?.includes(option.value)
                    )}
                />
            </div>
            <div className='flex justify-between gap-2 relative'>
                <div 
                    className={`text-sm mt-3 mb-1 border border-slate-400 px-2 py-1 rounded cursor-pointer active:scale-95 transition-all text-gray-600 font-semibold
                        ${!(takeFitnessTestFormData?.classes?.length || takeFitnessTestFormData?.discipline) && '-translate-x-[100%] opacity-0'}
                    `}
                    onClick={handleStudentFilterParametersReset}
                >
                    Reset
                </div>
                <div 
                    className={`text-sm mt-3 mb-1 bg-slate-400 px-2 py-1 rounded cursor-pointer active:scale-95 transition-all text-white font-semibold
                        ${(isStudentFilterParametersSubmitted || !takeFitnessTestFormData?.discipline) && 'translate-x-[100%] opacity-0'}
                    `}
                    onClick={handleStudentFilterParametersSubmit}
                >
                    Add Students of selected parameters
                </div>
            </div>
        </div>
        <div className=' p-2 mt-3 border border-slate-300 rounded'>
            <div className='flex flex-wrap justify-between sm:gap-4 items-center'>
                <div className='order-1 text-sm mb-1 font-semibold text-gray-600'>Students:</div>
                <div className='order-4 sm:order-2 text-sm mb-1 flex-grow relative w-full sm:w-auto'>
                    <input
                        type='text'
                        className='text-sm h-8 text-gray-700 resize-none w-full border-opacity-30 focus:ring-0 outline-none border-none' 
                        placeholder='Search Students'
                        value={studentSearchTerm}
                        onChange={handleStudentSearchTermChange}
                        onFocus={() => setIsSearchInputOnFocus(true)}
                        ref={inputRef}
                    />
                    {isSearchInputOnFocus && (
                        <div className={`${style.suggestionBox}`} ref={suggestionBoxRef}>
                            <ul className={style.suggestionBoxListWrapper}>
                                {isStudentDataFetchLoading ? (
                                    <li className={style.menuSearchLoaderContainer}>
                                        <div className={style.menuSearchLoader}></div>
                                        Loading...
                                    </li>
                                ) : studentSuggestionListData?.length === 0 ? (
                                    <li className=''>
                                        <div className={'text-center font-semibold my-2 text-gray-500'}>
                                            No student found
                                        </div>
                                    </li>
                                ) : (studentSuggestionListData.map((item, index) => (
                                        <li
                                            className={`${style.suggestionBoxListItem} ${takeFitnessTestFormData?.studentsData.some(student => student.id === item.id) ? 'bg-green-50' : 'cursor-pointer'}`}
                                            onClick={() => {
                                                if(takeFitnessTestFormData?.studentsData.some(student => student.id === item.id)){
                                                    return;
                                                }else{
                                                    handleSugestionListItemClick(item);
                                                }
                                            }}
                                            key={index}
                                        >
                                            {item && item?.attributes && (
                                                <div className={`flex gap-2 p-1 px-2 rounded items-center justify-between`}>
                                                    <div>
                                                        <span>
                                                            <img
                                                                className={style.studentProfileWrapper}
                                                                src={item?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                                                                alt={`Profile image`}
                                                            />
                                                        </span>
                                                        <span>{`${item?.attributes?.firstName} ${item?.attributes?.middleName ?? ''} ${item?.attributes?.lastName ?? ''}`}</span>
                                                    </div>
                                                    {takeFitnessTestFormData?.studentsData.some(student => student.id === item.id) && (
                                                        <div className='text-xs  rounded-lg px-2 py-1 bg-green-300'>Added</div>
                                                    )}
                                                </div>
                                            )}
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                <div 
                    className={`order-2 sm:order-3 text-sm mb-1 px-2 py-1 font-semibold text-white border-x-0 rounded-xl bg-red-100 transition-all ${takeFitnessTestFormData?.studentsData?.length > 0 && 'bg-red-300 cursor-pointer active:scale-95'}`}
                    onClick={handleClearAllStudents}
                >
                    Clear all
                </div>
                <div className='order-3 sm:order-4 text-sm mb-1 px-2 py-1 font-semibold text-gray-600 border border-gray-300 rounded-xl bg-slate-100'>Selected: {takeFitnessTestFormData?.studentsData?.length ?? 0}</div>
            </div>
            <div className='w-full h-[180px] bg-slate-300 rounded p-1 flex flex-col gap-1 sm:items-center overflow-y-auto thin-scrollbar py-2'>
                {takeFitnessTestFormData?.studentsData?.map((data, index) => (
                    <div key={data?.id} className='bg-slate-200 rounded p-1 h-12 flex items-center gap-2 sm:w-[400px]'>
                        <div className='h-10 w-10 bg-gray-600 rounded-full overflow-hidden'>
                            <img 
                                src={data?.attributes?.photo?.data?.attributes?.url ?? StudentProfileIcon}
                                className='h-full w-full object-contain'
                            />
                        </div>
                        <div className='flex-grow'>
                            <div className='text-sm font-semibold text-slate-800 truncate'>{`${data?.attributes?.firstName} ${data?.attributes?.middleName ?? ''} ${data?.attributes?.lastName ?? ''}`}</div>
                            <div className='text-xs text-slate-700'>Age: {calculateAge(new Date(data?.attributes?.dob))}</div>
                        </div>
                        <div className=''>
                            <img
                                src={SquareCrossIcon}
                                onClick={() => handleRemoveStudent(data?.id)}
                                className='h-8 cursor-pointer transition-all active:scale-95'
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div>
            <div className='text-sm mt-3 mb-1'>Remarks:</div>
            <input
                className={`text-sm text-gray-500 resize-none w-full border-opacity-30 rounded`}
                placeholder="Enter remarks if any"
                value={takeFitnessTestFormData.remarks}
                onChange={e => handleFormInputChange('remarks', e.target.value)}
            />
        </div>
        <div className='flex justify-end'>
            <button 
                className='font-semibold mt-6 mb-1 w-full sm:w-auto bg-primary py-2 text-white px-8 rounded transition-all active:scale-95 disabled:opacity-20'
                disabled={!takeFitnessTestFormData?.studentsData?.length}
                onClick={handleCunductableFitnessTestSubmit}
            >
                Submit
            </button>
        </div>
    </div>
  )
}

export default TakeFitnessTestForm