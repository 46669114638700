import React, { ReactNode } from 'react';
import style from './style.module.css';
import logo from '../../../assets/logo.png';
import toggleOpenIcon from '../../../assets/icons/menu.png';
import toggleCloseIcon from '../../../assets/icons/cross.png';
import UserProfileIcon from '../../../assets/icons/user-profile.png';
import { getCurrentDateFormatted } from '../../../utils/helpers/helpers';
import { Outlet, useLocation } from 'react-router-dom';
import logoutviewmodel from '../../view-models/logout-view-model';
import HeaderMenuViewModel from '../../view-models/header-menu-view-model';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { Roles } from '../../../utils/auth/roles';
import { Routes } from '../routes';
import { HiBars3BottomRight } from "react-icons/hi2";
import Loader from '../../components/bits/loader';
import { FiSettings } from "react-icons/fi";
import { BiHome } from "react-icons/bi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { PiUsersThreeBold } from "react-icons/pi";
import { PiStudentBold } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import { GrAnnounce } from "react-icons/gr";
import { MdPhotoCameraFront } from "react-icons/md";
import { TbFaceId } from "react-icons/tb";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { AiOutlineMacCommand } from "react-icons/ai";
import GlobalSearchScreen from '../../components/widgets/global-search-screen';

interface LayoutProps {
    children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
    const formattedDate = getCurrentDateFormatted();
    const location = useLocation();

    const { handleLogout } = logoutviewmodel();

    const {
        supplierData,
        isSupplierFetchSuccess,
        userData,
        handleOnMenuItemClick,
        isStudentDataFetchLoading,
        studentSuggestionListData,
        goToStudentDetails,
        handleGlobalSearchInputChange,
        globalSearchTerm,
        isSidebarOpen,
        handleToggleMenuClick,
        isDropdownOpen,
        handleAvatarClick,
        userRole,
        isUserDetailsDataFetchSuccess,
        userDetailsData,
        isWebDropdownOpen,
        handleSettingsIconClick,
        isSupplierDataLoading,
        isUserDetailsDataLoading,
        userState,
        handleContainerClick,
        inputRef,
        handleOpenLiveAttendance,
        isEnquiriesSearchResultFetching,
        enquiriesSearchResultList,
    } = HeaderMenuViewModel();
    
    return (
        <div className={style.container}>
            {(isSupplierDataLoading || isUserDetailsDataLoading) && <FullScreenLoader />}
            <GlobalSearchScreen 
                searchTerm={globalSearchTerm}
                setSearchTerm={handleGlobalSearchInputChange}
                isStudentDataFetchLoading={isStudentDataFetchLoading}
                studentSuggestionListData={studentSuggestionListData}
                isEnquiriesSearchResultFetching={isEnquiriesSearchResultFetching}
                enquiriesSearchResultList={enquiriesSearchResultList}
            />
            
            <div className={style.contentContainer}>
                {/* Side menu */}
                {isSidebarOpen &&
                    <div 
                        className='sm:hidden absolute top-0 left-0 w-full h-full z-[2000] backdrop-blur-sm bg-black/20'
                        onClick={handleToggleMenuClick}
                    ></div>
                }
                <div className={`${isSidebarOpen ? style.sideMenuActive : style.sideMenu}`}>
                    <div className={style.headerLogoContainer}>
                        {isSidebarOpen && 
                            <img
                                className={style.headerLogo}
                                src={logo}
                                alt="logo"
                            />
                        }
                        <HiBars3BottomRight 
                            size={30} 
                            className='text-white cursor-pointer hidden sm:block' 
                            onClick={handleToggleMenuClick}
                        />
                        <HiBars3BottomRight 
                            size={36} 
                            className='text-white cursor-pointer sm:hidden' 
                            onClick={handleToggleMenuClick}
                        />
                        {/* <div className={style.headerCurrentDate}>{formattedDate}</div> */}
                    </div>

                    <div className='flex flex-col gap-2 mt-12'>
                        <>
                            <div
                                id="dashboard"
                                className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${
                                    location.pathname === '/dashboard' ? style.activeMenuItem : ''
                                }`}
                                onClick={() => handleOnMenuItemClick(Routes.Dashboard)}
                            >
                                <BiHome size={24}/>
                                {isSidebarOpen && <div className={style.menuItemLabel}>Dashboard</div>}
                            </div>
                            <div
                                id="academy-management"
                                className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${
                                    location.pathname === '/academies' ? style.activeMenuItem : ''
                                }`}
                                onClick={() => handleOnMenuItemClick(Routes.Academies)}
                            >
                                <HiOutlineBuildingOffice2 size={24} />
                                {isSidebarOpen && <div className={style.menuItemLabel}>Academy Management</div>}
                            </div>

                            {/* {(userState?.userDetails?.attributes?.studentsAccess !== 'noAccess' ||
                                userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                                <div
                                    id="search"
                                    className={style.menuSearchInputContainer}
                                >
                                    <input
                                        className={style.menuSearchInput}
                                        placeholder="Search students by name"
                                        onChange={handlesearchInputChange}
                                    />
                                    <span className={style.menuSearchIconContainer}>
                                        <img
                                            className={style.menuSearchIcon}
                                            src={searchIcon}
                                            alt="search"
                                        />
                                    </span>
                                    <span className={style.menuSearchLoaderContainer}>
                                        {isStudentDataFetchLoading && (
                                            <div className={style.menuSearchLoader}></div>
                                        )}
                                    </span>

                                    {searchTerm && (
                                        <div className={style.suggestionBox}>
                                            <ul className={style.suggestionBoxListWrapper}>
                                                {(studentSuggestionListData?.data?.data ?? []).map(
                                                    (item, index) => (
                                                        <li
                                                            className={style.suggestionBoxListItem}
                                                            key={index}
                                                            onClick={goToStudentDetails.bind(
                                                                this,
                                                                item.id
                                                            )}
                                                        >
                                                            <span>
                                                                <img
                                                                    className={
                                                                        style.studentProfileWrapper
                                                                    }
                                                                    src={
                                                                        item?.attributes?.photo?.data
                                                                            ?.attributes?.url
                                                                            ? item?.attributes?.photo
                                                                                ?.data?.attributes
                                                                                ?.url
                                                                            : StudentProfileIcon
                                                                    }
                                                                />
                                                            </span>
                                                            <span>
                                                                {item?.attributes?.firstName +
                                                                    ' ' +
                                                                    item?.attributes?.lastName}
                                                            </span>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )} */}

                            <div
                                id="enquiry-navMenu"
                                className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${
                                    location.pathname === '/enquires' ? style.activeMenuItem : ''
                                }`}
                                onClick={() => handleOnMenuItemClick(Routes.Enquires)}
                            >
                                <PiUsersThreeBold size={24} />
                                {isSidebarOpen && <div className={style.menuItemLabel}>Enquiries</div>}
                            </div>
                            <div
                                id="students-navmenu"
                                className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${
                                    location.pathname === '/students' ? style.activeMenuItem : ''
                                }`}
                                onClick={() => handleOnMenuItemClick(Routes.Students)}
                            >
                                <PiStudentBold size={24} />
                                {isSidebarOpen && <div className={style.menuItemLabel}>Students</div>}
                            </div>
                            <div
                                id="feepayment-navmenu"
                                className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${
                                    location.pathname === '/fee-payment' ? style.activeMenuItem : ''
                                }`}
                                onClick={() => handleOnMenuItemClick(Routes.FeePayment)}
                            >
                                <RiMoneyDollarCircleLine size={24} />
                                {isSidebarOpen && <div className={style.menuItemLabel}>Fee Payment</div>}
                            </div>
                        </>
                        <div
                            id="schedule-navmenu"
                            className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${
                                location.pathname === '/schedule' || location.pathname.startsWith('/schedule-details/') ? style.activeMenuItem : ''
                            }`}                        
                            onClick={() => handleOnMenuItemClick(Routes.Schedule)}
                        >
                            <FaRegCalendarAlt size={24} />
                            {isSidebarOpen && <div className={style.menuItemLabel}>Schedule</div>}
                        </div>
                        <div
                            id="progress-tracker-navmenu"
                            className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${location.pathname === '/progress-tracker' ? style.activeMenuItem : ''}`}
                            onClick={() => handleOnMenuItemClick(Routes.ProgressTracker)}
                        >
                            <GiProgression size={24} />
                            {isSidebarOpen && <div className={style.menuItemLabel}> Progress Tracker</div>}
                        </div>
                        <div
                            id="announcement-navmenu"
                            className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${location.pathname === '/announcement' ? style.activeMenuItem : ''}`}
                            onClick={() => handleOnMenuItemClick(Routes.Announcement)}
                        >
                            <GrAnnounce size={24} />
                            {isSidebarOpen && <div className={style.menuItemLabel}> Announcement</div>}
                        </div>
                        <div
                            id="virtual-class-navmenu"
                            className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${location.pathname === '/virtual-class' ? style.activeMenuItem : ''}`}
                            onClick={() => handleOnMenuItemClick(Routes.VirtualClass)}
                        >
                            <MdPhotoCameraFront size={24} />
                            {isSidebarOpen && <div className={style.menuItemLabel}> Virtual Class</div>}
                        </div>
                        <div
                            className={`${style.menuItemContainer} ${isSidebarOpen ? 'px-6' : 'px-4'} ${location.pathname === '/live-attendance' ? style.activeMenuItem : ''}`}
                            onClick={() => handleOnMenuItemClick(Routes.LiveAttendance)}
                        >
                            <TbFaceId size={24} />
                            {isSidebarOpen && <div className={style.menuItemLabel}> Live Attendance</div>}
                        </div>
                    </div>
                </div>

                <div className={style.topBarMainSectionWrapper}>
                    {/* Top Bar */}
                    <div className={style.headerContainer}>
                        <div
                            className={style.sidebarToggleContainer}
                            onClick={handleToggleMenuClick}
                        >
                           <HiBars3BottomRight 
                                size={36} 
                                className='text-white cursor-pointer' 
                            />
                        </div>
                        <div className={style.headerFirstColumnWrapper}>
                            <div>
                                <img 
                                    src='/favicon.svg'
                                    className={`w-10 h-10 ${isSidebarOpen ? 'hidden' : 'hidden sm:block'}`}
                                />
                            </div>
                            <div className={style.headerWelcomeContainer}>
                                {userRole === Roles.AUTHENTICATED_USER && (
                                    <div className={style.headerWelcomeMessage}>
                                        Welcome {supplierData?.data?.data?.[0]?.attributes?.firstName ?? ''},
                                    </div>
                                )}
                                {userRole === Roles.RESTRICTED_USER && (
                                    <div className={style.headerWelcomeMessage}>
                                        Welcome {userDetailsData?.data?.data[0]?.attributes?.name},
                                    </div>
                                )}
                                {userRole === Roles.AUTHENTICATED_USER && (
                                    <div className={style.headerBusinessSummary}>
                                        Here is a summary of your business
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Attendance toggle */}
                        {/* <div className='flex'>
                            <div 
                                className={`p-1 px-4 font-semibold text-lg hover:scale-105 active:scale-95 transition-all rounded-md bg-[#455a64] text-nowrap flex cursor-pointer text-white`} 
                                onClick={handleOpenLiveAttendance}
                            >
                                Live Attendance
                            </div>
                        </div>  */}

                        <div className={style.headerSecondColumnWrapper}>
                            <div className='rounded-full bg-[#edebeb] flex items-center px-1 pr-3 cursor-pointer flex-grow sm:flex-grow-0 mx-2 sm:mx-0 relative' onClick={handleContainerClick}>
                                <IoSearch size={24} className='ml-2 text-slate-800' />
                                <input 
                                    ref={inputRef}
                                    type="text" 
                                    className='rounded-2xl border-0 focus:ring-0 p-2 bg-[#edebeb] w-full' 
                                    placeholder={window.innerWidth > 640 ? 'Search...' : ''}
                                    value={globalSearchTerm}
                                    onChange={(e) => handleGlobalSearchInputChange(e.target.value)}
                                />
                                <AiOutlineMacCommand size={20} />
                                <span className='font-semibold px-[2px]'>S</span>
                                <img 
                                    src={logo}
                                    className='h-8 sm:hidden absolute inset-0 m-auto opacity-20 cursor-text'
                                />
                            </div>
                            <div className={`${style.headerIconContainerWrapper}`}>
                                <div
                                    className={`${style.headerIconContainer} settings-icon-group`}
                                    onClick={handleSettingsIconClick}
                                >
                                    <FiSettings 
                                        className={style.headerIcon}
                                    />
                                </div>
                                {isWebDropdownOpen && (
                                    <div className={style.settingsDropdownMenu}>
                                        <ul>
                                            {(userState?.userDetails?.attributes?.userManagementAccess !==
                                                'noAccess' ||
                                                userState?.user?.role?.type ===
                                                    Roles.AUTHENTICATED_USER) && (
                                                <>
                                                    <li
                                                        className={style.dropdownListItem}
                                                        onClick={() =>
                                                            handleOnMenuItemClick(Routes.UserManagement)
                                                        }
                                                    >
                                                        User Management
                                                    </li>
                                                    <li
                                                        className={style.dropdownListItem}
                                                        onClick={() =>
                                                            handleOnMenuItemClick(Routes.Settings)
                                                        }
                                                    >
                                                        Settings
                                                    </li>
                                                </>
                                            )}
                                            <li
                                                className={style.dropdownListItem}
                                                onClick={handleLogout}
                                            >
                                                Logout
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {userRole === Roles.AUTHENTICATED_USER &&
                                (isSupplierFetchSuccess ? (
                                    <div className={style.headerUserContainer} onClick={handleAvatarClick}>
                                        <div
                                            className={style.headerUserAvatarContainer}
                                        >
                                            <img
                                                className={style.headerUserAvatar}
                                                src={
                                                    supplierData?.data?.data?.[0]?.attributes?.photo?.data
                                                        ?.attributes?.url ?? UserProfileIcon
                                                }
                                                alt="avatar"
                                            /> 
                                            {isDropdownOpen && (
                                                <div className={style.dropdownMenu}>
                                                    <ul>
                                                        <li
                                                            className={style.dropdownListItem}
                                                            onClick={() =>
                                                                handleOnMenuItemClick(Routes.UserManagement)
                                                            }
                                                        >
                                                            User Management
                                                        </li>
                                                        <li
                                                            className={style.dropdownListItem}
                                                            onClick={() =>
                                                                handleOnMenuItemClick(Routes.Settings)
                                                            }
                                                        >
                                                            Settings
                                                        </li>
                                                        <li
                                                            className={style.dropdownListItem}
                                                            onClick={handleLogout}
                                                        >
                                                            Logout
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <RiArrowDropDownLine size={28} className='sm:hidden text-slate-200'/>
                                        <div className={style.headerUserDetailsContainer}>
                                            <div className={style.headerUserName}>
                                                {supplierData?.data?.data?.[0]?.attributes?.firstName ?? ''}{' '}
                                                {supplierData?.data?.data?.[0]?.attributes?.lastName ?? ''}
                                            </div>
                                            <div className={style.headerUserEmail}>
                                                {userData?.email ?? ''}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={style.loaderContainer}>
                                        <Loader />
                                    </div>
                                ))}
                            {userRole === Roles.RESTRICTED_USER &&
                                (isUserDetailsDataFetchSuccess ? (
                                    <div className={style.headerUserContainer}>
                                        <div
                                            className={style.headerUserAvatarContainer}
                                            onClick={handleAvatarClick}
                                        >
                                            <img
                                                className={style.headerUserAvatar}
                                                src={
                                                    userDetailsData?.data?.data[0]?.attributes?.photo?.data
                                                        ?.attributes?.url ?? UserProfileIcon
                                                }
                                                alt="avatar"
                                            />
                                            {isDropdownOpen && (
                                                <div className={style.dropdownMenu}>
                                                    <ul>
                                                        {userState?.userDetails?.attributes
                                                            ?.userManagementAccess !== 'noAccess' && (
                                                            <li
                                                                className={style.dropdownListItem}
                                                                onClick={() =>
                                                                    handleOnMenuItemClick(
                                                                        Routes.UserManagement
                                                                    )
                                                                }
                                                            >
                                                                User Management
                                                            </li>
                                                        )}
                                                        <li
                                                            className={style.dropdownListItem}
                                                            onClick={handleLogout}
                                                        >
                                                            Logout
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <div className={style.headerUserDetailsContainer}>
                                            <div className={style.headerUserName}>
                                                {userDetailsData?.data?.data[0]?.attributes?.name}
                                            </div>
                                            <div className={style.headerUserEmail}>
                                                {userDetailsData?.data?.data[0]?.attributes?.email}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={style.loaderContainer}>
                                        <Loader />
                                    </div>
                                ))}
                        </div>
                    </div>
                    
                    {/* children */}
                    <div className='flex-grow overflow-y-auto p-1 bg-white w-screen max-w-[100vw] sm:w-auto overflow-x-hidden'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
