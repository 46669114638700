import userEvent from '@testing-library/user-event';
import { invokeApi } from '../utils/helpers/invoke-api';
import { AnnouncementPostData } from '../models/announcement/announcement-post-data';
import { AnnouncementPostDataWrapper } from '../models/announcement/announcement-post-data-wrapper';
import { uploadImageresponsedata } from '../utils/types/upload-Image-response-data';
import { AnnouncementDataListWrapper } from '../models/announcement/announcement-data-list-wrapper';
import ApiResponse from '../utils/types/api-response';
import { StudentListWrapper } from '../models/student/student-list-wrapper';

export class AnnouncementService {
    private static _instance: AnnouncementService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new AnnouncementService());
    }

    public getStudentList = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=*&pagination[pageSize]=1500&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAnnouncementList = async (id: number): Promise<ApiResponse<AnnouncementDataListWrapper>> => {
        return invokeApi<null, AnnouncementDataListWrapper>({
            route: `parent-notifcations?populate=*&filters[type]=Announcement&filters[users][id]=${id}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewAnnouncement = async (data: AnnouncementPostDataWrapper) => {
        return invokeApi<AnnouncementPostDataWrapper, AnnouncementDataListWrapper>({
            route: 'parent-notifcations',
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public uploadFile = async (data: FormData) => {
        return invokeApi<FormData, uploadImageresponsedata[]>({
            route: 'upload/',
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };
}
