import React from 'react'
import { StudentCertificateRecordData } from '../../../../models/certifications/student-certificate-record-data'
import ArrowRightIcon from '../../../../assets/icons/arrow-right.png';
import { CertificationData } from '../../../../models/certifications/certification-data';
import { CertificationEventData } from '../../../../models/certifications/certification-event-data';
import { LiveCertificationEventStudentRemarks } from '../../../view-models/actions-view-models/certification-event-student-info-viewmodel';
import { StudentData } from '../../../../models/student/student-data';

interface CertificationEventStudentInfoFormProps {
    studentsPresentCertification: StudentCertificateRecordData | undefined;
    certificationsList: CertificationData[];
    selectedEventData: CertificationEventData | undefined;
    eventStudentRemarkProvider: string;
    setEventStudentRemarkProvider: React.Dispatch<React.SetStateAction<string>>
    eventStudentRemarks: string;
    setEventStudentRemarks: React.Dispatch<React.SetStateAction<string>>
    liveCertificationEventStudentRemarks: LiveCertificationEventStudentRemarks[]
    handleAddLiveCertificationEventStudentRemarks: (e: React.FormEvent, data: LiveCertificationEventStudentRemarks) => void;
    studentData: StudentData | undefined;
    handleSkipStudent: () => void;
    handleApproveStudent: () => void;
}

const CertificationEventStudentInfoForm = ({
    studentsPresentCertification,
    certificationsList,
    selectedEventData,
    eventStudentRemarkProvider,
    setEventStudentRemarkProvider,
    eventStudentRemarks,
    setEventStudentRemarks,
    liveCertificationEventStudentRemarks,
    handleAddLiveCertificationEventStudentRemarks,
    studentData,
    handleSkipStudent,
    handleApproveStudent,
}: CertificationEventStudentInfoFormProps) => {
    const s = selectedEventData?.attributes?.students?.find(s => s?.studentId === studentData?.id)

    return (
        <div className='w-[85vw] sm:w-[540px]'>
            <div className='p-1 px-2 rounded border'>
                <div className='flex items-center justify-center sm:py-10'>
                    {studentsPresentCertification ? (
                        <div className='sm:w-auto w-[30%] flex flex-col items-center border-2 border-blue-500 rounded p-2 font-semibold text-slate-600'>
                            <div>{studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.name}</div>
                            <hr className='w-[95%] my-2 sm:w-full border-2 border-slate-300'/>
                            <select 
                                className='w-full sm:w-[140px] p-2 rounded text-center'
                                value={studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level}
                                disabled
                            >
                                <option 
                                    key={studentsPresentCertification?.attributes?.certificationInfo?.data?.id} 
                                    value={studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level}
                                >
                                    Level {studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level}
                                </option>
                            </select>
                        </div>
                    ) : (
                        <div className='sm:w-auto w-[30%] flex flex-col items-center border-2 border-blue-500 rounded p-2 font-semibold text-slate-600'>
                            <div className='px-4 py-2'>Not Certified</div>
                        </div>
                    )}
                    <div>
                        <img 
                            src={ArrowRightIcon}
                            className='h-8 w-8 sm:h-16 sm:w-16'
                        />
                    </div>
                    <div className='sm:w-auto w-[30%] text-center flex flex-col items-center border-2 border-green-500 rounded p-2 font-semibold text-slate-600'>
                        {!studentsPresentCertification ? (
                            <>
                                <div>{certificationsList?.find(cert => cert?.id === s?.certificationId)?.attributes?.name}</div>
                                <hr className='my-2 w-full border-2 border-slate-300'/>
                                <select 
                                    className='w-full sm:w-[140px] p-2 rounded text-center'
                                    value={certificationsList?.find(cert => cert?.id === s?.certificationId)?.attributes?.level ?? 0}
                                    disabled
                                    // onChange={e => handleStudentPromotionLevelChange(student?.studentId, Number(e.target.value))}
                                >
                                    {certificationsList?.map(cert => (
                                        <option key={cert?.id} value={cert?.attributes?.level}>Level {cert?.attributes?.level}</option>
                                    ))}
                                </select>
                            </>
                        ) : certificationsList?.filter(cert => cert?.attributes?.level > studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level).length > 0 ? (
                                <>
                                    <div>{certificationsList?.find(cert => cert?.id === s?.certificationId)?.attributes?.name}</div>
                                    <hr className='my-2 w-full border-2 border-slate-300'/>
                                    <select 
                                        className='w-full sm:w-[140px] p-2 rounded text-center'
                                        value={certificationsList?.find(cert => cert?.id === s?.certificationId)?.attributes?.level ?? 0}
                                        disabled
                                        // onChange={e => handleStudentPromotionLevelChange(student?.studentId, Number(e.target.value))}
                                    >
                                        {certificationsList?.filter(cert => cert?.attributes?.level > studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level).map(cert => (
                                            <option key={cert?.id} value={cert?.attributes?.level}>Level {cert?.attributes?.level}</option>
                                        ))}
                                    </select>
                                </>
                            ) : (
                                <div className='text-slate-600 font-semibold'>No more levels to promote</div>
                            )}
                    </div>
                </div>
                <div className='rounded border-2 border-slate-200 p-2 w-full'>
                    <div className='w-full flex items-center justify-between relative overflow-hidden'>
                        <div className='h-2 bg-gray-300 w-[calc(100%-16px)] absolute'></div>
                        {!studentsPresentCertification &&
                            <div className='h-12 w-12 rounded-full bg-slate-300 z-10 border-4 border-blue-500'></div>
                        }
                        {certificationsList
                            .filter((cert) => cert?.attributes?.level <= (certificationsList?.find(cert => cert?.id === s?.certificationId)?.attributes?.level ?? 0) && cert?.attributes?.level >= (studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level ?? 0))
                            .map((cert, index, filteredCertifications) => (
                                <div
                                    key={cert?.id}
                                    className={`h-12 w-12 rounded-full bg-slate-300 z-10 flex items-center justify-center
                                        ${(index === 0 && studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes) ? 'border-4 border-blue-500' : ''}
                                        ${index === filteredCertifications.length - 1 ? 'border-4 border-green-500' : ''}
                                    `}
                                >
                                    {cert?.attributes?.level}
                                </div>
                            ))
                        }
                    </div>
                    <div className='w-full grid grid-cols-2 border rounded mt-2 bg-slate-200 p-2'>
                        <div>Event Fees</div>
                        <div className='text-right'>₹ {selectedEventData?.attributes?.eventFees}</div>
                        {certificationsList
                            .filter((cert) => cert?.attributes?.level <= (certificationsList?.find(cert => cert?.id === s?.certificationId)?.attributes?.level ?? 0) && cert?.attributes?.level > (studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level ?? 0))
                            .map((cert, index, filteredCertifications) => (
                                <React.Fragment key={cert?.id}>
                                    <div>{cert?.attributes?.name} ({cert?.attributes?.level})</div>
                                    <div className='text-right'>₹ N/A</div>
                                </React.Fragment>
                            ))
                        }
                        <div className='font-semibold'>Total</div>
                        <div className='font-semibold text-right'>₹ {selectedEventData?.attributes?.eventFees}</div>
                    </div>
                    <div className='bg-slate-200 p-2 mt-2'>
                        <form 
                            className='flex flex-col items-center'
                            onSubmit={e => {
                                handleAddLiveCertificationEventStudentRemarks(e, {
                                    // studentId: studentData?.id ?? 0,
                                    // certificationEventId: selectedEventData?.id ?? 0,
                                    remarks: eventStudentRemarks,
                                    givenBy: eventStudentRemarkProvider
                                })
                            }}
                        >
                            <select
                                className='border-0 focus:ring-0 rounded w-full'
                                value={eventStudentRemarkProvider}
                                onChange={e => setEventStudentRemarkProvider(e.target.value)}
                                required
                            >
                                <option value=''>Select Remark Provider</option>
                                {selectedEventData?.attributes?.instructors.trim() && selectedEventData?.attributes?.instructors?.split(',').map((instructor, index) => (
                                    <option key={instructor.trim()} value={`${instructor.trim()} (Instructor)`}>{`${instructor.trim()} (Instructor)`}</option>
                                ))}
                                {selectedEventData?.attributes?.chiefExaminers !== '' && selectedEventData?.attributes?.chiefExaminers?.split(',').map((chiefExaminers, index) => (
                                    <option key={chiefExaminers.trim()} value={`${chiefExaminers.trim()} (Chief Examiner)`}>{`${chiefExaminers.trim()} (Chief Examiner)`}</option>
                                ))}
                                {selectedEventData?.attributes?.externalExaminers !== '' && selectedEventData?.attributes?.externalExaminers?.split(',').map((externalExaminers, index) => (
                                    <option key={externalExaminers.trim()} value={`${externalExaminers.trim()} (External Examiner)`}>{`${externalExaminers.trim()} (External Examiner)`}</option>
                                ))}
                            </select>
                            <input 
                                type="text"
                                placeholder='Enter Remarks'
                                className='border-0 focus:ring-0 rounded w-full'
                                value={eventStudentRemarks}
                                onChange={e => setEventStudentRemarks(e.target.value)}
                                required
                            />
                            <button 
                                className='bg-slate-300 w-full text-center py-2 rounded' 
                                type='submit'
                            >
                                Add
                            </button>
                        </form>
                        <div className='mt-2 flex flex-col gap-1 p-1 rounded border border-gray-500 max-h-[180px] overflow-y-auto thin-scrollbar'>
                            {liveCertificationEventStudentRemarks.length === 0 && <div className='text-center font-semibold text-slate-400'>No Remarks Added</div>}
                            {liveCertificationEventStudentRemarks.reverse()
                                ?.map((r, index) => (
                                    <div key={index} className='rounded border bg-slate-50 p-2'>
                                        <div className='w-full'>{r?.remarks}</div>
                                        <div className='text-end italic'>- {r?.givenBy}</div>
                                    </div>
                                )) 
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-between px-4 mt-4'>
                <button className='px-4 py-1 font-semibold text-primary border-2 border-primary rounded' onClick={handleSkipStudent}>Skip</button>
                <button className='px-4 py-1 font-semibold text-white bg-primary rounded' onClick={handleApproveStudent}>Approve</button>
            </div>
        </div>
    )
}

export default CertificationEventStudentInfoForm