import { AcademyData } from '../../models/academy/academy-data';

export type CoachesState = {
    currentPage: number;
    academies: AcademyData[];
};

export type CoachesAction =
    | { type: 'setCurrentPage'; payload: number }
    | { type: 'setAcademies'; payload: AcademyData[] };

export const coachesReducer = (state: CoachesState, action: CoachesAction): CoachesState => {
    switch (action.type) {
        case 'setCurrentPage':
            return { ...state, currentPage: action.payload };
        case 'setAcademies':
            return { ...state, academies: action.payload };
        default:
            return state;
    }
};
