import { DisciplineOption } from '../models/academy/discipline-option-data';
import { DisciplineListWrapper } from '../models/discipline/discipline-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class DisciplinesService {
    private static _instance: DisciplinesService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new DisciplinesService());
    }

    public getDisciplinesList = async (): Promise<ApiResponse<DisciplineListWrapper>> => {
        return invokeApi<null, DisciplineListWrapper>({
            route: `disciplines?sort[0]=name&populate=academies`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
