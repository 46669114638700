// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_iconContainer__9tWSD {
    background-color: #f7f7f7;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: orange;
    border: 2px solid orange;
    margin-bottom: 20px;
}

.style_modalBody__76wDw {
    margin-bottom: 20px;
}

.style_modalFooter__r1dMT {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.style_cancelButton__FaxM9 {
    background: #e0e0e0;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/delete-class-modal/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".iconContainer {\n    background-color: #f7f7f7;\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 24px;\n    color: orange;\n    border: 2px solid orange;\n    margin-bottom: 20px;\n}\n\n.modalBody {\n    margin-bottom: 20px;\n}\n\n.modalFooter {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 20px;\n}\n\n.cancelButton {\n    background: #e0e0e0;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `style_iconContainer__9tWSD`,
	"modalBody": `style_modalBody__76wDw`,
	"modalFooter": `style_modalFooter__r1dMT`,
	"cancelButton": `style_cancelButton__FaxM9`
};
export default ___CSS_LOADER_EXPORT___;
