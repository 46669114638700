import React, { useState } from 'react';

interface CustomDropdownProps {
  options: { value: string | number; label: string }[];
  value: string | number;
  onChange: (value: string | number) => void;
  width?: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  value,
  onChange,
  width = '180',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (optionValue: string | number) => {
    onChange(optionValue);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="relative inline-block w-fit cursor-pointer transition duration-300">
      <div
        className={`bg-primary text-white py-1 px-2 sm:p-2 sm:px-4 text-xs sm:text-sm rounded-lg flex items-center justify-between gap-2 w-[${width}px] `}
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown on click
      >
        <span>{options.find(option => option.value === value)?.label || 'Select an option'}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
          className={`transform transition duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
        >
          <path
            fill='white'
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="absolute top-full flex flex-col gap-1 bg-primary rounded-lg p-1 mt-1 w-full z-10">
          {options.map((option) => (
            <div key={option.value} className={`w-full hover:bg-gray-700 cursor-pointer px-2 py-[2px] rounded-lg ${value === option.value ? 'border border-slate-600' : ''}`}>
              <input
                id={`option-${option.value}`}
                name="dropdown-option"
                type="radio"
                value={option.value}
                checked={value === option.value}
                onChange={() => handleOptionClick(option.value)}
                className="hidden"
              />
              <label
                htmlFor={`option-${option.value}`}
                className="option text-white w-full flex items-center justify-center text-xs sm:text-sm"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
