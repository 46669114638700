// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_spinnerWrapper__bVhRV {
    height: 2rem;
    width: 2rem
}
@keyframes style_spin__8GCDm {
    to {
        transform: rotate(360deg)
    }
}
.style_spinnerWrapper__bVhRV {
    animation: style_spin__8GCDm 1s linear infinite;
    align-items: center;
    border-radius: 9999px;
    border-top-width: 4px;
    border-style: solid;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
    --tw-border-opacity: 0.5
}
`, "",{"version":3,"sources":["webpack://./src/view/components/bits/loader/style.module.css"],"names":[],"mappings":"AACI;IAAA,YAA+G;IAA/G;AAA+G;AAA/G;IAAA;QAAA;IAA+G;AAAA;AAA/G;IAAA,+CAA+G;IAA/G,mBAA+G;IAA/G,qBAA+G;IAA/G,qBAA+G;IAA/G,mBAA+G;IAA/G,wDAA+G;IAA/G;AAA+G","sourcesContent":[".spinnerWrapper {\n    @apply w-8 h-8 border-t-4 border-blue-500 border-solid border-opacity-50 rounded-full animate-spin items-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerWrapper": `style_spinnerWrapper__bVhRV`,
	"spin": `style_spin__8GCDm`
};
export default ___CSS_LOADER_EXPORT___;
