import { DisciplineOption } from '../models/academy/discipline-option-data';
import { DisciplineListWrapper } from '../models/discipline/discipline-list-wrapper';
import { VirtualMeetingDataWrapper } from '../models/virtual-meeting/virtual-meeting-data-wrapper';
import { VirtualMeetingListWrapper } from '../models/virtual-meeting/virtual-meeting-list-wrapper';
import { VirtualMeetingPostData } from '../models/virtual-meeting/virtual-meeting-post-data';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class VirtualMeetingService {
    private static _instance: VirtualMeetingService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new VirtualMeetingService());
    }

    public getActiveMeetingOfUser = async (userId: number): Promise<ApiResponse<VirtualMeetingListWrapper>> => {
        return invokeApi<null, VirtualMeetingListWrapper>({
            route: `virtual-meetings?populate=*&filters[hosts][id]=${userId}&filters[startTime][$lte]=${new Date().toISOString()}&filters[endTime][$gte]=${new Date().toISOString()}&filters[hasEnded][$eq]=false`,
            method: 'GET',
            privateRoute: true,
        });
    };


    public postNewMeeting = async (data: VirtualMeetingPostData) => {
        return invokeApi<{data: VirtualMeetingPostData}, VirtualMeetingDataWrapper>({
            route: `virtual-meetings`,
            method: 'POST',
            privateRoute: true,
            data: {data},
        });
    };

    public putNewMeeting = async (id: number, data: VirtualMeetingPostData) => {
        return invokeApi<{data: VirtualMeetingPostData}, VirtualMeetingDataWrapper>({
            route: `virtual-meetings/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {data},
        });
    };

    public getVirtualMeetingToken = async (): Promise<ApiResponse<{token: string}>> => {
        return invokeApi<null, {token: string}>({
            route: `get-virtual-meeting-token`,
            method: 'GET',
            privateRoute: true,
        });
    };

}
