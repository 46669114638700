import React from 'react'
import { StudentData } from '../../../../models/student/student-data';
import { convertTo12HourFormat } from '../../../../utils/helpers/helpers';
import arrowIcon from '../../../../assets/icons/arrow-right.png';

interface StudentsTimingsConfiltResolveFormProps {
    isImpactedStudentsFetching?: boolean;
    groupedImpactedStudents: Record<string, StudentData[]>;
    currentClassTimings: {
        day: string;
        start_time: string;
        end_time: string;
    }[];
    resolvedTimingEntries: Record<string, string>;
    handleNewTimingSelection: (key: string, value: string) => void;
    handleResolveTimingConflictSubmit: (e: React.FormEvent) => void;
}

const StudentsTimingsConfiltResolveForm = ({
    isImpactedStudentsFetching,
    groupedImpactedStudents,
    currentClassTimings,
    resolvedTimingEntries,
    handleNewTimingSelection,
    handleResolveTimingConflictSubmit,
}: StudentsTimingsConfiltResolveFormProps) => {
  return ( 
    <form className='sm:h-[450px] sm:w-[620px] w-[90vw] h-[80vh]' onSubmit={handleResolveTimingConflictSubmit}>
        {isImpactedStudentsFetching ? (
            <div className='flex items-center justify-center h-full w-full gap-2'>
                <h1 className='text-xl text-slate-400 animate-pulse font-semibold'>Fetching impacted students...</h1>
            </div>
        ) : (
            <div className='p-2 flex flex-col gap-4'>
                {Object.keys(groupedImpactedStudents).map((key, index) => (
                    <div key={index} className='rounded border-4 bg-slate-100 border-slate-300 p-2'>
                        <div>Found {groupedImpactedStudents[key].length} impacted students due to removal of {' '}
                            <span className='text-gray-600'>{key.split('-')[0]} {convertTo12HourFormat(key.split('-')[1])} - {convertTo12HourFormat(key.split('-')[2])}</span>
                        </div>
                        <ul className={`flex items-center gap-2 p-2 border-2 rounded border-gray-200 ${resolvedTimingEntries[key] ? 'bg-green-100' : 'bg-red-100'}`}>
                            {groupedImpactedStudents[key].map((student, studentIndex) => (
                                <li className='bg-white rounded px-1 py-[1px] text-xs shadow-md' key={studentIndex}>
                                    {student.attributes.firstName}
                                </li>
                            ))}
                        </ul>
                        <hr className='my-2'/>
                        <div className='grid sm:grid-cols-10 grid-cols-1 p-2 items-center text-sm'>
                            <div className='col-span-4 flex flex-col sm:flex-row items-center gap-2'>
                                <div className='bg-gray-200 p-2 rounded line-through'>{key.split('-')[0]} {convertTo12HourFormat(key.split('-')[1])} - {convertTo12HourFormat(key.split('-')[2])}</div>
                                <img 
                                    src={arrowIcon}
                                    className='h-6 w-6 rotate-90 mb-2 sm:mb-0 sm:rotate-0'
                                />
                            </div>
                            <select 
                                className='col-span-6 border-none rounded'
                                onChange={e => handleNewTimingSelection(key, e.target.value)}
                                required
                                value={resolvedTimingEntries[key] || ''}
                            >
                                <option value=''>Select an available session for students</option>
                                {currentClassTimings.map((timing, index) => (
                                    <option key={index} value={`${timing.day}-${timing.start_time}-${timing.end_time}`}>{timing.day} ( {convertTo12HourFormat(timing.start_time)} - {convertTo12HourFormat(timing.end_time)} )</option>
                                ))}
                            </select>
                        </div>
                    </div>
                ))}
            </div>
        )}
        <div className='flex justify-end p-2'>
            <button type='submit' className='bg-primary text-white px-4 py-2 rounded active:scale-95 transition-all'>
                Continue
            </button>
        </div>
    </form>
  )
}

export default StudentsTimingsConfiltResolveForm