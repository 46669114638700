import React from 'react';
import GuestStudentMarkAttendanceViewModel from '../../view-models/actions-view-models/guest-student-mark-attendance-view-model';
import PopupModal from '../../components/widgets/popup-modal';
import GuestStudentAttendanceMarkForm from '../../components/widgets/guest-student-attendance-mark-form';
import { ClassData } from '../../../models/class/class-data';
import FullScreenLoader from '../../components/bits/fullscreen-loader';

interface GuestStudentMarkAttendanceProps {
    isOpen: boolean;
    handleClose: () => void;
    currentStudentsIds: number[];
    currentClass: ClassData | undefined;
}

const GuestStudentMarkAttendance = ({
    isOpen,
    handleClose,
    currentStudentsIds,
    currentClass,
}: GuestStudentMarkAttendanceProps) => {
    const {
        isLoading,
        studentSuggestionListData,
        isStudentDataFetchLoading,
        searchTerm,
        handlesearchInputChange,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        onStudentSearchListItemClick,
        selectedStudentsForAttendance,
        handleRemoveSelectedStduentForAttendance,
        handleMarkAttendance,
        resetSelectedStudentsForAttendance,
    } = GuestStudentMarkAttendanceViewModel({ handleClose, currentClass });

    return (
        <PopupModal
            title="Mark Attendance"
            isOpen={isOpen}
            handleModalClose={() => {
                resetSelectedStudentsForAttendance();
                handleClose();
            }}
        >
            {isLoading && <FullScreenLoader />}
            <GuestStudentAttendanceMarkForm
                studentSuggestionListData={studentSuggestionListData
                    .filter(s => !currentStudentsIds.includes(s.id))
                    .filter(s => !selectedStudentsForAttendance.map(st => st.id).includes(s.id))}
                isStudentDataFetchLoading={isStudentDataFetchLoading}
                searchTerm={searchTerm}
                handlesearchInputChange={handlesearchInputChange}
                isSearchInputOnFocus={isSearchInputOnFocus}
                setIsSearchInputOnFocus={setIsSearchInputOnFocus}
                onStudentSearchListItemClick={onStudentSearchListItemClick}
                selectedStudentsForAttendance={selectedStudentsForAttendance}
                handleRemoveSelectedStduentForAttendance={handleRemoveSelectedStduentForAttendance}
                handleMarkAttendance={handleMarkAttendance}
            />
        </PopupModal>
    );
};

export default GuestStudentMarkAttendance;
