import React, { memo } from 'react';
import style from './style.module.css';
import horizontalEllipses from '../../../../assets/icons/horizontal-ellipsis.png';
import { StudentAttributes } from '../../../../models/student/student-attributes';
import StudentProfileIcon from '../../../../assets/icons/user.png';

interface StudentsListItemProps {
    studentAttributes: StudentAttributes;
    handleActionsButton: () => void;
    goToStudentDetails: () => void;
    // dropdownRef: React.RefObject<HTMLDivElement>;
    searchTerm: string;
}

const StudentsListItem = ({
    studentAttributes,
    handleActionsButton,
    goToStudentDetails,
    searchTerm,
    // dropdownRef,
}: StudentsListItemProps) => {

    const highlightText = (text: string, search: string): React.ReactNode => {
        if (!search) {
          return text;
        }
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return parts.map((part, index) => 
          part.toLowerCase() === search.toLowerCase() ? <mark key={index} className="highlight">{part}</mark> : part
        );
    };

    return (
        <div className={style.listItemContainer}>
            <img
                className={style.listItemImage}
                src={studentAttributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? StudentProfileIcon}
                alt="list_image"
            />
            <div className={style.listItemMobileResponsiveWrapper}>
                <div className={style.listItemNameContainer}>
                    <div className={`${style.listItemName}`}>
                        {highlightText(`${studentAttributes.firstName ?? ''} ${studentAttributes.middleName ?? ''} ${studentAttributes.lastName ?? ''}`, searchTerm)}
                    </div>
                    <div className={style.listItemParent}>
                        Parent name: {studentAttributes?.studentRelation?.[0]?.name ?? 'NA'}
                    </div>
                </div>
                <div className={`${style.listItemContact}`}>
                    Contact Number: {highlightText(`${studentAttributes?.parentContactNo ?? ''}`, searchTerm)}
                </div>
                <div className={`${style.listItemContact} group`}>
                    {studentAttributes.academies.data.length <= 0 ? (
                        <span className={style.studentProfileStatusInactive}>Inactive</span>
                    ) : (
                        <>
                            {studentAttributes.classes.data.length === 1 ? (
                                studentAttributes.classes.data[0]?.attributes?.class_name
                            ) : (
                                <>
                                    {`${
                                        studentAttributes.classes.data[0]?.attributes.class_name
                                    } and ${studentAttributes.classes.data.length - 1} `}
                                    <span className="underline text-blue-700 group cursor-pointer">
                                        more
                                    </span>
                                    <ul className="absolute bg-white py-0.5 text-start w-fit z-10 border-solid border-b-gray-400 ml-[110px] sm:ml-20 px-1 hidden group-hover:flex flex-col">
                                        {studentAttributes.classes.data.map((item, i) => (
                                            <li key={i}>{item?.attributes.class_name}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <button
                onClick={goToStudentDetails}
                className={style.listItemSolidButton}
            >
                View Profile
            </button>
            <button
                onClick={handleActionsButton}
                className={style.listItemButton}
            >
                More Actions
            </button>
            <button
                onClick={handleActionsButton}
                className={style.listItemIcon}
            >
                <img src={horizontalEllipses} />
            </button>
        </div>
    );
};

export default memo(StudentsListItem);
