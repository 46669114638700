import { useState } from 'react';
import {
    SCHEDULE_DETAILS_QUERY,
    STUDENT_SUGGESTION_QUERY,
} from '../../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { HeaderMenuService } from '../../../services/header-menu-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { useDebounce } from 'use-debounce';
import { StudentData } from '../../../models/student/student-data';
import AttendanceRequest from '../../../utils/types/attendance-request';
import { AttendanceListWrapper } from '../../../models/attendance/attendance-list-wrapper';
import ScheduleService from '../../../services/schedule-service';
import { ClassData } from '../../../models/class/class-data';

const GuestStudentMarkAttendanceViewModel = ({
    handleClose,
    currentClass,
}: {
    handleClose: () => void;
    currentClass: ClassData | undefined;
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
    const [selectedStudentsForAttendance, setSelectedStudentsForAttendance] = useState<
        StudentData[]
    >([]);
    const queryClient = useQueryClient();

    const {
        isSuccess: isStudentSuggesionDataFetchSuccess,
        isLoading: isStudentDataFetchLoading,
        data: studentSuggestionListData,
    } = useQuery({
        queryKey: [STUDENT_SUGGESTION_QUERY, value],
        queryFn: async () => {
            try {
                // Perform asynchronous operations here
                const response = await HeaderMenuService.instance.getStudentList(
                    userState?.user?.id ?? 0,
                    value
                );
                // Return the result
                return response;
            } catch (error) {
                // Handle errors
                console.error('Error fetching student suggestion data:', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
        enabled: searchTerm.length > 0,
    });

    const handlesearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const onStudentSearchListItemClick = (student: StudentData) => {
        setSelectedStudentsForAttendance(prev => [...prev, student]);
        setSearchTerm('');
    };
    const handleRemoveSelectedStduentForAttendance = (id: number) => {
        setSelectedStudentsForAttendance(prev => prev.filter(s => s.id !== id));
    };

    const postBulkAttendance = async (
        data: AttendanceRequest[]
    ): Promise<AttendanceListWrapper | undefined> => {
        const response = await ScheduleService.instance.postBulkAttendance(data, false);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addBulkAttendance,
        isLoading: isAttendanceBulkPosting,
        error: attendanceBulkPostError,
    } = useMutation(postBulkAttendance, {
        onSuccess: data => {
            alert('Attendance Marked Successfully!');
            resetSelectedStudentsForAttendance();
            handleClose();
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
        onError: (error, variables, context) => {
            alert('Failed to mark attendance! Please try again');
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
    });

    const handleMarkAttendance = () => {
        if (selectedStudentsForAttendance.length > 0) {
            const data = selectedStudentsForAttendance.map(s => {
                return {
                    present: true,
                    on_leave: false,
                    note: 'Added using Guest Attendance',
                    attendance_date: new Date().toISOString().split('T')[0],
                    time: new Date().toISOString().split('T')[1].split('.')[0] + '.000',
                    class: currentClass?.id ?? 0,
                    student: s.id,
                    markedBy: userState?.user?.id ?? 0,
                    systemMarked: false,
                };
            });
            addBulkAttendance(data);
        } else {
            alert('Please select students to mark attendance');
        }
    };

    const resetSelectedStudentsForAttendance = () => {
        setSelectedStudentsForAttendance([]);
    };

    return {
        isLoading: isAttendanceBulkPosting,
        studentSuggestionListData: studentSuggestionListData?.data?.data ?? [],
        isStudentDataFetchLoading,
        searchTerm,
        handlesearchInputChange,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        onStudentSearchListItemClick,
        selectedStudentsForAttendance,
        handleRemoveSelectedStduentForAttendance,
        handleMarkAttendance,
        resetSelectedStudentsForAttendance,
    };
};

export default GuestStudentMarkAttendanceViewModel;
