import React from 'react';
import {
    ApparelFeeItemType,
    FeeStructureFormDataType,
    QualificationItemType,
    TrainingFeeItemType,
} from '../../../view-models/actions-view-models/add-fee-structure-view-model';
import Select from 'react-select';
import { AcademyData } from '../../../../models/academy/academy-data';
import trainingIcon from '../../../../assets/icons/training.png';
import eventIcon from '../../../../assets/icons/event.png';
import uniformIcon from '../../../../assets/icons/uniform.png';
import registrationcon from '../../../../assets/icons/document.png';
import deleteIcon from '../../../../assets/icons/delete-bin.png';
import CustomSelectModal from '../custom-select-modal';

interface FeeStructureFormProps {
    feeStructureFormData: FeeStructureFormDataType;
    handleFormInputChange: (field: string, value: string | number | number[]) => void;
    mapClassesToOptions: () =>
        | {
              value: number;
              label: string;
          }[]
        | [];
    allAcademies: AcademyData[];
    handleAddTrainingFeeItem: (e: React.FormEvent) => void;
    currentTrainingFeeItemData: TrainingFeeItemType;
    handleTrainingFeeFormInputChange: (field: string, value: string | number) => void;
    handleTrainingFeeItemDelete: (index: number) => void;
    handleSubmitFeeStructureForm: () => void;
    handleApparelFeeFormInputChange: (field: string, value: string | number) => void;
    handleAddApparelFeeItem: (e: React.FormEvent) => void;
    handleApparelFeeItemDelete: (index: number) => void;
    currentApparelFeeItemData: ApparelFeeItemType;
    currentQualificationFeeItemData: QualificationItemType;
    handleQualificationFeeFormInputChange: (field: string, value: string | number) => void;
    handleAddQualificationFeeItem: (e: React.FormEvent) => void;
    handleQualificationFeeItemDelete: (index: number) => void;
    trainingFeeAvailable: boolean;
    registrationFeesAvailable: boolean;
    apparelFeesAvailable: boolean;
    qualificationFeesAvailable: boolean;
    availableScheduleOptions: string[];
    availableSessionOptions: number[] | string[];
}

const FeeStructureForm = ({
    feeStructureFormData,
    handleFormInputChange,
    mapClassesToOptions,
    allAcademies,
    currentTrainingFeeItemData,
    handleTrainingFeeFormInputChange,
    handleAddTrainingFeeItem,
    handleTrainingFeeItemDelete,
    handleSubmitFeeStructureForm,
    handleApparelFeeFormInputChange,
    handleAddApparelFeeItem,
    handleApparelFeeItemDelete,
    currentApparelFeeItemData,
    currentQualificationFeeItemData,
    handleQualificationFeeFormInputChange,
    handleAddQualificationFeeItem,
    handleQualificationFeeItemDelete,
    trainingFeeAvailable,
    registrationFeesAvailable,
    apparelFeesAvailable,
    qualificationFeesAvailable,
    availableScheduleOptions,
    availableSessionOptions,
}: FeeStructureFormProps) => {
    return (
        <div className="w-[85vw] sm:w-[640px] p-2">
            <div>
                <h1 className="text-sm p-1">Academy:</h1>
                <div className="flex items-center gap-2">
                    <select
                        className="disabled:bg-gray-200 w-full rounded border-gray-500 border-opacity-30 p-2 text-sm"
                        onChange={e => handleFormInputChange('academy', Number(e.target.value))}
                        value={feeStructureFormData.academy ?? ''}
                        disabled={feeStructureFormData.academy !== null}
                    >
                        <option
                            value=""
                            className="text-gray-500"
                        >
                            Select an academy
                        </option>
                        {allAcademies?.map(academy => (
                            <option
                                key={academy.id}
                                value={academy.id}
                                className=""
                            >
                                {academy.attributes.name}
                            </option>
                        ))}
                    </select>
                    <div
                        className="text-white bg-slate-400 font-semibold rounded px-4 py-[6px] cursor-pointer active:scale-[98%]"
                        onClick={() => handleFormInputChange('academy', '')}
                    >
                        Clear
                    </div>
                </div>
            </div>
            {feeStructureFormData.feeType === null && (
                <div>
                    <h1 className="text-sm p-1">Fee Type:</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-4">
                        <div
                            className={`h-12 w-full flex items-center justify-between px-4 rounded-lg ${trainingFeeAvailable ? 'bg-slate-200' : 'bg-red-200'} font-semibold cursor-pointer`}
                            onClick={() => {
                                if (trainingFeeAvailable) {
                                    feeStructureFormData.academy !== null
                                        ? handleFormInputChange('feeType', 'Training')
                                        : alert('Please select an academy first');
                                } else {
                                    alert('Training fees is already created for this academy');
                                }
                            }}
                        >
                            <img
                                src={trainingIcon}
                                className="h-6 w-6"
                            />
                            <div className="text-center flex-grow">Training Fees</div>
                        </div>
                        <div
                            className={`h-12 w-full flex items-center justify-between px-4 rounded-lg ${registrationFeesAvailable ? 'bg-slate-200' : 'bg-red-200'} font-semibold cursor-pointer`}
                            onClick={() => {
                                if (registrationFeesAvailable) {
                                    feeStructureFormData.academy !== null
                                        ? handleFormInputChange('feeType', 'Registration')
                                        : alert('Please select an academy first');
                                } else {
                                    alert('Registration fees is already created for this academy');
                                }
                            }}
                        >
                            <img
                                src={registrationcon}
                                className="h-6 w-6"
                            />
                            <div className="text-center flex-grow">Registration Fees</div>
                        </div>
                        <div
                            className={`h-12 w-full flex items-center justify-between px-4 rounded-lg ${apparelFeesAvailable ? 'bg-slate-200' : 'bg-red-200'} font-semibold cursor-pointer`}
                            onClick={() => {
                                if (apparelFeesAvailable) {
                                    feeStructureFormData.academy !== null
                                        ? handleFormInputChange('feeType', 'Uniform/Gear')
                                        : alert('Please select an academy first');
                                } else {
                                    alert('Apparel fees is already created for this academy');
                                }
                            }}
                        >
                            <img
                                src={uniformIcon}
                                className="h-6 w-6"
                            />
                            <div className="text-center flex-grow">Uniform/Gear Fees</div>
                        </div>
                        <div
                            className={`h-12 w-full flex items-center justify-between px-4 rounded-lg ${qualificationFeesAvailable ? 'bg-slate-200' : 'bg-red-200'} font-semibold cursor-pointer`}
                            onClick={() => {
                                if (qualificationFeesAvailable) {
                                    feeStructureFormData.academy !== null
                                        ? handleFormInputChange('feeType', 'Event/Competition')
                                        : alert('Please select an academy first');
                                } else {
                                    alert('Qualification fees is already created for this academy');
                                }
                            }}
                        >
                            <img
                                src={eventIcon}
                                className="h-6 w-6"
                            />
                            <div className="text-center flex-grow">Event/Competition Fees</div>
                        </div>
                    </div>
                </div>
            )}
            {/* <div>
            <h1 className='text-sm p-1'>
                Fee Type:
            </h1>
            <div
                className='flex items-center justify-between gap-2 p-1'
            >
                <div className='text-sm flex items-center gap-2 '>
                    <input 
                        type="radio" 
                        id="registration"
                        onChange={(e) => handleFormInputChange('trainingFeeSchedule', 'Monthly')}
                        name='feeType'
                        className='text-gray-500 disabled:opacity-30'
                        checked={feeStructureFormData.feeType === 'Registration'}
                    />
                    <label className='m-0' htmlFor='registration'>Registration</label>
                </div>
                <div className='text-sm flex items-center gap-2'>
                    <input 
                        type="radio" 
                        id="uniform"
                        onChange={(e) => handleFormInputChange('trainingFeeSchedule', 'Quarterly')}
                        name='feeType'
                        className='text-gray-500 disabled:opacity-30'
                        checked={feeStructureFormData.feeType === 'Uniform/Gear'}
                    />
                    <label className='m-0' htmlFor='uniform'>Uniform/Gear</label>
                </div>
                <div className='text-sm flex items-center gap-2'>
                    <input 
                        type="radio" 
                        id='event'
                        onChange={(e) => handleFormInputChange('trainingFeeSchedule', 'Event/Competition')}
                        name='feeType'
                        className='text-gray-500 disabled:opacity-30'
                        checked={feeStructureFormData.feeType === 'Event/Competition'}
                    />
                    <label className='m-0' htmlFor='event'>Event/Competition</label>
                </div>
                <div className='text-sm flex items-center gap-2'>
                    <input 
                        type="radio" 
                        id='training'
                        name='feeType'
                        className='text-gray-500 disabled:opacity-30'
                        onChange={(e) => handleFormInputChange('trainingFeeSchedule', 'Training')}
                        checked={feeStructureFormData.feeType === 'Training'}
                    />
                    <label className='m-0' htmlFor='training'>Training Fees</label>
                </div>
            </div>
        </div> */}
            <div className="bg-gray-100 rounded p-2 my-2">
                {feeStructureFormData.feeType === 'Training' && (
                    <div>
                        <div className="flex items-center gap-4 justify-center">
                            <img
                                src={trainingIcon}
                                className="h-6 w-6"
                            />
                            <div className="font-semibold text-gray-600">Training Fees</div>
                        </div>
                        <hr className="border-2 border-gray-300 m-2" />
                        <div>
                            <h1 className="text-sm p-1">Fees applicable for:</h1>
                            <Select
                                isMulti
                                className="disabled:bg-gray-200 w-full rounded border-gray-500 border-opacity-30 text-sm"
                                placeholder="Select classes"
                                options={mapClassesToOptions()}
                                onChange={options =>
                                    handleFormInputChange(
                                        'classes',
                                        options.map(o => o.value)
                                    )
                                }
                            />
                            {/* <div className='sm:block hidden'>
                        </div>
                        <div className='sm:hidden'>
                        </div> */}
                            {/* <CustomSelectModal
                                placeholder='Select classes'
                                options={mapClassesToOptions()}
                                value={}
                                onChange={(options) => handleFormInputChange('classes', options.map(o => o.value))}
                            /> */}
                        </div>
                        <form
                            className="p-2 rounded-md border border-gray-500 mt-2"
                            onSubmit={handleAddTrainingFeeItem}
                        >
                            <div className="grid grid-cols-3 w-full items-center">
                                <h1 className="text-sm p-1">Schedule</h1>
                                <select
                                    required
                                    className="col-span-2 rounded border-gray-500 border-opacity-30 text-sm"
                                    value={
                                        currentTrainingFeeItemData.schedule
                                            ? currentTrainingFeeItemData.schedule
                                            : ''
                                    }
                                    onChange={e =>
                                        handleTrainingFeeFormInputChange('schedule', e.target.value)
                                    }
                                >
                                    <option value="">Select a schedule</option>
                                    {availableScheduleOptions.map(option => (
                                        <option
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid grid-cols-3 w-full items-center">
                                <h1 className="text-sm p-1">Sessions/week</h1>
                                <select
                                    required
                                    className="col-span-2 rounded border-gray-500 border-opacity-30 text-sm disabled:opacity-30"
                                    value={
                                        currentTrainingFeeItemData.sessions
                                            ? currentTrainingFeeItemData.sessions
                                            : ''
                                    }
                                    onChange={e =>
                                        handleTrainingFeeFormInputChange(
                                            'sessions',
                                            Number(e.target.value)
                                        )
                                    }
                                    disabled={currentTrainingFeeItemData.schedule === null}
                                >
                                    <option value="">Select number of sessions per week</option>
                                    {availableSessionOptions.map(option => (
                                        <option
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid grid-cols-3 w-full items-center">
                                <h1 className="text-sm p-1">Amount</h1>
                                <input
                                    className="col-span-2 rounded border-gray-500 border-opacity-30 text-sm"
                                    type="number"
                                    required
                                    value={
                                        currentTrainingFeeItemData.amount
                                            ? currentTrainingFeeItemData.amount
                                            : ''
                                    }
                                    onChange={e =>
                                        handleTrainingFeeFormInputChange('amount', e.target.value)
                                    }
                                    placeholder="Enter amount"
                                />
                            </div>
                            <button className="w-full text-center mt-2 bg-slate-300 text-xl p-1 font-bold text-gray-600 rounded-md cursor-pointer active:scale-[98%] transition-all">
                                Add
                            </button>
                        </form>
                        <div className="p-2 border rounded-lg mt-2 bg-white  h-[240px] flex flex-col">
                            <div className="flex w-full items-center justify-center">
                                <div className="flex-grow grid grid-cols-10 text-lg font-semibold text-gray-600">
                                    <div className="text-center col-span-3">Schedule</div>
                                    <div className="text-center col-span-3">Sessions/week</div>
                                    <div className="text-center col-span-3">Amount</div>
                                </div>
                            </div>
                            <hr className="border-[1px] border-gray-300 m-1" />
                            <div className="w-full items-center justify-center overflow-y-auto thin-scrollbar flex-grow">
                                {feeStructureFormData.trainingFee.map(
                                    (item: TrainingFeeItemType, index: number) => (
                                        <div
                                            className="text-center grid grid-cols-10 w-full my-1 border-b border-bg-slate-100"
                                            key={index}
                                        >
                                            <div className="col-span-3">{item.schedule}</div>
                                            <div className="col-span-3">{item.sessions}</div>
                                            <div className="col-span-3">&#x20b9;{item.amount}</div>
                                            <div className=" text-white font-extrabold rounded">
                                                <img
                                                    src={deleteIcon}
                                                    className="h-6 w-6 cursor-pointer active:scale-[80%] transition-all"
                                                    onClick={() =>
                                                        handleTrainingFeeItemDelete(index)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {feeStructureFormData.feeType === 'Event/Competition' && (
                    <div>
                        <div className="flex items-center gap-4 justify-center">
                            <img
                                src={eventIcon}
                                className="h-6 w-6"
                            />
                            <div className="font-semibold text-gray-600">
                                Event/Competition Fees
                            </div>
                        </div>
                        <hr className="border-2 border-gray-300 m-2" />
                        <form
                            className="p-2 rounded-md border border-gray-500 mt-2"
                            onSubmit={handleAddQualificationFeeItem}
                        >
                            <div className="my-1">
                                <h1 className="text-sm p-1">Type:</h1>
                                <select
                                    className="disabled:bg-gray-200 w-full rounded border-gray-500 border-opacity-30 text-sm"
                                    required
                                    value={
                                        currentQualificationFeeItemData.type
                                            ? currentQualificationFeeItemData.type
                                            : ''
                                    }
                                    onChange={e =>
                                        handleQualificationFeeFormInputChange(
                                            'type',
                                            e.target.value
                                        )
                                    }
                                >
                                    <option
                                        value=""
                                        className="text-gray-500"
                                    >
                                        Choose a type
                                    </option>
                                    <option value="Event">Event</option>
                                    <option value="Competition">Competition</option>
                                    <option value="Exam">Exam</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div className="my-1">
                                <h1 className="text-sm p-1">
                                    Certification Level/Event/Competition Name:
                                </h1>
                                <input
                                    type="text"
                                    className="disabled:opacity-20 w-full"
                                    placeholder="Enter name of certifications/Exams or Competitions or Other Events"
                                    value={currentQualificationFeeItemData.info}
                                    required
                                    onChange={e =>
                                        handleQualificationFeeFormInputChange(
                                            'info',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <h1 className="text-sm p-1">Fees amount:</h1>
                                <div className="flex items-center w-full bg-slate-400 rounded">
                                    <div className="w-10 text-white font-semibold text-center">
                                        &#x20b9;
                                    </div>
                                    <input
                                        className="disabled:opacity-20 flex-grow w-full rounded border-gray-500 border-opacity-30 p-2 text-sm"
                                        value={
                                            currentQualificationFeeItemData.amount
                                                ? currentQualificationFeeItemData.amount
                                                : ''
                                        }
                                        onChange={e =>
                                            handleQualificationFeeFormInputChange(
                                                'amount',
                                                e.target.value
                                            )
                                        }
                                        type="number"
                                        required
                                        placeholder="Enter fee amount"
                                    />
                                </div>
                            </div>
                            <button className="w-full text-center mt-2 bg-slate-300 text-xl p-1 font-bold text-gray-600 rounded-md cursor-pointer active:scale-[98%] transition-all">
                                Add
                            </button>
                        </form>
                        <div className="p-2 border rounded-lg mt-2 bg-white h-[240px] flex flex-col">
                            <div className="flex w-full items-center justify-center">
                                <div className="flex-grow grid grid-cols-10 text-lg font-semibold text-gray-600">
                                    <div className="text-center col-span-3">Type</div>
                                    <div className="text-center col-span-3">Description</div>
                                    <div className="text-center col-span-3">Amount</div>
                                </div>
                            </div>
                            <hr className="border-[1px] border-gray-300 m-1" />
                            <div className="w-full items-center justify-center overflow-y-auto thin-scrollbar flex-grow">
                                {feeStructureFormData.qualificationFee.map(
                                    (item: QualificationItemType, index: number) => (
                                        <div
                                            className="text-center grid grid-cols-10 w-full my-1 border-b border-bg-slate-100"
                                            key={index}
                                        >
                                            <div className="col-span-3">{item.type}</div>
                                            {/* <div className='col-span-3 truncate'>{item.info}</div> */}
                                            <div className="relative group col-span-3">
                                                <div className="cursor-pointer truncate">
                                                    {item.info}
                                                </div>
                                                {/* <div className='hidden sm:w-max group-hover:block absolute top-6 bg-white border border-gray-300 p-2 rounded z-50 shadow-md w-[50vw]'>
                                            {item.info}
                                        </div> */}
                                            </div>
                                            <div className="col-span-3">&#x20b9;{item.amount}</div>
                                            <div className=" text-white font-extrabold rounded">
                                                <img
                                                    src={deleteIcon}
                                                    className="h-6 w-6 cursor-pointer active:scale-[80%] transition-all"
                                                    onClick={() =>
                                                        handleQualificationFeeItemDelete(index)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {feeStructureFormData.feeType === 'Uniform/Gear' && (
                    <div>
                        <div className="flex items-center gap-4 justify-center">
                            <img
                                src={uniformIcon}
                                className="h-6 w-6"
                            />
                            <div className="font-semibold text-gray-600">Uniform/Gear Fees</div>
                        </div>
                        <hr className="border-2 border-gray-300 m-2" />
                        <form
                            className="p-2 rounded-md border border-gray-500 mt-2"
                            onSubmit={handleAddApparelFeeItem}
                        >
                            <div className="my-1">
                                <h1 className="text-sm p-1">Type:</h1>
                                <select
                                    className="disabled:bg-gray-200 w-full rounded border-gray-500 border-opacity-30 text-sm"
                                    onChange={e =>
                                        handleApparelFeeFormInputChange('type', e.target.value)
                                    }
                                    required
                                    value={
                                        currentApparelFeeItemData.type
                                            ? currentApparelFeeItemData.type
                                            : ''
                                    }
                                >
                                    <option
                                        value=""
                                        className="text-gray-500"
                                    >
                                        Choose a type
                                    </option>
                                    <option value="Uniform">Uniform</option>
                                    <option value="Gear">Gear</option>
                                </select>
                            </div>
                            {currentApparelFeeItemData.type === 'Gear' && (
                                <div className="my-1">
                                    <h1 className="text-sm p-1">Name of the Gear:</h1>
                                    <input
                                        type="text"
                                        required={currentApparelFeeItemData.type === 'Gear'}
                                        className="disabled:opacity-20 w-full"
                                        placeholder="Mention the name of the gear"
                                        onChange={e =>
                                            handleApparelFeeFormInputChange(
                                                'gearName',
                                                e.target.value
                                            )
                                        }
                                        value={currentApparelFeeItemData.gearName}
                                    />
                                </div>
                            )}
                            <div className="my-1">
                                <h1 className="text-sm p-1">Size/Description:</h1>
                                <input
                                    type="text"
                                    className="disabled:opacity-20 w-full"
                                    required
                                    placeholder="Enter size or description - ex. 5x5, Black belt, etc"
                                    value={currentApparelFeeItemData.apparelDescription}
                                    onChange={e =>
                                        handleApparelFeeFormInputChange(
                                            'apparelDescription',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <h1 className="text-sm p-1">Fees amount:</h1>
                                <div className="flex items-center w-full bg-slate-400 rounded">
                                    <div className="w-10 text-white font-semibold text-center">
                                        &#x20b9;
                                    </div>
                                    <input
                                        className="flex-grow w-full rounded border-gray-500 border-opacity-30 p-2 text-sm"
                                        type="number"
                                        value={
                                            currentApparelFeeItemData.apparelPrice
                                                ? currentApparelFeeItemData.apparelPrice
                                                : ''
                                        }
                                        required
                                        onChange={e =>
                                            handleApparelFeeFormInputChange(
                                                'apparelPrice',
                                                e.target.value
                                            )
                                        }
                                        placeholder="Enter uniform/gear fees amount"
                                    />
                                </div>
                            </div>
                            <button className="w-full text-center mt-2 bg-slate-300 text-xl p-1 font-bold text-gray-600 rounded-md cursor-pointer active:scale-[98%] transition-all">
                                Add
                            </button>
                        </form>
                        <div className="p-2 border rounded-lg mt-2 bg-white  h-[240px] flex flex-col">
                            <div className="flex w-full items-center justify-center">
                                <div className="flex-grow grid grid-cols-10 text-lg font-semibold text-gray-600">
                                    <div className="text-center col-span-3">Type</div>
                                    <div className="text-center col-span-4">Description</div>
                                    <div className="text-center col-span-2">Amount</div>
                                </div>
                            </div>
                            <hr className="border-[1px] border-gray-300 m-1" />
                            <div className="w-full items-center justify-center overflow-y-auto thin-scrollbar flex-grow">
                                {feeStructureFormData.apparelFee.map(
                                    (item: ApparelFeeItemType, index: number) => (
                                        <div
                                            className="text-center grid grid-cols-10 w-full my-1 border-b border-bg-slate-100"
                                            key={index}
                                        >
                                            <div className="col-span-3">
                                                {item.type}{' '}
                                                {item.type === 'Gear' ? `(${item.gearName})` : ''}
                                            </div>
                                            <div className="col-span-4 truncate">
                                                {item.apparelDescription}
                                            </div>
                                            <div className="col-span-2">
                                                &#x20b9;{item.apparelPrice}
                                            </div>
                                            <div className=" text-white font-extrabold rounded">
                                                <img
                                                    src={deleteIcon}
                                                    className="h-6 w-6 cursor-pointer active:scale-[80%] transition-all"
                                                    onClick={() =>
                                                        handleApparelFeeItemDelete(index)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {feeStructureFormData.feeType === 'Registration' && (
                    <div>
                        <div className="flex items-center gap-4 justify-center">
                            <img
                                src={registrationcon}
                                className="h-6 w-6"
                            />
                            <div className="font-semibold text-gray-600">Registration Fees</div>
                        </div>
                        <hr className="border-2 border-gray-300 m-2" />
                        <div>
                            <h1 className="text-sm p-1">Fees applicable for:</h1>
                            <Select
                                isMulti
                                className="disabled:bg-gray-200 w-full rounded border-gray-500 border-opacity-30 text-sm"
                                placeholder="Select classes"
                                options={mapClassesToOptions()}
                                onChange={options =>
                                    handleFormInputChange(
                                        'classes',
                                        options.map(o => o.value)
                                    )
                                }
                            />
                        </div>
                        <div>
                            <h1 className="text-sm p-1">Fees amount:</h1>
                            <div className="flex items-center w-full bg-slate-400 rounded">
                                <div className="w-10 text-white font-semibold text-center">
                                    &#x20b9;
                                </div>
                                <input
                                    className="disabled:bg-gray-200 flex-grow w-full rounded border-gray-500 border-opacity-30 p-2 text-sm"
                                    type="number"
                                    placeholder="Enter registration fees amount"
                                    value={
                                        feeStructureFormData.registrationFee
                                            ? feeStructureFormData.registrationFee
                                            : ''
                                    }
                                    onChange={e =>
                                        handleFormInputChange(
                                            'registrationFee',
                                            Number(e.target.value)
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {feeStructureFormData.feeType === null && (
                    <div className="flex items-center h-full gap-4 justify-center">
                        <div className="font-semibold text-xl text-gray-600 text-center">
                            Choose an academy and fee type to continue.
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-end w-full mt-4">
                <button
                    className="bg-primary text-white w-full font-semibold py-2 rounded disabled:opacity-20 active:scale-95 transition-all"
                    disabled={
                        feeStructureFormData.trainingFee.length === 0 &&
                        feeStructureFormData.qualificationFee.length === 0 &&
                        feeStructureFormData.apparelFee.length === 0 &&
                        feeStructureFormData.registrationFee === null
                    }
                    onClick={handleSubmitFeeStructureForm}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default FeeStructureForm;
