import React from 'react';
import Navbar from '../../components/landing-page-items/Navbar';
import Hero from '../../components/landing-page-items/Hero';
import Companies from '../../components/landing-page-items/Companies';
import Process from '../../components/landing-page-items/Process';
import Services from '../../components/landing-page-items/Services';
import Testimonials2 from '../../components/landing-page-items/Testimonials';
import Footer from '../../components/landing-page-items/Footer';
import landingPageViewModel from '../../view-models/landing-page-view-model';
import { Helmet } from 'react-helmet-async';

const LandingPage = () => {
    const {
        bookDemoFormData,
        handleInputChange,
        handleBookDemoFormSubmit,
        isDemoBooked,
        handleClick,
    } = landingPageViewModel();

    return (
        <div className="Landing">
            <Helmet>
                <title>Empower your Sports and Arts academy:SpArts</title>
                <meta name='description' content='"A complete solution for studio management, dance studio software,
                fitness center membership software, ballet studio software and sports academy"'/>
            </Helmet>
            <Navbar />
            <Hero
                bookDemoFormData={bookDemoFormData}
                handleInputChange={handleInputChange}
                handleBookDemoFormSubmit={handleBookDemoFormSubmit}
                isDemoBooked={isDemoBooked}
            />
            <hr />
            <Companies />
            <hr />
            <Process />
            <Services />
            <div className="2xl:m-3 w-full border-[.5px] border-[#0008] rounded-xl p-8 flex flex-col sm:flex-row justify-between">
                <div className="flex-1  lg:flex-[25%] text-2xl font-bold">
                    Built specifically for you & your academy
                </div>

                <div className="flex-1 lg:flex-[75%] flex flex-col gap-2 lg:gap-0 lg:flex-row justify-between">
                    <div className="flex flex-row items-center">
                        <div className=" rounded w-[70px] h-[70px] flex items-center justify-center bg-[#E9ECF2] ">
                            <img
                                src="/graphics/footer/star.webp"
                                alt="star"
                            />
                        </div>
                        <div className="text-gray p-3 font-extralight">
                            Digital transition support
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className=" rounded w-[70px] h-[70px] flex items-center justify-center bg-[#E9ECF2] ">
                            <img
                                src="/graphics/footer/badge.webp"
                                alt="star"
                            />
                        </div>
                        <div className="text-gray p-3 font-extralight">
                            Trusted by over 25 academies
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className=" rounded w-[70px] h-[70px] flex items-center justify-center bg-[#E9ECF2] ">
                            <img
                                src="/graphics/footer/bulb.webp"
                                alt="star"
                            />
                        </div>
                        <div className="text-gray p-3 font-extralight">
                            Enrolled 600+ new students
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LandingPage;
