// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formInput__SGVrQ {

    width: 100%;

    min-width: 120px;

    max-width: 160px;

    resize: none;

    border-radius: 0.25rem;

    border-color: rgb(107 114 128 / var(--tw-border-opacity));

    --tw-border-opacity: 0.3;

    padding: 2px;

    padding-left: 0.5rem;

    padding-right: 0.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.style_formInput__SGVrQ:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.style_suggestionBox__op8zR {

    position: absolute;

    z-index: 10;

    margin-top: 0.25rem;

    max-height: 8rem;

    width: 100%;

    overflow-y: auto;

    border-radius: 4px;

    border-width: 2px;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.style_suggestionBoxListWrapper__mZtUO {

    font-size: 0.875rem;

    line-height: 1.25rem
}
.style_suggestionBoxListItem__8l44s {

    cursor: pointer;

    border-bottom-width: 2px;

    padding-left: 0.5rem;

    padding-right: 0.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}
.style_suggestionBoxListItem__8l44s:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/edit-technique-keypoint-form/style.module.css"],"names":[],"mappings":"AACI;;IAAA,WAA6J;;IAA7J,gBAA6J;;IAA7J,gBAA6J;;IAA7J,YAA6J;;IAA7J,sBAA6J;;IAA7J,yDAA6J;;IAA7J,wBAA6J;;IAA7J,YAA6J;;IAA7J,oBAA6J;;IAA7J,qBAA6J;;IAA7J,oBAA6J;;IAA7J,uBAA6J;;IAA7J,mBAA6J;;IAA7J,oBAA6J;;IAA7J,oBAA6J;;IAA7J;AAA6J;;AAA7J;;IAAA,kBAA6J;;IAA7J;AAA6J;;AAI7J;;IAAA,kBAAyF;;IAAzF,WAAyF;;IAAzF,mBAAyF;;IAAzF,gBAAyF;;IAAzF,WAAyF;;IAAzF,gBAAyF;;IAAzF,kBAAyF;;IAAzF,iBAAyF;;IAAzF,kBAAyF;;IAAzF;AAAyF;AAGzF;;IAAA,mBAAc;;IAAd;AAAc;AAGd;;IAAA,eAA4D;;IAA5D,wBAA4D;;IAA5D,oBAA4D;;IAA5D,qBAA4D;;IAA5D,oBAA4D;;IAA5D;AAA4D;AAA5D;;IAAA,kBAA4D;;IAA5D;AAA4D","sourcesContent":[".formInput {\n    @apply px-2 py-1 text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded disabled:bg-slate-100 p-[2px] max-w-[160px] min-w-[120px];\n}\n\n.suggestionBox {\n    @apply absolute w-full max-h-32 overflow-y-auto bg-white z-10 rounded-[4px] mt-1 border-2;\n}\n.suggestionBoxListWrapper {\n    @apply text-sm;\n}\n.suggestionBoxListItem {\n    @apply px-2 py-1 border-b-2 cursor-pointer hover:bg-slate-50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInput": `style_formInput__SGVrQ`,
	"suggestionBox": `style_suggestionBox__op8zR`,
	"suggestionBoxListWrapper": `style_suggestionBoxListWrapper__mZtUO`,
	"suggestionBoxListItem": `style_suggestionBoxListItem__8l44s`
};
export default ___CSS_LOADER_EXPORT___;
