import { SupplierListWrapper } from '../models/supplier/supplier-list-wrapper';
import { User } from '../models/user/user';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { getJWTToken } from '../utils/helpers/helpers';
import { EnquiryListWrapper } from '../models/enquiry/enquiry-list-wrapper';

export class HeaderMenuService {
    private static _instance: HeaderMenuService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new HeaderMenuService());
    }

    public getUserInfo = async (): Promise<ApiResponse<User>> => {
        return invokeApi<null, User>({
            route: `users/me?populate=role`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getSupplierDetails = async (
        userId: number
    ): Promise<ApiResponse<SupplierListWrapper>> => {
        return invokeApi<null, SupplierListWrapper>({
            route: `supplier-details/?populate=*&filters[userInfo][id][$eq]=${userId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentList = async (
        supplierId: number,
        searchTerm: string
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=*&filters[users][id]=${supplierId}&filters[$or][0][firstName][$contains]=${searchTerm}&filters[$or][1][lastName][$contains]=${searchTerm}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getParentUsersList = async (from: Date) => {
        return invokeApi<null, User[]>({
            route: `users?filters[role][type]=parent&filters[createdAt][$gte]=${from.toISOString()}&fields[0]=createdAt`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getEnquiriesList = async (from: Date) => {
        return invokeApi<null, EnquiryListWrapper>({
            route: `enquiries?populate=addedBy.role&&filters[createdAt][$gte]=${from.toISOString()}&fields[0]=createdAt`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
