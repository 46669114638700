import { TrainedStudentFaceDataWrapper } from '../models/attendance/trained-student-face-data-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';

export class UtilityService {
    private static _instance: UtilityService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new UtilityService());
    }

    public getStudentsTrainedFaceData = async (id: number) => {
        return invokeApi<null, {data: TrainedStudentFaceDataWrapper}>({
            route: `students-trained-face-data/${id}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
