import React from 'react'
import ArrowRightIcon from '../../../../assets/icons/arrow-right.png'
import { CertificationData } from '../../../../models/certifications/certification-data';

interface CertificatePromotionFormProps {
    selectedStudents: number[];
    selectedLevelToPromote: number;
    setSelectedLevelToPromote: React.Dispatch<React.SetStateAction<number>>;
    selectedCertificationData: CertificationData;
    certificationsList: CertificationData[];
    handleAddStudentCertificateRecord: () => void;
}

const CertificatePromotionForm = ({
    selectedStudents,
    selectedLevelToPromote,
    setSelectedLevelToPromote,
    selectedCertificationData,
    certificationsList,
    handleAddStudentCertificateRecord,
}: CertificatePromotionFormProps) => {
  return (
    <div>
        <div className='p-4 bg-slate-100 rounded border-2 sm:w-[540px]' >
            <div className='flex items-center justify-between sm:gap-0 gap-[30vw]'>
                <div className='font-semibold text-xs sm:text-lg text-slate-600'>{`Promoting ${selectedStudents.length === 1 ? '1 student' : `${selectedStudents.length} students`}`}</div>
                <div className='text-xs sm:text-base cursor-pointer px-2 py-1 rounded border-2 border-slate-600 text-slate-600 font-semibold'>View list</div>
            </div>
            <hr className='my-2 border-2 border-slate-400'/>
            
            <div className='flex items-center justify-center sm:py-10'>
                {selectedCertificationData.attributes ? (
                    <div className='sm:w-auto w-[30%] flex flex-col items-center border-2 border-slate-300 rounded p-2 font-semibold text-slate-600'>
                        <div>{selectedCertificationData?.attributes?.name}</div>
                        <hr className='w-[95%] my-2 sm:w-full border-2 border-slate-300'/>
                        <select 
                            className='w-full sm:w-[140px] bg-slate-300 p-2 rounded text-center'
                            value={selectedCertificationData?.attributes?.level}
                            disabled
                        >
                            <option key={selectedCertificationData?.id} value={selectedCertificationData?.attributes?.level}>Level {selectedCertificationData?.attributes?.level}</option>
                        </select>
                    </div>
                ) : (
                    <div className='sm:w-auto w-[30%] flex flex-col items-center border-2 border-slate-300 rounded p-2 font-semibold text-slate-600'>
                        <div className='px-4 py-2'>Not Certified</div>
                    </div>
                )}
                <div>
                    <img 
                        src={ArrowRightIcon}
                        className='h-8 w-8 sm:h-16 sm:w-16'
                    />
                </div>
                <div className='sm:w-auto w-[30%] text-center flex flex-col items-center border-2 border-slate-300 rounded p-2 font-semibold text-slate-600'>
                    {!selectedCertificationData.attributes ? (
                        <>
                            <div>{certificationsList?.find(cert => cert?.attributes?.level === selectedLevelToPromote)?.attributes?.name}</div>
                            <hr className='my-2 w-full border-2 border-slate-300'/>
                            <select 
                                className='w-full sm:w-[140px] p-2 rounded text-center'
                                value={selectedLevelToPromote}
                                onChange={e => setSelectedLevelToPromote(Number(e.target.value))}
                                
                            >
                                {certificationsList?.map(cert => (
                                    <option key={cert?.id} value={cert?.attributes?.level}>Level {cert?.attributes?.level}</option>
                                ))}
                            </select>
                        </>
                    ) : certificationsList?.filter(cert => cert?.attributes?.level > selectedCertificationData?.attributes?.level).length > 0 ? (
                            <>
                                <div>{certificationsList?.find(cert => cert?.attributes?.level === selectedLevelToPromote)?.attributes?.name}</div>
                                <hr className='my-2 w-full border-2 border-slate-300'/>
                                <select 
                                    className='w-full sm:w-[140px] p-2 rounded text-center'
                                    value={selectedLevelToPromote}
                                    onChange={e => setSelectedLevelToPromote(Number(e.target.value))}
                                    
                                >
                                    {certificationsList?.filter(cert => cert?.attributes?.level > selectedCertificationData?.attributes?.level).map(cert => (
                                        <option key={cert?.id} value={cert?.attributes?.level}>Level {cert?.attributes?.level}</option>
                                    ))}
                                </select>
                            </>
                        ) : (
                            <div className='text-slate-600 font-semibold'>No more levels to promote</div>
                        )}
                </div>
            </div>

            <div className='w-full p-2 rounded border-2 border-slate-200 flex items-center justify-between relative overflow-hidden'>
                <div className='h-2 bg-gray-300 w-[calc(100%-16px)] absolute'></div>
                {!selectedCertificationData?.attributes &&
                    <div className='h-12 w-12 rounded-full bg-slate-300 z-10 border-4 border-blue-500'></div>
                }
                {certificationsList
                    .filter((cert) => cert?.attributes?.level <= selectedLevelToPromote && cert?.attributes?.level >= (selectedCertificationData?.attributes?.level ?? 0))
                    .map((cert, index, filteredCertifications) => (
                        <div
                            key={cert?.id}
                            className={`h-12 w-12 rounded-full bg-slate-300 z-10 flex items-center justify-center
                                ${(index === 0 && selectedCertificationData?.attributes) ? 'border-4 border-blue-500' : ''}
                                ${index === filteredCertifications.length - 1 ? 'border-4 border-green-500' : ''}
                            `}
                        >
                            {cert?.attributes?.level}
                        </div>
                    ))
                }
            </div>
        </div>
        {(!selectedCertificationData?.attributes || certificationsList?.filter(cert => cert?.attributes?.level > selectedCertificationData?.attributes?.level).length > 0) && (
            <div className='flex justify-end items-center mt-2'>
                <div 
                    className='cursor-pointer px-2 py-1 text-lg rounded bg-slate-600 text-white font-semibold'
                    onClick={handleAddStudentCertificateRecord}
                >
                    Continue
                </div>
            </div>
        )}
    </div>
  )
}

export default CertificatePromotionForm