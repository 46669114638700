import React from 'react';
import style from './style.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import chevrondownIcon from '../../../../assets/icons/chevrondown.png';
import coachProfile from '../../../../assets/icons/coach-profile.png';
import plusIcon from '../../../../assets/icons/plus.png';
import arrowDownIcon from '../../../../assets/icons/down-arrow.png';
import arrowUpIcon from '../../../../assets/icons/up-arrow.png';
import { useCollapse } from 'react-collapsed';
import { CoachData } from '../../../../models/coach/coach-data';
import {
    ACADEMY_ACCESS_OPTIONS,
    CLASS_ACCESS_OPTIONS,
    DASHBOARD_ACCESS_OPTIONS,
    ENQUIRIES_ACCESS_OPTIONS,
    FEE_PAYMENT_ACCESS_OPTIONS,
    SCHEDULE_ACCESS_OPTIONS,
    STUDENT_ACCESS_OPTIONS,
} from '../../widgets/new-user-form/data';

interface CoachListItemProps {
    isCoachItemExpanded?: boolean;
    isExpandable: boolean;
    coachData: CoachData;
    handleCoachItemExpand?: () => void;
    isCoachAcademiesListExpanded?: boolean;
    handleCoachListItemAcademiesExpandClick?: () => void;
    handleUserEditClick?: () => void;
    handleBlockUserClick?: () => void;
    handleUnblockUserClick?: () => void;
}

const CoachListItem = ({
    isCoachItemExpanded,
    coachData,
    handleCoachItemExpand,
    isCoachAcademiesListExpanded,
    handleCoachListItemAcademiesExpandClick,
    handleBlockUserClick,
    handleUnblockUserClick,
    handleUserEditClick,
    isExpandable,
}: CoachListItemProps) => {
    const { getCollapseProps } = useCollapse({
        isExpanded: isCoachItemExpanded,
    });

    return (
        <div className={style.coachListItemContainer}>
            <div className={style.coachListItemWrapperContainer}>
                <div className={style.coachProfileImgContainer}>
                    <img
                        className={style.coachProfileImg}
                        src={coachData?.attributes?.photo?.data?.attributes?.url ?? coachProfile}
                    />
                </div>
                <div className={style.coachInfoContainer}>
                    <div className={style.coachName}>
                        {coachData?.attributes?.name}
                        {coachData?.attributes?.userInfo?.data?.attributes?.blocked === true && (
                            <div className={style.blockedTag}>Blocked</div>
                        )}
                    </div>
                    <div className="inline-flex gap-1">
                        Academy:{' '}
                        {coachData?.attributes?.academies?.data.length === 1 ? (
                            <div>{coachData?.attributes?.academies?.data[0]?.attributes?.name}</div>
                        ) : (
                            <div className="inline-flex items-center gap-1 relative">
                                {coachData?.attributes?.academies?.data[0]?.attributes?.name}
                                <img
                                    className="p-1 h-4 w-4"
                                    src={plusIcon}
                                    alt="show all icon"
                                />
                                <div
                                    onClick={handleCoachListItemAcademiesExpandClick}
                                    className="cursor-pointer bg-slate-100 flex items-center justify-center h-4 w-4 rounded-full text-xs"
                                >
                                    {coachData?.attributes?.academies?.data?.length - 1}
                                </div>
                                {isCoachAcademiesListExpanded && (
                                    <div className="absolute bg-white p-2 rounded top-0 left-full ml-1 shadow w-[200px]">
                                        {coachData?.attributes?.academies?.data?.map(item => (
                                            <div key={item?.id}>{item?.attributes?.name}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div>Contact number: {coachData?.attributes?.contactNo}</div>
                    <div
                        className={style.collapsedInfo}
                        {...getCollapseProps()}
                    >
                        <div>Email ID: {coachData?.attributes?.email}</div>
                        <div className="col-span-2">
                            Govt ID:{' '}
                            <a
                                className={style.govtIdLinkText}
                                href={coachData?.attributes?.governmentId?.data?.attributes?.url}
                                target="blank"
                            >
                                {coachData?.attributes?.governmentId?.data?.attributes?.name}
                            </a>
                        </div>
                        <div className={style.userPermissionsInfoContainer}>
                            <div className={style.permissionTitle}>Dashboard</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.dashboardAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                DASHBOARD_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                            <hr className="col-span-3 m-1" />
                            <div className={style.permissionTitle}>Academy Profile</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.academyAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                ACADEMY_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                            <hr className="col-span-3 m-1" />
                            <div className={style.permissionTitle}>Classes & Sub groups</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.classesAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                CLASS_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                            <hr className="col-span-3 m-1" />
                            <div className={style.permissionTitle}>Students</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.studentsAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                STUDENT_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                            <hr className="col-span-3 m-1" />
                            <div className={style.permissionTitle}>Fee Payment</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.feePaymentAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                FEE_PAYMENT_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                            <hr className="col-span-3 m-1" />
                            <div className={style.permissionTitle}>Enquiries</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.enquiriesAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                ENQUIRIES_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                            <hr className="col-span-3 m-1" />
                            <div className={style.permissionTitle}>Schedule</div>
                            <div className={style.permissionInfo}>
                                {coachData?.attributes?.scheduleAccess
                                    .split(',')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-sm px-2 ${item === 'noAccess' ? 'bg-red-200' : 'bg-green-200'}`}
                                        >
                                            {
                                                SCHEDULE_ACCESS_OPTIONS.filter(
                                                    val => val.value === item
                                                )[0].label
                                            }
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                {!isExpandable ? (
                    <></>
                ) : !isCoachItemExpanded ? (
                    <>
                        <div className={style.expandBtnWrapper}>
                            <button
                                className={style.listItemButton}
                                onClick={handleCoachItemExpand}
                            >
                                View details{' '}
                                <img
                                    className={style.listItemDownIcon}
                                    src={chevrondownIcon}
                                    alt="down"
                                />
                            </button>
                        </div>
                        <div
                            className="sm:hidden cursor-pointer"
                            onClick={handleCoachItemExpand}
                        >
                            <img
                                src={arrowDownIcon}
                                className="h-6 w-6"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.expandBtnWrapper}>
                            <button
                                className={style.listItemButton}
                                onClick={handleCoachItemExpand}
                            >
                                Hide details{' '}
                                <img
                                    className={style.listItemUpIcon}
                                    src={chevrondownIcon}
                                    alt="down"
                                />
                            </button>
                        </div>
                        <div
                            className="sm:hidden cursor-pointer"
                            onClick={handleCoachItemExpand}
                        >
                            <img
                                src={arrowUpIcon}
                                className="h-6 w-6"
                            />
                        </div>
                    </>
                )}
            </div>
            <div
                className={style.editBtnWrapper}
                {...getCollapseProps()}
            >
                {coachData?.attributes?.userInfo?.data?.attributes?.blocked === true ? (
                    <button
                        className={style.unblockUserBtn}
                        onClick={handleUnblockUserClick}
                    >
                        Unblock User
                    </button>
                ) : (
                    <button
                        className={style.blockUserBtn}
                        onClick={handleBlockUserClick}
                    >
                        Block User
                    </button>
                )}
                <button
                    className={style.editCoachBtn}
                    onClick={handleUserEditClick}
                >
                    Edit User
                </button>
            </div>
        </div>
    );
};

export default CoachListItem;
