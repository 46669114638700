import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user-slice';
import virtualMeetingReducer from './virtual-meeting-slice';

const store = configureStore({
    reducer: {
        user: userReducer,
        virtualMeeting: virtualMeetingReducer,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
