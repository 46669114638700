import React from 'react'
import CertifyNewStudentViewModel from '../../view-models/actions-view-models/certify-new-student-view-model'
import PopupModal from '../../components/widgets/popup-modal'
import CertifyNewStudentForm from '../../components/widgets/certify-new-student-form'
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { StudentData } from '../../../models/student/student-data';
import { CertificationData } from '../../../models/certifications/certification-data';

interface CertifyNewStudentProps {
    isOpen: boolean;
    handleClose: () => void;
    availableNewStudentsToCertify: StudentData[];
    selectedCertificationData: CertificationData;
}

const CertifyNewStudent = ({
    isOpen,
    handleClose,
    availableNewStudentsToCertify,
    selectedCertificationData,
}: CertifyNewStudentProps) => {

    const {
        isLoading,
        handleModalClose,
        selectedStudents,
        handleAddStudent,
        handleRemoveStudent,
        serchTerm,
        setSearchTerm,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        handleCertifyNewStudentsFormSubmit,
    } = CertifyNewStudentViewModel({
        handleClose,
        selectedCertificationData,
    })

    return (
        <>
            {isLoading && <FullScreenLoader/>}
            <PopupModal
                title="Certify New Students"
                isOpen={isOpen}
                handleModalClose={handleModalClose}
                background='white'
            >
                <CertifyNewStudentForm 
                    availableNewStudentsToCertify={
                        availableNewStudentsToCertify?.filter(student => !selectedStudents?.includes(student.id))
                            ?.filter(student => student?.attributes?.firstName?.toLowerCase()?.includes(serchTerm?.toLowerCase()) || 
                                    student?.attributes?.lastName?.toLowerCase()?.includes(serchTerm?.toLowerCase()))
                    }
                    allAvailableStudents={availableNewStudentsToCertify}
                    handleAddStudent={handleAddStudent}
                    handleRemoveStudent={handleRemoveStudent}
                    serchTerm={serchTerm}
                    setSearchTerm={setSearchTerm}
                    selectedStudents={selectedStudents}
                    isSearchInputOnFocus={isSearchInputOnFocus}
                    setIsSearchInputOnFocus={setIsSearchInputOnFocus}
                    selectedCertificationData={selectedCertificationData}
                    handleCertifyNewStudentsFormSubmit={handleCertifyNewStudentsFormSubmit}
                />
            </PopupModal>
        </>
    )
}

export default CertifyNewStudent
