import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Login from '../../view/pages/login';
import { getJWTToken } from '../helpers/helpers';

export const AuthWrapper = ({ children }: { children: ReactNode }) => {
    const userState = useSelector((state: RootState) => state.user);

    if (userState.user && getJWTToken() && getJWTToken() !== '') {
        return <>{children}</>;
    } else {
        return <Login />;
    }
};
