import React, { ReactNode, useEffect } from 'react';
import PopupModal from '../../components/widgets/popup-modal';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import AddFeeStructureViewModel from '../../view-models/actions-view-models/add-fee-structure-view-model';
import FeeStructureForm from '../../components/widgets/fee-structure-form';
import { FeeStructureData } from '../../../models/fee-structure/fee-structure-data';

interface AddFeeStructureProps {
    isOpen: boolean;
    handleClose: () => void;
    feeStructuresData: FeeStructureData[];
}

const AddFeeStructure = ({ isOpen, handleClose, feeStructuresData }: AddFeeStructureProps) => {
    const {
        isLoading,
        feeStructureFormData,
        handleFormInputChange,
        mapClassesToOptions,
        allAcademies,
        handleFeeStructureModalClose,
        handleAddTrainingFeeItem,
        currentTrainingFeeItemData,
        handleTrainingFeeFormInputChange,
        handleTrainingFeeItemDelete,
        handleSubmitFeeStructureForm,
        handleApparelFeeFormInputChange,
        handleAddApparelFeeItem,
        handleApparelFeeItemDelete,
        currentApparelFeeItemData,
        currentQualificationFeeItemData,
        handleQualificationFeeFormInputChange,
        handleAddQualificationFeeItem,
        handleQualificationFeeItemDelete,
        trainingFeeAvailable,
        registrationFeesAvailable,
        apparelFeesAvailable,
        qualificationFeesAvailable,
        availableScheduleOptions,
        availableSessionOptions,
    } = AddFeeStructureViewModel({ handleClose, feeStructuresData });

    return (
        <PopupModal
            handleModalClose={handleFeeStructureModalClose}
            isOpen={isOpen}
            background="white"
            title="Add Fee Structure"
        >
            {isLoading && <FullScreenLoader />}
            <FeeStructureForm
                feeStructureFormData={feeStructureFormData}
                handleFormInputChange={handleFormInputChange}
                mapClassesToOptions={mapClassesToOptions}
                allAcademies={allAcademies}
                handleAddTrainingFeeItem={handleAddTrainingFeeItem}
                currentTrainingFeeItemData={currentTrainingFeeItemData}
                handleTrainingFeeFormInputChange={handleTrainingFeeFormInputChange}
                handleTrainingFeeItemDelete={handleTrainingFeeItemDelete}
                handleSubmitFeeStructureForm={handleSubmitFeeStructureForm}
                handleApparelFeeFormInputChange={handleApparelFeeFormInputChange}
                handleAddApparelFeeItem={handleAddApparelFeeItem}
                handleApparelFeeItemDelete={handleApparelFeeItemDelete}
                currentApparelFeeItemData={currentApparelFeeItemData}
                currentQualificationFeeItemData={currentQualificationFeeItemData}
                handleQualificationFeeFormInputChange={handleQualificationFeeFormInputChange}
                handleAddQualificationFeeItem={handleAddQualificationFeeItem}
                handleQualificationFeeItemDelete={handleQualificationFeeItemDelete}
                trainingFeeAvailable={trainingFeeAvailable}
                registrationFeesAvailable={registrationFeesAvailable}
                apparelFeesAvailable={apparelFeesAvailable}
                qualificationFeesAvailable={qualificationFeesAvailable}
                availableScheduleOptions={availableScheduleOptions}
                availableSessionOptions={availableSessionOptions}
            />
        </PopupModal>
    );
};

export default AddFeeStructure;
