// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_requiredFieldLabelPrompt__AIvI7 {

    --tw-text-opacity: 1;

    color: rgb(220 38 38 / var(--tw-text-opacity))
}

.style_newClassFormContainer__CfjqY {

    width: 85vw;

    padding: 1rem;

    padding-bottom: 0.25rem
}

@media (min-width: 640px) {

    .style_newClassFormContainer__CfjqY {

        width: 480px
    }
}

.style_formInputLabel__pDvp7 {

    margin-top: 0.75rem;

    margin-bottom: 0.25rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

.style_formInput__Dexjw {

    width: 100%;

    resize: none;

    border-radius: 0.25rem;

    border-color: rgb(107 114 128 / var(--tw-border-opacity));

    --tw-border-opacity: 0.3;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.style_formInput__Dexjw:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.style_requiredFieldLabelPrompt__AIvI7 {

    --tw-text-opacity: 1;

    color: rgb(220 38 38 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/add-academy/style.module.css"],"names":[],"mappings":"AACI;;IAAA,oBAAmB;;IAAnB;AAAmB;;AAInB;;IAAA,WAAqC;;IAArC,aAAqC;;IAArC;AAAqC;;AAArC;;IAAA;;QAAA;IAAqC;AAAA;;AAIrC;;IAAA,mBAAwB;;IAAxB,sBAAwB;;IAAxB,mBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,WAA+G;;IAA/G,YAA+G;;IAA/G,sBAA+G;;IAA/G,yDAA+G;;IAA/G,wBAA+G;;IAA/G,mBAA+G;;IAA/G,oBAA+G;;IAA/G,oBAA+G;;IAA/G;AAA+G;;AAA/G;;IAAA,kBAA+G;;IAA/G;AAA+G;;AAI/G;;IAAA,oBAAmB;;IAAnB;AAAmB","sourcesContent":[".requiredFieldLabelPrompt {\n    @apply text-red-600;\n}\n\n.newClassFormContainer {\n    @apply p-4 w-[85vw] sm:w-[480px] pb-1;\n}\n\n.formInputLabel {\n    @apply text-sm mt-3 mb-1;\n}\n\n.formInput {\n    @apply text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded disabled:bg-slate-100;\n}\n\n.requiredFieldLabelPrompt {\n    @apply text-red-600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requiredFieldLabelPrompt": `style_requiredFieldLabelPrompt__AIvI7`,
	"newClassFormContainer": `style_newClassFormContainer__CfjqY`,
	"formInputLabel": `style_formInputLabel__pDvp7`,
	"formInput": `style_formInput__Dexjw`
};
export default ___CSS_LOADER_EXPORT___;
