import React from 'react';
import style from './style.module.css';
import uploadIcon from '../../../../assets/icons/upload.png';
import CustomCarousel from '../custom-carousel';

interface PhotoUploadFormProps {
    handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    previewURLs: string[];
    removeSelectedImage: (index: number) => void;
    uploadSelectedImage: () => void;
    triggerFileInput: () => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    handlePrev: (length: number) => void;
    handleNext: (length: number) => void;
    handleDotClick: (length: number) => void;
    currentImageIndex: number;
}

const PhotoUploadForm = ({
    handleImageChange,
    previewURLs,
    removeSelectedImage,
    fileInputRef,
    triggerFileInput,
    uploadSelectedImage,
    handlePrev,
    handleNext,
    handleDotClick,
    currentImageIndex,
}: PhotoUploadFormProps) => {
    return (
        <div className={style.formContainer}>
            <div
                className={`${style.photoUploadBtnContainer} ${
                    previewURLs.length === 0 ? '' : '!hidden'
                }`}
            >
                <div className={style.photoUploadInputContainer}>
                    <div className={style.photoUploadInputInputContainer}>
                        <div className={style.formImageInputBtnWrapper}>
                            <label
                                htmlFor="fileInput"
                                className={style.formImageInputBtnLabel}
                            >
                                <input
                                    type="file"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    className={style.formImageInput}
                                    onChange={handleImageChange}
                                    accept="image/*"
                                />
                                <div className={style.formImageInputLabel}>
                                    <img
                                        src={uploadIcon}
                                        alt="upload icon"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    Upload here
                </div>
            </div>
            <div
                className={`${style.photoUploadBtnContainer} ${
                    previewURLs.length === 0 ? '!hidden' : ''
                }`}
            >
                <div className={style.selectedImageContainer}>
                    <CustomCarousel
                        handlePrev={handlePrev}
                        isApiUpload={true}
                        handleNext={handleNext}
                        handleDotClick={handleDotClick}
                        imageArray={previewURLs}
                        currentImageIndex={currentImageIndex}
                        topCount={true}
                    />
                </div>
            </div>
            <div className={style.submitBtnContainer}>
                {previewURLs.length > 0 && (
                    <button
                        className={style.addMoreBtn}
                        onClick={triggerFileInput}
                    >
                        + Add more
                    </button>
                )}
                <button
                    className={style.submitBtn}
                    disabled={!previewURLs.length}
                    onClick={uploadSelectedImage}
                >
                    Proceed
                </button>
            </div>
        </div>
    );
};

export default PhotoUploadForm;
