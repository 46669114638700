import React from 'react';
import PopupModal from '../../components/widgets/popup-modal';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import TakeFitnessTestViewModel from '../../view-models/actions-view-models/take-fitness-test-view-model';
import { FitnessTestDetailsData } from '../../../models/progress-tracker/fitness-test-details-data';
import TakeFitnessTestForm from '../../components/widgets/take-fitness-test-form';
import { FitnessTestConductedData } from '../../../models/progress-tracker/fitness-test-conducted-data';

interface TakeFitnessTestProps {
    isOpen: boolean;
    handleClose: () => void;
    selectedFitnessTest: FitnessTestDetailsData;
    handleRecordFitnessTestModalOpen: (data: FitnessTestConductedData) => void
}

const TakeFitnessTest = ({
    isOpen,
    handleClose,
    selectedFitnessTest,
    handleRecordFitnessTestModalOpen,
}: TakeFitnessTestProps) => {
    const {
        isLoading,
        handleCloseModal,
        mapAcademyToOptions,
        takeFitnessTestFormData,
        handleFormInputChange,
        mapDisciplinesToOptions,
        mapClassesToOptions,
        studentSearchTerm,
        handleStudentSearchTermChange,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        studentSuggestionListData,
        handleSugestionListItemClick,
        isStudentDataFetchLoading,
        inputRef,
        suggestionBoxRef,
        handleRemoveStudent,
        isStudentFilterParametersSubmitted,
        handleStudentFilterParametersSubmit,
        handleStudentFilterParametersReset,
        handleClearAllStudents,
        handleCunductableFitnessTestSubmit,
    } = TakeFitnessTestViewModel({ handleClose, selectedFitnessTest, handleRecordFitnessTestModalOpen });

    return (
        <PopupModal
            title="Take Fitness Test"
            isOpen={isOpen}
            background='white'
            handleModalClose={handleCloseModal}
        >
            {isLoading && <FullScreenLoader />}
            <TakeFitnessTestForm
                selectedFitnessTest={selectedFitnessTest}
                mapAcademyToOptions={mapAcademyToOptions}
                takeFitnessTestFormData={takeFitnessTestFormData}
                handleFormInputChange={handleFormInputChange}
                mapDisciplinesToOptions={mapDisciplinesToOptions}
                mapClassesToOptions={mapClassesToOptions}
                studentSearchTerm={studentSearchTerm}
                handleStudentSearchTermChange={handleStudentSearchTermChange}
                isSearchInputOnFocus={isSearchInputOnFocus}
                setIsSearchInputOnFocus={setIsSearchInputOnFocus}
                studentSuggestionListData={studentSuggestionListData}
                isStudentDataFetchLoading={isStudentDataFetchLoading}
                handleSugestionListItemClick={handleSugestionListItemClick}
                inputRef={inputRef}
                suggestionBoxRef={suggestionBoxRef}
                handleRemoveStudent={handleRemoveStudent}
                isStudentFilterParametersSubmitted={isStudentFilterParametersSubmitted}
                handleStudentFilterParametersSubmit={handleStudentFilterParametersSubmit}
                handleClearAllStudents={handleClearAllStudents}
                handleStudentFilterParametersReset={handleStudentFilterParametersReset}
                handleCunductableFitnessTestSubmit={handleCunductableFitnessTestSubmit}
            />
        </PopupModal>
    );
};

export default TakeFitnessTest;
