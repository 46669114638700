import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { ENQUIRY_QUERY } from "../../utils/constants/constants";
import { EnquiryService } from "../../services/enquiry-services";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/redux/store";

interface CalendarEvent {
    title: string;
    start: Date;
    end: Date;
    description?: string;
    contact?: number | string;
    name?: string;
    discipline?: string;
    noOfStudents?: number
}

const CalendarViewModel = () => {

    const userState = useSelector((state: RootState) => state.user);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>();
    const [showPopup, setShowPopup] = useState(false);

    const handleEventClick = useCallback((event: CalendarEvent) => {
        setSelectedEvent(event);
        setShowPopup(true);
    },[]);

    const closePopup = () => {
        setShowPopup(false);
        setSelectedEvent(null);
    };

    const { isSuccess: isEnquiriesFetchSuccess, data: enquiriesData } = useQuery({
        queryKey: [ENQUIRY_QUERY],
        queryFn: EnquiryService.instance.getEnquiriesList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

        const enquiryData =
            enquiriesData?.data?.data?.map(data => {
            const start: Date = new Date(data?.attributes?.demoDateAndTime);
            const title = 'ENQUIRY/DEMO';
            const contact = data?.attributes?.contactNo;
            const name = data?.attributes?.firstName + data?.attributes?.middleName + data?.attributes?.lastName;
            const discipline =  data?.attributes?.discipline;
            // const rRule = 'FREQ=WEEKLY;BYDAY=MO,WE';
            const end: Date = new Date(data?.attributes?.demoDateAndTime);
            return {start, end, title, contact, name, discipline};
            }) || []
    

    return {
        enquiriesData: enquiryData,
        handleEventClick,
        showPopup,
        selectedEvent,
        closePopup
    }
}



export default CalendarViewModel;