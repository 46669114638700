export const DASHBOARD_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'restrictedView', label: 'Access without graphs & analytics' },
];

export const ACADEMY_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'viewOnly', label: 'View Only' },
    { value: 'noAccess', label: 'No Access' },
];

export const CLASS_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'viewOnly', label: 'View Only' },
    { value: 'noAccess', label: 'No Access' },
];

export const USER_MANAGEMENT_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'noAccess', label: 'No Access' },
];

export const STUDENT_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'addOnly', label: 'Add Only' },
    { value: 'restrictedView', label: 'Restricted View Only' },
    { value: 'noAccess', label: 'No Access' },
];

export const FEE_PAYMENT_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'addOnly', label: 'Add Only' },
    { value: 'restrictedView', label: 'Restricted View Only' },
    { value: 'noAccess', label: 'No Access' },
];

export const ENQUIRIES_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'addOnly', label: 'Add Only' },
    { value: 'restrictedView', label: 'Restricted View Only' },
    { value: 'noAccess', label: 'No Access' },
];

export const SCHEDULE_ACCESS_OPTIONS = [
    { value: 'fullAccess', label: 'Full Access' },
    { value: 'noAccess', label: 'No Access' },
];
