// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__B6\\+WQ {

    min-width: 340px;

    padding: 0.5rem
}

.style_dropStudentNoteHeader__Mheha {

    text-align: center;

    font-size: 1.25rem;

    line-height: 1.75rem;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.style_checkBoxLabelWrapper__HXQmy {

    display: inline-flex;

    align-items: center;

    gap: 0.5rem
}
.style_academyCheckBoxContainer__vhS\\+k {

    display: flex;

    flex-direction: column;

    gap: 0.5rem;

    padding: 0.5rem
}

.style_dropStudentBtn__SBZtf {

    border-radius: 0.25rem;

    --tw-bg-opacity: 1;

    background-color: rgb(248 113 113 / var(--tw-bg-opacity));

    padding: 0.5rem
}

.style_dropBtnContainer__K8ZPA {

    margin-top: 1rem;

    display: flex;

    justify-content: flex-end
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/drop-student-form/style.module.css"],"names":[],"mappings":"AACI;;IAAA,gBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,kBAAsD;;IAAtD,kBAAsD;;IAAtD,oBAAsD;;IAAtD,gBAAsD;;IAAtD,oBAAsD;;IAAtD;AAAsD;;AAItD;;IAAA,oBAAqC;;IAArC,mBAAqC;;IAArC;AAAqC;AAGrC;;IAAA,aAA8B;;IAA9B,sBAA8B;;IAA9B,WAA8B;;IAA9B;AAA8B;;AAI9B;;IAAA,sBAA6B;;IAA7B,kBAA6B;;IAA7B,yDAA6B;;IAA7B;AAA6B;;AAI7B;;IAAA,gBAA4B;;IAA5B,aAA4B;;IAA5B;AAA4B","sourcesContent":[".container {\n    @apply min-w-[340px] p-2;\n}\n\n.dropStudentNoteHeader {\n    @apply text-center font-semibold text-xl text-gray-600;\n}\n\n.checkBoxLabelWrapper {\n    @apply inline-flex items-center gap-2;\n}\n.academyCheckBoxContainer {\n    @apply flex flex-col gap-2 p-2;\n}\n\n.dropStudentBtn {\n    @apply bg-red-400 p-2 rounded;\n}\n\n.dropBtnContainer {\n    @apply flex justify-end mt-4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__B6+WQ`,
	"dropStudentNoteHeader": `style_dropStudentNoteHeader__Mheha`,
	"checkBoxLabelWrapper": `style_checkBoxLabelWrapper__HXQmy`,
	"academyCheckBoxContainer": `style_academyCheckBoxContainer__vhS+k`,
	"dropStudentBtn": `style_dropStudentBtn__SBZtf`,
	"dropBtnContainer": `style_dropBtnContainer__K8ZPA`
};
export default ___CSS_LOADER_EXPORT___;
