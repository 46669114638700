import React from 'react'
import PopupModal from '../../components/widgets/popup-modal'
import AddCertificationEventViewModel from '../../view-models/actions-view-models/add-certification-event-view-model';
import AddEditCertificationEventForm from '../../components/widgets/add-edit-certification-event-form';
import { StudentData } from '../../../models/student/student-data';
import { StudentCertificateRecordData } from '../../../models/certifications/student-certificate-record-data';
import { CertificationData } from '../../../models/certifications/certification-data';
import { CertificationEventData } from '../../../models/certifications/certification-event-data';

interface AddCertificationEventProps {
    isOpen: boolean;
    handleClose: () => void;
    activeAcademySelected: number;
    activeDisciplineSelected: number;
    allStudentsOFSelectedAcademyAndDiscipline: StudentData[];
    allStudentCertificationRecordsOfDiscipline: StudentCertificateRecordData[];
    certificationsList: CertificationData[];
    isEditing: boolean;
    editingEventData: CertificationEventData | null;
}

const AddCertificationEvent = ({
    isOpen,
    handleClose,
    activeAcademySelected,
    activeDisciplineSelected,
    allStudentsOFSelectedAcademyAndDiscipline,
    allStudentCertificationRecordsOfDiscipline,
    certificationsList,
    isEditing,
    editingEventData,
}: AddCertificationEventProps) => {

    const {
        isLoading,
        handleModalClose,
        handleInputChange,
        certificationEventFormData,
        handleAddInstructor,
        handleAddChiefExaminer,
        handleRemoveInstructor,
        handleRemoveChiefExaminer,
        handleRemoveExternalExaminer,
        handleAddExternalExaminer,
        handleFormSubmit,
        expandedSection,
        setExpandedSection,
        serchTerm,
        setSearchTerm,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        handleRemoveStudent,
        handleAddStudent,
        expandedStudentCard,
        handleExpandStudentCard,
        handleStudentPromotionLevelChange,
    } = AddCertificationEventViewModel({
        handleClose,
        activeAcademySelected,
        activeDisciplineSelected,
        allStudentCertificationRecordsOfDiscipline,
        isEditing,
        editingEventData,
        isOpen,
        certificationsList,
    });

    return (
        <PopupModal
            title='Add Certification Event'
            isOpen={isOpen}
            handleModalClose={handleModalClose}
            background='white'
        >
            <AddEditCertificationEventForm
                handleInputChange={handleInputChange}
                certificationEventFormData={certificationEventFormData}
                handleAddInstructor={handleAddInstructor}
                handleAddChiefExaminer={handleAddChiefExaminer}
                handleAddExternalExaminer={handleAddExternalExaminer}
                handleRemoveInstructor={handleRemoveInstructor}
                handleRemoveChiefExaminer={handleRemoveChiefExaminer}
                handleRemoveExternalExaminer={handleRemoveExternalExaminer}
                handleFormSubmit={handleFormSubmit}
                expandedSection={expandedSection}
                setExpandedSection={setExpandedSection}
                allStudentsOFSelectedAcademyAndDiscipline={allStudentsOFSelectedAcademyAndDiscipline}
                serchTerm={serchTerm}
                setSearchTerm={setSearchTerm}
                isSearchInputOnFocus={isSearchInputOnFocus}
                setIsSearchInputOnFocus={setIsSearchInputOnFocus}
                handleAddStudent={handleAddStudent}
                handleRemoveStudent={handleRemoveStudent}
                expandedStudentCard={expandedStudentCard}
                handleExpandStudentCard={handleExpandStudentCard}
                allStudentCertificationRecordsOfDiscipline={allStudentCertificationRecordsOfDiscipline}
                certificationsList={certificationsList}
                handleStudentPromotionLevelChange={handleStudentPromotionLevelChange}
                isEditing={isEditing}
            />
        </PopupModal>
    )
}

export default AddCertificationEvent