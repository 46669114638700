import React from 'react';
import { ClassData } from '../../../../models/class/class-data';
import style from './style.module.css';
import {
    formatTime,
    getCurrentDayString,
    getFormattedWeekDay,
} from '../../../../utils/helpers/helpers';
import horizontalEllipses from '../../../../assets/icons/horizontal-ellipsis.png';
import { AttendanceAttributesWraper } from '../../../../models/attendance/attendance-attributes-wrapper';

type Props = {
    data: ClassData;
    onViewClicked: (id: number, date: string, startTime: string, endTime: string) => void;
    attendanceListCount: AttendanceAttributesWraper[] | undefined;
    isCalendarMode: boolean;
    filterStudentsForDay?: boolean;
    currentDate: Date;
};

const ScheduleListItem = ({
    data,
    onViewClicked,
    attendanceListCount,
    isCalendarMode,
    filterStudentsForDay,
    currentDate,
}: Props) => {
    const allStudentOfThisClass = data.attributes.students.data;
    const allStudentsOfClassForThisDay = allStudentOfThisClass?.filter(item => {
        const sessions = item?.attributes?.sessions ?? [];
        if (sessions.length === 0) {
            return true;
        }
        return sessions.some(session => {
            const classIdMatch = session?.class?.data?.id === data?.id;
            if (classIdMatch) {
                const sessionTimings = session.sessionTimings ?? [];
                if (sessionTimings.length === 0) {
                    return true;
                }
                return sessionTimings.some(t => t.day === getCurrentDayString(currentDate) && t.start_time === data?.attributes?.class_timing?.start_time && t.end_time === data?.attributes?.class_timing?.end_time);
            }
            return false;
        });
    });
    const allAttendanceMarkedForThisClass = attendanceListCount?.filter(
        item => item.attributes.class.data.id === data.id
    );
    const studentsNotBelongToThisClass =
        allAttendanceMarkedForThisClass?.filter(
            item =>
                !allStudentsOfClassForThisDay
                    .map(student => student.id)
                    .includes(item.attributes.student.data.id)
        ) ?? [];

    return (
        <div className={style.container}>
            <input
                className={style.checkBox}
                type="checkbox"
            />
            <div className={style.letterContainer}>C</div>
            <div className={style.dateContainer}>
                {getFormattedWeekDay(data?.attributes?.class_day ?? '')}
            </div>
            <div className={style.timeContainer}>
                {formatTime(data?.attributes?.class_timing?.start_time ?? '')} -{' '}
                {formatTime(data?.attributes?.class_timing?.end_time ?? '')}
            </div>
            <div className={style.classNameContainer}>{data?.attributes?.class_name ?? ''}</div>
            {new Date(data?.attributes?.class_day ?? '') > new Date() && !isCalendarMode ? (
                <div className={style.hiddenConent}>
                    <div className={style.markedCount}>
                        {attendanceListCount?.filter(
                            item => item.attributes.class.data.id === data.id
                        ).length ?? 0}
                    </div>
                    <div className={style.unmarkedCount}>
                        {data?.attributes?.students?.data?.length -
                            (attendanceListCount?.filter(
                                item => item.attributes.class.data.id === data.id
                            ).length ?? 0)}
                    </div>
                </div>
            ) : (
                <div className={style.markedUnmarkedCountsContainer}>
                    <div className={style.markedPresentCount}>
                        {allAttendanceMarkedForThisClass?.filter(
                            item => item.attributes.present === true
                        ).length ?? 0}
                    </div>
                    <div className={style.unmarkedCount}>
                        {filterStudentsForDay
                            ? allStudentsOfClassForThisDay?.length +
                              studentsNotBelongToThisClass?.length -
                              (allAttendanceMarkedForThisClass?.length ?? 0)
                            : allStudentOfThisClass?.length +
                              studentsNotBelongToThisClass?.length -
                              (allAttendanceMarkedForThisClass?.length ?? 0)}
                    </div>
                </div>
            )}
            <div className={style.studentContainer}>
                {allStudentsOfClassForThisDay?.length === 0
                    ? 'No student registered'
                    : filterStudentsForDay
                      ? allStudentsOfClassForThisDay?.length === 1
                          ? allStudentsOfClassForThisDay[0]?.attributes?.firstName
                          : `${allStudentsOfClassForThisDay[0]?.attributes?.firstName} and ${allStudentsOfClassForThisDay?.length + studentsNotBelongToThisClass?.length - 1 + ' Others'}`
                      : allStudentOfThisClass?.length === 1
                        ? allStudentOfThisClass[0]?.attributes?.firstName
                        : `${allStudentOfThisClass[0]?.attributes?.firstName} and ${allStudentOfThisClass?.length + studentsNotBelongToThisClass?.length - 1 + ' Others'}`}
            </div>
            {new Date(data?.attributes?.class_day ?? '') > new Date() && !isCalendarMode ? (
                <div className={style.hiddenConent}>
                    <div className={style.viewText}>View</div>
                    <div className={style.optionsIconContainer}>
                        <img
                            className={style.optionsIcon}
                            src={horizontalEllipses}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div
                        onClick={onViewClicked.bind(
                            this,
                            data?.id,
                            data?.attributes?.class_day ?? '',
                            data?.attributes?.class_timing?.start_time ?? '',
                            data?.attributes?.class_timing?.end_time ?? ''
                        )}
                        className={style.viewText}
                    >
                        View
                    </div>
                    <div
                        onClick={onViewClicked.bind(
                            this,
                            data?.id,
                            data?.attributes?.class_day ?? '',
                            data?.attributes?.class_timing?.start_time ?? '',
                            data?.attributes?.class_timing?.end_time ?? ''
                        )}
                        className={style.optionsIconContainer}
                    >
                        <img
                            className={style.optionsIcon}
                            src={horizontalEllipses}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default ScheduleListItem;
