import { CoachData } from '../models/coach/coach-data';
import { CoachListWrapper } from '../models/coach/coach-list-wrapper';
import { CoachPostDataWrapper } from '../models/coach/coach-post-data-wrapper';
import { UserDetailsData } from '../models/user-details/user-details-data';
import { UserDetailsListWrapper } from '../models/user-details/user-details-list-wrapper';
import { UserDetailsPostDataWrapper } from '../models/user-details/user-details-post-data-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class UserDetailsService {
    private static _instance: UserDetailsService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new UserDetailsService());
    }

    public getUserDetailsList = async (
        supplierId: number
    ): Promise<ApiResponse<UserDetailsListWrapper>> => {
        return invokeApi<null, UserDetailsListWrapper>({
            route: `user-details?populate=*&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getUserDetails = async (
        userId: number
    ): Promise<ApiResponse<UserDetailsListWrapper>> => {
        return invokeApi<null, UserDetailsListWrapper>({
            route: `user-details?populate=*&filters[userInfo][id][$eq]=${userId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewUserDetails = async (data: UserDetailsPostDataWrapper) => {
        return invokeApi<UserDetailsPostDataWrapper, UserDetailsData>({
            route: `user-details`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public updateUserDetails = async (data: UserDetailsPostDataWrapper, id: number) => {
        return invokeApi<UserDetailsPostDataWrapper, UserDetailsData>({
            route: `user-details/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };
}
