import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    SUPPLIER_PAYMENT_DETAILS_QUERY,
    SUPPLIER_QUERY,
    USER_DETAILS_LIST_QUERY,
} from '../../utils/constants/constants';
import { UserDetailsService } from '../../services/user-details-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { SettingsService } from '../../services/settings-services';
import { ReportSettingsPostData } from '../../models/settings/report-settings-post-data';
import { SupplierDataWrapper } from '../../models/supplier/supplier-data-wrapper';
import { NotificationSettingsPostData } from '../../models/settings/notification-setting-post-data';
import { DetailSettingsPostData } from '../../models/settings/detail-setting-post-data';
import { DetailEmailPostData } from '../../models/settings/detail-setting-email-data';
import { UserPasswordPostData } from '../../models/user/user-password-post-data';
import { setJWTToken } from '../../utils/helpers/helpers';
import { AuthResponse } from '../../models/user/auth-response';
import HeaderMenuViewModel from './header-menu-view-model';
import { Url } from 'url';
import { UploadService } from '../../services/upload-service';
import { DetailImagePostData } from '../../models/settings/detail-setting-image-data';
import { useNavigate } from 'react-router-dom';

export type SettingTab =
    | 'My Details'
    | 'Password'
    | 'Team'
    | 'Billing'
    | 'Student Notification'
    | 'Report'
    | 'Payments';

export interface ReportFormDataType {
    feeReports: string;
    scheduleReports: string;
    feeReportFrequency: string;
    scheduleReportFrequency: string;
}

export interface NotificationFormDataType {
    studentNotifications: string;
    studentNotificationMedium: string | null;
    feeNotifications: string;
    feeNotificationMedium: string | null;
    enquiryNotifications: string;
    enquiryNotificationMedium: string | null;
    scheduleNotifications: string;
    scheduleNotificationMedium: string | null;
    pendingFeeNotificationFrequency: string;
    dueFeeNotificationFrequency: string;
    enquiryFollowUpNotificationFrequency: string;
}

export interface DetailsFormDataType {
    firstName: string;
    lastName: string;
    email: string;
    photo: string;
    VPA: string;
}

export interface PasswordFormDataType {
    currentPassword: string;
    newPassword: string;
}

const SettingsViewModel = () => {
    const initialReportFormData = {
        feeReports: '',
        scheduleReports: '',
        feeReportFrequency: '',
        scheduleReportFrequency: '',
    };

    const initialNotificationFormData = {
        studentNotifications: '',
        studentNotificationMedium: null,
        feeNotifications: '',
        feeNotificationMedium: null,
        enquiryNotifications: '',
        enquiryNotificationMedium: null,
        scheduleNotifications: '',
        scheduleNotificationMedium: null,
        pendingFeeNotificationFrequency: '',
        dueFeeNotificationFrequency: '',
        enquiryFollowUpNotificationFrequency: '',
    };

    const initialDetailFormData = {
        firstName: '',
        lastName: '',
        email: '',
        photo: '',
        VPA: '',
    };

    const initialPasswordFormData = {
        currentPassword: '',
        newPassword: '',
    };

    const { supplierData } = HeaderMenuViewModel();

    const [activeTab, setActiveTab] = useState<SettingTab>('My Details');
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const [reportFormData, setReportFormData] = useState<ReportFormDataType>(initialReportFormData);
    const [notificationsFormData, setNotificationsFormData] = useState<NotificationFormDataType>(
        initialNotificationFormData
    );
    const [detailFormData, setDetailFormData] =
        useState<DetailsFormDataType>(initialDetailFormData);
    const [passwordFormData, setPasswordFormData] =
        useState<PasswordFormDataType>(initialPasswordFormData);

    const [prevData, setPrevData] = useState<DetailsFormDataType>(initialDetailFormData);

    const [disabled, setDisabled] = useState(true);

    const [profileImageInputKey, setProfileImageInputKey] = useState<number>(0);
    const [imageid, setImageId] = useState<number>();
    const navigate = useNavigate();
    const [isValidVPA, setIsValidVPA] = useState(true);

    useEffect(() => {
        setNotificationsFormData({
            studentNotifications:
                userState?.supplierDetails?.attributes?.studentNotifications ?? '',
            studentNotificationMedium:
                userState?.supplierDetails?.attributes?.studentNotificationMedium ?? null,
            feeNotifications: userState?.supplierDetails?.attributes?.feeNotifications ?? '',
            feeNotificationMedium:
                userState?.supplierDetails?.attributes?.feeNotificationMedium ?? null,
            enquiryNotifications:
                userState?.supplierDetails?.attributes?.enquiryNotifications ?? '',
            enquiryNotificationMedium:
                userState?.supplierDetails?.attributes?.enquiryNotificationMedium ?? null,
            scheduleNotifications:
                userState?.supplierDetails?.attributes?.scheduleNotifications ?? '',
            scheduleNotificationMedium:
                userState?.supplierDetails?.attributes?.scheduleNotificationMedium ?? null,
            pendingFeeNotificationFrequency:
                userState?.supplierDetails?.attributes?.pendingFeeNotificationFrequency ?? '',
            dueFeeNotificationFrequency:
                userState?.supplierDetails?.attributes?.dueFeeNotificationFrequency ?? '',
            enquiryFollowUpNotificationFrequency:
                userState?.supplierDetails?.attributes?.enquiryFollowUpNotificationFrequency ?? '',
        });
        setReportFormData({
            feeReports: userState?.supplierDetails?.attributes?.feeReports ?? '',
            scheduleReports: userState?.supplierDetails?.attributes?.scheduleReports ?? '',
            feeReportFrequency: userState?.supplierDetails?.attributes?.feeReportFrequency ?? '',
            scheduleReportFrequency:
                userState?.supplierDetails?.attributes?.scheduleReportFrequency ?? '',
        });
        setDetailFormData({
            firstName: userState?.supplierDetails?.attributes?.firstName ?? '',
            lastName: userState?.supplierDetails?.attributes?.lastName ?? '',
            email: userState?.supplierDetails?.attributes?.userInfo?.data?.attributes?.email ?? '',
            photo: userState?.supplierDetails?.attributes?.photo?.data?.attributes?.url ?? '',
            VPA: userState?.supplierDetails?.attributes?.VPA ?? '',
        });
        setPrevData({
            firstName: userState?.supplierDetails?.attributes?.firstName ?? '',
            lastName: userState?.supplierDetails?.attributes?.lastName ?? '',
            email: userState?.supplierDetails?.attributes?.userInfo?.data?.attributes?.email ?? '',
            photo: userState?.supplierDetails?.attributes?.photo?.data?.attributes?.url ?? '',
            VPA: userState?.supplierDetails?.attributes?.VPA ?? '',
        });
    }, [userState.supplierDetails]);

    useEffect(() => {
        if (
            notificationsFormData.scheduleNotificationMedium?.split(',').includes('Both') ||
            (notificationsFormData.scheduleNotificationMedium?.split(',').includes('Whatsapp') &&
                notificationsFormData.scheduleNotificationMedium?.split(',').includes('Email'))
        ) {
            setNotificationsFormData(prev => ({
                ...prev,
                scheduleNotificationMedium: 'Both',
            }));
        }
        if (notificationsFormData.scheduleNotificationMedium === '') {
            setNotificationsFormData(prev => ({
                ...prev,
                scheduleNotificationMedium: null,
            }));
        }
    }, [notificationsFormData.scheduleNotificationMedium]);

    useEffect(() => {
        if (
            notificationsFormData.feeNotificationMedium?.split(',').includes('Both') ||
            (notificationsFormData.feeNotificationMedium?.split(',').includes('Whatsapp') &&
                notificationsFormData.feeNotificationMedium?.split(',').includes('Email'))
        ) {
            setNotificationsFormData(prev => ({
                ...prev,
                feeNotificationMedium: 'Both',
            }));
        }
        if (notificationsFormData.feeNotificationMedium === '') {
            setNotificationsFormData(prev => ({
                ...prev,
                feeNotificationMedium: null,
            }));
        }
    }, [notificationsFormData.feeNotificationMedium]);

    useEffect(() => {
        if (
            notificationsFormData.studentNotificationMedium?.split(',').includes('Both') ||
            (notificationsFormData.studentNotificationMedium?.split(',').includes('Whatsapp') &&
                notificationsFormData.studentNotificationMedium?.split(',').includes('Email'))
        ) {
            setNotificationsFormData(prev => ({
                ...prev,
                studentNotificationMedium: 'Both',
            }));
        }
        if (notificationsFormData.studentNotificationMedium === '') {
            setNotificationsFormData(prev => ({
                ...prev,
                studentNotificationMedium: null,
            }));
        }
    }, [notificationsFormData.studentNotificationMedium]);

    useEffect(() => {
        if (
            notificationsFormData.enquiryNotificationMedium?.split(',').includes('Both') ||
            (notificationsFormData.enquiryNotificationMedium?.split(',').includes('Whatsapp') &&
                notificationsFormData.enquiryNotificationMedium?.split(',').includes('Email'))
        ) {
            setNotificationsFormData(prev => ({
                ...prev,
                enquiryNotificationMedium: 'Both',
            }));
        }
        if (notificationsFormData.enquiryNotificationMedium === '') {
            setNotificationsFormData(prev => ({
                ...prev,
                enquiryNotificationMedium: null,
            }));
        }
    }, [notificationsFormData.enquiryNotificationMedium]);

    const {
        isLoading: isSupplierPaymentDetailsFetching,
        isSuccess: isSupplierPaymentDetailsFetchSuccess,
        data: supplierPaymentDetails,
    } = useQuery({
        queryKey: [SUPPLIER_PAYMENT_DETAILS_QUERY],
        queryFn: () =>
            SettingsService.instance.getSupplierPaymentDetails(userState?.supplierDetails?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.supplierDetails?.id ?? 0) > 0,
    });

    const changeTab = (tab: SettingTab) => {
        setActiveTab(tab);
    };

    const {
        isSuccess: isUserDetailsDataFetchSuccess,
        isLoading: isUserDetailsDataLoading,
        data: UserDetailsData,
    } = useQuery({
        queryKey: [USER_DETAILS_LIST_QUERY],
        queryFn: UserDetailsService.instance.getUserDetailsList.bind(
            this,
            userState?.user?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    // const handleOnChangeImage = (file: Url) => {
    //     setDetailImageData(file);
    // }

    const handleDetailsInputChange = (field: string, value: string) => {
        if (field === 'VPA') {
            // VPA validation regex: username@bank, both parts can have alphanumeric characters and periods
            const vpaRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+$/;
            const isValid = vpaRegex.test(value);
            setIsValidVPA(isValid);
        }
        setDetailFormData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    useEffect(() => {
        if (
            prevData.firstName === detailFormData.firstName &&
            prevData.lastName === detailFormData.lastName &&
            prevData.email === detailFormData.email &&
            prevData.photo === detailFormData.photo &&
            (prevData.VPA === detailFormData.VPA || !isValidVPA)
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [detailFormData]);

    const handleReportsInputChange = (field: string, value: string) => {
        setReportFormData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleNotificationsInputChange = (field: string, value: string) => {
        setNotificationsFormData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const updateReportSettings = async (
        data: ReportSettingsPostData
    ): Promise<SupplierDataWrapper | undefined> => {
        const response = await SettingsService.instance.postSupplierReportSettings(
            userState.supplierDetails.id,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On update Report settings Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: changeReportSettings,
        isLoading: isReportSettingsUpdating,
        isSuccess: isReportSettingsUpdated,
    } = useMutation(updateReportSettings, {
        onSuccess: data => {
            queryClient.invalidateQueries(SUPPLIER_QUERY);
            alert('Updated Successfully!');
        },
        onError: error => {
            // console.log(`On Report settings update Error:`, error);
            alert('Failed to update settings! Please try again');
            // Error actions
        },
    });

    const updateNotificationSettings = async (
        data: NotificationSettingsPostData
    ): Promise<SupplierDataWrapper | undefined> => {
        const response = await SettingsService.instance.postSupplierNotificationSettings(
            userState.supplierDetails.id,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On update Report settings Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: changeNotificationSettings,
        isLoading: isNotificationSettingsUpdating,
        isSuccess: isNotificationSettingsUpdated,
    } = useMutation(updateNotificationSettings, {
        onSuccess: data => {
            queryClient.invalidateQueries(SUPPLIER_QUERY);
            alert('Updated Successfully!');
        },
        onError: error => {
            // console.log(`On Report settings update Error:`, error);
            alert('Failed to update settings! Please try again');
            // Error actions
        },
    });

    const updateDetailSettings = async (
        data: DetailSettingsPostData
    ): Promise<SupplierDataWrapper | undefined> => {
        const response = await SettingsService.instance.postSupplierDetailSettings(
            userState.supplierDetails.id,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On update Detail Settings Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const updateEmailSettings = async (
        data: DetailEmailPostData
    ): Promise<SupplierDataWrapper | undefined> => {
        const response = await SettingsService.instance.postSupplierEmail(
            userState.supplierDetails.attributes.userInfo.data.id,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On update Detail Settings Error ${JSON.stringify(
            //         response.error,
            //     )}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: changeEmailSettings,
        // isLoading: ,
        // isSuccess: isDetailSettingUpdated,
    } = useMutation(updateEmailSettings, {
        onSuccess: data => {
            queryClient.invalidateQueries(SUPPLIER_QUERY);
            alert('Updated Successfully!');
        },
        onError: error => {
            // console.log(`On Detail settings update Error:`, error);
            alert('Failed to update settings! Please try again');
        },
    });

    const {
        mutate: changeDetailSettings,
        isLoading: isDetailSettingsUpdating,
        isSuccess: isDetailSettingUpdated,
    } = useMutation(updateDetailSettings, {
        onSuccess: data => {
            queryClient.invalidateQueries(SUPPLIER_QUERY);
            changeEmailSettings({ email: detailFormData.email });
        },

        onError: error => {
            // console.log(`On Detail settings update Error:`, error);
            alert('Failed to update settings! Please try again');
        },
    });

    const handleSubmitReportSettings = () => {
        changeReportSettings(reportFormData);
    };

    const handleSubmitNotificationSettings = () => {
        changeNotificationSettings(notificationsFormData);
    };

    const handleSubmitDetailSettings = (event: React.FormEvent) => {
        event.preventDefault();
        const confirmation = window.confirm('Are you sure you want to make the changes?');
        if (confirmation) {
            changeDetailSettings({
                firstName: detailFormData.firstName,
                lastName: detailFormData.lastName,
                photo: imageid,
                VPA: detailFormData.VPA,
            });
        } else {
            handleFormReset;
        }
    };

    const handleFormReset = () => {
        setDetailFormData({
            firstName: prevData.firstName,
            lastName: prevData.lastName,
            email: prevData.email,
            photo: prevData.photo,
            VPA: prevData.VPA,
        });
    };

    const resetPasswordFields = () => {
        setPasswordFormData(initialPasswordFormData);
    };

    const updateUserPassword = async (
        data: UserPasswordPostData
    ): Promise<AuthResponse | undefined> => {
        const response = await SettingsService.instance.postUserPassword(data);
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On update Password Error ${JSON.stringify(
            //         response.error,
            //     )}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: changePassword,
        // isLoading: ,
        // isSuccess: isDetailSettingUpdated,
    } = useMutation(updateUserPassword, {
        onSuccess: data => {
            setJWTToken(data?.jwt ?? '');
            resetPasswordFields();
            alert('Updated Password Successfully!');
        },
        onError: error => {
            // console.log(`On Detail settings update Error:`, error);
            alert('Failed to update password! Please try again');
        },
    });

    const handlePasswordInputChange = (field: string, value: string) => {
        setPasswordFormData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handlePasswordChangeSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const reqBody = {
            password: passwordFormData.newPassword,
            currentPassword: passwordFormData.currentPassword,
            passwordConfirmation: passwordFormData.newPassword,
        };
        changePassword(reqBody);
    };

    const PostSupplierImage = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response?.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setDetailFormData(prevFormData => ({
                                ...prevFormData,
                                photo: item?.url,
                            }));
                        }
                        if (item.id) {
                            setImageId(item?.id);
                        }
                    });
            } else {
                // console.log(
                //     `On Post postSupplierImageUpload Error ${JSON.stringify(
                //         response.error,
                //     )}`,
                // );
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postSupplierImageUpload: ${error}`);
            throw error;
        }
    };

    const {
        mutate: UploadSupplierImage,
        isLoading: isProfileImagePosting,
        error: isAcademyImagePostingError,
    } = useMutation(PostSupplierImage, {
        onSuccess: data => {
            console.log('image upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const handleSupplierProfileImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 200) {
                    alert('Image size exceeds 200KB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadSupplierImage(data);
            setProfileImageInputKey(prev => prev + 1);
        }
    };

    useEffect(() => {
        if (detailFormData.VPA === '') {
            setIsValidVPA(true);
        }
    }, [detailFormData.VPA]);

    const handleEditPayemtDetailsRequest = () => {
        const recipient = 'help@sparts.com';
        const subject = 'Request to change merchant payment details';
        const body = 'Body of the email goes here.\njust a placeholder';

        window.location.href = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    };
    const emailSubject = `Request to change merchant payment details`;
    const emailBody = `
        -------------------------------
        Supplier Details:-
        -------------------------------
        Name: ${userState?.supplierDetails?.attributes?.firstName} ${userState?.supplierDetails?.attributes?.lastName ?? ''}
        Email: ${userState?.supplierDetails?.attributes?.userInfo?.data?.attributes?.email}


        -------------------------------
        Merchant Payment Details:-
        -------------------------------
        Business Name: ${supplierPaymentDetails?.data?.data?.attributes?.cashfreeCredentials?.merchantName ?? ''}
        Email: ${supplierPaymentDetails?.data?.data?.attributes?.cashfreeCredentials?.email ?? ''}
        Phone Number: ${supplierPaymentDetails?.data?.data?.attributes?.cashfreeCredentials?.phone ?? ''}
    `;

    return {
        activeTab,
        changeTab,
        usersData: UserDetailsData?.data?.data,
        userState,
        reportFormData,
        detailFormData,
        prevData,
        disabled,
        handleReportsInputChange,
        handleDetailsInputChange,
        // handleOnChangeImage,
        // detailImageData,
        isProfileImagePosting,
        handleSubmitReportSettings,
        handleSubmitNotificationSettings,
        handleSubmitDetailSettings,
        handleNotificationsInputChange,
        handleFormReset,
        resetPasswordFields,
        notificationsFormData,
        handlePasswordInputChange,
        handleSupplierProfileImgChange,
        passwordFormData,
        handlePasswordChangeSubmit,
        navigate,
        isValidVPA,
        emailSubject,
        emailBody,
        supplierPaymentDetails: supplierPaymentDetails?.data?.data?.attributes?.cashfreeCredentials,
    };
};

export default SettingsViewModel;
