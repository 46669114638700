import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useImmerReducer } from 'use-immer';
import { RootState } from '../../utils/redux/store';
import { setActiveMeetingData, setMeetingView } from '../../utils/redux/virtual-meeting-slice';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { VirtualMeetingPostData } from '../../models/virtual-meeting/virtual-meeting-post-data';
import { VirtualMeetingDataWrapper } from '../../models/virtual-meeting/virtual-meeting-data-wrapper';
import { VirtualMeetingService } from '../../services/virtual-meeting-service';
import { ACTIVE_VIRTUAL_MEETING_OF_USER, CLASS_DETAILS_QUERY, STUDENT_DATA_QUERY } from '../../utils/constants/constants';
import { set } from 'lodash';
import { StudentData } from '../../models/student/student-data';
import { ClassData } from '../../models/class/class-data';
import ClassService from '../../services/class-service';
import { AnnouncementService } from '../../services/announcement-service';

/**
 * A React functional component that serves as a view model for the VirtualClass component.
 * This function is responsible for managing the state and behavior of the virtual class view.
 * It returns an object that can be used to handle the logic associated with the virtual class.
 */
const VirtualClassViewModel = () => {
    const meetingState = useSelector((state: RootState) => state.virtualMeeting);
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const [instantMeetingFormData, setInstantMeetingFormData] = React.useState<{
        meetingName: string;
        durationInMinutes: number;
        participantsType: string;
        students: number[];
        classes: number[];
    }>({
        meetingName: '',
        durationInMinutes: 30,
        participantsType: 'CLASS',
        students: [],
        classes: [],
    })

    useEffect(() => {
        setInstantMeetingFormData(prevFormData => ({
            ...prevFormData,
            students: [],
            classes: [],
        }))
    },[instantMeetingFormData.participantsType])

    const {
        isLoading: isActiveVirtualMeetingOfUserFetching,
        data: activeVirtualMeetingOfUser,
        isSuccess: isActiveVirtualMeetingOfUserSuccess,
    } = useQuery({
        queryKey: [ACTIVE_VIRTUAL_MEETING_OF_USER],
        queryFn: () => VirtualMeetingService.instance.getActiveMeetingOfUser(userState?.user?.id ?? 0),
        refetchOnWindowFocus: true,
        enabled: (userState?.user?.id ?? 0) > 0,
        refetchInterval: 30000,
    });

    useEffect(() => {
        if(isActiveVirtualMeetingOfUserSuccess && activeVirtualMeetingOfUser?.data?.data?.length === 0) {
            dispatch(setActiveMeetingData(null));
        }
    }, [activeVirtualMeetingOfUser])

    const {isLoading: isClassesFetching, data: classesData } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {isLoading: isStudentListLoading, data: studentData } = useQuery({
        queryKey: [STUDENT_DATA_QUERY],
        queryFn: AnnouncementService.instance.getStudentList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const handleInstantMeetingFormChange = (field: string, value: string | number | number[]) => {
        setInstantMeetingFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    }

    const postInstantMeeting = async (
        data: VirtualMeetingPostData
    ): Promise<VirtualMeetingDataWrapper | undefined> => {
        const response = await VirtualMeetingService.instance.postNewMeeting(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: createInstantMeeting,
        isLoading: isInstantMeetingPosting,
    } = useMutation(postInstantMeeting, {
        onSuccess: data => {
            dispatch(setActiveMeetingData(data ?? null));
            dispatch(setMeetingView(true));
            queryClient.invalidateQueries(ACTIVE_VIRTUAL_MEETING_OF_USER);
        },
        onError: error => {
            alert('Failed to start meeting! Please try again');
        },
    });

    const handleStartInstantMeeting = () => {
        createInstantMeeting({
            meetingName: `${instantMeetingFormData.meetingName}-${new Date().toISOString()}`,
            startTime: new Date().toISOString(),
            endTime: new Date(new Date().getTime() + instantMeetingFormData.durationInMinutes * 60000).toISOString(),
            type: 'INSTANT',
            durationInMinutes: instantMeetingFormData.durationInMinutes,
            forClasses: instantMeetingFormData.participantsType === 'CLASS' ? instantMeetingFormData.classes : [],
            forStudents: instantMeetingFormData.participantsType === 'STUDENT' ? instantMeetingFormData.students : [],
            hosts: [userState?.user?.id ?? 0],
        });
    }

    function getMeetingProgress(startTime: string, endTime: string): number {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const now = new Date(); // Get the current time
    
        if (now < start) {
            return 0; // 0% progress
        }
    
        if (now > end) {
            return 100; // 100% progress
        }
    
        const totalDuration = end.getTime() - start.getTime();
        const timeElapsed = now.getTime() - start.getTime();
        const progress = (timeElapsed / totalDuration) * 100;
    
        return Math.min(Math.max(progress, 0), 100);
    }

    function getTimeLeftForMeeting(endTime: string) {
        const end = new Date(endTime).getTime();
        const now = new Date().getTime();
        const timeLeft = end - now;
        
        if (timeLeft <= 0) {
            return 'Meeting has ended';
        }
    
        // Convert timeLeft from milliseconds to minutes and hours
        const minutesLeft = Math.floor(timeLeft / 1000 / 60);
        const hoursLeft = Math.floor(minutesLeft / 60);
        const remainingMinutes = minutesLeft % 60;
    
        if (hoursLeft > 0) {
            return `${hoursLeft}h ${remainingMinutes}m remaining`;
        } else {
            return `${remainingMinutes}m remaining`;
        }
    }
    
    const handleJoinOnGoingMeeting = () => {
        dispatch(setMeetingView(true));
    }

    const mapStudentsToOptions = (): { value: number; label: string }[] | [] => {
        if (studentData?.data?.data) {
            return studentData?.data?.data?.map((item: StudentData) => ({
                value: item.id,
                label: `${item?.attributes?.firstName} ${item?.attributes?.middleName ?? ''} ${item?.attributes?.lastName ?? ''}`
            }));
        }
        return [];
    };

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data) {
            return classesData?.data?.data?.filter(item=>item.attributes.status !== "INACTIVE")
            .map((item: ClassData) => ({
                value: item.id,
                label: item?.attributes?.class_name,
            }));
        }
        return [];
    };
    
    return {
        isLoading: isInstantMeetingPosting || isActiveVirtualMeetingOfUserFetching || isClassesFetching || isStudentListLoading,
        activeMeeting: (activeVirtualMeetingOfUser?.data?.data && activeVirtualMeetingOfUser?.data?.data.length > 0) ? activeVirtualMeetingOfUser?.data?.data[0] : null,
        meetingState,
        handleStartInstantMeeting,
        instantMeetingFormData,
        getMeetingProgress,
        getTimeLeftForMeeting,
        handleInstantMeetingFormChange,
        handleJoinOnGoingMeeting,
        mapStudentsToOptions,
        mapClassesToOptions,
    }
}

export default VirtualClassViewModel