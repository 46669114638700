import React, { ChangeEvent, useState } from 'react';
import style from './style.module.css';
import Loader from '../../bits/loader';
import Select from 'react-select';
import {
    AcademiesDisciplinesItem,
    coachFormTypes,
} from '../../../view-models/user-management-view-model';
import { CoachData } from '../../../../models/coach/coach-data';
import { arraysEqual } from '../../../../utils/helpers/helpers';
import DashboardIcon from '../../../../assets/icons/dashboard.png';
import AcademyIcon from '../../../../assets/icons/academy.png';
import StudentsIcon from '../../../../assets/icons/students.png';
import FeeIcon from '../../../../assets/icons/fee.png';
import EnquiriesIcon from '../../../../assets/icons/enquiries.png';
import ScheduleIcon from '../../../../assets/icons/schedule.png';
import UserIcon from '../../../../assets/icons/user.png';
import {
    ACADEMY_ACCESS_OPTIONS,
    CLASS_ACCESS_OPTIONS,
    DASHBOARD_ACCESS_OPTIONS,
    ENQUIRIES_ACCESS_OPTIONS,
    FEE_PAYMENT_ACCESS_OPTIONS,
    SCHEDULE_ACCESS_OPTIONS,
    STUDENT_ACCESS_OPTIONS,
    USER_MANAGEMENT_ACCESS_OPTIONS,
} from './data';

interface NewUserFormProps {
    isEditMode: boolean;
    profileImageInputKey?: number;
    coachDocumentInputKey: number;
    handleProfileImgChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleCoachDocumentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isCoachProfileImagePosting?: boolean;
    isCoachDocumentPosting: boolean;
    coachFormData: coachFormTypes;
    mapAcademiesToOptions: () => { value: number; label: string }[];
    handleInputChange: (property: string, value: string | number[]) => void;
    handleAddNewCoach: (e: React.FormEvent) => void;
    mapDiciplinesOfSelectedAcademiesToOptions: () => { value: number; label: string }[] | [];
    // mapClassesOfSelectedDisciplinesToOptions: () => {value: number; label: string}[] | [];
    coachEditingData?: CoachData;
    activeUserFormTab: number;
    switchTab: (tabNumber: number, isGoingBack: boolean) => void;
    checkedQuickAccess: number;
    handleQuickAccessCheck: (num: number) => void;
    mapClassesOfSelectedAcademiesToOptions: () => { value: number; label: string }[] | [];
    currentUserNames: string[];
    currentUserEmails: string[];
    allDisciplinesOptions: { value: number; label: string }[];
    academiesDisciplinesSelected: AcademiesDisciplinesItem[];
    handleDisciplineChange: (academyId: number, selectedDisciplines: number[]) => void;
}

const NewUserForm: React.FC<NewUserFormProps> = ({
    isEditMode,
    profileImageInputKey,
    handleProfileImgChange,
    isCoachProfileImagePosting,
    coachFormData,
    mapAcademiesToOptions,
    handleInputChange,
    coachDocumentInputKey,
    handleAddNewCoach,
    handleCoachDocumentChange,
    isCoachDocumentPosting,
    mapClassesOfSelectedAcademiesToOptions,
    mapDiciplinesOfSelectedAcademiesToOptions,
    // mapClassesOfSelectedDisciplinesToOptions,
    coachEditingData,
    activeUserFormTab,
    switchTab,
    checkedQuickAccess,
    handleQuickAccessCheck,
    currentUserNames,
    currentUserEmails,
    allDisciplinesOptions,
    academiesDisciplinesSelected,
    handleDisciplineChange,
}) => {
    return (
        <div className={style.formWrapper}>
            <div className={style.formTabRow}>
                <div
                    className={`${style.formTabBtn} ${activeUserFormTab === 1 ? style.activeUserFormTabBtn : ''}`}
                >
                    Primary Details
                </div>
                <div
                    className={`${style.formTabBtn} ${activeUserFormTab === 2 ? style.activeUserFormTabBtn : ''}`}
                >
                    Access Management
                </div>
            </div>
            <form onSubmit={handleAddNewCoach}>
                <div
                    className={`${style.tabOne} ${activeUserFormTab === 1 ? style.activeUserFormTab : ''}`}
                >
                    <div>
                        <div className={style.formInputLabel}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            Name:
                            <div className="relative group ml-2">
                                <div className="flex items-center justify-center h-4 w-4 text-[10px] rounded-full bg-gray-300 cursor-pointer ">
                                    i
                                </div>
                                <div className="hidden w-max group-hover:block absolute top-5 left-0 bg-white border border-gray-300 p-2 rounded shadow-md max-w-[50vw] whitespace-normal break-words">
                                    Use a unique username. Do not use generic terms like user,
                                    admin, coach etc
                                </div>
                            </div>
                        </div>
                        <input
                            required
                            // disabled={isEditMode}
                            className={`${style.formInput} ${currentUserNames.includes(coachFormData.name) ? 'border !border-red-400 focus:ring-red-400' : ''}`}
                            placeholder="Enter user fullname"
                            value={coachFormData.name}
                            onChange={e => handleInputChange('name', e.target.value)}
                        />
                        {currentUserNames.includes(coachFormData.name) && (
                            <div className="text-xs text-red-400 text-right pr-1">
                                Username is already taken!
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={style.formInputLabel}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            Contact number:
                        </div>
                        <input
                            required
                            // disabled={isEditMode}
                            className={style.formInput}
                            placeholder="Enter user contact number"
                            value={coachFormData.contact}
                            onChange={e => handleInputChange('contact', e.target.value)}
                        />
                    </div>
                    <div>
                        <div className={style.formInputLabel}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            Email ID:
                            <div className="relative group ml-2">
                                <div className="flex items-center justify-center h-4 w-4 text-[10px] rounded-full bg-gray-300 cursor-pointer ">
                                    i
                                </div>
                                <div className="hidden w-max group-hover:block absolute top-5 left-0 bg-white border border-gray-300 p-2 rounded shadow-md">
                                    Email Id must be unique
                                </div>
                            </div>
                        </div>
                        <input
                            required
                            // disabled={isEditMode}
                            className={`${style.formInput} ${currentUserEmails.includes(coachFormData.email) ? 'border !border-red-400 focus:ring-red-400' : ''}`}
                            placeholder="Enter user email id"
                            value={coachFormData.email}
                            onChange={e => handleInputChange('email', e.target.value)}
                        />
                        {currentUserEmails.includes(coachFormData.email) && (
                            <div className="text-xs text-red-400 text-right pr-1">
                                Email Id is not available!
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={style.formInputLabel}>
                            Profile photo:
                            <span className={style.imageMaxSizePrompt}>(max 200KB)</span>
                        </div>
                        <div className="flex gap-4 ">
                            <div className={style.formImageInputBtnWrapper}>
                                <label
                                    htmlFor="imageInput"
                                    className={style.formImageInputBtnLabel}
                                >
                                    <input
                                        key={profileImageInputKey}
                                        type="file"
                                        id="imageInput"
                                        className={style.formImageInput}
                                        onChange={handleProfileImgChange}
                                        accept="image/*"
                                        size={30720}
                                    />
                                    <div className={style.formImageInputLabel}>
                                        <div>+</div>
                                        <div>Upload</div>
                                    </div>
                                </label>
                            </div>
                            {coachFormData.photo && !isCoachProfileImagePosting ? (
                                <div className="h-[105px] w-[105px] min-h-[105px] min-w-[105px] border">
                                    <img
                                        src={coachFormData.photo}
                                        alt="profile image"
                                        className="h-full w-full object-contain"
                                    />
                                </div>
                            ) : (
                                <div className="min-h-[105px] h-[105px] w-[105px] text-center min-w-[105px] border flex justify-center items-center text-gray-500">
                                    {!isCoachProfileImagePosting ? 'No image uploaded' : <Loader />}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className={style.formInputLabel}>Government ID:</div>
                        <div className={style.documentUploadInputWrapper}>
                            <div className="flex-grow">
                                {isCoachDocumentPosting ? (
                                    'Uploading...'
                                ) : coachFormData.governmentId === '' ? (
                                    'Size of the document should not exceed 2 MB'
                                ) : (
                                    <a
                                        className="text-blue-600"
                                        target="blank"
                                        href={coachFormData.governmentId}
                                    >
                                        {coachFormData.governmentIdName}
                                    </a>
                                )}
                            </div>
                            <div className={style.formDocumentInputBtnWrapper}>
                                <label
                                    htmlFor="documentInput"
                                    className={style.formImageInputBtnLabel}
                                >
                                    <input
                                        type="file"
                                        key={coachDocumentInputKey}
                                        id="documentInput"
                                        // disabled={isEditMode}
                                        className={style.documentFormInput}
                                        accept="application/pdf"
                                        // value={formData.firstName}
                                        onChange={handleCoachDocumentChange}
                                    />
                                    <div className={style.formImageInputLabel}>Browse files</div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div className={style.formInputLabel}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            Discipline:
                        </div>
                        <Select 
                            isMulti
                            required
                            options={mapDiciplinesOfSelectedAcademiesToOptions()}
                            className={style.formInput}
                            classNamePrefix="Select Discipline"
                            onChange={selectedOptions => {
                                const selectedValues = selectedOptions.map(option => option.value);
                                handleInputChange('discipline', selectedValues.join(','));
                            }}
                            value={mapDiciplinesOfSelectedAcademiesToOptions().filter(option => coachFormData.discipline.split(',').includes(option.value))}
                        />
                    </div> */}
                    {/* <div>
                        <div className={style.formInputLabel}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            Class:
                        </div>
                        <Select 
                            isMulti
                            required
                            options={mapClassesOfSelectedDisciplinesToOptions()}
                            className={style.formInput}
                            classNamePrefix="Select Class"
                            onChange={(item) => handleInputChange('classes',item.map(({value}) => value))}
                            value={mapClassesOfSelectedDisciplinesToOptions().filter(option => coachFormData.classes.includes(option.value))}
                        />
                    </div> */}
                    <div>
                        <div className={style.formInputLabel}>Remarks:</div>
                        <input
                            // disabled={isEditMode}
                            className={style.formInput}
                            placeholder="Enter remarks if any"
                            value={coachFormData.remarks}
                            onChange={e => handleInputChange('remarks', e.target.value)}
                        />
                    </div>
                    <div className={style.submitBtnContainer}>
                        <div
                            className={style.formSubmitBtn}
                            onClick={() => switchTab(2, false)}
                        >
                            Next
                        </div>
                    </div>
                </div>
                <div
                    className={`${style.tabOne} ${activeUserFormTab === 2 ? style.activeUserFormTab : ''}`}
                >
                    <div className="w-full">
                        <div className="text-xl font-semibold">Quick create access</div>
                        <div className="inline-flex w-full justify-between text-xs">
                            <div className="inline-flex items-center gap-1">
                                <input
                                    className="h-3 w-3"
                                    type="radio"
                                    name="quickAccess"
                                    checked={checkedQuickAccess === 1}
                                    onChange={e => e.target.checked && handleQuickAccessCheck(1)}
                                />
                                Admin with full access
                                <div className="relative group">
                                    <div className="flex items-center justify-center h-3 w-3 text-[10px] rounded-full bg-gray-300 cursor-pointer">
                                        i
                                    </div>
                                    <div className="hidden w-max group-hover:block absolute top-4 left-0 bg-white border border-gray-300 p-2 rounded shadow-md">
                                        has access to everything
                                    </div>
                                </div>
                            </div>
                            <div className="inline-flex items-center gap-1">
                                <input
                                    className="h-3 w-3"
                                    type="radio"
                                    name="quickAccess"
                                    checked={checkedQuickAccess === 2}
                                    onChange={e => e.target.checked && handleQuickAccessCheck(2)}
                                />
                                Attendance only
                                <div className="relative group">
                                    <div className="flex items-center justify-center h-3 w-3 text-[10px] rounded-full bg-gray-300 cursor-pointer">
                                        i
                                    </div>
                                    <div className="hidden w-max group-hover:block absolute top-4 right-0 bg-white border border-gray-300 p-2 rounded shadow-md">
                                        will have access to mark attendance of choosen classes
                                    </div>
                                </div>
                            </div>
                            <div className="inline-flex items-center gap-1">
                                <input
                                    className="h-3 w-3"
                                    type="radio"
                                    name="quickAccess"
                                    checked={checkedQuickAccess === 3}
                                    onChange={e => e.target.checked && handleQuickAccessCheck(3)}
                                />
                                Fees only
                                <div className="relative group">
                                    <div className="flex items-center justify-center h-3 w-3 text-[10px] rounded-full bg-gray-300 cursor-pointer">
                                        i
                                    </div>
                                    <div className="hidden w-max group-hover:block absolute top-4 right-0 bg-white border border-gray-300 p-2 rounded shadow-md">
                                        will have access to collect fees
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-10 text-xl font-semibold w-full flex justify-center">
                            OR
                        </div>
                        <div className="text-xl font-semibold">Create custom access</div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="font-semibold inline-flex gap-4 items-center">
                                <img
                                    src={DashboardIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className="h-5 w-5"
                                    alt="dashboard icon"
                                />
                                Dashboard
                            </div>
                            <Select
                                className="sm:w-[60%]"
                                value={DASHBOARD_ACCESS_OPTIONS.filter(
                                    item => item.value === coachFormData.dashboardAccess
                                )}
                                options={DASHBOARD_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange('dashboardAccess', option?.value ?? '')
                                }
                            />
                        </div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="font-semibold inline-flex gap-4 items-center">
                                <img
                                    src={AcademyIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className="h-5 w-5"
                                    alt="academy icon"
                                />
                                Academy Profile
                            </div>
                            <Select
                                className="sm:w-[60%]"
                                value={ACADEMY_ACCESS_OPTIONS.filter(
                                    item => item.value === coachFormData.academyAccess
                                )}
                                options={ACADEMY_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange('academyAccess', option?.value ?? '')
                                }
                            />
                        </div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="inline-flex gap-4 items-center">
                                <img
                                    src={AcademyIcon}
                                    className="h-5 w-5"
                                    alt="academy icon"
                                />
                                Select Academies
                            </div>
                            <Select
                                isMulti
                                required={true}
                                closeMenuOnSelect={false}
                                isDisabled={coachFormData.academyAccess === 'noAccess'}
                                options={mapAcademiesToOptions()}
                                className={`sm:w-[60%] ${coachFormData.academies.length === 0 ? 'border border-red-300 rounded' : ''}`}
                                classNamePrefix="Select Academy"
                                onChange={item =>
                                    handleInputChange(
                                        'academies',
                                        item.map(({ value }) => value)
                                    )
                                }
                                value={mapAcademiesToOptions().filter(option =>
                                    coachFormData.academies.includes(option.value)
                                )}
                            />
                        </div>
                        {academiesDisciplinesSelected.filter(item => item.allDisciplines.length > 1)
                            .length > 0 && (
                            <div className="rounded border bg-slate-100 p-2 mb-2 ml-8">
                                <div className="text-sm text-right text-gray-600">
                                    Following academies are multidisciplinary, select a discipline.
                                </div>
                                <hr className="mb-2" />
                                {academiesDisciplinesSelected
                                    .filter(item => item.allDisciplines.length > 1)
                                    .map(item => (
                                        <div
                                            key={item.academyId}
                                            className="flex items-center justify-between gap-2 mb-1"
                                        >
                                            <div>
                                                {
                                                    mapAcademiesToOptions().find(
                                                        option => option.value === item.academyId
                                                    )?.label
                                                }
                                            </div>
                                            <Select
                                                isMulti
                                                required={true}
                                                className="w-[60%]"
                                                options={allDisciplinesOptions.filter(option =>
                                                    item.allDisciplines.includes(option.value)
                                                )}
                                                value={allDisciplinesOptions.filter(option =>
                                                    item.selectedDisciplines.includes(option.value)
                                                )}
                                                onChange={option =>
                                                    handleDisciplineChange(
                                                        item.academyId,
                                                        option.map(({ value }) => value)
                                                    )
                                                }
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="inline-flex gap-4 items-center">
                                <img
                                    src={AcademyIcon}
                                    className="h-5 w-5"
                                    alt="academy icon"
                                />
                                Classes & Sub groups
                            </div>
                            <Select
                                className="sm:w-[60%]"
                                value={CLASS_ACCESS_OPTIONS.filter(
                                    item => item.value === coachFormData.classesAccess
                                )}
                                options={CLASS_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange('classesAccess', option?.value ?? '')
                                }
                            />
                        </div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="inline-flex gap-4 items-center">
                                <img
                                    src={AcademyIcon}
                                    className="h-5 w-5"
                                    alt="academy icon"
                                />
                                Selected classes
                            </div>
                            <Select
                                isMulti
                                required={true}
                                isDisabled={true}
                                closeMenuOnSelect={false}
                                className={`sm:w-[60%] ${coachFormData.classes.length === 0 ? 'border border-red-300 rounded' : ''}`}
                                options={mapClassesOfSelectedAcademiesToOptions()}
                                onChange={item =>
                                    handleInputChange(
                                        'classes',
                                        item.map(({ value }) => value)
                                    )
                                }
                                value={mapClassesOfSelectedAcademiesToOptions().filter(option =>
                                    coachFormData.classes.includes(option.value)
                                )}
                                // value={mapClassesOfSelectedAcademiesToOptions()}
                            />
                        </div>
                        {/* <div className='sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0'>
                            <div className='font-semibold inline-flex gap-4 items-center'>
                                <img 
                                    src={UserIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className='h-5 w-5'
                                    alt='user icon'
                                />
                                User Management
                            </div>
                            <Select 
                                className='sm:w-[60%]'
                                value={USER_MANAGEMENT_ACCESS_OPTIONS.filter(item => item.value === coachFormData.userManagementAccess)}
                                options={USER_MANAGEMENT_ACCESS_OPTIONS}
                                onChange={option => handleInputChange('userManagementAccess', option?.value ?? '')}
                            />
                        </div> */}
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="font-semibold inline-flex gap-4 items-center">
                                <img
                                    src={StudentsIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className="h-5 w-5"
                                    alt="students icon"
                                />
                                Students
                            </div>
                            <Select
                                isMulti
                                className="sm:w-[60%]"
                                value={STUDENT_ACCESS_OPTIONS.filter(item =>
                                    coachFormData.studentsAccess.split(',').includes(item.value)
                                )}
                                options={STUDENT_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange(
                                        'studentsAccess',
                                        option.map(item => item.value).join(',') ?? ''
                                    )
                                }
                            />
                        </div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="font-semibold inline-flex gap-4 items-center">
                                <img
                                    src={FeeIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className="h-5 w-5"
                                    alt="fee icon"
                                />
                                Fee Payment
                            </div>
                            <Select
                                isMulti
                                className="sm:w-[60%]"
                                value={FEE_PAYMENT_ACCESS_OPTIONS.filter(item =>
                                    coachFormData.feePaymentAccess.split(',').includes(item.value)
                                )}
                                options={FEE_PAYMENT_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange(
                                        'feePaymentAccess',
                                        option.map(item => item.value).join(',') ?? ''
                                    )
                                }
                                menuPlacement="top"
                                isSearchable={false}
                            />
                        </div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="font-semibold inline-flex gap-4 items-center">
                                <img
                                    src={EnquiriesIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className="h-5 w-5"
                                    alt="enquiries icon"
                                />
                                Enquiries
                            </div>
                            <Select
                                isMulti
                                className="sm:w-[60%]"
                                value={ENQUIRIES_ACCESS_OPTIONS.filter(item =>
                                    coachFormData.enquiriesAccess.split(',').includes(item.value)
                                )}
                                options={ENQUIRIES_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange(
                                        'enquiriesAccess',
                                        option.map(item => item.value).join(',') ?? ''
                                    )
                                }
                                menuPlacement="top"
                                isSearchable={false}
                            />
                        </div>
                        <div className="sm:inline-flex justify-between items-center w-full mb-1 mt-4 sm:mt-0">
                            <div className="font-semibold inline-flex gap-4 items-center">
                                <img
                                    src={ScheduleIcon}
                                    style={{ filter: 'invert(100%) grayscale(100%)' }}
                                    className="h-5 w-5"
                                    alt="schedule icon"
                                />
                                Schedule
                            </div>
                            <Select
                                className="sm:w-[60%]"
                                value={SCHEDULE_ACCESS_OPTIONS.filter(
                                    item => item.value === coachFormData.scheduleAccess
                                )}
                                options={SCHEDULE_ACCESS_OPTIONS}
                                onChange={option =>
                                    handleInputChange('scheduleAccess', option?.value ?? '')
                                }
                                menuPlacement="top"
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className={style.submitBtnsContainer}>
                        <div
                            className={style.formSubmitBtn}
                            onClick={() => switchTab(1, true)}
                        >
                            Back
                        </div>
                        <button
                            type="submit"
                            className={style.formSubmitBtn}
                            disabled={
                                arraysEqual(
                                    coachFormData.academies,
                                    coachEditingData?.attributes?.academies?.data?.map(
                                        item => item.id
                                    ) ?? []
                                ) &&
                                arraysEqual(
                                    coachFormData.classes,
                                    coachEditingData?.attributes?.classes?.data?.map(
                                        item => item.id
                                    ) ?? []
                                ) &&
                                coachFormData.name === coachEditingData?.attributes?.name &&
                                coachFormData.contact === coachEditingData?.attributes?.contactNo &&
                                coachFormData.photo ===
                                    coachEditingData?.attributes?.photo?.data?.attributes?.url &&
                                coachFormData.governmentId ===
                                    coachEditingData?.attributes?.governmentId?.data?.attributes
                                        ?.url &&
                                coachFormData.email === coachEditingData?.attributes?.email &&
                                // arraysEqual(coachFormData.discipline.split(','),coachEditingData?.attributes?.discipline.split(','))
                                coachFormData.dashboardAccess ===
                                    coachEditingData?.attributes?.dashboardAccess &&
                                coachFormData.academyAccess ===
                                    coachEditingData?.attributes?.academyAccess &&
                                coachFormData.classesAccess ===
                                    coachEditingData?.attributes?.classesAccess &&
                                coachFormData.studentsAccess ===
                                    coachEditingData?.attributes?.studentsAccess &&
                                coachFormData.feePaymentAccess ===
                                    coachEditingData?.attributes?.feePaymentAccess &&
                                coachFormData.enquiriesAccess ===
                                    coachEditingData?.attributes?.enquiriesAccess &&
                                coachFormData.scheduleAccess ===
                                    coachEditingData?.attributes?.scheduleAccess
                            }
                        >
                            {isEditMode ? 'Update User' : 'Create User'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewUserForm;
