import React from 'react'
import VirtualClassViewModel from '../../view-models/virtual-class-viewmodel'
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { formatTime, formatTimeToAMPM } from '../../../utils/helpers/helpers';
import Select from 'react-select';

const VirtualClass = () => {
    const {
        isLoading,
        instantMeetingFormData,
        meetingState,
        activeMeeting,
        handleStartInstantMeeting,
        handleInstantMeetingFormChange,
        getMeetingProgress,
        getTimeLeftForMeeting,
        handleJoinOnGoingMeeting,
        mapStudentsToOptions,
        mapClassesToOptions,
    } = VirtualClassViewModel();

    return (
        <>
            {isLoading && <FullScreenLoader />}
            <div>
                <div className='w-full p-4 rounded-lg border-2 border-slate-300 flex gap-8'>
                    {activeMeeting ? (
                        <>
                            <div className='flex-grow'>
                                <div className='flex items-center gap-4 text-xl font-semibold'>
                                    <div className='text-slate-600'>Meeting Name: </div>
                                    <div className='text-slate-800'>{activeMeeting.attributes.meetingName.split('-')[0]}</div>
                                    <div className='text-slate-800 px-2 mt-1 bg-slate-300 rounded text-sm'>{activeMeeting.attributes.type === 'INSTANT' ? 'Instant' : 'Scheduled'}</div>
                                </div>
                                <hr className='my-2' />
                                <div className='flex items-center py-4'>
                                    <div className='bg-slate-300 px-2 py-1 rounded text-slate-600'>{formatTimeToAMPM(activeMeeting.attributes.startTime)}</div>
                                    <div className='relative flex-grow mx-4'>
                                        <div className='w-full h-2 bg-slate-300 rounded'>
                                            <div 
                                                className='h-2 bg-green-300 rounded' 
                                                style={{ width: `${getMeetingProgress(activeMeeting.attributes.startTime, activeMeeting.attributes.endTime)}%` }}
                                            ></div>
                                        </div>
                                        <div className='absolute top-full mt-2 left-1/2 transform -translate-x-1/2 rounded bg-slate-300 px-2 py-1 text-xs'>
                                            {getTimeLeftForMeeting(activeMeeting.attributes.endTime)}
                                        </div>
                                    </div>
                                    <div className='bg-slate-300 px-2 py-1 rounded text-slate-600'>{formatTimeToAMPM(activeMeeting.attributes.endTime)}</div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <div className='flex items-center gap-4 text-xl font-semibold px-4 py-1 rounded-md border-2 border-red-300 text-red-300'>
                                    <div className='h-2 w-2 rounded-full bg-red-500 animate-ping'></div>
                                    Ongoing Meeting
                                </div>
                                <div 
                                    className='cursor-pointer text-xl font-semibold text-white bg-primary rounded px-4 py-2 text-center active:scale-95 transition-all' 
                                    onClick={handleJoinOnGoingMeeting}
                                >
                                    Join
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <form onSubmit={handleStartInstantMeeting} className='flex flex-col justify-between gap-2 w-[400px] min-w-[400px]'>
                                <div>
                                    <div className='text-3xl font-semibold text-slate-600'>Start an instant meeting</div>
                                    <input 
                                        type="text" 
                                        className='p-2 w-full rounded border mt-3 border-slate-300 focus:outline-none focus:ring-1 focus:ring-primary'
                                        placeholder='Meeting Name'
                                        value={instantMeetingFormData.meetingName}
                                        required
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const isValid = /^[A-Za-z0-9\s]*$/.test(value);

                                            if (isValid) {
                                                handleInstantMeetingFormChange('meetingName', value);
                                            }
                                        }}
                                    />
                                    <select
                                        className='p-2 w-full rounded border mt-3 border-slate-300 focus:outline-none focus:ring-1 focus:ring-primary'
                                        value={instantMeetingFormData.durationInMinutes}
                                        onChange={(e) => handleInstantMeetingFormChange('durationInMinutes', Number(e.target.value))}
                                        required
                                    >
                                        <option value="15">15 Minutes</option>
                                        <option value="30">30 Minutes</option>
                                        <option value="45">45 Minutes</option>
                                        <option value="60">1 Hour</option>
                                        <option value="90">1 Hour 30 Minutes</option>
                                        <option value="120">2 Hours</option>
                                    </select>
                                </div>
                                <button 
                                    type='submit'
                                    className='text-xl font-semibold text-slate-600 bg-slate-400 cursor-pointer p-2 rounded w-full text-center active:scale-95 transition-all'
                                >
                                    Start
                                </button>
                            </form>
                            <div className='flex-grow rounded bg-slate-300 relative group p-2'>
                                <div>
                                    {instantMeetingFormData.participantsType === 'STUDENT' ? (
                                        <Select 
                                            isMulti
                                            placeholder="Select students"
                                            options={mapStudentsToOptions()}
                                            onChange={(option) => handleInstantMeetingFormChange('students', option.map(({ value }) => value))}
                                            value={mapStudentsToOptions().filter(option =>
                                                instantMeetingFormData.students?.includes(option.value)
                                            )}
                                        />
                                    ) : (
                                        <Select 
                                            isMulti
                                            placeholder="Select classes"
                                            options={mapClassesToOptions()}
                                            onChange={(option) => handleInstantMeetingFormChange('classes', option.map(({ value }) => value))}
                                            value={mapClassesToOptions().filter(option =>
                                                instantMeetingFormData.classes?.includes(option.value)
                                            )}
                                        />
                                    )}
                                </div>
                                <div className='flex items-center gap-8 px-4 py-2 shadow-inner bg-slate-300 rounded absolute top-[calc(100%-50px)] right-[-12px] '>
                                    <div 
                                        className={`text-slate-600 cursor-pointer text-xl rounded-xl text-center w-[140px] py-2 border border-slate-300 ${instantMeetingFormData.participantsType === 'STUDENT' ? 'bg-slate-500 font-semibold text-white' : ''}`}
                                        onClick={() => handleInstantMeetingFormChange('participantsType', 'STUDENT')}
                                    >
                                        Students
                                    </div>
                                    <div 
                                        className={`text-slate-600 cursor-pointer text-xl rounded-xl text-center w-[140px] py-2 border border-slate-300 ${instantMeetingFormData.participantsType === 'CLASS' ? 'bg-slate-500 font-semibold text-white' : ''}`}
                                        onClick={() => handleInstantMeetingFormChange('participantsType', 'CLASS')}
                                    >
                                        Class
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default VirtualClass