import { AcademyData } from '../../models/academy/academy-data';
import { EnrollmentData } from '../../models/enrollment-data/enrollment-data';
import { StudentData } from '../../models/student/student-data';

export type StudentsState = {
    openTab: number;
    selectedStudentIndex: number;
    currentPage: number;
    queryString: string;
    enrollmentQueryString: string;
    academies: AcademyData[];
    students: StudentData[];
    selfEnrolment: EnrollmentData[];
};

export type StudentsAction =
    | { type: 'setOpenTab'; payload: number }
    | { type: 'setSelectedStudent'; payload: number }
    | { type: 'deselectStudent' }
    | { type: 'setCurrentPage'; payload: number }
    | { type: 'setQueryString'; payload: string }
    | { type: 'setEnrollmentQueryString'; payload: string }
    | { type: 'setAcademies'; payload: AcademyData[] }
    | { type: 'setCurrentPage'; payload: number }
    | { type: 'setSelfEnrolment'; payload: EnrollmentData[] };

export const studentsReducer = (state: StudentsState, action: StudentsAction): StudentsState => {
    switch (action.type) {
        case 'setOpenTab':
            return { ...state, openTab: action.payload };
        case 'setSelectedStudent':
            return { ...state, selectedStudentIndex: action.payload };
        case 'deselectStudent':
            return { ...state, selectedStudentIndex: -1 };
        case 'setCurrentPage':
            return { ...state, currentPage: action.payload };
        case 'setQueryString':
            return { ...state, queryString: action.payload };
        case 'setEnrollmentQueryString':
            return { ...state, enrollmentQueryString: action.payload };
        case 'setAcademies':
            return { ...state, academies: action.payload };
        case 'setSelfEnrolment':
            return { ...state, selfEnrolment: action.payload };
        default:
            return state;
    }
};
