// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formWrapper__4XdBN {

    width: 80vw;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

@media (min-width: 640px) {

    .style_formWrapper__4XdBN {

        width: auto;

        padding-left: 9rem;

        padding-right: 9rem
    }
}

@media (min-width: 1280px) {

    .style_formWrapper__4XdBN {

        padding-left: 16rem;

        padding-right: 16rem
    }
}

.style_formContainer__C4SyI {

    display: flex;

    flex-direction: column;

    padding: 1.5rem;

    text-align: left
}

@media (min-width: 640px) {

    .style_formContainer__C4SyI {

        width: 500px
    }
}

.style_formTitle__XdNJ3 {

    margin-bottom: 1rem;

    text-align: center;

    font-size: 1.5rem;

    line-height: 2rem;

    font-weight: 700
}

.style_formInputLabel__vSwOy {

    margin-top: 0.75rem;

    margin-bottom: 0.25rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

.style_formInput__bEQBc {

    width: 100%;

    resize: none;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.style_formSubmitBtn__t6R5A {

    margin-top: 0.5rem;

    width: 100%;

    --tw-bg-opacity: 1;

    background-color: rgb(71 85 105 / var(--tw-bg-opacity));

    padding: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.style_successMessage__B\\+9az {

    padding: 2rem;

    text-align: center;

    font-size: 1.5rem;

    line-height: 2rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(34 197 94 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/record-leave-form/style.module.css"],"names":[],"mappings":"AACI;;IAAA,WAAoD;;IAApD,kBAAoD;;IAApD;AAAoD;;AAApD;;IAAA;;QAAA,WAAoD;;QAApD,kBAAoD;;QAApD;IAAoD;AAAA;;AAApD;;IAAA;;QAAA,mBAAoD;;QAApD;IAAoD;AAAA;;AAIpD;;IAAA,aAA+C;;IAA/C,sBAA+C;;IAA/C,eAA+C;;IAA/C;AAA+C;;AAA/C;;IAAA;;QAAA;IAA+C;AAAA;;AAI/C;;IAAA,mBAA0C;;IAA1C,kBAA0C;;IAA1C,iBAA0C;;IAA1C,iBAA0C;;IAA1C;AAA0C;;AAI1C;;IAAA,mBAAwB;;IAAxB,sBAAwB;;IAAxB,mBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,WAA+C;;IAA/C,YAA+C;;IAA/C,mBAA+C;;IAA/C,oBAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C;;AAI/C;;IAAA,kBAAsD;;IAAtD,WAAsD;;IAAtD,kBAAsD;;IAAtD,uDAAsD;;IAAtD,eAAsD;;IAAtD,mBAAsD;;IAAtD,oBAAsD;;IAAtD,oBAAsD;;IAAtD;AAAsD;;AAItD;;IAAA,aAAwD;;IAAxD,kBAAwD;;IAAxD,iBAAwD;;IAAxD,iBAAwD;;IAAxD,gBAAwD;;IAAxD,oBAAwD;;IAAxD;AAAwD","sourcesContent":[".formWrapper {\n    @apply sm:px-36 xl:px-64 bg-white w-[80vw] sm:w-auto;\n}\n\n.formContainer {\n    @apply flex flex-col text-left sm:w-[500px] p-6;\n}\n\n.formTitle {\n    @apply text-2xl font-bold text-center mb-4;\n}\n\n.formInputLabel {\n    @apply text-sm mt-3 mb-1;\n}\n\n.formInput {\n    @apply text-sm text-gray-500 resize-none w-full;\n}\n\n.formSubmitBtn {\n    @apply bg-slate-600 text-sm text-white p-2 mt-2 w-full;\n}\n\n.successMessage {\n    @apply text-2xl p-8 text-green-500 font-bold text-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `style_formWrapper__4XdBN`,
	"formContainer": `style_formContainer__C4SyI`,
	"formTitle": `style_formTitle__XdNJ3`,
	"formInputLabel": `style_formInputLabel__vSwOy`,
	"formInput": `style_formInput__bEQBc`,
	"formSubmitBtn": `style_formSubmitBtn__t6R5A`,
	"successMessage": `style_successMessage__B+9az`
};
export default ___CSS_LOADER_EXPORT___;
