import React from 'react';
import style from './style.module.css';
import addUserIcon from '../../../../assets/icons/add-user.png';
import enrollFormIcon from '../../../../assets/icons/enroll-form.png';

type Props = {
    handleAddEnrollmentFormOpen: () => void;
    handleAddNewStudentFormOpen: () => void;
};
function EnrolmentForm({ handleAddEnrollmentFormOpen, handleAddNewStudentFormOpen }: Props) {
    return (
        <div className={style.container}>
            <div className="px-4 pt-4">
                {/* <form onSubmit={handleNextButtonClick}>
                    <div className="flex gap-2 items-center justify-start">
                        <input type="radio" id="enrolment" name="select" value="Enrolment" className="focus:ring-0"/>
                        <label htmlFor="enrolment" className="text-base font-normal cursor-pointer">Send Enrolment Form</label>
                    </div>
                    <div className="flex gap-2 mt-2 items-center justify-start">
                        <input type="radio" id="addStudent" name="select" value="AddStudent" className="focus:ring-0"/>
                        <label htmlFor="addStudent" className="text-base font-normal cursor-pointer">Add Student Manually</label>
                    </div>
                    <div className="flex justify-end sm:mt-2 mr-4 mt-4 ">
                        <button className="p-1 px-4 bg-slate-700 text-white rounded " type="submit">Next</button>
                    </div>
                </form> */}
                <div className="flex gap-8 justify-around mt-2 flex-col sm:flex-row">
                    <div
                        className="p-4 rounded-md shadow-lg flex flex-col items-center justify-center gap-2 cursor-pointer active:shadow-inner transition-all"
                        onClick={handleAddEnrollmentFormOpen}
                    >
                        <img
                            src={enrollFormIcon}
                            className="w-20 h-20"
                        />
                        <div className="text-center text-xl font-semibold text-gray-700">
                            Send Enrolment Form
                        </div>
                    </div>
                    <div
                        className="p-4 rounded-md shadow-lg flex flex-col items-center justify-center gap-2 cursor-pointer active:shadow-inner transition-all"
                        onClick={handleAddNewStudentFormOpen}
                    >
                        <img
                            src={addUserIcon}
                            className="w-20 h-20"
                        />
                        <div className="text-center text-xl font-semibold text-gray-700">
                            Add Student Manually
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnrolmentForm;
