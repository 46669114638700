/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { AttendanceListWrapper } from '../../models/attendance/attendance-list-wrapper';
import ClassService from '../../services/class-service';
import ScheduleService from '../../services/schedule-service';
import {
    AI_SCHEDULE_DETAILS_QUERY,
    CLASS_DETAILS_QUERY,
    CLASS_STUDENT_DETAILS,
    SCHEDULE_DETAILS_QUERY,
    STUDENT_DATA_QUERY,
    STUDENT_QUERY,
    STUDENT_SEARCH_SUGGESTION_QUERY,
    STUDENT_SUGGESTION_QUERY,
} from '../../utils/constants/constants';
import { RootState } from '../../utils/redux/store';
import AttendanceRequest from '../../utils/types/attendance-request';
import { Routes } from '../navigation/routes';
import { StudentData } from '../../models/student/student-data';
import { attempt } from 'lodash';
import { base64toFile, getCurrentDayString, getJWTToken } from '../../utils/helpers/helpers';
import { UploadService } from '../../services/upload-service';
import { uploadImageresponsedata } from '../../utils/types/upload-Image-response-data';
import AttendanceAiApiResponse from '../../utils/types/Attendance-ai-api-response';
import { HeaderMenuService } from '../../services/header-menu-service';
import { StudentPostDataWrapper } from '../../models/student/student-post-data-wrapper';
import { StudentService } from '../../services/student-service';
import AiAttendanceRequest from '../../utils/types/ai-attendance-resquest';
import { AiAttendanceListWrapper } from '../../models/attendance/ai-attendance-list-wrapper';
import { StudentDetailsWrapper } from '../../models/student/student-details-wrapper';
import StudentDetailsViewModel from './student-details-view-model';
import { AttendanceDataWraper } from '../../models/attendance/attendance-data-wrapper';

export interface NewStudentMinimalDataType {
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string;
    DateOfBirth: string;
    BloodGroup: string;
    academies: number[];
    skillLevel: string;
    photo: string;
    class: number[];
}

const ScheduleDetailsViewmodel = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const userState = useSelector((state: RootState) => state.user);
    const selectedDate = useLocation().state?.date;
    const filterStudentsForDay = useLocation().state?.filterStudentsForDay;
    const timings: {start: string, end: string} = useLocation().state?.time;
    const queryClient = useQueryClient();
    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
    
    const {
        isSuccess: isClassDetailsFetchedSuccess,
        isLoading: isClassDetailsLoading,
        data: classDetails,
    } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY, id, selectedDate],
        queryFn: ClassService.instance.getClassDetails.bind(this, Number(id)),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isClassStudentDetailsFetchedSuccess,
        isLoading: isClassStudentDetailsLoading,
        data: classStudentDetails,
    } = useQuery({
        queryKey: [id, searchTerm, CLASS_STUDENT_DETAILS],
        queryFn: () => ClassService.instance.getClassStudentsDetails(Number(id), searchTerm),
        refetchOnWindowFocus: false,
        enabled: true,
    });
    const [isfilterModalOpen, setIsfilterModalOpen] = useState(false);

    // Fetch Attendance History
    const {
        isSuccess: isAttendanceFetchSuccess,
        isLoading: isAttendanceLoading,
        isFetching: isAttendanceFetching,
        data: attendanceList,
    } = useQuery({
        queryKey: [SCHEDULE_DETAILS_QUERY, id, selectedDate, searchTerm],
        queryFn: ScheduleService.instance.getAttendanceHistory.bind(
            this,
            classDetails?.data?.data?.id ?? 0,
            selectedDate,
            searchTerm
        ),
        refetchOnWindowFocus: true,
        enabled: (userState?.user?.id ?? 0) > 0 && isClassDetailsFetchedSuccess,
        onSuccess: () => {
            setNotLatestAttendanceList(prev => {
                const newArray = [...prev];  // Create a copy of the array
                newArray.pop();              // Remove the last element
                return newArray;             // Return the updated array
            });
        }
    });

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
    
        if (!isAttendanceFetching) {
            // Start a timer when isAttendanceFetching becomes false
            timer = setTimeout(() => {
                setNotLatestAttendanceList([]);
                queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
            }, 5000);
        }
    
        return () => {
            // Clear the timer if isAttendanceFetching changes before 2 seconds pass
            clearTimeout(timer);
        };
    }, [isAttendanceFetching]);
    
    const {
        isSuccess: isAiAttendanceResponseFetchSuccess,
        isLoading: isAiAttendanceResponseLoading,
        isFetching: isAiAttendanceResponseFetching,
        data: AiAttendanceResponse,
    } = useQuery({
        queryKey: [AI_SCHEDULE_DETAILS_QUERY, id, selectedDate],
        queryFn: ScheduleService.instance.getAiAttendanceResponse.bind(
            this,
            classDetails?.data?.data?.id ?? 0,
            selectedDate
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0 && isClassDetailsFetchedSuccess,
    });

    
    const postAttendance = async (
        data: AttendanceRequest
    ): Promise<AttendanceDataWraper | undefined> => {
        const response = await ScheduleService.instance.postAttendance(data);
        if (response.success) {
            setNotLatestAttendanceList(prev => [data.student ?? 0, ...prev]);
            setActiveStudentsMarkingAttendance(prev => [...prev.filter(id => id !== response.data?.data.attributes.student.data.id)]);
            return response.data;
        } else {
            setNotLatestAttendanceList(prev => [data.student ?? 0, ...prev]);
            setActiveStudentsMarkingAttendance(prev => [...prev.filter(id => id !== response.data?.data.attributes.student.data.id)]);
            throw new Error(response.error?.message);
        }
    };
    
    const {
        mutate: addAttendance,
        isLoading: isAttendancePosting,
        error: isAttendancePostError,
    } = useMutation(postAttendance, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
        onError: error => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
    });

    const  [activeStudentsMarkingAttendance, setActiveStudentsMarkingAttendance] = useState<number[]>([]);
    const [notLatestAttendanceList, setNotLatestAttendanceList] = useState<number[]>([]);

    const handleAddAttendance = async (data: AttendanceRequest) => {
        setActiveStudentsMarkingAttendance(prev => [...prev, data.student ?? 0]);
        addAttendance(data);
    };

    const handleEditAttendance = async (id: number,data: AttendanceRequest) => {
        setActiveStudentsMarkingAttendance(prev => [...prev, data.student ?? 0]);
        updateAttendance({attendanceId: id, data});
    }

    const putAttendance = async (args: {
        attendanceId: number;
        data: AttendanceRequest;
    }): Promise<AttendanceDataWraper | undefined> => {
        const { attendanceId, data } = args;
        const response = await ScheduleService.instance.putAttendance(attendanceId, data);
        if (response.success) {
            setNotLatestAttendanceList(prev => [data.student ?? 0, ...prev]);
            setActiveStudentsMarkingAttendance(prev => [...prev.filter(id => id !== response.data?.data.attributes.student.data.id)]);
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateAttendance,
        isLoading: isAttendanceUpdating,
        error: isAttendanceUpdateError,
    } = useMutation(putAttendance, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
        onError: error => {
            throw error;
        },
    });

    const handlesearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event?.target?.value);
    };

    const onStudentSearchListItemClick = (name: string) => {
        setSearchTerm(name);
    };

    const [dropSortBy, setDropSortBy] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState<StudentData[]>([]);

    const checkBoxHandler = (option: string) => {
        if (classStudentDetails?.data?.data) {
            let sortedData = [...classStudentDetails.data.data];

            switch (option) {
                case 'Student name A-Z':
                    sortedData.sort((a, b) =>
                        a.attributes.firstName.localeCompare(b.attributes.firstName)
                    );
                    break;
                // Add more cases for other sorting options if needed
                default:
                    // Reset sorting to the original order if no option is selected
                    sortedData = [...classStudentDetails.data.data];
                    break;
            }

            // If the clicked option is already selected, deselect it
            if (selectedOption === option) {
                setSelectedOption('');
                setSortBy(classStudentDetails.data.data);
            } else {
                // Otherwise, select the clicked option
                setSelectedOption(option);
                setSortBy(sortedData);
            }

            setDropSortBy(false);
        }
    };

    const handleFilterModalClose = () => {
        setIsfilterModalOpen(false);
    };

    const handleFilterBtnClick = () => {
        setIsfilterModalOpen(true);
    };

    const goScheduleDetailsBase = useCallback(() => {
        navigate(Routes.Schedule, {state: {date: selectedDate}});
    }, []);

    const [isPhotoUploadFormOpen, setIsPhotoUploadFormOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<(number | File)[]>([]);
    const [previewURLs, setPreviewURLs] = useState<string[]>([]);
    const [imageProcessingSuccess, setImageProcessingSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedImageData, setUploadedImageData] = useState<uploadImageresponsedata>();
    const [aiModelResponse, setAiModelResponse] = useState<AttendanceAiApiResponse>();
    const [aiModelResponseCorrected, setAiModelResponseCorrected] = useState<AttendanceAiApiResponse>();
    const [isStudentInfoEditFormOpen, setIsStudentInfoEditFormOpen] = useState(false);
    const [activeStudentEdit, setActiveStudentEdit] = useState<number>(-1);
    const [expandedPresentByAiCards, setExpandedPresentByAiCards] = useState<number[]>([]);
    const [expandedPresentByUserCards, setExpandedPresentByUserCards] = useState<number[]>([]);
    const [expandedAbsentCards, setExpandedAbsentCards] = useState<number[]>([]);
    const [expandedUnIdentifiedCards, setExpandedUnIdentifiedCards] = useState<number[]>([]);
    const [isAiResponsePageOpen, setIsAiResponsePageOpen] = useState<boolean>(false);
    const [studentsMarkedPresentByUser, setStudentsMarkedPresentByUser] = useState<number[]>([]);

    const handleMoveStudentFromAbsentToPresent = (id: number) => {
        setStudentsMarkedPresentByUser(prev => [...prev, id]);
    }

    useEffect(() => {
        setExpandedPresentByUserCards([]);
        setExpandedAbsentCards([]);
    }, [studentsMarkedPresentByUser])

    const handleMoveStudentFromPresentToAbsent = (id: number) => {
        setStudentsMarkedPresentByUser(prev => prev.filter(studentId => studentId !== id));
    }

    useEffect(() => {
        if (AiAttendanceResponse?.data?.data[0]?.attributes?.aiResponse?.success === true) {
            setAiModelResponseCorrected(
                JSON.parse(
                    JSON.stringify(AiAttendanceResponse?.data?.data[0]?.attributes?.aiResponse)
                )
            );
        }
    }, [AiAttendanceResponse?.data?.data]);

    const postBulkAttendance = async (
        data: AttendanceRequest[]
    ): Promise<AttendanceListWrapper | undefined> => {
        const response = await ScheduleService.instance.postBulkAttendance(data, true);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addBulkAttendance,
        isLoading: isAttendanceBulkPosting,
        error: attendanceBulkPostError,
    } = useMutation(postBulkAttendance, {
        onSuccess: data => {
            // This function will only be called if the mutation is successful
            alert('Attendance Marked Successfully!');
            setSelectedImage([]);
            setImageProcessingSuccess(false);
            setUploadedImageData(undefined);
            setAiModelResponse(undefined);
            setAiModelResponseCorrected(undefined);
            setIsAiResponsePageOpen(false);
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
            queryClient.invalidateQueries(CLASS_STUDENT_DETAILS);
            queryClient.invalidateQueries(AI_SCHEDULE_DETAILS_QUERY);
            setIsLoading(false);
        },
        onError: (error, variables, context) => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
            setIsLoading(false);
        },
    });

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const filesArray = Array.from(files); // Convert FileList to array
            console.log(filesArray);
            
            const newPreviewURLs = filesArray.map(file => URL.createObjectURL(file));

            setSelectedImage(prev => [...prev, ...filesArray]);
            setPreviewURLs(prev => [...prev, ...newPreviewURLs]);
        }
    };

    const postAiAttendanceData = async (
        data: AiAttendanceRequest
    ): Promise<AiAttendanceListWrapper | undefined> => {
        const response = await ScheduleService.instance.postAiAttendanceRequestData(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewAiAttendanceData,
        isLoading: isNewAiAttendanceDataLoading,
        isSuccess: isNewAiAttendanceDataAdded,
    } = useMutation(postAiAttendanceData, {
        onSuccess: data => {
            queryClient.invalidateQueries(AI_SCHEDULE_DETAILS_QUERY);
            alert('Created attendance Ai Request Successfully!');
        },
        onError: error => {
            alert('Failed to register a request to Ai model! Please try again');
        },
    });

    const updateAiAttendanceData = async (
        data: AiAttendanceRequest
    ): Promise<AiAttendanceListWrapper | undefined> => {
        const response = await ScheduleService.instance.putAiAttendanceRequestData(data,AiAttendanceResponse?.data?.data[0]?.id ?? 0);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editNewAiAttendanceData,
        isLoading: isEditAiAttendanceDataLoading,
        isSuccess: isEditAiAttendanceDataAdded,
    } = useMutation(updateAiAttendanceData, {
        onSuccess: data => {
            queryClient.invalidateQueries(AI_SCHEDULE_DETAILS_QUERY);
            alert('Updated attendance Ai Request Successfully!');
        },
        onError: error => {
            alert('Failed to update the request to Ai model! Please try again');
        },
    });

    useEffect(() => {
        if (previewURLs.length > 0) {
            setCurrentImageIndex(previewURLs.length - 1);
        }
    }, [previewURLs]);

    const uploadSelectedImage = async () => {
        setIsLoading(true);
        const data = new FormData();
        selectedImage
        .filter(img => typeof img === 'object')
            .map((img, index) => {
            
            data.append('files', img as File, `students-attendance-${new Date().getTime().toString()}-${index}`);
        });
        

        try {
            console.log(data);
            
            const response = await UploadService.instance.uploadImage(data);

            if (response.success && response.data) {
                const reqBody: AiAttendanceRequest = {
                    class: parseInt(id ?? '') || 0,
                    date: new Date(),
                    images: [
                        ...(response?.data ? response.data.map(item => item?.id) : []),
                        ...selectedImage.filter(img => typeof img === 'number') as number[] 
                    ],
                    users: classDetails?.data?.data?.attributes?.users?.data
                        ? classDetails.data.data.attributes.users.data.map(item => item.id)
                        : [], 
                    aiResponse: null
                };
                if(AiAttendanceResponse?.data?.data && AiAttendanceResponse?.data?.data.length > 0) {
                    editNewAiAttendanceData(reqBody);
                }else{
                    addNewAiAttendanceData(reqBody);
                }
                queryClient.invalidateQueries(AI_SCHEDULE_DETAILS_QUERY);
                setIsLoading(false);
                setSelectedImage([]);
                setPreviewURLs([]);
                setIsAiResponsePageOpen(false);
                setIsPhotoUploadFormOpen(false);
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to upload image! Please try again.');
            setIsLoading(false);
            throw error;
        }
    };

    const handleAttendanceAiResponseCorrection = async () => {
        setIsLoading(true);

        const changedItems = aiModelResponseCorrected?.present.reduce(
            (changedIndexes: number[], [name, value], index) => {
                if (
                    aiModelResponse?.present[index] &&
                    aiModelResponse.present[index][1] !== value
                ) {
                    changedIndexes.push(index);
                }
                return changedIndexes;
            },
            []
        );

        const attendanceToBeCreated: AttendanceRequest[] = [
            ...(aiModelResponseCorrected?.present.map((item, index) => ({
                present: true,
                on_leave: false,
                note: 'Marked through AI model',
                attendance_date: new Date().toISOString().split('T')[0], // Using ISO format for date
                class: Number(id),
                student: item[1],
                time: new Date().toISOString().split('T')[1].split('.')[0] + '.000', // Using ISO format for time
                markedBy: userState?.user?.id ?? 0,
                systemMarked: false,
                photo: aiModelResponseCorrected?.crops[index],
            })) ?? []),
            ...(classStudentDetails?.data?.data?.filter(s => studentsMarkedPresentByUser.includes(s.id)).map(s => ({
                present: true,
                on_leave: false,
                note: 'Marked through AI model',
                attendance_date: new Date().toISOString().split('T')[0], // Using ISO format for date
                class: Number(id),
                student: s?.id,
                time: new Date().toISOString().split('T')[1].split('.')[0] + '.000', // Using ISO format for time
                markedBy: userState?.user?.id ?? 0,
                systemMarked: false,
            })) ?? [])
        ];

        const requestBody = {
            student_id: changedItems?.map(item => aiModelResponseCorrected?.present[item][1]),
            faces: changedItems?.map(item => aiModelResponseCorrected?.crops[item]),
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (changedItems?.length && changedItems.length > 0) {
            fetch(`${process.env.REACT_APP_AI_API_URL}/api/v1/correction`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(async data => {
                    // Handle successful response
                    await addBulkAttendance(attendanceToBeCreated);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    setIsLoading(false);
                    alert('Failed to Update Student Info! Please try again.');
                });
        } else {
            await addBulkAttendance(attendanceToBeCreated);
        }
    };

    const removeSelectedImage = (index: number) => {
        setSelectedImage(prev => prev.filter((_, i) => i !== index));
    };

    const handlePhotoUploadBtnClick = () => {
        setIsPhotoUploadFormOpen(true);
    };

    const handleAddMorePhotoBtnClick = () => {
        setPreviewURLs(AiAttendanceResponse?.data?.data[0].attributes?.images?.data?.map(img => img?.attributes?.url) ?? []);
        setSelectedImage(AiAttendanceResponse?.data?.data[0].attributes?.images?.data?.map(img => img?.id) ?? []);
        setIsPhotoUploadFormOpen(true);
    }

    const handlePhotoUploadFormClose = () => {
        setIsPhotoUploadFormOpen(false);
    };

    const handleStudentEditBtnClick = (index: number) => {
        setActiveStudentEdit(index);
        setStudentSearchTerm(aiModelResponseCorrected?.present[index][0] ?? '');
        setIsStudentInfoEditFormOpen(true);
    };

    const handleStudentEditFormClose = () => {
        setIsStudentInfoEditFormOpen(false);
        setStudentSearchTerm('');
        setSelectedStudentInEditStudentForm(undefined);
    };

    const handleCardExpandBtnClick = (index: number, type: string) => {
        switch (type) {
            case 'presentByAi':
                setExpandedPresentByAiCards(prev => {
                    if (prev.includes(index)) {
                        return [...prev].filter(item => item !== index);
                    } else {
                        return [...prev, index];
                    }
                });
                break;
            case 'presentByUser':
                setExpandedPresentByUserCards(prev => {
                    if (prev.includes(index)) {
                        return [...prev].filter(item => item !== index);
                    } else {
                        return [...prev, index];
                    }
                });
                break;
            case 'absent':
                setExpandedAbsentCards(prev => {
                    if (prev.includes(index)) {
                        return [...prev].filter(item => item !== index);
                    } else {
                        return [...prev, index];
                    }
                });
                break;
            case 'unidentified':
                setExpandedUnIdentifiedCards(prev => {
                    if (prev.includes(index)) {
                        return [...prev].filter(item => item !== index);
                    } else {
                        return [...prev, index];
                    }
                });
                break;
            default:
                break;
        }
    };

    const handleAiResponseCorrectionChanges = (data: AttendanceAiApiResponse) => {
        setAiModelResponseCorrected(data);
    };

    const [studentSearchTerm, setStudentSearchTerm] = useState('');
    const [isStudentSearchInputOnFocus, setIsStudentSearchInputOnFocus] = useState(false);
    const [editStudentValue] = useDebounce(studentSearchTerm, 1000);
    const [selectedStudentInEditStudentForm, setSelectedStudentInEditStudentForm] = useState<StudentData>();

    const {
        isSuccess: isEditStudentSuggesionDataFetchSuccess,
        isLoading: isEditStudentDataFetchLoading,
        data: editStudentSuggestionListData,
    } = useQuery({
        queryKey: [STUDENT_SEARCH_SUGGESTION_QUERY, editStudentValue],
        queryFn: async () => {
            try {
                // Perform asynchronous operations here
                const response = await HeaderMenuService.instance.getStudentList(
                    userState.user?.id ?? 0,
                    editStudentValue
                );
                // Return the result
                return response;
            } catch (error) {
                // Handle errors
                console.error('Error fetching student suggestion data:', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
        enabled: studentSearchTerm.length >= 0,
    });

    const handleStudentSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStudentSearchTerm(event?.target?.value);
    };

    const onEditStudentSearchListItemClick = (student: StudentData) => {
        setSelectedStudentInEditStudentForm(student);
        setStudentSearchTerm(
            student?.attributes?.firstName +
                ' ' +
                (student?.attributes?.middleName || '') +
                ' ' +
                (student?.attributes?.lastName || '')
        );
    };

    const initialNewStudentMinimalFormData = {
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        DateOfBirth: '',
        BloodGroup: '',
        academies: [],
        skillLevel: '',
        photo: '',
        class: [],
    };

    const [newStudentMinimalFormData, setNewStudentMinimalFormData] =
        useState<NewStudentMinimalDataType>(initialNewStudentMinimalFormData);
    const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState<boolean>(false);
    const [activeAddStudentFormIndex, setActiveAddStudentFormIndex] = useState<number>(-1);

    const handleAddStudentInputChange = (property: string, value: string | number[]) => {
        setNewStudentMinimalFormData(prev => ({
            ...prev,
            [property]: value,
        }));
    };

    const postNewStudent = async (
        data: StudentPostDataWrapper
    ): Promise<StudentDetailsWrapper | undefined> => {
        const response = await StudentService.instance.postNewStudent(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewStudent,
        isLoading: isNewStudentAdding,
        // error: isLeaveRecordPostError,
        isSuccess: isNewStudentAdded,
    } = useMutation(postNewStudent, {
        onSuccess: data => {
            queryClient.invalidateQueries(CLASS_STUDENT_DETAILS);
            alert('Added student Successfully!');
            
            setAiModelResponseCorrected((prev: AttendanceAiApiResponse | undefined) => {
                if (!prev) {
                    return {
                        success: false,
                        result: [],
                        present: [],
                        max_students: [],
                        crops: [],
                    };
                }

                const updatedPresent = [...prev.present];

                updatedPresent[activeAddStudentFormIndex][0] = `${
                    data?.data?.attributes?.firstName ?? ''
                } ${data?.data?.attributes?.middleName ?? ''} ${
                    data?.data?.attributes?.lastName ?? ''
                }`;
                updatedPresent[activeAddStudentFormIndex][1] = data?.data?.id ?? 0;
                return {
                    ...prev,
                    present: updatedPresent,
                };
            });

            setIsNewStudentModalOpen(false);
            setIsStudentInfoEditFormOpen(false);
            setActiveAddStudentFormIndex(-1);
            queryClient.invalidateQueries(STUDENT_QUERY);
        },
        onError: error => {
            alert('Failed to add student! Please try again');
        },
    });

    const [isImageUploading,setIsImageUploading] = useState(false);

    const postNewStudentImage = async (data: FormData) => {
        setIsImageUploading(true);
        try {
            const response = await UploadService.instance.uploadImage(data);
            if (response.success) {
                const newStudentData: StudentPostDataWrapper = {
                    data: {
                        firstName: newStudentMinimalFormData.firstName,
                        lastName: newStudentMinimalFormData.lastName || null,
                        middleName: newStudentMinimalFormData.middleName || null,
                        gender: newStudentMinimalFormData.gender,
                        dob: newStudentMinimalFormData.DateOfBirth,
                        photo: response?.data?.[0].id,
                        academies: [classDetails?.data?.data?.attributes?.academies?.data?.id ?? 0],
                        classes: [classDetails?.data?.data?.id ?? 0],
                        studentAcademyHistory: [
                            {
                                supplier: userState.user?.id,
                                academy:
                                    classDetails?.data?.data?.attributes?.academies?.data?.id ?? 0,
                                status: 'Active',
                                statusDate: new Date(),
                            },
                        ],
                        users: [userState?.user?.id || 0],
                    },
                };
                setIsImageUploading(false);
                addNewStudent(newStudentData);
            }
        } catch (error) {
            setIsImageUploading(false);
            console.error(`Error in postNewStudentImage: ${error}`);
            throw error;
        }
    };

    const {
        mutate: uploadImageNewStudent,
        isLoading: isNewStudentImageUploading,
        isSuccess: isNewStudentImageUploaded,
    } = useMutation(postNewStudentImage, {
        onSuccess: data => {
            console.log('upload student image successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const handleAddNewStudent = (e: React.FormEvent) => {
        e.preventDefault();

        const data = new FormData();
        const imageFile = base64toFile(
            `data:image/jpeg;base64,${aiModelResponseCorrected?.crops[activeAddStudentFormIndex]}`
        );

        data.append('files', imageFile);
        uploadImageNewStudent(data);
    };

    const handleAddStudentBtnClick = (index: number) => {
        setIsNewStudentModalOpen(true);
        setActiveAddStudentFormIndex(index);
    };

    const handleNewStudentModalClose = () => {
        setIsNewStudentModalOpen(false);
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleViewAiResponseBtnClick = () => {
        setIsAiResponsePageOpen(true);
        setCurrentImageIndex(0);
    };

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrev = (length: number) => {
        setCurrentImageIndex(prevIndex => (prevIndex === 0 ? length - 1 : prevIndex - 1));
    };

    const handleNext = (length: number) => {
        setCurrentImageIndex(prevIndex => (prevIndex === length - 1 ? 0 : prevIndex + 1));
    };

    const handleDotClick = (index: number) => {
        setCurrentImageIndex(index);
    };

    const handleImageIndexClick = (index: number) => {
        setCurrentImageIndex(index);
    };

    const indexToPhotoNumberConverter = (index: number): string => {
        if (10 <= index % 100 && index % 100 <= 20) {
            return index + 'th';
        } else {
            const suffix = { 1: 'st', 2: 'nd', 3: 'rd' }[index % 10] || 'th';
            return index + suffix;
        }
    };

    const handleAiResponseDataRefetch = () => {
        queryClient.invalidateQueries(AI_SCHEDULE_DETAILS_QUERY);
    };

    const listOfAllStudents = [
        ...(classStudentDetails?.data?.data ?? []).filter(item => {
            if (!filterStudentsForDay) {
                return true;
            }
            const sessions = item?.attributes?.sessions ?? [];

            // Keep items with no sessions
            if (sessions.length === 0) {
                return true;
            }

            // Check each session
            return sessions.some(session => {
                const classIdMatch = session?.class?.data?.id === classDetails?.data?.data?.id;

                if (classIdMatch) {
                    const sessionTimings = session.sessionTimings ?? [];

                    // Keep if sessionTimings is empty
                    if (sessionTimings.length === 0) {
                        return true;
                    }

                    // Keep if any sessionTiming matches the selectedDay
                    return sessionTimings.some(t => t.day === getCurrentDayString(new Date(selectedDate)) && t.start_time === timings.start && t.end_time === timings.end);
                }

                return false;
            });
        }),
        ...(attendanceList?.data?.data.map(item => item.attributes.student.data) ?? []),
    ]
        .flatMap(item => item)
        .filter((value, index, self) => self.findIndex(item => item.id === value.id) === index);

    const totalPresent = attendanceList?.data?.data
        .filter(
            value => value.attributes.present === true
            // You can add additional conditions here if needed
        )
        .map(item => item.attributes.student.data.id) // Map to get student IDs
        .filter((value, index, self) => self.indexOf(value) === index).length;

    const totalAbsent = attendanceList?.data?.data
        .filter(
            value => value.attributes.present === false
            // You can add additional conditions here if needed
        )
        .map(item => item.attributes.student.data.id) // Map to get student IDs
        .filter((value, index, self) => self.indexOf(value) === index).length;

    const [isAddGuestStudentAttendanceModalOpen, setIsAddGuestStudentAttendanceModalOpen] =
        useState(false);

    const handleAddGuestStudentAttendanceModalClose = () => {
        setIsAddGuestStudentAttendanceModalOpen(false);
    };

    const handleAddGuestStudentAttendanceModalOpen = () => {
        setIsAddGuestStudentAttendanceModalOpen(true);
    };

    return {
        classDetails,
        selectedDate,
        attendanceList,
        isClassDetailsLoading,
        isAttendanceLoading,
        isAttendanceUpdating,
        addAttendance,
        updateAttendance,
        handleEditAttendance,
        isAttendanceFetchSuccess,
        isAttendancePosting,
        isAttendanceFetching,
        handleAiResponseDataRefetch,
        setSearchTerm,
        onStudentSearchListItemClick,
        isSearchInputOnFocus,
        searchTerm,
        setIsSearchInputOnFocus,
        handlesearchInputChange,
        studentSuggestionListData: classStudentDetails,
        isStudentDataFetchLoading: isClassStudentDetailsLoading,
        classStudentDetails: sortBy.length > 0 ? sortBy : classStudentDetails?.data?.data,
        setDropSortBy,
        dropSortBy,
        sortBy,
        selectedOption,
        checkBoxHandler,
        handleFilterModalClose,
        isfilterModalOpen,
        handleFilterBtnClick,
        queryClient,
        handleAddAttendance,
        goScheduleDetailsBase,
        userState,
        isPhotoUploadFormOpen,
        handlePhotoUploadBtnClick,
        handlePhotoUploadFormClose,
        imageProcessingSuccess,
        isLoading: isLoading || isImageUploading,
        uploadedImageData,
        previewURLs,
        removeSelectedImage,
        handleImageChange,
        uploadSelectedImage,
        aiModelResponseCorrected,
        aiModelResponse,
        handleStudentEditBtnClick,
        handleStudentEditFormClose,
        activeStudentEdit,
        handleCardExpandBtnClick,
        expandedPresentByAiCards,
        expandedPresentByUserCards,
        expandedAbsentCards,
        expandedUnIdentifiedCards,
        handleAttendanceAiResponseCorrection,
        handleAiResponseCorrectionChanges,
        setAiModelResponseCorrected,
        isStudentInfoEditFormOpen,
        studentSearchTerm,
        isStudentSearchInputOnFocus,
        setIsStudentSearchInputOnFocus,
        handleStudentSearchInputChange,
        isEditStudentDataFetchLoading,
        editStudentSuggestionListData,
        onEditStudentSearchListItemClick,
        newStudentMinimalFormData,
        handleAddStudentInputChange,
        handleAddNewStudent,
        isNewStudentModalOpen,
        handleAddStudentBtnClick,
        handleNewStudentModalClose,
        activeAddStudentFormIndex,
        selectedStudentInEditStudentForm,
        isNewStudentAdding,
        triggerFileInput,
        fileInputRef,
        AiAttendanceResponse: AiAttendanceResponse?.data?.data,
        handleViewAiResponseBtnClick,
        isAiResponsePageOpen,
        currentImageIndex,
        handlePrev,
        handleNext,
        handleDotClick,
        handleImageIndexClick,
        indexToPhotoNumberConverter,
        listOfAllStudents,
        totalPresent,
        totalAbsent,
        isAddGuestStudentAttendanceModalOpen,
        handleAddGuestStudentAttendanceModalClose,
        handleAddGuestStudentAttendanceModalOpen,
        filterStudentsForDay,
        timings,
        activeStudentsMarkingAttendance,
        notLatestAttendanceList,
        handleAddMorePhotoBtnClick,
        studentsMarkedPresentByUser,
        handleMoveStudentFromAbsentToPresent,
        handleMoveStudentFromPresentToAbsent,
    };
};

export default ScheduleDetailsViewmodel;
