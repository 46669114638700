import { ClassData } from '../../models/class/class-data';

export type ScheduleState = {
    classData: ClassData[];
    selectedCalendarDate: Date;
};

export type ScheduleAction =
    | { type: 'setClassData'; payload: ClassData[] }
    | { type: 'setSelectedCalendarDate'; payload: Date };

export const scheduleReducer = (state: ScheduleState, action: ScheduleAction) => {
    switch (action.type) {
        case 'setClassData':
            return {
                ...state,
                classData: action.payload,
            };
        case 'setSelectedCalendarDate':
            return {
                ...state,
                selectedCalendarDate: action.payload,
            };
        default:
            return state;
    }
};
