/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import style from './style.module.css';
import GrowthBarchart from '../../components/widgets/growth-bar-chart';
import DashboardViewModel from '../../view-models/dashboard-view-model';
import { AiOutlinePlus } from 'react-icons/ai';
import { SlOptions } from 'react-icons/sl';
import { formatTime, getFormattedWeekDay } from '../../../utils/helpers/helpers';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { Roles } from '../../../utils/auth/roles';
import AddStudent from '../../actions/add-student';
import CollectFees from '../../actions/collect-fees';
import AcademyScheduler from '../scheduler';
import CustomDropdown from '../../components/widgets/custom-dropdown';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { TbArrowsMinimize } from "react-icons/tb";

const QuickActionComponent = () => {
    const {
        onCollectFeeClicked,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
        handleNewStudentModalClose,
        isNewStudentModalOpen,
        handleAddNewStudentBtnClick,
        userState,
    } = DashboardViewModel();

    return (
        <div
            id="quickAction"
            className={style.cardContainer}
        >
            <div className={style.cardHeaderNoBorder}>Quick Action </div>
            <div className={style.actionButtonWrapper}>
                {((userState?.userDetails?.attributes?.studentsAccess !== 'noAccess' &&
                    userState?.userDetails?.attributes?.studentsAccess !== 'restrictedView') ||
                    userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                    <button
                        className={style.actionButton}
                        onClick={handleAddNewStudentBtnClick}
                    >
                        <AiOutlinePlus />
                        <span className={style.textSmall}>Add new Student</span>
                    </button>
                )}

                {((userState?.userDetails?.attributes?.feePaymentAccess !== 'noAccess' &&
                    userState?.userDetails?.attributes?.feePaymentAccess !== 'restrictedView') ||
                    userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                    <button
                        className={style.actionButton}
                        onClick={onCollectFeeClicked}
                    >
                        <span className={style.textSmall}>Collect Fees</span>
                    </button>
                )}

                {/* temporarily commented out for demo */}

                {/* <button className={style.actionButton}>
                        <AiOutlinePlus />
                        <span className={style.textSmall}>Add new Enquiry</span>
                    </button> */}
            </div>

            <CollectFees
                isOpen={isFeeCollectionModalOpen}
                handleClose={handleFeeCollectionModalClose}
                isStudentChangable={true}
            />
            <AddStudent
                isOpen={isNewStudentModalOpen}
                handleClose={handleNewStudentModalClose}
                isEdit={false}
            />
        </div>
    );
};

const ActiveStudentComponent = () => {
    const { activeStudentsCount } = DashboardViewModel();

    return (
        <div className={style.cardContainer}>
            <div className={style.innerBlock}>
                <div>
                    <span className={style.upperCase}>Active Students</span>
                </div>
                {/* temporarily commented out for demo */}

                {/* <div>
                        <span className={style.upperCase}>Active Coaches</span>
				    </div> */}

                {/* temporarily commented out for demo */}
            </div>
            <div className={style.flexJustify}>
                <div>
                    <span className={style.textLarge}>{activeStudentsCount}</span>
                </div>
                {/* temporarily commented out for demo */}

                {/* <div>
					<span className={style.textLarge}>5</span>
					</div> */}

                {/* temporarily commented out for demo */}
            </div>
        </div>
    );
};

const ScheduleComponent = () => {
    const { goScheduleDetails, todayClasses } = DashboardViewModel();

    return (
        <div
            id="upcomingSchedule"
            className={`${style.cardContainer} `}
        >
            <div className={style.cardHeaderWrapper}>
                <div>Upcoming Schedule</div>
                <div>
                    {/* <div className={style.filterItemContainer}>
                        <label className={style.paddingRight2}>
                            {' '}
                            <FiFilter />{' '}
                        </label>

                        <div className={style.filterItemInnerContainer}>
                            <label className={style.pointerCursor}>
                                <input
                                    type="radio"
                                    value="All"
                                    className={style.hideBlock}
                                />
                                <span className={style.filterHeaderText}>
                                    All
                                </span>
                            </label>
                            {DATA_SCHEDULE.map((item, index) => (
                                <label
                                    key={item.id}
                                    className={style.filterHeader}>
                                    <input
                                        type="radio"
                                        key={index}
                                        value={item.category}
                                        className={style.hideBlock}
                                    />
                                    <span className={style.filterHeaderText}>
                                        {item.category}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div> */}
                </div>

                <div className="hidden">
                    <SlOptions />
                </div>
            </div>
            <table className={style.tableWrapper}>
                <tbody>
                    {todayClasses.length === 0 ? (
                        <div>No classes today.</div>
                    ) : (
                        todayClasses.map((item, index) => (
                            <tr
                                key={index}
                                className={style.tableRow}
                            >
                                <td>
                                    <input type="checkbox" />
                                </td>
                                <td className={style.tableCell}>
                                    <span className={style.categoryWrapper}>c</span>
                                </td>
                                <td
                                    className={`${style.tableCell} hidden sm:block  sm:pt-[0.9rem]`}
                                >
                                    <div>
                                        {' '}
                                        {getFormattedWeekDay(item?.attributes?.class_day ?? '')}
                                    </div>
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    {formatTime(
                                        item?.attributes?.class_timing?.start_time ?? ''
                                    )} -{' '}
                                    {formatTime(item?.attributes?.class_timing?.end_time ?? '')}
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    {item?.attributes?.class_name ?? ''}
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    {item?.attributes?.students?.data?.length === 0
                                        ? 'No student registered'
                                        : item?.attributes?.students?.data?.length === 1
                                          ? item?.attributes?.students?.data[0]?.attributes
                                                .firstName
                                          : `${
                                                item?.attributes?.students?.data[0]?.attributes
                                                    ?.firstName
                                            } and ${
                                                item?.attributes?.students.data?.length - 1
                                            } more`}
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    <button
                                        onClick={() =>
                                            goScheduleDetails(
                                                item.id,
                                                item?.attributes?.class_day ?? '',
                                                item?.attributes?.class_timing?.start_time ?? '',
                                                item?.attributes?.class_timing?.end_time ?? ''
                                            )
                                        }
                                        className={style.linkWrapper}
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

const WelcomePrompt = () => {
    const { supplierData } = DashboardViewModel();
    return (
        <div className={style.headerWelcomeContainer}>
            <div className={style.headerWelcomeMessage}>
                Welcome {supplierData?.data?.data?.[0]?.attributes?.firstName ?? ''},
            </div>
            <div className={style.headerBusinessSummary}>Here is a summary of your business</div>
        </div>
    );
};

const ChartSkeleton = () => {
    return (
        <div className='w-full h-full p-14 relative'>
            <div className='grid grid-cols-8 gap-4 w-full h-full'>
                {[...Array(8)].map((_, index) => (
                    <div key={index} className='flex flex-col gap-4 w-full h-full items-center z-10'>
                        <div className='animate-pulse rounded-md bg-slate-200 flex-grow w-full'></div>
                        <div className='animate-pulse rounded-md bg-slate-300 h-6 w-3/4'></div>
                    </div>
                ))}
            </div>
            
            <div className='p-10 w-full h-full flex flex-col items-center justify-between absolute top-0 left-0 right-0'>
                <div className='h-1 bg-slate-100 mb-2 w-full animate-pulse'></div>
                <div className='h-1 bg-slate-100 mb-2 w-full animate-pulse'></div>
                <div className='h-1 bg-slate-100 mb-2 w-full animate-pulse'></div>
                <div className='h-1 bg-slate-100 mb-2 w-full animate-pulse'></div>
                <div className='h-1 bg-slate-100 mb-2 w-full animate-pulse'></div>
                <div className='h-1 bg-slate-100 mb-2 w-full opacity-0'></div>
            </div>
        </div>
    );
};

const ParentRegChartCustomTooltip = ({ active, payload }: {active: any, payload: any}) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          border: '1px solid #ccc',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        }} className='bg-slate-500 text-purple-50 font-semibold p-4 rounded-md'>
          <p>{`Timeframe: ${payload[0]?.payload.name}`}</p>
          <p>{`Registrations: ${payload[0]?.value}`}</p>
        </div>
      );
    }
  
    return null;
};

const EnquiresChartCustomTooltip = ({ active, payload }: {active: any, payload: any}) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          border: '1px solid #ccc',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        }} className='bg-slate-500 text-purple-50 font-semibold p-4 rounded-md'>
          <p>{`Timeframe: ${payload[0]?.payload.name}`}</p>
          <p>{`Parents: ${payload[0]?.value ?? 0}`}</p>
          <p>{`Partners: ${payload[1]?.value ?? 0}`}</p>
        </div>
      );
    }
  
    return null;
};

const CalendarComponent = () => {
    const { meetingsData } = DashboardViewModel();

    // const handleDateChange = (date: Value | null) => {
    //     setSelectedDate(date);
    // };

    return (
        <div className="flex flex-col items-center gap-8 p-6">
            {/* Meetings Section */}
            <div className="max-w-md">
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold mb-4">Classes</h2>
                    <p className="text-md mb-4 text-[#334155] font-semibold">View All</p>
                </div>
                <ul className="space-y-4">
                    {meetingsData.length > 0 ? (
                        meetingsData.map((meeting: any, index: number) => (
                            <li
                                key={index}
                                className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow"
                            >
                                <div>
                                    <h3 className="font-medium text-lg">{meeting.title}</h3>
                                    <p className="text-sm text-gray-600">{meeting.time}</p>
                                    <p className="text-sm text-gray-600">
                                        Students: {meeting.students}
                                    </p>
                                </div>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-500">No classes for this date.</p>
                    )}
                </ul>
            </div>
        </div>
    );
};

const Dashboard = () => {
    const {
        FeesSummary,
        isLoading,
        MonthlyRevenueLastThreeMonths,
        MonthlyStudentRegistration,
        userState,
        MonthlyRevenueLastTwelveMonthsSpread,
        todayClasses,
        classData,
        schedulerData,
        parentUserRegistrationChartMode,
        onParentUserRegistrationChartModeChange,
        isParentUsersListFetching,
        parentUserRegChartData,
        enquiriesChartMode,
        onEnquiriesChartModeChange,
        isEnquiriesListFetching,
        enquiriesRegistrationChartData,
        expandedComponent,
        handleExpandComponent,
    } = DashboardViewModel();

    const handleVibrate = () => {
        navigator.vibrate([100, 50, 100, 50, 100, 50, 100, 50, 100, 50, 100, 50]);
    };

    return (
        <div className='relative h-full w-full'>
            {isLoading && <FullScreenLoader />}
            {/* <WelcomePrompt /> */}
            
            <div className='grid grid-cols-2 gap-1'>
                <div 
                    className={`rounded-md py-4 bg-slate-50 col-span-2 sm:col-span-1
                        ${expandedComponent === 'ENQUIRIES_CHART' ? 'absolute top-0 left-0 right-0 bottom-0 h-full w-full z-50' : expandedComponent === null ? 'w-full' : 'hidden'}
                    `}
                >
                    <div className='flex items-center justify-between px-3 sm:px-6'>
                        <div className='font-semibold text-md sm:text-lg'>Enquiries</div>
                        <div className='flex gap-4 sm:gap-8 items-center'>
                            <div className='flex gap-1 sm:gap-2 items-center font-semibold text-xs sm:text-sm'>
                                <div className='w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-[#455A64]'></div>
                                <div>Partners</div>
                            </div>
                            <div className='flex gap-1 sm:gap-2 items-center font-semibold text-xs sm:text-sm'>
                                <div className='w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-[#F9CB54]'></div>
                                <div>Parents</div>
                            </div>
                            <div>
                                <CustomDropdown 
                                    options={[
                                        {
                                            value: "WEEK",
                                            label: 'Week',
                                        },
                                        {
                                            value: "MONTH",
                                            label: 'Month',
                                        },
                                    ]}
                                    value={enquiriesChartMode}
                                    onChange={(value) => onEnquiriesChartModeChange(value as 'WEEK' |'MONTH')}
                                />
                            </div>
                            <div className='bg-slate-300 rounded-md p-1 cursor-pointer' onClick={() => handleExpandComponent('ENQUIRIES_CHART')}>
                                {expandedComponent === 'ENQUIRIES_CHART' ? (
                                    <>
                                        <TbArrowsMinimize size={24} className='hidden sm:block'/>  
                                        <TbArrowsMinimize size={18} className='sm:hidden'/>
                                    </>
                                ) : (
                                    <>
                                        <HiOutlineArrowsExpand size={24} className='hidden sm:block' />
                                        <HiOutlineArrowsExpand size={18} className='sm:hidden' />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`mt-8 ${expandedComponent === 'ENQUIRIES_CHART' ? 'h-[calc(100vh-200px)]' : 'h-[350px]'}`}>
                        {isEnquiriesListFetching ? <ChartSkeleton /> : (
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={enquiriesRegistrationChartData()}
                                    margin={{
                                        top: 5,
                                        right: 20,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid 
                                        stroke="#000"
                                        strokeDasharray="10 8" 
                                        horizontal={true}
                                        vertical={false}
                                        strokeOpacity={0.1}
                                    />
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" tick={{ fontSize: 12, fill: "#333" }} />
                                    <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12, fill: "#333" }} />
                                    <Tooltip content={<EnquiresChartCustomTooltip active={true} payload={enquiriesRegistrationChartData()} />} />
                                    <Bar 
                                        dataKey="partners" 
                                        fill="#455A64" 
                                        activeBar={{ fill: '#455A64a9', stroke: 'gray' }}
                                        radius={[10, 10, 10, 10]}
                                    />
                                    <Bar 
                                        dataKey="parents" 
                                        fill="#F9CB54" 
                                        activeBar={{ fill: '#F9CB54a9', stroke: 'orange' }}
                                        radius={[10, 10, 10, 10]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                </div>


                <div 
                    className={`rounded-md py-4 bg-slate-50 col-span-2 sm:col-span-1
                        ${expandedComponent === 'PARENT_REGISTRATIONS_CHART' ? 'absolute top-0 left-0 right-0 bottom-0 h-full w-full z-50' : expandedComponent === null ? 'w-full' : 'hidden'}
                    `}
                >
                    <div className='flex items-center justify-between px-3 sm:px-6'>
                        <div className='font-semibold text-md sm:text-lg truncate'>Parent User Registrations</div>
                        <div className='flex gap-4 sm:gap-8 items-center'>
                            <div className='flex gap-1 sm:gap-2 items-center font-semibold text-xs sm:text-sm'>
                                <div className='w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-[#60A5FA]'></div>
                                <div>Parent Users</div>
                            </div>
                            <div>
                                <CustomDropdown 
                                    options={[
                                        {
                                            value: "WEEK",
                                            label: 'Week',
                                        },
                                        {
                                            value: "MONTH",
                                            label: 'Month',
                                        },
                                    ]}
                                    value={parentUserRegistrationChartMode}
                                    onChange={(value) => onParentUserRegistrationChartModeChange(value as 'WEEK' |'MONTH')}
                                />
                            </div>
                            <div className='bg-slate-300 rounded-md p-1 cursor-pointer' onClick={() => handleExpandComponent('PARENT_REGISTRATIONS_CHART')}>
                                {expandedComponent === 'PARENT_REGISTRATIONS_CHART' ? (
                                    <>
                                        <TbArrowsMinimize size={24} className='hidden sm:block'/>  
                                        <TbArrowsMinimize size={18} className='sm:hidden'/>
                                    </>
                                ) : (
                                    <>
                                        <HiOutlineArrowsExpand size={24} className='hidden sm:block' />
                                        <HiOutlineArrowsExpand size={18} className='sm:hidden' />
                                    </>
                        )}
                            </div>
                        </div>
                    </div>
                    <div className={`mt-8 ${expandedComponent === 'PARENT_REGISTRATIONS_CHART' ? 'h-[calc(100vh-200px)]' : 'h-[350px]'}`}>
                        {isParentUsersListFetching ? <ChartSkeleton /> : (
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={parentUserRegChartData()}
                                    margin={{
                                        top: 5,
                                        right: 20,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid
                                        stroke="#000"
                                        strokeDasharray="10 8" 
                                        horizontal={true}
                                        vertical={false}
                                        strokeOpacity={0.1}
                                    />
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" tick={{ fontSize: 12, fill: "#333" }} />
                                    <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12, fill: "#333" }} />
                                    <Tooltip content={<ParentRegChartCustomTooltip active={true} payload={parentUserRegChartData()} />} />
                                    <Bar 
                                        dataKey="registrations" 
                                        fill="#60A5FA" 
                                        activeBar={{ fill: '#60A5FAa9', stroke: 'blue' }}
                                        radius={[10, 10, 10, 10]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                </div>
            </div>
            <div 
                className={`flex flex-col bg-slate-50 w-full rounded-md p-1 sm:p-4 my-2 sm:my-4
                    ${expandedComponent === 'SCHEDULE_CALENDAR' ? 'absolute top-0 left-0 right-0 bottom-0 h-full w-full z-50 my-0 sm:my-0 p-1 sm:p-1' : expandedComponent === null ? 'w-full' : 'hidden'}
                `}
            >
                <div className='flex items-center justify-between px-2 py-1 sm:px-6 sm:py-2'>
                    <div className='font-semibold text-md sm:text-lg'>Schedule Calendar</div>
                    <div className='bg-slate-300 rounded-md p-1 cursor-pointer' onClick={() => handleExpandComponent('SCHEDULE_CALENDAR')}>
                        {expandedComponent === 'SCHEDULE_CALENDAR' ? (
                            <>
                                <TbArrowsMinimize size={24} className='hidden sm:block'/>  
                                <TbArrowsMinimize size={18} className='sm:hidden'/>
                            </>
                        ) : (
                            <>
                                <HiOutlineArrowsExpand size={24} className='hidden sm:block' />
                                <HiOutlineArrowsExpand size={18} className='sm:hidden' />
                            </>
                        )}
                    </div>
                </div>
                <div className={`mt-4 ${expandedComponent === 'SCHEDULE_CALENDAR' ? 'h-[calc(100vh-140px)] sm:h-[calc(100vh-160px)]' : 'h-[400px]'}`}>
                    <AcademyScheduler />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
