import React, { useEffect, useMemo, useState } from 'react';
import FeePaymentViewModel from '../../view-models/fee-payment-viewmodel';
import style from './style.module.css';
import filterIcon from '../../../assets/icons/filter.png';
import sortIcon from '../../../assets/icons/sort.png';
import FilterCheckBoxItem from '../../components/pieces/filter-check-box-item';
import arrowDropdownIcon from '../../../assets/icons/arrow-dropdown.png';
import FeePaymentListItem from '../../components/pieces/fee-payment-list-item';
import {
    addMonthsToDate,
    differenceInDays,
    differenceInMonths,
} from '../../../utils/helpers/helpers';
import Loader from '../../components/bits/loader';
import PopupModal from '../../components/widgets/popup-modal';
import arrowLeftIcon from '../../../assets/icons/chevron-left.png';
import arrowRightIcon from '../../../assets/icons/chevron-right.png';
import clearIcon from '../../../assets/icons/clear.png';

import emailIcon from '../../../assets/icons/email.png';
import whatsappIcon from '../../../assets/icons/whatsapp.png';
import errorIcon from '../../../assets/icons/error.png';
import searchIcon from '../../../assets/icons/search.png';

import ExistingFeecollectionForm from '../../components/widgets/existing-fee-collection-form';
import { FeePaymentData } from '../../../models/fee/fee-payment-data';
import ChangeClassForm from '../../components/widgets/change-class-form';
import DropStudentForm from '../../components/widgets/drop-student-form';
import RecordLeaveForm from '../../components/widgets/record-leave-form';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import { Roles } from '../../../utils/auth/roles';

const FeePayment = () => {
    const {
        state,
        setOpenTab,
        feeSchedules,
        onCollectFeeClicked,
        isSuccess,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
        handleSendEmailReminder,
        handleFeeListItemCheck,
        allReceivedListItemsSelected,
        allPendingListItemsSelected,
        handleAllPendingListSelect,
        handleAllReceivedListSelect,
        openFilterInModileResponsive,
        indexOfFirstItem,
        indexOfLastItem,
        pendingFee,
        itemsPerPage,
        currentPage,
        dispatch,
        openFilter,
        feePaymentFormData,
        pendingFeeTotal,
        receivedFee,
        receivedFeeTotal,
        handleOpenFilter,
        handleFeeCollectSubmit,
        activeCollectFeeStudent,
        allClasses,
        handleFeeFormInputChange,
        visibleFilter,
        toggleVisibleFilter,
        handleSentRemindersPromptModalClose,
        sentRemindersPromtModal,
        successEmailsDelivered,
        successMesssagesDelivered,
        checkedPendingFeeListItems,
        checkedReceivedFeeListItems,
        failedEmailsDelivered,
        failedMesssagesDelivered,
        FeeItemModelVisible,
        handleViewDetailsClick,
        handleAcademyFilterSelect,
        handleDisciplineFilterSelect,
        handleFeeScheduleFilterSelected,
        filteredPendingFee,
        filteredReceivedFee,
        handleBeforeEndDateFilterSelected,
        handleBeforeEndDateRangeFilterSelected,
        selectedBeforeEndDateForFilter,
        selectedAcademiesForFilter,
        selectedDisciplinesForFilter,
        selectedFeeScheduleForFilter,
        selectedBeforeEndDateRangeForFilter,
        isReceivedSortOptionsVisible,
        isPendingSortOptionsVisible,
        selectedReceivedSortOption,
        selectedPendingSortOption,
        handleReceivedSortDropBtnClick,
        handlePendingSortDropBtnClick,
        handlePendingSortOptionChange,
        handleReceivedSortOptionChange,
        leaveRecordData,
        moreActionsOpen,
        handleActionsButton,
        pendingSortDropdownRef,
        receivedSortDropDownRef,
        filterDropdownRef,
        moreActionsDropdownRef,
        handleFeeItemModalCloseClick,
        handleFeeDetailsEditBtnClick,
        isFeeDetailsModalEditing,
        editingFeeDetailsFormData,
        handleFeeDetailFormInputChange,
        handleFeeDetailsUpdate,
        handleArchieveFeeCollection,
        totalDue,
        handleChangeClassFormOpen,
        isChangeClassModalOpen,
        handleChangeClassModalClose,
        changeClassInputValue,
        handleClassChangeFormInputChange,
        handleClassChange,
        isRecordLeaveModalOpen,
        handleRecordLeaveModalClose,
        handleRecordLeaveFormInputChange,
        handleRecordLeavesFormSubmit,
        formValues,
        recordLeaveModalStudentData,
        isRecordPostSuccess,
        openRecordLeaveFormModal,
        goToStudentDetails,
        handleDropStudentModalClose,
        isDropStudentFormOpen,
        studentActiveAcademies,
        selectedAcademiesIdToDropStudentFrom,
        handleStudentDropAction,
        handleDropStudentAcademyCheck,
        checkedAcademiesToDropStudent,
        openStudentDropoutConfirmation,
        isFeeCollectionUpdating,
        isFeeCollectionarchieving,
        isStudentClassUpdating,
        isLeaveRecordPosting,
        isStudentDropping,
        userState,
        feeStudentSearchTerm,
        handleFeeStudentSearchInputChange,
        handleFeeStudentSearchInputClear,
        allDisciplines,
        feesInWords,
        feesRecievedInWords,
        balanceFeesInWords,
    } = FeePaymentViewModel();

    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState<FeePaymentData[]>([]);

    const checkBoxHandler = (option: string) => {
        const isSameOption = selectedOption === option;
        const newSelectedOption = isSameOption ? null : option;
        setSelectedOption(newSelectedOption);

        const tabToSelect = state.openTab === 1 ? pendingFee : receivedFee;

        if (newSelectedOption) {
            const sortedData = [...tabToSelect];

            switch (newSelectedOption) {
                case 'Student name A-Z':
                    sortedData.sort((a, b) =>
                        a?.attributes?.student?.data?.attributes?.firstName?.localeCompare(
                            b?.attributes?.student?.data?.attributes?.firstName
                        )
                    );
                    break;
                case 'FeePendingAmountHightoLow':
                    sortedData.sort(
                        (a, b) => (b?.attributes?.balance ?? 0) - (a?.attributes?.balance ?? 0)
                    );
                    break;
                case 'Pendingdueoldesttonewest':
                    break;
                case 'Numberofoverduedays':
                    break;

                default:
                    sortedData.sort((a, b) => a.id - b.id);
            }

            setSortBy(sortedData);
        } else {
            setSortBy([...tabToSelect]);
        }
    };

    const renderSortOptions = useMemo(() => {
        return (
            <>
                {((openFilter && state.openTab === 1) || isPendingSortOptionsVisible) && (
                    <>
                        <div
                            className={`${
                                openFilter
                                    ? ''
                                    : 'absolute flex flex-col p-2 gap-2 bg-white z-50 right-0 w-[200%] shadow-md shadow-gray-300'
                            }`}
                        >
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    className={style.checkBoxInput}
                                    name="sort"
                                    checked={selectedPendingSortOption === 3}
                                    onChange={() => handlePendingSortOptionChange(3)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Name(A-Z)
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    name="sort"
                                    className={style.checkBoxInput}
                                    checked={selectedPendingSortOption === 1}
                                    onChange={() => handlePendingSortOptionChange(1)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Fees end date (newest)
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    className={style.checkBoxInput}
                                    name="sort"
                                    checked={selectedPendingSortOption === 2}
                                    onChange={() => handlePendingSortOptionChange(2)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Fees end Date (oldest)
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    className={style.checkBoxInput}
                                    name="sort"
                                    checked={selectedPendingSortOption === 4}
                                    onChange={() => handlePendingSortOptionChange(4)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Number of overdue days (low - high)
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {((openFilter && state.openTab === 2) || isReceivedSortOptionsVisible) && (
                    <>
                        <div
                            className={`${
                                openFilter
                                    ? ''
                                    : 'absolute flex flex-col p-2 gap-2 bg-white z-50 right-0 w-[200%] shadow-md shadow-gray-300'
                            }`}
                        >
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    className={style.checkBoxInput}
                                    name="sort"
                                    checked={selectedReceivedSortOption === 4}
                                    onChange={() => handleReceivedSortOptionChange(4)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Name(A-Z)
                                </div>
                            </div>

                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    name="sort"
                                    className={style.checkBoxInput}
                                    checked={selectedReceivedSortOption === 1}
                                    onChange={() => handleReceivedSortOptionChange(1)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Fees payment date (newest)
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    name="sort"
                                    className={style.checkBoxInput}
                                    checked={selectedReceivedSortOption === 2}
                                    onChange={() => handleReceivedSortOptionChange(2)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Fees payment date (oldest)
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    className={style.checkBoxInput}
                                    name="sort"
                                    checked={selectedReceivedSortOption === 3}
                                    onChange={() => handleReceivedSortOptionChange(3)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Fees end date (nearest)
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    className={style.checkBoxInput}
                                    name="sort"
                                    checked={selectedReceivedSortOption === 5}
                                    onChange={() => handleReceivedSortOptionChange(5)}
                                />
                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                    Number of remaining days (low - high)
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }, [
        isPendingSortOptionsVisible,
        isReceivedSortOptionsVisible,
        selectedPendingSortOption,
        selectedReceivedSortOption,
        openFilter,
        state.openTab,
        visibleFilter,
    ]);

    const renderFilters = useMemo(() => {
        return (
            <div ref={filterDropdownRef}>
                <div className={style.filterLabelContentWrapper}>
                    <div
                        className={`${style.filterName} ${selectedAcademiesForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                        onClick={() => toggleVisibleFilter(1)}
                    >
                        Academy Name
                    </div>
                    {visibleFilter === 1 && (
                        <div className={style.filterItemsContainer}>
                            {state?.academies?.map((academy, index) => (
                                <FilterCheckBoxItem
                                    key={index}
                                    isChecked={selectedAcademiesForFilter.includes(academy?.id)}
                                    filterName={academy?.attributes?.name}
                                    onFilterSelected={() => handleAcademyFilterSelect(academy?.id)}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className={style.filterLabelContentWrapper}>
                    <div
                        className={`${style.filterName} ${selectedDisciplinesForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                        onClick={() => toggleVisibleFilter(2)}
                    >
                        Discipline
                    </div>
                    {visibleFilter === 2 && (
                        <div className={style.filterItemsContainer}>
                            {allDisciplines.map((discipline, index) => (
                                <FilterCheckBoxItem
                                    key={index}
                                    isChecked={selectedDisciplinesForFilter.includes(discipline.id)}
                                    filterName={
                                        allDisciplines.filter(item => item.id === discipline.id)[0]
                                            ?.attributes?.name
                                    }
                                    onFilterSelected={() =>
                                        handleDisciplineFilterSelect(discipline.id)
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className={style.filterLabelContentWrapper}>
                    <div
                        className={`${style.filterName} ${selectedFeeScheduleForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                        onClick={() => toggleVisibleFilter(3)}
                    >
                        Fee Schedule
                    </div>
                    {visibleFilter === 3 && (
                        <div className={style.filterItemsContainer}>
                            {feeSchedules.map((schedule, index) => (
                                <FilterCheckBoxItem
                                    key={index}
                                    filterName={schedule}
                                    isChecked={selectedFeeScheduleForFilter.includes(schedule)}
                                    onFilterSelected={() =>
                                        handleFeeScheduleFilterSelected(schedule)
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
                {state.openTab === 2 && (
                    <>
                        <div className={style.filterLabelContentWrapper}>
                            <div
                                className={`${style.filterName} ${selectedBeforeEndDateRangeForFilter ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                onClick={() => toggleVisibleFilter(4)}
                            >
                                End date in next
                            </div>
                            {visibleFilter === 4 && (
                                <div className={style.filterItemsContainer}>
                                    <select
                                        className={style.filterInput}
                                        value={selectedBeforeEndDateRangeForFilter}
                                        onChange={e =>
                                            handleBeforeEndDateRangeFilterSelected(e.target.value)
                                        }
                                    >
                                        <option value="">none</option>
                                        <option value="7">7 days</option>
                                        <option value="30">30 days</option>
                                        <option value="45">45 days</option>
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className={style.filterLabelContentWrapper}>
                            <div
                                className={`${style.filterName} ${selectedBeforeEndDateForFilter ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                onClick={() => toggleVisibleFilter(5)}
                            >
                                End date before
                            </div>
                            {visibleFilter === 5 && (
                                <div className={style.filterItemsContainer}>
                                    <input
                                        type="date"
                                        className={style.filterInput}
                                        value={selectedBeforeEndDateForFilter}
                                        onChange={e =>
                                            handleBeforeEndDateFilterSelected(e.target.value)
                                        }
                                    />
                                    <button
                                        onClick={() => {
                                            handleBeforeEndDateFilterSelected('');
                                        }}
                                        className={style.filterClearBtn}
                                    >
                                        cancel
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }, [
        state.academies,
        visibleFilter,
        selectedBeforeEndDateForFilter,
        state.openTab,
        selectedAcademiesForFilter,
        selectedDisciplinesForFilter,
        selectedFeeScheduleForFilter,
        selectedBeforeEndDateRangeForFilter,
        // sortDropdownRef,
        filterDropdownRef,
    ]);

    const renderMainContent = useMemo(() => {
        return (
            <div className="flex-1">
                <div
                    className={state.openTab === 1 ? style.contentContainerMain : 'hidden'}
                    id="link1"
                >
                    {/* Content for Pending Fee */}
                    <div className={`${style.feeListHeader} relative`}>
                        <div className="flex gap-4 items-center w-full">
                            <div className="sm:w-[20%] w-[20%] flex justify-between items-center">
                                <div className="items-center flex justify-start gap-2">
                                    <input
                                        type="checkbox"
                                        onChange={handleAllPendingListSelect}
                                        checked={allPendingListItemsSelected}
                                        className={style.checkBoxInput}
                                    />
                                    <span className="text-xs">Select All</span>
                                </div>

                                {userState?.userDetails?.attributes?.feePaymentAccess !==
                                    'restrictedView' && (
                                    <>
                                        {/* <button className={style.primaryBtn}>
                                        Send Invoice
                                    </button> */}
                                        <button
                                            id="reminderBtn"
                                            onClick={handleSendEmailReminder}
                                            className={`${style.outlineBtn}`}
                                        >
                                            Send Reminder
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className={`sm:w-1/2 w-[70%] ${style.headerSearchContainerMain}`}>
                                <div
                                    className={`flex justify-between ${style.headerSearchContainer}
                                    `}
                                >
                                    <div className="sm:w-4 w-[10%]">
                                        <img
                                            className={style.menuSearchIcon}
                                            src={searchIcon}
                                            alt="search"
                                        />
                                    </div>
                                    <div className="sm:w-full w-[80%]">
                                        <input
                                            className={style.searchBar}
                                            placeholder="Search student"
                                            value={feeStudentSearchTerm}
                                            onChange={handleFeeStudentSearchInputChange}
                                        />
                                    </div>
                                    <div className="sm:w-6 w-[10%]">
                                        <img
                                            src={clearIcon}
                                            className={style.menuClearInputIcon}
                                            alt="clear"
                                            onClick={handleFeeStudentSearchInputClear}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:w-[30%] sm:flex hidden justify-between items-center gap-2">
                                {userState?.user?.role?.type === Roles.AUTHENTICATED_USER && (
                                    <div className="text-sm">Total due amount: ₹{totalDue}</div>
                                )}

                                <div
                                    ref={pendingSortDropdownRef}
                                    className="relative"
                                >
                                    <div
                                        className={`${style.sortDropdownBtn}`}
                                        onClick={handlePendingSortDropBtnClick}
                                    >
                                        Sort by
                                        <img
                                            className={style.dropDownIcon}
                                            src={arrowDropdownIcon}
                                            alt="downarrow"
                                        />
                                    </div>
                                    {renderSortOptions}
                                </div>
                            </div>
                            {/* mobile responsive div */}
                            <div className="sm:hidden flex w-[20%] justify-end relative">
                                <img
                                    onClick={openFilterInModileResponsive}
                                    src={filterIcon}
                                    className="w-5 h-5 cursor-pointer"
                                    alt="filter"
                                />
                                {(selectedAcademiesForFilter.length > 0 ||
                                    selectedDisciplinesForFilter.length > 0 ||
                                    selectedFeeScheduleForFilter.length > 0) && (
                                    <div className="w-3 h-3 absolute bottom-0 right-0 bg-red-500 rounded-full"></div>
                                )}
                            </div>
                        </div>
                    </div>
                    {userState?.user?.role?.type === Roles.AUTHENTICATED_USER && (
                        <div className="w-full sm:hidden flex ml-5">
                            Total due amount: ₹{totalDue}
                        </div>
                    )}

                    <div className={style.contentContainer}>
                        {pendingFee?.map((value, index) => (
                            <FeePaymentListItem
                                key={index}
                                days={`Pending for: ${differenceInDays(
                                    new Date(value?.attributes?.dueDate),
                                    new Date()
                                )} days`}
                                feePaymentAttribute={value?.attributes}
                                handleActionsButton={() => handleActionsButton(value?.id)}
                                isActionsOpen={value?.id === moreActionsOpen}
                                onCollectFeeClicked={() => onCollectFeeClicked(value)}
                                isAllChecked={allPendingListItemsSelected}
                                handleFeeListItemCheck={() => handleFeeListItemCheck(value)}
                                isModalOpen={FeeItemModelVisible === value?.id}
                                handleViewDetailsClick={() => handleViewDetailsClick(value?.id)}
                                isFeeDue={true}
                                leaveRecordData={leaveRecordData
                                    ?.filter(item => {
                                        if (
                                            item.attributes?.student?.data?.id ===
                                                value?.attributes?.student?.data?.id &&
                                            item?.attributes?.class?.data?.id ===
                                                value?.attributes?.class?.data?.id
                                        ) {
                                            return true;
                                        }
                                    })
                                    .filter(record => {
                                        const recordDate = new Date(
                                            record.attributes.attendance_date
                                        );
                                        return (
                                            recordDate >=
                                                new Date(
                                                    value?.attributes?.fee_dates[0]?.startDate
                                                ) &&
                                            recordDate <=
                                                new Date(value?.attributes?.fee_dates[0]?.dueDate)
                                        );
                                    })}
                                dropdownRef={moreActionsDropdownRef}
                                handleFeeItemModalCloseClick={handleFeeItemModalCloseClick}
                                handleFeeDetailsEditBtnClick={() =>
                                    handleFeeDetailsEditBtnClick(value)
                                }
                                isFeeDetailsModalEditing={isFeeDetailsModalEditing}
                                editingFeeDetailsFormData={editingFeeDetailsFormData}
                                handleFeeDetailFormInputChange={handleFeeDetailFormInputChange}
                                handleFeeDetailsUpdate={() => handleFeeDetailsUpdate(value)}
                                classNotActive={
                                    !value?.attributes?.student?.data?.attributes?.classes?.data
                                        ?.map(item => item.id)
                                        .includes(value?.attributes?.class?.data?.id)
                                }
                                handleArchieveFeeCollection={() =>
                                    handleArchieveFeeCollection(value?.id)
                                }
                                handleChangeClassFormOpen={() => handleChangeClassFormOpen(value)}
                                openRecordLeaveFormModal={() =>
                                    openRecordLeaveFormModal(value?.attributes?.student?.data)
                                }
                                goToStudentDetails={goToStudentDetails}
                                openStudentDropoutConfirmation={() =>
                                    openStudentDropoutConfirmation(value?.attributes?.student?.data)
                                }
                                isViewOnly={
                                    userState?.userDetails?.attributes?.feePaymentAccess ===
                                    'restrictedView'
                                }
                            />
                        ))}
                    </div>

                    <div className={style.studentsFooterContainer}>
                        <div className={style.studentsRecordsLabel}>
                            {indexOfFirstItem + 1}-{indexOfLastItem} of {pendingFee?.length ?? 0}{' '}
                            records
                        </div>
                        <div className={style.studentsRecordsDropdownContainerWrapper}>
                            <div>Show</div>
                            <div className={style.studentsRecordsDropdownContainer}>
                                10{' '}
                                <img
                                    className={style.studentsArrowDropdownIcon}
                                    src={arrowDropdownIcon}
                                    alt="down"
                                />
                            </div>
                        </div>
                        <div className={style.studentsPaginationContainer}>
                            <img
                                className={style.studentsPaginationIcon}
                                src={arrowLeftIcon}
                                alt="arrowLeftIcon"
                                onClick={() =>
                                    dispatch({
                                        type: 'setCurrentPage',
                                        payload: currentPage == 1 ? 1 : currentPage - 1,
                                    })
                                }
                            />
                            {Array.from({
                                length: Math.ceil(pendingFeeTotal / itemsPerPage),
                            }).map((_, index) => (
                                <div
                                    className={`cursor-pointer ${
                                        index + 1 == currentPage ? 'text-black' : 'text-blue-500'
                                    }`}
                                    key={index}
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: index + 1,
                                        })
                                    }
                                >
                                    {index + 1}
                                </div>
                            ))}
                            <img
                                className={style.studentsPaginationIcon}
                                src={arrowRightIcon}
                                alt="arrowRightIcon"
                                onClick={() =>
                                    dispatch({
                                        type: 'setCurrentPage',
                                        payload:
                                            currentPage + itemsPerPage + 1 > pendingFeeTotal
                                                ? currentPage
                                                : currentPage + 1,
                                    })
                                }
                            />
                        </div>
                    </div>

                    {/* mobile responsive div */}
                    <div className="px-2 flex justify-between sm:hidden pt-2">
                        {userState?.userDetails?.attributes?.feePaymentAccess !==
                            'restrictedView' && (
                            <>
                                {/* <button className={style.primaryBtn1}>
                                Send Invoice
                            </button> */}
                                <button
                                    onClick={handleSendEmailReminder}
                                    className={style.outlineBtn1}
                                >
                                    Send Reminder
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div
                    className={state.openTab === 2 ? style.contentContainerMain : 'hidden'}
                    id="link2"
                >
                    {/* Content For Received Fee */}
                    <div className={`${style.feeListHeader} relative`}>
                        <div className="flex gap-4 items-center w-full justify-between">
                            <div className="w-[20%] justify-between flex items-center">
                                <div className="items-center flex justify-start gap-2">
                                    <input
                                        type="checkbox"
                                        className={style.checkBoxInput}
                                        onChange={handleAllReceivedListSelect}
                                    />
                                    <span className="text-xs">Select All</span>
                                </div>

                                {userState?.userDetails?.attributes?.feePaymentAccess !==
                                    'restrictedView' && (
                                    <>
                                        {/* <button className={style.primaryBtn}>
                                    Resend receipt
                                    </button> */}
                                        <button
                                            id="reminderBtn"
                                            onClick={handleSendEmailReminder}
                                            className={style.outlineBtn}
                                        >
                                            Send Reminder
                                        </button>
                                    </>
                                )}
                            </div>
                            <div
                                className={`sm:w-[60%] w-[65%] ${style.headerSearchContainerMain}`}
                            >
                                <div
                                    className={`flex justify-between ${style.headerSearchContainer}
                                    `}
                                >
                                    <div className="sm:w-4 w-[10%]">
                                        <img
                                            className={style.menuSearchIcon}
                                            src={searchIcon}
                                            alt="search"
                                        />
                                    </div>
                                    <div className="sm:w-full w-[80%]">
                                        <input
                                            className={style.searchBar}
                                            placeholder="Search student"
                                            value={feeStudentSearchTerm}
                                            onChange={handleFeeStudentSearchInputChange}
                                        />
                                    </div>
                                    <div className="sm:w-6 w-[10%]">
                                        <img
                                            src={clearIcon}
                                            className={style.menuClearInputIcon}
                                            alt="clear"
                                            onClick={handleFeeStudentSearchInputClear}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                ref={receivedSortDropDownRef}
                                className="relative"
                            >
                                <div
                                    className={`justify-end ${style.sortDropdownBtn}`}
                                    onClick={handleReceivedSortDropBtnClick}
                                >
                                    Sort by
                                    <img
                                        className={style.dropDownIcon}
                                        src={arrowDropdownIcon}
                                        alt="downarrow"
                                    />
                                </div>
                                {renderSortOptions}
                            </div>
                            {/* mobile responsive div */}
                            <div className="sm:hidden flex w-[20%] justify-end">
                                <img
                                    onClick={openFilterInModileResponsive}
                                    src={filterIcon}
                                    className="w-5 h-5 cursor-pointer"
                                    alt="filter"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.contentContainer}>
                        {receivedFee?.map((value, index) => (
                            <FeePaymentListItem
                                key={index}
                                handleActionsButton={() => handleActionsButton(value?.id)}
                                isActionsOpen={value?.id === moreActionsOpen}
                                days={`Due in: ${differenceInDays(
                                    new Date(value.attributes.dueDate),
                                    new Date()
                                )} days`}
                                feePaymentAttribute={value?.attributes}
                                onCollectFeeClicked={() => onCollectFeeClicked(value)}
                                isAllChecked={allReceivedListItemsSelected}
                                handleFeeListItemCheck={() => handleFeeListItemCheck(value)}
                                isModalOpen={FeeItemModelVisible === value?.id}
                                handleViewDetailsClick={() => handleViewDetailsClick(value?.id)}
                                isFeeDue={false}
                                dropdownRef={moreActionsDropdownRef}
                                handleFeeItemModalCloseClick={handleFeeItemModalCloseClick}
                                handleFeeDetailsEditBtnClick={() =>
                                    handleFeeDetailsEditBtnClick(value)
                                }
                                isFeeDetailsModalEditing={isFeeDetailsModalEditing}
                                editingFeeDetailsFormData={editingFeeDetailsFormData}
                                handleFeeDetailFormInputChange={handleFeeDetailFormInputChange}
                                handleFeeDetailsUpdate={() => handleFeeDetailsUpdate(value)}
                                classNotActive={
                                    !value?.attributes?.student?.data?.attributes?.classes?.data
                                        ?.map(item => item.id)
                                        .includes(value?.attributes?.class?.data?.id)
                                }
                                handleArchieveFeeCollection={() =>
                                    handleArchieveFeeCollection(value?.id)
                                }
                                handleChangeClassFormOpen={() => handleChangeClassFormOpen(value)}
                                openRecordLeaveFormModal={() =>
                                    openRecordLeaveFormModal(value?.attributes?.student?.data)
                                }
                                goToStudentDetails={goToStudentDetails}
                                openStudentDropoutConfirmation={() =>
                                    openStudentDropoutConfirmation(value?.attributes?.student?.data)
                                }
                                isViewOnly={
                                    userState?.userDetails?.attributes?.feePaymentAccess ===
                                    'restrictedView'
                                }
                            />
                        ))}
                    </div>{' '}
                    <div>
                        <div className={style.studentsFooterContainer}>
                            <div className={style.studentsRecordsLabel}>
                                {indexOfFirstItem + 1}-{indexOfLastItem} of{' '}
                                {receivedFee?.length ?? 0} records
                            </div>
                            <div className={style.studentsRecordsDropdownContainerWrapper}>
                                <div>Show</div>
                                <div className={style.studentsRecordsDropdownContainer}>
                                    10{' '}
                                    <img
                                        className={style.studentsArrowDropdownIcon}
                                        src={arrowDropdownIcon}
                                        alt="down"
                                    />
                                </div>
                            </div>
                            <div className={style.studentsPaginationContainer}>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowLeftIcon}
                                    alt="arrowLeftIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: currentPage == 1 ? 1 : currentPage - 1,
                                        })
                                    }
                                />

                                {Array.from({
                                    length: Math.ceil(receivedFeeTotal / itemsPerPage),
                                }).map((_, index) => (
                                    <div
                                        className={`${
                                            index + 1 == currentPage
                                                ? 'text-black cursor-default'
                                                : 'text-blue-500 cursor-pointer'
                                        }`}
                                        key={index}
                                        onClick={() =>
                                            dispatch({
                                                type: 'setCurrentPage',
                                                payload: index + 1,
                                            })
                                        }
                                    >
                                        {index + 1}
                                    </div>
                                ))}
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowRightIcon}
                                    alt="arrowRightIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload:
                                                currentPage + itemsPerPage + 1 > receivedFeeTotal
                                                    ? currentPage
                                                    : currentPage + 1,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {/* mobile responsive div */}
                    <div className="px-2 flex justify-between sm:hidden pt-2 w-full mx-auto">
                        {userState?.userDetails?.attributes?.feePaymentAccess !==
                            'restrictedView' && (
                            <>
                                {/* <button className={style.primaryBtn1}>
                                    Resend Receipt
                                </button> */}
                                <button
                                    onClick={handleSendEmailReminder}
                                    className="outline-primary text-primary text-xs px-4 py-2 outline rounded sm:hidden"
                                >
                                    Send Reminder
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }, [
        state.pendingFee,
        state.receivedFee,
        state.openTab,
        allPendingListItemsSelected,
        allReceivedListItemsSelected,
        sortBy,
        FeeItemModelVisible,
        filteredPendingFee,
        filteredReceivedFee,
        feeStudentSearchTerm,
    ]);

    return (
        <>
            {(isFeeCollectionUpdating ||
                isFeeCollectionarchieving ||
                isStudentClassUpdating ||
                isLeaveRecordPosting ||
                isStudentDropping) && <FullScreenLoader />}
            {userState?.userDetails?.attributes?.feePaymentAccess !== 'noAccess' ||
            userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                <div className={style.container}>
                    {/* Tabs */}
                    <ul
                        className={style.tabListContainer}
                        role="tablist"
                    >
                        <li
                            className={`w-auto sm:w-36 h-16 rounded-md p-4 transition-all cursor-pointer ${
                                state.openTab === 1
                                    ? 'bg-white shadow-xl border-2 border-gray-500'
                                    : 'bg-transparent border-2 border-gray-300 scale-95'
                            }`}
                        >
                            <a
                                id="pendingFeeTab"
                                className={style.tabListItemLink}
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'setCurrentPage',
                                        payload: 1,
                                    });
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Pending Fee
                            </a>
                        </li>
                        <li
                            className={`w-auto sm:w-36 h-16 rounded-md p-4 transition-all cursor-pointer ${
                                state.openTab === 2
                                    ? 'bg-white shadow-2xl border-2 border-gray-500'
                                    : 'bg-transparent border-2 border-gray-300 scale-95'
                            }`}
                        >
                            <a
                                id="receivedFeeTab"
                                // className={style.tabListItemLink}
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'setCurrentPage',
                                        payload: 1,
                                    });
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Received Fee
                            </a>
                        </li>
                    </ul>
                    {/* Main Content */}
                    <div className={style.mainContentConatiner}>
                        <div className={style.filtersContainer}>
                            <div className={style.filterTitle}>
                                Filters
                                <img
                                    className={style.filterIcon}
                                    src={filterIcon}
                                    alt="filter"
                                />
                            </div>
                            {state.academies.length > 0 && renderFilters}
                        </div>
                        {isSuccess ? (
                            renderMainContent
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <AccessDeniedPage />
            )}

            <PopupModal
                handleModalClose={handleFeeCollectionModalClose}
                isOpen={isFeeCollectionModalOpen}
            >
                <ExistingFeecollectionForm
                    activeCollectFeeStudent={activeCollectFeeStudent}
                    feePaymentFormData={feePaymentFormData}
                    handleFeeCollectSubmit={handleFeeCollectSubmit}
                    allClasses={allClasses}
                    handleFeeFormInputChange={handleFeeFormInputChange}
                    leaveRecordData={leaveRecordData
                        ?.filter(item => {
                            if (
                                item.attributes?.student?.data?.id === feePaymentFormData.student &&
                                item?.attributes?.class?.data?.id === feePaymentFormData.class
                            ) {
                                return true;
                            }
                        })
                        .filter(record => {
                            const recordDate = new Date(record.attributes.attendance_date);
                            return (
                                recordDate >=
                                    new Date(
                                        activeCollectFeeStudent?.attributes?.fee_dates[0]
                                            ?.startDate ?? ''
                                    ) &&
                                recordDate <=
                                    new Date(
                                        activeCollectFeeStudent?.attributes?.fee_dates[0]
                                            ?.dueDate ?? ''
                                    )
                            );
                        })}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleChangeClassModalClose}
                isOpen={isChangeClassModalOpen}
                background="white"
                title="Change Class"
            >
                <ChangeClassForm
                    activeCollectFeeStudent={activeCollectFeeStudent}
                    allClasses={allClasses}
                    changeClassInputValue={changeClassInputValue}
                    handleClassChangeFormInputChange={handleClassChangeFormInputChange}
                    handleClassChange={handleClassChange}
                />
            </PopupModal>

            <PopupModal
                isOpen={isRecordLeaveModalOpen}
                handleModalClose={handleRecordLeaveModalClose}
            >
                <RecordLeaveForm
                    handleInputChange={handleRecordLeaveFormInputChange}
                    handleFormSubmit={handleRecordLeavesFormSubmit}
                    formValues={formValues}
                    studentData={recordLeaveModalStudentData}
                    isRecordPostSuccess={isRecordPostSuccess}
                />
            </PopupModal>
            <PopupModal
                handleModalClose={handleDropStudentModalClose}
                isOpen={isDropStudentFormOpen}
                background="white"
                title="Drop Student"
            >
                <DropStudentForm
                    studentActiveAcademies={studentActiveAcademies}
                    handleStudentDropAction={handleStudentDropAction}
                    handleDropStudentAcademyCheck={handleDropStudentAcademyCheck}
                    checkedAcademiesToDropStudent={checkedAcademiesToDropStudent}
                />
            </PopupModal>

            <PopupModal
                isOpen={sentRemindersPromtModal}
                handleModalClose={handleSentRemindersPromptModalClose}
            >
                <div className={style.sentRemindersPromtModalContainer}>
                    <div className={style.sentRemindersPromtTitle}>
                        Reminders sent successfully to:
                    </div>
                    <div className={style.studentNameListContainer}>
                        {state.openTab === 1
                            ? checkedPendingFeeListItems
                                  ?.filter(value =>
                                      value?.attributes?.student?.data?.attributes?.classes?.data
                                          ?.map(item => item.id)
                                          .includes(value?.attributes?.class?.data?.id)
                                  )
                                  ?.map((item, index) => (
                                      <div
                                          className={style.studentNameBox}
                                          key={index}
                                      >
                                          {item?.attributes?.student?.data?.attributes?.firstName}
                                          <div className={style.iconsContainer}>
                                              {successEmailsDelivered.some(
                                                  name =>
                                                      name ===
                                                      item?.attributes?.student?.data?.attributes
                                                          ?.firstName
                                              ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={emailIcon}
                                                      alt="email icon"
                                                  />
                                              ) : failedEmailsDelivered.some(
                                                    name =>
                                                        name ===
                                                        item?.attributes?.student?.data?.attributes
                                                            ?.firstName
                                                ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={errorIcon}
                                                      alt="error icon"
                                                  />
                                              ) : (
                                                  <Loader />
                                              )}
                                              {successMesssagesDelivered.some(
                                                  name =>
                                                      name ===
                                                      item?.attributes?.student?.data?.attributes
                                                          ?.firstName
                                              ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={whatsappIcon}
                                                      alt="whatsapp icon"
                                                  />
                                              ) : failedMesssagesDelivered.some(
                                                    name =>
                                                        name ===
                                                        item?.attributes?.student?.data?.attributes
                                                            ?.firstName
                                                ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={errorIcon}
                                                      alt="error icon"
                                                  />
                                              ) : (
                                                  <Loader />
                                              )}
                                          </div>
                                      </div>
                                  ))
                            : checkedReceivedFeeListItems
                                  ?.filter(value =>
                                      value?.attributes?.student?.data?.attributes?.classes?.data
                                          ?.map(item => item.id)
                                          .includes(value?.attributes?.class?.data?.id)
                                  )
                                  ?.map((item, index) => (
                                      <div
                                          className={style.studentNameBox}
                                          key={index}
                                      >
                                          {item?.attributes?.student?.data?.attributes?.firstName}
                                          <div className={style.iconsContainer}>
                                              {successEmailsDelivered.some(
                                                  name =>
                                                      name ===
                                                      item?.attributes?.student?.data?.attributes
                                                          ?.firstName
                                              ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={emailIcon}
                                                      alt="email icon"
                                                  />
                                              ) : failedEmailsDelivered.some(
                                                    name =>
                                                        name ===
                                                        item?.attributes?.student?.data?.attributes
                                                            ?.firstName
                                                ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={errorIcon}
                                                      alt="error icon"
                                                  />
                                              ) : (
                                                  <Loader />
                                              )}
                                              {successMesssagesDelivered.some(
                                                  name =>
                                                      name ===
                                                      item?.attributes?.student?.data?.attributes
                                                          ?.firstName
                                              ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={whatsappIcon}
                                                      alt="whatsapp icon"
                                                  />
                                              ) : failedMesssagesDelivered.some(
                                                    name =>
                                                        name ===
                                                        item?.attributes?.student?.data?.attributes
                                                            ?.firstName
                                                ) ? (
                                                  <img
                                                      className={style.studentNameBoxIcon}
                                                      src={errorIcon}
                                                      alt="error icon"
                                                  />
                                              ) : (
                                                  <Loader />
                                              )}
                                          </div>
                                      </div>
                                  ))}
                    </div>
                </div>
            </PopupModal>

            <PopupModal
                handleModalClose={handleOpenFilter}
                isOpen={openFilter}
                background="white"
            >
                <div className="w-[80vw] h-[80vh] p-2 flex flex-col gap-4">
                    <div className="p-4 border rounded">
                        <div className={style.filterTitle}>
                            Filters
                            <img
                                className={style.filterIcon}
                                src={filterIcon}
                                alt="filter"
                            />
                        </div>
                        {renderFilters}
                    </div>
                    <hr />
                    <div className="p-4 border rounded">
                        <div className={style.filterTitle}>
                            Sort by
                            <img
                                className={style.filterIcon}
                                src={sortIcon}
                                alt="filter"
                            />
                        </div>
                        {renderSortOptions}
                    </div>
                </div>
            </PopupModal>
        </>
    );
};

export default FeePayment;
