import React from 'react';
import style from './style.module.css';

export type Props = {
    filterName: string;
    onFilterSelected: () => void;
    isChecked?: boolean;
};

const FilterCheckBoxItem = ({ filterName, onFilterSelected, isChecked }: Props) => {
    return (
        <div className={style.filterCheckboxContainer}>
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onFilterSelected}
            />
            <div className={style.filterCheckboxLabel}>{filterName}</div>
        </div>
    );
};

export default React.memo(FilterCheckBoxItem);
