import React, { ChangeEvent, useState } from 'react';
import style from './style.module.css';
import Select from 'react-select';
import { EnquiryFormDataType } from '../../../view-models/enquiries-view-model';

interface NewEnquiryFormProps {
    mapAcademiesToOptions: () => [] | { value: number; label: string }[];
    mapDiciplinesOfSelectedAcademiesToOptions: () => [] | { value: number; label: string }[];
    mapClassesOfSelectedDisciplinesToOptions: () => [] | { value: number; label: string }[];
    handleInputChange: (property: string, value: string | number[] | boolean | null) => void;
    enquiryFormData: EnquiryFormDataType;
    handleEnquiryFormSubmit: (e: React.FormEvent) => void;
    isEdit?: boolean;
}

const NewEnquiryForm: React.FC<NewEnquiryFormProps> = ({
    mapAcademiesToOptions,
    mapDiciplinesOfSelectedAcademiesToOptions,
    mapClassesOfSelectedDisciplinesToOptions,
    handleInputChange,
    enquiryFormData,
    handleEnquiryFormSubmit,
    isEdit,
}) => {

    return (
        <div className={style.formWrapper}>
            <form onSubmit={handleEnquiryFormSubmit}>
                <div className="inline-flex gap-1">
                    <div>
                        <div className={style.formInputLabel}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            First name:
                        </div>
                        <input
                            required
                            className={style.formInput}
                            placeholder="Enter first name"
                            value={enquiryFormData.firstName}
                            onChange={e => handleInputChange('firstName', e.target.value)}
                        />
                    </div>
                    <div>
                        <div className={style.formInputLabel}>Middle name:</div>
                        <input
                            className={style.formInput}
                            placeholder="Enter middle name"
                            value={enquiryFormData.middleName}
                            onChange={e => handleInputChange('middleName', e.target.value)}
                        />
                    </div>
                    <div>
                        <div className={style.formInputLabel}>Last name:</div>
                        <input
                            className={style.formInput}
                            placeholder="Enter last name"
                            value={enquiryFormData.lastName}
                            onChange={e => handleInputChange('lastName', e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <div className={style.formInputLabel}>Parent name:</div>
                    <input
                        className={style.formInput}
                        placeholder="Enter parent name"
                        value={enquiryFormData.parentName}
                        onChange={e => handleInputChange('parentName', e.target.value)}
                    />
                </div>
                <div>
                    <div className={style.formInputLabel}>
                        <span className={style.requiredFieldLabelPrompt}>*</span>
                        Contact number:
                    </div>
                    <input
                        required
                        className={style.formInput}
                        pattern='[0-9]{10}'
                        type="tel"
                        placeholder="Enter contact number"
                        value={enquiryFormData.contactNo}
                        onChange={e => handleInputChange('contactNo', e.target.value)}
                        title="Please enter a valid 10-digit contact number"
                    />
                </div>
                <div>
                    <div className={style.formInputLabel}>Email ID:</div>
                    <input
                        className={style.formInput}
                        type="email"
                        placeholder="Enter email address"
                        value={enquiryFormData.email}
                        onChange={e => handleInputChange('email', e.target.value)}
                    />
                </div>
                <div>
                    <div className={style.formInputLabel}>
                        <span className={style.requiredFieldLabelPrompt}>*</span>
                        Academy:
                    </div>
                    <Select
                        isMulti
                        required
                        options={mapAcademiesToOptions()}
                        className={style.formInput}
                        classNamePrefix="Select Acadamy"
                        onChange={item =>
                            handleInputChange(
                                'academies',
                                item.map(({ value }) => value)
                            )
                        }
                        value={mapAcademiesToOptions()?.filter(option =>
                            enquiryFormData.academies.includes(option.value)
                        )}
                    />
                </div>
                <div>
                    <div className={style.formInputLabel}>
                        <span className={style.requiredFieldLabelPrompt}>*</span>
                        Discipline:
                    </div>
                    <Select
                        isMulti
                        required
                        options={mapDiciplinesOfSelectedAcademiesToOptions()}
                        className={style.formInput}
                        classNamePrefix="Select Discipline"
                        onChange={selectedOptions => {
                            const selectedValues = selectedOptions.map(option => option.value);
                            handleInputChange('discipline', selectedValues);
                        }}
                        value={mapDiciplinesOfSelectedAcademiesToOptions()?.filter(option =>
                            enquiryFormData.discipline.includes(option.value)
                        )}
                    />
                </div>
                <div>
                    <div className={style.formInputLabel}>
                        <span className={style.requiredFieldLabelPrompt}>*</span>
                        Class:
                    </div>
                    <Select
                        isMulti
                        required
                        options={mapClassesOfSelectedDisciplinesToOptions()}
                        className={style.formInput}
                        classNamePrefix="Select Class"
                        onChange={item =>
                            handleInputChange(
                                'classesInterested',
                                item.map(({ value }) => value)
                            )
                        }
                        value={mapClassesOfSelectedDisciplinesToOptions()?.filter(option =>
                            enquiryFormData.classesInterested.includes(option.value)
                        )}
                    />
                </div>
                {((isEdit && enquiryFormData.remarks !== '') || !isEdit) && (
                    <div>
                        <div className={style.formInputLabel}>Remarks:</div>
                        <textarea
                            className={style.formInput}
                            disabled={isEdit}
                            placeholder="Enter remarks if any"
                            value={enquiryFormData.remarks}
                            onChange={e => handleInputChange('remarks', e.target.value)}
                        />
                    </div>
                )}
                <div className='mt-2 p-2 border rounded border-gray-200 w-full transition-all'>
                    <div className='flex items-center justify-between'>
                        <div className='text-sm'>Book demo class</div>
                        <input 
                            type='checkbox' 
                            className='w-5 h-5 rounded-full mr-2 focus:ring-0 text-slate-500 '
                            onChange={e => {
                                handleInputChange('isDemoChecked', e.target.checked);
                                if (e.target.checked === false) {
                                    handleInputChange('demoDate', null);
                                    handleInputChange('demoTime', null);
                                }
                            }}
                            checked={enquiryFormData.isDemoChecked}
                        />
                    </div>
                    <div
                        className={`${
                                enquiryFormData.isDemoChecked ? 'h-auto opacity-100 mt-2' : 'h-0 opacity-0 m-0'
                            } overflow-hidden transition-all duration-300 ease-in-out w-full flex flex-col gap-1`}
                    >
                        <div className='flex items-center gap-2 p-1 px-4 justify-between bg-slate-50 rounded'>
                            <div>Date</div>
                            <input 
                                type='date'
                                className='w-[70%] rounded-md border-gray-200 py-1 px-3' 
                                required={enquiryFormData.isDemoChecked ? true : false}
                                onChange={e => handleInputChange('demoDate', e.target.value)}
                                value={enquiryFormData?.demoDate ?? ''}
                            />
                        </div>
                        <div className='flex items-center gap-2 p-1 px-4 justify-between bg-slate-50 rounded'>
                            <div>Time</div>
                            <input 
                                type='time'
                                className='w-[70%] rounded-md border-gray-200 py-1 px-3' 
                                required={enquiryFormData.isDemoChecked ? true : false}
                                onChange={e => handleInputChange('demoTime', e.target.value)}
                                value={enquiryFormData?.demoTime ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.submitBtnContainer}>
                    <button
                        type="submit"
                        className={style.formSubmitBtn}
                    >
                        {isEdit ? 'Update Enquiry' : 'Add Enquiry'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default NewEnquiryForm;
