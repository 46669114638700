// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_overLay__QJGZO {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 100000;
    background-color: #000000b8
}
.style_Container__hPHgC {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10000;
    max-height: 98%;
    width: 95vw;
    max-width: 96%;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    overflow-y: scroll;
    scroll-behavior: smooth;
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}
@media (min-width: 640px) {
    .style_Container__hPHgC {
        max-height: 90%;
        width: auto;
        max-width: 90%
    }
}
.style_ContainerFullScreen__kJ0S\\+ {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10000;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    overflow-y: scroll;
    scroll-behavior: smooth;
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}
.style_hiddenTopBar__2y3ZA {
    padding: 0px
}
.style_visibleTopBar__yl-ak {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem
}
/* .closeBtnContainer{
    @apply 
} */
.style_topBarContainer__zZFNJ {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between
}
.style_topBarTitle__0FXnG {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600
}
@media (min-width: 640px) {
    .style_topBarTitle__0FXnG {
        font-size: 1.5rem;
        line-height: 2rem
    }
}
.style_closeButton__Gcv8k {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.25rem
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/popup-modal/style.module.css"],"names":[],"mappings":"AACI;IAAA,eAAqE;IAArE,QAAqE;IAArE,SAAqE;IAArE,WAAqE;IAArE,UAAqE;IAArE,eAAqE;IAArE;AAAqE;AAGrE;IAAA,eAAiN;IAAjN,QAAiN;IAAjN,SAAiN;IAAjN,cAAiN;IAAjN,eAAiN;IAAjN,WAAiN;IAAjN,cAAiN;IAAjN,sBAAiN;IAAjN,sBAAiN;IAAjN,+LAAiN;IAAjN,kBAAiN;IAAjN,uBAAiN;IAAjN,sBAAiN;IAAjN,kBAAiN;IAAjN;AAAiN;AAAjN;IAAA;QAAA,eAAiN;QAAjN,WAAiN;QAAjN;IAAiN;AAAA;AAGjN;IAAA,eAAyK;IAAzK,QAAyK;IAAzK,SAAyK;IAAzK,cAAyK;IAAzK,gBAAyK;IAAzK,WAAyK;IAAzK,eAAyK;IAAzK,sBAAyK;IAAzK,sBAAyK;IAAzK,+LAAyK;IAAzK,kBAAyK;IAAzK,uBAAyK;IAAzK,sBAAyK;IAAzK,kBAAyK;IAAzK;AAAyK;AAGzK;IAAA;AAAS;AAGT;IAAA,oBAAe;IAAf,qBAAe;IAAf;AAAe;AAEnB;;GAEG;AAEC;IAAA,aAA+C;IAA/C,WAA+C;IAA/C,mBAA+C;IAA/C;AAA+C;AAG/C;IAAA,mBAAoD;IAApD,sBAAoD;IAApD,oBAAoD;IAApD,eAAoD;IAApD,mBAAoD;IAApD;AAAoD;AAApD;IAAA;QAAA,iBAAoD;QAApD;IAAoD;AAAA;AAGpD;IAAA,cAAoB;IAApB,aAAoB;IAApB;AAAoB","sourcesContent":[".overLay {\n    @apply fixed top-0 left-0 bottom-0 right-0  bg-[#000000b8] z-[100000];\n}\n.Container {\n    @apply sm:w-auto w-[95vw] max-w-[96%] max-h-[98%] sm:max-w-[90%] sm:max-h-[90%] overflow-y-scroll fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[10000] bg-slate-200 rounded scroll-smooth;\n}\n.ContainerFullScreen {\n    @apply w-auto max-w-[100%] max-h-[100%] overflow-y-scroll fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[10000] bg-slate-200 rounded scroll-smooth;\n}\n.hiddenTopBar {\n    @apply p-0\n}\n.visibleTopBar {\n    @apply px-2 pb-4\n}\n/* .closeBtnContainer{\n    @apply \n} */\n.topBarContainer {\n    @apply flex justify-between w-full items-center;\n}\n.topBarTitle {\n    @apply text-base sm:text-2xl font-semibold pl-2 py-2;\n}\n.closeButton {\n    @apply h-10 w-10 p-1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overLay": `style_overLay__QJGZO`,
	"Container": `style_Container__hPHgC`,
	"ContainerFullScreen": `style_ContainerFullScreen__kJ0S+`,
	"hiddenTopBar": `style_hiddenTopBar__2y3ZA`,
	"visibleTopBar": `style_visibleTopBar__yl-ak`,
	"topBarContainer": `style_topBarContainer__zZFNJ`,
	"topBarTitle": `style_topBarTitle__0FXnG`,
	"closeButton": `style_closeButton__Gcv8k`
};
export default ___CSS_LOADER_EXPORT___;
