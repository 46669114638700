import React from 'react';
import style from './style.module.css';
import searchIcon from '../../../assets/icons/search.png';
import uploadIcon from '../../../assets/icons/upload.png';
import ScheduleDetailsViewmodel from '../../view-models/schedule-details-viewmodel';
import arrowDropdownIcon from '../../../assets/icons/arrow-dropdown.png';
import filterIcon from '../../../assets/icons/filter.png';
import StudentProfileIcon from '../../../assets/icons/user.png';
import {
    areDatesSame,
    convertTo12HourFormat,
    formatTime,
    getFormattedWeekDay,
    getISTTime,
    getWeekDayForDateFromTimings,
} from '../../../utils/helpers/helpers';
import AttendanceListItem from '../../components/pieces/attendance-list-item';
import refreshIcon from '../../../assets/icons/refresh.png';
import Loader from '../../components/bits/loader';
import PopupModal from '../../components/widgets/popup-modal';
import { IoChevronBack } from 'react-icons/io5';
import PhotoUploadForm from '../../components/widgets/photo-upload-form';
import AiAttendanceListItem from '../../components/pieces/ai-attendance-list-item';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import AttendanceStudentEditForm from '../../components/widgets/attendance-student-edit-form.tsx';
import NewStudentMininalForm from '../../components/widgets/new-student-minimal-form';
import CustomCarousel from '../../components/widgets/custom-carousel';
import GuestStudentMarkAttendance from '../../actions/guest-student-mark-attendance';

const AttendanceListSkeliton = () => {
    return (
        <div className="inline-flex p-2 gap-4 bg-slate-100 rounded">
            {/* Skeleton effect for the avatar */}
            <div className="bg-slate-200 rounded-full h-12 w-12 animate-pulse"></div>
            {/* Skeleton effect for the text content */}
            <div className="flex flex-col gap-2 p-1">
                <div className="h-4 w-16 bg-slate-200 animate-pulse rounded-md"></div>
                <div className="h-4 w-48 bg-slate-200 animate-pulse rounded-md"></div>
            </div>
        </div>
    );
};

const ScheduleDetails = () => {
    const {
        classDetails,
        selectedDate,
        attendanceList,
        handleEditAttendance,
        isAttendanceUpdating,
        isAttendanceFetchSuccess,
        isAttendancePosting,
        isAttendanceFetching,
        setIsSearchInputOnFocus,
        searchTerm,
        handlesearchInputChange,
        isStudentDataFetchLoading,
        classStudentDetails,
        dropSortBy,
        selectedOption,
        checkBoxHandler,
        setDropSortBy,
        handleFilterModalClose,
        isfilterModalOpen,
        handleFilterBtnClick,
        handleAddAttendance,
        goScheduleDetailsBase,
        userState,
        isPhotoUploadFormOpen,
        handlePhotoUploadBtnClick,
        handlePhotoUploadFormClose,
        isLoading,
        previewURLs,
        removeSelectedImage,
        handleImageChange,
        uploadSelectedImage,
        aiModelResponseCorrected,
        handleStudentEditBtnClick,
        handleStudentEditFormClose,
        activeStudentEdit,
        handleCardExpandBtnClick,
        expandedPresentByAiCards,
        expandedPresentByUserCards,
        expandedAbsentCards,
        expandedUnIdentifiedCards,
        handleAttendanceAiResponseCorrection,
        setAiModelResponseCorrected,
        isStudentInfoEditFormOpen,
        studentSearchTerm,
        isStudentSearchInputOnFocus,
        setIsStudentSearchInputOnFocus,
        handleStudentSearchInputChange,
        isEditStudentDataFetchLoading,
        editStudentSuggestionListData,
        onEditStudentSearchListItemClick,
        newStudentMinimalFormData,
        handleAddStudentInputChange,
        handleAddNewStudent,
        handleAddStudentBtnClick,
        isNewStudentModalOpen,
        handleNewStudentModalClose,
        activeAddStudentFormIndex,
        selectedStudentInEditStudentForm,
        isNewStudentAdding,
        triggerFileInput,
        fileInputRef,
        AiAttendanceResponse,
        handleViewAiResponseBtnClick,
        isAiResponsePageOpen,
        currentImageIndex,
        handlePrev,
        handleNext,
        handleDotClick,
        handleImageIndexClick,
        indexToPhotoNumberConverter,
        handleAiResponseDataRefetch,
        listOfAllStudents,
        totalAbsent,
        totalPresent,
        isAddGuestStudentAttendanceModalOpen,
        handleAddGuestStudentAttendanceModalClose,
        handleAddGuestStudentAttendanceModalOpen,
        timings,
        activeStudentsMarkingAttendance,
        notLatestAttendanceList,
        handleAddMorePhotoBtnClick,
        studentsMarkedPresentByUser,
        handleMoveStudentFromAbsentToPresent,
        handleMoveStudentFromPresentToAbsent,
    } = ScheduleDetailsViewmodel();
    
    return (
        <>
            {isNewStudentAdding && <FullScreenLoader />}
            {isAiResponsePageOpen ? (
                <>
                    <div className="text-2xl text-blue-700 font-semibold p-2">
                        {classDetails?.data?.data?.attributes?.class_name ?? ''}
                    </div>
                    <div className="sm:flex gap-4 flex sm:flex-row flex-col  items-center">
                        <div className="h-[240px] sm:h-[32vw] w-[95vw] sm:w-[60vw] mb-4">
                            <CustomCarousel
                                handlePrev={handlePrev}
                                handleNext={handleNext}
                                handleDotClick={handleDotClick}
                                imageArray={aiModelResponseCorrected?.result}
                                isApiUpload={false}
                                currentImageIndex={currentImageIndex}
                                topCount={true}
                            />
                        </div>

                        <div className="h-auto sm:h-[32vw] flex flex-col mt-2 sm:mt-0 justify-between w-full sm:w-auto flex-grow">
                            <div>
                                <button 
                                    className='w-full h-8 bg-primary text-white rounded'
                                    onClick={handleAddMorePhotoBtnClick}
                                >
                                    Upload More Photos
                                </button>
                            </div>
                            <div className="mt-auto text-center sm:text-start">
                                {/* <div>Total number of students: {aiModelResponseCorrected?.max_students.length}</div> */}
                                <div className='p-2 rounded border-2 border-slate-200 mt-1'>
                                    <div>
                                        {`Total Present: ${(aiModelResponseCorrected?.present.filter(item => item[0] !== 'Unknown').length ?? 0) + studentsMarkedPresentByUser.length}`}
                                    </div>
                                    <hr className='border-2 my-1'/>
                                    <div>
                                        {`Marked By AI: ${aiModelResponseCorrected?.present.filter(item => item[0] !== 'Unknown').length ?? 0}`}
                                    </div>
                                    <div>
                                        {`Marked By User: ${studentsMarkedPresentByUser.length}`}
                                    </div>
                                </div>
                                <div className='p-2 rounded border-2 border-slate-200 mt-1'>
                                    <div>
                                            {`Total Absent: ${classStudentDetails && classStudentDetails.filter(item => !aiModelResponseCorrected?.present.map(item => item[1]).includes(item.id)).length - studentsMarkedPresentByUser.length}`}
                                    </div>
                                </div>
                                <div className='p-2 rounded border-2 border-slate-200 mt-1'>
                                    <div>
                                        Unidentified:{' '}
                                        {
                                            aiModelResponseCorrected?.present.filter(
                                                item => item[0] === 'Unknown'
                                            ).length
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="sm:grid sm:grid-cols-3 gap-4 w-full">
                        <div className="flex flex-col gap-1 border-2 border-gray-300 rounded p-1 mb-2">
                            <div className="text-center text-xl text-blue-700 font-semibold p-2">
                                {`Students Present: ${(aiModelResponseCorrected?.present.filter(item => item[0] !== 'Unknown').length ?? 0) + studentsMarkedPresentByUser.length}`}
                            </div>
                            <hr />
                            <div className="max-h-[450px] overflow-y-scroll">
                                {aiModelResponseCorrected?.present.map((item, index) => {
                                    if (item[0] !== 'Unknown') {
                                        return (
                                            <AiAttendanceListItem
                                                key={index}
                                                isImageUrl={false}
                                                imageIndex={
                                                    aiModelResponseCorrected?.present[index][4]
                                                }
                                                image={aiModelResponseCorrected.crops[index]}
                                                name={item[0]}
                                                handleImageIndexClick={handleImageIndexClick}
                                                handleCardExpandBtnClick={() =>
                                                    handleCardExpandBtnClick(index, 'presentByAi')
                                                }
                                                isExpanded={expandedPresentByAiCards.includes(index)}
                                                status="presentByAi"
                                                handleStudentEditBtnClick={() =>
                                                    handleStudentEditBtnClick(index)
                                                }
                                                isRepeated={
                                                    aiModelResponseCorrected.present.filter(
                                                        arr => arr[0] === item[0]
                                                    ).length > 1
                                                }
                                                handleIndex={indexToPhotoNumberConverter}
                                            />
                                        );
                                    }
                                })}
                                <hr className='my-2 border-2 border-gray-400'/>
                                {classStudentDetails &&
                                    classStudentDetails
                                        .filter(item => !aiModelResponseCorrected?.present.map(item => item[1]).includes(item.id))
                                        .filter(item => studentsMarkedPresentByUser.includes(item.id))
                                        .map((item, index) => (
                                            <AiAttendanceListItem
                                                key={index}
                                                isImageUrl={true}
                                                image={
                                                    item?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? 
                                                    item?.attributes?.photo?.data?.attributes?.url ?? 
                                                    StudentProfileIcon
                                                }
                                                name={` ${item?.attributes?.firstName} ${item?.attributes?.lastName ?? ''}`}
                                                handleImageIndexClick={handleImageIndexClick}
                                                handleCardExpandBtnClick={() =>
                                                    handleCardExpandBtnClick(index, 'presentByUser')
                                                }
                                                isExpanded={expandedPresentByUserCards.includes(index)}
                                                status="presentByUser"
                                                handleStudentEditBtnClick={() =>
                                                    handleStudentEditBtnClick(index)
                                                }
                                                handleIndex={indexToPhotoNumberConverter}
                                                handleMoveStudentFromPresentToAbsent={() => handleMoveStudentFromPresentToAbsent(item.id)}
                                            />
                                        ))
                                        
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 border-2 border-gray-300 rounded p-1 mb-2">
                            <div className="text-center text-xl text-blue-700 font-semibold p-2">
                                {`Students Absent: ${classStudentDetails && classStudentDetails.filter(item => !aiModelResponseCorrected?.present.map(item => item[1]).includes(item.id)).length - studentsMarkedPresentByUser.length}`}
                            </div>
                            <hr />
                            <div className="max-h-[450px] overflow-y-scroll">
                                {classStudentDetails &&
                                    classStudentDetails
                                        .filter(
                                            item => !aiModelResponseCorrected?.present.map(item => item[1]).includes(item.id)
                                        )
                                        .filter(item => !studentsMarkedPresentByUser.includes(item.id))
                                        .map((item, index) => (
                                            <AiAttendanceListItem
                                                key={index}
                                                isImageUrl={true}
                                                image={
                                                    item?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? 
                                                    item?.attributes?.photo?.data?.attributes?.url ?? 
                                                    StudentProfileIcon
                                                }
                                                name={
                                                    item?.attributes?.firstName +
                                                    ' ' +
                                                    (item?.attributes?.lastName ?? '')
                                                }
                                                handleCardExpandBtnClick={() =>
                                                    handleCardExpandBtnClick(index, 'absent')
                                                }
                                                isExpanded={expandedAbsentCards.includes(index)}
                                                status="absent"
                                                handleStudentEditBtnClick={() =>
                                                    handleStudentEditBtnClick(index)
                                                }
                                                handleMoveStudentFromAbsentToPresent={() => handleMoveStudentFromAbsentToPresent(item?.id)}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 border-2 border-gray-300 rounded p-1 mb-2">
                            <div className="text-center text-xl text-blue-700 font-semibold p-2">
                                Students Unidentified:{' '}
                                {
                                    aiModelResponseCorrected?.present.filter(
                                        item => item[0] === 'Unknown'
                                    ).length
                                }
                            </div>
                            <hr />
                            <div className="max-h-[450px] overflow-y-scroll">
                                {aiModelResponseCorrected?.present.map((item, index) => {
                                    if (item[0] === 'Unknown') {
                                        return (
                                            <AiAttendanceListItem
                                                key={index}
                                                isImageUrl={false}
                                                image={aiModelResponseCorrected?.crops[index]}
                                                name={item[0]}
                                                handleCardExpandBtnClick={() =>
                                                    handleCardExpandBtnClick(index, 'unidentified')
                                                }
                                                isExpanded={expandedUnIdentifiedCards.includes(
                                                    index
                                                )}
                                                status="unidentified"
                                                handleStudentEditBtnClick={() =>
                                                    handleStudentEditBtnClick(index)
                                                }
                                                handleAddStudentBtnClick={() =>
                                                    handleAddStudentBtnClick(index)
                                                }
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="inline-flex justify-end w-full">
                        <button
                            className="w-48 h-12 bg-primary text-white rounded font-semibold disabled:opacity-40"
                            onClick={handleAttendanceAiResponseCorrection}
                        >
                            Mark Attendance
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className={`${style.scheduleDetailHeaderWrap} relative`}>
                        <div className={style.headerTextWrapper}>
                            <div className={style.headerDateTimeWrapper}>
                                <span
                                    className="text-white flex items-center justify-center bg-[#455A64] p-2 w-[100px] rounded mr-3 hover:bg-[#374151]"
                                    onClick={() => goScheduleDetailsBase()}
                                >
                                    <IoChevronBack />
                                </span>
                                <span className={style.scheduleHeaderDateTimeLabel}>
                                    {getFormattedWeekDay(selectedDate)}{' '}
                                </span>
                                <span className={style.scheduleHeaderDateTimeLabel}>
                                    {`${convertTo12HourFormat(timings?.start) ?? ''} - ${convertTo12HourFormat(timings?.end) ?? ''}`}
                                </span>
                            </div>
                            <div className={style.scheduleHeaderLabel}>
                                {classDetails?.data?.data?.attributes?.class_name ?? ''}{' '}
                            </div>
                            <div className="absolute right-5 mb-8 sm:hidden">
                                <img
                                    src={filterIcon}
                                    alt="filter"
                                    className="w-6 h-6"
                                    onClick={handleFilterBtnClick}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-around">
                            {areDatesSame( new Date(selectedDate), new Date()) &&
                                <div className="flex items-center gap-8 mx-5">
                                    {AiAttendanceResponse && AiAttendanceResponse?.length === 0 ? (
                                        <button
                                            className="inline-flex items-center gap-2 p-2 py-1 border-2 border-primary rounded"
                                            onClick={handlePhotoUploadBtnClick}
                                        >
                                            Upload Photo
                                            <img
                                                className={style.dropDownIcon}
                                                src={uploadIcon}
                                                alt="upload icon"
                                            />
                                        </button>
                                    ) : AiAttendanceResponse && AiAttendanceResponse[0]?.attributes?.isAttendanceSubmited === false ? (
                                        AiAttendanceResponse && AiAttendanceResponse[0]?.attributes?.aiResponse === null ? (
                                            <div className="text-gray-500 cursor-wait font-semibold flex items-center gap-2">
                                                <div className="animate-pulse">
                                                    Uploaded images are being processed by AI Model{' '}
                                                </div>
                                                <img
                                                    src={refreshIcon}
                                                    className="h-6 w-6 cursor-pointer hover:scale-110 active:rotate-180 transition-all"
                                                    onClick={handleAiResponseDataRefetch}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={handleViewAiResponseBtnClick}
                                                    className="p-2 py-1 border-2 border-primary rounded"
                                                >
                                                    View AI-Based Attendance
                                                </button>
                                                <button
                                                    className="inline-flex items-center gap-2 p-2 py-1 border-2 border-primary rounded"
                                                    onClick={handleAddMorePhotoBtnClick}
                                                >
                                                    Add more images
                                                    <img
                                                        className={style.dropDownIcon}
                                                        src={uploadIcon}
                                                        alt="upload icon"
                                                    />
                                                </button>
                                            </>
                                        )
                                    ) : (
                                        <div className="text-gray-500 font-semibold">
                                            Marked using AI Model
                                        </div>
                                    )}
                                </div>
                            }
                            <div
                                className={`${style.sortDropdownBtn}  cursor-pointer w-fit ml-auto  mr-[20px]`}
                                onClick={() => setDropSortBy(!dropSortBy)}
                            >
                                Sort by: Name
                                <img
                                    className={style.dropDownIcon}
                                    src={arrowDropdownIcon}
                                    alt="downarrow"
                                />
                            </div>

                            {dropSortBy && (
                                <div className="flex flex-row gap-2 absolute mt-2   right-3 z-20  ">
                                    <input
                                        type="checkbox"
                                        name="sorting"
                                        checked={selectedOption === 'Student name A-Z'}
                                        onChange={() => checkBoxHandler('Student name A-Z')}
                                    />
                                    <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                        Student name A-Z
                                    </div>
                                    {/* Add more checkboxes for other sorting options if needed */}
                                </div>
                            )}
                        </div>
                        <div className={style.headerSearchContainerMain}>
                            <div className={`${style.headerSearchContainer} relative `}>
                                <img
                                    className={style.menuSearchIcon}
                                    src={searchIcon}
                                    alt="search"
                                />

                                <input
                                    className={style.searchBar}
                                    placeholder="Student Name"
                                    value={searchTerm}
                                    onFocus={() => setIsSearchInputOnFocus(true)} // Set isSearchInputOnFocus to true when the input is focused
                                    onBlur={() =>
                                        setTimeout(() => setIsSearchInputOnFocus(false), 200)
                                    }
                                    onChange={handlesearchInputChange}
                                />
                                <span className={style.menuSearchLoaderContainer}>
                                    {isStudentDataFetchLoading && (
                                        <div className={style.menuSearchLoader}></div>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className={style.studentCountsWrapper}>
                            <div className={style.studentAttendenceCountWrap}>
                                <div className={style.studentPresentCountBadge}>{totalPresent}</div>
                                <div className={style.studentOnLeaveCountBadge}>{totalAbsent}</div>
                                <div className={style.studentUnmarkedCountBadge}>
                                    {listOfAllStudents.length -
                                        ((totalPresent ?? 0) + (totalAbsent ?? 0))}
                                </div>
                            </div>
                            <div className={style.totalCountWrapper}>
                                Total students{' '}
                                <span className={style.totalCountLabelWrapper}>
                                    {listOfAllStudents.length}
                                </span>
                            </div>
                            {/* Temporary Commented */}
                            {/* <div className={style.actionButtonWrapper}>
                            <button className={style.actionButton}>
                                {' '}
                                All Present{' '}
                            </button>
                            <button className={style.actionButtonBorderd}>
                                {' '}
                                + Add new Student{' '}
                            </button>
                        </div> */}
                            <button
                                onClick={handleAddGuestStudentAttendanceModalOpen}
                                className={`${style.actionButtonBorderd} active:scale-95 active:text-gray-200 active:shadow-lg active:bg-gray-400 transition-all`}
                            >
                                Mark for other students
                            </button>
                        </div>
                    </div>

                    {isAttendanceFetchSuccess ? (
                        <div className={style.studentListWrapper}>
                            {listOfAllStudents.map((item, index) => (
                                <React.Fragment key={index}>
                                    <AttendanceListItem
                                        studentAttributes={item.attributes}
                                        present={
                                            attendanceList?.data?.data.find(
                                                value =>
                                                    value.attributes.attendance_date ===
                                                        selectedDate &&
                                                    value.attributes.student.data.id === item.id &&
                                                    value.attributes.present === true
                                            )
                                                ? true
                                                : false
                                        }
                                        absent={
                                            attendanceList?.data?.data.find(
                                                value =>
                                                    value.attributes.attendance_date ===
                                                        selectedDate &&
                                                    value.attributes.student.data.id === item.id &&
                                                    value.attributes.present === false
                                            )
                                                ? true
                                                : false
                                        }
                                        isSystemMarked={
                                            attendanceList?.data?.data.find(
                                                value =>
                                                    value.attributes.attendance_date ===
                                                        selectedDate &&
                                                    value.attributes.student.data.id === item.id &&
                                                    value.attributes.systemMarked === true
                                            )
                                                ? true
                                                : false
                                        }
                                        key={index}
                                        addAttendance={(present, onLeave, note) =>
                                            handleAddAttendance({
                                                present,
                                                on_leave: onLeave,
                                                note,
                                                attendance_date: selectedDate,
                                                time: getISTTime(),
                                                class: classDetails?.data?.data?.id ?? 0,
                                                student: item.id,
                                                markedBy: userState?.user?.id ?? 0,
                                                systemMarked: false,
                                            })
                                        }
                                        updateAttendance={(present, onLeave, note) =>
                                            handleEditAttendance(
                                                attendanceList?.data?.data.find(value =>
                                                    value?.attributes?.attendance_date === selectedDate &&
                                                    value?.attributes?.student?.data?.id === item?.id
                                                )?.id ?? 0,
                                                {
                                                    present,
                                                    on_leave: onLeave,
                                                    note,
                                                    attendance_date: selectedDate,
                                                    time: getISTTime(),
                                                    class: classDetails?.data?.data?.id ?? 0,
                                                    student: item.id,
                                                    markedBy: userState?.user?.id ?? 0,
                                                    systemMarked: false,
                                                },
                                            )
                                        }
                                        // showLoader={
                                        //     isAttendancePosting ||
                                        //     isAttendanceFetching ||
                                        //     isAttendanceUpdating
                                        // }
                                        isLatest={!notLatestAttendanceList.includes(item.id)}
                                        showLoader = {
                                            activeStudentsMarkingAttendance.includes(item.id)
                                        }
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        <div className={style.loaderContainer}>
                            <Loader />
                        </div>
                    )}
                </>
            )}

            {isLoading && <FullScreenLoader />}

            <PopupModal
                handleModalClose={handleFilterModalClose}
                isOpen={isfilterModalOpen}
            >
                <div className="w-[300px] flex gap-2 items-center justify-center  h-[100px] flex-row">
                    <input
                        type="checkbox"
                        name="sorting"
                        checked={selectedOption === 'Student name A-Z'}
                        onChange={() => checkBoxHandler('Student name A-Z')}
                    />
                    <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                        Student name A-Z
                    </div>
                    {/* Add more checkboxes for other sorting options if needed */}
                </div>
            </PopupModal>

            <PopupModal
                handleModalClose={handlePhotoUploadFormClose}
                isOpen={isPhotoUploadFormOpen}
                title="Upload Photo"
                background="white"
            >
                <PhotoUploadForm
                    triggerFileInput={triggerFileInput}
                    fileInputRef={fileInputRef}
                    handleImageChange={handleImageChange}
                    removeSelectedImage={removeSelectedImage}
                    previewURLs={previewURLs}
                    uploadSelectedImage={uploadSelectedImage}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    handleDotClick={handleDotClick}
                    currentImageIndex={currentImageIndex}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleStudentEditFormClose}
                isOpen={isStudentInfoEditFormOpen}
                title="Edit Student Info"
                background="white"
            >
                <AttendanceStudentEditForm
                    handleStudentEditFormClose={handleStudentEditFormClose}
                    aiModelResponseCorrected={aiModelResponseCorrected}
                    activeStudentEdit={activeStudentEdit}
                    setAiModelResponseCorrected={setAiModelResponseCorrected}
                    batchName={classDetails?.data?.data?.attributes?.class_name ?? ''}
                    studentSearchTerm={studentSearchTerm}
                    isStudentSearchInputOnFocus={isStudentSearchInputOnFocus}
                    setIsStudentSearchInputOnFocus={setIsStudentSearchInputOnFocus}
                    handleStudentSearchInputChange={handleStudentSearchInputChange}
                    isEditStudentDataFetchLoading={isEditStudentDataFetchLoading}
                    editStudentSuggestionListData={editStudentSuggestionListData}
                    onEditStudentSearchListItemClick={onEditStudentSearchListItemClick}
                    selectedStudentInEditStudentForm={selectedStudentInEditStudentForm}
                    handleAddStudentBtnClick={() => handleAddStudentBtnClick(activeStudentEdit)}
                />
            </PopupModal>
            <PopupModal
                handleModalClose={handleNewStudentModalClose}
                isOpen={isNewStudentModalOpen}
                title="Add Student"
                background="white"
            >
                <NewStudentMininalForm
                    formData={newStudentMinimalFormData}
                    handleAddStudentInputChange={handleAddStudentInputChange}
                    handleAddNewStudent={handleAddNewStudent}
                    image={aiModelResponseCorrected?.crops[activeAddStudentFormIndex] ?? ''}
                    classDetails={classDetails}
                />
            </PopupModal>

            <GuestStudentMarkAttendance
                isOpen={isAddGuestStudentAttendanceModalOpen}
                handleClose={handleAddGuestStudentAttendanceModalClose}
                currentStudentsIds={listOfAllStudents?.map(value => value?.id)}
                currentClass={classDetails?.data?.data}
            />
        </>
    );
};
export default ScheduleDetails;
