import React from 'react'
import { Toaster } from 'react-hot-toast';
import LiveAttendanceViewModel from '../../view-models/live-attendance-view-model'
import closeIcon from '../../../assets/icons/cross.png'
import cameraSwitchIcon from '../../../assets/icons/camera-switch.png'
import editIcon from '../../../assets/icons/edit.png'
import defaultProfile from '../../../assets/icons/user.png'
import cameraFrame from '../../../assets/icons/corner-border-frame.png'
import clearIcon from '../../../assets/icons/clear.png';
import { calculateAge, getFormattedDate } from '../../../utils/helpers/helpers';


const LiveAttendance = () => {
    const {
        isLoading,
        videoRef,
        handleCloseLiveAttendance,
        handleCaptureLoop,
        capturedImage,
        switchCamera,
        devices,
        capturedImageBestMatch,
        countdown,
        toggleSidePanel,
        isSidePanelOpen,
        isMultipleFaceDetected,
        canvasRef,
        boxSize,
        isDaysAttendanceFetching,
        daysAttendance,
        isCapturedStudentDataFetching,
        capturedStudentData,
        isMatching,
        activeSidePanelTab,
        setActiveSidePanelTab,
        isDetectionPaused,
        setIsDetectionPaused,
        detectionInterval,
        setDetectionInterval,
        cooldown,
        setCooldown,
        expandedStudentAttendanceCard,
        handleStudentAttendanceEditClick,
        studentSearchTerm,
        setStudentSearchTerm,
        isStudentSuggestionDataFetchLoading,
        studentSuggestionListData,
        activeEditingStudentAttendanceData,
        isSelectedStudentDataLoading,
        setActiveEditingStudentAttendanceData,
        selectedStudentData,
        handleStudentSuggestionSelect,
        showStudentSuggestions,
        setShowStudentSuggestions,
        handleEditAttendanceRecord
    } = LiveAttendanceViewModel();

    return (
        <div className='bg-slate-200'>
            {isLoading && 
                <div className='absolute bg-slate-200 top-0 left-0 right-0 bottom-0 text-2xl font-semibold flex justify-center items-center h-screen w-screen z-[10002] backdrop-blur-xl'>
                    <div className="spinner animate-boxSpinner">
                        <div><img src='/assets/images/icon-only.png' className='h-full w-full p-1'/></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            }
            {isDetectionPaused ? (
                <div className='absolute top-0 right-0 h-4 w-4 rounded-full bg-red-500 m-8 z-[10005] animate-pulse'></div>
            ) : (
                <div className='absolute top-0 right-0 h-4 w-4 rounded-full bg-green-500 m-8 z-[10005] animate-pulse'></div>
            )}
            <div className='inset-0 w-screen h-screen relative'>
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    onPlay={handleCaptureLoop}
                    style={{ width: window.innerWidth, height: window.innerHeight, objectFit: "cover", transform: "scaleX(-1)", display: "block" }}
                ></video>
                {(!isLoading && !capturedImage && !isMatching && !isMultipleFaceDetected) && (
                    <div 
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[95]'
                        style={{
                            height: boxSize,
                            width: boxSize,
                        }}
                    >
                        <img 
                            src={cameraFrame}
                            className='h-full w-full opacity-40 animate-pulse'
                        />
                    </div>
                )}
                <canvas ref={canvasRef} className='absolute top-0 left-0' style={{transform: "scaleX(-1)", display: "none"}}></canvas>
                {(capturedImage && countdown >= 0) && (
                    <>
                        <div className='absolute top-0 left-0 w-full h-full z-10 backdrop-blur-lg'></div>
                        <div 
                            className='w-[400px] bg-slate-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 z-[120] rounded'
                        >
                            {/* <h3 className='text-center font-semibold text-gray-700'>Captured Image</h3> */}
                            <img src={capturedImage} alt="Captured Face" className='rounded' style={{transform: "scaleX(-1)"}} />
                            {(isCapturedStudentDataFetching || isMatching) ? (
                                <div className='w-full pt-2 flex flex-col gap-2 items-center'>
                                    <div className='h-6 w-[220px] bg-slate-200 animate-pulse rounded'></div>
                                    <div className='h-6 w-[160px] bg-slate-300 animate-pulse rounded'></div>
                                </div>
                            ) : (
                                <div className='w-full pt-2 flex flex-col gap-2 items-center'>
                                    {/* <div className='p-2 text-center font-semibold'>{capturedImageBestMatch?.name} - {capturedImageBestMatch?.similarity}</div> */}
                                    <div className='font-semibold text-lg text-gray-800'>{`${capturedStudentData?.attributes?.firstName} ${capturedStudentData?.attributes?.middleName ?? ''} ${capturedStudentData?.attributes?.lastName ?? ''}`}</div>
                                    <div className=''>{capturedStudentData?.attributes?.classes?.data[0]?.attributes?.class_name}</div>
                                </div>
                            )}
                        </div>
                        {/* <div 
                            className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 text-center font-semibold z-[120] text-slate-200 text-2xl opacity-80'
                        >
                            {countdown}
                        </div> */}
                    </>
                )}
                {isMultipleFaceDetected && (
                    <div
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center border-4 border-red-400 p-8 font-semibold z-[20] rounded-lg text-red-400 text-2xl bg-black opacity-80 animate-pulse'
                    >
                        <div className='text-3xl underline'>Multiple Faces Detected!</div>
                        <div className='mt-2'>Please ensure only one student is in the frame for accurate recognition.</div>
                    </div>
                )}
                <div className='p-4 absolute bottom-0 w-full flex justify-center gap-4 hover:gap-10 scale-75 hover:scale-100 transition-all'>
                    <button 
                        className='disabled:opacity-50 disabled:cursor-not-allowed w-14 h-14 p-2 rounded-full bg-[#455a64] border-none z-100000 hover:scale-105 transition-all' 
                        onClick={switchCamera} 
                        disabled={devices.length <= 1}
                    >
                        <img 
                            src={cameraSwitchIcon}
                            className='w-full'
                        />
                    </button>
                    <button 
                        className='bg-red-500 w-14 h-14 p-1 border-none rounded-full z-100000 hover:scale-105 transition-all' 
                        onClick={handleCloseLiveAttendance}
                    >
                        <img 
                            src={closeIcon}
                            className='w-full'
                        />
                    </button>
                </div>
            </div>



            <div className={`absolute top-0 h-full z-[10006] ${isSidePanelOpen ? 'left-0' : '-left-[500px]'} transition-all`}>
                <div 
                    className='h-14 w-14 hover:scale-125 transition-all rounded-full bg-slate-200 absolute bottom-0 left-[500px] transform -translate-x-1/2 -translate-y-1/2 -z-10 cursor-pointer'
                    onClick={toggleSidePanel}
                ></div>
                <div className='w-[500px] h-full bg-slate-200 p-2'>
                    <div className='grid grid-cols-2'>
                        <div 
                            className={`font-semibold text-lg text-center px-4 p-1 border-b-4 rounded-t-md transition-all cursor-pointer ${activeSidePanelTab === 1 ? 'bg-slate-50 border-gray-500' : ''}`}
                            onClick={() => setActiveSidePanelTab(1)}
                        >
                            Settings
                        </div>
                        <div 
                            className={`font-semibold text-lg text-center px-4 p-1 border-b-4 rounded-t-md transition-all cursor-pointer ${activeSidePanelTab === 2 ? 'bg-slate-50 border-gray-500' : ''}`}
                            onClick={() => setActiveSidePanelTab(2)}
                        >
                            Students recongnized
                        </div>
                    </div>
                    <hr className='my-2 border-gray-400'/>
                    <div>
                        {activeSidePanelTab === 1 && (
                            <div>
                                <div className='flex items-center justify-between mb-2 px-8 py-2'>
                                    <div className='text-lg font-semibold'>Pause detection</div>
                                    <label className="custom-checkbox">
                                        <input name="dummy" type="checkbox" checked={isDetectionPaused} onChange={(e) => setIsDetectionPaused(e.target.checked)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <hr className='border-slate-400 mx-14 my-2'/>
                                <div className='flex items-center justify-between mb-2 px-8 py-2'>
                                    <div className='text-lg font-semibold'>Detection Interval</div>
                                    <select className='border-0 rounded focus:ring-0' value={detectionInterval} onChange={(e) => setDetectionInterval(Number(e.target.value))}>
                                        <option value={5000}>5 seconds</option>
                                        <option value={10000}>10 seconds</option>
                                        <option value={15000}>15 seconds</option>
                                        <option value={20000}>20 seconds</option>
                                        <option value={30000}>30 seconds</option>
                                    </select>
                                </div>
                                <hr className='border-slate-400 mx-14 my-2'/>
                                <div className='flex items-center justify-between mb-2 px-8 py-2'>
                                    <div className='text-lg font-semibold'>Cooldown Time</div>
                                    <select className='border-0 rounded focus:ring-0' value={cooldown} onChange={(e) => setCooldown(Number(e.target.value))}>
                                        <option value={500}>No cooldown</option>
                                        <option value={2000}>2 seconds</option>
                                        <option value={3000}>3 seconds</option>
                                        <option value={5000}>5 seconds</option>
                                        <option value={10000}>10 seconds</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        {activeSidePanelTab === 2 && (
                            <div className='flex flex-col gap-2 overflow-y-auto px-4 py-2'>
                                {isDaysAttendanceFetching ? (
                                    Array(5).map((_, index) => (
                                        <div key={index} className='flex items-center rounded bg-slate-50 p-1'>
                                            <div className='w-16 h-16 rounded bg-slate-300 animate-pulse'></div>
                                            <div className='flex-grow flex flex-col gap-2 p-2'>
                                                <div className='w-32 h-5 rounded bg-slate-300 animate-pulse'></div>
                                                <div className='w-60 h-5 rounded bg-slate-300 animate-pulse'></div>
                                            </div>
                                            <div>
                                                <img 
                                                    src={editIcon}
                                                    className='w-6 mr-2 cursor-pointer'
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : daysAttendance.map((data, index) => (
                                    <>
                                        <div 
                                            key={index} 
                                            className={`flex items-center rounded p-1 transition-all ${data?.attributes?.studentFeedBack === 'INCORRECT' ? 'bg-red-200' : 'bg-slate-50'} ${expandedStudentAttendanceCard === data?.id ? 'scale-105 shadow-md shadow-gray-500' : ''}`}>
                                            <img 
                                                src={data?.attributes?.capturedImage?.data?.attributes?.url ?? ''}
                                                className='w-16 h-16 rounded object-cover'
                                            />
                                            <div className='flex-grow flex flex-col gap-1 p-2'>
                                                <div className='font-semibold'>{`${data?.attributes?.student?.data?.attributes?.firstName} ${data?.attributes?.student?.data?.attributes?.middleName ?? ''} ${data?.attributes?.student?.data?.attributes?.lastName ?? ''}`}</div>
                                                <div className=''>{data?.attributes?.class?.data?.attributes?.class_name}</div>
                                            </div>
                                            <div>
                                                <img 
                                                    src={editIcon}
                                                    className='w-6 mr-2 cursor-pointer'
                                                    onClick={() => handleStudentAttendanceEditClick(data?.id)}
                                                />
                                            </div>
                                        </div>
                                        {expandedStudentAttendanceCard === data?.id && (
                                            <div className='p-2 bg-white rounded-b-md'>
                                                {selectedStudentData?.id !== data?.attributes?.student?.data?.id && (
                                                    <div className='p-4 rounded border-2 my-2'>
                                                        <div className='text-center text-gray-600 font-semibold'>Selected Student</div>
                                                        <hr className='my-2'/>
                                                        <div className='flex gap-2 items-center'>
                                                            <img 
                                                                src={selectedStudentData?.attributes?.photo?.data?.attributes?.url ?? defaultProfile}
                                                                className='w-16 h-16 rounded object-cover'
                                                            />
                                                            <div>
                                                                <div>{`${selectedStudentData?.attributes?.firstName} ${selectedStudentData?.attributes?.middleName ?? ''} ${selectedStudentData?.attributes?.lastName ?? ''}`}</div>
                                                                <div>{calculateAge(new Date(selectedStudentData?.attributes?.dob ?? ''))}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='flex flex-col items-center gap-2'>
                                                    <div className='relative w-full'>
                                                        <input 
                                                            type='text' 
                                                            className='border-0 w-full rounded bg-slate-100'
                                                            placeholder='Search Students'
                                                            required
                                                            value={studentSearchTerm}
                                                            onChange={(e) => setStudentSearchTerm(e.target.value)}
                                                            onFocus={() => setShowStudentSuggestions(true)}
                                                            onBlur={() => {
                                                                setTimeout(() => {
                                                                    setShowStudentSuggestions(false);
                                                                }, 200);
                                                            }}
                                                        />
                                                        <img 
                                                            src={clearIcon}
                                                            className='w-6 absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer'
                                                            onClick={() => setStudentSearchTerm('')}
                                                        />
                                                        {showStudentSuggestions && (
                                                            <div className='absolute shadow-md shadow-gray-500 right-0 left-0 top-full z-10 bg-slate-300 max-h-[200px] overflow-y-auto w-full rounded mt-2 p-1 flex flex-col gap-1'>
                                                                {isStudentSuggestionDataFetchLoading ? (
                                                                    <div className='p-2 py-10 font-semibold text-slate-400 text-center animate-pulse'>Loading...</div>
                                                                ) : (
                                                                    studentSuggestionListData?.length > 0 ? (
                                                                        studentSuggestionListData.map((student, index) => (
                                                                            <div 
                                                                                className='p-2 border-b-1 bg-slate-200 flex items-center gap-2' 
                                                                                key={index}
                                                                                onClick={() => handleStudentSuggestionSelect(student?.id ?? '')}
                                                                            >
                                                                                <img 
                                                                                    src={student?.attributes?.photo?.data?.attributes?.url ?? defaultProfile}
                                                                                    className='w-10 h-10 rounded object-cover'
                                                                                />
                                                                                <div className='text-sm'>
                                                                                    <div className='font-semibold'>{`${student?.attributes?.firstName} ${student?.attributes?.middleName ?? ''} ${student?.attributes?.lastName ?? ''}`}</div>
                                                                                    <div>{calculateAge(new Date(student?.attributes?.dob ?? ''))}</div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className='p-2 py-10 font-semibold text-slate-400 text-center'>No results found</div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <select 
                                                        className='border-1 border-slate-200 focus:ring-0 w-full rounded'
                                                        required
                                                        value={activeEditingStudentAttendanceData?.class}
                                                        onChange={(e) => setActiveEditingStudentAttendanceData(prev => ({student: prev?.student ?? 0, class: Number(e.target.value)}))}
                                                    >
                                                        <option value=''>Select Class</option>
                                                        {selectedStudentData?.attributes?.classes?.data?.map((c, index) => (
                                                            <option key={index} value={c?.id}>{c?.attributes?.class_name}</option>
                                                        ))}
                                                    </select>
                                                    <button  
                                                        disabled={activeEditingStudentAttendanceData?.student === data?.attributes?.student?.data?.id ? true : false} 
                                                        onClick={handleEditAttendanceRecord} 
                                                        className='bg-slate-400 rounded px-4 py-2 font-semibold disabled:opacity-40 disabled:cursor-not-allowed'
                                                    >
                                                        Submit Changes
                                                    </button>
                                                </div>
                                                <hr className='my-6 border-slate-300 mx-10'/>
                                                <div className='grid grid-cols-2 gap-2 mt-2'>
                                                    <div>
                                                        <div className='text-center text-gray-500 font-semibold text-sm'>Original</div>
                                                        <img 
                                                            src={data?.attributes?.student?.data?.attributes?.photo?.data?.attributes?.url ?? ''}
                                                            className='w-full h-64 rounded object-cover'
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className='text-center text-gray-500 font-semibold text-sm'>Captured Today</div>
                                                        <img 
                                                            src={data?.attributes?.capturedImage?.data?.attributes?.url ?? ''}
                                                            className='w-full h-64 rounded object-cover'
                                                        />
                                                    </div>
                                                </div>
                                                <div 
                                                    className='bg-primary text-white p-2 rounded mt-2 text-center cursor-pointer font-semibold active:scale-95 transition-all'
                                                    onClick={() => alert('Feature not implemented yet')}
                                                >
                                                    Replace student image with captured image
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Toaster 
                position='bottom-center'
            />
        </div>
    )
}

export default LiveAttendance