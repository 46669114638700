import React, { ChangeEvent, useState } from 'react';
import style from './style.module.css';
import { KeyPointDataType } from '../../../view-models/progress-tracker-view-model';
import TechniqueJointOptions from '../../../pages/progress-tracker/data';

interface EditTechniqueKeyPointFormProps {
    data: KeyPointDataType;
    handleEditKeyPointModalInputChange: (value: number, field: string) => void;
    handleSaveKeyPointUpdatedData: () => void;
    handleDeleteKeyPointUpdatedData: () => void;
}

const EditTechniqueKeyPointForm: React.FC<EditTechniqueKeyPointFormProps> = ({
    data,
    handleEditKeyPointModalInputChange,
    handleSaveKeyPointUpdatedData,
    handleDeleteKeyPointUpdatedData,
}) => {
    const [isJoinOneSearchInputOnFocus, setIsJoinOneSearchInputOnFocus] = useState<boolean>(false);
    const [isJoinTwoSearchInputOnFocus, setIsJoinTwoSearchInputOnFocus] = useState<boolean>(false);
    const [joinOneSearchTerm, setJoinOneSearchTerm] = useState<string>(
        TechniqueJointOptions.find(item => item.value === data.join1)?.label || ''
    );
    const [joinTwoSearchTerm, setJoinTwoSearchTerm] = useState<string>(
        TechniqueJointOptions.find(item => item.value === data.join2)?.label || ''
    );

    return (
        <div className="p-4 sm:w-[400px]">
            <div className="flex items-center justify-between w-full gap-4 mb-4">
                <label>Time stamp</label>
                <div>{data.timestamp?.toFixed(2)}</div>
            </div>
            <div className="flex items-center justify-between w-full sm:gap-4  mb-4">
                <label className="w-[30%]">Join 1</label>
                <div className="relative">
                    <input
                        placeholder="Join1"
                        className={`w-[70%] ${style.formInput} ${
                            TechniqueJointOptions.filter(option =>
                                option.label.toLowerCase().includes(joinOneSearchTerm.toLowerCase())
                            ).length === 0
                                ? ' !border-red-400'
                                : ''
                        }`}
                        value={joinOneSearchTerm}
                        onFocus={() => setIsJoinOneSearchInputOnFocus(true)}
                        onBlur={() => setTimeout(() => setIsJoinOneSearchInputOnFocus(false), 200)}
                        onChange={e => setJoinOneSearchTerm(e.target.value)}
                    />
                    {isJoinOneSearchInputOnFocus && (
                        <div className={`${style.suggestionBox}`}>
                            <ul className={style.suggestionBoxListWrapper}>
                                {TechniqueJointOptions.filter(option =>
                                    option.label
                                        .toLowerCase()
                                        .includes(joinOneSearchTerm.toLowerCase())
                                ).map(item => (
                                    <li
                                        className={style.suggestionBoxListItem}
                                        onClick={() => {
                                            handleEditKeyPointModalInputChange(item.value, 'join1');
                                            setJoinOneSearchTerm(item.label);
                                            setIsJoinOneSearchInputOnFocus(false);
                                        }}
                                        key={item.value}
                                    >
                                        <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center justify-between w-full sm:gap-4 mb-4">
                <label className="w-[30%]">Join 2</label>
                <div className="relative">
                    <input
                        placeholder="Join2"
                        className={`w-[70%] ${style.formInput} ${
                            TechniqueJointOptions.filter(option =>
                                option.label.toLowerCase().includes(joinTwoSearchTerm.toLowerCase())
                            ).length === 0
                                ? ' !border-red-400'
                                : ''
                        }`}
                        value={joinTwoSearchTerm}
                        onFocus={() => setIsJoinTwoSearchInputOnFocus(true)}
                        onBlur={() => setTimeout(() => setIsJoinTwoSearchInputOnFocus(false), 200)}
                        onChange={e => setJoinTwoSearchTerm(e.target.value)}
                    />
                    {isJoinTwoSearchInputOnFocus && (
                        <div className={`${style.suggestionBox}`}>
                            <ul className={style.suggestionBoxListWrapper}>
                                {TechniqueJointOptions.filter(option =>
                                    option.label
                                        .toLowerCase()
                                        .includes(joinTwoSearchTerm.toLowerCase())
                                ).map(item => (
                                    <li
                                        className={style.suggestionBoxListItem}
                                        onClick={() => {
                                            handleEditKeyPointModalInputChange(item.value, 'join2');
                                            setJoinTwoSearchTerm(item.label);
                                            setIsJoinTwoSearchInputOnFocus(false);
                                        }}
                                        key={item.value}
                                    >
                                        <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center justify-between w-full gap-4 mb-10">
                <label>Weightage</label>
                <input
                    type="number"
                    value={data.weightage ?? ''}
                    onChange={e =>
                        handleEditKeyPointModalInputChange(parseInt(e.target.value), 'weightage')
                    }
                    placeholder="Weightage"
                    className={style.formInput}
                />
            </div>
            <div className="flex items-center justify-between w-full gap-4">
                <div
                    className="flex-grow py-2 text-center cursor-pointer bg-red-400 rounded font-semibold text-white active:scale-90 transition-all"
                    onClick={handleDeleteKeyPointUpdatedData}
                >
                    Delete
                </div>
                <div
                    className="flex-grow py-2 text-center cursor-pointer bg-primary rounded font-semibold text-white active:scale-90 transition-all"
                    onClick={handleSaveKeyPointUpdatedData}
                >
                    Save
                </div>
            </div>
        </div>
    );
};

export default EditTechniqueKeyPointForm;
