import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StudentDetailsViewModel from '../../view-models/student-details-view-model';
import { TechniqueResultData } from '../../../models/student/student-ai-evaluation-data';

const StudentEvalResults = () => {
    const { studentId, techniqueId } = useParams();

    const [evalData, setevalData] = useState<TechniqueResultData | null>(null);

    const { studentsEvaluationData } = StudentDetailsViewModel();

    useEffect(() => {
        if (
            studentsEvaluationData &&
            studentsEvaluationData.data?.data &&
            studentsEvaluationData.data?.data.length > 0 &&
            techniqueId
        ) {
            setevalData(
                studentsEvaluationData?.data?.data.filter(
                    item => item.id === parseInt(techniqueId)
                )[0]
            );
        }
    }, [studentsEvaluationData, techniqueId]);

    // console.log(evalData)

    // console.log(studentId, techniqueId)
    return (
        <>
            {evalData ? (
                <div className="flex flex-col p-4 text-3xl">
                    <h1 className="font-bold">
                        Technique name: {evalData.attributes.techniqueName}
                    </h1>
                    {evalData.attributes.results.data.map((i, index) => (
                        <div
                            key={index}
                            className="flex flex-col md:flex-row gap-4"
                        >
                            <div className="bg-[#7c303086] flex-1 p-2">
                                <img
                                    src={`${i.Student_Image}`}
                                    alt=""
                                />
                            </div>
                            <div className="bg-[#7c303086] flex-1 p-2">
                                <img
                                    src={`${i.Instructor_Image}`}
                                    alt=""
                                />
                            </div>
                            <div className="bg-[#d8d6d686] flex-1 p-2 text-base ">
                                <div className="bg-blue-500 w-full rounded-full p-2 text-white font-bold text-center">
                                    {' '}
                                    {i.Score}
                                </div>
                                <h1 className="text-xl font-bold p-2">Tips:</h1>
                                <p className="p-2">{i.Tips}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default StudentEvalResults;
