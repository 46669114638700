import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams, useRouteError } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { LoginService } from '../../services/login-service';
import { DEFAULT_ERROR, USER_QUERY } from '../../utils/constants/constants';
import { getJWTToken, setJWTToken } from '../../utils/helpers/helpers';
import { Routes } from '../navigation/routes';
import { LoginState, loginReducer } from '../reducers/login-reducer';
import ForgetpasswordPostData from '../../utils/types/forgetpassword-post-data';
import RestpasswordPostData from '../../utils/types/reset-password-post-data';
import { setUser } from '../../utils/redux/user-slice';
import { User } from '../../models/user/user';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { HeaderMenuService } from '../../services/header-menu-service';
import { Roles } from '../../utils/auth/roles';
import axios from 'axios';
import ApiResponse from '../../utils/types/api-response';
import { invokeApi } from '../../utils/helpers/invoke-api';
import ApiRequest from '../../utils/types/api-request';

const LoginViewModel = () => {
    const initialState: LoginState = {
        email: '',
        password: '',
        isLoginCalling: false,
        showPassword: false,
        errorMsg: '',
    };

    const [{ email, password, isLoginCalling, showPassword, errorMsg }, dispatch] = useImmerReducer(
        loginReducer,
        initialState
    );

    const userState = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const usedispatch = useDispatch();
    const [isAcademyUser, setIsAcademyUser] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onLoginClicked = useCallback(() => {
        dispatch({
            type: 'setIsLoginCalling',
        });
        LoginService.instance
            .checkLogin(email, password)
            .then(async response => {
                dispatch({
                    type: 'setIsLoginCalling',
                });
                if (response.success && response?.data?.user) {
                    // usedispatch(setUser(response?.data?.user))
                    setIsLoading(true);
                    await LoginService.instance
                        .getUserRole(response?.data?.user?.id ?? 0)
                        .then(res => {
                            if (
                                res.success &&
                                (res.data?.role.type === Roles.AUTHENTICATED_USER ||
                                    res.data?.role.type === Roles.RESTRICTED_USER)
                            ) {
                                setIsAcademyUser(true);
                                setJWTToken(response.data?.jwt ?? '');
                                queryClient.invalidateQueries(USER_QUERY);
                            } else {
                                dispatch({
                                    type: 'setErrorMsg',
                                    payload: 'Only Academy Users are allowed to login',
                                });
                            }
                            setIsLoading(false);
                        })
                        .catch(error => {
                            setIsLoading(false);
                            console.log(error);
                        });
                    // navigate(Routes.Dashboard, {replace: false});
                } else {
                    dispatch({
                        type: 'setErrorMsg',
                        payload: response.error?.message ?? DEFAULT_ERROR,
                    });
                }
            })
            .catch(error =>
                dispatch({
                    type: 'setErrorMsg',
                    payload: error?.message ?? DEFAULT_ERROR,
                })
            );
    }, [email, password]);

    const {
        isSuccess: isUserInfoFetchSuccess,
        data: userData,
        isLoading: isUserInfoFetching,
    } = useQuery({
        queryKey: [USER_QUERY],
        queryFn: HeaderMenuService.instance.getUserInfo,
        refetchOnWindowFocus: false,
        enabled: getJWTToken() != null && getJWTToken().length > 0 && isAcademyUser,
    });

    useEffect(() => {
        if (isAcademyUser) {
            if (isUserInfoFetchSuccess && getJWTToken() !== '') {
                if (userData.success) {
                    usedispatch(setUser(userData?.data ?? ({} as User)));
                    navigate(Routes.Dashboard, { replace: true });
                } else {
                    if (userData.error?.status === 403) {
                        setJWTToken('');
                        navigate(Routes.Login, { replace: true });
                    }
                }
            }
        }
    }, [isUserInfoFetchSuccess, userData, isAcademyUser]);

    const [forgotPasswordPage, setforgotPasswordPage] = useState(false);

    const [forgotPasswordEmailId, setforgotPasswordPageEmailId] = useState({
        emailid: '',
    });

    const onForgotPasswordinputHandler = (emailid: string, value: string) => {
        setforgotPasswordPageEmailId(prevValues => ({
            ...prevValues,
            [emailid]: value,
        }));
    };

    const checkEmailExists = async (email: string): Promise<boolean> => {
        const apiRequest: ApiRequest<null> = {
            route: `/users?filters[email]=${email}`,
            method: 'GET',
            privateRoute: true,
        };

        try {
            const response: ApiResponse<any> = await invokeApi<null, any>(apiRequest);

            if (response.success && response.data) {
                return response.data.length > 0;
            } else {
                console.error('Failed to check email:', response.error?.message);
                return false;
            }
        } catch (error) {
            console.error('Error checking email:', error);
            return false;
        }
    };

    const handlerSubmitFormForForgotPassWord = (event: React.FormEvent) => {
        event.preventDefault();

        checkEmailExists(forgotPasswordEmailId.emailid).then(response => {
            if (response) {
                postForgotPasswordEmailData({ email: forgotPasswordEmailId.emailid });
            } else {
                alert('Please enter the registred Email Id');
            }
        });
    };
    const postForgotPasswordEmailPosting = async (data: ForgetpasswordPostData) => {
        try {
            const response = await LoginService.instance.checkForgotPasswordEmail(data);

            if (response.success) {
                response.data;
                alert('The reset password URL is sent to your registred E-mail');
                setforgotPasswordPage(false);
            } else {
                alert('Please enter the registred Email Id');
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postAddAcademy: ${error}`);
            throw error;
        }
    };

    const {
        mutate: postForgotPasswordEmailData,
        isLoading: ispostForgotPasswordEmailLoading,
        error: ispostForgotPasswordEmailError,
    } = useMutation(postForgotPasswordEmailPosting, {
        onSuccess: data => {
            // Add any additional success logic here
        },
        onError: error => {
            // console.log('on post Add Academy Error', error);
            // alert('Failed to update the student! Please try again');
        },
    });

    const [forgotePasswordReset, setForgotePasswordReset] = useState({
        password: '',
        confirmationpassword: '',
    });

    const onResetPasswordinputHandler = (password: string, value: string) => {
        setForgotePasswordReset(prevValues => ({
            ...prevValues,
            [password]: value,
        }));
    };

    const postResetPasswordPosting = async (data: RestpasswordPostData) => {
        try {
            const response = await LoginService.instance.checkResetPassword(data);

            if (response.success) {
                response.data;
            } else {
                // console.log(
                //     `On Post Reset Password  Error ${JSON.stringify(
                //         response.error,
                //     )}`,
                // );
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in  Reset Password: ${error}`);
            throw error;
        }
    };

    const {
        mutate: resetPasswordpostData,
        isLoading: isresetPasswordpostDataLoading,
        error: isresetPasswordpostDataError,
    } = useMutation(postResetPasswordPosting, {
        onSuccess: data => {
            alert('Passsword changed successfully!');
            navigate(Routes.Login, { replace: true });
        },
        onError: error => {
            // console.log('on post Add Academy Error', error);
            alert('Failed to update the password! Please try again');
        },
    });

    const location = useLocation();
    const [toResetpasswordCode, setToResetpasswordCode] = useState('');
    useEffect(() => {
        // Extract code from URL
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        // Now you can use the 'code' variable in your logic
        setToResetpasswordCode(code || '');
    }, [location.search]);

    const handlerSubmitResetPassword = () => {
        if (
            forgotePasswordReset.password.length === 0 &&
            forgotePasswordReset.confirmationpassword.length === 0
        ) {
            return alert('Please enter a password');
        }
        if (forgotePasswordReset.password !== forgotePasswordReset.confirmationpassword) {
            return alert('Passwords does not match!');
        }

        const data = {
            code: toResetpasswordCode, // code contained in the reset link of step 3.
            password: forgotePasswordReset.password,
            passwordConfirmation: forgotePasswordReset.confirmationpassword,
        };

        resetPasswordpostData(data);
    };

    return {
        email,
        isLoading:
            ispostForgotPasswordEmailLoading || isUserInfoFetching || isLoginCalling || isLoading,
        password,
        showPassword,
        onLoginClicked,
        errorMsg,
        dispatch,
        forgotPasswordPage,
        setforgotPasswordPage,
        onForgotPasswordinputHandler,
        handlerSubmitFormForForgotPassWord,
        forgotPasswordEmailId,
        navigate,
        handlerSubmitResetPassword,
        onResetPasswordinputHandler,
        forgotePasswordReset,
    };
};

export default LoginViewModel;
