import { useQuery } from 'react-query';
import { SCHEDULE_DETAILS_QUERY, SCHEDULE_QUERY } from '../../utils/constants/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { ClassData } from '../../models/class/class-data';
import { areDatesSame, formatDateToInputDate, getCurrentDateString, getRawWeekDate, getWeekDay } from '../../utils/helpers/helpers';
import { ScheduleState, scheduleReducer } from '../reducers/schedule-reducer';
import { useImmerReducer } from 'use-immer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import ClassService from '../../services/class-service';
import ScheduleService from '../../services/schedule-service';

const ScheduleViewmodel = () => {
    const navigate = useNavigate();
    const selectedDate = useLocation().state?.date;
    const initialState: ScheduleState = {
        classData: [],
        selectedCalendarDate: selectedDate ? new Date(selectedDate) : new Date(),
    };
    const [{ classData, selectedCalendarDate }, dispatch] = useImmerReducer(
        scheduleReducer,
        initialState
    );
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const userState = useSelector((state: RootState) => state.user);
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
    const [filterStudentsForDay, setFilterStudentsForDay] = useState(true);

    const { isSuccess, isLoading, data } = useQuery({
        queryKey: [SCHEDULE_QUERY, userState?.user?.id],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    function getDateString(date: Date | null) {
        if (date) {
            const formatedDate = new Date(date);
            const year = formatedDate.getFullYear();
            const month = String(formatedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
            const day = String(formatedDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        } else {
            return '';
        }
    }

    const {
        isSuccess: isAttendanceCountFetchSuccess,
        isLoading: isAttendanceCountLoading,
        data: attendanceListCount,
    } = useQuery({
        queryKey: [SCHEDULE_DETAILS_QUERY, selectedCalendarDate],
        queryFn: ScheduleService.instance.getAttendanceHistoryCount.bind(
            this,
            userState?.user?.id ?? 0,
            getDateString(selectedCalendarDate)
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    useEffect(() => {
        if (data)
            dispatch({
                type: 'setClassData',
                payload: transfromScheduleResponse(data?.data?.data ?? []),
            });
    }, [data,selectedCalendarDate]);

    const transfromScheduleResponse = (classList: ClassData[]) => {
        let transfromedData: ClassData[] = [];
        classList.forEach(value => {
            const timingMappeddata = value.attributes.class_timings?.map(
                timing =>
                    ({
                        id: value.id,
                        attributes: {...value.attributes, class_timing: timing},
                    }) as ClassData,
            );
            transfromedData = [...transfromedData, ...(timingMappeddata ?? [])];
        });
        let filteredData: ClassData[] = [];
        for (let i = 0; i < 7; i++) {
            filteredData = [
                ...filteredData,
                ...transfromedData
                    .filter(
                        value =>
                            value.attributes.class_timing?.day ===
                            getWeekDay(i,selectedCalendarDate),
                    )
                    .map(
                        value =>
                            ({
                                id: value.id,
                                attributes: {
                                    ...value.attributes,
                                    class_day: getRawWeekDate(i,selectedCalendarDate),
                                },
                            }) as ClassData,
                    ),
            ];
        }

        if(!areDatesSame(selectedCalendarDate,new Date)){
            filteredData = filteredData.filter(value => value.attributes.class_day === getRawWeekDate(0,selectedCalendarDate))
        }
        return filteredData;
    };


    const getDayName = (date: Date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()];
    };

    const getDayNameString = (date: string) => {
        const dateObject = new Date(date);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[dateObject.getDay()];
    };

    const tileContentForEdit = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const today = new Date();
            const isCurrentDay = date.toDateString() === new Date().toDateString();

            const daysClassesTaken = data?.data?.data.flatMap(cls => 
                cls?.attributes.class_timings?.map(timing => timing.day) || []
            );

            const isInClassTiming = daysClassesTaken?.some(
                day => day === getDayName(date)
            );

            const publishedAtList = (data?.data?.data as ClassData[])
                .map(element => element.attributes.publishedAt)
                .map(dateString => new Date(dateString)).map(date => date.getTime())
            const classStarts = new Date(Math.min(...publishedAtList));

            if (isInClassTiming && classStarts && today >= date && classStarts <= date && isCurrentDay) {
                return (
                    <>
                        <div className="relative">
                            <div className="rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute bottom-[100%] right-1 sm:right-2" />
                            <div className='sm:!text-xs leading-none !text-[6px] bg-gray-100 rounded-lg absolute py-[1px] sm:!py-[2px] top-0 right-0 left-0'>Today</div>
                        </div>
                    </>
                );
            }else if (isInClassTiming && classStarts && today >= date && classStarts <= date) {
                return (
                    <>
                        <div className="relative">
                            <div className="rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute bottom-[100%] right-1 sm:right-2" />
                        </div>
                    </>
                );
            }
        }
        return null;
    };

    const handleDayClickOnEditMode = (value: Date) => {
        dispatch({ type: 'setSelectedCalendarDate', payload: value });
        setIsCalendarVisible(false)
    };

    const handelToggles = (value: boolean) => {
        setIsCalendarVisible(value);
    };

    const goScheduleDetails = useCallback((id: number, date: string, startTime: string, endTime: string) => {
        navigate(`${Routes.ScheduleDetails}/${id}`, { state: { date: formatDateToInputDate(selectedCalendarDate), filterStudentsForDay, time: { start: startTime, end: endTime} } });
    },[filterStudentsForDay,selectedCalendarDate]);

    const handleFilterPopupModalOpen = () => {
        setIsFilterModalOpen(true);
    };

    const handleFilterModalClose = () => {
        setIsFilterModalOpen(false);
    };

    const handleToggleFilterStudentsForDay = () => {
        setFilterStudentsForDay(prev => !prev);
    };

    const tileClassName = ({ date, view }: { date: Date; view: string }) => {
        const isCurrentDay = date.toDateString() === new Date().toDateString();
        if (view === 'month' && selectedCalendarDate && date.toDateString() === selectedCalendarDate.toDateString()) {
            return '!text-black sm:!py-6 !bg-white !shadow-inner !shadow-gray-600 !relative sm:!text-xl';
        }else if(view === 'month' && isCurrentDay) {
            return `!text-black sm:!py-6 !relative !shadow-lg !shadow-gray-500 !bg-gray-200 `;
        }else{
            return `!text-black sm:!py-6 !relative !shadow-sm !shadow-gray-300`;
        }
    };

    return {
        isLoading: isAttendanceCountLoading || isLoading,
        classData,
        isSuccess,
        goScheduleDetails,
        isFilterModalOpen,
        handleFilterModalClose,
        handleFilterPopupModalOpen,
        classes: data?.data?.data,
        attendanceListCount: attendanceListCount?.data?.data,
        tileContentForEdit,
        handleDayClickOnEditMode,
        isCalendarVisible,
        handelToggles,
        userState,
        filterStudentsForDay,
        handleToggleFilterStudentsForDay,
        tileClassName,
        currentDate: selectedCalendarDate,
    };
};

export default ScheduleViewmodel;
