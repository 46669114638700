import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { STUDENT_ENROLLMENT_FORM_QUERY } from '../../utils/constants/constants';
import { StudentEnrollmentService } from '../../services/student-enrollment-service';
import { useState } from 'react';
import { formatDateToInputDate } from '../../utils/helpers/helpers';
import { EnrolmentPostDataWrapper } from '../../models/enrollment-data/enrolment-post-data-wrapper';
import { EnrollmentDataListWrapper } from '../../models/enrollment-data/enrollment-data-list-wrapper';

export interface formData {
    firstName: string;
    middleName?: string;
    lastName?: string;
    gender?: string;
    dob?: string;
    bloodGroup?: string;
    medicalConditions?: string;
    address?: string;
    relationName?: string;
    relationNumber?: string;
    relationEmail?: string;
    relationWithStudent?: string;
    secondRelationName?: string;
    secondRelationNumber?: string;
    secondRelationEmail?: string;
    secondRelationWithStudent?: string;
}
const EnrollStudentFormViewModel = () => {
    const { slug } = useParams();
    const initialFormData: formData = {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        dob: '',
        bloodGroup: '',
        medicalConditions: '',
        address: '',
        relationName: '',
        relationNumber: '',
        relationEmail: '',
        relationWithStudent: '',
        secondRelationName: '',
        secondRelationNumber: '',
        secondRelationEmail: '',
        secondRelationWithStudent: '',
    };
    const [formData, setFormData] = useState<formData>(initialFormData);
    const [secondRelation, setSecondRelation] = useState(false);
    const [thankyouPage, setThankYouPage] = useState(false);

    const {
        isSuccess: isEnrollmentDataSuccess,
        isLoading: isEnrollmentDataFetching,
        data: enrollmentData,
    } = useQuery({
        queryKey: [STUDENT_ENROLLMENT_FORM_QUERY],
        queryFn: () => StudentEnrollmentService.instance.verifyEnrollmentSlug(slug || ''),
        refetchOnWindowFocus: false,
        enabled: slug !== undefined,
    });

    const putEnrolmentData = async (
        data: EnrolmentPostDataWrapper
    ): Promise<EnrollmentDataListWrapper | undefined> => {
        const response = await StudentEnrollmentService.instance.putEnrolmentData(
            data,
            enrollmentData?.data?.data[0]?.id ?? 0
        );
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewEnrolmentJson,
        isLoading: isNewEnrolmentJsonLoading,
        isSuccess: isNewEnrolmentJsonAdded,
    } = useMutation(putEnrolmentData, {
        onSuccess: data => {
            alert('Added Details Sucessfully!');
        },
        onError: error => {
            alert('Failed to add details. Please try again!');
        },
    });

    const handleFormInputChange = (field: string, value: string | number | undefined) => {
        setFormData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };
    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const reqFormData: EnrolmentPostDataWrapper = {
            data: {
                status: 'FORM_SUBMITTED',
                formData: formData,
            },
        };
        await addNewEnrolmentJson(reqFormData);
        setFormData(initialFormData);
        setThankYouPage(true);
    };

    const handleDeleteRelationClick = () => {
        setSecondRelation(false);
        setFormData(prev => {
            return {
                ...prev,
                secondRelationName: '',
                secondRelationEmail: '',
                secondRelationNumber: '',
                secondRelationWithStudent: '',
            };
        });
    };

    return {
        isEnrollmentDataFetching,
        isSubmitted: enrollmentData?.data?.data[0]?.attributes?.status === 'FORM_SUBMITTED',
        isActive: enrollmentData?.data?.data[0]?.attributes?.status !== 'REJECTED',
        isInvalidLink: enrollmentData?.data?.data?.length === 0,
        data: enrollmentData,
        formData,
        handleFormInputChange,
        handleFormSubmit,
        isNewEnrolmentJsonLoading,
        isNewEnrolmentJsonAdded,
        secondRelation,
        setSecondRelation,
        handleDeleteRelationClick,
        thankyouPage,
    };
};

export default EnrollStudentFormViewModel;
