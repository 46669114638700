import { FeePaymentListWrapper } from '../models/fee/fee-payment-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class DashboardService {
    private static _instance: DashboardService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new DashboardService());
    }

    public getStudentsClassFeeData = async (
        studentId: number
    ): Promise<ApiResponse<FeePaymentListWrapper>> => {
        return invokeApi<null, FeePaymentListWrapper>({
            route: `fee-collections?filters[student][id][$eq]=${studentId}&populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getFeeListForDashboard = async (
        studentId: number
    ): Promise<ApiResponse<FeePaymentListWrapper>> => {
        return invokeApi<null, FeePaymentListWrapper>({
            route: `fee-collections?populate[0]=fee_dates.feeRemindersSent&populate[1]=user&populate[2]=class&populate[3]=student.photo&populate[4]=student.studentRelation&populate[5]=student.academies&populate[6]=student.classes&populate[7]=academy&filters[users][id]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
