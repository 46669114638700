import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import style from './style.module.css';
import redCloseIcon from '../../../../assets/icons/red-close.png';
interface PopupModalProps {
    isOpen: boolean;
    handleModalClose: () => void;
    children: ReactNode;
    background?: string;
    title?: string;
    hideTopBar?: boolean;
    isFullScreen?: boolean;
}
const PopupModal = ({ isOpen, background, title, handleModalClose, children, hideTopBar = false, isFullScreen = false }: PopupModalProps) => {
    if (!isOpen) return null;

    const containerStyle = {
        backgroundColor: background || '',
    };

    return ReactDOM.createPortal(
        <div className={style.overLay}>
            <div
                className={`${isFullScreen ? style.ContainerFullScreen : style.Container} ${hideTopBar ? style.hiddenTopBar : style.visibleTopBar}`}
                style={containerStyle}
            >
                {!hideTopBar &&
                    <div className={style.topBarContainer}>
                        <div className={style.topBarTitle}>{title}</div>
                        <button
                            onClick={handleModalClose}
                            className={style.closeBtnContainer}
                        >
                            <img
                                className={style.closeButton}
                                src={redCloseIcon}
                            />
                        </button>
                    </div>
                }
                {children}
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement
    );
};

export default PopupModal;
