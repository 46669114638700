import React, { useEffect, useMemo, useState } from 'react';
import ArrowUp from '../../../../assets/icons/up-arrow.png';
import ArrowDown from '../../../../assets/icons/down-arrow.png';
import AddIcon from '../../../../assets/icons/add_icon.png';
import RemoveIcon from '../../../../assets/icons/remove_icon.png';
import Select from 'react-select';
import { AcademyData } from '../../../../models/academy/academy-data';
import { AcademyCard } from '../../../view-models/dashboard-view-model';
import style from './style.module.css';
import AddClassCard from '../add-class-card';
import { ClassData } from '../../../../models/class/class-data';

interface AddAcademyCardProps {
    index: number;
    isExpanded: number;
    handleExpandClick: () => void;
    handleDeleteClick: () => void;
    listOfAvailableAcademies: { value: number; label: string }[];
    handleInputChange: (
        property: string,
        value: string | number[] | number | undefined,
        index: number
    ) => void;
    academyCardDetails: AcademyCard;
    listOfAllAcademies: () => { value: number; label: string }[];
    listOfAllClasses: () => { value: number; label: string }[];
    listOfAvailableClasses: { value: number; label: string }[];
    handleClassAdding: (id: number, academyCardIndex: number) => void;
    handleClassDeleting: (classId: number, academyCardIndex: number) => void;
    allClasses: ClassData[];
    handleCheckboxChange: (
        key: string,
        timing: { day: string; from: string; to: string },
        academyCardIndex: number,
        classId: number
    ) => void;
    handleCheckboxChangeForDay: (day: string, academyCardIndex: number, classId: number) => void;
}

function AddAcademyCard({
    index,
    isExpanded,
    handleExpandClick,
    handleDeleteClick,
    listOfAvailableAcademies,
    handleInputChange,
    academyCardDetails,
    listOfAllAcademies,
    listOfAllClasses,
    listOfAvailableClasses,
    handleClassAdding,
    handleClassDeleting,
    allClasses,
    handleCheckboxChange,
    handleCheckboxChangeForDay,
}: AddAcademyCardProps) {
    return (
        <div className="border mt-2 rounded-md mr-1">
            <div className="bg-slate-200 rounded-md flex text-black justify-between items-center px-2 py-1">
                <div>
                    {academyCardDetails.academyId !== 0 &&
                        listOfAllAcademies()
                            .filter(academy => academy.value === academyCardDetails.academyId)
                            .map(academy => academy.label)}
                </div>
                {/* <div>{listOfAllClasses().filter(option => academyCardDetails.classId.includes(option.value)).map(option => option.label).join(',')}</div> */}
                <div className="flex justify-end gap-2 items-center">
                    <div>
                        <div
                            className="w-[25px] h-[25px] flex justify-center items-center p-2 cursor-pointer"
                            onClick={handleDeleteClick}
                        >
                            <i className="ri-delete-bin-line text-red-600"></i>
                        </div>
                    </div>
                    <div>
                        {isExpanded === index ? (
                            <img
                                src={ArrowUp}
                                className="w-6 h-6 cursor-pointer text-black"
                                onClick={handleExpandClick}
                            />
                        ) : (
                            <img
                                src={ArrowDown}
                                className="w-6 h-6 cursor-pointer text-black"
                                onClick={handleExpandClick}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isExpanded === index && (
                <div className="bg-slate-50 px-2 w-full py-1">
                    {/* <div className="flex justify-end items-center ">
                        <div className='w-[20px] h-[20px] cursor-pointer flex justify-center items-center'>
                            { //IF the id is already in the array show delete icon else show add icon.
                            }
                            <img src={AddIcon} alt="Add the academy" />
                        </div>
                    </div> */}
                    <div className="sm:flex justify-between items-center w-full gap-4">
                        <div className="sm:w-1/2 w-full">
                            <div className={style.formInputLabel}>
                                <span className={style.requiredFieldLabelPrompt}>*</span>
                                Academy:
                            </div>
                            {/* <Select
                                options={listOfAvailableAcademies}
                                className={style.formInput}
                                classNamePrefix="Select Academy"
                                isDisabled={academyCardDetails.academyId !== 0}
                                onChange={e =>
                                    handleInputChange(
                                        'academyId',
                                        (e?.value),
                                        index,
                                    )
                                }
                                value={listOfAllAcademies().filter(option => option.value === academyCardDetails.academyId)}
                            /> */}
                            <select
                                className={style.formInput}
                                disabled={academyCardDetails.academyId !== 0}
                                value={academyCardDetails.academyId}
                                onChange={e =>
                                    handleInputChange('academyId', Number(e.target.value), index)
                                }
                            >
                                <option
                                    className="text-gray-400 bg-slate-300"
                                    value={0}
                                >
                                    Select an Academy
                                </option>
                                {(academyCardDetails.academyId === 0
                                    ? listOfAvailableAcademies
                                    : listOfAllAcademies()
                                ).map((academy, index) => (
                                    <option
                                        key={index}
                                        value={academy.value}
                                    >
                                        {academy.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="sm:w-1/2 w-full">
                            <div className={style.formInputLabel}>Enrolment Date:</div>
                            <input
                                className={style.formInput}
                                type="date"
                                placeholder="Enter Enrolment Date"
                                value={academyCardDetails?.enrolmentDate?.toString()}
                                onChange={e =>
                                    handleInputChange('enrolmentDate', e.target.value, index)
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <div className={`${style.formInputLabel} flex items-center`}>
                            <span className={style.requiredFieldLabelPrompt}>*</span>
                            Add Classes:
                        </div>
                        <AddClassCard
                            listOfAvailableClasses={listOfAvailableClasses}
                            classCardData={academyCardDetails.classesSelected}
                            listOfAllClasses={listOfAllClasses}
                            handleClassAdding={handleClassAdding}
                            academyCardIndex={index}
                            handleClassDeleting={handleClassDeleting}
                            allClasses={allClasses}
                            handleCheckboxChange={handleCheckboxChange}
                            handleCheckboxChangeForDay={handleCheckboxChangeForDay}
                        />
                    </div>
                    {/* <div className="sm:w-1/2 w-full">
                        <div className={style.formInputLabel}>
                                <span
                                    className={
                                        style.requiredFieldLabelPrompt
                                    }>
                                    *
                                </span>
                                Classes:
                            </div>
                        <Select
                            isMulti
                            options={listOfAvailableClasses}
                            className={style.formInput}
                            classNamePrefix="Select Class"
                                    onChange={item =>
                                        handleInputChange(
                                            'classId',
                                            item.map(({value}) => value),
                                            id
                                        )
                                    }
                                    value={listOfAllClasses().filter(
                                        option => 
                                            academyCardDetails.classId?.includes(option.value)
                                    )}
                        />
                    </div> */}
                    <div className="sm:flex justify-between items-center gap-4">
                        <div className="w-full">
                            <span className={style.formInputLabel}>Remarks:</span>
                            <input
                                type="text"
                                className={style.formInput}
                                placeholder="Enter Student Remarks"
                                value={academyCardDetails?.remarks}
                                onChange={e => handleInputChange('remarks', e.target.value, index)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default AddAcademyCard;
