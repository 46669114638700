export type EnquiriesState = {
    currentPage: number;
};

export type EnquiriesAction = { type: 'setCurrentPage'; payload: number };

export const enquiriesReducer = (
    state: EnquiriesState,
    action: EnquiriesAction
): EnquiriesState => {
    switch (action.type) {
        case 'setCurrentPage':
            return { ...state, currentPage: action.payload };
        default:
            return state;
    }
};
