import React from 'react';
import style from './style.module.css';
import BulkStudentAttendanceDataType from '../../../../utils/types/bulk-student-attendance-data-type';
import downloadIcon from '../../../../assets/icons/download-icon.png';
import {
    isValidDate,
    isValidEmail,
    isValidNumber,
    verifyBulkStudentCsvRow,
} from '../../../../utils/helpers/helpers';

interface BulkStudentUploadFormProps {
    selectedCsvFileData: BulkStudentAttendanceDataType[];
    handleCsvFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    allClassesName: string[];
    csvFileInputRef: React.RefObject<HTMLInputElement>;
    handleBulkStudentAddSubmit: (data: BulkStudentAttendanceDataType[]) => void;
    handleExportInvaidStudentsDataToCsv: () => void;
}

const BulkStudentUploadForm: React.FC<BulkStudentUploadFormProps> = ({
    selectedCsvFileData,
    handleCsvFileInputChange,
    allClassesName,
    csvFileInputRef,
    handleBulkStudentAddSubmit,
    handleExportInvaidStudentsDataToCsv,
}) => {
    return (
        <div className={style.container}>
            <div className="flex justify-between items-center gap-4">
                <div className={style.formUploadInputBtnWrapper}>
                    <label
                        htmlFor="UploadInput"
                        className={style.formUploadInputBtnLabel}
                    >
                        <input
                            type="file"
                            id="UploadInput"
                            className={style.formUploadInput}
                            accept=".csv"
                            onChange={e => handleCsvFileInputChange(e)}
                            ref={csvFileInputRef}
                        />
                        <div className={style.formUploadInputLabel}>
                            <div>Select a CSV file</div>
                        </div>
                    </label>
                </div>
                <div className="font-semibold text-gray-500">
                    Total students: {selectedCsvFileData.length}
                </div>
                <div className="font-semibold text-green-400">
                    Valid students:{' '}
                    {
                        selectedCsvFileData.filter(
                            data => !verifyBulkStudentCsvRow(data, allClassesName)
                        ).length
                    }
                </div>
                <div className="font-semibold text-red-400">
                    Invalid students:{' '}
                    {
                        selectedCsvFileData.filter(data =>
                            verifyBulkStudentCsvRow(data, allClassesName)
                        ).length
                    }
                </div>
                <div className="border-2 border-gray-200 rounded-2xl px-4 py-1">
                    Facing issues with file format?
                    <a
                        className="text-gray-600 cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://cloudconvert.com/xlsx-to-csv"
                    >
                        {' '}
                        check out here
                    </a>
                </div>
            </div>
            <div className="overflow-auto w-full sm:h-[50vh] h-[65vh] border rounded thin-scrollbar mt-2">
                <div className="flex sticky top-0 bg-white">
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[50px]">
                        Sl.no
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        First Name
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Middle Name
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Last Name
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Enrolement Date
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Gender
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Apartment Name
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[340px]">
                        Current Address
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Date Of Birth
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Parent Contact No
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Parent Email Id
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        State
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        City
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-1
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-1 Name
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-1 Gender
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-1 Contact
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-1 Email
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-2
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-2 Name
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-2 Gender
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-2 Contact
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[140px]">
                        Relation-2 Email
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[350px]">
                        Classes
                    </div>
                    <div className="bg-slate-200 border border-gray-700 px-1 min-w-[50px]">
                        Photo
                    </div>
                </div>
                {selectedCsvFileData.map((data, index) => {
                    const isError = verifyBulkStudentCsvRow(data, allClassesName);
                    const isPrevError = verifyBulkStudentCsvRow(
                        selectedCsvFileData[index - 1],
                        allClassesName
                    );

                    return (
                        <div
                            className={`flex`}
                            key={index}
                        >
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2 border-l-red-400' : 'border-gray-300'} px-1 min-w-[50px]`}
                            >
                                {index + 1}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${data.firstName === '' ? '!bg-red-200' : ''}`}
                            >
                                {data.firstName}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.middleName}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.lastName}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${!isValidDate(data.enrolementDate) ? '!bg-red-200' : ''}`}>{data.enrolementDate}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.enrolementDate}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${data.gender !== 'male' && data.gender !== 'female' ? '!bg-red-200' : ''}`}
                            >
                                {data.gender}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.apartmentName}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[340px]`}
                            >
                                {data.currentAddress}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.dob}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${!isValidDate(data.dob) ? '!bg-red-200' : ''}`}>{data.dob}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.parentContactNo}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.parentEmailId}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${!isValidEmail(data.parentEmailId) ? '!bg-red-200' : ''}`}>{data.parentEmailId}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.state}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.city}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation1}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation1Name}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation1Gender}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${(data.relation1Gender !== 'male' && data?.relation1Gender !== 'female' && data?.relation1Gender !== '') ? '!bg-red-200' : ''}`}>{data.relation1Gender}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation1ContactNo}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${(!isValidEmail(data.relation1EmailId) || data.relation1EmailId === '') ? '!bg-red-200' : ''}`}>{data.relation1EmailId}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation1EmailId}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation2}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation2Name}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${(data.relation2Gender !== 'male' && data?.relation2Gender !== 'female' && data?.relation2Gender !== '') ? '!bg-red-200' : ''}`}>{data.relation2Gender}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation2Gender}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation2ContactNo}
                            </div>
                            {/* <div className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px] ${(!isValidEmail(data.relation2EmailId) || data.relation2EmailId === '') ? '!bg-red-200' : ''}`}>{data.relation2EmailId}</div> */}
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2' : 'border-gray-300'} px-1 min-w-[140px]`}
                            >
                                {data.relation2EmailId}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2 ' : 'border-gray-300'} px-1 min-w-[350px] flex flex-wrap gap-1 p-1`}
                            >
                                {data.classes?.split(',')?.map((className, index) => (
                                    <div
                                        key={index}
                                        className={`whitespace-nowrap px-1 ${allClassesName.includes(className) ? 'bg-slate-300' : 'bg-red-300'} rounded text-xs`}
                                    >
                                        {className}
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`border ${isPrevError ? 'border-t-0' : ''} ${isError ? 'border-y-red-400 bg-red-50 border-2 border-r-red-400' : 'border-gray-300'} px-1 min-w-[50px] flex items-center justify-center`}
                            >
                                {isValidNumber(data.photo) ? (
                                    <div className="h-4 w-4 rounded-full bg-green-300"></div>
                                ) : (
                                    <div className="h-4 w-4 rounded-full bg-red-300"></div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="w-full flex justify-between mt-4">
                {selectedCsvFileData.filter(data => verifyBulkStudentCsvRow(data, allClassesName))
                    .length > 0 ? (
                    <div className="inline-flex items-center gap-2 text-gray-600">
                        <div className="flex items-center justify-center h-7 w-7 text-[16px] rounded-full bg-gray-300">
                            i
                        </div>
                        Student data with red outline will be considered invalid and not be created!
                        <div
                            className="rounded-full border border-gray-400 p-1 inline-flex items-center gap-2 active:scale-[99%] transition-all"
                            onClick={handleExportInvaidStudentsDataToCsv}
                        >
                            <img
                                src={downloadIcon}
                                className="h-6 w-6 p-1 border rounded-full cursor-pointer"
                            ></img>
                            <div className="cursor-pointer font-semibold">
                                {' '}
                                click here to export invalid students data.
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="opacity-0">i</div>
                )}
                <button
                    className="h-[36.8px] rounded bg-primary text-sm text-white px-[15px] py-[6.4px] inline-flex items-center cursor-pointer disabled:cursor-not-allowed disabled:bg-slate-200"
                    disabled={selectedCsvFileData.length === 0}
                    onClick={() =>
                        handleBulkStudentAddSubmit(
                            selectedCsvFileData.filter(
                                data => !verifyBulkStudentCsvRow(data, allClassesName)
                            )
                        )
                    }
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default BulkStudentUploadForm;
