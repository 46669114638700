import React from 'react';
import style from './style.module.css';
import { AcademyData } from '../../../../models/academy/academy-data';

interface DropStudentFormProps {
    studentActiveAcademies: AcademyData[] | undefined;
    handleStudentDropAction: () => void;
    checkedAcademiesToDropStudent: number[];
    handleDropStudentAcademyCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DropStudentForm: React.FC<DropStudentFormProps> = ({
    studentActiveAcademies,
    checkedAcademiesToDropStudent,
    handleDropStudentAcademyCheck,
    handleStudentDropAction,
}) => {
    return (
        <div className={style.container}>
            <div className={style.dropStudentNoteHeader}>
                Select acadamies you want the student to be dropped out from:
            </div>
            <div className={style.academyCheckBoxContainer}>
                {studentActiveAcademies?.map((item, index) => (
                    <div
                        key={index}
                        className={style.checkBoxLabelWrapper}
                    >
                        <input
                            type="checkbox"
                            value={item?.id}
                            onChange={handleDropStudentAcademyCheck}
                            checked={checkedAcademiesToDropStudent.includes(item?.id)}
                        />
                        <span>{item?.attributes?.name}</span>
                    </div>
                ))}
            </div>
            <div className={style.dropBtnContainer}>
                <button
                    className={style.dropStudentBtn}
                    onClick={handleStudentDropAction}
                >
                    Drop Student
                </button>
            </div>
        </div>
    );
};

export default DropStudentForm;
