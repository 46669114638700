import React, { useState, useEffect } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { Roles } from '../../../utils/auth/roles';
import { HeaderMenuService } from '../../../services/header-menu-service';
import { UserDetailsService } from '../../../services/user-details-service';
import UserProfileIcon from '../../../assets/icons/user-profile.png';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: ReadonlyArray<string>;
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): void;
}

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const navigate = useNavigate();

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const scrollThreshold = window.innerHeight * 0.1;
            setIsScrolled(scrollPosition > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let deferredPrompt: BeforeInstallPromptEvent | null = null;

        // Event listener for beforeinstallprompt
        const beforeInstallPromptHandler: EventListenerOrEventListenerObject = (e: Event) => {
            e.preventDefault();
            deferredPrompt = e as BeforeInstallPromptEvent;
            const installAppButton = document.getElementById('installApp');
            if (installAppButton) {
                installAppButton.style.display = 'block';
            }
            console.log('✅ BeforeInstallPromptEvent fired', true);
        };

        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

        // Function to handle the installation process
        async function installApp() {
            if (deferredPrompt) {
                deferredPrompt.prompt();
                console.log('🆗 Installation Dialog opened');
                const { outcome } = await deferredPrompt.userChoice;
                deferredPrompt = null;
                if (outcome === 'accepted') {
                    const installAppButton = document.getElementById('installApp');
                    if (installAppButton) {
                        installAppButton.style.display = 'hidden';
                    }
                    console.log('😀 User accepted the install prompt.', true);
                } else if (outcome === 'dismissed') {
                    console.log('😟 User dismissed the install prompt');
                }
            }
        }

        // Attach event listener to the button
        const button = document.getElementById('installApp');
        if (button) {
            button.addEventListener('click', installApp);
        }

        // Clean-up function to remove event listener when unmounting
        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
            if (button) {
                button.removeEventListener('click', installApp);
            }
        };
    }, []);

    const userState = useSelector((state: RootState) => state.user);
    const [userImage, setUserImage] = useState('');

    useEffect(() => {
        if(userState?.user?.role?.type === Roles.AUTHENTICATED_USER){
            HeaderMenuService.instance.getSupplierDetails(userState?.user?.id ?? 0).then((data) => {
                setUserImage(data?.data?.data[0]?.attributes?.photo?.data?.attributes?.url ?? '')
            })
        }else if(userState?.user?.role?.type === Roles.RESTRICTED_USER) {
            UserDetailsService.instance.getUserDetails(userState?.user?.id ?? 0).then((data) => {
                setUserImage(data?.data?.data[0].attributes?.photo?.data?.attributes?.url ?? '')
            })
        }else{
            return
        }
    }, [userState]);

    
    return (
        <header
            className={`fixed py-2 bg-white z-100 ipad_pro:pt-3 ipad_pro:pb-3 z-50 transition-all ${
                isScrolled
                    ? 'bg-[#fff8] backdrop-blur lg:m-2 lg:mr-2 w-[99%] rounded-xl shadow-sm'
                    : 'w-[100%]'
            }`}
        >
            <nav className="flex justify-between items-center w-[92%] mx-auto">
                <div>
                    <div onClick={() => navigate(Routes.LandingPage)} className="flex flex-row align-middle justify-center gap-1 cursor-pointer">
                        <img
                            className="w-8 "
                            src="/icons/navLogo.svg"
                            alt="..."
                        />
                        <div className=" xss:text-lg lg:text-xl font-semibold flex align-middle justify-center text-sm">
                            FOR ACADEMIES
                        </div>
                    </div>
                </div>
                <div
                    className={`p-4 lg:p-2 flex align-middle justify-center lg:text-left bg-transparent z-0 nav-links duration-500 lg:static absolute lg:min-h-fit left-0 top-[110%] lg:w-auto m-3 w-[96%] items-center px-5 ${
                        !isNavOpen ? 'hidden lg:block' : 'bg-white rounded border'
                    }`}
                >
                    <ul className="flex lg:flex-row flex-col lg:items-center lg:gap-[4vw] gap-8 text-lg lg:text-base text-justify">
                        <li onClick={() => navigate(`/#Customer`)} className="text-justify">
                            <a
                                className="hover:text-gray-500 text-justify"
                                href="#Customer"
                            >
                                Our Customers
                            </a>
                        </li>
                        <li onClick={() => navigate(`/#Advantage`)} className="text-justify">
                            <a
                                className="hover:text-gray-500 text-justify"
                                href="#Advantage"
                            >
                                SpArts Advantage
                            </a>
                        </li>
                        <li onClick={() => navigate(Routes.BlogsList)} className="text-justify cursor-pointer">
                            <div
                                className="hover:text-gray-500 text-justify"
                            >
                                Blogs
                            </div>
                        </li>
                        <li className="text-justify">
                            <a
                                className="hover:text-gray-500 text-justify cursor-pointer"
                                href={`https://students.sparts.app/contact-us`}
                            >
                                Contact Us
                            </a>
                        </li>
                        {userState?.user?.id ? (
                            <>
                                <hr className='lg:hidden'/>
                                <li className='w-8 h-8 flex items-center gap-2 rounded hover:bg-slate-200 transition-all cursor-pointer lg:hidden'>
                                    <img
                                        className="w-full h-full rounded-full cursor-pointer shadow-lg hover:scale-105 active:scale-95 transition-all duration-300"
                                        src={userImage === '' ? UserProfileIcon : userImage}
                                        alt="User Profile"
                                        onClick={() => navigate(Routes.Dashboard, { replace: false })}
                                    />
                                    <div>Dashboard</div>
                                </li>
                            </>
                        ) : (
                            <li className="text-justify lg:hidden">
                                <a
                                    className="hover:text-gray-500 text-justify cursor-pointer"
                                    onClick={() => navigate(Routes.Login, { replace: false })}
                                >
                                    Sign In
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="flex items-center gap-2 sm:gap-6">
                    <div className="flex items-center justify-center m-2">
                        <button
                            className="hidden text-sm sm:text-md px-2 sm:px-4 py-[4px] sm:py-[6px] border-slate-700 border-2 sm:border-4 hover:bg-slate-700 rounded-lg font-semibold hover:text-white opacity-50 hover:opacity-100 hover:shadow-2xl ease-in duration-150 hover:scale-105"
                            id="installApp"
                        >
                            <span className="hidden sm:inline">Install SpArts</span>
                            <span className="sm:hidden">Install</span>
                        </button>
                    </div>
                    {userState?.user?.id ? (
                        <div className='hidden lg:block w-12 h-12'>
                            <img
                                className="w-full h-full rounded-full cursor-pointer shadow-lg hover:scale-105 active:scale-95 transition-all duration-300"
                                src={userImage === '' ? UserProfileIcon : userImage}
                                alt="User Profile"
                                onClick={() => navigate(Routes.Dashboard, { replace: false })}
                            />
                        </div>
                    ) : (
                        <>
                            <div
                                className="text-sm lg:text-lg hover:text-gray-500 hidden lg:block cursor-pointer"
                                onClick={() => navigate(Routes.Login, { replace: false })}
                            >
                                Sign In
                            </div>
                            <button
                                className={`bg-[#F6B40A] sm:text-lg text-white px-3 py-2 sm:px-5 sm:py-2 rounded-full hover:bg-[#FFED00] text-xs`}
                            >
                                <a href="#demo">Book Demo</a>
                            </button>
                        </>
                    )}
                    <RxHamburgerMenu
                        name="menu"
                        className="text-3xl cursor-pointer lg:hidden"
                        onClick={toggleNav}
                    />
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
