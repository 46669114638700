export const DATA_FILTER_LIST = [
    {
        id: '1',
        name: 'Academy',
        filters: [
            {
                id: '1',
                name: 'Academy 1',
            },
            {
                id: '2',
                name: 'Academy 2',
            },
        ],
    },
    {
        id: '2',
        name: 'Discipline',
        filters: [
            {
                id: '1',
                name: 'Badminton',
            },
            {
                id: '2',
                name: 'Yoga',
            },
        ],
    },
    {
        id: '3',
        name: 'Batches',
        filters: [
            {
                id: '1',
                name: 'Group classes',
            },
            {
                id: '2',
                name: 'Single classes',
            },
            {
                id: '3',
                name: 'Double classes',
            },
        ],
    },
];

export const ENROLLMENT_STATUS_TYPES = [
    {
        value: 'FORM_SUBMITTED',
        label: 'Form submitted'
    },
    {
        value: 'STUDENT_ADDED',
        label: 'Student added'
    },
    {
        value: 'REJECTED',
        label: 'Rejected'
    },
    {
        value: 'SUBMISSION_PENDING',
        label: 'Submission pending'
    }
];

export const DATA_STUDENTS_LIST = [
    {
        name: 'John Doe',
        parent: 'Jane Doe',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 123-456-7890',
        emailId: 'john.doe@example.com',
    },
    {
        name: 'Alice Smith',
        parent: 'Robert Smith',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 234-567-8901',
        emailId: 'alice.smith@example.com',
    },
    {
        name: 'Bob Johnson',
        parent: 'Sara Johnson',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 345-678-9012',
        emailId: 'bob.johnson@example.com',
    },
    {
        name: 'Charlie Brown',
        parent: 'Lucy Brown',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 456-789-0123',
        emailId: 'charlie.brown@example.com',
    },
    {
        name: 'David Williams',
        parent: 'Nina Williams',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 567-890-1234',
        emailId: 'david.williams@example.com',
    },
    {
        name: 'Eva Davis',
        parent: 'Tom Davis',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 678-901-2345',
        emailId: 'eva.davis@example.com',
    },
    {
        name: 'Frank Martinez',
        parent: 'Lily Martinez',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 789-012-3456',
        emailId: 'frank.martinez@example.com',
    },
    {
        name: 'Grace Wilson',
        parent: 'Rick Wilson',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 890-123-4567',
        emailId: 'grace.wilson@example.com',
    },
    {
        name: 'Hank Thompson',
        parent: 'Ella Thompson',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 901-234-5678',
        emailId: 'hank.thompson@example.com',
    },
    {
        name: 'Isabel White',
        parent: 'Max White',
        image: 'https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg',
        contact: '+1 012-345-6789',
        emailId: 'isabel.white@example.com',
    },
];
{
    /* temporarily commented out for demo */
}
export const DROPDOWN_LISTS = [
    { label: 'View Profile', action: 'GO_TO_DETAIL' },
    { label: 'Fees History', action: 'GO_TO_FEE_HISTORY' },
    { label: 'Collect Fees', action: 'GO_TO_COLLECT_FEE' },
    { label: 'Record Leave', action: 'OPEN_RECORD_LEAVE_FORM' },
    { label: 'Dropout', action: 'OPEN_STUDENT_DROPOUT_PROMPT' },
    { label: 'Edit Student', action: 'OPEN_STUDENT_EDIT_FORM' },
];

export const ageOptions = [
    { value: 'under6', label: '6 and below' },
    { value: '6', label: '6 - 7' },
    { value: '7', label: '7 - 8' },
    { value: '8', label: '8 - 9' },
    { value: '9', label: '9 - 10' },
    { value: '10', label: '10 - 11' },
    { value: '11', label: '11 - 12' },
    { value: '12', label: '12 - 13' },
    { value: '13', label: '13 - 14' },
    { value: '14', label: '14 - 15' },
    { value: '15plus', label: '15 and above' },
];

export const statusOptions = [
    { value: -1, label: 'All (default)' },
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
];
