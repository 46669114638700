import React, { useEffect, useMemo, useState } from 'react';
import AnnouncementViewModel from '../../view-models/announcement-view-model';
import style from './style.module.css';
import Select from 'react-select';
import Loader from '../../components/bits/loader';
import chevrondownIcon from '../../../assets/icons/chevrondown.png';
import chevronUpIcon from '../../../assets/icons/up-arrow.png';
import { key } from 'localforage';
import { getFormattedDate } from '../../../utils/helpers/helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Roles } from '../../../utils/auth/roles';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import arrowRightIcon from '../../../assets/icons/chevron-right.png'
import FullScreenLoader from '../../components/bits/fullscreen-loader';

const Announcement = () => {
    const {
        state,
        setOpenTab,
        userState,
        dispatch,
        handleFileChange,
        fileUploaded,
        isAcademyImagePosting,
        handleFormInputChange,
        formData,
        handleSubmit,
        mapClassesToOptions,
        mapStudentsToOptions,
        announcementData,
        isStudentListFetchSuccess,
        isExpanded,
        handleViewClick,
        isMinimizeBarHovered,
        setIsMinimizeBarHovered,
        isRecentAnnoncementsListVisible,
        handleOpenCloseRecentAnnouncementSideBar,
        isNewAnnouncementLoading
    } = AnnouncementViewModel();

    const renderMainContent = useMemo(() => {
        return (
            <div className="z-10 relative sm:h-[calc(100vh-192px)] h-[calc(100vh-146px)]">
                {isNewAnnouncementLoading  && <FullScreenLoader />}
                {state.openTab === 1 && (
                    <div className={style.contentContainerMain}>
                        <div className={`bg-white ${isRecentAnnoncementsListVisible ? 'sm:w-[50%] w-0 opacity-0 sm:opacity-100' : 'sm:w-full w-full'} sm:transition-all transition-opacity h-full max-h-full overflow-y-auto thin-scrollbar`}>
                            <div className='flex w-full h-full max-h-full overflow-hidden'>
                                <div className="w-full flex-grow">
                                    <div className={`${style.header} pt-3 pl-3 mb-4`}>
                                        Add announcement
                                    </div>
                                    <form
                                        onSubmit={handleSubmit}
                                        className="flex flex-col p-3 gap-2"
                                    >
                                        <div className="flex flex-col">
                                            <label className="font-bold mb-1">
                                                Title: <span className="text-red-400">&#42;</span>
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                value={formData.title}
                                                onChange={e => {
                                                    handleFormInputChange('title', e.target.value);
                                                }}
                                                placeholder="Add a title"
                                                className="p-2 text-lg mt-1"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="font-bold mb-1">
                                                Description:{' '}
                                                <span className="text-red-400">&#42;</span>
                                            </label>
                                            <ReactQuill
                                                theme="snow"
                                                value={formData.description}
                                                onChange={value =>
                                                    handleFormInputChange('description', value)
                                                }
                                                className="h-[240px] mb-[43px]"
                                            />
                                        </div>
                                        <div className={style.photoContainer}>
                                            <div className={style.formInputLabel}>
                                                Send a file <span className="text-red-400"></span>
                                                <span className={style.imageMaxSizePrompt}>
                                                    (max 10MB):
                                                </span>
                                            </div>
                                            <div className="flex flex-col-reverse">
                                                <div className={style.formImageInputBtnWrapper}>
                                                    <label
                                                        htmlFor="fileInput"
                                                        className={style.formImageInputBtnLabel}
                                                    >
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            className={style.formImageInput}
                                                            onChange={handleFileChange}
                                                            accept="image/*,application/pdf,audio/*,video/*"
                                                        />
                                                        <div className={style.formImageInputLabel}>
                                                            <div className="text-black">
                                                                Select File
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                {fileUploaded !== 0 && !isAcademyImagePosting ? (
                                                    <div className="h-[60px] w-[160px] min-h-[60px] min-w-[160px] border mb-1 text-center flex justify-center items-center">
                                                        File Uploaded
                                                    </div>
                                                ) : (
                                                    <div className="min-h-[60px] h-[60px] w-[160px] text-center min-w-[160px] border mb-1 flex justify-center items-center text-gray-500 ">
                                                        {!isAcademyImagePosting ? (
                                                            'No file uploaded'
                                                        ) : (
                                                            <Loader />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex justify-between items-center w-full gap-8">
                                            <div className="sm:w-1/2 w-full">
                                                <label className={style.formInputLabel}>
                                                    Send to:{' '}
                                                    <span className="text-red-400">&#42;</span>
                                                </label>
                                                <select
                                                    required
                                                    value={formData.selectedInput || ''}
                                                    onChange={e =>
                                                        handleFormInputChange(
                                                            'selectedInput',
                                                            e.target.value
                                                        )
                                                    }
                                                    className={style.formInput}
                                                >
                                                    <option value=""></option>
                                                    <option value="all">All</option>
                                                    <option value="class">Classes</option>
                                                    <option value="students">Students</option>
                                                </select>
                                            </div>
                                            <div className="sm:w-1/2 w-full">
                                                {formData.selectedInput === 'class' && (
                                                    <>
                                                        <label className={style.formInputLabel}>
                                                            Select Classes:{' '}
                                                            <span className="text-red-400">
                                                                &#42;
                                                            </span>
                                                        </label>
                                                        <Select
                                                            isMulti
                                                            options={mapClassesToOptions()}
                                                            className={style.formInput}
                                                            classNamePrefix="Select Prefix"
                                                            onChange={item =>
                                                                handleFormInputChange(
                                                                    'forClasses',
                                                                    item.map(({ value }) => value)
                                                                )
                                                            }
                                                            value={mapClassesToOptions().filter(
                                                                option =>
                                                                    formData.forClasses?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                        />
                                                    </>
                                                )}
                                                {formData.selectedInput === 'students' && (
                                                    <>
                                                        <label className={style.formInputLabel}>
                                                            Select Students:{' '}
                                                            <span className="text-red-400">
                                                                &#42;
                                                            </span>
                                                        </label>

                                                        <Select
                                                            isMulti
                                                            options={mapStudentsToOptions()}
                                                            className={style.formInput}
                                                            classNamePrefix="Select Prefix"
                                                            onChange={item =>
                                                                handleFormInputChange(
                                                                    'forStudents',
                                                                    item.map(({ value }) => value)
                                                                )
                                                            }
                                                            value={mapStudentsToOptions().filter(option =>
                                                                    formData.forStudents?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                type="submit"
                                                className="bg-gray-500 text-white border-none p-2 cursor-pointer hover:bg-gray-600 rounded"
                                            >
                                                Create Announcement
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div
                                    className="w-[20px] h-full max-h-full cursor-pointer bg-slate-300 hover:bg-slate-400 transition-all flex items-center justify-center"
                                    onMouseEnter={() => setIsMinimizeBarHovered(true)}
                                    onMouseLeave={() => setIsMinimizeBarHovered(false)}
                                    onClick={handleOpenCloseRecentAnnouncementSideBar}
                                >
                                    <img
                                        src={arrowRightIcon}
                                        className={`h-6 w-6 ${isRecentAnnoncementsListVisible ? '' : 'rotate-180'}`}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${isRecentAnnoncementsListVisible ? 'sm:w-[50%] w-full' : 'w-0 opacity-0 sm:opacity-100'} sm:transition-all transition-opacity`}
                        >
                            <div
                                className={`flex-grow h-full flex flex-col transition-opacity duration-300 ${isRecentAnnoncementsListVisible ? 'sm:block' : 'hidden'} ${isMinimizeBarHovered ? 'sm:opacity-30' : 'opacity-100'}`}
                            >
                                <div className={`${style.header} pt-3 pl-3 mb-4`}>
                                    Most recent Annoucement
                                </div>
                                <div className="flex flex-col flex-grow bg-white">
                                    {announcementData?.data?.data &&
                                    announcementData?.data?.data.length > 0 ? (
                                        announcementData?.data?.data
                                            .slice(-5)
                                            .reverse()
                                            .map((item, index) => {

                                                return (
                                                    <div
                                                        className="flex flex-col"
                                                        key={index}
                                                    >
                                                        <div className="sm:h-[60px] h-24 flex border bg-white justify-between p-4">
                                                            <div className="flex flex-col justify-center w-[40%]">
                                                                <div className="font-medium text-base overflow-clip text-ellipsis whitespace-nowrap">
                                                                    {item?.attributes?.title ?? ''}
                                                                </div>
                                                                <div className="text-slate-600 text-sm ">
                                                                    Date:{' '}
                                                                    {item.attributes.createdAt &&
                                                                        getFormattedDate(
                                                                            item?.attributes?.createdAt
                                                                        )}
                                                                </div>
                                                                {/* No function to schedule an announcement currently */}
                                                            </div>
                                                            <div
                                                                className={`${style.listItemContact} group flex items-center`}
                                                            >
                                                                {item?.attributes?.forAll?.data !== null && (
                                                                    <>All Students</>
                                                                )}
                                                                {item?.attributes?.forClasses &&
                                                                    item?.attributes?.forClasses
                                                                        ?.data.length > 0 && (
                                                                        <>
                                                                            <span className=" font-normal">
                                                                                {item?.attributes
                                                                                    ?.forClasses
                                                                                    ?.data
                                                                                    ?.length ===
                                                                                1 ? (
                                                                                    item?.attributes
                                                                                        ?.forClasses
                                                                                        ?.data[0]
                                                                                        .attributes
                                                                                        .class_name
                                                                                ) : (
                                                                                    <>
                                                                                        {`${
                                                                                            item
                                                                                                ?.attributes
                                                                                                ?.forClasses
                                                                                                ?.data[0]
                                                                                                ?.attributes
                                                                                                .class_name
                                                                                        } and ${item?.attributes?.forClasses?.data.length - 1} `}
                                                                                        <span className="underline text-blue-700 group cursor-pointer">
                                                                                            more
                                                                                        </span>
                                                                                        <ul className="bg-gray-300 absolute py-0.5 text-start w-fit z-10 border-solid border-b-gray-400 ml-[77px] -mt-6 sm:ml-32 px-1 hidden group-hover:flex flex-col">
                                                                                            {item?.attributes?.forClasses?.data.map(
                                                                                                (
                                                                                                    item,
                                                                                                    i
                                                                                                ) => (
                                                                                                    <li
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            item
                                                                                                                ?.attributes
                                                                                                                .class_name
                                                                                                        }
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                {item?.attributes?.forStudents &&
                                                                    item?.attributes.forStudents
                                                                        ?.data.length > 0 && (
                                                                        <>
                                                                            Students -{' '}
                                                                            <span className=" font-normal">
                                                                                {item?.attributes
                                                                                    ?.forStudents
                                                                                    ?.data
                                                                                    ?.length ===
                                                                                1 ? (
                                                                                    `${
                                                                                        item
                                                                                            ?.attributes
                                                                                            ?.forStudents
                                                                                            ?.data[0]
                                                                                            .attributes
                                                                                            .firstName
                                                                                    } ${
                                                                                        item
                                                                                            ?.attributes
                                                                                            ?.forStudents
                                                                                            ?.data[0]
                                                                                            .attributes
                                                                                            .lastName
                                                                                    }`
                                                                                ) : (
                                                                                    <>
                                                                                        {`${
                                                                                            item
                                                                                                ?.attributes
                                                                                                ?.forStudents
                                                                                                ?.data[0]
                                                                                                ?.attributes
                                                                                                .firstName
                                                                                        } ${item?.attributes?.forStudents?.data[0]?.attributes.lastName} and ${item?.attributes?.forStudents?.data.length - 1} `}
                                                                                        <span
                                                                                            className={`underline text-blue-700 group cursor-pointer`}
                                                                                        >
                                                                                            more
                                                                                        </span>
                                                                                        <ul className=" bg-gray-300 absolute py-0.5 text-start w-fit z-10 border-solid border-b-gray-400 ml-[77px] -mt-6 sm:ml-20 px-1 hidden group-hover:flex flex-col">
                                                                                            {item?.attributes?.forStudents?.data.map(
                                                                                                (
                                                                                                    item,
                                                                                                    i
                                                                                                ) => (
                                                                                                    <li
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    >
                                                                                                        {`${item?.attributes.firstName} ${item?.attributes.lastName}`}
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                            </div>
                                                            <div
                                                                className="flex gap-2 justify-end items-center cursor-pointer text-sm w-[30%]"
                                                                onClick={() => handleViewClick(index)}
                                                            >
                                                                <div className="text-blue-500 flex justify-center font-medium text-sm ">
                                                                    {isExpanded === -1 ? (
                                                                        <>View Details</>
                                                                    ) : (
                                                                        <>Hide Details</>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {isExpanded === index && (
                                                            <div className="bg-white px-4 text-start border-8 py-1 ">
                                                                <div className="font-semibold text-slate-500">
                                                                    {/* Description: */}
                                                                </div>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            item?.attributes
                                                                                ?.description ?? '',
                                                                    }}
                                                                    className="reset-all border-2 p-2 my-2 rounded"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })
                                    ) : (
                                        <div className="m-auto text-2xl text-center w-full px-3 mt-10 text-slate-500">
                                            No new announcement
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="w-full px-2 py-4 bg-primary text-center text-white font-semibold rounded sm:hidden cursor-pointer"
                                    onClick={handleOpenCloseRecentAnnouncementSideBar}
                                >
                                    New Announcement
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {state.openTab === 2 && (
                    <div className={style.contentContainerMain}>
                        <div className="bg-white w-full">
                            <div className="">
                                {announcementData?.data?.data &&
                                announcementData?.data?.data.length > 0 ? (
                                    announcementData?.data?.data
                                        .slice()
                                        .reverse()
                                        .map((item, index) => {
                                            return (
                                                <div
                                                    className="flex flex-col"
                                                    key={index}
                                                >
                                                    <div className="sm:h-[60px] h-24 flex border bg-white justify-between p-4">
                                                        <div className="flex flex-col justify-center w-[40%]">
                                                            <div className="font-medium text-base overflow-clip text-ellipsis whitespace-nowrap">
                                                                {item?.attributes?.title ?? ''}
                                                            </div>
                                                            <div className="text-slate-600 text-sm ">
                                                                Date:{' '}
                                                                {item.attributes.createdAt &&
                                                                    getFormattedDate(
                                                                        item?.attributes?.createdAt
                                                                    )}
                                                            </div>
                                                            {/* No function to schedule an announcement currently */}
                                                        </div>
                                                        <div className="flex flex-col justify-center w-[30%] text-center">
                                                            <div
                                                                className={`${style.listItemContact} group`}
                                                            >
                                                                {item?.attributes?.forAll?.data !==
                                                                    null && (
                                                                    <>All Students</>
                                                                )}
                                                                {item?.attributes?.forClasses &&
                                                                    item?.attributes?.forClasses
                                                                        ?.data.length > 0 && (
                                                                        <>
                                                                            <span className=" font-normal">
                                                                                {item?.attributes
                                                                                    ?.forClasses
                                                                                    ?.data
                                                                                    ?.length ===
                                                                                1 ? (
                                                                                    item?.attributes
                                                                                        ?.forClasses
                                                                                        ?.data[0]
                                                                                        .attributes
                                                                                        .class_name
                                                                                ) : (
                                                                                    <>
                                                                                        {`${
                                                                                            item
                                                                                                ?.attributes
                                                                                                ?.forClasses
                                                                                                ?.data[0]
                                                                                                ?.attributes
                                                                                                .class_name
                                                                                        } and ${item?.attributes?.forClasses?.data.length - 1} `}
                                                                                        <span className="underline text-blue-700 group cursor-pointer">
                                                                                            more
                                                                                        </span>
                                                                                        <ul className="bg-gray-300 absolute py-0.5 text-start w-fit z-10 border-solid border-b-gray-400 ml-[77px] -mt-6 sm:ml-72 px-1 hidden group-hover:flex flex-col">
                                                                                            {item?.attributes?.forClasses?.data.map(
                                                                                                (
                                                                                                    item,
                                                                                                    i
                                                                                                ) => (
                                                                                                    <li
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            item
                                                                                                                ?.attributes
                                                                                                                .class_name
                                                                                                        }
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                {item?.attributes?.forStudents &&
                                                                    item?.attributes.forStudents
                                                                        ?.data.length > 0 && (
                                                                        <>
                                                                            Students -{' '}
                                                                            <span className=" font-normal">
                                                                                {item?.attributes
                                                                                    ?.forStudents
                                                                                    ?.data
                                                                                    ?.length ===
                                                                                1 ? (
                                                                                    `${
                                                                                        item
                                                                                            ?.attributes
                                                                                            ?.forStudents
                                                                                            ?.data[0]
                                                                                            .attributes
                                                                                            .firstName
                                                                                    } ${
                                                                                        item
                                                                                            ?.attributes
                                                                                            ?.forStudents
                                                                                            ?.data[0]
                                                                                            .attributes
                                                                                            .lastName
                                                                                    }`
                                                                                ) : (
                                                                                    <>
                                                                                        {`${
                                                                                            item
                                                                                                ?.attributes
                                                                                                ?.forStudents
                                                                                                ?.data[0]
                                                                                                ?.attributes
                                                                                                .firstName
                                                                                        } ${item?.attributes?.forStudents?.data[0]?.attributes.lastName} and ${item?.attributes?.forStudents?.data.length - 1} `}
                                                                                        <span
                                                                                            className={`underline text-blue-700 group cursor-pointer`}
                                                                                        >
                                                                                            more
                                                                                        </span>
                                                                                        <ul className=" bg-gray-300 absolute py-0.5 text-start w-fit z-10 border-solid border-b-gray-400 ml-[77px] -mt-6 sm:ml-56 px-1 hidden group-hover:flex flex-col">
                                                                                            {item?.attributes?.forStudents?.data.map(
                                                                                                (
                                                                                                    item,
                                                                                                    i
                                                                                                ) => (
                                                                                                    <li
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    >
                                                                                                        {`${item?.attributes.firstName} ${item?.attributes.lastName}`}
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex gap-2 justify-end items-center cursor-pointer text-sm w-[30%]"
                                                            onClick={() => handleViewClick(index)}
                                                        >
                                                            <div className="text-blue-500 flex justify-center font-medium text-sm ">
                                                                {isExpanded === -1 ? (
                                                                    <>View Details</>
                                                                ) : (
                                                                    <>Hide Details</>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {isExpanded === index && (
                                                        <div className="bg-white px-4 text-start border-8 py-1 ">
                                                            <div className="font-semibold text-slate-500">
                                                                {/* Description: */}
                                                            </div>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        item?.attributes
                                                                            ?.description ?? '',
                                                                }}
                                                                className="reset-all border-2 p-2 my-2 rounded"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div className="flex justify-center m-auto text-2xl text-center w-full px-3 text-slate-500 h-[160px] items-center">
                                        No new announcement
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }, [
        state.openTab,
        formData,
        fileUploaded,
        isAcademyImagePosting,
        isStudentListFetchSuccess,
        announcementData,
        isExpanded,
        mapClassesToOptions,
        mapStudentsToOptions,
    ]);

    return (
        <>
            {userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                <>
                    <ul
                        className={style.tabListContainer}
                        role="tablist"
                    >
                        <li
                            className={
                                style.tabListItem +
                                ` ${
                                    state.openTab === 1
                                        ? 'bg-white shadow-2xl border-2 border-gray-500 '
                                        : 'bg-transparent border-2 border-gray-300 scale-95'
                                }`
                            }
                        >
                            <a
                                id="pendingFeeTab"
                                className={style.tabListItemLink}
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'setCurrentPage',
                                        payload: 1,
                                    });
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Announcements
                            </a>
                        </li>
                        <li
                            className={
                                style.tabListItem +
                                ` ${
                                    state.openTab === 2
                                        ? 'bg-white shadow-2xl border-2 border-gray-500 '
                                        : 'bg-transparent border-2 border-gray-300 scale-95'
                                }`
                            }
                        >
                            <a
                                id="receivedFeeTab"
                                className={style.tabListItemLink}
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'setCurrentPage',
                                        payload: 1,
                                    });
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Archive
                            </a>
                        </li>
                    </ul>
                    {renderMainContent}
                </>
            ) : (
                <AccessDeniedPage />
            )}
        </>
    );
};

export default Announcement;
