import React, { FormEvent } from 'react'
import { CertificationEventFormDataType } from '../../../view-models/actions-view-models/add-certification-event-view-model';
import MinusIcon from '../../../../assets/icons/minus.png';
import { StudentData } from '../../../../models/student/student-data';
import defaultProfilePic from '../../../../assets/icons/user.png';
import { StudentCertificateRecordData } from '../../../../models/certifications/student-certificate-record-data';
import ArrowRightIcon from '../../../../assets/icons/arrow-right.png'
import { CertificationData } from '../../../../models/certifications/certification-data';

interface AddEditCertificationEventForm {
    handleInputChange: (value: string | number, field: string) => void;
    certificationEventFormData: CertificationEventFormDataType;
    handleAddInstructor: () => void;
    handleAddChiefExaminer: () => void;
    handleAddExternalExaminer: () => void;
    handleRemoveInstructor: (index: number) => void;
    handleRemoveChiefExaminer: (index: number) => void;
    handleRemoveExternalExaminer: (index: number) => void;
    handleFormSubmit: (e: FormEvent) => void;
    expandedSection: 'EVENT-DETAILS' | 'INSTRUCTORS' | 'EXAMINERS' | 'STUDENTS';
    setExpandedSection: (value: 'EVENT-DETAILS' | 'INSTRUCTORS' | 'EXAMINERS' | 'STUDENTS') => void;
    allStudentsOFSelectedAcademyAndDiscipline: StudentData[];
    serchTerm: string
    setSearchTerm: (value: string) => void
    isSearchInputOnFocus: boolean
    setIsSearchInputOnFocus: (value: boolean) => void;
    handleAddStudent: (id: number) => void;
    handleRemoveStudent: (id: number) => void;
    expandedStudentCard: number | null;
    handleExpandStudentCard: (id: number) => void;
    allStudentCertificationRecordsOfDiscipline: StudentCertificateRecordData[];
    certificationsList: CertificationData[];
    handleStudentPromotionLevelChange: (studentId: number, promotionLevel: number) => void;
    isEditing: boolean;
}

const AddEditCertificationEventForm = ({
    handleInputChange,
    certificationEventFormData,
    handleAddInstructor,
    handleAddChiefExaminer,
    handleAddExternalExaminer,
    handleRemoveInstructor,
    handleRemoveChiefExaminer,
    handleRemoveExternalExaminer,
    handleFormSubmit,
    expandedSection,
    setExpandedSection,
    allStudentsOFSelectedAcademyAndDiscipline,
    serchTerm,
    setSearchTerm,
    isSearchInputOnFocus,
    handleExpandStudentCard,
    setIsSearchInputOnFocus,
    handleAddStudent,
    handleRemoveStudent,
    expandedStudentCard,
    allStudentCertificationRecordsOfDiscipline,
    certificationsList,
    handleStudentPromotionLevelChange,
    isEditing,
}: AddEditCertificationEventForm) => {
    
  return (
    <form onSubmit={handleFormSubmit} className='w-full sm:w-[620px] h-[80vh] p-2 flex flex-col gap-1 overflow-y-auto'>
        <div>
            {expandedSection === 'EVENT-DETAILS' ? (
                <div className='p-1 px-2 rounded border'>
                    <div className='mb-4'>
                        <label className='font-normal'>Event Name</label>
                        <input 
                            className='w-full border-1 rounded'
                            type="text" 
                            required
                            value={certificationEventFormData?.eventName}
                            onChange={e => handleInputChange(e.target.value, 'eventName')}
                        />
                    </div>
                    <div className='mb-4'>
                        <label className='font-normal'>Event Date</label>
                        <input 
                            className='w-full border-1 border-[#d1d5db] rounded'
                            required
                            type="date" 
                            min={new Date().toISOString().split('T')[0]}
                            value={certificationEventFormData?.date}
                            onChange={e => handleInputChange(e.target.value, 'date')}
                        />
                    </div>
                    <div className='mb-4'>
                        <label className='font-normal'>Venue</label>
                        <input 
                            className='w-full border-1 rounded'
                            required
                            type="text" 
                            value={certificationEventFormData?.venue}
                            onChange={e => handleInputChange(e.target.value, 'venue')}
                        />
                    </div>
                    <div className='mb-4'>
                        <label className='font-normal'>Event Fees</label>
                        <div className='flex items-center h-10'>
                            <div className='w-8 h-10 bg-slate-300 text-slate-800 flex items-center justify-center rounded-l'>₹</div>
                            <input 
                                className='w-full h-10 border-1 rounded-r border-[#d1d5db]'
                                required
                                type="number" 
                                value={certificationEventFormData?.eventFees}
                                onChange={e => handleInputChange(e.target.value, 'eventFees')}
                                min={0}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='px-2 rounded font-semibold text-gray-600  flex items-center justify-between bg-slate-50 py-2 cursor-pointer' onClick={() => setExpandedSection('EVENT-DETAILS')}>
                    <div className='flex items-center gap-1'>
                        Event Details
                        {certificationEventFormData.eventName && certificationEventFormData.date && certificationEventFormData.venue && <img src='/assets/images/green-tick.png' className='h-4 w-4' />}
                    </div>
                    <img 
                        src='/assets/images/expand.png'
                        className='h-6 w-6'
                    />
                </div>
            )}
        </div>
        {expandedSection === 'INSTRUCTORS' ? (
            <div className='mb-4 p-1 px-2 rounded border'>
                <label className='font-normal'>Instructors</label>
                <div className='flex items-center gap-2'>
                    <input 
                        className='w-full border-1 rounded'
                        type="text" 
                        value={certificationEventFormData?.instructorsInputFieldValue}
                        onChange={e => handleInputChange(e.target.value, 'instructorsInputFieldValue')}
                    />
                    <div 
                        className='px-6 bg-gray-500 text-white rounded cursor-pointer py-2'
                        onClick={handleAddInstructor}
                    >
                        Add
                    </div>
                </div>
                <div className='flex flex-col border-2 rounded gap-1 p-2'>
                    {certificationEventFormData?.instructors.length === 0 && <div className='text-slate-600 text-center font-semibold'>No Instructors Added</div>}
                    {certificationEventFormData?.instructors?.map((instructor, index) => (
                        <div className='flex px-1 items-center bg-slate-200' key={index}>
                            <div className='bg-slate-600 h-full text-white w-6 flex items-center justify-center rounded'>{index + 1}</div>
                            <div className='px-2 py-1 rounded flex-grow'>
                                {instructor}
                            </div>
                            <div 
                                className='cursor-pointer bg-red-300 px-1 rounded flex items-center justify-center'
                                onClick={() => handleRemoveInstructor(index)}
                            >
                                <img 
                                    src={MinusIcon}
                                    className='h-6 w-6 invert'
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            <div className='px-2 font-semibold text-gray-600 rounded flex items-center justify-between bg-slate-50 py-2 cursor-pointer' onClick={() => setExpandedSection('INSTRUCTORS')}>
                <div className='flex items-center gap-1'>
                    Add Instructors
                    {certificationEventFormData.instructors.length > 0 && <img src='/assets/images/green-tick.png' className='h-4 w-4' />}
                </div>
                <img 
                    src='/assets/images/expand.png'
                    className='h-6 w-6'
                />
            </div>
        )}
        {expandedSection === 'EXAMINERS' ? (
            <div className='p-1 px-2 rounded border'>
                <div className='mb-4'>
                    <label className='font-normal'>Chief Examiner</label>
                    <div className='flex items-center gap-2'>
                        <input 
                            className='w-full border-1 rounded'
                            type="text" 
                            value={certificationEventFormData?.chiefExaminersInputFieldValue}
                            onChange={e => handleInputChange(e.target.value, 'chiefExaminersInputFieldValue')}
                        />
                        <div 
                            className='px-6 bg-gray-500 text-white rounded cursor-pointer py-2'
                            onClick={handleAddChiefExaminer}
                        >
                            Add
                        </div>
                    </div>
                    <div className='flex flex-col border-2 rounded gap-1 p-2'>
                        {certificationEventFormData?.chiefExaminers.length === 0 && <div className='text-slate-600 text-center font-semibold'>No Chief Examiners Added</div>}
                        {certificationEventFormData?.chiefExaminers?.map((examiner, index) => (
                            <div className='flex px-1 items-center bg-slate-200' key={index}>
                                <div className='bg-slate-600 h-full text-white w-6 flex items-center justify-center rounded'>{index + 1}</div>
                                <div className='px-2 py-1 rounded flex-grow'>
                                    {examiner}
                                </div>
                                <div 
                                    className='cursor-pointer bg-red-300 px-1 rounded flex items-center justify-center'
                                    onClick={() => handleRemoveChiefExaminer(index)}
                                >
                                    <img 
                                        src={MinusIcon}
                                        className='h-6 w-6 invert'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='mb-4'>
                    <label className='font-normal'>External Examiner</label>
                    <div className='flex items-center gap-2'>
                        <input 
                            className='w-full border-1 rounded'
                            type="text" 
                            value={certificationEventFormData?.externalExaminersInputFieldValue}
                            onChange={e => handleInputChange(e.target.value, 'externalExaminersInputFieldValue')}
                        />
                        <div 
                            className='px-6 bg-gray-500 text-white rounded cursor-pointer py-2'
                            onClick={handleAddExternalExaminer}
                        >
                            Add
                        </div>
                    </div>
                    <div className='flex flex-col border-2 rounded gap-1 p-2'>
                        {certificationEventFormData?.externalExaminers.length === 0 && <div className='text-slate-600 text-center font-semibold'>No External Examiners Added</div>}
                        {certificationEventFormData?.externalExaminers?.map((examiner, index) => (
                            <div className='flex px-1 items-center bg-slate-200' key={index}>
                                <div className='bg-slate-600 h-full text-white w-6 flex items-center justify-center rounded'>{index + 1}</div>
                                <div className='px-2 py-1 rounded flex-grow'>
                                    {examiner}
                                </div>
                                <div 
                                    className='cursor-pointer bg-red-300 px-1 rounded flex items-center justify-center'
                                    onClick={() => handleRemoveExternalExaminer(index)}
                                >
                                    <img 
                                        src={MinusIcon}
                                        className='h-6 w-6 invert'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ) : (
            <div className='px-2 font-semibold text-gray-600 rounded flex items-center justify-between bg-slate-50 py-2 cursor-pointer' onClick={() => setExpandedSection('EXAMINERS')}>
                
                <div className='flex items-center gap-1'>
                    Add Examiners
                    {certificationEventFormData.externalExaminers.length > 0 && certificationEventFormData.chiefExaminers.length > 0 && <img src='/assets/images/green-tick.png' className='h-4 w-4' />}
                </div>
                <img 
                    src='/assets/images/expand.png'
                    className='h-6 w-6'
                />
            </div>
        )}
        {expandedSection === 'STUDENTS' ? (
            <div className='p-1 px-2 rounded border'>
                <div className='relative'>
                    <input 
                        type='text'
                        className='rounded p-1 w-full'
                        placeholder='Search Students'
                        value={serchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onFocus={() => setIsSearchInputOnFocus(true)}
                        onBlur={() => setTimeout(() => setIsSearchInputOnFocus(false), 200)}
                    />
                    <div className={`absolute z-30 bg-white top-full w-full h-[200px] border shadow shadow-gray-700 overflow-y-auto thin-scrollbar transition-transform ${isSearchInputOnFocus ? 'block opacity-100' : 'opacity-0 hidden'}`}>
                        {allStudentsOFSelectedAcademyAndDiscipline
                            ?.filter(s => s?.attributes?.firstName?.toLocaleLowerCase()?.includes(serchTerm?.toLocaleLowerCase()) || s?.attributes?.lastName?.toLocaleLowerCase()?.includes(serchTerm?.toLocaleLowerCase()))
                            ?.filter(s => !certificationEventFormData?.students.map(student => student?.studentId).includes(s?.id))
                            ?.map(student => (
                                <div 
                                    key={student?.id} 
                                    className='flex items-center gap-2 p-1 px-4 bg-slate-50 rounded overflow-y-auto hover:bg-slate-200 cursor-pointer'
                                    onClick={() => handleAddStudent(student?.id)}
                                >
                                    <img 
                                        src={student?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                                        className='w-10 h-10 rounded-full object-cover'
                                    />
                                    <div className='text-gray-700 font-semibold'>
                                        {student?.attributes?.firstName} {student?.attributes?.lastName ?? ''}
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
                {certificationEventFormData?.students?.length === 0 ? (
                    <div className='text-slate-600 text-center font-semibold border-2 rounded gap-1 p-2'>No Students Added</div>
                ) : (
                    <div className='flex flex-col gap-1 p-1'>
                        {certificationEventFormData?.students?.map((student, index) => {
                            const studentsPresentCertification = allStudentCertificationRecordsOfDiscipline
                                ?.find(c => c?.attributes?.student?.data?.id === student?.studentId);
                            
                            return (
                                <div key={index}>
                                    <div 
                                        className='flex px-2 py-1 rounded items-center bg-slate-200 cursor-pointer' 
                                        onClick={() => handleExpandStudentCard(student?.studentId)}
                                    >
                                        <img 
                                            src={allStudentsOFSelectedAcademyAndDiscipline.find(s => s?.id === student?.studentId)?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                                            className='w-10 h-10 rounded-full object-cover'
                                        />
                                        <div className='px-2 py-1 rounded flex-grow'>
                                            {`${allStudentsOFSelectedAcademyAndDiscipline.find(s => s?.id === student?.studentId)?.attributes?.firstName}
                                            ${allStudentsOFSelectedAcademyAndDiscipline.find(s => s?.id === student?.studentId)?.attributes?.lastName ?? ''}`}
                                        </div>
                                        <div 
                                            className='cursor-pointer bg-red-300 px-1 rounded flex items-center justify-center'
                                            onClick={() => handleRemoveStudent(student?.studentId)}
                                        >
                                            <img 
                                                src={MinusIcon}
                                                className='h-6 w-6 invert'
                                            />
                                        </div>
                                    </div>
                                    {expandedStudentCard === student?.studentId && (
                                        <div className='p-1 px-2 rounded border'>
                                            <div className='flex items-center justify-center sm:py-10'>
                                                {studentsPresentCertification ? (
                                                    <div className='sm:w-auto w-[30%] flex flex-col items-center border-2 border-blue-500 rounded p-2 font-semibold text-slate-600'>
                                                        <div>{studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.name}</div>
                                                        <hr className='w-[95%] my-2 sm:w-full border-2 border-slate-300'/>
                                                        <select 
                                                            className='w-full sm:w-[140px] bg-slate-300 p-2 rounded text-center'
                                                            value={studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level}
                                                            disabled
                                                        >
                                                            <option 
                                                                key={studentsPresentCertification?.attributes?.certificationInfo?.data?.id} 
                                                                value={studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level}
                                                            >
                                                                Level {studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level}
                                                            </option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className='sm:w-auto w-[30%] flex flex-col items-center border-2 border-blue-500 rounded p-2 font-semibold text-slate-600'>
                                                        <div className='px-4 py-2'>Not Certified</div>
                                                    </div>
                                                )}
                                                <div>
                                                    <img 
                                                        src={ArrowRightIcon}
                                                        className='h-8 w-8 sm:h-16 sm:w-16'
                                                    />
                                                </div>
                                                <div className='sm:w-auto w-[30%] text-center flex flex-col items-center border-2 border-green-500 rounded p-2 font-semibold text-slate-600'>
                                                    {!studentsPresentCertification ? (
                                                        <>
                                                            <div>{certificationsList?.find(cert => cert?.attributes?.level === student?.promotionLevel)?.attributes?.name}</div>
                                                            <hr className='my-2 w-full border-2 border-slate-300'/>
                                                            <select 
                                                                className='w-full sm:w-[140px] p-2 rounded text-center'
                                                                value={student?.promotionLevel}
                                                                onChange={e => handleStudentPromotionLevelChange(student?.studentId, Number(e.target.value))}
                                                            >
                                                                {certificationsList?.map(cert => (
                                                                    <option key={cert?.id} value={cert?.attributes?.level}>Level {cert?.attributes?.level}</option>
                                                                ))}
                                                            </select>
                                                        </>
                                                    ) : certificationsList?.filter(cert => cert?.attributes?.level > studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level).length > 0 ? (
                                                            <>
                                                                <div>{certificationsList?.find(cert => cert?.attributes?.level === student?.promotionLevel)?.attributes?.name}</div>
                                                                <hr className='my-2 w-full border-2 border-slate-300'/>
                                                                <select 
                                                                    className='w-full sm:w-[140px] p-2 rounded text-center'
                                                                    value={student?.promotionLevel}
                                                                    onChange={e => handleStudentPromotionLevelChange(student?.studentId, Number(e.target.value))}
                                                                >
                                                                    {certificationsList?.filter(cert => cert?.attributes?.level > studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level).map(cert => (
                                                                        <option key={cert?.id} value={cert?.attributes?.level}>Level {cert?.attributes?.level}</option>
                                                                    ))}
                                                                </select>
                                                            </>
                                                        ) : (
                                                            <div className='text-slate-600 font-semibold'>No more levels to promote</div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className='rounded border-2 border-slate-200 p-2 w-full'>
                                                <div className='w-full flex items-center justify-between relative overflow-hidden'>
                                                    <div className='h-2 bg-gray-300 w-[calc(100%-16px)] absolute'></div>
                                                    {!studentsPresentCertification &&
                                                        <div className='h-12 w-12 rounded-full bg-slate-300 z-10 border-4 border-blue-500'></div>
                                                    }
                                                    {certificationsList
                                                        .filter((cert) => cert?.attributes?.level <= (student?.promotionLevel) && cert?.attributes?.level >= (studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level ?? 0))
                                                        .map((cert, index, filteredCertifications) => (
                                                            <div
                                                                key={cert?.id}
                                                                className={`h-12 w-12 rounded-full bg-slate-300 z-10 flex items-center justify-center
                                                                    ${(index === 0 && studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes) ? 'border-4 border-blue-500' : ''}
                                                                    ${index === filteredCertifications.length - 1 ? 'border-4 border-green-500' : ''}
                                                                `}
                                                            >
                                                                {cert?.attributes?.level}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className='w-full grid grid-cols-2 border rounded mt-2 bg-slate-200 p-2'>
                                                    <div>Event Fees</div>
                                                    <div className='text-right'>₹ {certificationEventFormData?.eventFees}</div>
                                                    {certificationsList
                                                        .filter((cert) => cert?.attributes?.level <= (student?.promotionLevel) && cert?.attributes?.level > (studentsPresentCertification?.attributes?.certificationInfo?.data?.attributes?.level ?? -1))
                                                        .map((cert, index, filteredCertifications) => (
                                                            <React.Fragment key={cert?.id}>
                                                                <div>{cert?.attributes?.name} ({cert?.attributes?.level})</div>
                                                                <div className='text-right'>₹ N/A</div>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                    <div className='font-semibold'>Total</div>
                                                    <div className='font-semibold text-right'>₹ {certificationEventFormData?.eventFees}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        ) : (
            <div className='px-2 font-semibold text-gray-600 rounded flex items-center justify-between bg-slate-50 py-2 cursor-pointer' onClick={() => setExpandedSection('STUDENTS')}>
                <div className='flex items-center gap-1'>
                    Add Students
                    {certificationEventFormData.students.length > 0 && <img src='/assets/images/green-tick.png' className='h-4 w-4' />}
                </div>
                <img 
                    src='/assets/images/expand.png'
                    className='h-6 w-6'
                />
            </div>
        )}
        <div className='flex justify-end mt-2 flex-grow items-end'>
            <button type='submit' className='px-6 py-2 bg-primary text-white rounded'>
                {isEditing ? 'Update Event' : 'Add Event'}
            </button>
        </div>
    </form>
  )
}

export default AddEditCertificationEventForm