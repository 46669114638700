import { DeleteJWTToken, DeleteUserState } from '../../utils/helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { setUser } from '../../utils/redux/user-slice';
import { useDispatch } from 'react-redux';

const logoutviewmodel = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(setUser(null));
        DeleteJWTToken();
        DeleteUserState();
        navigate(Routes.Login, { replace: true });
    };

    return {
        handleLogout,
    };
};

export default logoutviewmodel;
