import { User } from '../models/user/user';
import { UserData } from '../models/user/user-data';
import { UserPostData } from '../models/user/user-post-data';
import { UserRolesListWrapper } from '../models/user/user-role-list-wrapper';
import { UsersListWrapper } from '../models/user/users-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class UserService {
    private static _instance: UserService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new UserService());
    }

    public getUserRoles = async (): Promise<ApiResponse<UserRolesListWrapper>> => {
        return invokeApi<null, UserRolesListWrapper>({
            route: `users-permissions/roles`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getUserNameAndEmailsList = async (): Promise<
        ApiResponse<{ id: number; username: string; email: string }[]>
    > => {
        return invokeApi<null, { id: number; username: string; email: string }[]>({
            route: `users?fields[0]=username&fields[1]=email`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewUser = async (data: UserPostData) => {
        return invokeApi<UserPostData, User>({
            route: `users`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public updateUser = async (data: UserPostData, id: number) => {
        return invokeApi<UserPostData, User>({
            route: `users/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };

    public deleteUser = async (id: number) => {
        return invokeApi<null, User>({
            route: `users/${id}`,
            method: 'DELETE',
            privateRoute: true,
        });
    };
}
