import React, { Dispatch, useState } from 'react';
import AttendanceAiApiResponse from '../../../../utils/types/Attendance-ai-api-response';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import ApiResponse from '../../../../utils/types/api-response';
import { StudentListWrapper } from '../../../../models/student/student-list-wrapper';
import { StudentData } from '../../../../models/student/student-data';

interface AttendanceStudentEditFormProps {
    handleStudentEditFormClose: () => void;
    aiModelResponseCorrected: AttendanceAiApiResponse | undefined;
    activeStudentEdit: number;
    setAiModelResponseCorrected: React.Dispatch<React.SetStateAction<AttendanceAiApiResponse | undefined>>;
    batchName: string;
    studentSearchTerm: string;
    isStudentSearchInputOnFocus: boolean;
    setIsStudentSearchInputOnFocus: React.Dispatch<React.SetStateAction<boolean>>;
    isEditStudentDataFetchLoading: boolean;
    handleStudentSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    editStudentSuggestionListData: ApiResponse<StudentListWrapper> | undefined;
    onEditStudentSearchListItemClick: (name: StudentData) => void;
    selectedStudentInEditStudentForm: StudentData | undefined;
    handleAddStudentBtnClick: () => void;
}

const AttendanceStudentEditForm = ({
    handleStudentEditFormClose,
    aiModelResponseCorrected,
    activeStudentEdit,
    setAiModelResponseCorrected,
    batchName,
    studentSearchTerm,
    isStudentSearchInputOnFocus,
    setIsStudentSearchInputOnFocus,
    handleStudentSearchInputChange,
    isEditStudentDataFetchLoading,
    editStudentSuggestionListData,
    onEditStudentSearchListItemClick,
    selectedStudentInEditStudentForm,
    handleAddStudentBtnClick,
}: AttendanceStudentEditFormProps) => {
    const [id, setId] = useState<number>(
        aiModelResponseCorrected?.present[activeStudentEdit][1] ?? 0
    );

    return (
        <div className="sm:w-[400px] w-[86vw]">
            <div>Student Name:</div>
            <input
                placeholder="Search Students"
                className="w-full py-1 rounded border-gray-400"
                required
                value={studentSearchTerm}
                onFocus={() => setIsStudentSearchInputOnFocus(true)}
                onBlur={() => setTimeout(() => setIsStudentSearchInputOnFocus(false), 200)}
                onChange={handleStudentSearchInputChange}
            />
            {isStudentSearchInputOnFocus && (
                <div className="absolute w-[400px] max-h-48 overflow-y-auto bg-white z-10 rounded-[4px] border-2">
                    <ul className="px-2 text-xs">
                        {isEditStudentDataFetchLoading ? (
                            <li className="inline-flex p-1 items-center gap-2">
                                <div className="w-6 h-6 border-t-2 border-blue-500 border-solid border-opacity-50 rounded-full animate-spin items-center"></div>
                                Loading...
                            </li>
                        ) : editStudentSuggestionListData?.data?.data?.length === 0 ? (
                            <li className="p-1 border-b-2 cursor-pointer min-h-[34px]">
                                <div className="">No student found</div>
                            </li>
                        ) : (
                            (editStudentSuggestionListData?.data?.data ?? []).map((item, index) => (
                                <li
                                    className="p-1 border-b-2 cursor-pointer min-h-[34px]"
                                    onClick={() => {
                                        onEditStudentSearchListItemClick(item);
                                        setIsStudentSearchInputOnFocus(false);
                                        setId(item.id);
                                    }}
                                    key={index}
                                >
                                    {item && item?.attributes && (
                                        <>
                                            <span>
                                                <img
                                                    className="rounded-full h-8 w-8 inline mr-1 overflow-hidden"
                                                    src={
                                                        item?.attributes?.photo?.data?.attributes
                                                            ?.url ?? StudentProfileIcon
                                                    }
                                                    alt={`Profile image`}
                                                />
                                            </span>
                                            <span>
                                                {item?.attributes?.firstName +
                                                    ' ' +
                                                    (item.attributes.lastName
                                                        ? item.attributes.lastName
                                                        : '')}
                                            </span>
                                        </>
                                    )}
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            )}
            <br />
            <br />
            <div>Class:</div>
            <div className="text-gray-400">{batchName}</div>
            <br />
            <br />
            <div className="flex justify-end w-full gap-4">
                <button
                    className="px-2 py-[2px] border-[#f56d20] border-2 text-[#f56d20] rounded"
                    onClick={handleAddStudentBtnClick}
                >
                    Add Student
                </button>
                <button
                    className="w-32 h-8 bg-[#f56d20] text-white rounded"
                    disabled={!selectedStudentInEditStudentForm}
                    onClick={() => {
                        setAiModelResponseCorrected((prev: AttendanceAiApiResponse | undefined) => {
                            if (!prev) {
                                // Handle the case where the previous state is undefined
                                return {
                                    success: false,
                                    result: [],
                                    present: [],
                                    max_students: [],
                                    crops: [],
                                };
                            }

                            // Update the state based on the previous state
                            const updatedPresent = [...prev.present];
                            updatedPresent[activeStudentEdit][0] =
                                selectedStudentInEditStudentForm?.attributes?.firstName ||
                                '' +
                                    ' ' +
                                    selectedStudentInEditStudentForm?.attributes?.middleName ||
                                '' + ' ' + selectedStudentInEditStudentForm?.attributes?.lastName ||
                                '';
                            updatedPresent[activeStudentEdit][1] =
                                selectedStudentInEditStudentForm?.id ?? 0;
                            return {
                                ...prev,
                                present: updatedPresent,
                            };
                        });
                        handleStudentEditFormClose();
                    }}
                >
                    Save Changes
                </button>
            </div>
        </div>
    );
};

export default AttendanceStudentEditForm;
