import { DeleteJWTToken } from '../../utils/helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    ACADEMY_QUERY,
    COACH_QUERY,
    SCHEDULE_QUERY,
    STUDENT_QUERY,
    USER_DETAILS_LIST_QUERY,
    USER_QUERY,
    USER_ROLES_QUERY,
} from '../../utils/constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useEffect, useReducer, useState } from 'react';
import { CoachesState, coachesReducer } from '../reducers/coaches-reducer';
import { UploadService } from '../../services/upload-service';
import { AcademiesService } from '../../services/academies-service';
import { UserService } from '../../services/user-services';
import { UserPostData } from '../../models/user/user-post-data';
import { User } from '../../models/user/user';
import ApiResponse from '../../utils/types/api-response';
import { CoachPostDataWrapper } from '../../models/coach/coach-post-data-wrapper';
import { CoachData } from '../../models/coach/coach-data';
import ClassService from '../../services/class-service';
import { ClassData } from '../../models/class/class-data';
import { UserDetailsService } from '../../services/user-details-service';
import { UserDetailsPostDataWrapper } from '../../models/user-details/user-details-post-data-wrapper';
import { UserDetailsData } from '../../models/user-details/user-details-data';

export interface coachFormTypes {
    name: string;
    contact: string;
    email: string;
    photo: string;
    governmentId: string;
    governmentIdName: string;
    academies: number[];
    dashboardAccess: string;
    academyAccess: string;
    classesAccess: string;
    userManagementAccess: string;
    studentsAccess: string;
    feePaymentAccess: string;
    enquiriesAccess: string;
    scheduleAccess: string;
    classes: number[];
    remarks: string;
}

export interface AcademiesDisciplinesItem {
    academyId: number;
    allDisciplines: number[];
    selectedDisciplines: number[];
}

const UserManagementViewModel = () => {
    const userState = useSelector((state: RootState) => state.user);
    const initialState: CoachesState = {
        currentPage: 1,
        academies: [],
    };

    const coachFormDataInitialState = {
        name: '',
        contact: '',
        email: '',
        photo: '',
        governmentId: '',
        governmentIdName: '',
        academies: [],
        dashboardAccess: 'restrictedView',
        academyAccess: 'noAccess',
        classesAccess: 'noAccess',
        userManagementAccess: 'noAccess',
        studentsAccess: 'noAccess',
        feePaymentAccess: 'noAccess',
        enquiriesAccess: 'noAccess',
        scheduleAccess: 'noAccess',
        classes: [],
        remarks: '',
    };

    const [coachFormData, setCoachFormData] = useState<coachFormTypes>(coachFormDataInitialState);
    const [academiesDisciplinesSelected, setAcademiesDisciplinesSelected] = useState<
        AcademiesDisciplinesItem[]
    >([]);
    const [allDisciplinesOptions, setAllDisciplinesOptions] = useState<
        { value: number; label: string }[]
    >([]);
    const [checkedQuickAccess, setCheckedQuickAccess] = useState<number>(0);
    const [visibleFilter, setVisibleFilter] = useState<number>(0);
    const [isfilterModalOpen, setIsfilterModalOpen] = useState<boolean>(false);
    const [state, dispatch] = useReducer(coachesReducer, initialState);
    const [selectedAcademiesForFilter, setSelectedAcademiesForFilter] = useState<number[]>([]);
    const [isCoachAcademiesListExpanded, setIsCoachAcademiesListExpanded] =
        useState<boolean>(false);
    const [coachEditingData, setCoachEditingData] = useState<CoachData>();

    const [isNewCoachModalOpen, setIsNewCoachModalOpen] = useState<boolean>(false);
    const [isEditCoachModalOpen, setIsEditCoachModalOpen] = useState<boolean>(false);

    const itemsPerPage = 10;
    const indexOfLastItem = state.currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [expandedCoachItem, setExpandedCoachItem] = useState<number>(0);
    const [profileImageInputKey, setProfileImageInputKey] = useState<number>(0);
    const [coachDocumentInputKey, setCoachDocumentInputKey] = useState<number>(0);
    const [imageId, setImageId] = useState<number>();
    const [documentId, setDocumentId] = useState<number>();
    const queryClient = useQueryClient();
    const [currentUserNames, setCurrentUserNames] = useState<string[]>([]);
    const [currentUserEmails, setCurrentUserEmails] = useState<string[]>([]);

    const { isSuccess: isUserRolesFetchSuccess, data: userRoles } = useQuery({
        queryKey: [USER_ROLES_QUERY],
        queryFn: UserService.instance.getUserRoles,
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const { isSuccess: isUserNameAndEmailsListFetchSuccess, data: userNameAndEmailsList } =
        useQuery({
            queryKey: [coachFormData.name, coachFormData.email],
            queryFn: UserService.instance.getUserNameAndEmailsList,
            refetchOnWindowFocus: false,
            enabled: true,
        });

    useEffect(() => {
        const data = userNameAndEmailsList?.data ?? [];
        if (Array.isArray(data) && data.length > 0) {
            setCurrentUserEmails(data.map(user => user?.email));
            setCurrentUserNames(data.map(user => user?.username));
        }
    }, [userNameAndEmailsList]);

    const handleCoachItemExpand = (id: number) => {
        if (expandedCoachItem === id) {
            setExpandedCoachItem(0);
        } else {
            setExpandedCoachItem(id);
        }
    };

    const handleQuickAccessCheck = (num: number) => {
        setCheckedQuickAccess(num);
    };

    useEffect(() => {
        switch (checkedQuickAccess) {
            case 1:
                setCoachFormData(prev => ({
                    ...prev,
                    dashboardAccess: 'fullAccess',
                    academyAccess: 'fullAccess',
                    classesAccess: 'fullAccess',
                    // userManagementAccess: 'fullAccess',
                    studentsAccess: 'fullAccess',
                    feePaymentAccess: 'fullAccess',
                    enquiriesAccess: 'fullAccess',
                    scheduleAccess: 'fullAccess',
                }));
                break;
            case 2:
                setCoachFormData(prev => ({
                    ...prev,
                    dashboardAccess: 'restrictedView',
                    academyAccess: 'viewOnly',
                    classesAccess: 'viewOnly',
                    // userManagementAccess: 'noAccess',
                    studentsAccess: 'noAccess',
                    feePaymentAccess: 'noAccess',
                    enquiriesAccess: 'noAccess',
                    scheduleAccess: 'fullAccess',
                }));
                break;
            case 3:
                setCoachFormData(prev => ({
                    ...prev,
                    dashboardAccess: 'restrictedView',
                    academyAccess: 'viewOnly',
                    classesAccess: 'viewOnly',
                    // userManagementAccess: 'noAccess',
                    studentsAccess: 'noAccess',
                    feePaymentAccess: 'fullAccess',
                    enquiriesAccess: 'noAccess',
                    scheduleAccess: 'noAccess',
                }));
                break;
            default:
                break;
        }
    }, [checkedQuickAccess]);

    useEffect(() => {
        if (
            coachFormData.studentsAccess.split(',')[
                coachFormData.studentsAccess.split(',').length - 1
            ] === 'noAccess'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                studentsAccess: 'noAccess',
            }));
        } else if (
            coachFormData.studentsAccess.split(',')[
                coachFormData.studentsAccess.split(',').length - 1
            ] === 'fullAccess'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                studentsAccess: 'fullAccess',
            }));
        } else if (
            coachFormData.studentsAccess.split(',')[
                coachFormData.studentsAccess.split(',').length - 1
            ] === 'restrictedView'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                studentsAccess: prev.studentsAccess
                    .replace('fullAccess', '')
                    .replace('noAccess', ''),
            }));
        } else if (
            coachFormData.studentsAccess.split(',')[
                coachFormData.studentsAccess.split(',').length - 1
            ] === 'addOnly'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                studentsAccess: prev.studentsAccess
                    .replace('fullAccess', '')
                    .replace('noAccess', ''),
            }));
        }
    }, [coachFormData.studentsAccess]);

    useEffect(() => {
        if (
            coachFormData.feePaymentAccess.split(',')[
                coachFormData.feePaymentAccess.split(',').length - 1
            ] === 'noAccess'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                feePaymentAccess: 'noAccess',
            }));
        } else if (
            coachFormData.feePaymentAccess.split(',')[
                coachFormData.feePaymentAccess.split(',').length - 1
            ] === 'fullAccess'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                feePaymentAccess: 'fullAccess',
            }));
        } else if (
            coachFormData.feePaymentAccess.split(',')[
                coachFormData.feePaymentAccess.split(',').length - 1
            ] === 'restrictedView'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                feePaymentAccess: prev.feePaymentAccess
                    .replace('fullAccess', '')
                    .replace('noAccess', ''),
            }));
        } else if (
            coachFormData.feePaymentAccess.split(',')[
                coachFormData.feePaymentAccess.split(',').length - 1
            ] === 'addOnly'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                feePaymentAccess: prev.feePaymentAccess
                    .replace('fullAccess', '')
                    .replace('noAccess', ''),
            }));
        }
    }, [coachFormData.feePaymentAccess]);

    useEffect(() => {
        if (
            coachFormData.enquiriesAccess.split(',')[
                coachFormData.enquiriesAccess.split(',').length - 1
            ] === 'noAccess'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                enquiriesAccess: 'noAccess',
            }));
        } else if (
            coachFormData.enquiriesAccess.split(',')[
                coachFormData.enquiriesAccess.split(',').length - 1
            ] === 'fullAccess'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                enquiriesAccess: 'fullAccess',
            }));
        } else if (
            coachFormData.enquiriesAccess.split(',')[
                coachFormData.enquiriesAccess.split(',').length - 1
            ] === 'restrictedView'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                enquiriesAccess: prev.enquiriesAccess
                    .replace('fullAccess', '')
                    .replace('noAccess', ''),
            }));
        } else if (
            coachFormData.enquiriesAccess.split(',')[
                coachFormData.enquiriesAccess.split(',').length - 1
            ] === 'addOnly'
        ) {
            setCoachFormData(prev => ({
                ...prev,
                enquiriesAccess: prev.enquiriesAccess
                    .replace('fullAccess', '')
                    .replace('noAccess', ''),
            }));
        }
    }, [coachFormData.enquiriesAccess]);

    const toggleVisibleFilter = (filter: number) => {
        if (filter === visibleFilter) {
            setVisibleFilter(0);
        } else {
            setVisibleFilter(filter);
        }
    };

    const handleFilterBtnClick = () => {
        setIsfilterModalOpen(true);
    };

    const onAcademyFilterSelect = (id: number) => {
        setSelectedAcademiesForFilter(prev => {
            const index = prev.indexOf(id);
            if (index !== -1) {
                return prev.filter(item => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const {
        isSuccess: isUserDetailsDataFetchSuccess,
        isLoading: isUserDetailsDataLoading,
        data: UserDetailsData,
    } = useQuery({
        queryKey: [USER_DETAILS_LIST_QUERY],
        queryFn: UserDetailsService.instance.getUserDetailsList.bind(
            this,
            userState?.user?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isClassFetchSuccess, data: classesData } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    // useEffect(() => {
    //     setCoachFormData(prevFormData => ({
    //         ...prevFormData,
    //         discipline: prevFormData.discipline.split(',').filter(item => {
    //             return academiesData?.data?.data
    //                 .filter(academy => prevFormData.academies.includes(academy?.id))
    //                 .some(academy => academy.attributes.disciplines.split(',').includes(item));
    //         }).join(','),
    //     }));
    // }, [coachFormData.academies]);

    // useEffect(() => {
    //     setCoachFormData(prevFormData => {
    //         const remainingDisciplines = prevFormData.discipline.split(',');
    //         const remainingClasses = prevFormData.classes.filter(classId => {
    //             const classDiscipline = classesData?.data?.data.find(item => item.id === classId)?.attributes?.discipline;
    //             return classDiscipline && remainingDisciplines.includes(classDiscipline);
    //         });
    //         return {
    //             ...prevFormData,
    //             classes: remainingClasses
    //         };
    //     });
    // }, [coachFormData.discipline]);

    const mapAcademiesToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data?.map(academy => {
                const { id, attributes } = academy;
                return {
                    value: id,
                    label: attributes.name,
                };
            });
        }
        return [];
    };

    useEffect(() => {
        const selectedAcademiesData = academiesData?.data?.data.filter(acad =>
            coachFormData.academies.includes(acad?.id)
        );
        setAcademiesDisciplinesSelected(
            selectedAcademiesData?.map(acad => {
                const allClassesOfAcademy = classesData?.data?.data?.filter(
                    item => item?.attributes?.academies?.data?.id === acad?.id
                );
                const selectedClassesOfAcademy = allClassesOfAcademy?.filter(item =>
                    coachFormData.classes.includes(item?.id)
                );
                const disciplinesOfSelectedClasses =
                    selectedClassesOfAcademy
                        ?.map(item => item?.attributes?.class_discipline)
                        .map(item => item?.data?.id) ?? [];
                return {
                    academyId: acad?.id,
                    allDisciplines: acad?.attributes?.selectedDisciplines.data.map(item => item.id),
                    selectedDisciplines: acad?.attributes?.selectedDisciplines.data.map(item => item.id).length === 1 ? 
                        acad?.attributes?.selectedDisciplines.data.map(item => item.id) : 
                        acad?.attributes?.selectedDisciplines.data
                            .filter(item => disciplinesOfSelectedClasses.includes(item.id))
                            .map(item => item.id),
                };
            }) ?? []
        );

        const allDisciplinesOptions =
            selectedAcademiesData?.flatMap(
                acad =>
                    acad?.attributes?.selectedDisciplines.data.map(item => ({
                        value: item.id,
                        label: item?.attributes?.name,
                    })) ?? []
            ) ?? [];

        const uniqueDisciplines = allDisciplinesOptions.filter(
            (discipline, index, self) => index === self.findIndex(d => d.value === discipline.value)
        );

        setAllDisciplinesOptions(uniqueDisciplines);
    }, [coachFormData.academies, classesData?.data?.data]);


    useEffect(() => {
        if (academiesDisciplinesSelected.length > 0) {
            const academyIds = new Set(coachFormData.academies);

            const filteredClassIds =
                classesData?.data?.data
                    ?.filter(item => academyIds.has(item.attributes?.academies?.data?.id))
                    ?.filter(item =>
                        academiesDisciplinesSelected.some(
                            acad =>
                                acad.academyId === item.attributes?.academies?.data?.id &&
                                acad.selectedDisciplines.includes(
                                    item.attributes?.class_discipline?.data?.id
                                )
                        )
                    )
                    ?.map(classItem => classItem?.id)
                    ?.filter((id): id is number => id !== undefined) ?? [];

            setCoachFormData(prevData => ({
                ...prevData,
                classes: filteredClassIds,
            }));

            // const multidisciplinary = academiesData?.data?.data
            //     .filter(acad => coachFormData.academies.includes(acad?.id))
            //     .filter(acad => acad?.attributes?.selectedDisciplines?.data?.length > 1)
            //     .map(acad => acad?.id) ?? [];
            // setMultidisciplinaryAcademies(multidisciplinary)
        }
    }, [academiesDisciplinesSelected]);

    const handleDisciplineChange = (academyId: number, selectedDisciplines: number[]) => {
        setAcademiesDisciplinesSelected(prevData => {
            return prevData.map(acad => {
                if (acad.academyId === academyId) {
                    return {
                        ...acad,
                        selectedDisciplines,
                    };
                }
                return acad;
            });
        });
    };

    const mapClassesOfSelectedAcademiesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data && coachFormData.academies) {
            return classesData?.data?.data
                ?.filter(
                    (item: ClassData) =>
                        item?.attributes?.academies?.data &&
                        coachFormData.academies.includes(item?.attributes?.academies?.data?.id)
                )
                ?.map((item: ClassData) => ({
                    value: item?.id,
                    label: item?.attributes?.class_name,
                }));
        }
        return [];
    };

    const mapDiciplinesOfSelectedAcademiesToOptions = ():
        | { value: number; label: string }[]
        | [] => {
        if (academiesData?.data?.data && coachFormData.academies) {
            return academiesData.data.data
                .filter(academy => coachFormData.academies.includes(academy?.id))
                .flatMap(academy => {
                    return academy.attributes.selectedDisciplines?.data?.map(item => ({
                        value: item.id,
                        label: item.attributes.name,
                    }));
                });
        }
        return [];
    };

    // const mapClassesOfSelectedDisciplinesToOptions = (): {value: number; label: string}[] | [] => {
    //     if (classesData?.data?.data && coachFormData.classes) {
    //         return classesData?.data?.data
    //             .filter(item => coachFormData.discipline.split(',').includes(item?.attributes?.discipline))
    //             .map(item => {
    //                 const {id, attributes} = item;
    //                 return {
    //                     value: id,
    //                     label: attributes.class_name,
    //                 };
    //             });
    //     }
    //     return [];
    // };

    const handleFilterModalClose = () => {
        setIsfilterModalOpen(false);
    };

    const handleAddNewCoachBtnClick = () => {
        setIsNewCoachModalOpen(true);
    };

    const handleNewCoachModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsNewCoachModalOpen(false);
            setActiveUserFormTab(1);
            setCoachFormData(coachFormDataInitialState);
            setCheckedQuickAccess(0);
        } else {
            return;
        }
    };

    const handleEditCoachModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsEditCoachModalOpen(false);
            setCoachFormData(coachFormDataInitialState);
            setCoachEditingData(undefined);
            setCheckedQuickAccess(0);
            setActiveUserFormTab(1);
        } else {
            return;
        }
    };

    const PostCoachImage = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setCoachFormData(prevFormData => ({
                                ...prevFormData,
                                photo: item.url,
                            }));
                        }
                        if (item.id) {
                            setImageId(item.id);
                        }
                    });
            } else {
                // console.log(
                //     `On Post postStudentProfileImageUpload Error ${JSON.stringify(
                //         response.error,
                //     )}`,
                // );
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postStudentProfileImageUpload: ${error}`);
            throw error;
        }
    };

    const {
        mutate: UploadCoachImage,
        isLoading: isCoachProfileImagePosting,
        error: isCoachImagePostingError,
    } = useMutation(PostCoachImage, {
        onSuccess: data => {
            console.log('image upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const handleProfileImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 200) {
                    alert('Image size exceeds 200KB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadCoachImage(data);
            setProfileImageInputKey(prev => prev + 1);
        }
    };

    const PostCoachDocument = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadDocument(data);
            if (response.success) {
                response.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setCoachFormData(prevFormData => ({
                                ...prevFormData,
                                governmentId: item.url,
                                governmentIdName: item.name,
                            }));
                        }
                        if (item.id) {
                            setDocumentId(item.id);
                        }
                    });
            } else {
                // console.log(
                //     `On Post coach document Error ${JSON.stringify(
                //         response.error,
                //     )}`,
                // );
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in post coach document: ${error}`);
            throw error;
        }
    };

    const {
        mutate: UploadCoachDocument,
        isLoading: isCoachDocumentPosting,
        error: isCoachDocumentError,
    } = useMutation(PostCoachDocument, {
        onSuccess: data => {
            console.log('document upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const handleCoachDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024;
                if (fileSizeInKB > 2048) {
                    alert('File size exceeds 2MB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadCoachDocument(data);
            setCoachDocumentInputKey(prev => prev + 1);
        }
    };

    const handleInputChange = (field: string, value: string | number[]) => {
        setCoachFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const postNewCoachUser = async (data: UserPostData): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.postNewUser(data);
        if (response.success) {
            return response;
        } else {
            // console.log(
            //     `On Add coach user Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewUser,
        isLoading: isNewCoachUserAdding,
        isSuccess: isNewCoachUserAdded,
    } = useMutation(postNewCoachUser, {
        onSuccess: data => {
            addNewCoach({
                data: {
                    name: coachFormData.name,
                    contactNo: parseInt(coachFormData.contact),
                    email: coachFormData.email,
                    // discipline: coachFormData.discipline,
                    photo: imageId ?? null,
                    governmentId: documentId ?? null,
                    academies: coachFormData.academies,
                    users: [userState.user?.id ?? 0],
                    userInfo: data?.data?.id ?? 0,
                    classes: coachFormData.classes,
                    dashboardAccess: coachFormData.dashboardAccess,
                    academyAccess: coachFormData.academyAccess,
                    classesAccess: coachFormData.classesAccess,
                    userManagementAccess: coachFormData.userManagementAccess,
                    studentsAccess: coachFormData.studentsAccess,
                    feePaymentAccess: coachFormData.feePaymentAccess,
                    enquiriesAccess: coachFormData.enquiriesAccess,
                    scheduleAccess: coachFormData.scheduleAccess,
                    remarks: coachFormData.remarks,
                },
            });
        },
        onError: error => {
            // console.log(`On user add Error:`, error);
            alert('Failed to create user! Please try again');
            // Error actions
        },
    });

    const postNewCoach = async (
        data: UserDetailsPostDataWrapper
    ): Promise<ApiResponse<CoachData>> => {
        const response = await UserDetailsService.instance.postNewUserDetails(data);
        if (response.success) {
            return response;
        } else {
            await UserService.instance.deleteUser(data?.data?.userInfo);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewCoach,
        isLoading: isNewCoachAdding,
        isSuccess: isNewCoachAdded,
    } = useMutation(postNewCoach, {
        onSuccess: data => {
            alert('Added User Successfully!');
            setIsNewCoachModalOpen(false);
            setCoachFormData(coachFormDataInitialState);
            setCheckedQuickAccess(0);
            setActiveUserFormTab(1);
            queryClient.invalidateQueries(USER_DETAILS_LIST_QUERY);
        },
        onError: error => {
            alert('Failed to add User! Please try again');
        },
    });

    const editCoachUser = async (data: UserPostData): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.updateUser(
            data,
            coachEditingData?.attributes?.userInfo?.data?.id ?? 0
        );
        if (response.success) {
            return response;
        } else {
            // console.log(
            //     `On Update user user Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateCoachUser,
        isLoading: isCoachUserUpdating,
        isSuccess: isCoachUserUpdated,
    } = useMutation(editCoachUser, {
        onSuccess: data => {
            updateCoach({
                data: {
                    name: coachFormData.name,
                    contactNo: parseInt(coachFormData.contact),
                    email: coachFormData.email,
                    // discipline: coachFormData.discipline,
                    photo: imageId ?? null,
                    governmentId: documentId ?? null,
                    academies: coachFormData.academies,
                    users: [userState.user?.id ?? 0],
                    userInfo: data?.data?.id ?? 0,
                    classes: coachFormData.classes,
                    dashboardAccess: coachFormData.dashboardAccess,
                    classesAccess: coachFormData.classesAccess,
                    academyAccess: coachFormData.academyAccess,
                    userManagementAccess: coachFormData.userManagementAccess,
                    studentsAccess: coachFormData.studentsAccess,
                    feePaymentAccess: coachFormData.feePaymentAccess,
                    enquiriesAccess: coachFormData.enquiriesAccess,
                    scheduleAccess: coachFormData.scheduleAccess,
                    remarks: coachFormData.remarks,
                },
            });
        },
        onError: error => {
            // console.log(`On user update Error:`, error);
            alert('Failed to update user! Please try again');
            // Error actions
        },
    });

    const editCoach = async (data: UserDetailsPostDataWrapper): Promise<ApiResponse<CoachData>> => {
        const response = await UserDetailsService.instance.updateUserDetails(
            data,
            coachEditingData?.id ?? 0
        );
        if (response.success) {
            return response;
        } else {
            // console.log(
            //     `On edit user Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateCoach,
        isLoading: isCoachUpdating,
        isSuccess: isCoachUpdated,
    } = useMutation(editCoach, {
        onSuccess: data => {
            alert('Updated User Successfully!');
            setIsEditCoachModalOpen(false);
            setCoachFormData(coachFormDataInitialState);
            setCheckedQuickAccess(0);
            setActiveUserFormTab(1);
            queryClient.invalidateQueries(USER_DETAILS_LIST_QUERY);
        },
        onError: error => {
            // console.log(`On USer update Error:`, error);
            alert('Failed to update user! Please try again');
            // Error actions
        },
    });

    const handleAddNewCoach = async (e: React.FormEvent) => {
        e.preventDefault();

        const newUserData: UserPostData = {
            username: coachFormData.name,
            email: coachFormData.email,
            password: 'password',
            confirmed: true,
            role: userRoles?.data?.roles?.filter(role => role.type === 'restricted')[0]?.id ?? 0,
        };

        const editCoachUserData: UserPostData = {
            username: coachFormData.name,
            email: coachFormData.email,
        };

        if (isEditCoachModalOpen) {
            updateCoachUser(editCoachUserData);
        } else {
            addNewUser(newUserData);
        }
    };

    const handleCoachListItemAcademiesExpandClick = () => {
        setIsCoachAcademiesListExpanded(prev => !prev);
    };

    const handleUserEditClick = (coach: UserDetailsData) => {
        setCoachEditingData(coach);
        setCoachFormData({
            name: coach?.attributes?.name,
            contact: coach?.attributes?.contactNo,
            email: coach?.attributes?.email,
            photo: coach?.attributes?.photo?.data?.attributes?.url,
            governmentId: coach?.attributes?.governmentId?.data?.attributes?.url,
            governmentIdName: coach?.attributes?.governmentId?.data?.attributes?.name,
            // discipline: coach?.attributes?.discipline,
            academies: coach?.attributes?.academies?.data?.map(item => item.id),
            classes: coach?.attributes?.classes?.data?.map(item => item.id),
            dashboardAccess: coach?.attributes?.dashboardAccess,
            academyAccess: coach?.attributes?.academyAccess,
            classesAccess: coach?.attributes?.classesAccess,
            userManagementAccess: coach?.attributes?.userManagementAccess,
            studentsAccess: coach?.attributes?.studentsAccess,
            feePaymentAccess: coach?.attributes?.feePaymentAccess,
            enquiriesAccess: coach?.attributes?.enquiriesAccess,
            scheduleAccess: coach?.attributes?.scheduleAccess,
            remarks: coach?.attributes?.remarks,
        });
        if (coach?.attributes?.governmentId?.data) {
            setDocumentId(coach?.attributes?.governmentId?.data?.id);
        }
        if (coach?.attributes?.photo?.data) {
            setImageId(coach?.attributes?.photo?.data?.id);
        }
        setIsEditCoachModalOpen(true);
    };

    const [activeUserFormTab, setActiveUserFormTab] = useState<number>(1);

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateCurrentTab = () => {
        switch (activeUserFormTab) {
            case 1:
                if (coachFormData.name === '') {
                    alert('Please enter user name');
                    return false;
                } else if (coachFormData.contact === '') {
                    alert('Please enter user contact');
                    return false;
                } else if (!isValidEmail(coachFormData.email)) {
                    alert('Please enter a valid email address');
                    return false;
                }
                break;
            case 2:
                break;
            default:
                break;
        }
        return true;
    };

    const switchTab = (tabNumber: number, isGoingBack: boolean) => {
        if (isGoingBack) {
            setActiveUserFormTab(tabNumber);
        } else {
            if (validateCurrentTab()) {
                setActiveUserFormTab(tabNumber);
            }
        }
    };

    const blockExistingUser = async (
        id: number,
        data: UserPostData
    ): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.updateUser(data, id);
        if (response.success) {
            return response;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: blockUnblockUser,
        isLoading: isUserBlocking,
        isSuccess: isUserBlocked,
    } = useMutation<ApiResponse<User> | undefined, Error, { id: number; data: UserPostData }>(
        ({ id, data }) => blockExistingUser(id, data),
        {
            onSuccess: data => {
                // alert("Blocked user successfully!")
                queryClient.invalidateQueries(USER_DETAILS_LIST_QUERY);
            },
            onError: error => {
                // alert('Failed to block user! Please try again');
            },
        }
    );

    const handleBlockUserClick = (user: UserDetailsData) => {
        const data = {
            blocked: true,
        };
        const id = user?.attributes?.userInfo?.data?.id;
        blockUnblockUser({ id, data });
    };

    const handleUnblockUserClick = (user: UserDetailsData) => {
        const data = {
            blocked: false,
        };
        const id = user?.attributes?.userInfo?.data?.id;
        blockUnblockUser({ id, data });
    };

    return {
        UsersList: UserDetailsData?.data?.data,
        isUserDetailsDataFetchSuccess,
        visibleFilter,
        toggleVisibleFilter,
        isfilterModalOpen,
        handleFilterModalClose,
        indexOfFirstItem,
        indexOfLastItem,
        itemsPerPage,
        currentPage: state.currentPage,
        total: UserDetailsData?.data?.data?.length ?? 0,
        state,
        dispatch,
        handleFilterBtnClick,
        isNewCoachModalOpen,
        handleAddNewCoachBtnClick,
        handleNewCoachModalClose,
        isEditCoachModalOpen,
        handleEditCoachModalClose,
        onAcademyFilterSelect,
        expandedCoachItem,
        handleCoachItemExpand,
        profileImageInputKey,
        coachDocumentInputKey,
        handleProfileImgChange,
        isCoachProfileImagePosting,
        coachFormData,
        mapAcademiesToOptions,
        handleInputChange,
        handleAddNewCoach,
        handleCoachDocumentChange,
        isCoachDocumentPosting,
        mapDiciplinesOfSelectedAcademiesToOptions,
        // mapClassesOfSelectedDisciplinesToOptions,
        isCoachAcademiesListExpanded,
        handleCoachListItemAcademiesExpandClick,
        handleUserEditClick,
        coachEditingData,
        isNewCoachUserAdding,
        isNewCoachAdding,
        isCoachUserUpdating,
        isCoachUpdating,
        switchTab,
        activeUserFormTab,
        checkedQuickAccess,
        handleQuickAccessCheck,
        mapClassesOfSelectedAcademiesToOptions,
        handleBlockUserClick,
        handleUnblockUserClick,
        isUserBlocking,
        currentUserNames: currentUserNames.filter(
            name => name !== coachEditingData?.attributes?.name
        ),
        currentUserEmails: currentUserEmails.filter(
            email => email !== coachEditingData?.attributes?.email
        ),
        allDisciplinesOptions,
        academiesDisciplinesSelected,
        handleDisciplineChange,
    };
};

export default UserManagementViewModel;
