import React, { ChangeEvent, useState } from 'react';
import style from './style.module.css';
import { NewStudentMinimalDataType } from '../../../view-models/schedule-details-viewmodel';
import ApiResponse from '../../../../utils/types/api-response';
import { ClassDetailsWrapper } from '../../../../models/class/class-details-wrapper';

interface NewStudentMinimalFormProps {
    handleAddStudentInputChange: (property: string, value: string | number[]) => void;
    handleAddNewStudent: (e: React.FormEvent) => void;
    formData: NewStudentMinimalDataType;
    image: string;
    classDetails: ApiResponse<ClassDetailsWrapper> | undefined;
}

const NewStudentMininalForm: React.FC<NewStudentMinimalFormProps> = ({
    handleAddStudentInputChange,
    handleAddNewStudent,
    formData,
    image,
    classDetails,
}) => {
    return (
        <div className={style.formWrapper}>
            <form
                onSubmit={handleAddNewStudent}
                className={style.formTabContainer}
            >
                <div className={`${style.tabOne} ${style.activeTab}`}>
                    <div className={style.inputFieldsContainer}>
                        <div className={style.nameGenderWrapper}>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    First Name:
                                </div>
                                <input
                                    required
                                    // disabled={isEditMode}
                                    className={style.formInput}
                                    placeholder="Enter student's first name"
                                    value={formData.firstName}
                                    onChange={e =>
                                        handleAddStudentInputChange('firstName', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Last Name:</div>
                                <input
                                    // disabled={isEditMode}
                                    className={style.formInput}
                                    placeholder="Enter student's last name"
                                    value={formData.lastName}
                                    onChange={e =>
                                        handleAddStudentInputChange('lastName', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>Middle Name:</div>
                                <input
                                    className={style.formInput}
                                    // disabled={isEditMode}
                                    placeholder="Enter student's middle name"
                                    value={formData.middleName}
                                    onChange={e =>
                                        handleAddStudentInputChange('middleName', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    Gender:
                                </div>
                                <select
                                    required
                                    // disabled={isEditMode}
                                    className={style.formInput}
                                    value={formData.gender}
                                    onChange={e =>
                                        handleAddStudentInputChange('gender', e.target.value)
                                    }
                                >
                                    <option value="">Select student&apos;s gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                        </div>
                        <div className={style.photoDobBloodGroupWrapper}>
                            <div>
                                <div className={style.formInputLabel}>Photo:</div>
                                <div className="h-[160px] w-[160px] min-h-[160px] min-w-[160px] border">
                                    <img
                                        src={`data:image/jpeg;base64,${image}`}
                                        alt="profile image"
                                        className="h-full w-full object-contain"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className={style.formInputLabel}>
                                        <span className={style.requiredFieldLabelPrompt}>*</span>
                                        Date of Birth:
                                    </div>
                                    <input
                                        type="date"
                                        // disabled={isEditMode}
                                        className={style.formInput}
                                        value={formData.DateOfBirth}
                                        onChange={e =>
                                            handleAddStudentInputChange(
                                                'DateOfBirth',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={style.formInputLabel}>
                                <span className={style.requiredFieldLabelPrompt}>*</span>
                                Academy:
                            </div>
                            <input
                                type="text"
                                disabled={true}
                                className={style.formInput}
                                value={
                                    classDetails?.data?.data?.attributes?.academies?.data
                                        ?.attributes?.name
                                }
                            />
                        </div>
                        <div>
                            <div>
                                <div className={style.formInputLabel}>
                                    <span className={style.requiredFieldLabelPrompt}>*</span>
                                    Class:
                                </div>
                                <input
                                    type="text"
                                    disabled={true}
                                    className={style.formInput}
                                    value={classDetails?.data?.data?.attributes?.class_name}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.nextBtnContainer}>
                        <button className={style.formSubmitBtn}>Add Student</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewStudentMininalForm;
