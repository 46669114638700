// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__RjmB9 {

    min-width: 440px;

    padding: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

.style_containerLeftSidediv__8AcqD {

    margin-bottom: 0.5rem;

    display: flex;

    width: 100%;

    flex-direction: column;

    justify-content: space-between;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

@media (min-width: 640px) {

    .style_containerLeftSidediv__8AcqD {

        flex-direction: row
    }
}

.style_containerLeftSidedivText__Cuzwn {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.style_studentsInfoWrapper__OeI3N {

    margin-top: 1rem;

    border-radius: 0.25rem;

    border-width: 1px;

    padding: 0.5rem;

    padding-bottom: 0px
}

.style_classSelectInputContainer__y5t7e {

    padding: 0.5rem
}

.style_classSelectInput__9Ikab {

    width: 100%;

    resize: none;

    border-radius: 0.25rem;

    border-color: rgb(107 114 128 / var(--tw-border-opacity));

    --tw-border-opacity: 0.3;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.style_formSubmitBtn__nW\\+yq {

    border-radius: 0.375rem;

    --tw-bg-opacity: 1;

    background-color: rgb(69 90 100 / var(--tw-bg-opacity));

    padding: 0.5rem;

    padding-left: 1.5rem;

    padding-right: 1.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.style_formSubmitBtn__nW\\+yq:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.style_formSubmitBtnContainer__-cYQW {

    margin-top: 3.5rem;

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: center
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/change-class-form/style.module.css"],"names":[],"mappings":"AACI;;IAAA,gBAAgC;;IAAhC,eAAgC;;IAAhC,mBAAgC;;IAAhC;AAAgC;;AAIhC;;IAAA,qBAA4E;;IAA5E,aAA4E;;IAA5E,WAA4E;;IAA5E,sBAA4E;;IAA5E,8BAA4E;;IAA5E,oBAA4E;;IAA5E;AAA4E;;AAA5E;;IAAA;;QAAA;IAA4E;AAAA;;AAI5E;;IAAA,oBAAiB;;IAAjB;AAAiB;;AAIjB;;IAAA,gBAAmC;;IAAnC,sBAAmC;;IAAnC,iBAAmC;;IAAnC,eAAmC;;IAAnC;AAAmC;;AAInC;;IAAA;AAAU;;AAIV;;IAAA,WAAyF;;IAAzF,YAAyF;;IAAzF,sBAAyF;;IAAzF,yDAAyF;;IAAzF,wBAAyF;;IAAzF,mBAAyF;;IAAzF,oBAAyF;;IAAzF,oBAAyF;;IAAzF;AAAyF;;AAIzF;;IAAA,uBAA6E;;IAA7E,kBAA6E;;IAA7E,uDAA6E;;IAA7E,eAA6E;;IAA7E,oBAA6E;;IAA7E,qBAA6E;;IAA7E,mBAA6E;;IAA7E,oBAA6E;;IAA7E,oBAA6E;;IAA7E;AAA6E;;AAA7E;;IAAA,kBAA6E;;IAA7E;AAA6E;;AAI7E;;IAAA,kBAAoD;;IAApD,aAAoD;;IAApD,WAAoD;;IAApD,mBAAoD;;IAApD;AAAoD","sourcesContent":[".container {\n    @apply min-w-[440px] p-2 text-sm;\n}\n\n.containerLeftSidediv {\n    @apply text-gray-500 flex  flex-col sm:flex-row justify-between  w-full mb-2;\n}\n\n.containerLeftSidedivText {\n    @apply text-black;\n}\n\n.studentsInfoWrapper {\n    @apply p-2 pb-0 mt-4 border rounded;\n}\n\n.classSelectInputContainer {\n    @apply p-2;\n}\n\n.classSelectInput {\n    @apply text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded;\n}\n\n.formSubmitBtn {\n    @apply bg-primary text-sm text-white p-2 px-6 rounded-md disabled:bg-gray-200;\n}\n\n.formSubmitBtnContainer {\n    @apply flex w-full items-center justify-center mt-14;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__RjmB9`,
	"containerLeftSidediv": `style_containerLeftSidediv__8AcqD`,
	"containerLeftSidedivText": `style_containerLeftSidedivText__Cuzwn`,
	"studentsInfoWrapper": `style_studentsInfoWrapper__OeI3N`,
	"classSelectInputContainer": `style_classSelectInputContainer__y5t7e`,
	"classSelectInput": `style_classSelectInput__9Ikab`,
	"formSubmitBtn": `style_formSubmitBtn__nW+yq`,
	"formSubmitBtnContainer": `style_formSubmitBtnContainer__-cYQW`
};
export default ___CSS_LOADER_EXPORT___;
