export const PARAMETER_OPTIONS = [
    { value: 'DEFAULT', label: 'Default', icon: '⚙️' },
    { value: 'SPEED', label: 'Speed', icon: '🏃‍♂️' },
    { value: 'STAMINA', label: 'Stamina', icon: '🕰️' },
    { value: 'STRENGTH', label: 'Strength',  icon: '💪' },
    { value: 'POWER', label: 'Power',  icon: '⚡' },
    { value: 'FLEXIBILITY', label: 'Flexibility',  icon: '🤸‍♂️' },
    { value: 'AGILITY', label: 'Agility',  icon: '🦘' },
    { value: 'COORDINATION', label: 'Coordination',  icon: '🤝' },
    { value: 'BALANCE', label: 'Balance',  icon: '⚖️' },
    { value: 'ENDURANCE', label: 'Endurance',  icon: '🚴‍♂️' },
];