import React, { useMemo } from 'react';
import style from './style.module.css';
import { EnrollmentDataAttributes } from '../../../../models/enrollment-data/enrollment-data-attributes';

interface EnrolmentListItemProps {
    enrolmentAttributes: EnrollmentDataAttributes;
    allStudentsChecked?: boolean;
    handleEnrolmentStudentItemCheck: () => void;
    isChecked: boolean;
}

const EnrolmentListItem = ({
    enrolmentAttributes,
    allStudentsChecked,
    handleEnrolmentStudentItemCheck,
    isChecked,
}: EnrolmentListItemProps) => {
    return (
        <div className={style.listItemContainer}>
            <input
                type="checkbox"
                onChange={handleEnrolmentStudentItemCheck}
                checked={isChecked}
                disabled={enrolmentAttributes?.status !== 'FORM_SUBMITTED'}
                className={style.checkBoxInput}
            />
            <div className="grid grid-cols-2 sm:grid-cols-3 flex-grow text-sm">
                <div className="order-1">
                    {enrolmentAttributes?.formData?.firstName ? (
                        <>
                            {enrolmentAttributes?.formData?.firstName}{' '}
                            {enrolmentAttributes?.formData?.lastName ?? ''}
                        </>
                    ) : (
                        <div className="bg-slate-100 h-4 rounded animate-pulse w-1/2 " />
                    )}
                </div>
                <div className="font-medium col-span-2 sm:col-span-1 order-3 sm:order-2">
                    {enrolmentAttributes?.academies?.data
                        ?.map(item => item.attributes.name)
                        .join(', ')}
                </div>
                <div className="text-gray-600 sm:order-3 order-5">
                    {enrolmentAttributes.email && <>Email: {enrolmentAttributes.email}</>}
                </div>
                <div className="flex items-center gap-2 sm:order-4 order-2">
                    {enrolmentAttributes?.formData?.relationName ? (
                        <>
                            <span className="text-gray-400">
                                {enrolmentAttributes?.formData.gender === 'male'
                                    ? 'Son of '
                                    : 'Daughter of '}
                            </span>
                            {enrolmentAttributes?.formData?.relationName}
                            {/* {!enrolmentAttributes.slug && (
                                <div className="px-1 text-xs rounded-full border border-dashed border-green-400 text-green-400">
                                    creater
                                </div>
                            )} */}
                        </>
                    ) : (
                        <div className="bg-slate-200 h-4 rounded animate-pulse w-1/2" />
                    )}
                </div>
                <div className="font-normal text-start flex sm:order-5 col-span-2 sm:col-span-1 order-4">
                    {enrolmentAttributes?.classes?.data
                        ?.map(item => item.attributes.class_name)
                        .join(', ')}
                </div>
                <div className="text-gray-600 flex order-6">
                    {enrolmentAttributes.phoneNumber && (
                        <>Phone: {enrolmentAttributes.phoneNumber}</>
                    )}
                </div>
            </div>
            <div className="flex flex-col justify-center sm:items-end items-end sm:w-[130px] w-full order-last">
                <div className="flex gap-4">
                    {enrolmentAttributes.status === 'REJECTED' ? (
                        <div className="w-fit h-5 text-[10px] font-normal leading-4 tracking-[0.5px] text-white uppercase px-1 py-0.5 rounded-[3px] bg-red-500">
                            Rejected
                        </div>
                    ) : enrolmentAttributes.status === 'STUDENT_ADDED' ? (
                        <div className="w-fit h-5 text-[10px] font-normal leading-4 tracking-[0.5px] text-white uppercase px-1 py-0.5 rounded-[3px] bg-green-500">
                            Student Added
                        </div>
                    ) : enrolmentAttributes.status === 'FORM_SUBMITTED' ? (
                        <div className="w-fit h-5 text-[10px] font-normal leading-4 tracking-[0.5px] text-white uppercase px-1 py-0.5 rounded-[3px] bg-orange-300">
                            Form Submitted
                        </div>
                    ) : (
                        <div className="w-fit h-5 text-[10px] font-normal leading-4 tracking-[0.5px] text-white uppercase px-1 py-0.5 rounded-[3px] bg-gray-400">
                            Submission Pending
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EnrolmentListItem;
