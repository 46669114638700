import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import { BookDemoFormAttributes } from '../utils/types/book-demo-form-attribute';

export class LandingPageService {
    private static _instance: LandingPageService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new LandingPageService());
    }

    public postBookDemo = async (
        data: BookDemoFormAttributes
    ): Promise<ApiResponse<BookDemoFormAttributes>> => {
        return invokeApi<{ data: BookDemoFormAttributes }, BookDemoFormAttributes>({
            route: `demos`,
            method: 'POST',
            privateRoute: true,
            data: { data },
        });
    };
}
