import { AuthResponse } from '../models/user/auth-response';
import { User } from '../models/user/user';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import ForgetpasswordPostData from '../utils/types/forgetpassword-post-data';
import RestpasswordPostData from '../utils/types/reset-password-post-data';
import { UserDeatilsDataWrapperResponse } from '../utils/types/user-deatils-data-wrapper-response';
import { UserEmailData } from '../utils/types/user-email-data';

type LoginData = {
    identifier: string;
    password: string;
};

export class LoginService {
    private static _instance: LoginService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new LoginService());
    }

    public checkLogin = async (
        email: string,
        password: string
    ): Promise<ApiResponse<AuthResponse>> => {
        return invokeApi<LoginData, AuthResponse>({
            route: `auth/local`,
            method: 'POST',
            data: {
                identifier: email,
                password,
            },
        });
    };

    public getUserRole = async (id: number) => {
        return invokeApi<null, User>({
            route: `users/${id}?populate=role&field[0]=role&fields[1]=id`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public checkForgotPasswordEmail = async (
        data: ForgetpasswordPostData
    ): Promise<ApiResponse<ForgetpasswordPostData>> => {
        return invokeApi<ForgetpasswordPostData, ForgetpasswordPostData>({
            route: `auth/forgot-password`,
            method: 'POST',
            data: data,
        });
    };

    public checkResetPassword = async (
        data: RestpasswordPostData
    ): Promise<ApiResponse<RestpasswordPostData>> => {
        return invokeApi<RestpasswordPostData, RestpasswordPostData>({
            route: `auth/reset-password`,
            method: 'POST',
            data: data,
        });
    };
    public UsersDeatils = async () => {
        return invokeApi<null, UserEmailData[]>({
            route: `users`,
            method: 'GET',
        });
    };
}
