import React, { useState } from 'react';
import style from './style.module.css';
import chevrondownIcon from '../../../../assets/icons/chevrondown.png';
import acdemyProfile from '../../../../assets/icons/academy-profile.png';
import attachedIcon from '../../../../assets/icons/attached.png';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { AcademyAttributes } from '../../../../models/academy/acedemy-attributes';
import { useCollapse } from 'react-collapsed';
import CustomCarousel from '../../widgets/custom-carousel';

interface AcademyListItemProps {
    academyAttributes: AcademyAttributes;
    handleItemClick: () => void;
    // handleEditAcademy: () => void;
    isAcademyShouldExpanded: boolean;
    handleEditAcademyModalOpen: () => void;
    viewOnly: boolean;
}

const AcademyListItem = ({
    academyAttributes,
    handleItemClick,
    // handleEditAcademy,
    isAcademyShouldExpanded,
    handleEditAcademyModalOpen,
    viewOnly,
}: AcademyListItemProps) => {
    const { getCollapseProps } = useCollapse({
        isExpanded: isAcademyShouldExpanded,
    });

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrev = (length: number) => {
        setCurrentImageIndex((prevIndex: number) => (prevIndex === 0 ? length - 1 : prevIndex - 1));
    };

    const handleNext = (length: number) => {
        setCurrentImageIndex((prevIndex: number) => (prevIndex === length - 1 ? 0 : prevIndex + 1));
    };

    const handleDotClick = (index: number) => {
        setCurrentImageIndex(index);
    };

    return (
        <div>
            <div
                className={style.listItemContainer}
                onClick={handleItemClick}
            >
                <div className={style.listItemDetailsContainer}>
                    <img
                        className={style.listItemImage}
                        src={
                            academyAttributes?.images?.data &&
                            academyAttributes.images.data.length > 0
                                ? academyAttributes.images.data[0]?.attributes?.url || acdemyProfile
                                : acdemyProfile
                        }
                        alt={
                            academyAttributes?.images?.data &&
                            academyAttributes.images.data.length > 0
                                ? academyAttributes.images.data[0]?.attributes?.caption || ''
                                : ''
                        }
                    />
                    <div className={style.listItemNameContainer}>
                        <div className={style.listItemName}> {academyAttributes?.name ?? ''} </div>
                        <div className={style.listItemAddress}>
                            {' '}
                            {academyAttributes?.address ?? ''}{' '}
                        </div>
                    </div>
                </div>
                <div className={style.listItemDisciplineContainerWrapper}>
                    <div className={style.listItemDisciplineContainer}>
                        <div className={style.listItemDiscipline}>
                            Discipline:{' '}
                            {academyAttributes?.selectedDisciplines?.data
                                ?.map(item => item.attributes.name)
                                ?.join(',') ?? ''}
                        </div>
                    </div>
                    <button
                        onClick={handleItemClick}
                        className={style.listItemButton}
                    >
                        View Details{' '}
                        <img
                            className={style.listItemDownIcon}
                            src={chevrondownIcon}
                            alt="down"
                        />
                    </button>
                </div>
            </div>

            <div {...getCollapseProps()}>
                {academyAttributes?.images?.data && (
                    <div className={style.academiesCarouselContainer}>
                        <CustomCarousel
                            isApiUpload={true}
                            imageArray={academyAttributes?.images?.data?.map(
                                img => img?.attributes?.url
                            )}
                            handlePrev={handlePrev}
                            currentImageIndex={currentImageIndex}
                            handleNext={handleNext}
                            topCount={false}
                            handleDotClick={handleDotClick}
                        />
                        {/* <Carousel showThumbs={false}>
                            {(academyAttributes?.images?.data ?? [])?.map(
                                (item, index) => {
                                    return (
                                        <div key={index}>
                                            <img
                                                src={item?.attributes?.url ?? ''}
                                                alt={
                                                    item?.attributes?.caption ?? ''
                                                }
                                            />
                                        </div>
                                    );
                                },
                            )}
                        </Carousel> */}
                    </div>
                )}

                <div className={style.academiesDetailsContentContainer}>
                    <div className={style.academiesDetailsContainerWrapper}>
                        <div className={style.academiesDetailsContainer}>
                            <div style={{ fontSize: '20px' }}>{academyAttributes?.name ?? ''}</div>
                            <div>{academyAttributes?.address ?? ''}</div>
                            <div>Contact number: {academyAttributes?.contactNo ?? ''}</div>
                            <div>Email ID:{academyAttributes?.email ?? ''}</div>
                            <div>Webpage: {academyAttributes?.webpage ?? ''}</div>
                            <div>Remarks: {academyAttributes?.remarks ?? ''} </div>
                        </div>
                        <div className={style.academiesDetailsContainer}>
                            <div>
                                Disciplines:{' '}
                                {academyAttributes?.selectedDisciplines?.data
                                    ?.map(item => item?.attributes?.name)
                                    ?.join(',')}{' '}
                            </div>
                            <div>GSTNo.: {academyAttributes?.GSTNo ?? ''} </div>
                            <div>BankAccounts: {academyAttributes?.bankAccounts ?? ''} </div>

                            <div className={style.academiesAttachedIconContainer}>
                                Attached documents :
                                <img
                                    className={style.academiesAttachedIcon}
                                    src={attachedIcon}
                                    alt="attached"
                                />
                                {(academyAttributes?.documents?.data ?? [])?.map((item, index) => {
                                    return (
                                        <Link
                                            key={index}
                                            to={item?.attributes?.url ?? ''}
                                            className={style.academiesAttachedLink}
                                        >
                                            {item?.attributes.name ?? ' '}
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className={style.academiesDetailsEditButtonContainer}>
                        {!viewOnly && (
                            <button
                                className={style.academiesModalButton}
                                onClick={handleEditAcademyModalOpen}
                            >
                                Edit Academy
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcademyListItem;
