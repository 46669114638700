import React from 'react';
import './Process.css';

const Process = () => {
    return (
        <div className="overflow-hidden">
            <div className="w-[90%]  h-full xl:w-full xl:container 2xl:w-full 2xl:container  mx-auto mt-20 mb-10 sm:mb-0 lxl:w-[70vw]">
                <div className="relative lg:max-w-[80vw] lxl:max-w-[70vw] lxl: mx-auto 2l:max-w-[80vw] m-0">
                    <div className="sm:w-[35%] sm:absolute">
                        <div className="text-2xl font-semibold">
                            We have the best team and the best processes to help you grow
                        </div>
                        <div className="font-extralight text-sm mt-5">
                            Stop worrying about student dropouts, managing time taking manual
                            operations for running your academy. We are sportspersons and artists
                            with a decade of experience in running businesses.
                        </div>
                        <div className="rounded-2xl text-center text-white w-32 p-1 bg-[#F6B40A] mt-3 hover:bg-yellow-400">
                            <a href="#demo">Book Demo</a>
                        </div>
                    </div>
                    <div className="flex flex-col w-[100%] mx-auto mt-5 relative sm:hidden">
                        <ul>
                            <li className="timeline-item relative ">
                                <div className="timeline-marker"></div>
                                <div>
                                    <h1 className="text-lg font-bold">Save Time & Money</h1>
                                    <p className="font-thin">
                                        Get your student records and transactions digitised, save
                                        upto 60 hours/month..
                                    </p>
                                </div>
                                <div className="text-[8em] italic m-3 -z-5 text-[#00000011] absolute bottom-[-30%] right-[0%]">
                                    1
                                </div>
                            </li>
                            <li className="timeline-item">
                                <div className="timeline-marker"></div>
                                <div>
                                    <h1 className="text-lg font-bold">Manage your operations</h1>
                                    <p className="font-thin">
                                        With one click fees reminders, receipts; progress updates
                                        with attendance, all students records are readily available
                                        for you and your students.
                                    </p>
                                </div>
                                <div className="text-[8em] italic m-3 -z-5 text-[#00000011] absolute bottom-[-10%] xsm:bottom-[-25%] right-[0%]">
                                    2
                                </div>
                            </li>
                            <li className="timeline-item">
                                <div className="timeline-marker"></div>
                                <div>
                                    <h1 className="text-lg font-bold">Train More Students</h1>
                                    <p className="font-thin">
                                        Get new leads from SpArts’ pool of students.
                                    </p>
                                </div>
                                <div className="text-[8em] italic m-3 -z-5 text-[#00000011] absolute bottom-[-50%] xsm:bottom-[-90%] right-[0%]">
                                    3
                                </div>
                            </li>
                        </ul>
                    </div>
                    <svg
                        width="1000"
                        height="449"
                        viewBox="0 0 950 449"
                        fill="none"
                        version="1.1"
                        id="svg3"
                        className="hidden llg:hidden lg:inline"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs id="defs3">
                            <filter
                                id="filter0_d_1438_2360"
                                x="-0.000244141"
                                y="0.70361298"
                                width="1070"
                                height="448.255"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                                <feFlood
                                    floodOpacity="0"
                                    result="BackgroundImageFix"
                                    id="feFlood1"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                    id="feColorMatrix1"
                                />
                                <feOffset
                                    dy="24"
                                    id="feOffset1"
                                />
                                <feGaussianBlur
                                    stdDeviation="12"
                                    id="feGaussianBlur1"
                                />
                                <feComposite
                                    in2="hardAlpha"
                                    operator="out"
                                    id="feComposite1"
                                />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.215686 0 0 0 0 0.203922 0 0 0 0 0.662745 0 0 0 0.3 0"
                                    id="feColorMatrix2"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_1438_2360"
                                    id="feBlend2"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_1438_2360"
                                    result="shape"
                                    id="feBlend3"
                                />
                            </filter>
                        </defs>
                        <g
                            id="g2"
                            transform="matrix(0.91765034,0,0,0.91765034,-19.358899,17.69196)"
                        >
                            <g
                                filter="url(#filter0_d_1438_2360)"
                                id="g1"
                                // inkscape:export-filename="g1.png"
                                // inkscape:export-xdpi="96"
                                // inkscape:export-ydpi="96"
                            >
                                <path
                                    d="m 26.5,311 c 48,35 159.8,101 223,85 C 328.5,376 348,273 470,249 592,225 681.5,308.5 776,188 870.5,67.5003 916.5,-11.4997 1043.5,5.50027"
                                    stroke="#f57059"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    id="path1"
                                />
                            </g>
                            <rect
                                width="64"
                                height="64"
                                rx="20"
                                fill="#ffffff"
                                id="rect1"
                                x="146.97845"
                                y="357.98904"
                            />
                            <rect
                                x="166.97845"
                                y="377.98904"
                                width="23"
                                height="23"
                                rx="10"
                                fill="#c4c4c4"
                                id="rect2"
                            />
                            <rect
                                width="64"
                                height="64"
                                rx="20"
                                fill="#ffffff"
                                id="rect1-7"
                                x="624.3017"
                                y="224.5"
                            />
                            <rect
                                x="644.3017"
                                y="244.5"
                                width="23"
                                height="23"
                                rx="10"
                                fill="#c4c4c4"
                                id="rect2-5"
                            />
                            <rect
                                width="64"
                                height="64"
                                rx="20"
                                fill="#ffffff"
                                id="rect1-5"
                                x="963.12067"
                                y="-19.279631"
                            />
                            <rect
                                x="983.12067"
                                y="0.72036982"
                                width="23"
                                height="23"
                                rx="10"
                                fill="#c4c4c4"
                                id="rect2-6"
                            />
                        </g>
                    </svg>
                    <img
                        src="/graphics/process/Partner app screens/Vector 336.svg"
                        alt="process line vector"
                        className="hidden llg:inline lg:hidden"
                    />
                    <img
                        src="/graphics/process/Partner app screens/Vector 336.svg"
                        alt="process line vector"
                        className="hidden slg:inline lg:hidden"
                    />
                    <img
                        src="/graphics/process/Partner app screens/Vector 337.svg"
                        alt="process line vector"
                        className="hidden md:inline slg:hidden lg:hidden"
                    />
                    <img
                        src="/graphics/process/Partner app screens/Vector 337.svg"
                        alt="process line vector"
                        className="hidden sm:inline md:hidden slg:hidden lg:hidden"
                    />
                    <div className="relative hidden sm:inline">
                        <div className="relative max-w-[300px] top-[200%] md:top-[95%] left-[9%] lg:left-[12.5%] xl:left-[11.5%] 2xl:left-[10%]">
                            <h1 className="text-lg font-bold">Save Time & Money</h1>
                            <p className="font-thin">
                                Get your student records and transactions digitised, save upto 60
                                hours/month..
                            </p>
                            <div className="text-[12em] italic m-3 -z-10 text-[#00000011] absolute top-[-120%] md:top-[-150%] left-[50%]">
                                1
                            </div>
                        </div>
                    </div>
                    <div className=" hidden sm:inline absolute top-[47%] md:top-[51%] slg:top-[56%] llg:top-[54%] 2xl:top-[52%] left-[25%] md:left-[30%] lxl:left-[35%] lxl:top-[60%] slg:left-[40.5%] llg:left-[37%] lg:left-[45%] xl:left-[37.5%] 2xl:left-[32%]">
                        <div className="max-w-[300px] relative top-[-200%] left-[40%]">
                            <h1 className="text-lg font-bold">Manage your operations</h1>
                            <p className="font-thin">
                                With one click fees reminders, receipts; progress updates with
                                attendance, all students records are readily available for you and
                                your students.
                            </p>
                        </div>
                        <div className="text-[12em] italic m-3 -z-10 text-[#00000011] absolute top-[-80%] md:top-[-100%] left-[80%]">
                            2
                        </div>
                    </div>

                    <div className=" hidden sm:inline absolute top-[17%] slg:top-[12%] llg:top-[12%] left-[38%] md:left-[45%] slg:left-[57%] llg:left-[57%] lg:left-[62%] lxl:left-[60%] lxl:top-[15%] xl:left-[55%] 2xl:top-[12%] 2xl:left-[47%]">
                        <div className=" relative max-w-[250px] llg:max-w-[200px] xl:max-w-[300px] top-[-100%] left-[72%]">
                            <h1 className="text-lg font-bold">Train More Students</h1>
                            <p className="font-thin">
                                Get new leads from SpArts’ pool of students.
                            </p>
                        </div>

                        <div className="text-[12em] italic m-3 -z-10 text-[#00000011] absolute llg:top-[-100%] top-[-160%] md:top-[-190%] left-[100%] md:left-[120%]">
                            3
                        </div>
                    </div>

                    <div
                        className="bg-[#F5F8FF] rounded-[100%] absolute -z-50 hidden sm:inline"
                        style={{ width: '600px', height: '600px', left: '90%', top: '0%' }}
                    ></div>
                </div>
            </div>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
            >
                <path
                    fill="#F5F8FF"
                    fillOpacity="1"
                    d="M0,64L120,90.7C240,117,480,171,720,186.7C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    data-darkreader-inline-fill=""
                    // style={{
                    //   "--darkreader-inline-fill": "#007acc",
                    // }}
                ></path>
            </svg>
        </div>
    );
};

export default Process;
