import { clearConfigCache } from 'prettier';
import { AttendanceListWrapper } from '../models/attendance/attendance-list-wrapper';
import { FeePaymentListWrapper } from '../models/fee/fee-payment-list-wrapper';
import { FeePaymentPostData } from '../models/fee/fee-payment-post-data';
import { FeePaymentPostDataWrapper } from '../models/fee/fee-payment-post-data-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import { FeeReminderEmailAttributes } from '../utils/types/fee-reminder-email-attributes';
import FeeReminderWhatsappMessageData from '../utils/types/fee-reminder-whatsapp-message-data';

export class FeePaymentService {
    private static _instance: FeePaymentService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new FeePaymentService());
    }

    public getFeePaymentList = async (
        supplierId: number,
        query: string,
        searchTerm: string
    ): Promise<ApiResponse<FeePaymentListWrapper>> => {
        return invokeApi<null, FeePaymentListWrapper>({
            route: `fee-collections?populate[0]=fee_dates.feeRemindersSent&populate[1]=user&populate[2]=class.class_discipline&populate[3]=student.photo&populate[4]=student.studentRelation&populate[5]=student.academies&populate[6]=student.classes&populate[7]=academy&filters[users][id]=${supplierId}&filters[$or][0][student][firstName][$contains]=${searchTerm}&filters[$or][1][student][middleName][$contains]=${searchTerm}&filters[$or][2][student][lastName][$contains]=${searchTerm}&${query}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentsFeeRecords = async (
        supplierId: number,
        studentId: number
    ): Promise<ApiResponse<FeePaymentListWrapper>> => {
        return invokeApi<null, FeePaymentListWrapper>({
            route: `fee-collections?populate[0]=fee_dates&populate[1]=class&populate[2]=academy&filters[users][id]=${supplierId}&filters[student][id]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    // public postFeeReminderEmail = async (data: EmailAttributes) => {
    //     return invokeApi<EmailDataWrapper, null>({
    //         route: `email`,
    //         method: 'POST',
    //         privateRoute: true,
    //         data: {
    //             data
    //         },
    //     });
    // };

    public postFeeReminderEmail = async (data: FeeReminderEmailAttributes) => {
        return invokeApi<FeeReminderEmailAttributes, string>({
            route: `fee-reminder-email`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public postFeeReminderWhatsappMessage = async (data: FeeReminderWhatsappMessageData) => {
        return invokeApi<FeeReminderWhatsappMessageData, null>({
            route: `whatsapp/send-fee-reminder-message`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public updateFeeCollection = async (data: FeePaymentPostData, id: number) => {
        return invokeApi<FeePaymentPostDataWrapper, FeePaymentPostDataWrapper>({
            route: `fee-collections/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public postNewFeeCollection = async (data: FeePaymentPostData) => {
        return invokeApi<FeePaymentPostDataWrapper, FeePaymentPostDataWrapper>({
            route: `fee-collections`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public getLeaveRecordsList = async (
        userId: number
    ): Promise<ApiResponse<AttendanceListWrapper>> => {
        return invokeApi<null, AttendanceListWrapper>({
            route: `schedules?populate=class,student&filters[class][users][id]=${userId}&filters[on_leave]=true`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
