import React, { useEffect, useState } from 'react'
import { CertificationEventPostData } from '../../../models/certifications/certification-event-post-data';
import { CertificationService } from '../../../services/certification-service';
import { CertificationEventDataWrapper } from '../../../models/certifications/certification-event-data-wrapper';
import { CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE } from '../../../utils/constants/constants';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { CertificationEventData } from '../../../models/certifications/certification-event-data';
import { StudentData } from '../../../models/student/student-data';

export interface LiveCertificationEventStudentRemarks {
    remarks: string;
    givenBy: string;
}

const CertificationEventStudentInfoViewModel = ({
    isOpen,
    handleClose,
    selectedEventData,
    studentData,
}:{
    isOpen: boolean;
    handleClose: () => void;
    selectedEventData: CertificationEventData | undefined;
    studentData: StudentData | undefined;
}) => {

    const [eventStudentRemarkProvider, setEventStudentRemarkProvider] = useState<string>('');
    const [eventStudentRemarks, setEventStudentRemarks] = useState<string>('');

    const queryClient = useQueryClient();

    useEffect(() => {
        setEventStudentRemarkProvider('')
        setEventStudentRemarks('')
    },[isOpen])

    const [liveCertificationEventStudentRemarks, setLiveCertificationEventStudentRemarks] = useState<LiveCertificationEventStudentRemarks[]>([]);

    const handleAddLiveCertificationEventStudentRemarks = (e: React.FormEvent, data: LiveCertificationEventStudentRemarks) => {
        e.preventDefault();
        setLiveCertificationEventStudentRemarks(prev => [...prev, data]);
        setEventStudentRemarkProvider('')
        setEventStudentRemarks('')
    }

    const handleModalClose = () => {
        setLiveCertificationEventStudentRemarks([])
        handleClose();
    }

    const putCertificateEvent = async (
        data: CertificationEventPostData
    ): Promise<CertificationEventDataWrapper | undefined> => {
        const response = await CertificationService.instance.putCertificationEvent(data, selectedEventData?.id ?? 0);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateCertificationEvent,
        isLoading: isNewCertificationEventUpdating,
    } = useMutation(putCertificateEvent, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE);
            handleModalClose();
        },
        onError: (error) => {
            alert('Failed to process the request! Please try again');
        },
    });

    const handleSkipStudent = () => {
        if(selectedEventData){
            const updatedStudents: {
                status: "SKIPPED" | "APPROVED" | "DEFAULT" | "PROMOTED";
                studentId: number;
                certificationId: number;
                remarks: {
                    remark: string;
                    givenBy: string;
                }[];
            }[] = selectedEventData?.attributes?.students?.map(student => {
                if(student?.studentId === studentData?.id){
                    return {
                        ...student,
                        status: 'SKIPPED',
                    }
                }else{
                    return student
                }
            })
            updateCertificationEvent({
                students: updatedStudents,
            })
        }
    }

    const handleApproveStudent = () => {
        if(selectedEventData){
            const updatedStudents: {
                status: "SKIPPED" | "APPROVED" | "DEFAULT" | "PROMOTED";
                studentId: number;
                certificationId: number;
                remarks: {
                    remark: string;
                    givenBy: string;
                }[];
            }[] = selectedEventData?.attributes?.students?.map(student => {
                if(student?.studentId === studentData?.id){
                    return {
                        ...student,
                        status: 'APPROVED',
                        remarks: liveCertificationEventStudentRemarks.length > 0 
                            ? liveCertificationEventStudentRemarks.map(r => ({remark: r.remarks, givenBy: r.givenBy})) 
                            : []
                    }
                }else{
                    return student
                }
            })
            updateCertificationEvent({
                students: updatedStudents,
            })

        }
    }

    return {
        isLoading: isNewCertificationEventUpdating,
        handleModalClose,
        eventStudentRemarkProvider,
        setEventStudentRemarkProvider,
        eventStudentRemarks,
        setEventStudentRemarks,
        liveCertificationEventStudentRemarks,
        handleAddLiveCertificationEventStudentRemarks,
        handleSkipStudent,
        handleApproveStudent,
    }
}

export default CertificationEventStudentInfoViewModel