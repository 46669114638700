import React from 'react';
import { BookDemoFormAttributes } from '../../../utils/types/book-demo-form-attribute';

interface heroProps {
    bookDemoFormData: BookDemoFormAttributes;
    handleInputChange: (field: string, value: string) => void;
    handleBookDemoFormSubmit: (e: React.FormEvent) => void;
    isDemoBooked: boolean;
}

const Hero = ({
    bookDemoFormData,
    handleInputChange,
    handleBookDemoFormSubmit,
    isDemoBooked,
}: heroProps) => {
    return (
        <div
            className="Hero flex flex-col md:flex-row sm:pt-0"
            style={{ background: 'linear-gradient(135deg, #FBFCFF, #FFFFFF)' }}
        >
            <div className="flex flex-col md:justify-center w-[90%] items-center md:flex-[50%] mx-auto h-screen sm:h-full md:h-screen justify-center gap-y-8">
                <h1 className="text-2xl tall:text-2xl xss:text-4xl  md:text-6xl 2xl:text-6xl font-bold leading-[1.5] text-left md:ml-16 mt-20 md:mt-0 ">
                    Managing Sports & Arts academies has never been easier
                </h1>

                <div className="font-thin text-gray-700 md:ml-16 md:mt-2 xss:text-base text-xs md:text-lg">
                    <h3 className="italic font-thin ">Grow your business in a single platform</h3>
                    <p className="mt-8 font-medium">
                        Do you own a karate academy? Or run a guitar academy? Manage your academy
                        with a platform that processes enquiries, streamlines operations, and
                        improves the student experience
                    </p>
                </div>
            </div>
            <div
                className="flex-1 md:flex-[50%] flex items-center justify-center md:justify-around bg-[url('./assets/graphics/hero.webp')] bg-no-repeat bg-center bg-cover "
                style={{ backgroundSize: '80%' }}
            >
                <form
                    className="w-screen m-2 sm:m-0 sm:ml-0 h-full sm:w-full xl:w-1/2 max-w-lg p-4 sm:h-fit bg-[#192a68a6] rounded text-white"
                    id="demo"
                    onSubmit={handleBookDemoFormSubmit}
                >
                    <div className="flex flex-wrap -mx-3 ">
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <input
                                className={`appearance-none block w-full bg-[#192a68a6] border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:text-black ${bookDemoFormData.fullName === '' ? '' : ' bg-white text-black'}`}
                                id="fullName"
                                type="text"
                                value={bookDemoFormData.fullName}
                                onChange={e => {
                                    handleInputChange('fullName', e.target.value);
                                }}
                                placeholder="Full Name"
                                required
                                disabled={isDemoBooked}
                            />
                        </div>
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <input
                                className={`appearance-none block w-full bg-[#192a68a6] border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:text-black ${bookDemoFormData.email === '' ? '' : ' bg-white text-black'}`}
                                id="email"
                                type="email"
                                value={bookDemoFormData.email}
                                onChange={e => handleInputChange('email', e.target.value)}
                                placeholder="Enter Email Address"
                                required
                                disabled={isDemoBooked}
                            />
                        </div>
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <input
                                className={`appearance-none block w-full bg-[#192a68a6] border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:text-black ${bookDemoFormData.contactNo === '' ? '' : ' bg-white text-black'}`}
                                type="number"
                                id="number"
                                value={bookDemoFormData.contactNo}
                                onChange={e => handleInputChange('contactNo', e.target.value)}
                                placeholder="Mobile Number"
                                required
                                disabled={isDemoBooked}
                            />
                        </div>
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <input
                                className={`appearance-none block w-full bg-[#192a68a6] border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:text-black ${bookDemoFormData.location === '' ? '' : ' bg-white text-black'}`}
                                id="location"
                                type="text"
                                value={bookDemoFormData.location}
                                onChange={e => handleInputChange('location', e.target.value)}
                                placeholder="Area, City, Country"
                                required
                                disabled={isDemoBooked}
                            />
                        </div>
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <input
                                className={`appearance-none block w-full bg-[#192a68a6] border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:text-black ${bookDemoFormData.academyName === '' ? '' : ' bg-white text-black'}`}
                                id="academyName"
                                type="text"
                                value={bookDemoFormData.academyName}
                                onChange={e => handleInputChange('academyName', e.target.value)}
                                placeholder="Academy Name"
                                required
                                disabled={isDemoBooked}
                            />
                        </div>
                        {isDemoBooked ? (
                            <div className="text-white mx-3 bg-[#F46D21] w-full rounded text-center text-xl py-2">
                                Demo has been booked. we will get in touch shortly...
                            </div>
                        ) : (
                            <button
                                className="text-white mx-3 bg-[#F46D21] active:bg-[#ce6d35] w-full rounded text-center text-xl py-2 cursor-pointer"
                                type="submit"
                            >
                                Book Demo
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Hero;
