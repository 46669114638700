import { AssignmentTechniqueRequestDataWrapper } from '../models/progress-tracker/assignemnt-technique-request-data-wrapper';
import { AssignmentSubmissionsListWrapper } from '../models/progress-tracker/assignment-submissions-list-wrapper';
import { AssignmentTechniqueRequestListWrapper } from '../models/progress-tracker/assignment-technique-request-list-wrapper';
import { AssignmentTechniqueRequestPostData } from '../models/progress-tracker/assignment-technique-request-post-data';
import { FitnessTestConductedDataWrapper } from '../models/progress-tracker/fitness-test-conducted-data-wrapper';
import { FitnessTestConductedListWrapper } from '../models/progress-tracker/fitness-test-conducted-list-wrapper';
import { FitnessTestConductedPostData } from '../models/progress-tracker/fitness-test-conducted-postdata';
import { FitnessTestDetailsDataWrapper } from '../models/progress-tracker/fitness-test-details-data-wrapper';
import { FitnessTestDetailsListWrapper } from '../models/progress-tracker/fitness-test-details-list-wrapper';
import { FitnessTestDetailsPostData } from '../models/progress-tracker/fitness-test-details-postdata';
import { FitnessTestValueListWrapper } from '../models/progress-tracker/fitness-test-value-list-wrapper';
import { RegularAssignmentDataWrapper } from '../models/progress-tracker/regular-assignment-data-wrapper';
import { RegularAssignmentPostData } from '../models/progress-tracker/regular-assignment-post-data';
import { RegularAssignmentListWrapper } from '../models/progress-tracker/regular-assignments-list-wrapper';
import { StudentFitnessRecordDataWrapper } from '../models/progress-tracker/student-fitness-record-data-wrapper';
import { StudentFitnessRecordListWrapper } from '../models/progress-tracker/student-fitness-record-list-wrapper';
import { StudentFitnessRecordPostData } from '../models/progress-tracker/student-fitness-record-post-data';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class ProgressTrackerService {
    private static _instance: ProgressTrackerService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new ProgressTrackerService());
    }

    public getAssignmentTechniquesList = async (
        supplierId: number
    ): Promise<ApiResponse<AssignmentTechniqueRequestListWrapper>> => {
        return invokeApi<null, AssignmentTechniqueRequestListWrapper>({
            route: `assignment-technique-requests?populate[0]=video&populate[1]=users&populate[3]=keyFrames.data&populate[4]=academies&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getRegularAssignmentsList = async (
        supplierId: number
    ): Promise<ApiResponse<RegularAssignmentListWrapper>> => {
        return invokeApi<null, RegularAssignmentListWrapper>({
            route: `regular-assignments?populate=*&filters[users][id]=${supplierId}&sort[0]=createdAt:desc`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewAssignmentTechnique = async (
        data: AssignmentTechniqueRequestPostData
    ): Promise<ApiResponse<AssignmentTechniqueRequestDataWrapper>> => {
        return invokeApi<
            { data: AssignmentTechniqueRequestPostData },
            AssignmentTechniqueRequestDataWrapper
        >({
            route: `assignment-technique-requests`,
            privateRoute: true,
            method: 'POST',
            data: { data },
        });
    };

    public postNewRegularAssignment = async (
        data: RegularAssignmentPostData
    ): Promise<ApiResponse<RegularAssignmentDataWrapper>> => {
        return invokeApi<{ data: RegularAssignmentPostData }, RegularAssignmentDataWrapper>({
            route: `regular-assignments`,
            privateRoute: true,
            method: 'POST',
            data: { data },
        });
    };

    public putNewAssignmentTechnique = async (
        data: AssignmentTechniqueRequestPostData,
        id: number
    ): Promise<ApiResponse<AssignmentTechniqueRequestDataWrapper>> => {
        return invokeApi<
            { data: AssignmentTechniqueRequestPostData },
            AssignmentTechniqueRequestDataWrapper
        >({
            route: `assignment-technique-requests/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: { data },
        });
    };

    public putRegularAssignment = async (
        data: RegularAssignmentPostData,
        id: number
    ): Promise<ApiResponse<RegularAssignmentDataWrapper>> => {
        return invokeApi<{ data: RegularAssignmentPostData }, RegularAssignmentDataWrapper>({
            route: `regular-assignments/${id}`,
            privateRoute: true,
            method: 'PUT',
            data: { data },
        });
    };

    public deleteAssignmentTechniquesList = async (
        id: number
    ): Promise<ApiResponse<AssignmentTechniqueRequestDataWrapper>> => {
        return invokeApi<null, AssignmentTechniqueRequestDataWrapper>({
            route: `assignment-technique-requests/${id}`,
            method: 'DELETE',
            privateRoute: true,
        });
    };

    public getAssignmentSubmissionsOfStudent =  async (studentId: number): Promise<ApiResponse<AssignmentSubmissionsListWrapper>> => {
        return invokeApi<null, AssignmentSubmissionsListWrapper>({
            route: `assignment-submissions?populate[0]=fileSubmission&populate[1]=assignment&populate[2]=badges.badgeImage&filters[student]=${studentId}&sort[0]=createdAt:desc`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getFitnessTestDetailsList =  async (userId: number): Promise<ApiResponse<FitnessTestDetailsListWrapper>> => {
        return invokeApi<null, FitnessTestDetailsListWrapper>({
            route: `fitness-test-details?populate=fieldsRequired&filters[users]=${userId}&sort[0]=createdAt:desc`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getFitnessTestConductedList =  async (userId: number): Promise<ApiResponse<FitnessTestConductedListWrapper>> => {
        return invokeApi<null, FitnessTestConductedListWrapper>({
            route: `fitness-tests-organiseds?populate=participants,template&filters[users]=${userId}&sort[0]=createdAt:desc`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getFitnessTestConductingData =  async (id: number): Promise<ApiResponse<FitnessTestConductedDataWrapper>> => {
        return invokeApi<null, FitnessTestConductedDataWrapper>({
            route: `fitness-tests-organiseds/${id}?populate[0]=participants.photo&populate[1]=template.fieldsRequired`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewFitnessTestDetails = async (
        data: FitnessTestDetailsPostData
    ): Promise<ApiResponse<FitnessTestDetailsDataWrapper>> => {
        return invokeApi<{ data: FitnessTestDetailsPostData }, FitnessTestDetailsDataWrapper>({
            route: `fitness-test-details`,
            privateRoute: true,
            method: 'POST',
            data: { data },
        });
    };

    public postNewFitnessTestConducted = async (
        data: FitnessTestConductedPostData
    ): Promise<ApiResponse<FitnessTestConductedDataWrapper>> => {
        return invokeApi<{ data: FitnessTestConductedPostData }, FitnessTestConductedDataWrapper>({
            route: `fitness-tests-organiseds`,
            privateRoute: true,
            method: 'POST',
            data: { data },
        });
    };

    public updateFitnessTestConducted = async (
        data: FitnessTestConductedPostData,
        id: number
    ): Promise<ApiResponse<FitnessTestConductedDataWrapper>> => {
        return invokeApi<{ data: FitnessTestConductedPostData }, FitnessTestConductedDataWrapper>({
            route: `fitness-tests-organiseds/${id}`,
            privateRoute: true,
            method: 'PUT',
            data: { data },
        });
    };

    public postNewStudentFitnessRecord = async (
        data: StudentFitnessRecordPostData
    ): Promise<ApiResponse<StudentFitnessRecordDataWrapper>> => {
        return invokeApi<{ data: StudentFitnessRecordPostData }, StudentFitnessRecordDataWrapper>({
            route: `student-fitness-test-records`,
            privateRoute: true,
            method: 'POST',
            data: { data },
        });
    };

    public updateStudentFitnessRecord = async (
        data: StudentFitnessRecordPostData,
        id: number
    ): Promise<ApiResponse<StudentFitnessRecordDataWrapper>> => {
        return invokeApi<{ data: StudentFitnessRecordPostData }, StudentFitnessRecordDataWrapper>({
            route: `student-fitness-test-records/${id}`,
            privateRoute: true,
            method: 'PUT',
            data: { data },
        });
    };

    public getStudentsFitnessRecords = async (
        studentId: number,
        userId: number
    ): Promise<ApiResponse<StudentFitnessRecordListWrapper>> => {
        return invokeApi<null, StudentFitnessRecordListWrapper>({
            route: `student-fitness-test-records?populate[0]=testInfo.template&populate[1]=report&populate[2]=student&populate[3]=testResults&filters[student][id]=${studentId}&filters[users][id]=${userId}&sort[0]=createdAt:desc`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentFitnessRecordsOfTest = async (
        testId: number
    ): Promise<ApiResponse<StudentFitnessRecordListWrapper>> => {
        return invokeApi<null, StudentFitnessRecordListWrapper>({
            route: `student-fitness-test-records?populate=*&filters[testInfo][id]=${testId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getFitnessTestValuesList = async (): Promise<ApiResponse<FitnessTestValueListWrapper>> => {
        return invokeApi<null, FitnessTestValueListWrapper>({
            route: `fitness-test-values?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentSugessionList = async (
        supplierId: number,
        searchTerm: string
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=photo&filters[users][id]=${supplierId}&filters[$or][0][firstName][$contains]=${searchTerm}&filters[$or][1][middleName][$contains]=${searchTerm}&filters[$or][2][lastName][$contains]=${searchTerm}&pagination[pageSize]=50&sort[0]=firstName:asc`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
