import { log } from 'console';
import { CoachData } from '../models/coach/coach-data';
import { CoachListWrapper } from '../models/coach/coach-list-wrapper';
import { CoachPostDataWrapper } from '../models/coach/coach-post-data-wrapper';
import { DetailEmailPostData } from '../models/settings/detail-setting-email-data';
import { DetailImagePostData } from '../models/settings/detail-setting-image-data';
import { DetailSettingsPostData } from '../models/settings/detail-setting-post-data';
import { NotificationSettingsPostData } from '../models/settings/notification-setting-post-data';
import { ReportSettingsPostData } from '../models/settings/report-settings-post-data';
import { SupplierDataWrapper } from '../models/supplier/supplier-data-wrapper';
import { UserDetailsData } from '../models/user-details/user-details-data';
import { UserDetailsListWrapper } from '../models/user-details/user-details-list-wrapper';
import { UserDetailsPostDataWrapper } from '../models/user-details/user-details-post-data-wrapper';
import { AuthResponse } from '../models/user/auth-response';
import { UserPasswordPostData } from '../models/user/user-password-post-data';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class SettingsService {
    private static _instance: SettingsService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new SettingsService());
    }

    public postSupplierReportSettings = async (
        supplierId: number,
        data: ReportSettingsPostData
    ): Promise<ApiResponse<SupplierDataWrapper>> => {
        return invokeApi<{ data: ReportSettingsPostData }, SupplierDataWrapper>({
            route: `supplier-details/${supplierId}`,
            method: 'PUT',
            privateRoute: true,
            data: { data },
        });
    };

    public postSupplierNotificationSettings = async (
        supplierId: number,
        data: NotificationSettingsPostData
    ): Promise<ApiResponse<SupplierDataWrapper>> => {
        return invokeApi<{ data: NotificationSettingsPostData }, SupplierDataWrapper>({
            route: `supplier-details/${supplierId}`,
            method: 'PUT',
            privateRoute: true,
            data: { data },
        });
    };

    public postSupplierDetailSettings = async (
        supplierId: number,
        data: DetailSettingsPostData
    ): Promise<ApiResponse<SupplierDataWrapper>> => {
        return invokeApi<{ data: DetailSettingsPostData }, SupplierDataWrapper>({
            route: `supplier-details/${supplierId}`,
            method: 'PUT',
            privateRoute: true,
            data: { data },
        });
    };

    //Create one for email
    public postSupplierEmail = async (
        userId: number,
        data: DetailEmailPostData
    ): Promise<ApiResponse<SupplierDataWrapper>> => {
        return invokeApi<DetailEmailPostData, SupplierDataWrapper>({
            route: `users/${userId}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };

    public postUserPassword = async (
        data: UserPasswordPostData
    ): Promise<ApiResponse<AuthResponse>> => {
        return invokeApi<UserPasswordPostData, AuthResponse>({
            route: `auth/change-password`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public getSupplierPaymentDetails = async (
        supplierId: number
    ): Promise<ApiResponse<SupplierDataWrapper>> => {
        return invokeApi<null, SupplierDataWrapper>({
            route: `supplier-details/${supplierId}?fields[0]=firstName&populate[cashfreeCredentials][fields][0]=email&populate[cashfreeCredentials][fields][1]=merchantName&populate[cashfreeCredentials][fields][2]=phone&populate[cashfreeCredentials][fields][3]=status`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
