// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_dropdownListContainer__geIK4 {
    width: 131px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    --tw-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
    --tw-shadow-colored: 0 0 10px 2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.style_dropdownListItem__wcKch {
    cursor: pointer;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 0.375rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0px;
    --tw-text-opacity: 1;
    color: rgb(34 34 34 / var(--tw-text-opacity))
}
.style_dropdownListItem__wcKch:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/dropdown-list-feePayment/style.module.css"],"names":[],"mappings":"AACI;IAAA,YAA6E;IAA7E,kBAA6E;IAA7E,yDAA6E;IAA7E,iBAA6E;IAA7E,kBAA6E;IAA7E,gBAA6E;IAA7E,mBAA6E;IAA7E,yCAA6E;IAA7E,wDAA6E;IAA7E;AAA6E;AAG7E;IAAA,eAAsI;IAAtI,kBAAsI;IAAtI,yDAAsI;IAAtI,qBAAsI;IAAtI,wBAAsI;IAAtI,qBAAsI;IAAtI,uBAAsI;IAAtI,eAAsI;IAAtI,gBAAsI;IAAtI,oBAAsI;IAAtI,mBAAsI;IAAtI,oBAAsI;IAAtI;AAAsI;AAAtI;IAAA,kBAAsI;IAAtI;AAAsI","sourcesContent":[".dropdownListContainer {\n    @apply bg-white w-[131px] shadow-[0_0_10px_2px_rgba(0,0,0,0.1)] px-0 py-[5px];\n}\n.dropdownListItem {\n    @apply text-[13px] font-normal leading-5 tracking-[0px] text-[#222222] bg-white cursor-pointer pl-3 pr-1.5 py-1.5 hover:bg-neutral-100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownListContainer": `style_dropdownListContainer__geIK4`,
	"dropdownListItem": `style_dropdownListItem__wcKch`
};
export default ___CSS_LOADER_EXPORT___;
