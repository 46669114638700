// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formInput__FzpY1 {
    z-index: 50;
    max-height: 2.5rem;
    width: 100%;
    resize: none;
    border-radius: 0.25rem;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
    --tw-border-opacity: 0.3;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.style_formInput__FzpY1:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}
.style_suggestionBox__fc2xZ {
    position: absolute;
    z-index: 10;
    margin-top: 0.25rem;
    max-height: 12rem;
    width: 100%;
    overflow-y: auto;
    border-radius: 10px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.style_suggestionBoxListWrapper__sU2Me {
    font-size: 0.75rem;
    line-height: 1rem
}
.style_suggestionBoxListItem__0ZmvB {
    cursor: pointer;
    border-bottom-width: 2px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.style_suggestionBoxListItem__0ZmvB:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/send-enrolment-form/style.module.css"],"names":[],"mappings":"AACI;IAAA,WAA6H;IAA7H,kBAA6H;IAA7H,WAA6H;IAA7H,YAA6H;IAA7H,sBAA6H;IAA7H,yDAA6H;IAA7H,wBAA6H;IAA7H,mBAA6H;IAA7H,oBAA6H;IAA7H,oBAA6H;IAA7H;AAA6H;AAA7H;IAAA,kBAA6H;IAA7H;AAA6H;AAG7H;IAAA,kBAAiF;IAAjF,WAAiF;IAAjF,mBAAiF;IAAjF,iBAAiF;IAAjF,WAAiF;IAAjF,gBAAiF;IAAjF,mBAAiF;IAAjF,kBAAiF;IAAjF;AAAiF;AAGjF;IAAA,kBAAc;IAAd;AAAc;AAGd;IAAA,eAA6D;IAA7D,wBAA6D;IAA7D,mBAA6D;IAA7D,sBAA6D;IAA7D,qBAA6D;IAA7D;AAA6D;AAA7D;IAAA,kBAA6D;IAA7D;AAA6D","sourcesContent":[".formInput {\n    @apply text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded disabled:bg-slate-100 z-50 max-h-10;\n}\n.suggestionBox {\n    @apply absolute w-full max-h-48 overflow-y-auto bg-white z-10 rounded-[10px] mt-1;\n}\n.suggestionBoxListWrapper {\n    @apply text-xs;\n}\n.suggestionBoxListItem {\n    @apply py-2 px-3 border-b-2 hover:bg-slate-200 cursor-pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInput": `style_formInput__FzpY1`,
	"suggestionBox": `style_suggestionBox__fc2xZ`,
	"suggestionBoxListWrapper": `style_suggestionBoxListWrapper__sU2Me`,
	"suggestionBoxListItem": `style_suggestionBoxListItem__0ZmvB`
};
export default ___CSS_LOADER_EXPORT___;
