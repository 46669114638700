// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formInput__fNM4w {
    width: 100%;
    resize: none;
    border-radius: 0.25rem;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
    --tw-border-opacity: 0.3;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.style_formInput__fNM4w:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}
@media (min-width: 640px) {
    .style_formInput__fNM4w {
        max-height: 2.5rem
    }
}
`, "",{"version":3,"sources":["webpack://./src/view/pages/student-enroll-form/style.module.css"],"names":[],"mappings":"AACI;IAAA,WAA2H;IAA3H,YAA2H;IAA3H,sBAA2H;IAA3H,yDAA2H;IAA3H,wBAA2H;IAA3H,mBAA2H;IAA3H,oBAA2H;IAA3H,oBAA2H;IAA3H;AAA2H;AAA3H;IAAA,kBAA2H;IAA3H;AAA2H;AAA3H;IAAA;QAAA;IAA2H;AAAA","sourcesContent":[".formInput {\n    @apply text-sm text-gray-500 resize-none w-full border-gray-500 border-opacity-30 rounded disabled:bg-slate-100 sm:max-h-10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInput": `style_formInput__fNM4w`
};
export default ___CSS_LOADER_EXPORT___;
