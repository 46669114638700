import React from 'react';
import ReactDOM from 'react-dom';
import style from './style.module.css';
import Loader from '../loader';

const FullScreenLoader = () => {
    return ReactDOM.createPortal(
        <div className={style.overlay}>
            <div className={style.spinnerContainer}>
                <Loader />
            </div>
        </div>,
        document.getElementById('loader') as HTMLElement
    );
};

export default FullScreenLoader;
