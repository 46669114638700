// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_filterCheckboxContainer__4Yizo {
    display: flex;
    gap: 0.5rem
}
.style_filterCheckboxLabel__WTzK2 {
    font-size: 13px;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/view/components/pieces/filter-check-box-item/style.module.css"],"names":[],"mappings":"AACI;IAAA,aAAiB;IAAjB;AAAiB;AAGjB;IAAA,eAAoF;IAApF,kBAAoF;IAApF,gBAAoF;IAApF,oBAAoF;IAApF,mBAAoF;IAApF,oBAAoF;IAApF;AAAoF","sourcesContent":[".filterCheckboxContainer {\n    @apply flex gap-2;\n}\n.filterCheckboxLabel {\n    @apply text-[13px] font-normal leading-5 tracking-[0px] text-black text-xs font-bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterCheckboxContainer": `style_filterCheckboxContainer__4Yizo`,
	"filterCheckboxLabel": `style_filterCheckboxLabel__WTzK2`
};
export default ___CSS_LOADER_EXPORT___;
