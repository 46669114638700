import React, { useEffect, useState, useRef } from 'react'

type TimeInputProps = {
    value: number;
    onChange: (value: number) => void
    timeFormat: 'HOUR' | 'MINUTE' | 'SECOND';
    id?: string;
}

const TimeInput = ({
    value,
    onChange,
    id,
    timeFormat,
}: TimeInputProps) => {
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');
    const [milliseconds, setMilliseconds] = useState('');

    const hoursRef = useRef<HTMLInputElement>(null);
    const minutesRef = useRef<HTMLInputElement>(null);
    const secondsRef = useRef<HTMLInputElement>(null);
    const millisecondsRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const totalSeconds = Math.floor(value / 1000);
        const newHours = Math.floor(totalSeconds / 3600);
        const newMinutes = Math.floor((totalSeconds % 3600) / 60);
        const newSeconds = totalSeconds % 60;
        const newMilliseconds = value % 1000;
    
        setHours(newHours.toString().padStart(2, '0'));
        setMinutes(newMinutes.toString().padStart(2, '0'));
        setSeconds(newSeconds.toString().padStart(2, '0'));
        setMilliseconds(newMilliseconds.toString().padStart(3, '0'));
    }, [value]);

    const handleInputChange = (unit: 'hours' | 'minutes' | 'seconds' | 'milliseconds', inputValue: string) => {
        let newValue = Number(inputValue);
        if (unit === 'hours') newValue = Math.min(newValue, 23);
        if (unit === 'minutes' || unit === 'seconds') newValue = Math.min(newValue, 59);
        if (unit === 'milliseconds') newValue = Math.min(newValue, 999);
    
        const totalMilliseconds =
          (unit === 'hours' ? newValue * 3600000 : Number(hours) * 3600000) +
          (unit === 'minutes' ? newValue * 60000 : Number(minutes) * 60000) +
          (unit === 'seconds' ? newValue * 1000 : Number(seconds) * 1000) +
          (unit === 'milliseconds' ? newValue : Number(milliseconds));
    
        onChange(totalMilliseconds);

        // Move focus to the next field based on the current unit
        if (unit === 'hours' && newValue.toString().length === 2) {
            minutesRef.current?.focus();
        } else if (unit === 'minutes' && newValue.toString().length === 2) {
            secondsRef.current?.focus();
        } else if (unit === 'seconds' && newValue.toString().length === 2) {
            millisecondsRef.current?.focus();
        }
    };

    return (
        <div className='flex items-center bg-transparent rounded'>
            {timeFormat === 'HOUR' && (
                <>
                    <input 
                        id={id ?? ''}
                        ref={hoursRef}
                        className="border-0 focus:ring-0 px-0 w-5 bg-transparent" 
                        placeholder="HH" 
                        type="number" 
                        min="0" 
                        max="23" 
                        maxLength={2} 
                        value={hours}
                        onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            input.value = input.value.slice(-2);
                            handleInputChange('hours', input.value);
                        }}
                    />:
                </>
            )}
            {(timeFormat === 'HOUR' || timeFormat === 'MINUTE') && (
                <>
                    <input 
                        id={id ?? ''}
                        ref={minutesRef}
                        className='border-0 focus:ring-0 px-0 w-5 bg-transparent' 
                        placeholder='MM' 
                        type='number' 
                        min="0" 
                        max="59" 
                        maxLength={2} 
                        value={minutes}
                        onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            input.value = input.value.slice(-2);
                            handleInputChange('minutes', input.value);
                        }}
                    />:
                </>
            )}
            {(timeFormat === 'HOUR' || timeFormat === 'MINUTE' || timeFormat === 'SECOND') && (
                <>
                    <input 
                        id={id ?? ''}
                        ref={secondsRef}
                        className='border-0 focus:ring-0 px-0 w-5 bg-transparent' 
                        placeholder='SS' 
                        type='number' 
                        min="0" 
                        max="59" 
                        maxLength={2} 
                        value={seconds}
                        onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            input.value = input.value.slice(-2);
                            handleInputChange('seconds', input.value);
                        }}
                    />:
                </>
            )}
            <input 
                id={id ?? ''}
                ref={millisecondsRef}
                className='border-0 focus:ring-0 px-0 w-8 bg-transparent' 
                placeholder='sss'
                type='number'
                min="0"
                max="999" 
                maxLength={3} 
                value={milliseconds}
                onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    input.value = input.value.slice(-3);
                    handleInputChange('milliseconds', input.value);
                }}
            />
        </div>
    )
}

export default TimeInput
