import React from 'react';
import style from './style.module.css';

const DeleteClassModal = ({
    handleDeleteClassModalClose,
}: {
    handleDeleteClassModalClose: () => void;
}) => {
    // Function body
    return (
        <>
            <div className='flex justify-center'>
                    <div className={style.iconContainer}>
                    <span className="icon">!</span>
            </div>
            </div>
            <div className={style.modalBody}>
            <p>Before deleting the class you have to remove the students enrolled to this class</p>
            </div>
            <div className={style.modalFooter}>
            <button className={style.cancelButton} onClick={handleDeleteClassModalClose}>
                Close
            </button>
            </div>
        </>
    )
};

export default DeleteClassModal;